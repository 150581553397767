import { useState, useEffect } from 'react';
import { IBajaStepThree } from './../../../interfaces/baja/IBaja';
import { SummaryItem } from './SummaryItem';
import { Box, Typography } from '@mui/material';
import { CustomAccordion, IAccordionProps } from 'components/globals/accordion/CustomAccordion';

interface ISummaryStepThree {
	entity: IBajaStepThree;
}

export const SummaryStepThree = ({ entity }: ISummaryStepThree) => {
	const [accordionItems, setAccordionItems] = useState<IAccordionProps[]>([]);

	useEffect(() => {
		if (entity?.items) {
			const newAccordionItems: IAccordionProps[] = [];

			entity?.items?.forEach((item) => {
				newAccordionItems.push({
					title: `${item?.property?.item_inventory_number} - ${
						item?.property?.account_nomenclature?.description || item.property?.nomenclature_description
					}`,
					children: <SummaryItem entity={item} />
				});
			});
			setAccordionItems(newAccordionItems);
		}
	}, [entity]);

	return (
		<Box sx={{ margin: '3%', marginTop: '2%' }}>
			{accordionItems.length > 0 ? (
				<CustomAccordion accordions={accordionItems} />
			) : (
				<Box>
					<Typography>Sin datos</Typography>
				</Box>
			)}
		</Box>
	);
};
