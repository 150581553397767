import {
	FormControl,
	InputLabel,
	MenuItem,
	Select as MuiSelect,
	SelectChangeEvent,
	SelectProps as MuiSelectProps
} from '@mui/material';
import { ISelectOption } from 'interfaces/commons/IParameters';
import React, { useEffect, useState } from 'react';

interface SelectProps {
	options?: ISelectOption[];
	value?: ISelectOption | Array<ISelectOption>;
	onChange?(selected: ISelectOption | Array<ISelectOption>): void;
	label?: string;
	size?: 'small' | 'medium';
	disabled?: boolean;
	sx?: MuiSelectProps['sx'];
	multiple?: boolean;
	open?: boolean;
	onOpen?(): void;
	onClose?(): void;
}

export const Select: React.FC<SelectProps> = ({
	options,
	onChange,
	value,
	label,
	size,
	disabled,
	sx,
	multiple,
	open,
	onOpen,
	onClose
}) => {
	const [option, setOption] = useState<string | string[]>(multiple ? [] : '');

	const handleChange = ({ target: { value } }: SelectChangeEvent) => {
		setOption(value);

		const selected = (
			multiple
				? (value as unknown as string[]).map((label) => {
						return options?.find((el) => el?.label === label) as ISelectOption;
				  })
				: options?.find((el) => el?.label === value)
		) as ISelectOption | Array<ISelectOption>;

		onChange && onChange(selected);
	};

	useEffect(() => {
		if (Array.isArray(value)) return setOption(value?.map((el) => el.label) || []);
		setOption(value?.label || '');
	}, [value]);

	return (
		<FormControl fullWidth>
			{label ? <InputLabel id="select-label">{label}</InputLabel> : null}
			<MuiSelect
				labelId="select-label"
				disabled={disabled}
				fullWidth
				size={size}
				value={option as string}
				label={label}
				multiple={multiple}
				sx={sx}
				onClose={onClose}
				onOpen={onOpen}
				open={open}
				onChange={handleChange}>
				{options?.map((item, index) => {
					return (
						<MenuItem
							disabled={item.disabled}
							value={item.label}
							key={index}>
							{item.label}
						</MenuItem>
					);
				})}
			</MuiSelect>
		</FormControl>
	);
};
