import { Button, Typography } from '@mui/material';
import { useMovContext } from 'hooks/useMovContext';

export interface ITablePaginatorProps {
	table: any;
	showInputPage?: boolean;
}

export const TablePaginator = ({ table, showInputPage = false }: ITablePaginatorProps) => {
	const movContext = useMovContext();
	return (
		<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%' }}>
			<div
				style={{ margin: '15px', display: 'flex', alignSelf: 'flex-end', justifyContent: 'center', width: !showInputPage ? '100%' : '' }}>
				<Button
					name="first"
					variant="outlined"
					color="primary"
					sx={{ ml: '3px', mr: '3px', fontWeight: '600' }}
					onClick={() => {
						movContext?.setMeta({ ...movContext?.meta, currentPage: 1 });
						movContext?.setFiltersMovTable({ ...movContext?.filtersMovTable, page: 1 });
						movContext?.setIsUpdatingFilters(true);
					}}
					disabled={movContext?.meta.currentPage === 1}>
					{'<<'}
				</Button>
				<Button
					name="prev"
					variant="outlined"
					color="primary"
					sx={{ ml: '3px', mr: '3px', fontWeight: '600' }}
					onClick={() => {
						movContext?.setMeta({ ...movContext?.meta, currentPage: movContext?.meta.currentPage - 1 });
						movContext?.setFiltersMovTable({ ...movContext?.filtersMovTable, page: movContext?.meta.currentPage - 1 });
						movContext?.setIsUpdatingFilters(true);
					}}
					disabled={movContext?.meta.currentPage === 1}>
					{'<'}
				</Button>
				<Button
					name="next"
					variant="outlined"
					color="primary"
					sx={{ ml: '3px', mr: '3px', fontWeight: '600' }}
					onClick={() => {
						movContext?.setMeta({ ...movContext?.meta, currentPage: movContext?.meta.currentPage + 1 });
						movContext?.setFiltersMovTable({ ...movContext?.filtersMovTable, page: movContext?.meta.currentPage + 1 });
						movContext?.setIsUpdatingFilters(true);
					}}
					disabled={movContext?.meta.currentPage === movContext?.meta?.totalPages}>
					{'>'}
				</Button>
				<Button
					name="last"
					variant="outlined"
					color="primary"
					sx={{ ml: '3px', mr: '3px', fontWeight: '600' }}
					onClick={() => {
						movContext?.setMeta({ ...movContext?.meta, currentPage: movContext?.meta.totalPages });
						movContext?.setFiltersMovTable({ ...movContext?.filtersMovTable, page: movContext?.meta.totalPages });
						movContext?.setIsUpdatingFilters(true);
					}}
					disabled={movContext?.meta.currentPage === movContext?.meta?.totalPages}>
					{'>>'}
				</Button>
			</div>
			{showInputPage && (
				<div style={{ display: 'flex', justifyContent: 'end' }}>
					<span className="page">
						<div className="span-title">{'Página:'}</div>
						<strong style={{ whiteSpace: 'nowrap' }}>
							{movContext?.meta?.currentPage} de {movContext?.meta?.totalPages}
						</strong>
					</span>
					<span style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center' }}>
						| Ir a la página:
						<input
							style={{ width: '100px', height: '30px', marginBottom: '0px', padding: '4px', marginRight: '0px' }}
							type="number"
							defaultValue={table.getState().pagination.pageIndex + 1}
							onChange={(e) => {
								if (e.target.value.trim() === '') return;
								const page = e.target.value ? Number(e.target.value) : 1;
								if (page < 1) return;
								if (movContext?.meta?.totalPages && page > movContext?.meta?.totalPages) {
									movContext?.setMeta({ ...movContext?.meta, currentPage: movContext?.meta?.totalPages });

									return;
								}
								movContext?.setMeta({ ...movContext?.meta, currentPage: page });
								movContext?.setFiltersMovTable({ ...movContext?.filtersMovTable, page: page });
								movContext?.setIsUpdatingFilters(true);
							}}
						/>
					</span>
				</div>
			)}
		</div>
	);
};
