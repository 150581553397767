// Enums
import { ApiPaths } from 'enums/apiPaths';
import { handleHttpError, buildDefaultOptions, httpGet } from './httpService';

const URL_BASE = `${ApiPaths.DEREGISTRATION_TYPES}`;

export const tryGetAllDeregistrationTypes = async () => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpGet(URL_BASE, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};
