import { Item } from 'interfaces/crud/createHighData';
import { IPaso } from 'components/EncPatrimonial/altaStepper/AltaStepper';
import { getPropertyTypes } from 'services/crudService';

export const setAccessories = (paso3: IPaso, items: Item[]) => {
	const today = new Date();

	if (paso3.data.length > 0) {
		paso3.data.forEach((accessory: any) => {
			if (accessory.accessories) {
				accessory?.accessories?.map((item: any) => {
					items.push({
						account_nomenclature_id: item.nomenclatureCodeId,
						temporary_id: item.temporary_id,
						is_accessory: true,
						parent_property_temporary_id: item.parent_temporary_id,
						accessory_number: item.accessory_number,
						quantity: Number(item.quantity),
						discharge_date: today.toISOString(),
						unit_price: item.unitPrice,
						description: item.description
					});
				});
			}
		});
	}
	return items;
};

export const setItems = async (paso3: IPaso, items: Item[]) => {
	const today = new Date();
	const property_types = await getPropertyTypes().then((res) => {
		/* const dataNormalized = removeAccents(res.data);
		return dataNormalized; */
		return res.data;
	});

	if (paso3.data.length > 0) {
		paso3.data.forEach((item: any) => {
			//Genericos
			if (item.rightType === 'Genérico') {
				items.push({
					is_quantifiable: item.inventoriable,
					account_nomenclature_id: item.nomenclatureCodeId,
					temporary_id: item.id,
					is_accessory: false,
					quantity: Number(item.quantity),
					discharge_date: today.toISOString(),
					unit_price: item.unitPrice,
					description: item.description,
					property_type_id: property_types?.filter((value: any) => value.name === 'Genérico')[0]?.id
				});
			}
			//Vehiculos
			if (item.rightType === 'Vehículo') {
				if (item.vehicleType === 'Aeronaves') {
					items.push({
						account_nomenclature_id: item.nomenclatureCodeId,
						temporary_id: item.id,
						is_accessory: false,
						quantity: Number(item.quantity),
						discharge_date: today.toISOString(),
						unit_price: item.unitPrice,
						property_type_id: property_types?.filter((value: any) => value.name === 'Aeronave')[0]?.id,
						aircraft_details: {
							registration: item.domainRegistartion,
							serial_number: item.serialNumber,
							brand: item.brand,
							model: item.model,
							manufacturing_date: item.dateOfConstruction,
							type: item.type
						}
					});
				}
				if (item.vehicleType === 'Embarcaciones') {
					items.push({
						account_nomenclature_id: item.nomenclatureCodeId,
						temporary_id: item.id,
						is_accessory: false,
						quantity: Number(item.quantity),
						discharge_date: today.toISOString(),
						unit_price: item.unitPrice,
						property_type_id: property_types?.filter((value: any) => value.name === 'Embarcación')[0]?.id,
						ship_details: {
							registration: item.domainRegistartion,
							ship_name: item.boatName,
							type: item.type,
							helmet_number: item.boatHullNumber,
							helmet_model: item.modelHull,
							manufacturing_date: item.dateOfConstruction,
							headline: item.owner,
							engine_brand: item.engineBrandName,
							engine_number: item.engineNumber,
							model_series: item.engineModelSerie,
							engine_holder: item.engineOwner
						}
					});
				}
				if (item.vehicleType === 'Motovehículos') {
					items.push({
						account_nomenclature_id: item.nomenclatureCodeId,
						temporary_id: item.id,
						is_accessory: false,
						quantity: Number(item.quantity),
						discharge_date: today.toISOString(),
						unit_price: item.unitPrice,
						property_type_id: property_types?.filter((value: any) => value.name === 'Motocicleta')[0]?.id,
						motorcycle_details: {
							domain: item.domainRegistartion,
							engine_number: item.engineNumber,
							frame_number: item.bikeFrameNumber,
							brand: item.brand,
							model: item.model,
							year: item.year,
							origin: item.origin,
							headline: item.owner,
							type: item.type
						}
					});
				}
				if (item.vehicleType === 'Automotores') {
					items.push({
						account_nomenclature_id: item.nomenclatureCodeId,
						temporary_id: item.id,
						is_accessory: false,
						quantity: Number(item.quantity),
						discharge_date: today.toISOString(),
						unit_price: item.unitPrice,
						property_type_id: property_types?.filter((value: any) => value.name === 'Automotor')[0]?.id,
						automotive_details: {
							domain: item.domainRegistartion,
							engine_number: item.engineNumber,
							chassis_number: item.chassisNumber,
							brand: item.brand,
							model: item.model,
							year: item.year,
							origin: item.origin,
							headline: item.owner,
							type: item.type
						}
					});
				}
				if (item.vehicleType === 'Máquinas') {
					items.push({
						account_nomenclature_id: item.nomenclatureCodeId,
						temporary_id: item.id,
						is_accessory: false,
						quantity: Number(item.quantity),
						discharge_date: today.toISOString(),
						unit_price: item.unitPrice,
						property_type_id: property_types?.filter((value: any) => value.name === 'Máquinas')[0]?.id,
						machine_details: {
							domain: item.domainRegistartion,
							engine_number: item.engineNumber,
							chassis_number: item.chassisNumber,
							brand: item.brand,
							model: item.model,
							year: item.year,
							origin: item.origin,
							headline: item.owner,
							type: item.type
						}
					});
				}
			}
			//Inmuebles
			if (item.rightType === 'Inmueble') {
				items.push({
					account_nomenclature_id: item.nomenclatureCodeId,
					temporary_id: item.id,
					is_accessory: false,
					quantity: Number(item.quantity),
					discharge_date: today.toISOString(),
					unit_price: item.unitPrice,
					property_type_id: property_types?.filter((value: any) => value.name === 'Propiedad')[0]?.id,
					estate_details: {
						address: item.address,
						cadastral_information: {
							department: item.catastroValues.department,
							municipality: item.catastroValues.municipality,
							section: item.catastroValues.section,
							farm: item.catastroValues.ranch,
							street: item.catastroValues.apple,
							parcel: item.catastroValues.parcel,
							functional_unit: item.catastroValues.functionalUnity,
							real_estate_item: item.catastroValues.realStateItem,
							plane_number: item.catastroValues.planeNumber
						},
						registry_information: {
							department: item.registerInformation.department,
							farm: item.registerInformation.estate,
							invoice: item.registerInformation.folioFolder,
							observations: item.registerInformation.description,
							owner: item.registerInformation.propertyOwner,
							property_description: item.registerInformation.propertyDescription,
							registration_number: item.registerInformation.domainNumber,
							took: item.registerInformation.volumeFolder
						},
						property_title: item.propertyTitle,
						title_name_patrimonial_unit: item.titleOfPatrimonialDependency
					}
				});
			}
		});
	}
};

/* Call handleDisabled function when one of the dependencies change. */
function removeAccents(strArr: any[]) {
	if (strArr.length <= 0) {
		return strArr;
	}
	const newArr: any = [];
	strArr.map((item) => {
		newArr.push({ id: item.id, name: item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '') });
	});
	return newArr;
}
