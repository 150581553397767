import { FC, Dispatch, SetStateAction } from 'react';
import { FormGenericPassword } from '../inputs/FormGenericPassword';
import { Box, FormHelperText } from '@mui/material';

interface IFormCredentialsProps {
	title?: String;
	password?: string;
	repeatPassword?: string;
	handleChangePassword: Dispatch<SetStateAction<any>>;
	handleChangeRepeatPassword: Dispatch<SetStateAction<any>>;
	errorPassword?: string;
	errorRepeatPassword?: string;
	hintPassword?: string;
	hintRepeatPassword?: string;
	labelPassword?: string;
	labelRepeatPassword?: string;
}

export const FormCredentials: FC<IFormCredentialsProps> = ({
	title = 'Credenciales de acceso',
	password,
	repeatPassword,
	handleChangePassword,
	handleChangeRepeatPassword,
	errorPassword,
	errorRepeatPassword,
	hintPassword,
	hintRepeatPassword,
	labelPassword = 'Contraseña',
	labelRepeatPassword = 'Repita Contraseña'
}) => {
	return (
		<fieldset>
			<FormHelperText>{title}</FormHelperText>
			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '10px', rowGap: '30px', paddingTop: '10px' }}>
				<FormGenericPassword
					value={password}
					label={labelPassword}
					name="password"
					id="password"
					handleChange={handleChangePassword}
					error={errorPassword}
					hint={hintPassword}
				/>

				<FormGenericPassword
					value={repeatPassword}
					label={labelRepeatPassword}
					name="repeatPassword"
					id="repeatPassword"
					handleChange={handleChangeRepeatPassword}
					error={errorRepeatPassword}
					hint={hintRepeatPassword}
				/>
			</Box>
		</fieldset>
	);
};
