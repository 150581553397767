import React from 'react';
import { Box } from '@mui/material';
import { IBajaStepOne, IBajaStepThree, IBajaStepTwo } from 'interfaces/baja/IBaja';
import { ButtonConfirm, ButtonDecline, ButtonNextPrev } from 'components/globals/customsBtn/customBtn';
import { CustomAccordion, IAccordionProps } from 'components/globals/accordion/CustomAccordion';
import { SummaryStepOne } from './../summary/SummaryStepOne';
import { SummaryStepTwo } from '../summary/SummaryStepTwo';
import { SummaryStepThree } from '../summary/SummaryStepThree';

export interface IOverviewBajaSummaryForm {
	dataStepOne: IBajaStepOne;
	dataStepTwo: IBajaStepTwo;
	dataStepThree: IBajaStepThree;
	handleSubmit: () => void;
	handleCancel: () => void;
	handleBack: () => void;
	buttonLabelCancel?: string;
	buttonLabelSubmit?: string;
	buttonLabelBack?: string;
	handleBackHome?: () => void;
	buttonLabelBackHome?: string;
	showBackHomeButton?: boolean;
}

export const BajaSummary = ({
	dataStepOne,
	dataStepTwo,
	dataStepThree,
	handleSubmit,
	handleCancel,
	handleBack,
	handleBackHome,
	buttonLabelCancel = 'Cancelar',
	buttonLabelSubmit = 'Finalizar',
	buttonLabelBack = 'Atrás',
	buttonLabelBackHome = 'Aceptar',
	showBackHomeButton = false
}: IOverviewBajaSummaryForm) => {
	const accordionData: IAccordionProps[] = [
		{
			title: 'Paso 1: Instrumento Legal',
			children: <SummaryStepOne entity={dataStepOne} />
		},
		{
			title: 'Paso 2: Documentos Adjuntos',
			children: <SummaryStepTwo entity={dataStepTwo} />
		},
		{
			title: 'Paso 3: Bienes Patrimoniales',
			children: <SummaryStepThree entity={dataStepThree} />
		}
	];

	return (
		<>
			<Box pt={1}>
				<CustomAccordion accordions={accordionData} />
			</Box>
			<Box
				sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}
				mt={2}>
				<ButtonDecline
					sx={{ mr: 1 }}
					variant="contained"
					onClick={() => {
						handleCancel();
					}}>
					{buttonLabelCancel}
				</ButtonDecline>

				<ButtonNextPrev
					sx={{ ml: 2, mr: 2 }}
					variant="contained"
					onClick={handleBack}>
					{buttonLabelBack}
				</ButtonNextPrev>

				{showBackHomeButton ? (
					<ButtonNextPrev
						sx={{ ml: 2 }}
						variant="contained"
						onClick={handleBackHome}>
						{buttonLabelBackHome}
					</ButtonNextPrev>
				) : (
					<ButtonConfirm
						sx={{ ml: 1 }}
						variant="contained"
						onClick={() => handleSubmit()}>
						{buttonLabelSubmit}
					</ButtonConfirm>
				)}
			</Box>
		</>
	);
};
