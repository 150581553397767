import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { HeaderForm } from '../../components/globals/headerForm/HeaderForm';
import { memo, useState } from 'react';
import { useAppContext } from 'hooks/useAppContext';
import { Spinner } from 'components/globals/spinner/Spinner';
import { JurisdictionsOverview } from 'pages/jurisdiction/Jurisdictions';
import { OrganizationalUnitsOverview } from 'pages/organizationalUnit/OrganizationalUnits';
import { PatrimonialDependencesOverview } from 'pages/patrimonialDependence/PatrimonialDependence';
import { PatrimonialDependenceState } from 'contexts/patrimonialDependenceContext/PatrimonialDependenceState';
import { OrganizationalUnitState } from 'contexts/organizationalUnitContext/OrganizationalUnitState';
import { JurisdictionState } from 'contexts/jurisdictionContext/JurisdictionState';

export const PARAMETERS_LABELS = {
	patrimonialDependence: {
		label: 'Dependencia Patrimonial',
		add: 'Agregar Dependencia Patrimonial',
		update: 'Editar Dependencia Patrimonial',
		delete: '¿Desea Eliminar la Dependencia Patrimonial?',
		list: 'Dependencias Patrimoniales',
		manage: 'Gestión de Dependencias Patrimoniales',
		searchLabel: 'Ingrese código o nombre de la Dependencia Patrimonial'
	},
	organizationalUnits: {
		label: 'Unidad Organizacional',
		add: 'Agregar Unidad Organizacional',
		update: 'Editar Unidad Organizacional',
		delete: '¿Desea Eliminar la Unidad Organizacional?',
		list: 'Unidades Organizacionales',
		manage: 'Gestión de Unidades Organizacionales',
		searchLabel: 'Ingrese código o nombre de la Unidad Organizacional'
	},
	jurisdictions: {
		label: 'Jurisdicción',
		add: 'Agregar Jurisdicción',
		update: 'Editar Jurisdicción',
		delete: '¿Desea Eliminar la Jurisdicción?',
		list: 'Jurisdicciones',
		manage: 'Gestión de Jurisdicciones',
		searchLabel: 'Ingrese código o nombre de la Jurisdicción'
	}
};

const ParametersPage = memo(() => {
	const appContext = useAppContext();
	/* const patrimonialDependenceContext = usePatrimonialDependenceContext();
	const organizationalUnitContext = useOrganizationalUnitContext();
	const jurisdictionContext = useJurisdictionContext(); */

	const [selectedTab, setSelectedTab] = useState('0');

	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue);
	};

	const getTitle = () => {
		let title = 'Título no definido';
		switch (selectedTab) {
			case '0': // Dependencias Patrimoniales
				title = PARAMETERS_LABELS.patrimonialDependence.manage;
				break;
			case '1': // Unidades Organizacionales
				title = PARAMETERS_LABELS.organizationalUnits.manage;
				break;
			case '2': // Jurisdicciones
				title = PARAMETERS_LABELS.jurisdictions.manage;
				break;
			default:
				break;
		}
		return title;
	};

	return (
		<>
			{appContext?.showSpinner ? <Spinner /> : null}

			<HeaderForm title={getTitle()} />

			<Box sx={{ width: '100%', typography: 'body1' }}>
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList
							onChange={handleChangeTab}
							aria-label={'Opciones para Gestión de Parámetros'}>
							{/* Dependencias Patrimoniales */}
							<Tab
								label={PARAMETERS_LABELS.patrimonialDependence.list}
								value={'0'}
							/>
							{/* Unidades Organizacionales */}
							<Tab
								label={PARAMETERS_LABELS.organizationalUnits.list}
								value={'1'}
							/>
							{/* Jurisdicciones */}
							<Tab
								label={PARAMETERS_LABELS.jurisdictions.list}
								value={'2'}
							/>
						</TabList>
					</Box>

					{/* Dependencias Patrimoniales */}
					<TabPanel
						value={'0'}
						style={{ paddingLeft: '0px', paddingRight: '0px' }}>
						<PatrimonialDependenceState>
							<PatrimonialDependencesOverview />
						</PatrimonialDependenceState>
					</TabPanel>

					{/* Unidades Organizacionales */}
					<TabPanel
						value={'1'}
						style={{ paddingLeft: '0px', paddingRight: '0px' }}>
						<OrganizationalUnitState>
							<OrganizationalUnitsOverview />
						</OrganizationalUnitState>
					</TabPanel>

					{/* Jurisdicciones */}
					<TabPanel
						value={'2'}
						style={{ paddingLeft: '0px', paddingRight: '0px' }}>
						<JurisdictionState>
							<JurisdictionsOverview />
						</JurisdictionState>
					</TabPanel>
				</TabContext>
			</Box>
		</>
	);
});

ParametersPage.displayName = 'Parámetros';

export { ParametersPage };
