import { buildDefaultOptions, handleHttpError, httpDelete, httpGet } from './httpService';

export const getFiles = async (route: string) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpGet(route, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const deleteFile = async (route: string): Promise<any> => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpDelete(route, OPTIONS);
		return response;
	} catch (error) {
		return handleHttpError(error);
	}
};
