export const styleScrollBar = {
	'*::-webkit-scrollbar': {
		width: '0.4em'
	},
	'*::-webkit-scrollbar-track': {
		WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
	},
	'*::-webkit-scrollbar-thumb': {
		backgroundColor: '#00beeec7',
		outline: '1px solid slategrey'
	}
};
