import { ReactNode, useEffect, useState } from 'react';
import { IPlanilla } from 'interfaces/bienesFiscales/IPlanilla';
import { IMeta } from 'interfaces/commons/IResponse';
import { BFContext } from './BFContext';
import { ISelectItems } from 'interfaces/bienesFiscales/IBienesTableProps';
import { getAllSheets, getAllSheetsByFilters } from 'services';
import { isArray } from 'lodash';
import { ISheetsFilters } from 'interfaces/bienesFiscales/IBFContext';

export const BFProvider = ({ children }: { children: ReactNode }) => {
	const [tabValue, setTabValue] = useState('3');
	const [planillas, setPlanillas] = useState<IPlanilla[]>([]);
	const [mesFilter, setMesFilter] = useState<number | null>(null);
	const [tipoMovFilter, setTipoMovFilter] = useState<IPlanilla['movement_type']>(0);
	const [yearFilter, setYearFilter] = useState<number | null>(null);
	const [meta, setMeta] = useState<IMeta>({ currentPage: 1, totalPages: 1, itemsPerPage: 10 } as IMeta);
	const [dependency, setDependency] = useState<ISelectItems | null>(null);
	const [limit, setLimit] = useState(10);

	useEffect(() => {
		if (mesFilter || tipoMovFilter || yearFilter || dependency || limit) {
			const filters: ISheetsFilters = {} as ISheetsFilters;
			if (mesFilter) {
				filters.month = mesFilter;
			}
			if (tipoMovFilter > 0) {
				filters.movement_type = tipoMovFilter;
			}
			if (yearFilter && String(yearFilter).length === 4) {
				filters.year = yearFilter;
			}
			if (dependency) {
				filters.patrimonial_dependence_id = dependency.value;
			}
			filters.status_id = tabValue;
			filters.page = meta.currentPage;
			filters.limit = limit;
			getAllSheetsByFilters(filters).then((res: any) => {
				if (res.data.meta) {
					setMeta(res.data.meta);
				}
				if (res.data.items && isArray(res.data.items)) {
					const newSheets: IPlanilla[] = [];
					res.data.items.forEach((item: any) => {
						const newSheet: IPlanilla = {
							id: item.id,
							patrimonial_dependency: item?.patrimonial_dependence?.patrimonial_dependence,
							patrimonial_dependency_id: item?.patrimonial_dependence?.id,
							year: item.year,
							month: item.month,
							movement_type: item.movement_type,
							jurisdiction: item?.patrimonial_dependence?.organizational?.jurisdictions?.name
						};
						newSheets.push(newSheet);
					});
					setPlanillas(newSheets);
					return;
				}
			});
			return;
		}

		getAllSheets(tabValue, meta.currentPage, meta.itemsPerPage).then((res: any) => {
			if (res?.data?.meta) {
				setMeta(res.data.meta);
			}
			if (res?.data?.items && isArray(res.data.items)) {
				const newSheets: IPlanilla[] = [];
				res.data.items.forEach((item: any) => {
					const newSheet: IPlanilla = {
						id: item.id,
						patrimonial_dependency: item?.patrimonial_dependence?.patrimonial_dependence,
						patrimonial_dependency_id: item?.patrimonial_dependence?.id,
						year: item.year,
						month: item.month,
						movement_type: item.movement_type,
						jurisdiction: item?.patrimonial_dependence?.organizational?.jurisdictions?.name
					};
					newSheets.push(newSheet);
				});
				setPlanillas(newSheets);
			}
		});
	}, [mesFilter, tipoMovFilter, yearFilter, dependency, tabValue, limit]);

	const value = {
		tabValue,
		setTabValue,
		planillas,
		setPlanillas,
		mesFilter,
		setMesFilter,
		tipoMovFilter,
		setTipoMovFilter,
		yearFilter,
		setYearFilter,
		meta,
		setMeta,
		dependency,
		setDependency,
		setLimit
	};

	return <BFContext.Provider value={value}>{children}</BFContext.Provider>;
};
