/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, SetStateAction } from 'react';
import { IStep3PropertyValues, IStep3PropertyCatastroValues } from '../interfaces';

import { Button, FormControl, Box, Typography, SelectChangeEvent } from '@mui/material';
import { InputGeneric } from 'components/globals/inputs/InputGeneric';
import { ValidateCatastroInf } from '../inmueble/validateProperty';
import useDebounce from 'hooks/useDebounce';

export interface IPaso3AutomotoresProps {
	selectedRow?: any | null;
	submited: boolean;
	dataFromChild: IStep3PropertyValues;
	setDataFromChild: (data: SetStateAction<IStep3PropertyValues>) => void;
}

export const CatastroInf = ({ selectedRow, submited, dataFromChild, setDataFromChild }: IPaso3AutomotoresProps) => {
	const [catastroData, setCatastroData] = useState<IStep3PropertyCatastroValues>({} as IStep3PropertyCatastroValues);
	const [department, setDepartment] = useState(selectedRow?.catastroValues?.department || '');
	const [municipality, setMunicipality] = useState(selectedRow?.catastroValues?.municipality || '');
	const [section, setSection] = useState(selectedRow?.catastroValues?.section || '');
	const [ranch, setRanch] = useState(selectedRow?.catastroValues?.ranch || '');
	const [apple, setApple] = useState(selectedRow?.catastroValues?.apple || '');
	const [parcel, setParcel] = useState(selectedRow?.catastroValues?.parcel || '');
	const [functionalUnity, setFunctionalUnity] = useState(selectedRow?.catastroValues?.functionalUnity || '');
	const [realStateItem, setRealStateItem] = useState(selectedRow?.catastroValues?.realStateItem || '');
	const [planeNumber, setPlaneNumber] = useState(selectedRow?.catastroValues?.planeNumber || '');

	useEffect(() => {
		if (submited) {
			setCatastroData({} as IStep3PropertyCatastroValues);
			setDepartment('');
			setMunicipality('');
			setSection('');
			setRanch('');
			setApple('');
			setParcel('');
			setFunctionalUnity('');
			setRealStateItem('');
			setPlaneNumber('');
		}
	}, [submited]);

	const handleAdd = (evt: any) => {
		evt.preventDefault();
		setCatastroData({ ...catastroData, [evt.target.name]: evt.target.value });
	};

	useEffect(() => {
		const valid = ValidateCatastroInf(catastroData);
		if (valid) {
			setDataFromChild({ ...dataFromChild, catastroValues: { ...catastroData } });
		}
	}, [catastroData]);

	useEffect(() => {
		if (selectedRow) {
			const newItem = {
				department,
				municipality,
				section,
				ranch,
				apple,
				parcel,
				functionalUnity,
				realStateItem,
				planeNumber
			};
			setCatastroData(newItem);
		}
	}, [selectedRow]);

	return (
		<div className="borderedBox">
			<Typography
				sx={{ mb: 1, mt: 1 }}
				variant="h6">
				Información de Catastro
			</Typography>
			<Box
				onChange={handleAdd}
				sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '55px', rowGap: '55px' }}>
				<InputGeneric
					stateValue={department}
					label="Departamento"
					name="department"
					setStateValue={setDepartment}
					required={true}
					pattern={/^[0-9]*$/g}
				/>
				<InputGeneric
					stateValue={municipality}
					label="Municipio"
					name="municipality"
					setStateValue={setMunicipality}
					required={true}
					pattern={/^[0-9]*$/g}
				/>
				<InputGeneric
					stateValue={section}
					label="Sección"
					name="section"
					setStateValue={setSection}
					required={true}
					pattern={/^[0-9]*$/g}
				/>
				<InputGeneric
					stateValue={ranch}
					label="Chacra"
					name="ranch"
					setStateValue={setRanch}
					required={true}
					pattern={/^[0-9]*$/g}
				/>
				<InputGeneric
					stateValue={apple}
					label="Manzana"
					name="apple"
					setStateValue={setApple}
					required={true}
					pattern={/^[0-9]*$/g}
				/>
				<InputGeneric
					stateValue={parcel}
					label="Parcela"
					name="parcel"
					setStateValue={setParcel}
					required={true}
					pattern={/^[0-9]*$/g}
				/>
				<InputGeneric
					stateValue={functionalUnity}
					label="Unidad Funcional"
					name="functionalUnity"
					setStateValue={setFunctionalUnity}
					pattern={/^[0-9]*$/g}
				/>
				<InputGeneric
					stateValue={realStateItem}
					label="Partida Inmobiliaria"
					name="realStateItem"
					setStateValue={setRealStateItem}
					required={true}
					pattern={/^[0-9]*$/g}
				/>
				<InputGeneric
					stateValue={planeNumber}
					label="Número de Plano"
					name="planeNumber"
					setStateValue={setPlaneNumber}
					pattern={/^[0-9]*$/g}
				/>
			</Box>
		</div>
	);
};
