import { ApiPaths } from 'enums/apiPaths';
import apiInstance from 'interceptors/axiosInterceptor';
import { IAltaResponse, IAltaStepOne, IAltaStepTwo } from 'interfaces/alta/IAlta';
import { IResponseError } from 'interfaces/commons/IResponse';
import { Attachment } from 'interfaces/file/IFile';
import { buildDefaultOptions, handleHttpError, httpDelete, httpGet, httpPatch, httpPost } from './httpService';

const URL_BASE = `${ApiPaths.REGISTRATION}`;
const getURLById = (id: number) => {
	return `${URL_BASE}/${id}`;
};
const getURLFinalice = (id: number) => {
	return `${URL_BASE}/${id}/complete`;
};
const getURLToPatch = (id: number) => {
	return `${URL_BASE}/${id}/reception`;
};
const getURLLegalInstrumentToPatch = (id: number) => {
	return `${URL_BASE}/${id}/legal-instrument`;
};

// START Create/Update Registration Data
export const tryCreateOrUpdateRegistration = async (entity: IAltaStepOne): Promise<IAltaResponse | IResponseError> => {
	const uploadSingleFile = async (documentId: number, file: File, name: string): Promise<unknown> => {
		const formData = new FormData();
		const fileWithFormatedExtension = new File([file], file.name.toLocaleLowerCase());
		formData.append(name, fileWithFormatedExtension);
		return apiInstance.post(`/property-registration-files/${documentId}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	};

	try {
		const OPTIONS = buildDefaultOptions();
		const { id: documentId, voucherFile, legalInstrumentFile, ...payload } = entity;

		const { data } = documentId
			? await httpPatch(`${URL_BASE}/${documentId}/reception`, { ...payload }, OPTIONS)
			: await httpPost(`${URL_BASE}/reception`, { ...payload }, OPTIONS);

		if (voucherFile) await uploadSingleFile(data.id, voucherFile, 'reception');
		if (legalInstrumentFile) await uploadSingleFile(data.id, legalInstrumentFile, 'timeless_justification');

		return data;
	} catch (error) {
		return handleHttpError(error);
	}
};

// END Create/Update Registration Data

// START Create Registration Data to Legal Instrument
export const tryCreateOrUpdateRegistrationLegalInstrument = async (entity: IAltaStepTwo) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const { id, files, hasErrors, ...data } = entity;

		const response = await httpPatch(getURLLegalInstrumentToPatch(id ? id : 0), data, OPTIONS);
		const propertyId = response.data.id;

		if (files?.length) {
			const formData = new FormData();
			files.forEach((file) => formData.append('attachments', new File([file], file.name.toLocaleLowerCase())));
			await apiInstance.post(`/property-registration-files/${propertyId}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		}
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};
// END Create Registration Data to Legal Instrument

// START Finalice
export const tryFinalice = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpPatch(getURLFinalice(id), null, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};
// END Finalice

export const tryGetData = async (id: number): Promise<any> => {
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpGet(getURLById(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

// START Delete
export const tryDelete = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpDelete(getURLById(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};
// END Delete

// START Create/Update Registration Data
export const tryCreateOrEditItem = async (entity: any): Promise<any> => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response;
		// TODO Se debe complementar este servicio con el EP correspondiente para crear/actualizar un bien patrimonial
		if (entity.id === undefined) {
			response = await httpPost(`${URL_BASE}/reception`, entity, OPTIONS);
		} else {
			response = await httpPatch(getURLToPatch(entity.id), entity, OPTIONS);
		}

		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};
// END Create/Update Registration Data

// START Delete Item to Propertie
export const tryDeleteItem = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		// TODO Aquí se debe incorporar EP para remover bien patrimonial
		const response = await httpDelete(getURLFinalice(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};
// END Delete Item to Propertie

export const tryGetAllItems = async (id: number): Promise<any> => {
	try {
		const OPTIONS = buildDefaultOptions();
		// TODO Aquí se debe incorporar EP para recuperar todos los bienes patrimoniales para un Alta
		const response = await httpGet(getURLById(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const getPropertyItems = async (documentId: number) => {
	const response = await getPropertyData(documentId);
	return response.properties_items.map((property: any) => ({
		...property,
		details: { ...property.details.data },
		children: property.children?.map((el: any) => ({ ...el, details: { ...el.details.data } }))
	}));
};

export const getPropertyData = async (documentId: number) => {
	const response = await apiInstance.get(`/property-registration/${documentId}`);
	return response.data;
};

const ADD_ITEM_ERROR_MSG = 'Ocurrio un error al añadir el bien.';
const EDIT_ITEM_ERROR_MSG = 'Ocurrio un error inesperado al editar el bien.';

export interface GenericItemPayload {
	id?: number;
	is_quantifiable: boolean;
	account_nomenclature_id?: number;
	is_accessory: boolean;
	quantity: number;
	discharge_date: string;
	unit_price: number;
	description: string;
	property_type_id: number;
	details?: Record<string, unknown>;
	parent_property_id?: number;
	high_type_id?: number;
	row_number?: number;
}

export const saveGenericItem = async (documentId: number, data: GenericItemPayload): Promise<{ error?: string }> => {
	try {
		await apiInstance.post(`/properties/property-registration/${documentId}`, data);

		return {};
	} catch (e: any) {
		// return { error: ADD_ITEM_ERROR_MSG };
		return { error: e.response.data.message[0] ?? ADD_ITEM_ERROR_MSG };
	}
};

export interface InmueblePayload {
	id?: number;
	account_nomenclature_id: number;
	description?: string;
	discharge_date: string;
	is_accessory: boolean;
	is_quantifiable: boolean;
	property_type_id: number;
	quantity: number;
	unit_price: number;
	details: {
		address: string;
		cadastral_information: {
			department: {
				id: number;
				name: string;
				department_id: number;
			};
			location: {
				id: number;
				name: string;
				location_id: number;
				department_id: string;
			};
			section: string;
			farm: string;
			street: string;
			parcel: string;
			functional_unit: string;
			real_estate_item: string;
			plane_number: string;
		};
		registry_information: {
			department: string;
			registration_number: string;
			took: string;
			invoice: string;
			farm: string;
			property_description: string;
			owner: string;
			observations: string;
		};
		property_title: boolean;
		title_name_patrimonial_unit: boolean;
	};
	files: File[];
	parent_property_id?: number;
	high_type_id?: number;
	row_number?: number;
}

export const saveInmuebleItem = async (documentId: number, data: InmueblePayload): Promise<{ error?: string }> => {
	try {
		const { files, ...rest } = data;
		const response = await apiInstance.post(`/properties/property-registration/${documentId}`, rest);
		const propertyId = response.data.id;

		if (files.length) {
			const formData = new FormData();
			files.forEach((file) => formData.append('attachments', new File([file], file.name.toLocaleLowerCase())));
			await apiInstance.post(`/properties-files/${propertyId}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		}

		return {};
	} catch (e: any) {
		// return { error: ADD_ITEM_ERROR_MSG };
		return { error: e.response.data.message[0] ?? ADD_ITEM_ERROR_MSG };
	}
};

export const editInmuebleItem = async (documentId: number, data: InmueblePayload): Promise<{ error?: string }> => {
	try {
		const { id, files, ...rest } = data;
		const response = await apiInstance.put(`/properties/${documentId}/property-registration/${id}`, rest);
		const propertyId = response.data.id;

		if (files.length) {
			const formData = new FormData();
			files.forEach((file) => formData.append('attachments', new File([file], file.name.toLocaleLowerCase())));
			await apiInstance.post(`/properties-files/${propertyId}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		}
		return {};
	} catch (e) {
		return { error: EDIT_ITEM_ERROR_MSG };
	}
};

export const editGernericItem = async (documentId: number, data: GenericItemPayload) => {
	try {
		const { id, ...rest } = data;
		await apiInstance.put(`/properties/${documentId}/property-registration/${id}`, rest);

		return {};
	} catch (e) {
		return { error: EDIT_ITEM_ERROR_MSG };
	}
};

export interface VehiclePayload {
	id?: number;
	account_nomenclature_id: number;
	is_quantifiable: boolean;
	is_accessory: boolean;
	quantity: number;
	discharge_date?: string;
	unit_price: number;
	description?: string;
	property_type_id: number;
	property_sub_type_id: number;
	details: Record<string, unknown>;
	parent_property_id?: number;
	files: File[];
	high_type_id?: number;
	row_number?: number;
}

export const saveVehicleItem = async (documentId: number, data: VehiclePayload) => {
	try {
		const { files, ...rest } = data;
		const response = await apiInstance.post(`/properties/property-registration/${documentId}`, rest);
		const propertyId = response.data.id;

		if (files.length && propertyId) {
			try {
				const formData = new FormData();
				files.forEach((file) => formData.append('attachments', new File([file], file.name.toLocaleLowerCase())));
				await apiInstance.post(`/properties-files/${propertyId}`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				});
			} catch {
				return { error: 'Ocurrio error al adjuntar el archivo del bien.' };
			}
		}
		return {};
	} catch (e: any) {
		return { error: e.response.data.message[0] ?? ADD_ITEM_ERROR_MSG };
	}
};

export const editVehicleItem = async (documentId: number, data: VehiclePayload): Promise<{ error?: string }> => {
	try {
		const { id, files, ...rest } = data;
		const response = await apiInstance.put(`/properties/${documentId}/property-registration/${id}`, rest);
		const propertyId = response.data.id;

		if (files.length) {
			const formData = new FormData();
			files.forEach((file) => formData.append('attachments', new File([file], file.name.toLocaleLowerCase())));
			await apiInstance.post(`/properties-files/${propertyId}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		}
		return {};
	} catch (e) {
		return { error: EDIT_ITEM_ERROR_MSG };
	}
};

export const deletePropertyItem = async ({
	propertyRegistrationId,
	propertyId
}: {
	propertyRegistrationId: number;
	propertyId: number;
}) => {
	return apiInstance.delete(`/properties/${propertyRegistrationId}/property-registration/${propertyId}`);
};

export const cancelPropertyRegistration = (id: number) => {
	return apiInstance.delete(`/property-registration/${id}/cancel`);
};
export interface DocumentFile {
	id: number;
	name: string;
	file_type_id: number;
	property_registration_id: number;
	size: number;
}

export const getFilesByDocumentId = async (id: number): Promise<Attachment[]> => {
	const response = await apiInstance.get(`${URL_BASE}/${id}`);
	return response.data.files.map((el: DocumentFile) => ({ ...el, file: undefined }));
};

export const getLastPropertyRegistered = async () => {
	return (await apiInstance.get('/property-movements/property-registration/last')).data;
};

export const getLastVerifiedSheet = async ({ year, month }: { year: number; month: number }) => {
	return (await apiInstance.get('/sheets/verifity-send', { params: { year, month } })).data;
};
