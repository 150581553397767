import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { getPropertyMovementObservationHistory } from 'services/getPropertyMovementObservationHistory';

interface PropertyMovementTimelineProps {
	movementId?: number;
}

export default function PropertyMovementTimeline({ movementId }: PropertyMovementTimelineProps) {
	const [data, setData] = React.useState<any[]>([]);
	const [isLoading, setIsLoading] = React.useState(false);

	React.useEffect(() => {
		(async function () {
			try {
				if (!movementId) return;
				setIsLoading(true);
				const response = await getPropertyMovementObservationHistory(movementId);
				setData(response);
			} finally {
				setIsLoading(false);
			}
		})();
	}, []);

	return (
		<Timeline sx={{ maxHeight: '600px', pt: 2, display: 'flex', flexDirection: 'column' }}>
			{data.length ? (
				data.map((item, indx) => {
					console.log(item);

					return (
						<TimelineItem
							key={indx}
							sx={{ minHeight: 'auto' }}>
							<TimelineOppositeContent
								fontWeight={600}
								color="black">
								{item.date}
							</TimelineOppositeContent>
							<TimelineSeparator color="primary">
								<TimelineDot color="primary" />
								<TimelineConnector />
							</TimelineSeparator>
							<TimelineContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
								<Box>
									<Typography
										variant="h6"
										fontSize={18}
										component="span">
										Estado:
									</Typography>
									<Typography>{item.movementStatusName}</Typography>
								</Box>
								<Box>
									<Typography
										variant="h6"
										fontSize={18}
										component="span">
										Usuario:
									</Typography>
									<Typography>{item.userName}</Typography>
								</Box>
								<Box>
									<Typography
										variant="h6"
										fontSize={18}
										component="span">
										Observaciones:
									</Typography>
									<Typography>
										{console.log('observacion', item.observation)}
										{item.observation !== 'null' && item.observation !== null ? item.observation : '-'}
									</Typography>
								</Box>
							</TimelineContent>
						</TimelineItem>
					);
				})
			) : !isLoading ? (
				<Box>El movimiento no posee historial de observaciones.</Box>
			) : (
				'Cargando información...'
			)}
		</Timeline>
	);
}
