/* import { IMovContextData } from 'interfaces/movements/IMovTableProps'; */
import { IMovContextData } from 'interfaces/movements';
import { ItemType } from 'enums';

import {
	IMovItemGeneric,
	IMovItemVehicleAirplane,
	IMovItemVehicleCar,
	IMovItemVehicleMachine,
	IMovItemVehicleShip,
	IMovItemProperty,
	IMovItemAccessory
} from 'interfaces/movements/tableItems/tableItems';

const searchPropertyTitle = (uuid: string, movContext: IMovContextData) => {
	const searchUuid = movContext?.editMovement?.property_registration?.files?.find((file: any) => file.temporary_id === uuid);
	if (searchUuid) {
		return searchUuid.temporary_id;
	}
	return '';
};

export const formatDataForTable = (movContext: IMovContextData) => {
	if (!movContext?.editMovement?.property_registration) return;
	if (movContext?.editMovement?.property_registration?.properties_items) {
		const items = movContext?.editMovement?.property_registration?.properties_items;
		if (items && items.length > 0) {
			const rightListFromBackend: any[] = [];
			const accessoriesArray: any[] = [];
			items.map((item: any) => {
				// Accesorios
				if (item.is_accessory) {
					const date = new Date();
					const accessory = {
						id: item.temporary_id,
						temporary_id: item.temporary_id,
						accessory_number: item.id,
						idInDatabase: item.id,
						rightType: 'Genérico',
						nomenclatureCode: item.nomenclator_code,
						description: item.description,
						quantity: item.quantity,
						unitPrice: item.unit_price,
						parent_temporary_id: item.parent_property_temporary_id,
						discharge_date: date.toISOString(),
						is_accessory: item.is_accessory
					};
					accessoriesArray.push(accessory);
				}
				// Genericos
				if (item.property_type_id === ItemType.Generico) {
					const generic: IMovItemGeneric = {
						id: item.temporary_id,
						idInDatabase: item.id,
						rightType: 'Genérico',
						nomenclatureCode: item.nomenclator_code,
						description: item.description,
						quantity: item.quantity,
						unitPrice: item.unit_price,
						inventoriable: item.is_quantifiable
					};
					rightListFromBackend.push(generic);
				}
				// Vehiculos
				// Automotores o Motos
				if (item.property_type_id === ItemType.Automotor || item.property_type_id === ItemType.Motocicleta) {
					const automotor: IMovItemVehicleCar = {
						id: item.temporary_id,
						idInDatabase: item.id,
						rightType: 'Vehículo',
						vehicleType: 'Automotores',
						nomenclatureCode: item.nomenclator_code,
						quantity: item.quantity,
						unitPrice: item.unit_price,
						domainRegistartion: item.property_detail?.domain,
						engineNumber: item.property_detail?.engine_number,
						chassisNumber: item.property_detail?.chassis_number,
						brand: item.property_detail?.brand,
						model: item.property_detail?.model,
						year: item.property_detail?.year,
						origin: item.property_detail?.origin,
						owner: item.property_detail?.headline,
						type: item.property_detail?.type
					};
					automotor.propertyTitle = searchPropertyTitle(item.temporary_id, movContext);

					if (item.property_type_id === ItemType.Motocicleta) {
						automotor.vehicleType = 'Motovehículos';
						automotor.bikeFrameNumber = item.property_detail?.frame_number;
					}
					rightListFromBackend.push(automotor);
				}
				// Barcos
				if (item.property_type_id === ItemType.Embarcación) {
					const boat: IMovItemVehicleShip = {
						id: item.temporary_id,
						idInDatabase: item.id,
						rightType: 'Vehículo',
						vehicleType: 'Embarcaciones',
						nomenclatureCode: item.nomenclator_code,
						quantity: item.quantity,
						unitPrice: item.unit_price,
						domainRegistartion: item.property_detail?.registration,
						boatName: item.property_detail?.ship_name,
						type: item.property_detail?.type,
						boatHullNumber: item.property_detail?.helmet_number,
						modelHull: item.property_detail?.helmet_model,
						owner: item.property_detail?.headline,
						engineBrandName: item.property_detail?.engine_brand,
						engineNumber: item.property_detail?.engine_number,
						engineOwner: item.property_detail?.engine_holder,
						engineModelSerie: item.property_detail?.model_series,
						dateOfConstruction: new Date(item.property_detail?.manufacturing_date)
					};
					rightListFromBackend.push(boat);
				}
				// Aeronaves
				if (item.property_type_id === ItemType.Aeronave) {
					const airplane: IMovItemVehicleAirplane = {
						id: item.temporary_id,
						idInDatabase: item.id,
						rightType: 'Vehículo',
						vehicleType: 'Aeronaves',
						nomenclatureCode: item.nomenclator_code,
						quantity: item.quantity,
						unitPrice: item.unit_price,
						domainRegistartion: item.property_detail?.registration,
						brand: item.property_detail?.brand,
						model: item.property_detail?.model,
						serialNumber: item.property_detail?.serial_number,
						type: item.property_detail?.type,
						dateOfConstruction: new Date(item.property_detail?.manufacturing_date)
					};
					rightListFromBackend.push(airplane);
				}
				// Maquinaria
				if (item.property_type_id === ItemType.Máquinas) {
					const machine: IMovItemVehicleMachine = {
						id: item.temporary_id,
						idInDatabase: item.id,
						rightType: 'Vehículo',
						vehicleType: 'Máquinas',
						nomenclatureCode: item.nomenclator_code,
						quantity: item.quantity,
						unitPrice: item.unit_price,
						domainRegistartion: item.property_detail?.domain,
						engineNumber: item.property_detail?.engine_number,
						model: item.property_detail?.model,
						origin: item.property_detail?.origin,
						type: item.property_detail?.type,
						owner: item.property_detail?.headline,
						brand: item.property_detail?.brand,
						year: item.property_detail?.year,
						chassisNumber: item.property_detail?.chassis_number,
						propertyTitle: searchPropertyTitle(item.temporary_id, movContext)
					};
					rightListFromBackend.push(machine);
				}
				if (item.property_type_id === ItemType.Propiedad) {
					const property: IMovItemProperty = {
						id: item.temporary_id,
						idInDatabase: item.id,
						rightType: 'Inmueble',
						nomenclatureCode: item.nomenclator_code,
						quantity: item.quantity,
						unitPrice: item.unit_price,
						address: item.property_detail?.address,
						propertyTitle: item.property_detail?.property_title,
						titleOfPatrimonialDependency: item.property_detail?.title_name_patrimonial_unit,
						catastroValues: {
							department: item.property_detail?.cadastral_information?.department,
							municipality: item.property_detail?.cadastral_information?.municipality,
							section: item.property_detail?.cadastral_information?.section,
							ranch: item.property_detail?.cadastral_information?.farm,
							apple: item.property_detail?.cadastral_information?.street,
							parcel: item.property_detail?.cadastral_information?.parcel,
							functionalUnity: item.property_detail?.cadastral_information?.functional_unit,
							realStateItem: item.property_detail?.cadastral_information?.real_state_item,
							planeNumber: item.property_detail?.cadastral_information?.plane_number
						},
						registerInformation: {
							department: item.property_detail?.registry_information?.department,
							domainNumber: item.property_detail?.registry_information?.registration_number,
							volumeFolder: item.property_detail?.registry_information?.took,
							folioFolder: item.property_detail?.registry_information?.invoice,
							estate: item.property_detail?.registry_information?.farm,
							propertyDescription: item.property_detail?.registry_information?.property_description,
							propertyOwner: item.property_detail?.registry_information?.owner,
							observations: item.property_detail?.registry_information?.observations
						}
					};
					rightListFromBackend.push(property);
				}
			});
			if (rightListFromBackend.length > 0) {
				rightListFromBackend.map((item) => {
					const accessories = accessoriesArray.filter((accessory) => accessory.parent_temporary_id === item.id);
					if (accessories.length > 0) {
						item.accessories = accessories;
					}
				});
			}
			return rightListFromBackend;
		}
	}
};
