/* eslint-disable max-lines */
import { ReactNode, useEffect, useState } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { RegistrationContext } from './RegistrationContext';
import { toast } from 'react-toastify';
import { useAppContext } from 'hooks/useAppContext';
import {
	IAltaInstrument,
	IAltaProcedure,
	IAltaProvisionOrder,
	IAltaResponse,
	IAltaStepOne,
	IAltaStepThree,
	IAltaStepTwo
} from 'interfaces/alta/IAlta';
import {
	tryCreateOrEditItem,
	tryCreateOrUpdateRegistration,
	tryCreateOrUpdateRegistrationLegalInstrument,
	tryDelete,
	tryDeleteItem,
	tryFinalice,
	tryGetAllItems,
	tryGetData
} from 'services/altaService';
import { captureMessageError } from 'services/httpService';
import apiInstance from 'interceptors/axiosInterceptor';
import { IFileProgress } from 'interfaces/file/IFile';
import { FileType } from 'enums';
import { deleteFile } from 'services/fileManageService';
import { ISelectOption } from 'interfaces/commons/IParameters';

const LOCAL_STORAGE_STEP_ONE = 'STORAGED_REGISTER_STEP_ONE';
const LOCAL_STORAGE_STEP_TWO = 'STORAGED_REGISTER_STEP_TWO';
const LOCAL_STORAGE_STEP_THREE = 'STORAGED_REGISTER_STEP_THREE';
const STORAGED_REGISTER_ACTIVE_STEP = 'STORAGED_REGISTER_ACTIVE_STEP';

export const RegistrationState = ({ children }: { children: ReactNode | ReactJSXElement }) => {
	const contextoX = useAppContext();
	const [dataStepOne, setDataStepOne] = useState<IAltaStepOne | undefined>(undefined);
	const [dataStepTwo, setDataStepTwo] = useState<IAltaStepTwo | undefined>(undefined);
	const [dataStepThree, setDataStepThree] = useState<IAltaStepThree | undefined>(undefined);
	const [activeStep, setActiveStep] = useState<number>(0);
	const [selectedItemType, setSelectedItemType] = useState<ISelectOption | null>(null);

	useEffect(() => {
		const storagedDataStepOne = localStorage.getItem(LOCAL_STORAGE_STEP_ONE);
		const dataStepOneStoraged = storagedDataStepOne !== null ? JSON.parse(storagedDataStepOne) : null;
		if (dataStepOneStoraged !== null) {
			setDataStepOne(dataStepOneStoraged);
		}

		const storagedDataStepTwo = localStorage.getItem(LOCAL_STORAGE_STEP_TWO);
		const dataStepTwoStoraged = storagedDataStepTwo !== null ? JSON.parse(storagedDataStepTwo) : null;
		if (dataStepTwoStoraged !== null) {
			setDataStepTwo(dataStepTwoStoraged);
		}

		const storagedDataStepThree = localStorage.getItem(LOCAL_STORAGE_STEP_THREE);
		const dataStepThreeStoraged = storagedDataStepThree !== null ? JSON.parse(storagedDataStepThree) : null;
		if (dataStepThreeStoraged !== null) {
			setDataStepThree(dataStepThreeStoraged);
		}

		const storagedActiveStep = localStorage.getItem(STORAGED_REGISTER_ACTIVE_STEP);
		const activeStepStoraged = storagedActiveStep !== null ? parseInt(storagedActiveStep) : 0;
		setActiveStep(activeStepStoraged);
	}, []);

	useEffect(() => {
		selectedItemType && localStorage.setItem('SELECTED_ITEM_TYPE', JSON.stringify(selectedItemType));

		return () => {
			localStorage.removeItem('SELECTED_ITEM_TYPE');
		};
	}, [selectedItemType]);

	const updateSelectedItemType = (val: ISelectOption) => {
		setSelectedItemType(val);
	};

	const parseResponseDataStepOne = (data?: IAltaResponse): IAltaStepOne | undefined => {
		if (!data) return;

		return {
			high_type_id: data?.high_type_id,
			date_donation: data?.date_donation,
			date_legal_instrument: data?.date_legal_instrument,
			date_transfer: data?.date_transfer,
			date_voucher: data?.date_voucher,
			description: data?.description,
			donor_address: data?.donor_address,
			donor_name: data?.donor_name,
			id: data?.id,
			invoice_number: data?.invoice_number,
			property_type_id: data?.property_type_id,
			single_tax_id: data?.single_tax_id,
			to_patrimonial_dependence_id: data?.to_patrimonial_dependence_id,
			voucher_type_id: data?.voucher_type_id,
			voucherFile: undefined,
			legalInstrumentFile: undefined,
			hasErrors: false,
			afip_data: data.afip_data,
			minute_number: data?.minute_number
		} as IAltaStepOne;
	};

	const parseResponseDataStepTwo = (data: IAltaResponse): IAltaStepTwo | undefined => {
		if (!data) {
			return undefined;
		}

		return {
			id: data.id,
			files: [],
			procedure: {
				agency_code: data?.proceedings_number?.agency_code,
				number: data?.proceedings_number?.number,
				year: data?.proceedings_number?.year
			} as IAltaProcedure,
			instrument: {
				instrument_number: data?.legal_instrument?.instrument_number,
				instrument_year: data?.legal_instrument?.instrument_year,
				instrument_type: data.instrument_type
			} as IAltaInstrument,
			provision: {
				provision_number: data?.provision_order?.provision_number,
				provision_year: data?.provision_order?.provision_year
			} as IAltaProvisionOrder,
			contract_type: data.contract_type
		} as IAltaStepTwo;
	};

	const onHandleDataStepOne = async (data: IAltaStepOne | undefined) => {
		if (!data) {
			toast.error(
				'Para avanzar al siguiente paso debe indicar un resgitro de Alta existente o bien rellenar el formulario!'
			);
			return false;
		}
		contextoX?.onHandleShowSpinner();
		const response = await tryCreateOrUpdateRegistration({ ...data, id: dataStepOne?.id });
		contextoX?.onHandleHideSpinner();

		const defaultMessageError = captureMessageError(
			response,
			'Ocurrio un error al intentar guardar los datos de la alta patrimonial!'
		);

		if (defaultMessageError.hasError) {
			const stepOneErrors = {
				single_tax_id: 'Número de CUIT/CUIL',
				invoice_number: 'Número de factura',
				high_type_id: 'Tipo de Alta',
				voucher_type_id: 'Tipo de Comprobante',
				date_voucher: 'Fecha del Comprobante',
				date_donation: 'Fecha de Donación',
				date_transfer: 'Fecha de Transferencia',
				to_patrimonial_dependence_id: 'Dependencia Patrimonial de Destino',
				description: 'Descripción',
				property_type_id: 'Tipo de Bien'
			};

			const [key, ...restMsg] = defaultMessageError.message.split(' ');
			let restMsgText = restMsg.join(' ');

			if (restMsgText === 'must be a valid ISO 8601 date string!') restMsgText = 'es requerido!';

			if (key && stepOneErrors[key as keyof typeof stepOneErrors])
				toast.error(`${stepOneErrors[key as keyof typeof stepOneErrors]} ${restMsgText}`);
			else toast.error('Ocurrio un error al intentar guardar los datos de la alta patrimonial!');

			return false;
		}
		const parsed: IAltaResponse = response as IAltaResponse;
		const id: number | null = parsed.id || null;
		await onHandleGetAllData(id);

		toast.success('Datos de la Alta Patrimonial en el paso 1 guardados.');
		changeActiveStep(activeStep + 1);
		return true;
	};

	const onHandleDataStepTwo = async (data: IAltaStepTwo | undefined) => {
		if (!data) {
			toast.error(
				'Para avanzar al siguiente paso debe indicar un resgitro de Alta existente o bien rellenar el formulario!'
			);
			return false;
		}
		contextoX?.onHandleShowSpinner();
		const response = await tryCreateOrUpdateRegistrationLegalInstrument(data);
		contextoX?.onHandleHideSpinner();

		const defaultMessageError = captureMessageError(
			response,
			'Ocurrio un error al intentar guardar los datos de la alta patrimonial!'
		);
		if (defaultMessageError.hasError) {
			toast.error(defaultMessageError.message);
			return false;
		}

		const parsedData = parseResponseDataStepTwo(response);

		changeDataStepTwo(parsedData !== undefined ? parsedData : data);
		toast.success('Datos de la Alta Patrimonial en el paso 2 guardados.');
		changeActiveStep(activeStep + 1);
		return true;
	};

	const onHandleGetAllData = async (id: number | null) => {
		if (id == null) {
			toast.error('Para editar una alta patrimonial es necesario que indique una!');
			return false;
		}
		changeDataStepOne(undefined);
		changeDataStepTwo(undefined);
		changeDataStepThree(undefined);
		contextoX?.onHandleShowSpinner();
		const response = await tryGetData(id);
		contextoX?.onHandleHideSpinner();

		const defaultMessageError = captureMessageError(
			response,
			'Ocurrio un error al intentar recuperar los datos de la alta patrimonial!'
		);
		if (defaultMessageError.hasError) {
			toast.error(defaultMessageError.message);
			return false;
		}

		const parsedDataStepOne = parseResponseDataStepOne(response);
		changeDataStepOne(parsedDataStepOne);
		const parsedDataStepTwo = parseResponseDataStepTwo(response);
		changeDataStepTwo(parsedDataStepTwo);
		changeDataStepThree({ id: response.id, high_type_id: response.high_type_id } as IAltaStepThree);
		return true;
	};

	const changeDataStepOne = (data: IAltaStepOne | undefined) => {
		if (data) {
			localStorage.setItem(LOCAL_STORAGE_STEP_ONE, JSON.stringify(data));
		}
		setDataStepOne(data);
	};

	const changeDataStepTwo = (data: IAltaStepTwo | undefined) => {
		if (data) {
			localStorage.setItem(LOCAL_STORAGE_STEP_TWO, JSON.stringify(data));
		}
		setDataStepTwo(data);
		return true;
	};

	const changeDataStepThree = (data: IAltaStepThree | undefined) => {
		if (data) {
			localStorage.setItem(LOCAL_STORAGE_STEP_THREE, JSON.stringify(data));
		}
		setDataStepThree(data);
		return true;
	};

	const changeActiveStep = (index: number) => {
		localStorage.removeItem(STORAGED_REGISTER_ACTIVE_STEP);
		localStorage.setItem(STORAGED_REGISTER_ACTIVE_STEP, index.toString());
		setActiveStep(index);
	};

	// START Finalice Registration
	const onHandleFinalice = async (id: number | null) => {
		if (id === null) {
			toast.error('Para poder finalizar el proceso debe indicar un registro de alta existente!');
			return false;
		}
		contextoX?.onHandleShowSpinner();
		await tryFinalice(id);
		contextoX?.onHandleHideSpinner();

		localStorage.removeItem('STORAGED_REGISTER_STEP_ONE');
		localStorage.removeItem('STORAGED_REGISTER_STEP_TWO');
		localStorage.removeItem('STORAGED_REGISTER_STEP_THREE');

		toast.success('Guardado correctamente. A la espera de confirmación por el Encargado Patrimonial.');
		return true;
	};
	// END Finalice Registration

	// START Delete Registration Movement
	const onHandleDelete = async (id: number | null) => {
		if (id === null) {
			toast.error('Para poder eliminar un movimiento debe indicar un registro existente!');
			return false;
		}
		contextoX?.onHandleShowSpinner();
		const response = await tryDelete(id);
		contextoX?.onHandleHideSpinner();

		const defaultMessageError = captureMessageError(
			response,
			'No se ha podido eliminar la baja patrimonial, inténtalo más tarde!'
		);
		if (defaultMessageError.hasError) {
			toast.error(defaultMessageError.message);
			return false;
		}

		toast.success('Alta patrimonial eliminada exitosamente.');
		return true;
	};
	// END Delete Registration Movement

	const onHandleDeleteFile = async (file: any | null, step: number) => {
		if (!file) {
			toast.error('Para eliminar un documento adjunto debe indicar uno!');
			return false;
		}
		contextoX?.onHandleShowSpinner();
		const response = await deleteFile(`properties-files/upload/${file?.id}`);
		contextoX?.onHandleHideSpinner();

		if (response?.status !== 200) {
			toast.error(response?.message);
			return false;
		}

		switch (step) {
			case 1:
				await onHandleGetFilesStepOne(dataStepOne?.id ? dataStepOne?.id : null, false);
				break;
			case 2:
				await onHandleGetFilesStepTwo(dataStepTwo?.id ? dataStepTwo?.id : null, false);
				break;
			default:
				break;
		}

		toast.success('Documento Adjunto eliminado!');
		return response;
	};

	const onHandleGetFilesStepOne = async (id: number | null, showAlerts: boolean) => {
		if (id == null) {
			if (showAlerts) {
				toast.error(
					'Para recuperar los archivos adjuntos del Paso I de una alta patrimonial es necesario que indique una!'
				);
			} else {
				console.error(
					'Para recuperar los archivos adjuntos del Paso I de una alta patrimonial es necesario que indique una!'
				);
			}
			return false;
		}

		if (showAlerts) {
			contextoX?.onHandleShowSpinner();
		}
		const response = await tryGetData(id);
		if (showAlerts) {
			contextoX?.onHandleHideSpinner();
		}

		const defaultMessageError = captureMessageError(
			response,
			'Ocurrio un error al intentar recuperar los datos de la alta patrimonial!'
		);
		if (defaultMessageError.hasError) {
			if (showAlerts) {
				toast.error(defaultMessageError.message);
			} else {
				console.error(defaultMessageError.message);
			}
			return false;
		}

		const voucherAttached: File | undefined = response.files.find((sf: any) => {
			return sf.file_type_id == FileType.Factura_y_o_Remito;
		});
		const legalInstrumentAttached: File | undefined = response.files.find((sf: any) => {
			return sf.file_type_id == FileType.Resolución_o_Disposición;
		});

		changeDataStepOne({
			...dataStepOne,
			voucherFile: voucherAttached,
			legalInstrumentFile: legalInstrumentAttached
		} as IAltaStepOne);
		return true;
	};

	const onHandleGetFilesStepTwo = async (id: number | null, showAlerts: boolean) => {
		if (id == null) {
			if (showAlerts) {
				toast.error(
					'Para recuperar los archivos adjuntos del Paso II de una alta patrimonial es necesario que indique una!'
				);
			} else {
				console.error(
					'Para recuperar los archivos adjuntos del Paso II de una alta patrimonial es necesario que indique una!'
				);
			}
			return false;
		}
		if (showAlerts) {
			contextoX?.onHandleShowSpinner();
		}
		const response = await tryGetData(id);
		if (showAlerts) {
			contextoX?.onHandleHideSpinner();
		}

		const defaultMessageError = captureMessageError(
			response,
			'Ocurrio un error al intentar recuperar los datos de la alta patrimonial!'
		);
		if (defaultMessageError.hasError) {
			if (showAlerts) {
				toast.error(defaultMessageError.message);
			} else {
				console.error(defaultMessageError.message);
			}
			return false;
		}

		changeDataStepTwo({
			...dataStepTwo,
			files: []
		} as IAltaStepTwo);
		return true;
	};

	// END File Manager

	// START Add or Edit Item to Properties
	const onHandleAddOrEditItem = async (item: any | null) => {
		if (item == null) {
			toast.error('Para agregar/editar un Bien debe completar los campos del formulario!');
			return false;
		}
		contextoX?.onHandleShowSpinner();
		const response = await tryCreateOrEditItem(item);
		contextoX?.onHandleHideSpinner();

		const defaultMessageError = captureMessageError(
			response,
			'No se ha podido guardar el bien a la lista, inténtalo más tarde!'
		);
		if (defaultMessageError.hasError) {
			toast.error(defaultMessageError.message);
			console.error(defaultMessageError.message);
			return false;
		}

		toast.success('Bien Patrimonial guardado!');
		return true;
	};
	// END Add or Edit Item to Properties

	// START Remove Item to Properties
	const onHandleRemoveItem = async (id: number | null) => {
		if (id === null) {
			toast.error('Debe indicar un Bien Patrimonial para removerlo!');
			return false;
		}
		contextoX?.onHandleShowSpinner();
		const response = await tryDeleteItem(id);
		contextoX?.onHandleHideSpinner();

		const defaultMessageError = captureMessageError(
			response,
			'No se ha podido remover el bien de la lista, inténtalo más tarde!'
		);
		if (defaultMessageError.hasError) {
			toast.error(defaultMessageError.message);
			console.error(defaultMessageError.message);
			return false;
		}

		toast.success('Bien Patrimonial removido!');
		return true;
	};
	// END Remove Item to Properties

	// START Get All Item to Properties
	const [items, setItems] = useState<any[] | []>([]);
	const onHandleGetAllItems = async (id: number | null) => {
		if (id === null) {
			toast.error('Debe indicar un Alta Patrimonial para recuperar todos sus Bienes Patrimoniales!');
			return false;
		}
		setItems([]);
		contextoX?.onHandleShowSpinner();
		const response = await tryGetAllItems(id);
		contextoX?.onHandleHideSpinner();

		const defaultMessageError = captureMessageError(
			response,
			'No se ha podido recuperar la lista de bienes patrimoniales, inténtalo más tarde!'
		);
		if (defaultMessageError.hasError) {
			toast.error(defaultMessageError.message);
			console.error(defaultMessageError.message);
			return false;
		}

		setItems(response);
		toast.success('Bienes Patrimoniales recuperados!');
		return true;
	};
	// END Get All Item to Properties

	return (
		<RegistrationContext.Provider
			value={{
				onHandleDataStepOne,
				dataStepOne,
				onHandleDataStepTwo,
				dataStepTwo,
				dataStepThree,
				changeActiveStep,
				activeStep,
				onHandleFinalice,
				onHandleGetAllData,
				onHandleDelete,
				changeDataStepOne,
				changeDataStepTwo,
				changeDataStepThree,
				onHandleGetFilesStepOne,
				onHandleGetFilesStepTwo,
				onHandleDeleteFile,
				onHandleAddOrEditItem,
				onHandleRemoveItem,
				onHandleGetAllItems,
				updateSelectedItemType,
				selectedItemType,
				items
			}}>
			{children}
		</RegistrationContext.Provider>
	);
};
