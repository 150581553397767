import apiInstance from 'interceptors/axiosInterceptor';
import { trackPromise } from 'react-promise-tracker';
import { ApiPaths } from 'enums/apiPaths';
import { IBajaPropertyItem, IBajaStepOne, ITimelessProperty } from 'interfaces/baja/IBaja';
import {
	buildDefaultOptions,
	buildURLQuery,
	handleHttpError,
	httpDelete,
	httpGet,
	httpPatch,
	httpPost,
	httpPut
} from './httpService';
import { DocumentFile } from './altaService';
import { Attachment } from 'interfaces/file/IFile';

const URL_BASE = `${ApiPaths.DEREGISTRATION}`;
const getURLById = (id: number) => {
	return `${URL_BASE}/${id}`;
};
const getURLFinalice = (id: number) => {
	return `${URL_BASE}/${id}/complete`;
};
const getURLToPut = (id: number) => {
	return `${URL_BASE}/${id}`;
};
const getURLAddTimelessItem = (id: number) => {
	return `property-timeless-items/deregistration/${id}`;
};
const getURLRemoveTimelessItem = (id: number) => {
	return `property-timeless-items/${id}`;
};
const getURLAddItem = (id: number) => {
	return `property-deregistration-items/deregistration/${id}`;
};
const getURLEditItem = (id: number) => {
	return `property-deregistration-items/${id}`;
};
const getURLRemoveItem = (id: number) => {
	return `property-deregistration-items/${id}`;
};

/**
 * @description Search property code.
 */
export const getProperties = (code: string, isAccessory?: string): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const promise = trackPromise(
		new Promise((resolve) => {
			resolve(
				apiInstance
					.get(`properties/registered/search?inventory_or_property=${code}`, {
						headers: { Authorization: `Bearer ${token.access_token}` }
					})
					.then((res) => {
						return res;
					})
					.catch((error) => {
						return error;
					})
			);
		}),
		'getPropertiesTracker'
	);

	return promise;
};

// START Create Deregister Data
export const tryCreateOrUpdateDeregister = async (entity: IBajaStepOne) => {
	try {
		const OPTIONS = buildDefaultOptions();

		let response;

		const data = {
			...entity,
			date_delivery_certificate: entity.date_delivery_certificate ? new Date(entity.date_delivery_certificate) : null
		};
		const { id, ...payload } = data;

		if (id === undefined) {
			response = await httpPost(URL_BASE, payload, OPTIONS);
		} else {
			response = await httpPut(getURLToPut(id as number), payload, OPTIONS);
		}

		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};
// END Create Deregister Data

// START Finalice
export const tryFinalice = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpPatch(getURLFinalice(id), null, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};
// END Finalice

export const tryGetData = async (id: number): Promise<any> => {
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpGet(getURLById(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const trySearchItem = async (inventaryNumber: string, accessoryNumber: string | null) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const queryString = buildURLQuery({ item_inventory: inventaryNumber, item_number: accessoryNumber });
		const URI = `/properties/inventory-number?${queryString}`;
		const response = await httpGet(URI, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryAddItem = async (id: number, item: IBajaPropertyItem) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpPost(getURLAddItem(id), item, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryEditItem = async (item: IBajaPropertyItem) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const { id, ...data } = item;
		const response = await httpPut(getURLEditItem(id as number), data, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryRemoveItem = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpDelete(getURLRemoveItem(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryAddTimelessItem = async (id: number, entity: ITimelessProperty) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpPost(getURLAddTimelessItem(id), entity, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const createTimelessRegistration = async (payload: ITimelessProperty) => {
	const { id, ...data } = payload;
	id;
	const response = await apiInstance.post('/property-registration-timeless', data);
	return response.data as unknown;
};

export const tryRemoveTimelessItem = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpDelete(getURLRemoveTimelessItem(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

// START Finalice
export const tryDelete = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpDelete(getURLById(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};
// END Finalice

export const getFilesByDocumentId = async (id: number | undefined): Promise<Attachment[]> => {
	const response = await apiInstance.get(`${URL_BASE}/${id}`);
	return response.data.files.map((el: DocumentFile) => ({ ...el, file: { ...el } }));
};

export const cancelPropertyDeRegistration = (id: number) => {
	return apiInstance.delete(`/property-deregistration/${id}`);
};

export const uploadDeregistrationDocumentFiles = (documentId: number, files: File[]) => {
	const formData = new FormData();
	files.forEach((file) => {
		formData.append('attachments', file);
	});

	return apiInstance.post(`property-deregistration-files/upload/${documentId}`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
};
