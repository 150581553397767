import { ColumnDef } from '@tanstack/react-table';
import { IconButton, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

export const defaultColumns: ColumnDef<any, any>[] = [
	{
		accessorKey: 'name',
		enableSorting: true,
		header: () => <span>Nombre</span>,
		cell: ({ row }) => <p style={{ paddingLeft: '10px', textAlign: 'left' }}>{row.original[0]?.name}</p>
	},
	{
		accessorKey: 'file_type',
		enableSorting: true,
		header: () => <span>Tipo de Documento</span>,
		cell: ({ row }) => (
			<p style={{ paddingLeft: '10px', textAlign: 'left' }}>
				{row.original[0]?.file_type?.name || row.original[0]?.file_movement_type?.name}
			</p>
		)
	},
	{
		accessorKey: 'actions',
		enableSorting: false,
		enableGlobalFilter: false,
		enableColumnFilter: false,
		header: () => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>Acciones</span>
			</div>
		),
		cell: ({ row }) => (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'nowrap',
					width: '100%',
					justifyContent: 'space-around'
				}}>
				<IconButton
					onClick={() => {
						row.original.action = 'download';
					}}
					size="small"
					sx={{
						transition: 'all 200ms ease',
						'&:hover': {
							transform: 'scale(1.3)',
							transition: 'all 200ms ease'
						}
					}}>
					<Tooltip
						placement="right-start"
						arrow
						title="Descargar">
						<DownloadIcon />
					</Tooltip>
				</IconButton>
			</div>
		)
	}
];
