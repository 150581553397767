import { useState, FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

//Hooks
import { useMovContext } from 'hooks/useMovContext';

// Child component
import { AdquisicionEdit } from './adquisicion/Adquisicion';
import { TransferenciaEdit } from './transferencia/Transferencia';
import { DonacionEdit } from './donacion/Donacion';
//Interfaces
import {
	paso1Props,
	IAdquisicionValues,
	ITransferenciaValues,
	IDonacionValues,
	IFechasValues
} from 'components/EncPatrimonial/altaStepper/paso1/interfaces';

// Material UI
import { Box, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import { FechaFactRemitoEdit } from './FechaFactRemitoEdit';
import { getHighTypes } from 'services/crudService';

export const ReceptionEdit: FC<paso1Props> = ({ setPaso1 }) => {
	const movContext = useMovContext();
	const [tipoAlta, setTipoAlta] = useState('');
	const [paso1Adquisicion, setPaso1Adquisicion] = useState<IAdquisicionValues | null>(null);
	const [paso1Fechas, setPaso1Fechas] = useState<IFechasValues | null>(null);
	const [paso1Transferencia, setPaso1Transferencia] = useState<ITransferenciaValues | null>(null);
	const [paso1Donacion, setPaso1Donacion] = useState<IDonacionValues | null>(null);
	const [tipoAltaArr, setTipoAltaArr] = useState<any[]>([]);
	const navigate = useNavigate();

	useEffect(() => {
		getHighTypes()
			.then((res) => {
				setTipoAltaArr(res.data);
				if (!res.data) {
					throw new Error('Error al obtener los tipos de alta');
				}
			})
			.catch((err) => toast(err.message, { type: 'error', onClose: () => navigate('/', { replace: true }) }));
	}, []);

	// Adquisición
	useEffect(() => {
		if (tipoAlta !== 'Adquisición') return;
		const vali = paso1Adquisicion !== null && paso1Fechas !== null;
		setPaso1({
			data: { ...paso1Adquisicion, ...paso1Fechas, idTipoAlta: tipoAltaArr.find((item: any) => item.name === 'Adquisición')?.id },
			valid: vali
		});
	}, [tipoAlta, paso1Adquisicion, paso1Fechas]);

	// Transferencia
	useEffect(() => {
		if (tipoAlta !== 'Transferencia') return;

		const vali = paso1Transferencia?.dependencia?.trim().length !== 0 && paso1Transferencia?.id !== 0;
		setPaso1({
			data: { ...paso1Transferencia, idTipoAlta: 2 },
			valid: vali
		});
	}, [tipoAlta, paso1Transferencia]);

	// Donación
	useEffect(() => {
		if (tipoAlta !== 'Donación') return;

		setPaso1({
			data: { ...paso1Donacion, idTipoAlta: 3 },
			valid: true
		});
	}, [tipoAlta, paso1Donacion]);

	const handleTipoAlta = (evt: SelectChangeEvent) => {
		setTipoAlta(evt.target.value);
	};

	// For edit movement
	useEffect(() => {
		if (movContext?.editMovement) {
			setTipoAlta(tipoAltaArr.find((item: any) => item.id === movContext?.editMovement?.property_registration?.high_type_id)?.name);
		}
	}, [movContext?.editMovement]);

	return (
		<Box
			sx={{
				padding: '0px 2%',
				minHeight: '300px',
				display: 'flex',
				flexDirection: 'column',
				flexWrap: 'wrap',
				mt: 3
			}}>
			<Typography variant="body2">{`Los campos que contentan un (*) son obligatorios.`}</Typography>
			<Box
				component={'form'}
				sx={{ display: 'flex', pt: 2, flexDirection: 'row', flexWrap: 'wrap', columnGap: '10%' }}>
				<Box sx={{ display: 'flex', flexDirection: 'column', mb: 0, width: '250px' }}>
					<FormControl sx={{ width: '300px', mr: 2 }}>
						<Box>
							<InputLabel htmlFor="tipoAlta">Tipo de Alta *</InputLabel>
							<Select
								disabled={movContext?.editMovement?.movement_status?.name === 'Pendiente' ? false : true}
								sx={{ width: '100%' }}
								id="tipoAlta"
								name="tipoAlta"
								label="Tipo de Alta *"
								value={tipoAlta ? tipoAlta : ''}
								onChange={handleTipoAlta}>
								{tipoAltaArr ? (
									tipoAltaArr.map((data, index) => (
										<MenuItem
											key={index}
											value={data.name}>
											{data.name}
										</MenuItem>
									))
								) : (
									<MenuItem
										key={0}
										value={''}>
										{'Cargando...'}
									</MenuItem>
								)}
							</Select>
						</Box>
					</FormControl>
					{tipoAlta === 'Adquisición' && (
						<FechaFactRemitoEdit
							tipoAlta={tipoAlta}
							setPaso1Fechas={setPaso1Fechas}
						/>
					)}
				</Box>
				<Box sx={{ width: '600px' }}>
					{tipoAlta === 'Adquisición' && (
						<AdquisicionEdit
							tipoAlta={tipoAlta}
							paso1Adquisicion={paso1Adquisicion}
							setPaso1Adquisicion={setPaso1Adquisicion}
						/>
					)}
					{tipoAlta === 'Transferencia' && <TransferenciaEdit setPaso1Transferencia={setPaso1Transferencia} />}
					{tipoAlta === 'Donación' && <DonacionEdit setPaso1Donacion={setPaso1Donacion} />}
				</Box>
			</Box>
		</Box>
	);
};
