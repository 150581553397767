import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Services
import { getDeregistrationTypes, getHighTypes, getMovements } from 'services';

// Hooks
import { useMovContext } from 'hooks/useMovContext';

// Components
import { HeaderRoutes } from 'components/globals/headerRoutes/HeaderRoutes';
import { MovementsTable } from 'components/movementsTable/table/MovementsTable';

// Interfaces
import { IMovementEdit } from 'interfaces/movements/MovementEdit';
import { ILinks } from 'interfaces/Pagination';

// Material UI
import { Box } from '@mui/material';
import { FormDialog } from 'components/globals/dialog/FormDialog';
import { DetailsMovement } from './detailsMovement/DetailsMovement';
import { useDeregistrationContext } from 'hooks/useDeregistrationContext';

import { Spinner } from 'components/globals/spinner/Spinner';
import { useAppContext } from 'hooks/useAppContext';
import { MovementType } from 'enums';

const Movements = () => {
	const [movements, setMovements] = useState<IMovementEdit[]>([]);
	const [links, setLinks] = useState<ILinks>({} as ILinks);
	const [editMovement, setEditMovement] = useState<IMovementEdit | null>(null);
	const [highTypes, setHighTypes] = useState<any[]>([]);
	const [deregistrationTypes, setDeregistrationTypes] = useState<any[]>([]);
	const navigate = useNavigate();
	const movContext = useMovContext();
	const deregistrationContext = useDeregistrationContext();
	const appContext = useAppContext();
	// Get the userId param from the URL.
	const { movement_type } = useParams();
	// ...

	useEffect(() => {
		getHighTypes().then((res) => {
			setHighTypes(res.data);
		});

		getDeregistrationTypes().then((res) => {
			setDeregistrationTypes(res.data);
		});
	}, []);

	const movementTypeId = movement_type === 'altas' ? MovementType.ALTA : MovementType.BAJA;

	const fetchMovements = () => {
		return void getMovements({ ...movContext?.filtersMovTable, movement_type: movementTypeId })
			.then((res) => {
				const data = res?.data;

				if (data && data.items) {
					const movs = data.items.map((mov: IMovementEdit) => {
						return {
							...mov,
							type: mov.property_registration_id ? 'Alta' : 'Baja',
							high_type_name: highTypes.find((highType) => highType.id === mov?.property_registration?.high_type_id)
								?.name
						};
					});
					setMovements(movs);
					setLinks(res.data.links);
					movContext?.setFiltersMovTable((prev) => ({
						...prev,
						page: res.data.meta.currentPage,
						limit: res.data.meta.itemsPerPage
					}));
					movContext?.setMeta(res.data.meta);
				}
			})
			.finally(() => {
				movContext?.setIsUpdatingFilters(false);
			});
	};

	useEffect(() => {
		if (movContext?.isReloadMovementsTable && highTypes?.length > 0) fetchMovements();
	}, [movContext?.isReloadMovementsTable, movementTypeId]);

	useEffect(() => {
		if (highTypes?.length > 0 && movContext?.isUpdatingFilters) fetchMovements();
	}, [highTypes, movContext?.isUpdatingFilters, movementTypeId]);

	const onHandleEditDeregistration = async (entity: any) => {
		const result = await deregistrationContext?.onHandleGetDataStepOne(entity.property_deregistration.id || null);

		if (result) {
			navigate(`/baja/editar/${entity.property_deregistration?.id}?movementStatusId=${entity.movement_status?.id}`, {
				replace: true
			});
		}
	};

	const onHandleShowDeregistration = async (entity: IMovementEdit | undefined) => {
		if (!entity) {
			return false;
		}
		setEditMovement(entity);
		setToggleDetailsModal(true);
	};

	// START Handle Show Details
	const [showDetailsModal, setToggleDetailsModal] = useState<boolean>(false);
	const onHandleShowMovementEdit = async (entity: IMovementEdit) => {
		if (!entity) {
			return false;
		}
		const result = await movContext?.onHandleMovementGetDetails(entity?.id !== undefined ? entity.id : 0);

		if (result !== undefined) {
			setEditMovement(result);
			setToggleDetailsModal(true);
		}
	};
	// END Handle Show Details

	useEffect(() => {
		if (movContext) {
			if (highTypes?.length > 0) {
				getMovements({ ...movContext?.filtersMovTable, movement_type: movementTypeId }).then((res) => {
					const movs = res.data.items.map((mov: IMovementEdit) => {
						return {
							...mov,
							type: mov.property_registration_id ? 'Alta' : 'Baja',
							high_type_name: highTypes.find((highType) => highType.id === mov?.property_registration?.high_type_id)
								?.name
						};
					});
					setMovements(movs);
					setLinks(res.data.links);
					movContext.setFiltersMovTable((prev) => ({
						...prev,
						page: res.data.meta.currentPage,
						limit: res.data.meta.itemsPerPage
					}));
					movContext.setMeta(res.data.meta);
					movContext.setIsReloadMovementsTable(false);
				});
			}
		}

		localStorage.setItem(
			'MOVEMENT_TYPE',
			String(`${movement_type?.slice(0, 1)?.toUpperCase()}${movement_type?.slice(1, -1)}`)
		);

		return () => {
			localStorage.removeItem('MOVEMENT_TYPE');
		};
	}, [movementTypeId]);

	return (
		<>
			{appContext?.showSpinner ? <Spinner /> : null}

			{showDetailsModal && (
				<FormDialog
					maxWidth="lg"
					show={showDetailsModal}
					title={'Detalles del Movimiento'}
					content={<DetailsMovement selectedRow={editMovement} />}
					actions={<></>}
					handleClose={() => {
						setToggleDetailsModal(false);
						movContext?.setItemSelected(null);
					}}
				/>
			)}

			<HeaderRoutes
				key={movementTypeId}
				title={'Gestión de'}
				strongTitle={`Movimientos Patrimoniales: ${movement_type}`}>
				<Box>
					<MovementsTable
						documentType={movement_type}
						deregistrationTypesList={deregistrationTypes}
						registrationTypeList={highTypes}
						editMovement={editMovement}
						setEditMovement={(movement) => {
							if (!movement) return;

							movement.property_registration?.id &&
								navigate(
									`/alta/editar/${movement.property_registration?.id}?movementStatusId=${movement.movement_status?.id}`,
									{ replace: true }
								);
						}}
						movements={movements}
						handleShow={(entity: IMovementEdit) => {
							onHandleShowMovementEdit(entity);
						}}
						handleEditDeregistration={(entity: IMovementEdit) => {
							onHandleEditDeregistration(entity);
						}}
						handleShowDeregistration={(entity: IMovementEdit) => {
							onHandleShowDeregistration(entity);
						}}
					/>
				</Box>
			</HeaderRoutes>
		</>
	);
};

Movements.displayName = 'Movements';

export { Movements };
