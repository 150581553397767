import { HeaderRoutes } from 'components/globals/headerRoutes/HeaderRoutes';
import { DeregistrationState } from 'contexts/deregistrationContext/DeregistrationState';
import { StepperComponent } from 'components/bajas/stepper/Stepper';
import { BajaOverview } from 'components/bajas/BajaOverview';

export const DeregistrationPage = () => {
	return (
		<>
			<DeregistrationState>
				<HeaderRoutes
					title={'Formulario de'}
					strongTitle={'Baja'}>
					<>
						<StepperComponent />
						<BajaOverview />
					</>
				</HeaderRoutes>
			</DeregistrationState>
		</>
	);
};
