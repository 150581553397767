import { Box, Container, Grid } from '@mui/material';
import style from './footer.module.css';
import contaduria from 'assets/contaduria-general.svg';
import misionesBlanco from '../../../../assets/Misiones-Blanco.svg';
import { getVersion } from 'services';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

const VITE_VERSION_FRONT = import.meta.env.VITE_VERSION_FRONT;

const current_year = new Date().getFullYear();

export const Footer = () => {
	const [version, setVersion] = useState<string>('');

	useEffect(() => {
		const getData = () => {
			try {
				getVersion().then((res: any) => {
					setVersion(res.data);
				});
			} catch (error: any) {
				console.error(error);
			}
		};
		getData();
	}, []);

	return (
		<footer>
			<Box className={style.footer}>
				<Container>
					<Grid
						container
						spacing={{ xs: 2, md: 3 }}
						columns={{ xs: 4, sm: 8, md: 12 }}>
						<Grid
							item
							xs={12}
							sm={4}
							lg={4}
							sx={{ display: 'flex', justifyContent: 'center' }}>
							<Box>
								<img
									className={style.logos}
									src={misionesBlanco}
									alt="Misiones"
									width={170}
								/>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							sm={4}
							lg={4}
							sx={{ display: 'flex', justifyContent: 'center' }}>
							<Box>
								<img
									className={style.logos}
									src={contaduria}
									alt="Contaduria"
									width={100}
								/>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							sm={4}
							lg={4}
							sx={{ display: 'flex', justifyContent: 'center' }}>
							<Box
								pt={2}
								sx={{ opacity: '0.8' }}>
								<h5>
									<span>&copy;</span> {current_year} Copyright
									<p>
										Sistema Bienes Patrimoniales ( {VITE_VERSION_FRONT} | API:{version} )
									</p>
								</h5>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</footer>
	);
};
