/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ButtonConfirm, ButtonDecline } from 'components/globals/customsBtn/customBtn';
import { IBaja, IBajaStepOne } from 'interfaces/baja/IBaja';
import { ExpedientForm, IExpedient } from 'components/globals/expedients/expedient';
import { ILegalInstrument, LegalInstrumentForm } from 'components/globals/legalsInstrument/legalInstrument';
import { AppDatePicker } from 'components/globals/AppDatePicker/AppDatePicker';

export interface IOverviewBajaStepOneForm {
	entity?: IBaja;
	handleSubmit(data: IBajaStepOne): void;
	handleCancel(hasPendingData: boolean): void;
	buttonLabelCancel?: string;
	buttonLabelSubmit?: string;
	movementStatusId?: number;
}

export const LABELS_STEP_ONE_FORM = {
	procedure: 'Expediente de Baja',
	procedure_agency_code: 'Código de Organismo',
	procedure_number: 'Número',
	procedure_year: 'Año',
	instrument: 'Instrumento Legal',
	instrument_number: 'Número',
	instrument_year: 'Año',
	date_legal_instrument: 'Fecha del Instrumento Legal',
	date_delivery_certificate: 'Fecha de Acta *'
};

export function isValidNumber(value?: string, max?: number) {
	const num = Number(value);
	return num >= 1 && num <= (max || 1) && !value?.startsWith('0');
}

export function isValidYear(year: number) {
	const currentYear = new Date().getFullYear();
	return year >= 1900 && year <= currentYear;
}

export const MAX_AGENCY_CODE_VALUE = 9999;
export const MAX_NUMBER_VALUE = 99999;

export const BajaStepOneForm = ({
	entity,
	handleSubmit,
	handleCancel,
	buttonLabelCancel = 'Cancelar',
	buttonLabelSubmit = 'Siguiente',
	movementStatusId
}: IOverviewBajaStepOneForm) => {
	const [procedureAgencyCode, setProcedureAgencyCode] = useState<string>('');
	const [procedureNumber, setProcedureNumber] = useState<string>('');
	const [procedureYear, setProcedureYear] = useState<string>('');
	const [instrumentNumber, setInstrumentNumber] = useState<string>('');
	const [instrumentYear, setInstrumentYear] = useState<string>('');
	const [instrumentType, setInstrumentType] = useState<string>('');
	const [dateLegalInstrument, setLegalInstrumentDate] = useState<string | undefined>();
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [datePickerValue, setDatePickerValue] = useState<Date | null>(null);
	const [formErrors, setFormErrors] = useState({
		procedureAgencyCode: false,
		procedureNumber: false,
		procedureYear: false,
		instrumentNumber: false,
		instrumentYear: false
	});

	const isSubmitEnabled = useMemo(() => {
		const hasValues = [
			procedureAgencyCode,
			procedureNumber,
			instrumentNumber,
			instrumentYear,
			datePickerValue,
			instrumentType
		].every((val) => val);
		const hasNoError = Object.values(formErrors).every((error) => !error);
		return hasNoError && hasValues;
	}, [
		datePickerValue,
		procedureAgencyCode,
		procedureYear,
		procedureNumber,
		instrumentNumber,
		instrumentYear,
		instrumentType
	]);

	useEffect(() => {
		if (entity && entity?.instruments) {
			setProcedureAgencyCode(entity.instruments?.procedure?.agency_code || '');
			setProcedureNumber(entity.instruments?.procedure?.number || '');
			setProcedureYear(entity.instruments?.procedure?.year || '');
			setInstrumentNumber(entity.instruments?.instrument?.instrument_number || '');
			setInstrumentYear(entity.instruments?.instrument?.instrument_year || '');
			setLegalInstrumentDate(
				entity.instruments?.date_legal_instrument ? entity.instruments?.date_legal_instrument.substring(0, 10) : ''
			);
			setInstrumentType(entity.instruments.instrument?.instrument_type || '');

			const date = entity.instruments?.date_delivery_certificate;
			setDatePickerValue(new Date(date ? date : ''));
		}

		if (entity) {
			if (entity?.id !== null && entity.id > 0) {
				setIsEdit(true);
			}
		}
	}, [entity]);

	const getParsedData = (): IBajaStepOne => {
		let dateToUse = datePickerValue?.toString();
		if (dateToUse === 'Invalid Date') {
			setDatePickerValue(null);
			dateToUse = undefined;
		}
		return {
			procedure: {
				agency_code: procedureAgencyCode,
				number: procedureNumber,
				year: procedureYear
			},
			instrument: {
				instrument_number: instrumentNumber,
				instrument_year: instrumentYear,
				instrument_type: instrumentType
			},
			date_legal_instrument: undefined,
			date_delivery_certificate: dateToUse
		};
	};

	const onHandleSubmit = (): void => {
		handleSubmit(getParsedData());
	};

	const handleDate = (val: Date | null) => {
		setDatePickerValue(val);
	};

	return (
		<div className="borderedBox">
			<Grid
				container
				spacing={2}>
				<Grid
					item
					xs={12}
					sm={6}
					md={6}
					lg={6}
					xl={6}>
					<ExpedientForm
						required
						validateProcedureAgencyCode
						validateProcedureNumber
						validateYear
						disabled={Boolean(movementStatusId && movementStatusId !== 1)}
						agencyCode={procedureAgencyCode}
						number={procedureNumber}
						year={procedureYear}
						handleChange={(data: IExpedient) => {
							setProcedureAgencyCode(data.agency_code || '');
							setProcedureNumber(data.number || '');
							setProcedureYear(data.year || '');
						}}
						onError={(expedientErrors) => {
							setFormErrors((curr) => ({
								...curr,
								procedureAgencyCode: !!expedientErrors.procedure_agency_code,
								procedureNumber: !!expedientErrors.procedure_number,
								procedureYear: !!expedientErrors.procedure_year
							}));
						}}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					md={6}
					lg={6}
					xl={6}>
					<LegalInstrumentForm
						required
						isAlta={false}
						validateYear
						validateInstrumentNumber
						disabled={Boolean(movementStatusId && movementStatusId !== 1)}
						number={instrumentNumber}
						year={instrumentYear}
						type={instrumentType}
						handleChange={(data: ILegalInstrument) => {
							setInstrumentNumber(data.number || '');
							setInstrumentYear(data.year || '');
							setInstrumentType(data.type || '');
						}}
						onError={(instrumentErrors) => {
							setFormErrors((curr) => ({
								...curr,
								instrumentNumber: !!instrumentErrors.number,
								instrumentYear: !!instrumentErrors.year
							}));
						}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}>
						<Typography
							mb={2}
							variant="subtitle2">
							Fecha de Baja
						</Typography>
					</Grid>
					<AppDatePicker
						label="Fecha de Baja *"
						disableFuture
						disabled={Boolean(movementStatusId && movementStatusId !== 1)}
						onChange={handleDate}
						value={datePickerValue}
					/>
				</Grid>
				<Typography
					variant="subtitle2"
					style={{ width: '100%' }}
					pl={1}
					mt={1}>{`(*) Campos Obligatorios.`}</Typography>
			</Grid>

			<Box
				sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
				<ButtonDecline
					variant="contained"
					onClick={() => {
						handleCancel(false);
					}}>
					{buttonLabelCancel}
				</ButtonDecline>
				<ButtonConfirm
					sx={{ ml: 3 }}
					variant="contained"
					disabled={!isSubmitEnabled}
					onClick={() => onHandleSubmit()}>
					{buttonLabelSubmit}
				</ButtonConfirm>
			</Box>
		</div>
	);
};
