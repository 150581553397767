/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { FormGenericSelect } from 'components/globals/inputs/FormGenericSelect';
import { ISelectOption } from 'interfaces/commons/IParameters';
import { ButtonConfirm, ButtonDecline } from 'components/globals/customsBtn/customBtn';
import { FormGenericInput } from 'components/globals/inputs/FormGenericInput';
import {
	DEFAULT_NO_ERROR,
	emailValidator,
	fieldValidator,
	passwordsAreEquals,
	requiredValidator
} from 'helpers/Validators/Validators';
import { IUser, IUserResquestBody } from 'interfaces/user/IUser';
import lodash from 'lodash';
import { FormCredentials } from 'components/globals/layouts/FormCredentials';
import { getUserGroups } from 'services/rolesService';
import { tryGetAllPatrimonialDependences } from 'services/patrimonialDependencesService';

export interface IOverviewUserForm {
	entity: IUser | undefined;
	handleSubmit: (data: IUserResquestBody) => void;
	handleCancel: () => void;
	handleSearchDependencies: (term: string) => void;
	groups: ISelectOption[];
	profiles: ISelectOption[];
	patrimonialDependences: ISelectOption[];
}

export interface IErrorsForm {
	firstName?: string | undefined;
	lastName?: string | undefined;
	email?: string | undefined;
	group?: string | undefined;
	perfil?: string | undefined;
	patrimonialDependence?: string | undefined;
	password?: string | undefined;
	repeatPassword?: string | undefined;
}

export const LABELS_USER_FORM = {
	firstName: 'Nombre',
	lastName: 'Apellido',
	email: 'Correo electrónico',
	group: 'Grupo',
	perfil: 'Perfil',
	patrimonialDependence: 'Dependencia Patrimonial',
	password: 'Contraseña',
	repeatPassword: 'Repita la contraseña'
};

export const OverviewUserForm = ({
	entity,
	handleSubmit,
	handleCancel,
	handleSearchDependencies,
	groups = [],
	profiles = [],
	patrimonialDependences = []
}: IOverviewUserForm) => {
	const [id, setId] = useState<number | undefined>(entity?.id);
	const [firstName, setFirstName] = useState<string | undefined>(entity?.first_name);
	const [lastName, setLastName] = useState<string | undefined>(entity?.last_name);
	const [email, setEmail] = useState<string | undefined>(entity?.email);
	const [group, setGroup] = useState<ISelectOption | undefined>();
	const [perfil, setPerfil] = useState<ISelectOption | undefined>();
	const [patrimonialDependence, setPatrimonialDependence] = useState<ISelectOption | undefined>();
	const [errors, setErrors] = useState<IErrorsForm>({} as IErrorsForm);
	const isEdit = !!entity;
	const [groupOptions, setGroupOptions] = useState<any[]>([]);
	const [groupProfilesOptions, setGroupProfilesOptions] = useState<any[]>([]);
	const [groupOptionsData, setGroupOptionsData] = useState<any[]>([]);

	useEffect(() => {
		let group: ISelectOption = { id: 0, label: '' };
		if (entity?.group_id) {
			const selected = groupOptionsData.find((el) => el.id === entity.group_id);
			if (selected) {
				group = { label: selected.name, id: selected.id };
				setGroupProfilesOptions(selected.profiles.map((el: any) => ({ label: el.name, id: el.id })));
			}
		}
		setGroup(group);

		let profile: ISelectOption = { id: 0, label: '' };
		if (entity && entity.role) {
			profile = { id: entity.role.id, label: entity.role.name };
		}
		setPerfil(profile);

		let dependence: ISelectOption = { id: 0, label: '' };
		if (entity && entity.patrimonial_dependence_id) {
			const selected = patrimonialDependences.find((el) => el.id === entity.patrimonial_dependence_id);
			if (selected) {
				dependence = {
					id: selected.id,
					label: selected.label
				};
			}
		}
		setPatrimonialDependence(dependence);
	}, [entity, groupOptionsData, patrimonialDependences]);

	const validateForm = (): boolean => {
		const newErrors: IErrorsForm = {} as IErrorsForm;
		setErrors(newErrors);

		const firstNameError = requiredValidator({ value: firstName, label: LABELS_USER_FORM.firstName });
		if (firstNameError !== DEFAULT_NO_ERROR) {
			newErrors.firstName = firstNameError;
		}

		const lastNameError = requiredValidator({ value: lastName, label: LABELS_USER_FORM.lastName });
		if (lastNameError !== DEFAULT_NO_ERROR) {
			newErrors.lastName = lastNameError;
		}

		const emailError = emailValidator({ value: email, label: LABELS_USER_FORM.email });
		if (emailError !== DEFAULT_NO_ERROR) {
			newErrors.email = emailError;
		}

		const groupError = requiredValidator({ value: group?.label, label: LABELS_USER_FORM.group });
		if (groupError !== DEFAULT_NO_ERROR) {
			newErrors.group = groupError;
		}

		const perfilError = requiredValidator({ value: perfil?.label, label: LABELS_USER_FORM.perfil });
		if (perfilError !== DEFAULT_NO_ERROR) {
			newErrors.perfil = perfilError;
		}

		if (Object.entries(newErrors).length !== 0) {
			setErrors(newErrors);
			return false;
		}

		return true;
	};

	const getParsedData = (): IUserResquestBody => {
		return {
			id: isEdit ? entity.id : undefined,
			first_name: firstName ? firstName.trim() : '',
			last_name: lastName ? lastName.trim() : '',
			email: email ? email.trim() : '',
			role_id: perfil ? perfil.id : undefined,
			patrimonial_dependence_id: patrimonialDependence ? patrimonialDependence.id : undefined,
			group_id: group ? group.id : undefined
		};
	};

	const onHandleSubmit = (): void => {
		if (!validateForm()) {
			return;
		}
		handleSubmit(getParsedData());
	};

	const fetchGroups = async () => {
		const data = await getUserGroups();
		setGroupOptionsData(data);
		setGroupOptions(data.map((el) => ({ label: el.name, id: el.id })));
	};

	useEffect(() => void fetchGroups(), []);

	// START Handle Credentials
	const [password, setPassword] = useState('');
	const [repeatPassword, setRepeatPassword] = useState('');
	// END Handle Credentials

	return (
		<div className="borderedBox">
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'wrap',
					columnGap: '30px',
					rowGap: '30px',
					paddingTop: '30px'
				}}>
				<FormGenericInput
					required={true}
					value={firstName}
					label={LABELS_USER_FORM.firstName}
					name="first_name"
					id="first_name"
					handleChange={setFirstName}
					error={errors.firstName}
				/>

				<FormGenericInput
					required={true}
					value={lastName}
					label={LABELS_USER_FORM.lastName}
					name="last_name"
					id="last_name"
					handleChange={setLastName}
					error={errors.lastName}
				/>

				<FormGenericInput
					required={true}
					value={email}
					label={LABELS_USER_FORM.email}
					name="email"
					id="email"
					handleChange={setEmail}
					error={errors.email}
				/>

				<FormGenericSelect
					id={'group'}
					value={group}
					label={LABELS_USER_FORM.group}
					handleChange={(value) => {
						setGroup(value);

						setGroupProfilesOptions([]);
						setPerfil(undefined);
						setPatrimonialDependence(undefined);

						const selected = groupOptionsData.find((el) => el.id === value?.id);
						if (selected) {
							setGroupProfilesOptions(selected.profiles.map((el: any) => ({ label: el.name, id: el.id })));
						}
					}}
					options={groupOptions}
					error={errors.group}
				/>

				<FormGenericSelect
					value={perfil}
					label={LABELS_USER_FORM.perfil}
					id="perfil"
					options={groupProfilesOptions}
					handleChange={setPerfil}
					error={errors.perfil}
				/>

				{group?.label === 'Dependencia Patrimonial' ? (
					<FormGenericSelect
						value={patrimonialDependence}
						label={LABELS_USER_FORM.patrimonialDependence}
						id="patrimonialDependence"
						options={patrimonialDependences}
						handleChange={setPatrimonialDependence}
						error={errors.patrimonialDependence}
					/>
				) : null}
			</Box>

			{!isEdit ? <Box style={{ marginTop: '20px' }}></Box> : null}

			<Box
				sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
				<ButtonDecline
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => {
						handleCancel();
					}}>
					Cancelar
				</ButtonDecline>
				<ButtonConfirm
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => onHandleSubmit()}>
					Agregar
				</ButtonConfirm>
			</Box>
		</div>
	);
};
