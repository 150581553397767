/* eslint-disable max-lines */
import { HeaderForm } from '../../components/globals/headerForm/HeaderForm';
import { memo, useEffect, useRef, useState } from 'react';
import { usePresentationSheetContext } from 'hooks/usePresentationSheetContext';
import { useAppContext } from 'hooks/useAppContext';
import { Spinner } from 'components/globals/spinner/Spinner';
import { Alert, AlertTitle, Box, Grid, Stack, Typography } from '@mui/material';
import {
	IPresentationSheetAuditData,
	IPresentationSheetMovementAudit
} from 'interfaces/presentationSheet/IPresentationSheet';
import { useAuth } from 'hooks/useAuth';
import { FormGenericSelect } from 'components/globals/inputs/FormGenericSelect';
import { ISelectOption } from 'interfaces/commons/IParameters';
import { DEFAULT_NO_ERROR, requiredValidator } from 'helpers/Validators/Validators';
import { currencyLocalFormat } from 'helpers/Formaters/Formaters';
import { ButtonConfirm, ButtonNextPrev } from 'components/globals/customsBtn/customBtn';
import { useNavigate } from 'react-router-dom';
import lodash from 'lodash';
import { PresentationSheetAuditTable } from 'components/presentationSheet/PresentationSheetAuditTable';
import { FormDialog } from 'components/globals/dialog/FormDialog';
import { OverviewMovementStatusForm } from 'components/presentationSheet/OverviewMovementStatusForm';
import { AttachmentsFiles } from 'components/attachmentsFiles/AttachmentsFiles';
import { useLocation } from 'react-router-dom';
import { getOrganizationalDataById } from 'services/presentationSheetService';
import { tryGetAllPatrimonialDependences } from 'services/patrimonialDependencesService';
import PropertyMovementTimeline from 'components/globals/movementTimeline/PropertyMovementTimeline';

export interface IErrorsStepOneForm {
	patrimonialDependence?: string | undefined;
	year?: string | undefined;
	month?: string | undefined;
	movementType?: string | undefined;
}

export const LABELS_PRESENTATION_STEP_ONE_FORM = {
	patrimonialDependence: 'Dependencia Patrimonial',
	year: 'Año',
	month: 'Mes',
	movementType: 'Tipo de Movimiento'
};

const ALL_MONTHS: ISelectOption[] = [
	{ id: 1, label: 'Enero' },
	{ id: 2, label: 'Febrero' },
	{ id: 3, label: 'Marzo' },
	{ id: 4, label: 'Abril' },
	{ id: 5, label: 'Mayo' },
	{ id: 6, label: 'Junio' },
	{ id: 7, label: 'Julio' },
	{ id: 8, label: 'Agosto' },
	{ id: 9, label: 'Septiembre' },
	{ id: 10, label: 'Octubre' },
	{ id: 11, label: 'Noviembre' },
	{ id: 12, label: 'Diciembre' }
];

const ALL_MOVEMENTS_TYPE = [
	{ id: 1, label: 'Alta' },
	{ id: 2, label: 'Baja' }
];

const PresentationSheetAuditPage = memo(() => {
	const presentationContext = usePresentationSheetContext();
	const appContext = useAppContext();
	const auth = useAuth();
	const navigate = useNavigate();
	const location = useLocation();

	const [movements, setMovements] = useState<IPresentationSheetMovementAudit[]>([]);
	const [years, setYears] = useState<ISelectOption[]>([]);
	const [months, setMonths] = useState<ISelectOption[]>([]);
	const [patrimonialDependences, setPatrimonialDependences] = useState<ISelectOption[]>([]);

	const [movementsType, setMovementsType] = useState<ISelectOption[]>([]);
	const [year, setYear] = useState<ISelectOption | undefined>(undefined);
	const [month, setMonth] = useState<ISelectOption | undefined>(undefined);
	const [movementType, setMovementType] = useState<ISelectOption | undefined>(undefined);
	const [patrimonialDependence, setPatrimonialDependence] = useState<ISelectOption | undefined>(undefined);
	const [userId, setUserId] = useState<number | undefined>(auth?.user?.id);
	const [errors, setErrors] = useState<IErrorsStepOneForm>({} as IErrorsStepOneForm);
	const [total, setTotal] = useState<number | bigint>(0);
	const [enableForChanges, setEnableForChanges] = useState<boolean>(false);
	const [sheet, setSheet] = useState<IPresentationSheetAuditData | undefined>(undefined);
	const [statusList, setStatusList] = useState<ISelectOption[]>([]);
	const [organizationalData, setOrganizationalData] = useState<any>({});

	useEffect(() => {
		setMonths(ALL_MONTHS);
		const day = new Date();
		const startYear = 2022;
		const allYears: ISelectOption[] = [];
		for (let index = startYear; index < day.getFullYear() + 1; index++) {
			allYears.push({
				id: index,
				label: index.toString()
			});
		}
		setYears(
			allYears.sort((a: ISelectOption, b: ISelectOption) => {
				return a.id && b.id ? b.id - a.id : 0;
			})
		);
		setMovementsType(ALL_MOVEMENTS_TYPE);
	}, []);

	useEffect(() => {
		if (appContext?.patrimonialDependences.length > 0) {
			setPatrimonialDependences(
				appContext?.patrimonialDependences.map((p) => {
					return {
						id: p.id,
						label: p.patrimonial_dependence
					};
				})
			);
		}
	}, [appContext?.patrimonialDependences]);

	useEffect(() => {
		setStatusList(presentationContext?.statusList);
	}, [presentationContext?.statusList]);

	useEffect(() => {
		setMovements(presentationContext?.presentationsToAudit);
	}, [presentationContext?.presentationsToAudit]);

	useEffect(() => {
		setTotal(presentationContext?.totalToAudit);
	}, [presentationContext?.totalToAudit]);

	const handleGetOrganizationalData = async () => {
		setOrganizationalData(
			await getOrganizationalDataById(presentationContext?.sheetToAudit?.patrimonial_dependence_id || 0)
		);
	};

	useEffect(() => {
		setEnableForChanges(presentationContext?.isEnableToEditSheetToAudit());
		setSheet(presentationContext?.sheetToAudit);

		if (presentationContext?.sheetToAudit) handleGetOrganizationalData();
	}, [presentationContext?.sheetToAudit]);
	useEffect(() => {
		setYear(undefined);
	}, [patrimonialDependence]);

	useEffect(() => {
		if (year !== undefined) {
			const selectedYear = year.id;
			const day = new Date();
			if (selectedYear === day.getFullYear()) {
				const filteredMonths: ISelectOption[] = [];
				for (let index = 1; index < day.getMonth() + 2; index++) {
					filteredMonths.push(ALL_MONTHS[index - 1]);
				}
				setMonths(filteredMonths);
			} else {
				setMonths(ALL_MONTHS);
			}
		}
	}, [year]);

	const validateForm = (): boolean => {
		const newErrors: IErrorsStepOneForm = {} as IErrorsStepOneForm;
		setErrors(newErrors);

		const dependenceError = requiredValidator({
			value: patrimonialDependence?.label,
			label: LABELS_PRESENTATION_STEP_ONE_FORM.patrimonialDependence
		});
		if (dependenceError !== DEFAULT_NO_ERROR) {
			newErrors.patrimonialDependence = dependenceError;
		}

		const yearError = requiredValidator({ value: year?.label, label: LABELS_PRESENTATION_STEP_ONE_FORM.year });
		if (yearError !== DEFAULT_NO_ERROR) {
			newErrors.year = yearError;
		}

		const monthError = requiredValidator({ value: month?.label, label: LABELS_PRESENTATION_STEP_ONE_FORM.month });
		if (monthError !== DEFAULT_NO_ERROR) {
			newErrors.month = monthError;
		}

		const movementTypeError = requiredValidator({
			value: movementType?.label,
			label: LABELS_PRESENTATION_STEP_ONE_FORM.movementType
		});
		if (movementTypeError !== DEFAULT_NO_ERROR) {
			newErrors.movementType = movementTypeError;
		}

		if (Object.entries(newErrors).length !== 0) {
			setErrors(newErrors);
			return false;
		}

		return true;
	};

	const onHandleGenerate = async () => {
		setOrganizationalData({});
		if (!validateForm()) {
			return false;
		}

		await presentationContext?.onHandleTryGetAllMovementsToAudit(
			{
				patrimonial_dependence_id: patrimonialDependence?.id,
				year: year?.id,
				month: month?.id,
				movement_type: movementType?.id,
				user_id: userId
			},
			{ page: 1, limit: 10 }
		);
	};

	const onHandleCancel = () => {
		navigate('/home');
	};

	useEffect(() => {
		tryGetAllPatrimonialDependences().then((response) => {
			setPatrimonialDependences(response.map((item: any) => ({ label: item.patrimonial_dependence, id: item.id })));
		});
	}, []);

	// START Handle Observation Modal
	const [showStatusModal, toggleStatusModal] = useState(false);
	const [selectedRecord, setSelectedRecord] = useState<IPresentationSheetMovementAudit | undefined>(undefined);

	const onHandleShowStatusModal = (entity: IPresentationSheetMovementAudit) => {
		if (!entity) {
			return;
		}
		if (enableForChanges) {
			toggleStatusModal(true);
			setSelectedRecord(entity);
		}
	};

	const onHideStatusModal = () => {
		toggleStatusModal(false);
		setSelectedRecord(undefined);
	};

	const onHandleChangeStatus = (entity: IPresentationSheetMovementAudit | undefined) => {
		if (!entity) {
			return;
		}
		if (enableForChanges) {
			presentationContext?.onHandleChangeStatus(entity);
			onHideStatusModal();
		}
	};
	// END Handle Observation Modal

	// START Handle History Modal
	const [showHistoryModal, toggleHistoryModal] = useState(false);

	const onHandleShowModalHistory = (entity: IPresentationSheetMovementAudit) => {
		if (!entity) {
			return;
		}
		toggleHistoryModal(true);
		setSelectedRecord(entity);
	};

	const onHideModalHistory = () => {
		toggleHistoryModal(false);
		setSelectedRecord(undefined);
	};
	// END Handle History Modal

	// START Handle Files Modal
	const [showFilesModal, toggleFilesModal] = useState(false);

	const onHandleShowModalFiles = (entity: IPresentationSheetMovementAudit) => {
		if (!entity) {
			return;
		}
		toggleFilesModal(true);
		setSelectedRecord(entity);
	};

	const onHideModalFiles = () => {
		toggleFilesModal(false);
		setSelectedRecord(undefined);
	};
	// END Handle Files Modal

	// START Handle Submitt
	const onHandleSubmitt = async () => {
		if (enableForChanges) {
			if (!validateForm()) {
				return false;
			}

			await presentationContext?.onHandleTrySendPresentationSheetToAudit();
			clearData();
		}
	};
	// END Handle Submitt
	// clear data
	const clearData = () => {
		setPatrimonialDependence(undefined);
		setYear(undefined);
		setMonth(undefined);
		setMovementType(undefined);
		setMovements([]);
		setTotal(0);
		setOrganizationalData({});
		setSheet(undefined);
	};
	// Load data from home page
	useEffect(() => {
		if (location?.state?.planilla) {
			const sheet = location?.state?.planilla;
			setMonth({ id: sheet?.month, label: ALL_MONTHS.find((m) => m.id === sheet?.month)?.label || '' });
			setMovementType({
				id: sheet?.movement_type,
				label: ALL_MOVEMENTS_TYPE.find((m) => m.id === sheet?.movement_type)?.label || ''
			});
			presentationContext?.onHandleTryGetAllMovementsToAudit(
				{
					patrimonial_dependence_id: sheet.patrimonial_dependency_id,
					year: sheet.year,
					month: sheet.month,
					movement_type: sheet.movement_type,
					user_id: userId
				},
				{ page: 1, limit: 10 }
			);
		}
	}, []);
	// End load data from home page

	useEffect(() => {
		if (!location?.state?.planilla) return;
		const timeout = setTimeout(() => {
			setPatrimonialDependences([
				{
					id: location?.state?.planilla?.patrimonial_dependency_id,
					label: location?.state?.planilla?.patrimonial_dependency
				}
			]);
			setPatrimonialDependence({
				id: location?.state?.planilla?.patrimonial_dependency_id,
				label: location?.state?.planilla?.patrimonial_dependency
			});
		}, 300);
		return () => clearTimeout(timeout);
	}, [location.state]);

	useEffect(() => {
		if (!location?.state?.planilla) return;
		const timeout2 = setTimeout(() => {
			if (years) {
				setYear({ id: location?.state?.planilla?.year, label: String(location?.state?.planilla?.year) });
			}
		}, 600);
		return () => clearTimeout(timeout2);
	}, [months]);

	const getMovementTypeById = (id?: number) => {
		if (!id) return '-';

		return id === 1 ? 'Alta' : 'Baja';
	};

	const getMonth = (month?: number, year?: number) => {
		if (!month) return '-';

		return `${String(month).padStart(2, '0')}/${year}`;
	};

	return (
		<>
			{appContext?.showSpinner ? <Spinner /> : null}

			<HeaderForm title={'Auditar Planilla de Movimientos Mensual'} />

			{showStatusModal && (
				<FormDialog
					show={showStatusModal}
					title={'Cambiar estado'}
					content={
						<OverviewMovementStatusForm
							entity={selectedRecord}
							movementsStatus={statusList}
							handleSubmit={(entity) => {
								onHandleChangeStatus(entity);
							}}
							handleCancel={() => {
								onHideStatusModal();
							}}
						/>
					}
					actions={<></>}
					handleClose={(value) => onHideStatusModal()}
				/>
			)}

			{showHistoryModal && (
				<FormDialog
					show={showHistoryModal}
					title={'Historial'}
					content={<PropertyMovementTimeline movementId={selectedRecord?.movement_id} />}
					actions={<></>}
					handleClose={(value) => onHideModalHistory()}
				/>
			)}

			{showFilesModal && (
				<FormDialog
					show={showFilesModal}
					title={'Documentos adjuntos'}
					content={<AttachmentsFiles files={selectedRecord?.files ? selectedRecord.files : []} />}
					actions={<></>}
					handleClose={(value) => onHideModalFiles()}
				/>
			)}

			<Grid
				container
				spacing={2}
				mt={1}
				mb={1}>
				<Grid
					item
					xs={10}
					sm={10}
					md={10}
					xl={10}
					lg={10}>
					<Grid
						container
						spacing={2}
						direction="row"
						justifyContent="space-between"
						alignItems="center">
						<Grid
							item
							xs={12}
							sm={12}
							md={3}
							xl={3}
							lg={3}>
							<FormGenericSelect
								value={patrimonialDependence}
								label={LABELS_PRESENTATION_STEP_ONE_FORM.patrimonialDependence}
								id="patrimonialDependence"
								options={patrimonialDependences}
								handleChange={setPatrimonialDependence}
								error={errors.patrimonialDependence}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={3}
							xl={3}
							lg={3}>
							<FormGenericSelect
								id={'year'}
								value={year}
								label={LABELS_PRESENTATION_STEP_ONE_FORM.year}
								handleChange={setYear}
								options={years}
								error={errors.year}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={3}
							xl={3}
							lg={3}>
							<FormGenericSelect
								value={year ? month : undefined}
								label={LABELS_PRESENTATION_STEP_ONE_FORM.month}
								id="month"
								options={year ? months : []}
								disabled={year === undefined}
								handleChange={setMonth}
								error={errors.month}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={3}
							xl={3}
							lg={3}>
							<FormGenericSelect
								value={month ? movementType : undefined}
								label={LABELS_PRESENTATION_STEP_ONE_FORM.movementType}
								id="movementType"
								options={month ? movementsType : []}
								disabled={month === undefined}
								handleChange={setMovementType}
								error={errors.movementType}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={2}
					sm={2}
					md={2}
					xl={2}
					sx={{ display: 'flex', alignItems: 'center' }}
					lg={2}>
					<ButtonConfirm
						sx={{ ml: 3 }}
						variant="contained"
						onClick={() => onHandleGenerate()}
						disabled={movementType === undefined}>
						Buscar
					</ButtonConfirm>
				</Grid>
			</Grid>

			<Box
				sx={{
					border: '1px solid lightgray',
					borderRadius: '6px',
					padding: 1,
					width: '100%',
					mt: 2
				}}>
				<Typography
					fontSize={16}
					variant="h6">
					TIPO:{' '}
					<Typography
						fontSize={16}
						variant="caption">
						{getMovementTypeById(sheet?.movement_type)}
					</Typography>
				</Typography>
				<Typography
					fontSize={16}
					variant="h6">
					JURISDICCIÓN:{' '}
					<Typography
						fontSize={16}
						variant="caption">
						{organizationalData?.jurisdictions?.name || '-'}
					</Typography>
				</Typography>
				<Typography
					fontSize={16}
					variant="h6">
					UNIDAD ORGANIZACIONAL:
					<Typography
						fontSize={16}
						variant="caption">
						{organizationalData.name || '-'}
					</Typography>
				</Typography>
				<Typography
					fontSize={16}
					variant="h6">
					MES:{' '}
					<Typography
						fontSize={16}
						variant="caption">
						{getMonth(sheet?.month, sheet?.year)}
					</Typography>
				</Typography>
			</Box>

			{!enableForChanges && sheet !== undefined ? (
				<Stack
					sx={{ width: '100%' }}
					spacing={2}
					mb={1}>
					<Alert severity="warning">
						<AlertTitle>Planilla Consolidada</AlertTitle>
						Esta planilla ya fue revisada y se encuentra en estado consolidado. No podra editar los estados ya
						definidos.
					</Alert>
				</Stack>
			) : null}

			<Box sx={{ width: '100%', border: '1px solid lightgray', borderRadius: '6px', padding: 1, mt: 2 }}>
				<Box
					sx={{
						my: 3,
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%'
					}}>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<Typography variant="h6">Total de Movimientos:</Typography>
						<Typography variant="h6">{movements.length}</Typography>
					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center', gap: 6 }}>
						<Typography variant="h6">Total General:</Typography>
						<Typography variant="h4">{currencyLocalFormat(total)}</Typography>
					</Box>
				</Box>

				<PresentationSheetAuditTable
					elements={movements}
					handleToggleStatus={(entity: IPresentationSheetMovementAudit) => {
						onHandleShowStatusModal(entity);
					}}
					handleAttachments={(entity: IPresentationSheetMovementAudit) => {
						onHandleShowModalFiles(entity);
					}}
					handleHistory={(entity: IPresentationSheetMovementAudit) => {
						onHandleShowModalHistory(entity);
					}}
				/>
			</Box>

			<Grid
				container
				spacing={2}
				mb={1}
				direction="row"
				justifyContent="center"
				alignItems="center">
				<Grid item>
					<ButtonNextPrev
						key={'back'}
						sx={{ m: 2 }}
						variant="contained"
						onClick={() => onHandleCancel()}>
						{'Cancelar'}
					</ButtonNextPrev>
					<ButtonConfirm
						key={'finalizar'}
						sx={{ ml: 3 }}
						variant="contained"
						onClick={() => onHandleSubmitt()}
						disabled={!enableForChanges}>
						{presentationContext?.isDirector ? (total == 0 ? 'Consolidar' : 'Confirmar') : 'Guardar'}
					</ButtonConfirm>
				</Grid>
			</Grid>
		</>
	);
});

PresentationSheetAuditPage.displayName = 'Planilla de Presentación: Paso I';

export { PresentationSheetAuditPage };
