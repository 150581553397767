import { Button, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Download, VisibilitySharp, Upload, ArrowDropUp, ArrowDropDown } from '@mui/icons-material/';
import {
	useReactTable,
	getCoreRowModel,
	getFilteredRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFacetedMinMaxValues,
	getPaginationRowModel,
	getSortedRowModel,
	flexRender
} from '@tanstack/react-table';

const status = {
	1: 'Pendiente',
	2: 'Lista para enviar',
	3: 'Enviada',
	4: 'Observada',
	5: 'Rectificada',
	6: 'Consolidada',
	7: 'No presentada'
};

interface PresentationSheetManagementTableProps {
	elements: Array<any>;
	onUploadSheet?(row: any): void;
	onDownloadSheet?(payload: { movement_type: string }): void;
	onViewDetail?(row: any): void;
}

export const PresentationSheetManagementTable: React.FC<PresentationSheetManagementTableProps> = ({
	elements,
	onUploadSheet,
	onDownloadSheet,
	onViewDetail
}) => {
	const [showSubTableContent, setShowSubTableContent] = useState<Array<{ id: string; show: boolean }>>([]);

	const getSubtableRowById = (id: string) => {
		return showSubTableContent.find((el) => el.id === id) || { id: '0', show: false };
	};

	const table = useReactTable({
		data: elements,
		columns: [
			{
				accessorKey: 'year',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Año</span>,
				cell: ({ row: { original } }) => {
					return <div>{original.year}</div>;
				}
			},
			{
				accessorKey: 'month',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Mes</span>,
				cell: ({ row: { original } }) => {
					return <div>{original.month}</div>;
				}
			},
			// {
			// 	accessorKey: 'status',
			// 	enableSorting: false,
			// 	enableGlobalFilter: false,
			// 	enableColumnFilter: false,
			// 	header: () => <span>Estado</span>,
			// 	cell: ({ row: { original } }) => {
			// 		const colors: { [key: number]: string } = {
			// 			1: '#B1AFFF',
			// 			2: '#66DE93',
			// 			3: '#FFD56F',
			// 			4: '#86C8B6',
			// 			5: '#8DCBE6',
			// 			6: '#FF8787',
			// 			7: '#B1AFFF'
			// 		};
			// 		return (
			// 			<div>
			// 				<Typography
			// 					sx={{ wordBreak: 'break-all', minWidth: '100px' }}
			// 					padding="4px 6px"
			// 					borderRadius="6px"
			// 					bgcolor={colors[original.status as keyof typeof colors]}
			// 					fontSize={14}
			// 					color="black"
			// 					display="inline-block"
			// 					height="auto"
			// 					textTransform={'uppercase'}
			// 					fontWeight={800}>
			// 					{status[original.status as keyof typeof status] || '-'}
			// 				</Typography>
			// 			</div>
			// 		);
			// 	}
			// },
			{
				accessorKey: 'actions',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Acciones</span>,
				cell: ({ row: { original } }) => {
					const { show } = getSubtableRowById(original.id);

					return (
						<div>
							<Button
								id="menu-btn"
								onClick={() => {
									setShowSubTableContent((current) =>
										current.map((el) => {
											if (el.id === original.id) el.show = !el.show;
											else el.show = false;
											return el;
										})
									);
								}}>
								Ver planillas {show ? <ArrowDropUp /> : <ArrowDropDown />}
							</Button>
						</div>
					);
				}
			}
		],
		filterFns: { fuzzy: () => false },
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedMinMaxValues: getFacetedMinMaxValues()
	});

	useEffect(() => {
		setShowSubTableContent(elements.map((el) => ({ id: el.id, show: true })));
	}, [elements]);

	return (
		<Box>
			<table className="movements-table">
				<thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<th
										key={header.id}
										colSpan={header.colSpan}>
										{header.isPlaceholder ? null : (
											<div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
										)}
									</th>
								);
							})}
						</tr>
					))}
				</thead>

				<tbody>
					{table.getRowModel().rows.map((row, id) => {
						return (
							<React.Fragment key={id}>
								<tr key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<td
												onClick={() => void 0}
												key={cell.id}>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										);
									})}
								</tr>
								{getSubtableRowById(row.original.id).show ? (
									<tr key={'subtable'}>
										<td
											colSpan={4}
											className="sub-table-component">
											<Box
												sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}
												padding="12px 24px"
												bgcolor="#f9f9f9">
												<Typography variant="h6">Planillas del mes de {row.original.month}</Typography>
												<table>
													<thead>
														<tr>
															<th colSpan={2}>Tipo de Planilla</th>
															<th colSpan={2}>Estado</th>
															<th>Acciones</th>
														</tr>
													</thead>
													<tbody>
														{row.original.sheets.map((sheet: any, index: number) => {
															return (
																<tr key={index}>
																	<td colSpan={2}>
																		<div>{sheet.type}</div>
																	</td>
																	<td colSpan={2}>
																		<div>{sheet?.status}</div>
																	</td>
																	<td>
																		<Box
																			style={{
																				display: 'flex',
																				justifyContent: 'space-around'
																			}}
																			sx={{ display: 'flex', justifyContent: 'space-between' }}>
																			<Tooltip title={`${sheet.message ?? ''}`}>
																				<div>
																					<Button
																						fullWidth
																						size="small"
																						disabled={sheet.statusCode === 404}
																						onClick={() => {
																							onViewDetail && onViewDetail(sheet);
																						}}
																						color="primary">
																						<VisibilitySharp sx={{ marginRight: 1 }} />
																						Ver
																					</Button>
																				</div>
																			</Tooltip>
																			<Tooltip title={`${sheet.message ?? ''}`}>
																				<div>
																					<Button
																						fullWidth
																						disabled={sheet.disableDownload}
																						size="small"
																						onClick={() => {
																							if (onDownloadSheet) {
																								const data = {
																									movement_type: sheet.movementTypeId,
																									sheetId: sheet?.sheetId ?? null
																								};
																								onDownloadSheet(data);
																							}
																						}}
																						color="primary">
																						<Download sx={{ marginRight: 1 }} />
																						Descargar
																					</Button>
																				</div>
																			</Tooltip>
																			<Tooltip title={`${sheet.message ?? ''}`}>
																				<div>
																					<Button
																						fullWidth
																						disabled={!sheet.id || sheet?.status == 'Consolidada'}
																						size="small"
																						onClick={() => {
																							onUploadSheet && onUploadSheet(sheet);
																						}}
																						color="primary">
																						<Upload sx={{ marginRight: 1 }} />
																						Cargar
																					</Button>
																				</div>
																			</Tooltip>
																		</Box>
																	</td>
																</tr>
															);
														})}
													</tbody>
												</table>
											</Box>
										</td>
									</tr>
								) : null}
							</React.Fragment>
						);
					})}

					{elements.length === 0 ? (
						<tr>
							<td colSpan={4}>
								<Typography variant="subtitle1">No se encontraron resultados para la búsqueda realizada.</Typography>
							</td>
						</tr>
					) : null}
				</tbody>
			</table>
		</Box>
	);
};
