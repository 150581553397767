import { ISelectOption } from 'interfaces/commons/IParameters';
import { Select } from '../select/Select';

export const contractTypesOptions: Array<ISelectOption> = [
	{ id: 1, label: 'Compra Directa' },
	{ id: 2, label: 'Concurso de Precios' },
	{ id: 3, label: 'Contado Contra Entrega' },
	{ id: 4, label: 'Contratación Directa' },
	{ id: 5, label: 'Contratación Directa Art 3ro' },
	{ id: 6, label: 'Contrato' },
	{ id: 7, label: 'Licitación Privada' },
	{ id: 8, label: 'Licitación Pública' }
];

export const defaultContratTypeOption = { label: 'Seleccione Tipo de Contratación', id: 0, disabled: true };

interface ContractTypeSelectProps {
	value?: ISelectOption | Array<ISelectOption>;
	onChange?(selected: ISelectOption | Array<ISelectOption>): void;
	size?: 'small' | 'medium';
	disabled?: boolean;
	showLabel?: boolean;
}

export const ContractTypeSelect = ({ value, onChange, size, disabled, showLabel = true }: ContractTypeSelectProps) => {
	return (
		<Select
			value={value || defaultContratTypeOption}
			onChange={onChange}
			size={size}
			disabled={disabled}
			label={showLabel ? 'Tipo de Contratación' : ''}
			options={[defaultContratTypeOption, ...contractTypesOptions]}
		/>
	);
};
