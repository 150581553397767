export enum ApiPaths {
	API = 'http://localhost:3000/api',
	LOGIN = 'auth/login',
	ME = 'auth/me',
	RESET_PASSWORD = 'auth/recovery-password',
	RECOVERY_TOKEN = 'auth/search-user-token',
	CONFIRM_PASSWORD = 'auth/confirm-password',
	USERS = '/users',
	ROLES = '/profiles',
	SIGN_IN = 'auth/signin',
	USERS_GROUPS = '/users-group',
	PATRIMONIAL_DEPENDENCES = '/patrimonial-dependencies',
	ORGANISMS = '/organizational',
	CHANGE_PASSWORD = 'auth/change-password',
	UPDATE_PERSON = 'person-data',
	SHEETS = 'sheets',
	BALANCE = 'balances',
	JURISDICTIONS = '/jurisdictions',
	PROPERTY_MOVEMENTS = 'property-movements',
	DEREGISTRATION_TYPES = 'types/property-deregistration-types',
	PROPERTIES = 'properties',
	DEREGISTRATION = 'property-deregistration',
	DEREGISTRATION_FILES = 'property-deregistration-files',
	REGISTRATION = 'property-registration',
	REGISTRATION_TYPES = 'types/high-types',
	VOUCHER_TYPES = 'types/voucher-types',
	ITEM_TYPE = '/types/property-types'
}
