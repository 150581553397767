import { Box } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { SearchBarByText } from 'components/globals/searchBar/SearchBarByText';
import { FormDialog } from 'components/globals/dialog/FormDialog';
import { CustomDecideDialog } from 'components/globals/dialog/CustomDeleteDialog';

// Dependencias Patrimoniales
import { usePatrimonialDependenceContext } from 'hooks/usePatrimonialDependenceContext';
import { IPatrimonialDependence } from 'interfaces/patrimonial/IPatrimonial';
import { PatrimonialDependenceTable } from 'components/patrimonialDependence/PatrimonialDependenceTable';
import { OverviewPatrimonialDependenceForm } from 'components/patrimonialDependence/Overview';
import { IOrganizationalUnit } from 'interfaces/organism/IOrganism';
import { useAppContext } from 'hooks/useAppContext';

export const PATRIMONIAL_DEPENDENCES_LABELS = {
	label: 'Dependencia Patrimonial',
	add: 'Agregar Dependencia Patrimonial',
	update: 'Editar Dependencia Patrimonial',
	delete: '¿Desea Eliminar la Dependencia Patrimonial?',
	list: 'Dependencias Patrimoniales',
	manage: 'Gestión de Dependencias Patrimoniales',
	searchLabel: 'Ingrese código o nombre de la Dependencia Patrimonial'
};

const PatrimonialDependencesOverview = memo(() => {
	const patrimonialDependenceContext = usePatrimonialDependenceContext();
	const appContext = useAppContext();

	const [patrimonialDependences, setPatrimonialDependences] = useState<IPatrimonialDependence[] | []>([]);
	const [organizationalUnits, setOrganizationalUnits] = useState<IOrganizationalUnit[] | []>([]);

	useEffect(() => {
		appContext?.onHandleTryGetAllOrganisms();
		patrimonialDependenceContext?.onHandleGetAll(patrimonialDependenceContext?.filters, {
			page: patrimonialDependenceContext?.meta?.currentPage,
			limit: patrimonialDependenceContext?.meta?.itemsPerPage
		});
	}, []);

	useEffect(() => {
		setPatrimonialDependences(patrimonialDependenceContext?.entities ? patrimonialDependenceContext?.entities : []);
	}, [patrimonialDependenceContext?.entities]);

	useEffect(() => {
		setOrganizationalUnits(appContext?.organisms);
	}, [appContext?.organisms]);

	// START Handle Add entity Modal
	const [showManageEntityModal, toggleShowManageEntityModal] = useState<boolean>(false);

	const [selectedPatrimonialDependence, setSelectedPatrimonialDependence] = useState<
		IPatrimonialDependence | undefined
	>(undefined);
	const handleTogglePatrimonialDependenceModal = (
		show: boolean = false,
		entity: IPatrimonialDependence | undefined = undefined
	) => {
		setSelectedPatrimonialDependence(entity);
		toggleShowManageEntityModal(show);
	};

	const handleSubmitPatrimonialDependenceModal = async (entity: IPatrimonialDependence | undefined) => {
		if (!entity) {
			return;
		}
		let result = await patrimonialDependenceContext?.onHandleCreateOrUpdate(entity);

		if (result) {
			handleTogglePatrimonialDependenceModal();
			patrimonialDependenceContext?.onHandleGetAll(patrimonialDependenceContext?.filters, {
				page: patrimonialDependenceContext?.meta?.currentPage,
				limit: patrimonialDependenceContext?.meta?.itemsPerPage
			});
		}
	};
	// END Handle Add entity Modal

	// START Handle Delete entity Modal
	const [showDeleteEntityModal, toggleShowDeleteEntityModal] = useState<boolean>(false);

	const handleShowDeletePatrimonialDependence = (entity: IPatrimonialDependence | undefined) => {
		if (!entity) {
			return;
		}
		setSelectedPatrimonialDependence(entity);
		toggleShowDeleteEntityModal(true);
	};

	const handleHideDeletePatrimonialDependence = () => {
		setSelectedPatrimonialDependence(undefined);
		toggleShowDeleteEntityModal(false);
	};

	const handleSubmitDeletePatrimonialDependence = async (entity: IPatrimonialDependence | undefined) => {
		if (!entity) {
			return;
		}
		let result = await patrimonialDependenceContext?.onHandleDelete(entity.id ? entity.id : null);

		if (result) {
			handleHideDeletePatrimonialDependence();
			patrimonialDependenceContext?.onHandleGetAll(patrimonialDependenceContext?.filters, {
				page: patrimonialDependenceContext?.meta?.currentPage,
				limit: patrimonialDependenceContext?.meta?.itemsPerPage
			});
		}
	};
	// END Handle Delete entity Modal

	return (
		<>
			{showManageEntityModal && (
				<FormDialog
					show={showManageEntityModal}
					title={
						selectedPatrimonialDependence ? PATRIMONIAL_DEPENDENCES_LABELS.update : PATRIMONIAL_DEPENDENCES_LABELS.add
					}
					content={
						<OverviewPatrimonialDependenceForm
							entity={selectedPatrimonialDependence}
							handleCancel={() => {
								handleTogglePatrimonialDependenceModal();
							}}
							handleSubmit={(entity: IPatrimonialDependence) => {
								handleSubmitPatrimonialDependenceModal(entity);
							}}
							organizationalUnits={organizationalUnits ? organizationalUnits : []}
						/>
					}
					actions={<></>}
					handleClose={(value) => {
						handleTogglePatrimonialDependenceModal();
					}}
				/>
			)}

			{showDeleteEntityModal && (
				<CustomDecideDialog
					message={PATRIMONIAL_DEPENDENCES_LABELS.delete}
					handleSubmit={() => {
						handleSubmitDeletePatrimonialDependence(selectedPatrimonialDependence);
					}}
					handleDecline={() => {
						handleHideDeletePatrimonialDependence();
					}}
				/>
			)}

			<Box sx={{ width: '100%', typography: 'body1' }}>
				<SearchBarByText
					label={PATRIMONIAL_DEPENDENCES_LABELS.searchLabel}
					defaultValue={patrimonialDependenceContext?.filters?.term}
					onHandleSearch={(ev: any) => {
						patrimonialDependenceContext?.onHandleSearByFilters({ term: ev });
					}}
					submitLabel={PATRIMONIAL_DEPENDENCES_LABELS.add}
					onHandleSubmit={(ev: any) => {
						handleTogglePatrimonialDependenceModal(true);
					}}
					onHandleClear={(ev: any) => {
						patrimonialDependenceContext?.onHandleClearFilters();
					}}
				/>

				<Box sx={{ overflowX: 'scroll', marginTop: '5px' }}>
					<PatrimonialDependenceTable
						elements={patrimonialDependences}
						handleUpdate={(entity: any) => {
							handleTogglePatrimonialDependenceModal(true, entity);
						}}
						handleDelete={(entity: any) => {
							handleShowDeletePatrimonialDependence(entity);
						}}
					/>
				</Box>
			</Box>
		</>
	);
});

PatrimonialDependencesOverview.displayName = 'Dependencias Patrimoniales';

export { PatrimonialDependencesOverview };
