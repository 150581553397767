/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { FormGenericSelect } from 'components/globals/inputs/FormGenericSelect';
import { ISelectOption } from 'interfaces/commons/IParameters';
import { ButtonConfirm, ButtonDecline } from 'components/globals/customsBtn/customBtn';
import { FormGenericInput } from 'components/globals/inputs/FormGenericInput';
import { DEFAULT_NO_ERROR, requiredPartialNomenclatureCode, requiredRangeNumber, requiredValidator } from 'helpers/Validators/Validators';
import { IJurisdiction } from 'interfaces/jurisdictions/IJurisdictions';
import { IOrganizationalUnit } from 'interfaces/organism/IOrganism';

export interface IOverviewOrganizationalUnitForm {
	entity: IOrganizationalUnit | undefined;
	handleSubmit: (data: IOrganizationalUnit) => void;
	handleCancel: () => void;
	jurisdictions: IJurisdiction[];
}

export interface IErrorsForm {
	jurisdiction?: string | undefined;
	code?: string | undefined;
	name?: string | undefined;
}

export const LABELS_ORGANIZATIONAL_UNIT_FORM = {
	jurisdiction: 'Jurisdicción',
	code: 'Código',
	name: 'Nombre de la Unidad Organizacional'
};

const SEPARATOR = '.';

export const OverviewOrganizationalUnitForm = ({ entity, handleSubmit, handleCancel, jurisdictions }: IOverviewOrganizationalUnitForm) => {
	const [jurisdiction, setJurisdiction] = useState<ISelectOption | undefined>();
	const [code, setCode] = useState<string | undefined>();
	const [prefixCode, setPrefixCode] = useState<string | undefined>();
	const [name, setName] = useState<string | undefined>(entity?.name);
	const [errors, setErrors] = useState<IErrorsForm>({} as IErrorsForm);
	const isEdit = !!entity;

	useEffect(() => {
		let jurisdiction: ISelectOption = { id: 0, label: '' };
		let prefixCode = entity?.code ? entity.code.split(SEPARATOR) : [];
		if (entity && prefixCode && prefixCode.length > 2) {
			let prefix = prefixCode[0].concat(SEPARATOR.concat(prefixCode[1]));
			let finded = jurisdictions.find((j) => j.code == prefix);
			if (finded) {
				jurisdiction = { id: finded.id, label: finded.name } as ISelectOption;
			}
			setCode(prefixCode[2]);
		}
		setJurisdiction(jurisdiction);
	}, [entity]);

	useEffect(() => {
		setPrefixCode(undefined);
		if (jurisdiction) {
			let prefix = jurisdictions.find((j) => j.id == jurisdiction?.id)?.code;
			if (prefix) {
				setPrefixCode(prefix.concat(SEPARATOR));
			}
		}
	}, [jurisdiction]);

	const validateForm = (): boolean => {
		let newErrors: IErrorsForm = {} as IErrorsForm;
		setErrors(newErrors);

		let jurisdictionError = requiredValidator({ value: jurisdiction?.label, label: LABELS_ORGANIZATIONAL_UNIT_FORM.jurisdiction });
		if (jurisdictionError !== DEFAULT_NO_ERROR) {
			newErrors.jurisdiction = jurisdictionError;
		}

		let codeError = requiredPartialNomenclatureCode({ value: code, label: LABELS_ORGANIZATIONAL_UNIT_FORM.code });
		if (codeError !== DEFAULT_NO_ERROR) {
			newErrors.code = codeError;
		}

		let nameError = requiredValidator({ value: name, label: LABELS_ORGANIZATIONAL_UNIT_FORM.name });
		if (nameError !== DEFAULT_NO_ERROR) {
			newErrors.name = nameError;
		}

		if (Object.entries(newErrors).length !== 0) {
			setErrors(newErrors);
			return false;
		}

		return true;
	};

	const getParsedData = (): IOrganizationalUnit => {
		return {
			id: isEdit ? entity.id : undefined,
			code: code && prefixCode ? prefixCode?.concat(code.trim()).trim() : '',
			name: name ? name.trim() : '',
			jurisdictions_id: jurisdiction?.id
		};
	};

	const onHandleSubmit = (): void => {
		if (!validateForm()) {
			return;
		}
		handleSubmit(getParsedData());
	};

	return (
		<div className="borderedBox">
			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '30px', rowGap: '30px', paddingTop: '30px' }}>
				<FormGenericSelect
					value={jurisdiction}
					label={'Seleccione una ' + LABELS_ORGANIZATIONAL_UNIT_FORM.jurisdiction}
					id="jurisdiction"
					options={jurisdictions.map((at) => {
						return { id: at.id, label: at.name } as ISelectOption;
					})}
					handleChange={setJurisdiction}
					error={errors.jurisdiction}
				/>

				<FormGenericInput
					value={code}
					label={LABELS_ORGANIZATIONAL_UNIT_FORM.code}
					name="code"
					id="code"
					startAdornment={prefixCode}
					handleChange={setCode}
					error={errors.code}
				/>

				<FormGenericInput
					value={name}
					label={LABELS_ORGANIZATIONAL_UNIT_FORM.name}
					name="name"
					id="name"
					handleChange={setName}
					error={errors.name}
				/>
			</Box>

			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
				<ButtonDecline
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => {
						handleCancel();
					}}>
					Cancelar
				</ButtonDecline>
				<ButtonConfirm
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => onHandleSubmit()}>
					Agregar
				</ButtonConfirm>
			</Box>
		</div>
	);
};
