import { useEffect, useMemo, useState } from 'react';
import { FormGenericSelect } from 'components/globals/inputs/FormGenericSelect';
import { ISelectOption } from 'interfaces/commons/IParameters';
import lodash from 'lodash';
import { fetchNomenclatureCodes } from 'services/nomenclatureCodes';
import { Grid, TextField } from '@mui/material';
import { PROPERTY_TYPE_IDS, VEHICLE_TYPE_ID } from 'enums';

interface NomenclatureCodeInputProps {
	onChange?(option: ISelectOption): void;
	value?: ISelectOption;
	size?: 'small' | 'medium' | undefined;
	spacing?: number;
	disabled?: boolean;
	propertyTypeId?: number;
	propertySubtypeId?: number;
	required?: boolean;
	hideDetails?: boolean;
	isAccessory?: boolean;
}

const gridItemProps = {
	item: true,
	xs: 12,
	sm: 12,
	md: 12,
	lg: 8,
	xl: 8
};

export const NomenclatureCodeInput = ({
	onChange,
	value,
	size,
	spacing,
	disabled,
	propertyTypeId,
	propertySubtypeId,
	required,
	hideDetails,
	isAccessory
}: NomenclatureCodeInputProps) => {
	const [selectedOption, setSelectedOption] = useState<ISelectOption | undefined>(value);
	const [options, setOptions] = useState<ISelectOption[]>([]);
	const [defaultOptions, setDefaultOptions] = useState<ISelectOption[]>([]);

	const { group, subGroup, description } = useMemo(() => {
		if (!selectedOption || !selectedOption?.label) return { group: '', subGroup: '', description: '' };

		const values = selectedOption.label.split('|');
		const [group, subGroup, subDescription, description] = values.map((val) => val.trim());

		return {
			group: group.split('-').at(1),
			subGroup,
			description: [subDescription, description].filter((v) => v).join(' - ')
		};
	}, [selectedOption]);

	const handleChange = (value: ISelectOption) => {
		onChange && onChange(value);
		setSelectedOption(value);
	};

	const getNomenclatureCodes = lodash.debounce(async (code: string) => {
		if (!code || (code.includes('.') && options.length)) return setOptions(defaultOptions);
		setOptions([]);
		setOptions(await fetchNomenclatureCodes(code, propertyTypeId, propertySubtypeId));
	}, 300);

	const handleSetDefaultOptions = async () => {
		let data = await fetchNomenclatureCodes(undefined, propertyTypeId, propertySubtypeId);

		if (isAccessory && propertySubtypeId !== VEHICLE_TYPE_ID.OTHERS && propertyTypeId === PROPERTY_TYPE_IDS.VEHICLE) {
			data = [...(await fetchNomenclatureCodes(undefined, propertyTypeId, VEHICLE_TYPE_ID.OTHERS)), ...data];
		}

		setDefaultOptions(data);
		setOptions(data);
	};

	useEffect(() => {
		if (options.length === 0) {
			handleSetDefaultOptions();
			return;
		}

		if (value && options.length > 0) {
			const getOption = options.find((item) => item.id === value.id);
			handleChange(getOption as ISelectOption);
		}
	}, [options]);

	useEffect(() => setSelectedOption(value), [value]);

	useEffect(() => {
		propertyTypeId && handleSetDefaultOptions();
	}, []);

	useEffect(() => {
		propertyTypeId && propertySubtypeId && handleSetDefaultOptions();
	}, [propertySubtypeId]);

	return (
		<Grid
			container
			spacing={spacing ?? 2}>
			<Grid
				{...gridItemProps}
				xl={hideDetails ? 12 : 4}
				lg={hideDetails ? 12 : 4}>
				<FormGenericSelect
					required={required}
					disabled={disabled}
					handleChange={handleChange}
					handleInputChange={getNomenclatureCodes}
					id="nomenclature-code"
					label={'Ingrese Código Nomenclador'}
					options={options}
					size={size ?? 'small'}
					value={selectedOption}
				/>
			</Grid>
			{subGroup && !hideDetails ? (
				<Grid
					item
					marginLeft={0}
					pt={2}
					xl={8}
					lg={8}
					display="flex"
					gap={spacing ?? 2}
					width={'100%'}>
					<Grid
						item
						xs={4}
						md={4}
						sm={4}
						lg={3}
						xl={3}>
						<TextField
							fullWidth
							disabled={!group || disabled}
							value={group}
							label="Grupo"
							size={size ?? 'small'}
						/>
					</Grid>

					<Grid
						item
						xs={4}
						md={4}
						sm={4}
						lg={3}
						xl={3}>
						{subGroup && group ? (
							<TextField
								fullWidth
								disabled={!subGroup || disabled}
								value={subGroup}
								label="Sub Grupo"
								size={size ?? 'small'}
							/>
						) : null}
					</Grid>
					<Grid
						item
						xs={4}
						md={4}
						sm={4}
						lg={8}
						xl={8}>
						<TextField
							fullWidth
							disabled={!description || disabled}
							value={description}
							label="Descripción"
							size={size ?? 'small'}
						/>
					</Grid>
				</Grid>
			) : null}
		</Grid>
	);
};
