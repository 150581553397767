import { useMemo, FC } from 'react';
import { IUserTableProps } from 'interfaces/user/IUser';

import { Row, Cell } from '@tanstack/react-table';
import { defaultColumns } from './UserColumnDef';
import { useUsersContext } from 'hooks/useUsersContext';
import { PaginationTable } from 'components/globals/tabs/PaginationTable';
import { CustomTable } from 'components/globals/table/customTable';

export const UsersTable: FC<IUserTableProps> = ({
	elements,
	handleToggleStatus,
	handleEditUser,
	handleResetPassword
}) => {
	const usersContext = useUsersContext();
	const columns = useMemo<typeof defaultColumns>(() => [...defaultColumns], [defaultColumns]);

	const handleAction = (row: Row<any>, cell?: Cell<any, any>, evt?: any) => {
		if (!cell?.id?.includes('actions') && !cell?.id?.includes('active')) {
			return;
		}

		switch (row.original?.action) {
			case 'changeStatus':
				handleToggleStatus(row.original);
				break;
			case 'edit':
				handleEditUser(row.original);
				break;
			case 'refresh':
				handleResetPassword(row.original);
				break;
			default:
				break;
		}
	};

	return (
		<div className={'customBodyContainerTable'}>
			<PaginationTable
				currentPage={usersContext?.usersMeta?.currentPage}
				totalPages={usersContext?.usersMeta?.totalPages}
				showDetails={true}
				itemsPerPage={usersContext?.usersMeta?.itemsPerPage}
				totalItems={usersContext?.usersMeta?.itemCount}
				handleChangePage={(page: number, limit?: number) => usersContext?.onHandleChangePage({ page: page, limit })}
			/>

			<CustomTable
				columns={columns}
				elements={elements}
				defaultSortinField={'fullName'}
				itemsPerPage={usersContext?.usersMeta?.itemsPerPage}
				handleAction={(row: Row<any>, cell?: Cell<any, any>, evt?: any) => handleAction(row, cell, evt)}
			/>

			<div className="h-2" />
			<PaginationTable
				currentPage={usersContext?.usersMeta?.currentPage}
				totalPages={usersContext?.usersMeta?.totalPages}
				itemsPerPage={usersContext?.usersMeta?.itemsPerPage}
				totalItems={usersContext?.usersMeta?.totalItems}
				handleChangePage={(page: number, limit?: number) => usersContext?.onHandleChangePage({ page: page, limit })}
			/>
		</div>
	);
};
