import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import PulseLoader from 'react-spinners/PulseLoader';

// Hooks
import { useMovContext } from 'hooks/useMovContext';

//Service
import { getNomenclatureCode, getPropertyTypes } from 'services/crudService';

import { Typography, Box, TextField, Button, Modal } from '@mui/material';
import { BounceLoader } from 'react-spinners';
import { IStep3Accessories, IStep3GenericValues } from '../interfaces';

const styleModal = {
	position: 'absolute' as const,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '33%',
	minWidth: '532px',
	maxHeight: '95%',
	bgcolor: '#e4e4e4',
	border: '2px solid #000000b9',
	boxShadow: 24,
	overflow: 'auto' as const
};

interface IModalAccessoriesProps {
	openModal: boolean;
	setOpenModal: Dispatch<SetStateAction<boolean>>;
	selectedRow: IStep3GenericValues;
	setSelectedAccessoryRow: Dispatch<SetStateAction<IStep3Accessories | null>>;
}

interface IPropertyType {
	id: number;
	name: string;
}

export const ModalSelectAccessory = ({ openModal, setOpenModal, selectedRow, setSelectedAccessoryRow }: IModalAccessoriesProps) => {
	const [open, setOpen] = useState(true);
	const [loading, setLoading] = useState(false);
	const [opacity, setOpacity] = useState(false);

	const handleClose = () => {
		setOpen(false);
		setOpenModal(false);
	};

	const handleLoading = () => {
		setLoading(true);
		setTimeout(() => {
			setOpacity(true);
		}, 300);
		setTimeout(() => {
			handleClose();
		}, 1000);
	};

	const handleSelectedAccessory = (selectedAccessory: IStep3Accessories) => {
		if (selectedAccessory) {
			setSelectedAccessoryRow(selectedAccessory);
			handleClose();
		} else {
			setSelectedAccessoryRow(null);
		}
	};
	return (
		<div>
			<Modal
				keepMounted
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<Box sx={styleModal}>
					<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
						<>
							<Typography
								variant="h5"
								sx={{ textAlign: 'center', pt: 1, pb: 1, backgroundColor: 'rgb(51, 82, 100)', color: 'white', width: '100%' }}>
								Accesorios
							</Typography>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									flexDirection: 'row',
									flexWrap: 'wrap',
									rowGap: '10px',
									columnGap: '10px',
									minHeight: '250px',
									width: '100%',
									pl: 2,
									pr: 2,
									mt: 2,
									mb: 2
								}}>
								{!selectedRow?.accessories || selectedRow?.accessories?.length < 1 ? <Typography>No posee accesorios.</Typography> : null}
								{selectedRow?.accessories?.map((accessory, index) => (
									<Box
										key={`${accessory.id} - ${accessory.idInDatabase}`}
										onClick={() => handleSelectedAccessory(accessory)}
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'space-between',
											p: 1,
											border: '1px solid black',
											cursor: 'pointer',
											width: '100%',
											height: '150px',
											overflowY: 'auto',
											wordBreak: 'break-word',
											borderRadius: '5px',
											backgroundColor: 'white',
											transition: 'all 0.1s ease-in-out',
											boxShadow: '0px 0px 19px -10px rgba(0,0,0,0.74) inset',
											WebkitBoxShadow: '0px 0px 19px -10px rgba(0,0,0,0.74) inset',
											MozBoxShadow: '0px 0px 19px -10px rgba(0,0,0,0.74) inset',
											'&:hover': {
												backgroundColor: 'rgba(51, 82, 100,0.3)',
												transition: 'all 0.1s ease-in-out',
												boxShadow: '0px 0px 29px -15px rgba(0,0,0,0) inset',
												WebkitBoxShadow: '0px 0px 29px -15px rgba(0,0,0,0.0) inset',
												MozBoxShadow: '0px 0px 29px -15px rgba(0,0,0,0.0) inset'
											}
										}}>
										<Box>
											<Typography variant="h6">{`Accesorio N°${index + 1}`}</Typography>
											<Typography variant="body1">{`Código: ${accessory.nomenclatureCode}`}</Typography>
											<Typography variant="body1">{`Descripción: ${accessory.description}`}</Typography>
											<Typography variant="body1">{`Cantidad: ${accessory.quantity}`}</Typography>
											<Typography variant="body1">{`Precio Unitario: ${Number(accessory.unitPrice).toLocaleString('es-AR', {
												style: 'currency',
												currency: 'ARS'
											})}`}</Typography>
										</Box>
										<Typography
											variant="h6"
											sx={{ mr: '10%' }}
											key={`${accessory.id} - ${accessory.idInDatabase}`}>{`Click para editar el accesorio`}</Typography>
									</Box>
								))}
							</Box>
							<Button
								variant="contained"
								color="primary"
								sx={{ width: '150px', mb: 2 }}
								onClick={() => {
									setOpenModal(false);
									setOpen(false);
								}}>
								Salir
							</Button>
						</>

						{loading && (
							<Box
								sx={{
									zIndex: '9999',
									opacity: opacity ? '1' : '0',
									position: 'absolute',
									backdropFilter: 'blur(2px)',
									height: '100%',
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									transition: 'all 800ms ease-in-out'
								}}>
								<BounceLoader
									size={100}
									speedMultiplier={1.5}
								/>
							</Box>
						)}
					</Box>
				</Box>
			</Modal>
		</div>
	);
};
