import React, { ReactNode, useState } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { AppContext } from './AppContext';
import { tryGetAllRoles } from 'services/rolesService';
import { tryGetAllGroups } from 'services/groupsService';
import { tryGetAllPatrimonialDependences } from 'services/patrimonialDependencesService';
import { tryGetAllOrganisms } from 'services/organismsService';
import { tryGetAllJurisdictions, tryGetAllJurisdictionsBySearch } from 'services/jurisdictionsService';
import { tryGetAllDeregistrationTypes } from 'services/deregistrationTypesService';
import { useAuth } from 'hooks/useAuth';
import { IRoleAction } from 'interfaces/User';
import { RoleById } from 'enums';
import { tryGetAllAdministrativeTypes } from 'services/administrativeTypesService';
import { tryGetAllRegistrationTypes } from 'services/registrationTypesService';
import { tryGetAllItemTypes, tryGetAllVoucherTypes } from 'services/parametersService';

export const AppState = ({ children }: { children: ReactNode | ReactJSXElement }) => {
	const auth = useAuth();

	// START Handle Show and Hide Full Screen Spinner
	const [showSpinner, setShowSpinner] = useState(false);
	const onHandleShowSpinner = () => {
		setShowSpinner(true);
	};
	const onHandleHideSpinner = () => {
		setShowSpinner(false);
	};
	// END Handle Show and Hide Full Screen Spinner

	// START Handle Parameters
	const [groups, setGroups] = useState<any[] | []>([]);
	const onHandleTryGetAllGroups = async () => {
		setGroups([]);
		const response = await tryGetAllGroups({}, {});

		if (response.hasOwnProperty('error')) {
			console.error(response.error);
			return false;
		}

		setGroups(response.items);
		return true;
	};

	const [profiles, setProfiles] = useState<any[] | []>([]);
	const onHandleTryGetAllProfiles = async () => {
		setProfiles([]);
		const response = await tryGetAllRoles({}, {});

		if (response.hasOwnProperty('error')) {
			console.error(response.error);
			return false;
		}

		setProfiles(response.items);
		return true;
	};

	const [patrimonialDependences, setPatrimonialDependences] = useState<any[] | []>([]);
	const onHandleTryGetAllPatrimonialDependences = async (term?: string) => {
		setPatrimonialDependences([]);
		const response = await tryGetAllPatrimonialDependences(term ? { term: term } : {}, {});

		if (response.hasOwnProperty('error')) {
			console.error(response.error);
			return false;
		}

		setPatrimonialDependences(response);
		return true;
	};

	const [organisms, setOrganisms] = useState<any[] | []>([]);
	const onHandleTryGetAllOrganisms = async (term?: string) => {
		setPatrimonialDependences([]);
		const response = await tryGetAllOrganisms(term ? { term } : undefined, {});

		if (response.hasOwnProperty('error')) {
			console.error(response.error);
			return false;
		}

		setOrganisms(response.items);
		return true;
	};

	const [jurisdictions, setJurisdictions] = useState<any[] | []>([]);
	const [isLoadingJurisdictions, setIsLoadingJurisdictions] = useState<boolean>(false);
	const [jurisdictionsError, setJurisdictionsError] = useState<string | undefined>();
	const onHandleTryGetAllJurisdictions = async (term?: string) => {
		setJurisdictions([]);
		let response;
		setIsLoadingJurisdictions(true);
		if (term !== undefined) {
			response = await tryGetAllJurisdictionsBySearch(term ? { term: term } : {}, {});
		} else {
			response = await tryGetAllJurisdictions();
		}
		setIsLoadingJurisdictions(false);

		if (response.hasOwnProperty('error')) {
			console.error(response.error);
			setJurisdictionsError(response.error);
			return false;
		}

		setJurisdictions(response.items);
		return true;
	};
	// END Handle Parameters

	const [deregistrationTypes, setDeregistrationTypes] = useState<any[] | []>([]);
	const [isLoadingDeregistrationTypes, setIsLoadingDeregistrationTypes] = useState<boolean>(false);
	const [deregistrationTypesError, setDeregistrationTypesError] = useState<string | undefined>();
	const onHandleTryGetAllDeregistrationTypes = async () => {
		setDeregistrationTypes([]);
		setDeregistrationTypesError(undefined);
		setIsLoadingDeregistrationTypes(true);
		const response = await tryGetAllDeregistrationTypes();
		setIsLoadingDeregistrationTypes(false);

		if (response.hasOwnProperty('error')) {
			console.error(response.error);
			setDeregistrationTypesError(response.error);
			return false;
		}

		setDeregistrationTypes(response);
		return true;
	};

	const [registrationTypes, setRegistrationTypes] = useState<any[] | []>([]);
	const [isLoadingRegistrationTypes, setIsLoadingRegistrationTypes] = useState<boolean>(false);
	const [registrationTypesError, setRegistrationTypesError] = useState<string | undefined>();
	const onHandleTryGetAllRegistrationTypes = async () => {
		setRegistrationTypes([]);
		setRegistrationTypesError(undefined);
		setIsLoadingRegistrationTypes(true);
		const response = await tryGetAllRegistrationTypes();
		setIsLoadingRegistrationTypes(false);

		if (response.hasOwnProperty('error')) {
			console.error(response.error);
			setRegistrationTypesError(response.error);
			return false;
		}

		setRegistrationTypes(response);
		return true;
	};

	const [administrativeTypes, setAdministrativeTypes] = useState<any[] | []>([]);
	const onHandleTryGetAllAdministrativeTypes = async () => {
		setAdministrativeTypes([]);
		const response = await tryGetAllAdministrativeTypes();
		setAdministrativeTypes(response);
		return true;
	};

	const [voucherTypes, setVoucherTypes] = useState<any[] | []>([]);
	const [isLoadingVoucherTypes, setIsLoadingVoucherTypes] = useState<boolean>(false);
	const [voucherTypesError, setVoucherTypesError] = useState<string | undefined>();
	const onHandleTryGetAllVoucherTypes = async () => {
		setVoucherTypes([]);
		setIsLoadingVoucherTypes(true);
		setVoucherTypesError(undefined);
		const response = await tryGetAllVoucherTypes();
		setIsLoadingVoucherTypes(false);

		if (response.hasOwnProperty('error')) {
			console.error(response.error);
			setVoucherTypesError(response.error);
			return false;
		}

		setVoucherTypes(response);
		return true;
	};

	const [itemTypes, setItemTypes] = useState<any[] | []>([]);
	const [isLoadingItemTypes, setIsLoadingItemTypes] = useState<boolean>(false);
	const [itemTypesError, setItemTypesError] = useState<string | undefined>();
	const onHandleTryGetAllItemTypes = async () => {
		setItemTypes([]);
		setIsLoadingItemTypes(true);
		setItemTypesError(undefined);
		const response = await tryGetAllItemTypes();
		setIsLoadingItemTypes(false);

		/* if (response.hasOwnProperty('error')) {
			console.error(response.error);
			setItemTypesError(response.error);
			return false;
		} */

		setItemTypes(response as { id: number; name: string }[]);
		return true;
	};
	// END Handle Parameters

	// START Handle Permissions
	const getPermision = (requiredRoles: number[] | [], requiredActions: string[] | []): boolean => {
		if (auth === undefined) {
			return false;
		}
		if (requiredRoles.length === 0) {
			return false;
		}
		if (requiredActions.length === 0) {
			return false;
		}
		const user = auth?.user;
		if (user === undefined) {
			return false;
		}
		if (user?.role === undefined) {
			return false;
		}
		if (user.role?.id == RoleById.Super_Admin) return true;
		if (
			!requiredRoles.find((roleId: number) => {
				return roleId == user.role?.id;
			})
		) {
			return false;
		}
		let userActions: IRoleAction[] = user.role?.role_actions ? user.role?.role_actions : [];
		let selectedAction: IRoleAction | undefined = undefined;
		let i = 0;
		while (i < userActions.length && selectedAction !== undefined) {
			if (
				requiredActions.find((ra: string) => {
					return ra === userActions[i].resource;
				})
			) {
				selectedAction = userActions[i];
			}
			i++;
		}
		if (selectedAction !== undefined) {
			return selectedAction.is_active;
		}
		return false;
	};

	const canAccessModule = (module: string): boolean => {
		let result: boolean = false;
		switch (module) {
			case 'manage:Properties': //Acceso a módulo de gestión de Altas Patrimoniales
				result = getPermision([RoleById.Responsable_Patrimonial, RoleById.Operador_Dependencia_Patrimonial], [module]);
				break;
			case 'manage:PropertiesDeregistration': //Acceso a módulo de gestión de Bajas Patrimoniales
				result = getPermision([RoleById.Responsable_Patrimonial, RoleById.Operador_Dependencia_Patrimonial], [module]);
				break;
			case 'manage:Movements': //Acceso a módulo de gestión de Movimientos
				result = getPermision(
					[RoleById.Operador_Dir_de_BienesFiscales, RoleById.Responsable_Patrimonial, RoleById.Operador_Dependencia_Patrimonial],
					[module]
				);
				break;
			case 'manage:Sheets': //Acceso a módulo de gestión de Movimientos
				result = getPermision(
					[RoleById.Responsable_Patrimonial, RoleById.Director_de_BienesFiscales, RoleById.Operador_Dir_de_BienesFiscales],
					[module]
				);
				break;
			case 'manage:Reports': //Acceso a módulo de gestión de Reportes
				result = getPermision(
					[RoleById.Responsable_Patrimonial, RoleById.Director_de_BienesFiscales, RoleById.Operador_Dir_de_BienesFiscales],
					[module]
				);
				break;
			case 'manage:Balance': //Acceso a módulo de gestión de Balances
				result = getPermision(
					[RoleById.Director_de_BienesFiscales, RoleById.Operador_Dir_de_BienesFiscales, RoleById.Responsable_Patrimonial],
					[module]
				);
				break;
			case 'manage:Settings': //Acceso a módulo de gestión de Balances
				result = getPermision([RoleById.ITDirector, RoleById.Director_de_BienesFiscales], [module]);
				break;
			default:
				break;
		}
		return result;
	};
	// END Handle Permissions

	return (
		<AppContext.Provider
			value={{
				showSpinner,
				onHandleShowSpinner,
				onHandleHideSpinner,
				onHandleTryGetAllGroups,
				groups,
				onHandleTryGetAllProfiles,
				profiles,
				onHandleTryGetAllPatrimonialDependences,
				patrimonialDependences,
				onHandleTryGetAllOrganisms,
				organisms,
				onHandleTryGetAllJurisdictions,
				jurisdictions,
				isLoadingJurisdictions,
				jurisdictionsError,
				onHandleTryGetAllDeregistrationTypes,
				deregistrationTypes,
				isLoadingDeregistrationTypes,
				deregistrationTypesError,
				onHandleTryGetAllRegistrationTypes,
				registrationTypes,
				isLoadingRegistrationTypes,
				registrationTypesError,
				onHandleTryGetAllVoucherTypes,
				voucherTypes,
				isLoadingVoucherTypes,
				voucherTypesError,
				onHandleTryGetAllItemTypes,
				itemTypes,
				isLoadingItemTypes,
				itemTypesError,
				canAccessModule,
				getPermision,
				onHandleTryGetAllAdministrativeTypes,
				administrativeTypes
			}}>
			{children}
		</AppContext.Provider>
	);
};
