import { ColumnDef } from '@tanstack/react-table';
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export const defaultColumns: ColumnDef<any, any>[] = [
	{
		accessorKey: 'name',
		enableSorting: true,
		header: () => <span>Perfil</span>,
		cell: ({ getValue }) => <p style={{ textAlign: 'left', paddingLeft: '10px' }}>{getValue() as string}</p>
	},
	{
		accessorKey: 'group',
		enableColumnFilter: true,
		filterFn(col, _, value) {
			const colData = col.original?.group?.name;
			if (!value.length || !colData) return true;
			return colData.toLowerCase().includes(value.toLowerCase());
		},
		header: () => <span>Grupo</span>,
		cell: ({ row: { original } }) => {
			return <p style={{ textAlign: 'left', paddingLeft: '10px' }}>{original.group?.name || '-'}</p>;
		}
	},
	{
		accessorKey: 'actions',
		enableSorting: false,
		enableGlobalFilter: false,
		enableColumnFilter: false,
		header: () => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>Acciones</span>
			</div>
		),
		cell: ({ row, table }) => (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'nowrap',
					width: '100%',
					justifyContent: 'space-around'
				}}>
				<IconButton
					onClick={() => {
						row.original.action = 'edit';
					}}
					size="small"
					sx={{
						transition: 'all 200ms ease',
						'&:hover': {
							transform: 'scale(1.3)',
							transition: 'all 200ms ease'
						}
					}}>
					<Tooltip
						placement="right-start"
						arrow
						title="Editar Perfil">
						<EditIcon />
					</Tooltip>
				</IconButton>

				{/* <IconButton
					onClick={() => {
						row.original.action = 'delete';
					}}
					size="small"
					sx={{
						transition: 'all 200ms ease',
						'&:hover': {
							transform: 'scale(1.3)',
							transition: 'all 200ms ease'
						}
					}}>
					<Tooltip
						placement="right-start"
						arrow
						title="Eliminar Perfil">
						<DeleteIcon />
					</Tooltip>
				</IconButton> */}
			</div>
		)
	}
];
