import { ReactNode, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MovContext } from './MovContext';
import { IFiltersMovementTable, IMovementEdit, IRecepAndInstMovProvider } from 'interfaces/movements';
import { IVehicleType } from 'interfaces/items/IPropertiesItems';
import { IStep3Accessories } from 'pages/movement/editMovement/items/interfaces';

// Interfaces
import { IMeta } from 'interfaces/Pagination';

// Utils
import { setItems, setAccessories } from './utils';

//Services
import { toast } from 'react-toastify';
import { getPropertyTypes } from 'services/crudService';
import {
	uploadItemAttachmentsMovements,
	uploadPropertyAttachmentsMovements,
	patchPropertyMovements,
	postPropertyItemMovements,
	deletePropertyAttachmentMovements
} from 'services/Movements/crudMovements';
import { tryGetMovementByID } from 'services';
import { useAppContext } from 'hooks/useAppContext';

export const MovProvider = ({ children }: { children: ReactNode }) => {
	const appContext = useAppContext();
	const navigate = useNavigate();
	const [meta, setMeta] = useState<IMeta>({ currentPage: 1, itemsPerPage: 10 } as IMeta);
	const [filtersMovTable, setFiltersMovTable] = useState<IFiltersMovementTable>({} as IFiltersMovementTable);
	const [isReloadMovementsTable, setIsReloadMovementsTable] = useState<boolean>(false);
	const [isUpdating, setIsUpdating] = useState(false);
	const [isUpdatingFilters, setIsUpdatingFilters] = useState(false);
	const [isItemEdit, setIsItemEdit] = useState<boolean>(false);
	const [hasChanged, setHasChanged] = useState<boolean>(false);
	const [idMovement, setIdMovement] = useState<number | null>(null);
	const [editMovement, setEditMovement] = useState<IMovementEdit | null>(null);
	const [itemSelected, setItemSelected] = useState<any | null>(null);
	const [vehiclesTypes, setVehiclesTypes] = useState<IVehicleType[]>([]);

	// For Upload changes in Reception and Instruments.
	const [recepAndInst, setRecepAndInst] = useState<any[]>([]);

	// For Upload or Delete attachments in Reception and Instruments.
	const [recepAndInstAttachs, setRecepAndInstAttachs] = useState<IRecepAndInstMovProvider[]>([]);

	// For CRUD operations on accessories
	const [accessoryFromTable, setAccessoryFromTable] = useState<IStep3Accessories[]>([]);

	// For CRUD operations on attachments (this are attachments of the items, example: a property title in a car)
	const [attachments, setAttachments] = useState<any[]>([]); // Attachments from the movement

	// For CRUD operations on items
	const [itemsFromTable, setItemsFromTable] = useState<any[]>([]);

	const location = useLocation();

	useEffect(() => {
		if (location.pathname.includes('edit') && vehiclesTypes.length < 1) {
			const vehicles: IVehicleType[] = [];
			getPropertyTypes().then((res: any) => {
				res.data.map((item: any) => {
					vehicles.push({ vehicleType: item.name, id: item.id });
				});
				setVehiclesTypes(vehicles);
			});
		}
	}, [location.pathname]);

	useEffect(() => {
		if (!location.pathname.includes('movimientos')) {
			setFiltersMovTable({} as IFiltersMovementTable);
			setMeta({ currentPage: 1, itemsPerPage: 10 } as IMeta);
			setIsReloadMovementsTable(false);
		}
	}, [location.pathname]);

	useEffect(() => {
		if (!location.pathname.includes('edit') && location.pathname.includes('movimientos')) {
			setRecepAndInst([]);
			setAccessoryFromTable([]);
			setAttachments([]);
			setRecepAndInstAttachs([]);
			setItemsFromTable([]);
			setItemSelected(null);
			setEditMovement(null);
			setIdMovement(null);
			setHasChanged(false);
			setIsItemEdit(false);
		}
	}, [location.pathname]);

	useEffect(() => {
		if (isUpdating) {
			sendAllData();
		}
	}, [isUpdating]);

	const sendAllData = async () => {
		if (!isUpdating) return;
		const id = editMovement?.property_registration?.id || 0;
		if (id === 0) {
			toast.error('No se pudo obtener el id del alta.');
			setIsUpdating(false);
			return;
		}
		const sendItemsFromTable = await setItems(itemsFromTable).then((res) => {
			return res;
		});

		const promises: Promise<any>[] = [];

		const dataFromReceptionAndInstrument = patchPropertyMovements(recepAndInst[0], id);
		promises.push(dataFromReceptionAndInstrument);

		if (recepAndInstAttachs.length > 0) {
			const handleDeletePropertyAttachment = deletePropertyAttachmentMovements(recepAndInstAttachs);
			promises.push(handleDeletePropertyAttachment);

			const handleUploadPropertyAttachment = uploadPropertyAttachmentsMovements(recepAndInstAttachs, id);
			promises.push(handleUploadPropertyAttachment);
		}

		if (sendItemsFromTable.length > 0) {
			const handleUploadPropertyItem = postPropertyItemMovements(sendItemsFromTable, id);
			promises.push(handleUploadPropertyItem);
		}

		if (accessoryFromTable.length > 0) {
			const accessories = setAccessories(accessoryFromTable);
			const handleUploadPropertyAccessory = postPropertyItemMovements(accessories, id);
			promises.push(handleUploadPropertyAccessory);
		}

		Promise.allSettled(promises).then(() => {
			uploadItemAttachmentsMovements(attachments).then(() => {
				setIsUpdating(false);
				navigate('/movimientos', { replace: true });
			});
		});
	};

	// START Get Details
	const onHandleMovementGetDetails = async (id: number) => {
		appContext?.onHandleShowSpinner();
		const response = await tryGetMovementByID(id);
		appContext?.onHandleHideSpinner();

		// eslint-disable-next-line no-prototype-builtins
		if (response.hasOwnProperty('error')) {
			toast.error('Ocurrio un error al recuperar detalles del Movimiento!');
			console.error(response.error);
			return undefined;
		}
		toast.success('Detalles del Movimiento recuperados!');
		return response;
	};
	// END Get Details

	const value = {
		isReloadMovementsTable,
		setIsReloadMovementsTable,
		isUpdating,
		setIsUpdating,
		idMovement,
		setIdMovement,
		editMovement,
		setEditMovement,
		hasChanged,
		setHasChanged,
		isItemEdit,
		setIsItemEdit,
		vehiclesTypes,
		accessoryFromTable,
		setAccessoryFromTable,
		itemsFromTable,
		setItemsFromTable,
		itemSelected,
		setItemSelected,
		attachments,
		setAttachments,
		recepAndInst,
		setRecepAndInst,
		recepAndInstAttachs,
		setRecepAndInstAttachs,
		onHandleMovementGetDetails,
		meta,
		setMeta,
		filtersMovTable,
		setFiltersMovTable,
		isUpdatingFilters,
		setIsUpdatingFilters
	};

	return <MovContext.Provider value={value}>{children}</MovContext.Provider>;
};
