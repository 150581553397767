import { useState, useEffect } from 'react';

// Services
import { getLastPropertyRegistered, getLastVerifiedSheet } from '../../../services/altaService';

// Material UI
import { Box, FormHelperText, Grid, Typography, Divider } from '@mui/material';
import { FormGenericMultiline } from 'components/globals/inputs/FormGenericMultiline';
import { FileAttachementManager } from 'components/globals/inputs/FileManager';
import { DEFAULT_NO_ERROR, requiredValidator } from 'helpers/Validators/Validators';
import dayjs from 'dayjs';
import { Attachment } from 'interfaces/file/IFile';
import { AppDatePicker, getDateFromStr } from 'components/globals/AppDatePicker/AppDatePicker';
import ErrorIcon from '@mui/icons-material/Error';
import { format } from 'date-fns';
import { REGISTER_TYPES_ID } from 'enums';

export const LABELS_FCEHA_REMITO_FORM = {
	titulo: 'Fecha del Comprobante',
	date: 'Fecha de Factura/Remito/Sello',
	observations: 'Observaciones',
	attached: 'Adjuntar Instrumento Legal *',
	dateIntrument: 'Fecha de Instrumento Legal'
};

export interface IFechaComprobante {
	date: any | undefined;
	observations?: string | undefined;
	attached?: File | undefined;
	dateIntrument?: any | undefined;
	hasErrors: boolean;
	errors?: any;
}

interface FechaComprobanteComponentProps {
	showTitle?: boolean;
	entity_id?: number;
	date: any | undefined;
	observations?: string | undefined;
	attached?: File | undefined;
	dateIntrument?: any | undefined;
	handleChange: (data: IFechaComprobante) => void;
	attachments?: Attachment[];
	title?: string;
	label?: string;
	onDeleteFiles?: CallableFunction;
	disabled?: boolean;
	onTimelessError?: CallableFunction;
	registerTypeId?: number;
}

export interface IErrorsFechaComprobanteForm {
	date?: string | undefined;
	observations?: string | undefined;
	attached?: string | undefined;
	dateIntrument?: string | undefined;
}

const FechaFactRemito = ({
	showTitle = false,
	date,
	observations,
	attached,
	dateIntrument,
	handleChange,
	attachments,
	title,
	label,
	onDeleteFiles,
	disabled,
	onTimelessError,
	registerTypeId
}: FechaComprobanteComponentProps) => {
	const [fecha, setFecha] = useState<string | undefined>('');
	const [dateLastPropertyRegistered, setDateLastPropertyRegistered] = useState<Date | null>(null);
	const [observacionFile, setObservacionFile] = useState<File | undefined>();
	const [observacion, setObservacion] = useState<string | undefined>();
	const [isErrorCorrelative, setIsErrorCorrelative] = useState<boolean>(false);
	const [isErrorTimeless, setIsErrorTimeless] = useState<boolean>(false);
	const [dataFormHasChanged, setDataFormHasChanged] = useState<number>(0);
	const [observationDateValue, setObservationDateValue] = useState<Date | null>(null);
	const [instrumentDateValue, setInstrumentDateValue] = useState<Date | null>(null);
	const [errors, setErrors] = useState<IErrorsFechaComprobanteForm>({} as IErrorsFechaComprobanteForm);
	const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
	const [selectedYear, setSelectedYear] = useState<number | null>(null);
	const [monthlySheetWasSent, setMonthlySheetWasSent] = useState(false);

	useEffect(() => {
		date && setFecha(date);

		if (!observationDateValue && date) {
			setObservationDateValue(getDateFromStr(date));
			setDataFormHasChanged((v) => v + 1);
		}
	}, [date]);

	useEffect(() => {
		observations && setObservacion(observations);
	}, [observations]);

	useEffect(() => {
		setObservacionFile(attached);
	}, [attached]);

	useEffect(() => {
		if (!instrumentDateValue && dateIntrument) setInstrumentDateValue(getDateFromStr(dateIntrument));
	}, [dateIntrument]);

	useEffect(() => {
		if (registerTypeId === REGISTER_TYPES_ID.TRANSFERENCE) return;

		if (fecha && !disabled && dateLastPropertyRegistered) {
			const splitedDate = fecha.split('-');
			const [y, m, d] = splitedDate;
			const dateSelected = new Date(parseInt(y), parseInt(m) - 1, parseInt(d));
			dateSelected.setHours(0, 0, 0, 0);

			setSelectedMonth(dayjs(dateSelected).get('month'));
			setSelectedYear(dayjs(dateSelected).get('year'));

			const isLessDate = dateSelected < dateLastPropertyRegistered;
			const isGreaterDate = dateSelected > dateLastPropertyRegistered;

			const isTimeless = isLessDate && monthlySheetWasSent;
			const isCorrelative = (isLessDate && !monthlySheetWasSent) || (isGreaterDate && monthlySheetWasSent);

			setIsErrorTimeless(isTimeless);
			setIsErrorCorrelative(isCorrelative);

			if (isTimeless) {
				onTimelessError?.('timeless');
			} else if (isCorrelative) {
				onTimelessError?.('correlative');
			} else {
				onTimelessError?.('');
			}

			validateForm();
		}

		if (!fecha) {
			setIsErrorCorrelative(false);
			setIsErrorTimeless(false);
			setSelectedMonth(null);
			setSelectedYear(null);
		}
	}, [fecha, dateLastPropertyRegistered, monthlySheetWasSent]);

	const validateForm = (): boolean => {
		if (dataFormHasChanged <= 1 || disabled) return true;

		const newErrors: IErrorsFechaComprobanteForm = {} as IErrorsFechaComprobanteForm;
		setErrors(newErrors);

		const dateError = requiredValidator({
			value: observationDateValue?.toJSON(),
			label: LABELS_FCEHA_REMITO_FORM.date
		});
		if (dateError !== DEFAULT_NO_ERROR) {
			newErrors.date = dateError;
		}

		if (isErrorTimeless || isErrorCorrelative) {
			const observationError = requiredValidator({
				value: observacion,
				label: LABELS_FCEHA_REMITO_FORM.observations
			});
			if (observationError !== DEFAULT_NO_ERROR) {
				newErrors.observations = observationError;
			}
			const adjuntoError =
				observacionFile || attachments?.at(0)
					? DEFAULT_NO_ERROR
					: 'Debe adjuntar un '.concat(LABELS_FCEHA_REMITO_FORM.attached);
			if (adjuntoError !== DEFAULT_NO_ERROR) {
				newErrors.attached = adjuntoError;
			}
		}

		if (isErrorTimeless) {
			const dateIntrumentError = requiredValidator({
				value: instrumentDateValue?.toJSON(),
				label: LABELS_FCEHA_REMITO_FORM.dateIntrument
			});
			if (dateIntrumentError !== DEFAULT_NO_ERROR) {
				newErrors.dateIntrument = dateIntrumentError;
			}
		}

		setErrors(newErrors);
		if (Object.entries(newErrors).length !== 0) {
			return false;
		}

		return true;
	};

	const getParsedData = (hasErrors: boolean): IFechaComprobante => {
		return {
			date: observationDateValue?.toJSON(),
			observations: observacion,
			attached: observacionFile,
			dateIntrument: instrumentDateValue?.toJSON(),
			hasErrors: hasErrors,
			errors: hasErrors ? errors : ({} as IErrorsFechaComprobanteForm)
		} as IFechaComprobante;
	};

	useEffect(() => {
		if (dataFormHasChanged) {
			const hasErrors = !validateForm();
			const dataToSend = getParsedData(hasErrors);
			handleChange(dataToSend);
		}
	}, [dataFormHasChanged]);

	useEffect(() => {
		(async function () {
			try {
				const lastDocument = await getLastPropertyRegistered();
				const lastDate = getDateFromStr(lastDocument.movement_date);

				lastDate.setHours(0, 0, 0, 0);

				setDateLastPropertyRegistered(lastDate);
			} catch {
				setDateLastPropertyRegistered(null);
			}
		})();

		setErrors({});
	}, []);

	useEffect(() => {
		(async function () {
			if (!selectedMonth || !selectedYear) return;
			const data = await getLastVerifiedSheet({ year: selectedYear, month: selectedMonth });
			setMonthlySheetWasSent(data);
		})();
	}, [selectedMonth, selectedYear]);

	return (
		<>
			{showTitle && (
				<>
					<Typography
						variant="subtitle2"
						style={{ paddingLeft: '25px' }}
						fontSize={'1rem'}
						mt={2}>
						{title || LABELS_FCEHA_REMITO_FORM.titulo}
					</Typography>
					<Divider className="customDividerLight" />
				</>
			)}

			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}>
				<Box
					sx={{ display: 'flex', gap: 4, alignItems: 'center' }}
					width="100%">
					<Box
						width="100%"
						sx={{ position: 'relative', mb: 2 }}>
						<AppDatePicker
							disableFuture
							disabled={disabled}
							label={label || LABELS_FCEHA_REMITO_FORM.date + ' *'}
							value={observationDateValue}
							onChange={(date) => {
								setFecha(date ? format(date, 'yyyy-MM-dd') : undefined);
								setDataFormHasChanged(dataFormHasChanged + 1);
								setObservationDateValue(date);
							}}
						/>
					</Box>
				</Box>

				{errors?.date !== undefined && (
					<FormHelperText
						id={'startDateRequiredError'}
						error={true}>
						{errors.date}
					</FormHelperText>
				)}
				{isErrorCorrelative && (
					<FormHelperText>
						<ErrorIcon
							color="warning"
							fontSize="small"
						/>
						ERROR EN CORRELATIVIDAD DE Nº DE ACTAS DE RECEPCION; Incumplimiento Art. 22º Pto 6 inc b) Decreto 3421/86
						Reglamentario Ley VII Nº 11 de Contabilidad.
					</FormHelperText>
				)}
				{isErrorTimeless && (
					<FormHelperText>
						<ErrorIcon color="error" />
						INGRESO DE BIENES ATEMPORAL; Incumplimiento Art. 22º Pto 4 Decreto 3421/86 Reglamentario Ley VII Nº 11 de
						Contabilidad.
					</FormHelperText>
				)}
			</Grid>

			{(isErrorCorrelative || isErrorTimeless) && (
				<>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						my={2}>
						<FormGenericMultiline
							disabled={disabled}
							limit={256}
							value={observacion}
							label={LABELS_FCEHA_REMITO_FORM.observations}
							name="observation"
							id="observation"
							onChange={(value: string) => {
								value?.length + 1 <= 256 && setObservacion(value as string);
								setDataFormHasChanged((v) => v + 1);
							}}
							required={true}
							error={errors?.observations}
						/>
					</Grid>

					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}>
						<FileAttachementManager
							disabled={disabled}
							onDeleteSuccess={onDeleteFiles}
							attachments={attachments}
							fieldName="timeless_justification"
							label={LABELS_FCEHA_REMITO_FORM.attached}
							onChange={(file) => {
								setObservacionFile(file.at(0));
								setDataFormHasChanged(dataFormHasChanged + 1);
							}}
							error={errors?.attached}
							disableUploadAction
							routeToDelete="/property-registration-files"
						/>
					</Grid>
				</>
			)}

			{isErrorTimeless && (
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}>
					<Box sx={{ mt: 2 }}>
						<AppDatePicker
							disabled={disabled}
							label={LABELS_FCEHA_REMITO_FORM.dateIntrument + ' *'}
							value={instrumentDateValue}
							onChange={(date) => {
								setDataFormHasChanged(dataFormHasChanged + 1);
								setInstrumentDateValue(date);
							}}
						/>
					</Box>
					{errors?.dateIntrument !== undefined && (
						<FormHelperText
							id={'startDateError'}
							error={true}>
							{errors.dateIntrument}
						</FormHelperText>
					)}
				</Grid>
			)}
		</>
	);
};

FechaFactRemito.displayName = 'FechaFactRemito';

export { FechaFactRemito };
