import { FC } from 'react';
import { Button, Grid, Typography } from '@mui/material';

export interface IPaginationTableProps {
	currentPage: number;
	totalPages: number;
	itemsPerPage: number;
	totalItems?: number;
	showDetails?: boolean;
	handleChangePage: (page: number, limit?: number) => void;
}

export const LENGTH_OPTIONS = [10, 20, 30, 40, 50];

export const PaginationTable: FC<IPaginationTableProps> = ({
	currentPage = 1,
	totalPages = 1,
	itemsPerPage = 0,
	totalItems = 0,
	showDetails = false,
	handleChangePage = (page: number, limit?: number) => {}
}) => {
	const itemsJustify = showDetails ? 'flex-start' : 'center';

	return (
		<Grid
			container
			direction="row"
			justifyContent={showDetails ? 'space-between' : 'center'}
			alignItems="center"
			spacing={2}>
			{totalItems > 0 && (
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					xl={12}
					lg={12}>
					<div style={{ margin: '15px', alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: itemsJustify }}>
						<Typography
							variant="h5"
							sx={{ fontWeight: '600' }}>
							{`Total: ${totalItems}`}
						</Typography>
					</div>
				</Grid>
			)}
			<Grid
				item
				xs={12}
				sm={12}
				md={showDetails ? 6 : 12}
				xl={showDetails ? 6 : 12}
				lg={showDetails ? 6 : 12}>
				<div style={{ margin: '15px', alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: itemsJustify }}>
					<Button
						variant="outlined"
						color="primary"
						sx={{ ml: '3px', mr: '3px', fontWeight: '600' }}
						onClick={() => {
							handleChangePage(1);
						}}
						disabled={!(currentPage > 1)}>
						{'<<'}
					</Button>
					<Button
						variant="outlined"
						color="primary"
						sx={{ ml: '3px', mr: '3px', fontWeight: '600' }}
						onClick={() => {
							handleChangePage(currentPage !== 1 ? currentPage - 1 : 1);
						}}
						disabled={!(currentPage > 1)}>
						{'<'}
					</Button>
					<Button
						variant="outlined"
						color="primary"
						sx={{ ml: '3px', mr: '3px', fontWeight: '600' }}
						onClick={() => {
							handleChangePage(currentPage !== totalPages ? currentPage + 1 : totalPages);
						}}
						disabled={!(currentPage < totalPages)}>
						{'>'}
					</Button>
					<Button
						variant="outlined"
						color="primary"
						sx={{ ml: '3px', mr: '3px', fontWeight: '600' }}
						onClick={() => {
							handleChangePage(totalPages);
						}}
						disabled={!(currentPage < totalPages)}>
						{'>>'}
					</Button>
				</div>
			</Grid>
			{showDetails ? (
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
					xl={6}
					lg={6}>
					<div style={{ display: 'flex', justifyContent: 'end' }}>
						<span className="page">
							<div className="span-title">{'Página:'}</div>
							<strong style={{ whiteSpace: 'nowrap' }}>
								{currentPage} de {totalPages}
							</strong>
						</span>
						<span style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center' }}>
							| Ir a la página:
							<input
								style={{ width: '100px', height: '30px', marginBottom: '0px', padding: '4px', marginRight: '0px', textAlign: 'center' }}
								type="number"
								defaultValue={currentPage ? currentPage : 1}
								onChange={(e) => {
									if (Number(e.target.value) > 0 && Number(e.target.value) < totalPages + 1) {
										const page = e.target.value && Number(e.target.value) > 0 ? Number(e.target.value) : 1;
										handleChangePage(page);
									}
								}}
							/>
						</span>
					</div>
					<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
						<select
							className="select-mostrar border rounded "
							value={itemsPerPage}
							onChange={(e) => {
								handleChangePage(1, Number(e.target.value));
							}}>
							{LENGTH_OPTIONS.map((pageSize) => (
								<option
									key={pageSize}
									value={pageSize}>
									Mostrar {pageSize}
								</option>
							))}
						</select>
					</div>
				</Grid>
			) : null}
		</Grid>
	);
};
