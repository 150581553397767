import React from 'react';
import { Box, Typography } from '@mui/material';
import { CustomAccordion, IAccordionProps } from 'components/globals/accordion/CustomAccordion';
import { useEffect, useState } from 'react';
import { PROPERTY_TYPE_IDS, VEHICLES_TYPES } from 'enums';
import { GenericDetails } from '../forms/heritageAssets/generico/GenericDetails';
import { GenericFormData } from '../forms/heritageAssets/generico/Generic';
import { VehicleFormData } from '../forms/heritageAssets/vehicle/Vehicle';
import { InmuebleFormData } from '../forms/heritageAssets/inmueble/Inmueble';
import { VehicleDetails } from '../forms/heritageAssets/vehicle/VehicleDetails';
import { InmuebleDetails } from '../forms/heritageAssets/inmueble/InmuebleDetails';

const getPropertyData = (selectedProperty: any) => {
	if (!selectedProperty) return {} as GenericFormData | VehicleFormData | InmuebleFormData;

	const data = {
		general: {
			nomenclatureCode: {
				value:
					selectedProperty.nomenclator_code ||
					selectedProperty.account_nomenclature_details?.code ||
					selectedProperty?.account_nomenclature?.sub_group ||
					'',
				label:
					selectedProperty?.nomenclature_description ||
					selectedProperty.account_nomenclature_details?.description ||
					selectedProperty?.account_nomenclature?.sub_group_description ||
					'',
				id: selectedProperty.account_nomenclature_id || 0
			},
			quantity: selectedProperty.quantity,
			unitPrice: {
				value: String(selectedProperty.unit_price),
				floatValue: Number(selectedProperty.unit_price),
				formattedValue: `${Number(selectedProperty.unit_price)}`
			}
		}
	};

	if (selectedProperty?.property_type_id === PROPERTY_TYPE_IDS.GENERIC) {
		return {
			...data.general,
			description: selectedProperty.description
		};
	}

	if (selectedProperty?.property_type_id === PROPERTY_TYPE_IDS.VEHICLE) {
		return {
			general: data.general,
			vehicle: {
				brand: selectedProperty.details.brand,
				chassisNumber: selectedProperty.details.chassis_number,
				description: selectedProperty.description || '',
				domain: selectedProperty.details.domain,
				engineBrand: selectedProperty.details.engine_brand,
				engineHolder: selectedProperty.details.engine_holder,
				engineNumber: selectedProperty.details.engine_number,
				frameNumber: selectedProperty.details.frame_number,
				headline: selectedProperty.details.headline,
				helmetModel: selectedProperty.details.helmet_model,
				helmetNumber: selectedProperty.details.helmet_number,
				manufacturingDate: selectedProperty.details.manufacturing_date,
				model: selectedProperty.details.model,
				modelSeries: selectedProperty.details.model_series,
				origin: selectedProperty.details.origin,
				propertyTitle: !!selectedProperty.property_files?.length,
				registration: selectedProperty.details.registration,
				serialNumber: selectedProperty.details.serial_number,
				shipName: selectedProperty.details.ship_name,
				type: selectedProperty.details.type,
				year: selectedProperty.details.year,
				helmetBrand: selectedProperty.details.helmet_brand
			},
			ownership: { propertyTitle: [] }
		};
	}

	if (selectedProperty?.property_type_id === PROPERTY_TYPE_IDS.INMUEBLE) {
		return {
			general: data.general,
			cadastre: {
				department: {
					id: selectedProperty.details.cadastral_information?.department.id,
					label: selectedProperty.details.cadastral_information?.department.name,
					data: selectedProperty.details.cadastral_information?.department
				},
				municipality: {
					id: selectedProperty.details.cadastral_information?.location.id,
					label: selectedProperty.details.cadastral_information?.location.name,
					data: selectedProperty.details.cadastral_information?.location
				},
				section: selectedProperty.details.cadastral_information?.section,
				ranch: selectedProperty.details.cadastral_information?.farm,
				realEstateItem: selectedProperty.details.cadastral_information?.real_estate_item,
				block: selectedProperty.details.cadastral_information?.street,
				functionalUnit: selectedProperty.details.cadastral_information?.functional_unit,
				planeNumber: selectedProperty.details.cadastral_information?.plane_number,
				parcel: selectedProperty.details.cadastral_information?.parcel
			},
			registry: {
				department: selectedProperty.details.registry_information?.department,
				farm: selectedProperty.details.registry_information?.farm,
				invoice: selectedProperty.details.registry_information?.invoice,
				observations: selectedProperty.details.registry_information?.observations,
				tome: selectedProperty.details.registry_information?.took,
				propertyDescription: selectedProperty.details.registry_information?.property_description,
				owner: selectedProperty.details.registry_information?.owner,
				registrationNumber: selectedProperty.details.registry_information?.registration_number
			},
			address: {
				value: selectedProperty.details.address
			},
			ownership: {
				hasPropertyTitle: selectedProperty.details.property_title,
				isNameOfPatrimonialDependency: selectedProperty.details.title_name_patrimonial_unit,
				reason: selectedProperty.description,
				propertyTitle: []
			}
		};
	}

	return {} as GenericFormData | VehicleFormData | InmuebleFormData;
};

interface ISummaryStepThree {
	entity: any;
}

function processItemInventoryNumber(entity: any) {
	if (entity.is_accessory) return entity.item_inventory_number || '-';

	const inventoryNumberValue = Number(entity.item_inventory_number);

	return inventoryNumberValue
		? entity.quantity > 1
			? `${inventoryNumberValue}-${inventoryNumberValue + entity.quantity - 1}`
			: inventoryNumberValue
		: '-';
}

export const SummaryItem = ({ entity }: any) => {
	const selectedPropertyData = getPropertyData(entity);
	const inventoryNumber = processItemInventoryNumber(entity);

	if (!Object.keys(selectedPropertyData).length) return <p>Sin datos</p>;

	if (entity.property_type_id === PROPERTY_TYPE_IDS.GENERIC) {
		const data = selectedPropertyData as GenericFormData;

		return (
			<GenericDetails
				{...data}
				inventoryNumber={`${inventoryNumber}`}
				rowNumber={entity.row_number}
				isInventoriable={entity.is_quantifiable}
			/>
		);
	}

	if (entity.property_type_id === PROPERTY_TYPE_IDS.VEHICLE) {
		const data = selectedPropertyData as VehicleFormData;
		const vehicleType = VEHICLES_TYPES.find((type) => type.id === entity.property_sub_type_id);

		return (
			<VehicleDetails
				isAccessory={entity.is_accessory}
				vehicleType={vehicleType?.label}
				nomenclatureCode={data.general.nomenclatureCode}
				quantity={data.general.quantity}
				unitPrice={data.general.unitPrice.floatValue as number}
				details={data.vehicle}
				inventoryNumber={`${inventoryNumber}`}
				rowNumber={entity.row_number}
			/>
		);
	}

	if (entity.property_type_id === PROPERTY_TYPE_IDS.INMUEBLE) {
		const data = selectedPropertyData as InmuebleFormData;

		return (
			<InmuebleDetails
				isAccessory={entity.is_accessory}
				nomenclatureCode={data.general.nomenclatureCode}
				quantity={data.general.quantity}
				unitPrice={data.general.unitPrice.floatValue as number}
				address={data.address.value}
				cadestre={data.cadastre}
				registry={data.registry}
				rowNumber={entity.row_number}
				inventoryNumber={`${inventoryNumber}`}
			/>
		);
	}

	return <p>Sin datos</p>;
};

export const SummaryStepThree = ({ entity }: ISummaryStepThree) => {
	const [accordionItems, setAccordionItems] = useState<IAccordionProps[]>([]);

	useEffect(() => {
		if (entity?.properties_items) {
			setAccordionItems(
				entity?.properties_items?.map((item: any) => {
					item.details = item.details.data || item.details;

					const childrenAccordions = (item.children || []).map((child: any) => {
						const title = child.is_quantifiable
							? child?.account_nomenclature?.sub_group == undefined &&
							  child?.account_nomenclature?.sub_group_description == undefined
								? child?.description
								: `${child?.account_nomenclature?.sub_group} - ${child?.account_nomenclature?.sub_group_description}`
							: 'Bien no Inventariable';

						return {
							title: title || '-',
							children: <SummaryItem entity={child} />
						};
					});

					const title = item.is_quantifiable
						? item?.nomenclature_description || item?.account_nomenclature_details?.description
						: 'Bien no Inventariable';
					console.log('childrenAccordions', childrenAccordions);
					return {
						title: title || '-',
						children: (
							<Box>
								<SummaryItem entity={item} />

								{item.children?.length ? (
									<Box mt={1}>
										{/* <Divider /> */}
										<Typography
											fontSize={16}
											fontWeight="bold"
											my={1}
											variant="h6">
											Accesorios: {item.children?.length}
										</Typography>
										<CustomAccordion accordions={childrenAccordions} />
									</Box>
								) : null}
							</Box>
						)
					};
				})
			);
		}
	}, [entity]);

	return (
		<Box sx={{ margin: '3%', marginTop: '2%' }}>
			{accordionItems.length > 0 ? (
				<CustomAccordion accordions={accordionItems} />
			) : (
				<Box>
					<Typography>Sin datos</Typography>
				</Box>
			)}
		</Box>
	);
};
