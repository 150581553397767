import { useState, useEffect, memo, Dispatch, SetStateAction } from 'react';
import { NumericFormat, NumberFormatValues } from 'react-number-format';
import { TextField, Typography } from '@mui/material';

interface IUnitPriceInput {
	value?: string;
	setUnitPrice: Dispatch<SetStateAction<string>>;
	submited: boolean;
}

const UnitPriceInput = memo(({ value, setUnitPrice, submited }: IUnitPriceInput) => {
	const [unitPrice, setUnitPriceChild] = useState<NumberFormatValues | null>(null);

	useEffect(() => {
		if (value) {
			setUnitPriceChild({
				value: value,
				formattedValue: value,
				floatValue: parseFloat(value)
			});
		}
	}, [value]);

	useEffect(() => {
		if (submited) {
			setUnitPrice('');
			setUnitPriceChild(null);
		}
	}, [submited]);

	useEffect(() => {
		if (unitPrice?.value) {
			setUnitPrice(unitPrice?.value);
			return;
		}
		setUnitPrice('');
	}, [unitPrice]);

	const materialUITextFieldProps = {
		id: 'precioUnitarioInput',
		label: 'Precio Unitario *',
		size: 'small' as const
	};

	return (
		<div>
			<NumericFormat
				value={unitPrice?.floatValue ? unitPrice?.floatValue : ''}
				prefix="$"
				thousandsGroupStyle="none"
				thousandSeparator="."
				decimalSeparator=","
				onValueChange={(values, sourceInfo) => {
					setUnitPriceChild(values);
				}}
				customInput={TextField}
				{...materialUITextFieldProps}
			/>
		</div>
	);
});

UnitPriceInput.displayName = 'UnitPriceInput';

export { UnitPriceInput };
