import apiInstance from 'interceptors/axiosInterceptor';
import { trackPromise } from 'react-promise-tracker';
import { Item } from 'interfaces/crud/createHighData';
import { toast } from 'react-toastify';
import { IRecepAndInstMovProvider } from 'interfaces/movements';

export const uploadItemAttachmentsMovements = async (selectedFiles: any): Promise<any> => {
	const allPromises: Promise<string>[] = [];
	selectedFiles.forEach((aux: any) => {
		const formData = new FormData();
		formData.append('attachments', aux.file);
		const promise: Promise<string> = apiInstance.post(`/properties-files/upload/item/${aux.id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			params: { id: aux.idProperty }
		});
		allPromises.push(promise);
	});

	const data = Promise.allSettled(allPromises).then((results) => {
		return results;
	});

	return data;
};

export const uploadPropertyAttachmentsMovements = async (
	selectedFiles: IRecepAndInstMovProvider[],
	id: number
): Promise<any> => {
	const reception: any[] = [];
	const attachments: any[] = [];
	const timeless_justification: any[] = [];

	if (selectedFiles.length > 0) {
		selectedFiles.forEach((aux: any) => {
			if (aux.operation === 'create') {
				if (aux.key === 'reception') {
					reception.push(aux.file);
				}
				if (aux.key === 'attachments') {
					attachments.push(aux.file);
				}
				if (aux.key === 'timeless_justification') {
					timeless_justification.push(aux.file);
				}
			}
		});
	}

	const allFiles: any = { reception, attachments, timeless_justification };

	try {
		postUploadsForMovement(allFiles, id).then((res) => {
			return res;
		});
	} catch (error) {
		return error;
	}
};

export const postUploadsForMovement = async (selectedFiles: any, id: number): Promise<any> => {
	const formData = new FormData();
	const attachments: File[] = [];

	if (selectedFiles.reception) {
		if (Array.isArray(selectedFiles.reception)) {
			selectedFiles.reception.forEach((file: any) => {
				formData.append('reception', file);
			});
		} else {
			formData.append('reception', selectedFiles.reception);
		}
	}

	if (selectedFiles.attachments) {
		if (Array.isArray(selectedFiles.attachments)) {
			selectedFiles.attachments.forEach((file: any) => {
				attachments.push(file);
			});
		} else {
			attachments.push(selectedFiles.attachments);
		}
	}

	if (selectedFiles.timeless_justification) {
		if (Array.isArray(selectedFiles.timeless_justification)) {
			selectedFiles.timeless_justification.forEach((file: any) => {
				formData.append('timeless_justification', file);
			});
		} else {
			formData.append('timeless_justification', selectedFiles.timeless_justification);
		}
	}

	try {
		const response = apiInstance
			.post(`/properties-files/upload/${id}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then((res) => {
				uploadAttachmentsMovements(attachments, id);
				return res;
			})
			.catch((error) => {
				return error;
			});
		return response;
	} catch (error) {
		return error;
	}
};

const uploadAttachmentsMovements = async (selectedFiles: any, id: number): Promise<any> => {
	const allPromises: Promise<string>[] = [];
	if (selectedFiles.length > 0) {
		selectedFiles.forEach((file: any) => {
			const formData = new FormData();
			formData.append('attachments', file);
			const promise: Promise<string> = apiInstance.post(`/properties-files/upload/${id}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			allPromises.push(promise);
		});

		const data = toast
			.promise(
				trackPromise(Promise.allSettled(allPromises), 'postAttachmentsTracker1'),
				{
					pending: 'Subiendo archivos...',
					success: 'Archivos subidos correctamente',
					error: 'Error al subir archivos'
				},
				{ position: 'bottom-right' }
			)
			.then((results) => {
				return results;
			})
			.catch((error) => {
				return error;
			});

		return data;
	} else {
		return;
	}
};

export const patchPropertyMovements = async (data: any, id: number): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const promise = trackPromise(
		new Promise((resolve, reject) => {
			resolve(
				apiInstance
					.patch(`properties/${id}`, data, { headers: { Authorization: `Bearer ${token.access_token}` } })
					.then((res) => {
						return res;
					})
					.catch((error) => {
						return error;
					})
			);
		}),
		'patchPropertyTracker'
	);

	return promise;
};

export const postPropertyItemMovements = async (items: Item[], id: number) => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	if (items.length === 0) return undefined;
	const allPromises: Promise<string>[] = [];
	items.forEach((file: any) => {
		if (file.operation === 'create') {
			delete file.operation;
			const promise: Promise<string> = apiInstance.post(`/property-items/${id}`, file, {
				headers: {
					Authorization: `Bearer ${token.access_token}`
				}
			});
			allPromises.push(promise);
		}
		if (file.operation === 'delete') {
			delete file.operation;
			const promise: Promise<string> = apiInstance.delete(`/property-items/${file.id}`, {
				headers: {
					Authorization: `Bearer ${token.access_token}`
				}
			});
			allPromises.push(promise);
		}
		if (file.operation === 'update') {
			delete file.operation;
			const promise: Promise<string> = apiInstance.put(`/property-items/${id}/update`, file);
			allPromises.push(promise);
		}
	});

	const data = Promise.allSettled(allPromises)
		.then((results) => {
			return results;
		})
		.catch((error) => {
			return error;
		});

	return data;
};

export const deletePropertyAttachmentMovements = async (deleteFiles: IRecepAndInstMovProvider[]) => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');
	if (deleteFiles.length === 0) return undefined;
	const allPromises: Promise<any>[] = [];
	deleteFiles.forEach((file: IRecepAndInstMovProvider) => {
		if (file.operation === 'delete') {
			const promise: Promise<any> = apiInstance.delete(`/properties-files/upload/${file.id}`, {
				headers: { Authorization: `Bearer ${token.access_token}` }
			});
			allPromises.push(promise);
		}
	});

	Promise.allSettled(allPromises)
		.then((results) => {
			return results;
		})
		.catch((error) => {
			return error;
		});
};

export const deleteMovement = async (id: string): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');
	const promise = trackPromise(
		new Promise((resolve, reject) => {
			resolve(
				apiInstance
					.delete(`/property-movements/${id}`, { headers: { Authorization: `Bearer ${token.access_token}` } })
					.then((res) => {
						return res;
					})
					.catch((error) => {
						return error;
					})
			);
		}),
		'deletePropertyTracker'
	);

	return promise;
};

export const getLastMinuteMovements = async (): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');
	const promise = trackPromise(
		new Promise((resolve, reject) => {
			resolve(
				apiInstance
					.get(`/patrimonial-dependencies/last-minute`, { headers: { Authorization: `Bearer ${token.access_token}` } })
					.then((res) => {
						return res;
					})
					.catch((error) => {
						return error;
					})
			);
		}),
		'getLastMinuteMovementsTracker'
	);

	return promise;
};

export const updateMovementStatus = async (id: number, status: number, minute_number: number): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');
	const promise = trackPromise(
		new Promise((resolve, reject) => {
			apiInstance
				.patch(
					`/property-movements/${id}/update-status`,
					{ status_id: status, minute_number },
					{ headers: { Authorization: `Bearer ${token.access_token}` } }
				)
				.then((res) => {
					resolve(res); // Resuelve la promesa con el resultado de la llamada
				})
				.catch((error) => {
					reject(error); // Rechaza la promesa con el error de la llamada
				});
		}),
		'updateMovementStatusTracker'
	);
	return promise;
};

export const generatePdfMovement = async (id: number, acta: number, user?: string, address?: string): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');
	if (user === undefined || address === undefined) {
		const promise = trackPromise(
			new Promise((resolve, reject) => {
				resolve(
					apiInstance
						.get(`/property-movements/generate-pdf/movement`, {
							headers: { Authorization: `Bearer ${token.access_token}` },
							params: { id: Number(id) },
							responseType: 'blob'
						})
						.then((res: any) => {
							const file = new Blob([res.data], { type: 'application/pdf' });
							return file;
						})
						.then((blob) => {
							const url = window.URL.createObjectURL(blob);
							const link = document.createElement('a');
							link.href = url;
							link.download = `Acta_de_${acta ? 'alta_' + acta : 'baja'}`;
							document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
							link.click();
							link.remove(); //afterwards we remove the element again
						})
						.catch(() => {
							return toast('No se ha podido descargar el archivo.', { autoClose: 1000, type: 'error' });
						})
				);
			}),
			'generatePdfMovementTracker'
		);
		return promise;
	} else {
		const promise = trackPromise(
			new Promise((resolve, reject) => {
				resolve(
					apiInstance
						.get(`/property-movements/generate-pdf/movement`, {
							headers: { Authorization: `Bearer ${token.access_token}` },
							params: { id: Number(id), company_name: String(user), address: String(address) },
							responseType: 'blob'
						})
						.then((res: any) => {
							const file = new Blob([res.data], { type: 'application/pdf' });
							return file;
						})
						.then((blob) => {
							const url = window.URL.createObjectURL(blob);
							const link = document.createElement('a');
							link.href = url;
							link.download = `Acta_de_${acta ? 'alta_' + acta : 'baja'}`;
							document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
							link.click();
							link.remove(); //afterwards we remove the element again
						})
						.catch((error) => {
							return toast('No se ha podido descargar el archivo.', { autoClose: 1000, type: 'error' });
						})
				);
			}),
			'generatePdfMovementTracker'
		);
		return promise;
	}
};

export const uploadMinuteSigned = async (id: number, file: File): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');
	const formData = new FormData();

	const copyFile = new File([file], file.name.toLowerCase(), { type: file.type });
	formData.append('attachments', copyFile);

	const promise = trackPromise(
		new Promise((resolve) => {
			resolve(
				apiInstance
					.post(`/property-movements-files/upload/${id}`, formData, {
						headers: { Authorization: `Bearer ${token.access_token}` }
					})
					.then((res) => {
						return res;
					})
					.catch((error) => {
						return error;
					})
			);
		}),
		'uploadMinuteSignedTracker'
	);

	return promise;
};

export const deleteMinuteSigned = async (id: number): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const promise = trackPromise(
		new Promise((resolve, reject) => {
			resolve(
				apiInstance
					.delete(`/property-movements-files/${id}`, {
						headers: { Authorization: `Bearer ${token.access_token}` }
					})
					.then((res) => {
						return res;
					})
					.catch((error) => {
						return error;
					})
			);
		}),
		'deleteMinuteSignedTracker'
	);

	return promise;
};
