import style from './tablePaso3.module.css';
import { useState, useEffect, Fragment, memo, Dispatch, SetStateAction } from 'react';

// Services
import { BounceLoader } from 'react-spinners';
import { toast } from 'react-toastify';

// Hooks
import { useMovContext } from 'hooks/useMovContext';

// Components
import { CustomDialog } from 'components/globals/dialog/CustomDialog';
import { ModalAccessories } from '../modalAccessories/ModalAccessories';
import { ModalSelectAccessory } from '../modalAccessories/ModalSelectAccessory';
import { ModalEditAccessory } from '../modalAccessories/ModalEditAccessory';
import { EditItemModal } from '../modalEditItem/EditItemModal';
import { ModalVehicle } from '../modalDetails/ModalVehicle';
import { ModalGeneric } from '../modalDetails/ModalGeneric';
import { ModalProperty } from '../modalDetails/ModalProperty';

// Interfaces
import { IStep3PropertyValues, IStep3GenericValues, IStep3VehicleValues, IStep3Accessories } from '../interfaces';

// React Table
import {
	flexRender,
	Row,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
	Cell,
	FilterFn
} from '@tanstack/react-table';
import { RankingInfo, rankItem, compareItems } from '@tanstack/match-sorter-utils';
import { defaultColumnsPaso3Table } from './Paso3ColumnDef';

// Material UI
import { Button, Typography, Box } from '@mui/material';
import { MovementStatusType } from 'enums';
import { DialogType, ICustomButtonProps } from 'components/globals/dialog/utils';

declare module '@tanstack/table-core' {
	interface FilterFns {
		fuzzy: FilterFn<unknown>;
	}
	interface FilterMeta {
		itemRank: RankingInfo;
	}
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
	const itemRank = rankItem(row.getValue(columnId), value);
	addMeta({
		itemRank
	});
	return itemRank.passed;
};

type Generic = {
	nomenclaureCode: string;
	unitPrice: string;
	quantity: string;
	description: string;
};

interface IPaso3TableProps {
	rightList: IStep3GenericValues[];
	setRightList: Dispatch<SetStateAction<IStep3GenericValues[]>>;
}

const Paso3Table = memo(({ setRightList, rightList }: IPaso3TableProps) => {
	// Table states
	const [data, setData] = useState<IStep3GenericValues[]>([]);
	const defaultColumnsPaso3Table1 = defaultColumnsPaso3Table();
	const [columns] = useState<typeof defaultColumnsPaso3Table1>(() => [...defaultColumnsPaso3Table1]);
	const [columnVisibility, setColumnVisibility] = useState({});
	const [loading, setLoading] = useState(false);
	const [sorting, setSorting] = useState<SortingState>([]);

	// Dialog states
	const movContext = useMovContext();
	const [openModal, setOpenModal] = useState(false);
	const [openGenericDetails, setOpenGenericDetails] = useState(false);
	const [openVehicleDetails, setOpenVehicleDetails] = useState(false);
	const [openPropertyDetails, setOpenPropertyDetails] = useState(false);
	const [openEditAccessoryModal, setOpenEditAccessoryModal] = useState(false);
	const [openSelectAccessoryModal, setOpenSelectAccessoryModal] = useState(false);
	const [openEditItemModal, setOpenEditItemModal] = useState(false);
	const [selectedVehicleRow, setSelectedVehicleRow] = useState<IStep3VehicleValues>({} as IStep3VehicleValues);
	const [selectedGenericRow, setSelectedGenericRow] = useState<IStep3GenericValues>({} as IStep3GenericValues);
	const [selectedPropertyRow, setSelectedPropertyRow] = useState<IStep3PropertyValues>({} as IStep3PropertyValues);
	const [selectedAccessoryRow, setSelectedAccessoryRow] = useState<IStep3Accessories | null>(null);
	const [selectedRow, setSelectedRow] = useState<any | null>(null);
	const [isDialogDeleteOpen, setIsDialogDeleteOpen] = useState(false);
	const [rowForDelete, setRowForDelete] = useState<Row<any> | null>(null); // fila a eliminar

	useEffect(() => {
		setData([...rightList]);
		movContext?.setItemSelected(null);
	}, [rightList]);

	useEffect(() => {
		if (selectedAccessoryRow) {
			setOpenEditAccessoryModal(true);
		}
	}, [selectedAccessoryRow]);

	useEffect(() => {
		if (movContext?.editMovement && movContext?.editMovement?.movement_status_id !== MovementStatusType.PENDIENTE) {
			table.getAllLeafColumns().map((column) => {
				if (column.id === 'Borrar') {
					column.toggleVisibility(false);
				}
				if (column.id === 'accessoriesAdd') {
					column.toggleVisibility(false);
				}
			});
		}
	}, [movContext]);

	// Create the table and pass your options
	const table = useReactTable({
		data,
		columns,
		filterFns: {
			fuzzy: fuzzyFilter
		},
		state: {
			sorting,
			columnVisibility
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getRowCanExpand: () => true,
		onColumnVisibilityChange: setColumnVisibility
	});

	/**
	 * `renderSubComponent` is a function that takes an object with a `row` property, and returns a React
	 * component
	 * @param  - `row` - The row object that is being rendered.
	 * @returns A function that returns a component.
	 */
	const renderSubComponent = ({ row }: { row: Row<Generic> }) => {
		return (
			<Typography
				textAlign={'left'}
				sx={{ p: 2, transition: 'all 2s ease' }}>
				{row.original.description}
			</Typography>
		);
	};

	const dialogDelete: ICustomButtonProps[] = [
		{
			name: 'Si',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				setRightList(rightList.filter((item) => item.id !== rowForDelete?.original?.id));
				toast('Se ha eliminado el item', { type: 'warning', onClose: () => setRowForDelete(null) }); // TODO: cambiar a toast de success
				const itemsInMov = movContext?.editMovement?.property_registration?.properties_items;
				const existInBackend = itemsInMov?.find((file) => file.temporary_id === rowForDelete?.original?.id);
				if (!existInBackend) {
					movContext?.setAttachments(movContext?.attachments?.filter((file) => file.id !== rowForDelete?.original?.id)); // TODO: cambiar a toast de success
					movContext?.setItemsFromTable(
						movContext?.itemsFromTable?.filter((item) => item.id !== rowForDelete?.original?.id)
					);
					return;
				}
				const savedRow = rowForDelete?.original;
				savedRow.operation = 'delete';
				movContext?.setItemsFromTable((prev) => [...prev, savedRow]);
				setRowForDelete(null);
			}
		},
		{
			name: 'No',
			color: 'secondary',
			variant: 'contained',
			onClick: () => {
				return;
			}
		}
	];

	const info = (row: Row<any>, cell: Cell<any, any>, evt: any) => {
		setSelectedGenericRow({} as IStep3GenericValues);
		setSelectedVehicleRow({} as IStep3VehicleValues);
		if (cell.id.includes('Borrar') && evt.target.type === 'button') {
			setRowForDelete(row);
			setIsDialogDeleteOpen(true);
		}
		if (cell.id.includes('accessories') && evt.target.type === 'button') {
			if (evt.target.id === 'addAccessory') {
				setSelectedGenericRow(row.original);
				setOpenModal(true);
			}
			if (evt.target.id === 'editAccessory') {
				setSelectedGenericRow(row.original);
				setOpenSelectAccessoryModal(true);
			}
		}
		if (cell.id.includes('Ver') && evt.target.type === 'button') {
			if (row.original.rightType.includes('Genérico')) {
				setSelectedGenericRow(row.original);
				setOpenGenericDetails(true);
			}
			if (row.original.rightType.includes('Vehículo')) {
				setSelectedVehicleRow(row.original as IStep3VehicleValues);
				setOpenVehicleDetails(true);
			}
			if (row.original.rightType.includes('Inmueble')) {
				setSelectedPropertyRow(row.original);
				setOpenPropertyDetails(true);
			}
		}
		if (cell.id.includes('Editar') && evt.target.type === 'button') {
			setSelectedRow(row.original);
			movContext?.setItemSelected(row.original);
			setOpenEditItemModal(true);
		}
	};

	return (
		<Box
			className={style.tableContainer}
			sx={{ width: '100%', overflowX: 'auto' }}>
			<Typography
				fontWeight={500}
				sx={{ width: '100%', fontSize: '20px' }}>
				Lista de Bienes Registrados
			</Typography>
			<div className="pagination">
				<div className={style.paginationControls}>
					<div className={style.paginationHeader}>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Button
								variant="outlined"
								color="primary"
								sx={{ ml: '3px', mr: '3px', fontWeight: '600', maxHeight: '38px' }}
								onClick={() => table.setPageIndex(0)}
								disabled={!table.getCanPreviousPage()}>
								{'<<'}
							</Button>
							<Button
								variant="outlined"
								color="primary"
								sx={{ ml: '3px', mr: '3px', fontWeight: '600', maxHeight: '38px' }}
								onClick={() => table.previousPage()}
								disabled={!table.getCanPreviousPage()}>
								{'<'}
							</Button>
							<Button
								variant="outlined"
								color="primary"
								sx={{ ml: '3px', mr: '3px', fontWeight: '600', maxHeight: '38px' }}
								onClick={() => table.nextPage()}
								disabled={!table.getCanNextPage()}>
								{'>'}
							</Button>
							<Button
								variant="outlined"
								color="primary"
								sx={{ ml: '3px', mr: '3px', fontWeight: '600', maxHeight: '38px' }}
								onClick={() => table.setPageIndex(table.getPageCount() - 1)}
								disabled={!table.getCanNextPage()}>
								{'>>'}
							</Button>
						</Box>
						<Box sx={{ display: 'flex', justifySelf: 'end', alignSelf: 'end' }}>
							<div className="page">
								<Typography
									fontWeight={500}
									sx={{ fontSize: '16px', mr: 1 }}>
									Página:
								</Typography>

								<Typography
									fontWeight={500}
									sx={{ fontSize: '16px' }}>
									{table.getState().pagination.pageIndex + 1} de {table.getPageCount()}
								</Typography>
							</div>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Typography
									fontWeight={500}
									sx={{ fontSize: '16px' }}>
									| Ir a la Página:
								</Typography>
								<input
									className={style.inputNpage}
									type="number"
									defaultValue={table.getState().pagination.pageIndex + 1}
									onChange={(e) => {
										if (parseInt(e.target.value) > table.getPageCount()) return;
										const page = e.target.value ? Number(e.target.value) - 1 : 0;
										table.setPageIndex(page);
									}}
								/>
							</Box>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<select
									className={`${style.selectMostrar} select-mostrar border rounded p-2 `}
									value={table.getState().pagination.pageSize}
									onChange={(e) => {
										table.setPageSize(Number(e.target.value));
									}}>
									{[10, 20, 30, 40, 50].map((pageSize) => (
										<option
											key={pageSize}
											value={pageSize}>
											Mostrar {pageSize}
										</option>
									))}
								</select>
							</Box>
						</Box>
					</div>
				</div>
			</div>
			<h5 className="total">{`Total: ${data?.length || 0}`}</h5>

			{loading ? (
				<div className="table-loading">
					<BounceLoader
						size={100}
						speedMultiplier={1.5}
					/>
				</div>
			) : (
				<>
					<table style={{ transition: 'all 2s ease' }}>
						<thead>
							{table.getHeaderGroups().map((headerGroup) => (
								<tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => {
										return (
											<th
												key={header.id}
												colSpan={header.colSpan}>
												{header.isPlaceholder ? null : (
													<div
														{...{
															className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
															onClick: header.column.getToggleSortingHandler()
														}}>
														{flexRender(header.column.columnDef.header, header.getContext())}
														{{
															asc: ' 🔼',
															desc: ' 🔽'
														}[header.column.getIsSorted() as string] ?? null}
													</div>
												)}
											</th>
										);
									})}
								</tr>
							))}
						</thead>
						<tbody>
							{table.getRowModel().rows.map((row) => {
								return (
									<Fragment key={row.id}>
										<tr>
											{/* first row is a normal row */}
											{row.getVisibleCells().map((cell) => {
												return (
													<td
														onClick={(evt) => info(row, cell, evt)}
														key={cell.id}>
														{flexRender(cell.column.columnDef.cell, cell.getContext())}
													</td>
												);
											})}
										</tr>
										{row.getIsExpanded() && (
											<tr style={{ transition: 'all 2s ease' }}>
												{/* 2nd row is a custom 1 cell row */}
												<td
													colSpan={row.getVisibleCells().length}
													style={{ wordBreak: 'break-word' }}>
													{renderSubComponent({ row })}
												</td>
											</tr>
										)}
									</Fragment>
								);
							})}
						</tbody>
					</table>

					<div className="pagination-btn-bottom">
						<Button
							variant="outlined"
							color="primary"
							sx={{ ml: '3px', mr: '3px', fontWeight: '600' }}
							onClick={() => table.setPageIndex(0)}
							disabled={!table.getCanPreviousPage()}>
							{'<<'}
						</Button>
						<Button
							variant="outlined"
							color="primary"
							sx={{ ml: '3px', mr: '3px', fontWeight: '600' }}
							onClick={() => table.previousPage()}
							disabled={!table.getCanPreviousPage()}>
							{'<'}
						</Button>
						<Button
							variant="outlined"
							color="primary"
							sx={{ ml: '3px', mr: '3px', fontWeight: '600' }}
							onClick={() => table.nextPage()}
							disabled={!table.getCanNextPage()}>
							{'>'}
						</Button>
						<Button
							variant="outlined"
							color="primary"
							sx={{ ml: '3px', mr: '3px', fontWeight: '600' }}
							onClick={() => table.setPageIndex(table.getPageCount() - 1)}
							disabled={!table.getCanNextPage()}>
							{'>>'}
						</Button>
					</div>
				</>
			)}
			{openModal && (
				<ModalAccessories
					openModal={openModal}
					setOpenModal={setOpenModal}
					setRightList={setRightList}
					rightList={rightList}
					selectedRow={selectedGenericRow}
				/>
			)}
			{openSelectAccessoryModal && (
				<ModalSelectAccessory
					openModal={openSelectAccessoryModal}
					setOpenModal={setOpenSelectAccessoryModal}
					selectedRow={selectedGenericRow}
					setSelectedAccessoryRow={setSelectedAccessoryRow}
				/>
			)}
			{openEditAccessoryModal && (
				<ModalEditAccessory
					openModal={openEditAccessoryModal}
					setOpenModal={setOpenEditAccessoryModal}
					rightList={rightList}
					setRightList={setRightList}
					selectedRow={selectedGenericRow}
					selectedAccessoryRow={selectedAccessoryRow}
					setSelectedAccessoryRow={setSelectedAccessoryRow}
				/>
			)}
			{openGenericDetails && (
				<ModalGeneric
					openDetails={openGenericDetails}
					setOpenDetails={setOpenGenericDetails}
					selectedRow={selectedGenericRow}
				/>
			)}
			{openVehicleDetails && (
				<ModalVehicle
					openDetails={openVehicleDetails}
					setOpenDetails={setOpenVehicleDetails}
					selectedRow={selectedVehicleRow as any}
				/>
			)}
			{openPropertyDetails && (
				<ModalProperty
					openDetails={openPropertyDetails}
					setOpenDetails={setOpenPropertyDetails}
					selectedRow={selectedPropertyRow}
				/>
			)}
			{isDialogDeleteOpen && (
				<CustomDialog
					type={DialogType.SECONDARY}
					Custombuttons={dialogDelete}
					content={'¿Está seguro que desea eliminar el item?'}
					buttonPosition={'center'}
					setShowDialog={setIsDialogDeleteOpen}
				/>
			)}
			{openEditItemModal && (
				<EditItemModal
					openModal={openEditItemModal}
					setOpenModal={setOpenEditItemModal}
					selectedRow={selectedRow}
					setSelectedRow={setSelectedRow}
					setRightList={setRightList}
					rightList={rightList}
				/>
			)}
		</Box>
	);
});

Paso3Table.displayName = 'Paso3Table';

export { Paso3Table };
