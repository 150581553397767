import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { IFieldHiringFilePropertie } from 'interfaces/commons/IParameters';
import { GenericFieldDetail } from './FieldDetail';

export const FieldExpedientDetails = ({
	label = 'Expediente:',
	agencyCode = '-',
	number = '-',
	year = '-',
	defaultPaddingLeft = '15px'
}: IFieldHiringFilePropertie) => {
	return (
		<Grid
			item
			xs={12}
			sm={12}
			md={12}
			lg={12}
			xl={12}>
			<Grid
				container
				spacing={2}>
				{label !== undefined && label !== null ? (
					<>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}>
							<Typography
								fontSize={16}
								variant="h6">
								{label}
							</Typography>
						</Grid>
					</>
				) : null}

				{agencyCode !== undefined && agencyCode !== null ? (
					<>
						<GenericFieldDetail
							label={'Código de Organismo:'}
							value={agencyCode}
							paddingLeft={defaultPaddingLeft}
						/>
						<Divider className="customDivider" />
					</>
				) : null}

				{number !== undefined && number !== null ? (
					<>
						<GenericFieldDetail
							label={'Número:'}
							value={number}
							paddingLeft={defaultPaddingLeft}
						/>
						<Divider className="customDivider" />
					</>
				) : null}

				{year !== undefined && year !== null ? (
					<>
						<GenericFieldDetail
							label={'Año:'}
							value={year}
							paddingLeft={defaultPaddingLeft}
						/>
						<Divider className="customDivider" />
					</>
				) : null}
			</Grid>
		</Grid>
	);
};
