import { Dispatch, SetStateAction } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { IMeta } from 'interfaces/commons/IResponse';

export interface INotifPaginatorProps {
	showInputPage?: boolean;
	meta: IMeta;
	setMeta: Dispatch<SetStateAction<IMeta>>;
}

export const NotificationsPaginator = ({ showInputPage = false, meta, setMeta }: INotifPaginatorProps) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
			<Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
				<Box sx={{ mt: 2, mb: 2, display: 'flex', flexWrap: 'nowrap' }}>
					<Button
						variant="outlined"
						color="primary"
						sx={{ ml: '3px', mr: '3px', fontWeight: '600' }}
						onClick={() => setMeta({ ...meta, currentPage: 1 })}
						disabled={meta.currentPage === 1 || meta.currentPage < 1}>
						{'<<'}
					</Button>
					<Button
						variant="outlined"
						color="primary"
						sx={{ ml: '3px', mr: '3px', fontWeight: '600' }}
						onClick={() => setMeta({ ...meta, currentPage: meta.currentPage - 1 })}
						disabled={meta.currentPage === 1 || meta.currentPage < 1}>
						{'<'}
					</Button>
					<Button
						variant="outlined"
						color="primary"
						sx={{ ml: '3px', mr: '3px', fontWeight: '600' }}
						onClick={() => setMeta({ ...meta, currentPage: meta.currentPage + 1 })}
						disabled={meta.currentPage === meta.totalPages || meta.currentPage > meta.totalPages}>
						{'>'}
					</Button>
					<Button
						variant="outlined"
						color="primary"
						sx={{ ml: '3px', mr: '3px', fontWeight: '600' }}
						onClick={() => setMeta({ ...meta, currentPage: meta.totalPages })}
						disabled={meta.currentPage === meta.totalPages || meta.currentPage > meta.totalPages}>
						{'>>'}
					</Button>
				</Box>
				{showInputPage && (
					<>
						<Box
							className="pagination-page"
							sx={{ width: '100%' }}>
							<Box
								sx={{
									width: '100%',
									display: 'flex',
									flexDirection: 'row',
									flexWrap: 'nowrap',
									alignItems: 'center',
									justifyContent: 'end'
								}}>
								<Typography>{'Página:'}</Typography>
								<Typography>
									{meta.currentPage | 1} de {meta.totalPages | 1}
								</Typography>
								<Typography style={{ height: '100%', margin: 0, marginLeft: '10px' }}>| Ir a la página:</Typography>
								<input
									type="number"
									defaultValue={meta.currentPage}
									onChange={(e) => {
										if (Number(e.target.value) < 0) return;
										if (e.target.value === '') return;
										if (Number(e.target.value) === meta.currentPage) return;
										if (Number(e.target.value) <= meta.totalPages) {
											setMeta({ ...meta, currentPage: Number(e.target.value) });
										} else {
											return;
										}
									}}
									className="border m-1 p-1 rounded input-page"
								/>
							</Box>
						</Box>
					</>
				)}
			</Box>
			{showInputPage && (
				<Box sx={{ display: 'flex', justifyContent: 'end', pr: 2 }}>
					<select
						className="select-mostrar border rounded"
						value={meta.itemsPerPage}
						onChange={(e) => {
							setMeta({ ...meta, itemsPerPage: Number(e.target.value) });
						}}>
						{[10, 20, 30, 40, 50].map((pageSize) => (
							<option
								key={pageSize}
								value={pageSize}>
								Mostrar {pageSize}
							</option>
						))}
					</select>
				</Box>
			)}
		</div>
	);
};
