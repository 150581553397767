import { Dispatch, SetStateAction, useEffect } from 'react';

// Components
import { EditItemGen } from './EditItemGen/EditItemGen';
import { EditItemVeh } from './EditItemVeh/EditItemVeh';

// Interfaces
import { useMovContext } from 'hooks/useMovContext';
import { EditItemProp } from './EditItemProp/EditItemProp';

export interface IEditItemModalProps {
	openModal: boolean;
	setOpenModal: Dispatch<SetStateAction<boolean>>;
	rightList: any[];
	setRightList: Dispatch<SetStateAction<any[]>>;
	selectedRow: any | null;
	setSelectedRow: Dispatch<SetStateAction<any | null>>;
}

export const EditItemModal = ({ openModal, setOpenModal, rightList, setRightList, selectedRow, setSelectedRow }: IEditItemModalProps) => {
	const movContext = useMovContext();

	useEffect(() => {
		movContext?.setItemSelected(selectedRow);
		return () => {
			movContext?.setItemSelected(null);
		};
	}, []);
	return (
		<>
			{selectedRow?.rightType === 'Genérico' ? (
				<EditItemGen
					openModal={openModal}
					setOpenModal={setOpenModal}
					rightList={rightList}
					setRightList={setRightList}
					selectedRow={selectedRow}
					setSelectedRow={setSelectedRow}
				/>
			) : null}
			{selectedRow?.rightType === 'Vehículo' ? (
				<EditItemVeh
					openModal={openModal}
					setOpenModal={setOpenModal}
					rightList={rightList}
					setRightList={setRightList}
					selectedRow={selectedRow}
					setSelectedRow={setSelectedRow}
				/>
			) : null}
			{selectedRow?.rightType === 'Inmueble' ? (
				<EditItemProp
					openModal={openModal}
					setOpenModal={setOpenModal}
					rightList={rightList}
					setRightList={setRightList}
					selectedRow={selectedRow}
					setSelectedRow={setSelectedRow}
				/>
			) : null}
		</>
	);
};
