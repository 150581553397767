/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ButtonConfirm, ButtonDecline } from 'components/globals/customsBtn/customBtn';
import { FormGenericInput } from 'components/globals/inputs/FormGenericInput';
import { DEFAULT_NO_ERROR, fieldValidator, requiredValidator } from 'helpers/Validators/Validators';
import { IRole, IRoleResquestBody } from 'interfaces/role/IRole';
import { FormCredentials } from 'components/globals/layouts/FormCredentials';
import { FormGenericMultiline } from 'components/globals/inputs/FormGenericMultiline';

export interface IOverviewUserForm {
	entity: IRole | undefined;
	handleSubmit: (data: IRoleResquestBody) => void;
	handleCancel: () => void;
}

export interface IErrorsForm {
	name?: string | undefined;
	description?: string | undefined;
}

export const LABELS_PROFILE_FORM = {
	name: 'Nombre del perfil',
	description: 'Descripción'
};

export const OverviewProfileForm = ({ entity, handleSubmit, handleCancel }: IOverviewUserForm) => {
	const [id, setId] = useState<number | undefined>(entity?.id);
	const [name, setName] = useState<string | undefined>(entity?.name);
	const [description, setDescription] = useState<string | undefined>(entity?.description);
	const [errors, setErrors] = useState<IErrorsForm>({} as IErrorsForm);
	const isEdit = !!entity;

	const validateForm = (): boolean => {
		let newErrors: IErrorsForm = {} as IErrorsForm;
		setErrors(newErrors);

		let nameError = fieldValidator({
			value: name,
			label: LABELS_PROFILE_FORM.name
		});
		if (nameError !== DEFAULT_NO_ERROR) {
			newErrors.name = nameError;
		}

		let descriptionError = fieldValidator({
			value: description,
			label: LABELS_PROFILE_FORM.description,
			minLength: 3,
			maxLength: 32
		});
		if (descriptionError !== DEFAULT_NO_ERROR) {
			newErrors.description = descriptionError;
		}

		if (Object.entries(newErrors).length !== 0) {
			setErrors(newErrors);
			return false;
		}

		return true;
	};

	const getParsedData = (): IRoleResquestBody => {
		return {
			id: isEdit ? entity.id : 0,
			name: name ? name.trim() : '',
			description: description ? description.trim() : ''
		};
	};

	const onHandleSubmit = (): void => {
		if (!validateForm()) {
			return;
		}
		handleSubmit(getParsedData());
	};

	return (
		<div className="borderedBox">
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'wrap',
					columnGap: '30px',
					rowGap: '30px',
					paddingTop: '30px'
				}}>
				<FormGenericInput
					value={name}
					label={LABELS_PROFILE_FORM.name}
					name="name"
					id="name"
					handleChange={setName}
					error={errors.name}
					maxLength={64}
					required
				/>
				<FormGenericMultiline
					value={description}
					label={LABELS_PROFILE_FORM.description}
					name="description"
					id="description"
					handleChange={setDescription}
					error={errors.description}
				/>
			</Box>

			<Box
				sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
				<ButtonDecline
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => {
						handleCancel();
					}}>
					Cancelar
				</ButtonDecline>
				<ButtonConfirm
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => onHandleSubmit()}>
					Agregar
				</ButtonConfirm>
			</Box>
		</div>
	);
};
