import { useMemo, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { NomenclatureCodeInput } from 'components/globals/NomenclatureCodeInput/NomenclatureCodeInput';
import { FormInputUnitPrice } from 'components/globals/inputs/FormInputUnitPrice';
import { NumberFormatValues } from 'react-number-format';
import { FormGenericMultiline } from 'components/globals/inputs/FormGenericMultiline';
import { NomenclatureCode } from 'services/nomenclatureCodes';
import { QuantityInput } from 'components/globals/QuantityInput/QuantityInput';
import { FormGenericInput } from 'components/globals/inputs/FormGenericInput';

interface GenericProps {
	onChange?(data: GenericFormData): void;
	onSubmit?(data: GenericFormData): Promise<boolean>;
	onReset?: CallableFunction;
	onSuccess?: CallableFunction;
	value?: GenericFormData;
	loading?: boolean;
	submitBtnLabel?: string;
	readonly?: boolean;
	customInputDisable?: {
		[K in keyof GenericFormData]?: boolean;
	};
	isAccessory?: boolean;
	showRowNumberField?: boolean;
}
export interface GenericFormData {
	nomenclatureCode: NomenclatureCode;
	quantity: string;
	unitPrice: NumberFormatValues;
	description: string;
	rowNumber: string;
}

const gridContainerProps = {
	spacing: 2,
	container: true,
	pt: 3
};

const gridItemProps = {
	item: true,
	xs: 12,
	sm: 12,
	md: 12,
	lg: 4,
	xl: 4
};

const defaultFormData: GenericFormData = {
	nomenclatureCode: undefined as unknown as NomenclatureCode,
	quantity: '',
	unitPrice: { value: '', formattedValue: '', floatValue: 0 },
	description: '',
	rowNumber: ''
};

const GenericForm = ({
	onSubmit,
	value,
	onChange,
	loading,
	submitBtnLabel,
	readonly,
	onSuccess,
	customInputDisable,
	isAccessory,
	showRowNumberField
}: GenericProps) => {
	const [formData, setFormData] = useState<GenericFormData>(value || defaultFormData);

	const isSubmitDisabled = useMemo(() => {
		const { description, nomenclatureCode, unitPrice, quantity, rowNumber } = formData;
		const code = (nomenclatureCode && !customInputDisable?.nomenclatureCode) || true;

		if (!customInputDisable?.nomenclatureCode) {
			return !(description && code && quantity && unitPrice && nomenclatureCode);
		} else {
			return !(description && code && quantity && unitPrice);
		}
	}, [formData]);

	const handleFieldChange = (name: keyof GenericFormData) => {
		return (value: unknown) => {
			if (name == 'description') {
				setFormData((curr) => ({ ...curr, [name]: (value as string).toString().trim() }));
			} else {
				setFormData((curr) => ({ ...curr, [name]: value }));
			}
			onChange && onChange(formData);
		};
	};

	const handleSubmit = async () => {
		if (onSubmit) {
			const shouldResetForm = await onSubmit(formData);

			if (shouldResetForm) {
				setFormData(defaultFormData);
				onSuccess && onSuccess();
			}
		}
	};

	return (
		<Grid {...gridContainerProps}>
			<Grid
				item
				width="100%">
				<Typography
					variant="h1"
					fontSize={24}
					fontWeight={500}
					borderBottom="1px solid"
					borderColor="primary"
					pb={1}>
					{isAccessory ? 'Datos del Accesorio' : 'Datos Generales'}
				</Typography>
			</Grid>

			{!customInputDisable?.nomenclatureCode ? (
				<Grid
					item
					width={'100%'}>
					<NomenclatureCodeInput
						required={!customInputDisable?.nomenclatureCode}
						propertyTypeId={1}
						disabled={readonly}
						value={formData.nomenclatureCode}
						onChange={handleFieldChange('nomenclatureCode')}
					/>
				</Grid>
			) : null}

			<Grid
				{...gridContainerProps}
				marginLeft={0}>
				<Grid {...gridItemProps}>
					<QuantityInput
						required
						disabled={readonly || customInputDisable?.quantity}
						maxLength={3}
						size="small"
						label="Cantidad"
						value={formData.quantity}
						onChange={handleFieldChange('quantity')}
					/>
				</Grid>

				<Grid {...gridItemProps}>
					<FormInputUnitPrice
						disabled={readonly}
						handleChange={handleFieldChange('unitPrice')}
						id="price-input"
						name="unit-price-input"
						size="small"
						label="Precio Unitario"
						value={formData.unitPrice}
					/>
				</Grid>
				{showRowNumberField ? (
					<Grid {...gridItemProps}>
						<FormGenericInput
							disabled={readonly}
							handleChange={handleFieldChange('rowNumber')}
							size="small"
							type="number"
							label="Renglón"
							value={formData.rowNumber}
						/>
					</Grid>
				) : null}
			</Grid>

			<Grid
				item
				width={'100%'}>
				<FormGenericMultiline
					disabled={readonly}
					handleChange={handleFieldChange('description')}
					name="description"
					id="description"
					label="Descripción del Bien"
					value={formData.description}
					limit={256}
				/>

				{!readonly ? (
					<Box
						mt={2}
						sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							disabled={isSubmitDisabled || loading}
							variant="contained"
							onClick={handleSubmit}>
							{submitBtnLabel || 'Agregar Bien'}
						</Button>
					</Box>
				) : null}
			</Grid>
		</Grid>
	);
};

export default GenericForm;
