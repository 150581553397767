import { useState, useEffect, memo, Dispatch, SetStateAction } from 'react';
import { NumericFormat, NumberFormatValues } from 'react-number-format';
import { FormControl, FormHelperText, TextField } from '@mui/material';

interface IInputUnitPriceProps {
	value: NumberFormatValues | null;
	handleChange?: Dispatch<SetStateAction<NumberFormatValues | null>>;
	sx?: object;
	name?: string;
	id?: string;
	label?: string;
	required?: boolean;
	disabled?: boolean;
	hint?: string;
	fullWidth?: boolean;
	error?: string;
	size?: 'small' | 'medium' | undefined;
}

const FormInputUnitPrice = memo(
	({
		value,
		handleChange = () => void 0,
		sx,
		label,
		required = true,
		name = 'generic',
		id = 'generic',
		disabled = false,
		hint,
		error,
		size = 'medium',
		fullWidth = true
	}: IInputUnitPriceProps) => {
		const [internalState, setInternalState] = useState<NumberFormatValues | null>(null);
		const [valueHasChnaged, setValueHasChnaged] = useState<number>(0);

		useEffect(() => {
			setInternalState(value);
		}, [value]);

		useEffect(() => {
			if (valueHasChnaged) {
				handleChange(internalState);
			}
		}, [valueHasChnaged]);

		const hintId = `${id}_hint`;
		const errorId = `${id}_error`;

		return (
			<FormControl fullWidth={fullWidth}>
				<NumericFormat
					decimalScale={2}
					required={required}
					value={internalState !== null ? internalState?.floatValue : null}
					prefix="$"
					thousandsGroupStyle="none"
					thousandSeparator="."
					decimalSeparator=","
					onValueChange={(values) => {
						setInternalState(values);
						setValueHasChnaged(valueHasChnaged + 1);
					}}
					customInput={TextField}
					name={name}
					id={id}
					sx={sx}
					label={label}
					size={size}
					disabled={disabled}
					InputLabelProps={{
						shrink: true
					}}
				/>
				{hint !== undefined ? <FormHelperText id={hintId}>{hint}</FormHelperText> : null}
				{error !== undefined ? (
					<FormHelperText
						id={errorId}
						error={true}>
						{error}
					</FormHelperText>
				) : null}
			</FormControl>
		);
	}
);

FormInputUnitPrice.displayName = 'FormInputUnitPrice';

export { FormInputUnitPrice };
