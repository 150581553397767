import { useAuth } from 'hooks/useAuth';
import { ToolBar } from 'components/ui/ToolBar';
import navbar from './navbar.module.css';

import { Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';

import contaduria from 'assets/contaduria-general.svg';
import image from 'assets/header-colors.svg';
import { FormDialog } from 'components/globals/dialog/FormDialog';
import { OverviewSummaryUserForm } from 'components/user/OverviewSummary';
import { useUsersContext } from 'hooks/useUsersContext';
import { IChangePasswordResquestBody, IUserResquestBody } from 'interfaces/user/IUser';
import { OverviewUserChangePasswordForm } from 'components/user/OverviewChangePassword';
import { useState } from 'react';
import misionesBlanco from '../../../../assets/Misiones-Blanco.svg';

export const Navbar = () => {
	const auth = useAuth();
	const usersContext = useUsersContext();

	// START Handle Edit Profile
	const [showProfileModal, setShowProfileModal] = useState(false);

	const onHandleSubmitProfile = async (entity: IUserResquestBody | undefined) => {
		if (!entity) {
			return;
		}
		const result = await usersContext?.onHandleTryUpdatePersonData(entity);
		if (result) {
			setShowProfileModal(false);
		}
	};
	// END Handle Edit Profile

	// START Handle Password Change
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

	const onHandleChangePasswordConfirm = async (entity: IChangePasswordResquestBody) => {
		if (!entity) {
			return false;
		}
		const result = await usersContext?.onHandleTryChangePassword(entity, auth?.user?.id);
		if (result) {
			setShowChangePasswordModal(false);
		}
	};
	// END Handle Password Change

	return (
		<>
			{showProfileModal && (
				<FormDialog
					show={showProfileModal}
					title={'Mi Perfil'}
					content={
						<OverviewSummaryUserForm
							entity={{
								id: auth?.user?.id ? auth.user.id : 0,
								first_name: auth?.user?.first_name ? auth.user.first_name : '',
								last_name: auth?.user?.last_name ? auth.user.last_name : '',
								email: auth?.user?.email ? auth.user.email : '',
								fullName: '',
								role_id: auth?.user?.role?.id ? auth?.user.role.id : 0,
								is_active: true,
								group_id: auth?.user?.group?.id ? auth?.user.group.id : 0,
								patrimonial_dependence_id: auth?.user?.patrimonial_dependence?.id
									? auth?.user.patrimonial_dependence.id
									: 0
							}}
							handleSubmit={(entity) => {
								onHandleSubmitProfile(entity);
							}}
							handleCancel={() => {
								setShowProfileModal(false);
							}}
						/>
					}
					actions={<></>}
					handleClose={(value) => setShowProfileModal(false)}
				/>
			)}

			{showChangePasswordModal && (
				<FormDialog
					show={showChangePasswordModal}
					title={'Cambiar Contraseña'}
					content={
						<OverviewUserChangePasswordForm
							handleSubmit={(entity: IChangePasswordResquestBody) => {
								onHandleChangePasswordConfirm(entity);
							}}
							handleCancel={() => {
								setShowChangePasswordModal(false);
							}}
						/>
					}
					actions={<></>}
					handleClose={(value) => setShowChangePasswordModal(false)}
				/>
			)}

			<div className={navbar.full}>
				<img
					src={image}
					alt="bg"
					className={navbar.bg}
				/>
				<Box
					className={navbar.content}
					sx={{
						backgroundColor: '#EDEDED',
						width: '100%',
						height: '7vh',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-around'
					}}>
					<div>
						<img
							className={navbar.logos}
							src={misionesBlanco}
							alt="Misiones"
							width={170}
						/>
						<img
							className={navbar.logos}
							src={contaduria}
							alt="Contaduria"
							width={80}
						/>
					</div>
					<div>
						<Typography sx={{ fontSize: '25px', fontWeight: '300' }}>Administración de Bienes Patrimoniales</Typography>
					</div>
					<div>
						<div className="dropdown">
							<button
								className={`${navbar.btnSecondary} btn btn-secondary dropdown-toggle primary`}
								type="button"
								id="dropdownMenuButton"
								data-toggle="dropdown"
								aria-expanded="false">
								<PersonIcon />
								<span className={navbar.spanDropdown}>
									{`${auth?.user?.first_name} ${auth?.user?.last_name}` || 'Null'}
								</span>
							</button>
							<div
								className="dropdown-menu dropdown-menu-right"
								aria-labelledby="dropdownMenuButton"
								style={{ fontSize: '13px', fontFamily: 'Segoe UI', width: '230px' }}>
								<span
									style={{
										marginLeft: '10px',
										marginRight: '0px',
										textAlign: 'center',
										fontWeight: '500'
									}}>
									<Box padding={1}>
										{`${auth?.user?.role?.name}`}
										<Box mt={1}>{auth?.user?.patrimonial_dependence?.patrimonial_dependence}</Box>
									</Box>
								</span>
								<hr />
								<a
									className="dropdown-item"
									href="#"
									type="button"
									onClick={() => {
										setShowProfileModal(true);
									}}>
									<PersonIcon />
									<span className={navbar.spanDropdown}>Mi Perfil</span>
								</a>
								<a
									className="dropdown-item"
									href="#"
									type="button"
									onClick={() => {
										setShowChangePasswordModal(true);
									}}>
									<LockIcon />
									<span className={navbar.spanDropdown}>Cambiar Contraseña</span>
								</a>
								<div className="dropdown-divider"></div>
								<a
									className="dropdown-item"
									href="#"
									type="button"
									onClick={() => {
										auth.logout();
									}}>
									<LogoutIcon />
									<span className={navbar.spanDropdown}>Cerrar Sesion</span>
								</a>
							</div>
						</div>
					</div>
				</Box>
				<ToolBar />
			</div>
		</>
	);
};
