/* eslint-disable @typescript-eslint/no-unused-vars */
import { IFile } from 'interfaces/file/IFile';
import { useMemo } from 'react';
import { Row, Cell } from '@tanstack/react-table';
import { defaultColumns } from './AttachmentsFilesColumnDef';
import { CustomTable } from 'components/globals/table/customTable';

export interface IAttachmentsFiles {
	files: IFile[];
}

export const AttachmentsFiles = ({ files = [] }: IAttachmentsFiles) => {
	const columns = useMemo<typeof defaultColumns>(() => [...defaultColumns], [defaultColumns]);

	const newFiles = files.filter((file) => file?.length !== undefined && file?.length > 0);
	const handleDownload = (ruta: string, name: string) => {
		if (ruta === undefined || ruta === null || ruta === '#' || ruta === '') {
			return;
		}
		const lastIndex = ruta.lastIndexOf('/');
		fetch(ruta, {
			method: 'GET',
			headers: {}
		})
			.then((response) => {
				response.arrayBuffer().then(function (buffer) {
					const url = window.URL.createObjectURL(new Blob([buffer]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', name);
					document.body.appendChild(link);
					link.click();
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleAction = (row: Row<any>) => {
		switch (row.original?.action) {
			case 'download':
				handleDownload(row.original[0]?.path, row.original[0]?.name);
				break;
			default:
				break;
		}
	};

	return (
		<div className={'customBodyContainerTable'}>
			<CustomTable
				columns={columns}
				elements={newFiles}
				defaultSortinField={'file_type'}
				handleAction={(row: Row<any>, cell?: Cell<any, any>, evt?: any) => handleAction(row)}
			/>
		</div>
	);
};
