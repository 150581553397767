import { useEffect, useState } from 'react';
import { Divider, Grid } from '@mui/material';
import { FieldDate } from './../../globals/fields/FieldDate';
import { GenericFieldDetail } from 'components/globals/fields/FieldDetail';
import { currencyLocalFormat } from 'helpers/Formaters/Formaters';
import { IBajaAccessories } from 'interfaces/baja/IBaja';
import { DeregistrationTypes } from 'enums';
import { getPatrimonialDependencies } from 'services';

interface ISummaryItem {
	entity?: IBajaAccessories;
}

export const SummaryItem = ({ entity }: ISummaryItem) => {
	const [toPatrimonialDependence, setToPatrimonialDependence] = useState<string>('-');

	useEffect(() => {
		if (entity?.action) {
			delete entity.action;
		}
		if (entity?.to_patrimonial_dependence_id) {
			getPatrimonialDependencies(String(entity?.to_patrimonial_dependence_id)).then((res) => {
				entity.to_patrimonial_dependence_name = res.data[0].patrimonial_dependence;
				setToPatrimonialDependence(res.data[0].patrimonial_dependence);
			});
		}
	}, [entity]);

	return (
		<Grid
			container
			spacing={1}
			mt={1}>
			<GenericFieldDetail
				label={'N° de Inventario:'}
				value={entity?.property?.item_inventory_number}
			/>
			<Divider className="customDivider" />

			<GenericFieldDetail
				label={'N° de Accesorio:'}
				value={entity?.property?.accessory_number}
			/>
			<Divider className="customDivider" />

			<GenericFieldDetail
				label={'Código de Nomenclador:'}
				value={entity?.property?.account_nomenclature?.account ?? entity?.property?.account_nomenclature?.sub_group}
			/>
			<Divider className="customDivider" />

			<GenericFieldDetail
				label={'Descripción de Nomenclador:'}
				value={entity?.property?.nomenclature_description?.replace('. - null', '.')}
			/>
			<Divider className="customDivider" />

			<GenericFieldDetail
				label={'Precio Unitario:'}
				value={
					entity?.property?.unit_price !== undefined && entity?.property?.unit_price !== null
						? currencyLocalFormat(entity.property.unit_price)
						: '-'
				}
			/>
			<Divider className="customDivider" />

			<FieldDate
				label={'Fecha de Alta:'}
				value={entity?.property?.discharge_date}
			/>
			<Divider className="customDivider" />

			<GenericFieldDetail
				label={'Observaciones:'}
				value={entity?.description}
			/>
			<Divider className="customDivider" />

			<GenericFieldDetail
				label={'Tipo de Baja:'}
				value={entity?.deregistration_type ? entity?.deregistration_type?.name : '-'}
			/>

			{toPatrimonialDependence !== '-' && (
				<>
					<Divider className="customDivider" />
					<GenericFieldDetail
						label={'Dependencia Patrimonial de Destino:'}
						value={toPatrimonialDependence}
					/>
				</>
			)}

			{toPatrimonialDependence === '-' && (
				<>
					<Divider className="customDivider" />
					<GenericFieldDetail
						label={'Destino:'}
						value={entity?.description_destiny}
					/>
				</>
			)}
		</Grid>
	);
};
