import { HeaderRoutes } from 'components/globals/headerRoutes/HeaderRoutes';
import { RegistrationState } from 'contexts/registrationContext/RegistrationState';
import { StepperComponent } from 'components/altas/stepper/Stepper';
import { AltasOverview } from 'components/altas/AltasOverview';

export const RegistrationPage = () => {
	return (
		<>
			<RegistrationState>
				<HeaderRoutes
					title={'Formulario de'}
					strongTitle={'Alta'}>
					<>
						<StepperComponent />
						<AltasOverview />
					</>
				</HeaderRoutes>
			</RegistrationState>
		</>
	);
};
