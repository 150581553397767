import apiInstance from 'interceptors/axiosInterceptor';
import { trackPromise } from 'react-promise-tracker';
import { ISheetsFilters } from 'interfaces/bienesFiscales/IBFContext';

export const getAllSheets = async (status_id: string, page: number, limit: number) => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const promise = trackPromise(
		new Promise((resolve, reject) => {
			resolve(
				apiInstance
					.get(
						'/sheets/home-filter',

						{
							headers: { Authorization: `Bearer ${token}` },
							params: { status_id, page, limit }
						}
					)
					.then((response) => {
						return response;
					})
					.catch((error) => {
						return error;
					})
			);
		}),
		'getAllSheetsTracker'
	);

	return promise;
};

export const getAllSheetsByFilters = async (filters: ISheetsFilters) => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const promise = trackPromise(
		new Promise((resolve, reject) => {
			resolve(
				apiInstance
					.get(
						'/sheets/home-filter',

						{
							headers: { Authorization: `Bearer ${token}` },
							params: { ...filters }
						}
					)
					.then((response) => {
						return response;
					})
					.catch((error) => {
						return error;
					})
			);
		}),
		'getAllSheetsByFiltersTracker'
	);

	return promise;
};
