import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';

const styleModal = {
	position: 'absolute' as const,
	top: '45%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '44%',
	minWidth: '532px',
	maxHeight: '95%',
	bgcolor: '#e4e4e4',
	border: '2px solid #000000b9',
	boxShadow: 24
};

// Services
import { deleteMovement } from 'services/Movements/crudMovements';

// Interfaces
import { IMovementEdit } from 'interfaces/movements/MovementEdit';

// Hooks
import { useMovContext } from 'hooks/useMovContext';

// Material UI
import { MovementStatusType } from 'enums';
import { CustomDialog } from 'components/globals/dialog/CustomDialog';
import { DialogType, ICustomButtonProps } from 'components/globals/dialog/utils';

/**
 * @requisitos
 *
 * 1. Contendrá el mensaje "¿Desea confirmar el registro? Se generara el numero de Acta y los números de inventarios" 0020/2022 ----- 0026/2022.
 * 2. Deberá contener una etiqueta "Acta de Recepción Numero" + Campo de Texto para introducir manualmente el numero de acta
 * (el sistema deberá sugerir el siguiente numero al anterior registro guardado).
 * 3. Con los datos de pre-carga deberá mostrar además una etiqueta con la Leyenda "Números de Inventario que se generarán XXXX al XXXX".
 * 4. Una vez confirmado, el sistema asignara el numero de acta y los números de inventario para cada uno de los bienes que hayan sido cargados en el modulo de Alta.
 * 5. Permitirá descargar el Acta de Recepción.
 */

interface IModalDeleteProps {
	openModal: boolean;
	setOpenModal: Dispatch<SetStateAction<boolean>>;
	selectedRow: any;
	data: IMovementEdit[];
	setData: Dispatch<SetStateAction<IMovementEdit[]>>;
	onSuccess?: CallableFunction;
}

export const DeleteMovement = ({
	openModal,
	setOpenModal,
	selectedRow,
	data,
	setData,
	onSuccess
}: IModalDeleteProps) => {
	const movContext = useMovContext();
	const [loading, setLoading] = useState(false);
	const [opacity, setOpacity] = useState(false);
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		if (selectedRow.movement_status_id !== MovementStatusType.PENDIENTE) {
			setDisabled(true);
			return;
		}
	}, [selectedRow]);

	const handleLoading = () => {
		setLoading(true);
		setTimeout(() => {
			setOpacity(true);
		}, 300);
		setTimeout(() => {
			toast.warning('Movimiento eliminado con éxito');
			setLoading(false);
			setOpacity(false);
		}, 2000);
	};

	const saveChanges = () => {
		deleteMovement(String(selectedRow.id)).then(() => {
			onSuccess && onSuccess();
		});
		const newData = data.filter((item) => item.id !== selectedRow.id);
		setData([...newData]);
		handleLoading();
	};

	const deleteDialogBtns: ICustomButtonProps[] = [
		{
			name: 'Cancelar',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				return;
			}
		},
		{
			name: 'Confirmar',
			color: 'secondary',
			variant: 'contained',
			onClick: () => {
				saveChanges();
			}
		}
	];

	return (
		<div>
			{openModal && (
				<CustomDialog
					title="Eliminar Movimiento"
					type={DialogType.PRIMARY}
					content={'¿Desea Eliminar el registro?'}
					Custombuttons={deleteDialogBtns}
					buttonPosition={'center'}
					setShowDialog={setOpenModal}
				/>
			)}
		</div>
	);
};
