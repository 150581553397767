// Enums
import { ApiPaths } from 'enums/apiPaths';
import { IPaginationRequest } from 'interfaces/commons/IParameters';
import { _Download_File_PDF, _Download_File_XLS } from './downloadFilesService';
import { httpPost, handleHttpError, buildDefaultOptions, httpGet, buildURLQuery, httpPatch } from './httpService';

const URL_BASE = `${ApiPaths.BALANCE}`;
const URL_BALANCE_SUMAMRY = (id: number | undefined) => {
	return URL_BASE + `/${id}`;
};
const URL_SEARCH_ORGANIZATIONAL_UNITS = '/organizational/search';
const URL_CHANGE_STATUS = (id: number | undefined) => {
	return URL_BASE + '/change-sheet-presentation' + `/${id}`;
};
const URL_CLOSE_BALANCE = URL_BASE + '/periodclose';
const URL_DOWNLOAD_PDF_BY_JURISDICTION = (id: number | undefined) => {
	return URL_BASE + '/generate-pdf' + `/${id}`;
};
const URL_DOWNLOAD_XLS_BY_JURISDICTION = (id: number | undefined) => {
	return URL_BASE + '/generate-xls' + `/${id}`;
};

export const tryGetAllRecords = async (jurisdiction_id: number, { page = 1, limit = 10 }: IPaginationRequest): Promise<any> => {
	try {
		const OPTIONS = buildDefaultOptions();
		const queryString = buildURLQuery({ jurisdiction_id, page, limit });
		const URI = `${URL_SEARCH_ORGANIZATIONAL_UNITS}?${queryString}`;
		let response = await httpGet(URI, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryCloseBalance = async (jurisdiction_id: number): Promise<any> => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpPost(URL_CLOSE_BALANCE, { jurisdictions_id: jurisdiction_id }, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryChangeStatusToOrganizationalUnit = async (organization_id: number, status: boolean): Promise<any> => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpPatch(URL_CHANGE_STATUS(organization_id), { sheets_presentation: status }, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const trySummaryBalance = async (jurisdiction_id: number): Promise<any> => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpGet(URL_BALANCE_SUMAMRY(jurisdiction_id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryDownloadCompletePDFSummaryBalance = async (): Promise<any> => {
	try {
		const OPTIONS = buildDefaultOptions();
		return await _Download_File_PDF(URL_BASE, {}, 'Reporte anual completo');
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryDownloadReportPDFSummaryBalance = async (jurisdiction_id: number): Promise<any> => {
	try {
		const OPTIONS = buildDefaultOptions();
		return await _Download_File_PDF(URL_DOWNLOAD_PDF_BY_JURISDICTION(jurisdiction_id), {}, 'Reporte por jurisdicción');
	} catch (error) {
		return handleHttpError(error);
	}
};
