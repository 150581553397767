/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { FormGenericSelect } from 'components/globals/inputs/FormGenericSelect';
import { IAdministrativeType, ISelectOption } from 'interfaces/commons/IParameters';
import { ButtonConfirm, ButtonDecline } from 'components/globals/customsBtn/customBtn';
import { FormGenericInput } from 'components/globals/inputs/FormGenericInput';
import { DEFAULT_NO_ERROR, requiredPartialNomenclatureCode, requiredRangeNumber, requiredValidator } from 'helpers/Validators/Validators';
import { IJurisdiction } from 'interfaces/jurisdictions/IJurisdictions';

export interface IOverviewJurisdictionForm {
	entity: IJurisdiction | undefined;
	handleSubmit: (data: IJurisdiction) => void;
	handleCancel: () => void;
	administrativeTypes: IAdministrativeType[];
}

export interface IErrorsForm {
	administrative_type?: string | undefined;
	code?: string | undefined;
	name?: string | undefined;
}

export const LABELS_JURISDICTION_FORM = {
	administrative_type: 'Tipo de Administración',
	code: 'Código',
	name: 'Nombre de la Jurisdicción'
};

const SEPARATOR = '.';

export const OverviewJurisdictionForm = ({ entity, handleSubmit, handleCancel, administrativeTypes }: IOverviewJurisdictionForm) => {
	const [administrativeType, setAdministrativeType] = useState<ISelectOption | undefined>();
	const [code, setCode] = useState<string | undefined>();
	const [prefixCode, setPrefixCode] = useState<string | undefined>();
	const [name, setName] = useState<string | undefined>(entity?.name);
	const [errors, setErrors] = useState<IErrorsForm>({} as IErrorsForm);
	const isEdit = !!entity;

	useEffect(() => {
		let administrativeType: ISelectOption = { id: 0, label: '' };
		let prefixCode = entity?.code.split(SEPARATOR);
		if (entity && prefixCode && prefixCode[0]) {
			let prefix = prefixCode[0];
			let finded = administrativeTypes.find((at) => at.code == prefix);
			if (finded) {
				administrativeType = { id: finded.id, label: finded.name } as ISelectOption;
			}
			setCode(prefixCode[1]);
		}
		setAdministrativeType(administrativeType);
	}, [entity]);

	useEffect(() => {
		setPrefixCode(undefined);
		if (administrativeType) {
			let prefix = administrativeTypes.find((at) => at.id == administrativeType?.id)?.code;
			if (prefix) {
				setPrefixCode(prefix.concat(SEPARATOR));
			}
		}
	}, [administrativeType]);

	const validateForm = (): boolean => {
		let newErrors: IErrorsForm = {} as IErrorsForm;
		setErrors(newErrors);

		let administrativeTypeError = requiredValidator({
			value: administrativeType?.label,
			label: LABELS_JURISDICTION_FORM.administrative_type
		});
		if (administrativeTypeError !== DEFAULT_NO_ERROR) {
			newErrors.administrative_type = administrativeTypeError;
		}

		let codeError = requiredPartialNomenclatureCode({ value: code, label: LABELS_JURISDICTION_FORM.code });
		if (codeError !== DEFAULT_NO_ERROR) {
			newErrors.code = codeError;
		}

		let nameError = requiredValidator({ value: name, label: LABELS_JURISDICTION_FORM.name });
		if (nameError !== DEFAULT_NO_ERROR) {
			newErrors.name = nameError;
		}

		if (Object.entries(newErrors).length !== 0) {
			setErrors(newErrors);
			return false;
		}

		return true;
	};

	const getParsedData = (): IJurisdiction => {
		return {
			id: isEdit ? entity.id : undefined,
			code: code && prefixCode ? prefixCode?.concat(code.trim()).trim() : '',
			name: name ? name.trim() : ''
		};
	};

	const onHandleSubmit = (): void => {
		if (!validateForm()) {
			return;
		}
		handleSubmit(getParsedData());
	};

	return (
		<div className="borderedBox">
			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '30px', rowGap: '30px', paddingTop: '30px' }}>
				<FormGenericSelect
					value={administrativeType}
					label={LABELS_JURISDICTION_FORM.administrative_type}
					id="administrative_type"
					options={administrativeTypes.map((at) => {
						return { id: at.id, label: at.name } as ISelectOption;
					})}
					handleChange={setAdministrativeType}
					error={errors.administrative_type}
				/>

				<FormGenericInput
					value={code}
					label={LABELS_JURISDICTION_FORM.code}
					name="code"
					id="code"
					startAdornment={prefixCode}
					handleChange={setCode}
					error={errors.code}
				/>

				<FormGenericInput
					value={name}
					label={LABELS_JURISDICTION_FORM.name}
					name="name"
					id="name"
					handleChange={setName}
					error={errors.name}
				/>
			</Box>

			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
				<ButtonDecline
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => {
						handleCancel();
					}}>
					Cancelar
				</ButtonDecline>
				<ButtonConfirm
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => onHandleSubmit()}>
					Agregar
				</ButtonConfirm>
			</Box>
		</div>
	);
};
