import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';

// Services
import { getLastMinuteMovements, updateMovementStatus, generatePdfMovement } from 'services/Movements/crudMovements';

// Interfaces
import { IMovementEdit } from 'interfaces/movements/MovementEdit';

// Hooks
import { usePromiseTracker } from 'react-promise-tracker';

// Material UI
import { Typography, Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { BounceLoader } from 'react-spinners';
import { MovementStatusType } from 'enums';
import PuffLoader from 'react-spinners/PuffLoader';
import { FormDialog } from 'components/globals/dialog/FormDialog';

interface IModalApproveDownProps {
	openModal: boolean;
	setOpenModal: Dispatch<SetStateAction<boolean>>;
	selectedRow: any;
	data: IMovementEdit[];
	setData: Dispatch<SetStateAction<IMovementEdit[]>>;
	setIsDownMovement: Dispatch<SetStateAction<boolean>>;
	onSuccess?: CallableFunction;
}

export const ApproveDownMovement = ({
	openModal,
	setOpenModal,
	selectedRow,
	data,
	setData,
	setIsDownMovement,
	onSuccess
}: IModalApproveDownProps) => {
	const [receptionNumber, setReceptionNumber] = useState(selectedRow.property_deregistration?.minute_number || '');
	const [lastReception, setLastReception] = useState('');
	const [open, setOpen] = useState(true);
	const [loading, setLoading] = useState(false);
	const [opacity, setOpacity] = useState(false);
	const [disabledDownload, setDisabledDownload] = useState(false);
	const { promiseInProgress } = usePromiseTracker({ area: 'generatePdfMovementTracker' });

	useEffect(() => {
		getLastMinuteMovements().then((res) => {
			setLastReception(String(res.data.last_minute));
		});
		if (selectedRow.movement_status_id !== MovementStatusType.PENDIENTE) {
			setDisabledDownload(true);
			return;
		}
	}, []);

	const handleClose = () => {
		setOpen(false);
		setOpenModal(false);
		setIsDownMovement(false);
	};

	const handleLoading = () => {
		setLoading(true);
		setTimeout(() => {
			setOpacity(true);
		}, 300);
	};

	const saveChanges = () => {
		handleLoading();
		updateMovementStatus(selectedRow.id, MovementStatusType.APROBADO, Number(receptionNumber))
			.then(() => {
				const selectRow = data.find((item) => item.id === selectedRow.id);
				if (selectRow) {
					selectRow.movement_status_id = MovementStatusType.APROBADO;
				}
				setData([...data]);

				setDisabledDownload(true);
				setLoading(false);
				setOpacity(false);
				onSuccess && onSuccess();
				toast.success('Movimiento aprobado con éxito');
			})
			.catch((e) => {
				setLoading(false);
				setOpacity(false);
				toast.error(e.response.data.message);
			});
	};

	const downloadReception = () => {
		generatePdfMovement(selectedRow.id, 0);
	};

	return (
		<FormDialog
			title="	¿Desea confirmar el registro?"
			handleClose={() => setOpenModal(false)}
			show={openModal}
			actions={
				<Box
					id="boxButtonsApproveMovement"
					sx={{ display: 'flex', justifyContent: 'center', pb: 2 }}>
					<Button
						onClick={handleClose}
						sx={{ width: '180px' }}
						color="secondary"
						variant="contained">
						Salir
					</Button>

					<Button
						disabled={selectedRow?.movement_status_id !== MovementStatusType.PENDIENTE}
						onClick={saveChanges}
						sx={{ width: '180px', ml: 4 }}
						color="primary"
						variant="contained">
						Confirmar
					</Button>
				</Box>
			}
			content={
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
					<Box>
						<Box
							id="modalApproveMovementContent"
							sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center'
								}}>
								<Typography
									textAlign={'center'}
									fontWeight={'500'}>
									Descargar acta
								</Typography>
								{promiseInProgress ? (
									<Box sx={{ m: 4 }}>
										<PuffLoader
											color="#1a3762"
											size={100}
										/>
									</Box>
								) : disabledDownload ? (
									<FileDownloadIcon
										onClick={() => downloadReception()}
										color="primary"
										sx={{ width: '4em', height: '4em', cursor: 'pointer' }}
									/>
								) : (
									<FileDownloadIcon
										color="disabled"
										sx={{ width: '4em', height: '4em' }}
									/>
								)}
							</Box>
						</Box>
					</Box>
					{loading && (
						<Box
							sx={{
								zIndex: '9999',
								opacity: opacity ? '1' : '0',
								position: 'absolute',
								backdropFilter: 'blur(2px)',
								height: '100%',
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								transition: 'all 800ms ease-in-out'
							}}>
							<BounceLoader
								size={100}
								speedMultiplier={1.5}
							/>
						</Box>
					)}
				</Box>
			}
		/>
	);
};
