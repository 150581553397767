import { FC, Dispatch, SetStateAction, useState, useEffect, ChangeEvent } from 'react';

import { TextField, Typography } from '@mui/material';

interface IInputGenericProps {
	stateValue: string;
	setStateValue: Dispatch<SetStateAction<any>>;
	sx?: object;
	name: string;
	type?: string;
	label: string;
	required?: boolean;
	pattern?: RegExp;
}

export const InputGeneric: FC<IInputGenericProps> = ({ stateValue, setStateValue, sx, pattern, label, required, name, type }) => {
	const [generic, setGeneric] = useState(stateValue);

	useEffect(() => {
		setGeneric(stateValue);
	}, [stateValue]);

	useEffect(() => {
		setStateValue(generic);
	}, [generic]);

	const handleInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
		evt.preventDefault();
		const value = evt.target.value;
		if (value.trim().length === 0) setGeneric('');
		if (pattern) {
			if (!pattern.test(value)) return;
		}
		if (!RegExp(/^[a-zA-Z0-9\sÀ-ÿ\u00f1\u00d1]+[-\s]*[a-zA-Z0-9-\sÀ-ÿ\u00f1\u00d1]*$/g).test(value)) return;
		setGeneric(value);
	};

	const handleLabel = (): string => {
		if (required && label) return `${label} *`;
		if (label) return label;
		return '';
	};

	return (
		<div>
			<TextField
				type={type ? type : 'text'}
				name={name ? name : 'generic'}
				sx={sx ? { minWidth: '220px', flex: '1', flexBasis: '30%', ...sx } : { minWidth: '220px', flex: '1', flexBasis: '30%' }}
				value={generic}
				label={handleLabel()}
				size="small"
				onChange={handleInputChange}
			/>
		</div>
	);
};
