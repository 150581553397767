/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { FormGenericInput } from 'components/globals/inputs/FormGenericInput';
import { DEFAULT_NO_ERROR, requiredNumber, requiredYear } from 'helpers/Validators/Validators';
import lodash from 'lodash';

export interface IProvisionOrder {
	number: string | undefined;
	year: string | undefined;
	hasErrors: boolean;
}

export interface IOverviewProvisionOrderForm {
	number?: string | undefined;
	year?: string | undefined;
	title?: string;
	handleChange: (data: IProvisionOrder) => void;
}

export interface IErrorsProvisionOrderForm {
	number?: string | undefined;
	year?: string | undefined;
}

export const LABELS_PROVISION_ORDER_FORM = {
	title: 'Orden de Provisión',
	number: 'Número',
	year: 'Año'
};

export const ProvisionOrderForm = ({
	number,
	year,
	title = LABELS_PROVISION_ORDER_FORM.title,
	handleChange
}: IOverviewProvisionOrderForm) => {
	const [orderNumber, setOrderNumber] = useState<string | undefined>();
	const [orderYear, setOrderYear] = useState<string | undefined>();
	const [dataFormHasChnaged, setDataFormHasChnaged] = useState<number>(0);
	const [errors, setErrors] = useState<IErrorsProvisionOrderForm | undefined>({} as IErrorsProvisionOrderForm);

	useEffect(() => {
		setOrderNumber(number);
	}, [number]);

	useEffect(() => {
		setOrderYear(year);
	}, [year]);

	const validateForm = (): boolean => {
		const newErrors: IErrorsProvisionOrderForm = {} as IErrorsProvisionOrderForm;
		setErrors(newErrors);

		if (orderNumber !== undefined || orderYear !== undefined) {
			const instrumentNumberError = requiredNumber(
				{ value: orderNumber, label: LABELS_PROVISION_ORDER_FORM.number },
				true,
				true
			);
			if (instrumentNumberError !== DEFAULT_NO_ERROR) {
				newErrors.number = instrumentNumberError;
			}
		}

		if (orderYear !== undefined || orderNumber !== undefined) {
			const instrumentYearError = requiredYear({ value: orderYear, label: LABELS_PROVISION_ORDER_FORM.year });
			if (instrumentYearError !== DEFAULT_NO_ERROR) {
				newErrors.year = instrumentYearError;
			}
		}

		setErrors(newErrors);
		if (Object.entries(newErrors).length !== 0) {
			return false;
		}

		return true;
	};

	const getParsedData = (hasErrors = false): IProvisionOrder => {
		return {
			number: orderNumber,
			year: orderYear,
			hasErrors: hasErrors
		} as IProvisionOrder;
	};

	const debounceNumber = lodash.debounce((e) => {
		setOrderNumber(e == '' ? undefined : e);
		setDataFormHasChnaged(dataFormHasChnaged + 1);
	}, 300);

	const debounceYear = lodash.debounce((e) => {
		setOrderYear(e == '' ? undefined : e);
		setDataFormHasChnaged(dataFormHasChnaged + 1);
	}, 300);

	useEffect(() => {
		if (dataFormHasChnaged) {
			handleChange(getParsedData());
		}
	}, [dataFormHasChnaged]);

	return (
		<Grid
			container
			spacing={2}>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}>
				<Typography variant="subtitle2">{title}</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}>
				<FormGenericInput
					value={orderNumber}
					label={LABELS_PROVISION_ORDER_FORM.number}
					name="orderNumber"
					id="orderNumber"
					type="number"
					handleChange={debounceNumber}
					error={errors?.number}
					size={'medium'}
					required={false}
					maxLength={5}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}>
				<FormGenericInput
					value={orderYear}
					label={LABELS_PROVISION_ORDER_FORM.year}
					name="orderYear"
					id="orderYear"
					type="number"
					handleChange={debounceYear}
					error={errors?.year}
					size={'medium'}
					required={false}
					maxLength={4}
				/>
			</Grid>
		</Grid>
	);
};
