import { Divider, Grid, Typography } from '@mui/material';

// eslint-disable-next-line react/prop-types
export const FieldLabel = ({ label = 'Label' }) => {
	return (
		<Grid
			item
			xs={12}
			sm={12}
			md={12}
			lg={12}
			xl={12}>
			<Grid
				container
				spacing={2}>
				{label !== undefined && label !== null ? (
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}>
						<Typography
							fontSize={16}
							variant="h6"
							sx={{ textAlign: 'start' }}>
							{label}
						</Typography>
					</Grid>
				) : null}
				<Divider className="customDivider" />
			</Grid>
		</Grid>
	);
};
