import { useState, useEffect } from 'react';

// Hooks
import { useMovContext } from 'hooks/useMovContext';

// Components
import { Generic } from './generico/Generic';
import { Vehiculo } from './vehiculo/Vehiculo';
import { Inmueble } from './inmueble/Inmueble';

// Interfaces
import { IPaso3Props } from './interfaces';

import { SelectChangeEvent, Box, Typography, InputLabel, Select, MenuItem, FormControl, Button } from '@mui/material';
import { Paso3Table } from './tableStep3/Paso3Table';
import { formatDataForTable } from './utils';
import { MovementStatusType } from 'enums';

const rightTypeArr = ['Genérico', 'Vehículo', 'Inmueble'];

export const ItemsEdit = ({ setPaso3 }: IPaso3Props) => {
	const [rightType, setRightType] = useState('Genérico');
	const [rightList, setRightList] = useState<any[]>([]);
	const [btnAdd, setBtnAdd] = useState(true);
	const movContext = useMovContext();

	// Load the mapped items in the table
	useEffect(() => {
		setRightList([]);
		if (movContext?.editMovement) {
			const items = formatDataForTable(movContext);
			if (!items) return;
			setRightList(items);
		}
	}, [movContext?.editMovement]);

	const handlerightType = (evt: SelectChangeEvent) => {
		setRightType(evt.target.value as string);
	};

	useEffect(() => {
		if (rightList.length > 0) {
			setPaso3({ data: [...rightList], valid: true });
		} else {
			setPaso3({ data: [], valid: false });
		}
	}, []);

	useEffect(() => {
		if (rightList.length > 0) {
			setPaso3({ data: [...rightList], valid: true });
			setBtnAdd(true);
		} else {
			setPaso3({ data: [], valid: false });
			setBtnAdd(true);
		}
	}, [rightList]);

	return (
		<>
			<Box sx={{ padding: '0px 5%', mb: 4, mt: 2, minHeight: '300px' }}>
				{movContext?.editMovement?.movement_status_id === MovementStatusType.PENDIENTE ? (
					<Box>
						{!btnAdd && (
							<>
								<Box>
									<Typography sx={{ fontWeight: '500' }}>Completa los siguientes campos.</Typography>
								</Box>
								<Box
									component={'form'}
									sx={{ display: 'flex', pt: 2 }}>
									<Box sx={{ display: 'flex', flexDirection: 'column' }}>
										<FormControl sx={{ width: '300px', mr: 2 }}>
											<Box>
												<InputLabel htmlFor="tipoBien">Tipo de Bien</InputLabel>
												<Select
													sx={{ width: '220px', position: 'absolute' }}
													size="small"
													id="tipoBien"
													name="tipoBien"
													label="Tipo de Bien"
													value={rightType}
													onChange={handlerightType}>
													{rightTypeArr.map((data: string, index: number) => (
														<MenuItem
															key={index}
															value={data}>
															{data}
														</MenuItem>
													))}
												</Select>
											</Box>
										</FormControl>
									</Box>
								</Box>
								<Box sx={{ pt: 2 }}>
									{rightType === 'Genérico' && (
										<Generic
											rightList={rightList}
											setRightList={setRightList}
										/>
									)}
									{rightType === 'Vehículo' && (
										<Vehiculo
											rightList={rightList}
											setRightList={setRightList}
										/>
									)}
									{rightType === 'Inmueble' && (
										<Inmueble
											rightList={rightList}
											setRightList={setRightList}
										/>
									)}
								</Box>
							</>
						)}
						{btnAdd && (
							<Button
								sx={{ mt: 2, mb: 2 }}
								variant="contained"
								onClick={() => setBtnAdd(false)}>
								Agregar Bien
							</Button>
						)}
					</Box>
				) : null}
				<Paso3Table
					rightList={rightList}
					setRightList={setRightList}
				/>
			</Box>
		</>
	);
};
