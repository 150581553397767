/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { FormGenericInput } from 'components/globals/inputs/FormGenericInput';
import lodash from 'lodash';
import { MAX_NUMBER_VALUE, isValidNumber, isValidYear } from 'components/bajas/forms/stepOne';
import { Select } from '../select/Select';
import { ISelectOption } from 'interfaces/commons/IParameters';
import { REGISTER_TYPES_ID } from 'enums/itemType/itemType';
import { is } from 'date-fns/locale';

export interface ILegalInstrument {
	number: string | undefined;
	year: string | undefined;
	type?: string;
	hasErrors: boolean;
}

export interface IOverviewLegalInstrumentForm {
	number?: string | undefined;
	year?: string | undefined;
	type?: string;
	title?: string;
	handleChange(data: ILegalInstrument): void;
	disabled?: boolean;
	required?: boolean;
	validateYear?: boolean;
	validateInstrumentNumber?: boolean;
	isAlta?: boolean;
	onError?(errors: IErrorsLegalInstrumentForm): void;
}
export interface IErrorsLegalInstrumentForm {
	number?: string | undefined;
	year?: string | undefined;
}

export const LABELS_LEGAL_INSTRUMENT_FORM = {
	instrument: 'Instrumento Legal',
	instrument_number: 'Número',
	instrument_year: 'Año'
};

const legalInstrumentTypeOptions: Array<ISelectOption> = [
	{ label: 'Seleccioná un tipo', id: 0, disabled: true },
	{ label: 'DECRETO', id: 1 },
	{ label: 'RESOLUCIÓN', id: 3 },
	{ label: 'DISPOSICIÓN', id: 4 },
	{ label: 'LEY', id: 2 }
];

export const LegalInstrumentForm = ({
	number,
	year,
	title = LABELS_LEGAL_INSTRUMENT_FORM.instrument,
	handleChange,
	disabled,
	required,
	validateYear,
	validateInstrumentNumber,
	onError,
	isAlta = true,
	type
}: IOverviewLegalInstrumentForm) => {
	const [instrumentNumber, setInstrumentNumber] = useState<string | undefined>();
	const [instrumentYear, setInstrumentYear] = useState<string | undefined>();
	const [instrumentType, setInstrumentType] = useState<ISelectOption>(legalInstrumentTypeOptions[0]);
	const [dataFormHasChnaged, setDataFormHasChnaged] = useState<number>(0);
	const stepOneData = JSON.parse(localStorage.getItem('STORAGED_REGISTER_STEP_ONE') || '{}');
	useEffect(() => {
		setInstrumentNumber(number);
	}, [number]);

	useEffect(() => {
		setInstrumentYear(year);
	}, [year]);

	const yearError = useMemo(() => {
		const year = Number(instrumentYear);
		const error = !isValidYear(year) && dataFormHasChnaged && validateYear ? ' ' : undefined;
		return error;
	}, [instrumentYear]);

	const instrumentNumberError = useMemo(() => {
		return validateInstrumentNumber && instrumentNumber && !isValidNumber(instrumentNumber, MAX_NUMBER_VALUE)
			? 'Ingrese un número de instrumento válido'
			: undefined;
	}, [instrumentNumber]);

	const getParsedData = (hasErrors = false): ILegalInstrument => {
		return {
			number: instrumentNumber,
			year: instrumentYear,
			type: instrumentType.id === 0 ? '' : instrumentType.label,
			hasErrors: hasErrors
		};
	};

	const debounceNumber = lodash.debounce((e) => {
		setInstrumentNumber(e == '' ? undefined : e);
		setDataFormHasChnaged(dataFormHasChnaged + 1);
	}, 300);

	const debounceYear = lodash.debounce((e) => {
		setInstrumentYear(e == '' ? undefined : e);
		setDataFormHasChnaged(dataFormHasChnaged + 1);
	}, 300);

	useEffect(() => {
		if (dataFormHasChnaged) {
			handleChange(getParsedData());
		}
	}, [dataFormHasChnaged]);

	useEffect(() => {
		onError?.({ year: yearError, number: instrumentNumberError });
	}, [yearError, instrumentNumberError]);

	useEffect(() => {
		setInstrumentType(legalInstrumentTypeOptions.find((opt) => opt.label === type) || legalInstrumentTypeOptions[0]);
	}, [type]);

	const isRequired = isAlta ? (stepOneData.high_type_id === REGISTER_TYPES_ID.ACQUISITION ? '' : '*') : '';

	return (
		<Grid
			container
			spacing={2}>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}>
				<Typography variant="subtitle2">{title}</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}>
				<Select
					label={`Tipo ${stepOneData.high_type_id === REGISTER_TYPES_ID.ACQUISITION ? '' : '*'}`}
					value={instrumentType}
					onChange={(val) => {
						setInstrumentType(val as ISelectOption);
						setDataFormHasChnaged((curr) => curr + 1);
					}}
					options={legalInstrumentTypeOptions}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}>
				<FormGenericInput
					disabled={disabled}
					value={instrumentNumber}
					label={`${LABELS_LEGAL_INSTRUMENT_FORM.instrument_number}${isRequired}`}
					name="instrumentNumber"
					id="instrumentNumber"
					type="number"
					handleChange={debounceNumber}
					error={instrumentNumberError}
					size={'medium'}
					required={required}
					maxLength={5}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}>
				<FormGenericInput
					disabled={disabled}
					value={instrumentYear}
					label={`${LABELS_LEGAL_INSTRUMENT_FORM.instrument_year}${isRequired}`}
					name="instrumentYear"
					id="instrumentYear"
					type="number"
					handleChange={debounceYear}
					error={yearError}
					size={'medium'}
					required={required}
					maxLength={4}
				/>
			</Grid>
		</Grid>
	);
};
