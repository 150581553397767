import { Dispatch, SetStateAction, useState } from 'react';
import { styleScrollBar } from './styleScrollbar';
import { Typography, Box, Modal, List, ListItem, Button } from '@mui/material';
import { IStep3Accessories } from '../interfaces';
import { IStep3VehicleValues, IStep3GenericValues } from '../interfaces';

const styleModal = {
	position: 'absolute' as const,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '33%',
	minWidth: '532px',
	maxHeight: '95%',
	overflowY: 'auto',
	bgcolor: '#383838',
	border: '2px solid #00000071',
	boxShadow: 24,
	color: 'white',
	wordBreak: 'break-all' as const
};

interface IModalAccessoriesProps {
	openDetails: boolean;
	setOpenDetails: Dispatch<SetStateAction<boolean>>;
	selectedRow: IStep3VehicleValues;
}

export const ModalCar = ({ openDetails, setOpenDetails, selectedRow }: IModalAccessoriesProps) => {
	const [open, setOpen] = useState(true);

	const handleClose = () => {
		setOpen(false);
		setOpenDetails(false);
	};

	let formattedDate = '';
	if (selectedRow.dateOfConstruction) {
		const dateOfContruction = new Date(selectedRow.dateOfConstruction);
		formattedDate = dateOfContruction.toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' });
	} else {
		formattedDate = 'No disponible';
	}

	return (
		<div>
			<Modal
				sx={styleScrollBar}
				keepMounted
				open={openDetails}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<>
					<Box sx={styleModal}>
						<Typography
							variant="h4"
							sx={{ textAlign: 'center', pb: 2, mb: 3, pt: 1, fontWeight: 500, width: '100%', backgroundColor: 'rgb(51, 82, 100)' }}>
							Detalles del Bien
						</Typography>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', ml: 4, mr: 4 }}>
							<Box>
								<Typography variant="h6">Tipo de Bien</Typography>
								<Typography>Vehículo</Typography>
								<Typography variant="h6">Tipo de Vehículo</Typography>
								<Typography>{selectedRow.vehicleType}</Typography>
								<Typography variant="h6">Código Nomenclador</Typography>
								<Typography>{selectedRow.nomenclatureCode}</Typography>
								<Typography variant="h6">Cantidad</Typography>
								<Typography>{selectedRow.quantity} unidades</Typography>
								<Typography variant="h6">Precio Unitario</Typography>
								<Typography>{Number(selectedRow?.unitPrice).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</Typography>
								<Typography variant="h6">Dominio/Matricula</Typography>
								<Typography>{selectedRow.domainRegistartion}</Typography>
								<Typography variant="h6">N° de Motor</Typography>
								<Typography>{selectedRow.engineNumber}</Typography>
								{selectedRow.vehicleType === 'Motovehículos' && (
									<>
										<Typography variant="h6">N° de Cuadro</Typography>
										<Typography>{selectedRow.bikeFrameNumber}</Typography>
									</>
								)}
								<Typography variant="h6">Marca</Typography>
								<Typography>{selectedRow.brand}</Typography>
								<Typography variant="h6">Modelo</Typography>
								<Typography>{selectedRow.model}</Typography>
							</Box>
							<Box>
								<Typography variant="h6">Año</Typography>
								<Typography>{selectedRow.year}</Typography>
								<Typography variant="h6">Origen/Procedencia</Typography>
								<Typography>{selectedRow.origin}</Typography>
								<Typography variant="h6">Titular</Typography>
								<Typography>{selectedRow.owner}</Typography>
								<Typography variant="h6">Tipo</Typography>
								<Typography>{selectedRow.type}</Typography>
								<Typography variant="h6">Título de propiedad</Typography>
								{selectedRow?.propertyTitle ? <Typography>Si</Typography> : <Typography>No</Typography>}
							</Box>
						</Box>
						{selectedRow?.accessories && selectedRow?.accessories?.length > 0 && (
							<Typography
								variant="h6"
								sx={{ ml: 4, mt: 2, textDecoration: 'underline' }}>
								Accesorios
							</Typography>
						)}
						<List sx={{ ml: 3, mr: 3, mt: 1, mb: 1, p: 4 }}>
							{selectedRow?.accessories &&
								selectedRow?.accessories.length > 0 &&
								selectedRow?.accessories.map((accessory: IStep3Accessories, index) => (
									<Box
										key={index}
										sx={{ p: 2, border: '3px solid #a0a0a0', borderRadius: '5px', mb: 1 }}>
										Accesorio N° {index + 1}
										<ListItem>
											<span>▪</span>
											<Typography sx={{ fontSize: '13px', ml: '5px' }}>Código Nomenclador= {accessory.nomenclatureCode}</Typography>
										</ListItem>
										<ListItem>
											<span>▪</span>
											<Typography sx={{ fontSize: '13px', ml: '5px' }}>Cantidad= {accessory.quantity}</Typography>
										</ListItem>
										<ListItem>
											<span>▪</span>
											<Typography sx={{ fontSize: '13px', ml: '5px' }}>
												Precio Unitario= {Number(accessory.unitPrice).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}
											</Typography>
										</ListItem>
										<ListItem sx={{ display: 'flex', alignItems: 'flex-start' }}>
											<span>▪</span>
											<Typography sx={{ fontSize: '13px', ml: '5px', whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>
												Descripción= {accessory.description}
											</Typography>
										</ListItem>
									</Box>
								))}
						</List>
						<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', pb: 5 }}>
							<Button
								variant="contained"
								color="primary"
								sx={{ width: '200px' }}
								onClick={handleClose}>
								Salir
							</Button>
						</Box>
					</Box>
				</>
			</Modal>
		</div>
	);
};
