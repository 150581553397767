import React, { ReactNode, useState } from 'react';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';

export interface IMessage {
	variant?: 'success' | 'info' | 'warning' | 'error';
	title?: string;
	message: string;
	aditionalMessage?: string;
	isDissmisable?: boolean;
	icon?: ReactNode;
}

export const Message = ({ variant = 'warning', title, message, aditionalMessage, isDissmisable = false, icon }: IMessage) => {
	const [show, setShow] = useState(true);

	return (
		<Box
			sx={{ width: '100%' }}
			mb={1}
			p={1}
			mt={1}>
			{isDissmisable ? (
				<>
					{show ? (
						<Alert
							severity={variant}
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => {
										setShow(false);
									}}>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}>
							{title ? <AlertTitle>{title}</AlertTitle> : null}

							{message}
							{aditionalMessage ? (
								<>
									<hr />
									<p className="mb-0">{aditionalMessage}</p>
								</>
							) : null}
							{icon}
						</Alert>
					) : (
						<Button onClick={() => setShow(true)}>Mostrar más</Button>
					)}
				</>
			) : (
				<Alert severity={variant}>
					{title ? <AlertTitle>{title}</AlertTitle> : null}
					{message}
					{aditionalMessage ? (
						<>
							<hr />
							<p className="mb-0">{aditionalMessage}</p>
						</>
					) : null}
					{icon}
				</Alert>
			)}
		</Box>
	);
};
