import { ReactNode, useEffect, useState } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { DeregistrationContext } from './DeregistrationContext';
import { toast } from 'react-toastify';
import { useAppContext } from 'hooks/useAppContext';
import {
	IBaja,
	IBajaPropertyItem,
	IBajaResponse,
	IBajaStepOne,
	IBajaStepThree,
	IBajaStepTwo,
	IHeritageAsset,
	ITimelessProperty
} from 'interfaces/baja/IBaja';
import { IMeta } from 'interfaces/Pagination';
import { IPaginationRequest } from 'interfaces/commons/IParameters';
import {
	tryCreateOrUpdateDeregister,
	tryAddItem,
	createTimelessRegistration,
	tryRemoveItem,
	trySearchItem,
	tryGetData,
	tryFinalice,
	tryDelete,
	tryEditItem
} from 'services/bajaService';
import { deleteFile, getFiles } from 'services/fileManageService';

const LOCAL_STORAGE_STEP_ONE = 'STORAGED_DEREGISTER_STEP_ONE';
const LOCAL_STORAGE_STEP_TWO = 'STORAGED_DEREGISTER_STEP_TWO';
const LOCAL_STORAGE_STEP_THREE = 'STORAGED_DEREGISTER_STEP_THREE';
const STORAGED_DEREGISTER_ACTIVE_STEP = 'STORAGED_DEREGISTER_ACTIVE_STEP';

export const DeregistrationState = ({ children }: { children: ReactNode | ReactJSXElement }) => {
	const contextoX = useAppContext();

	const [dataStepOne, setDataStepOne] = useState<IBajaStepOne | undefined>(undefined);
	const [dataStepTwo, setDataStepTwo] = useState<IBajaStepTwo | undefined>(undefined);
	const [dataStepThree, setDataStepThree] = useState<IBajaStepThree | undefined>(undefined);

	useEffect(() => {
		const storagedDataStepOne = localStorage.getItem(LOCAL_STORAGE_STEP_ONE);
		const dataStepOneStoraged = storagedDataStepOne !== null ? JSON.parse(storagedDataStepOne) : null;
		if (dataStepOneStoraged !== null) {
			setDataStepOne(dataStepOneStoraged);
		}

		const storagedDataStepTwo = localStorage.getItem(LOCAL_STORAGE_STEP_TWO);
		const dataStepTwoStoraged = storagedDataStepTwo !== null ? JSON.parse(storagedDataStepTwo) : null;
		if (dataStepTwoStoraged !== null) {
			setDataStepTwo(dataStepTwoStoraged);
		}

		const storagedDataStepThree = localStorage.getItem(LOCAL_STORAGE_STEP_THREE);
		const dataStepThreeStoraged = storagedDataStepThree !== null ? JSON.parse(storagedDataStepThree) : null;
		if (dataStepThreeStoraged !== null) {
			setDataStepThree(dataStepThreeStoraged);
			let singleTaxId;
			const deregistration_type_id = [];

			for (let i = 0; i < dataStepThreeStoraged?.items?.length; i++) {
				const item = dataStepThreeStoraged?.items[i];
				if (item?.property?.property_registration && item?.property?.property_registration.single_tax_id) {
					singleTaxId = item?.property?.property_registration.single_tax_id;
					break;
				}
			}
			for (let i = 0; i < dataStepThreeStoraged?.items?.length; i++) {
				const item = dataStepThreeStoraged?.items[i];
				if (item?.deregistration_type_id && item?.deregistration_type?.id) {
					deregistration_type_id.push(item?.deregistration_type?.id);
				}
			}

			const newSteponedata = {
				...dataStepOneStoraged,
				single_tax_id: singleTaxId,
				deregistration_type_id: deregistration_type_id
			};
			setDataStepOne(newSteponedata);
		}

		const storagedActiveStep = localStorage.getItem(STORAGED_DEREGISTER_ACTIVE_STEP);
		const activeStepStoraged = storagedActiveStep !== null ? parseInt(storagedActiveStep) : 0;
		setActiveStep(activeStepStoraged);
	}, []);

	const parseResponseDataStepOne = (data: IBajaResponse): IBajaStepOne => {
		const newInstrument = {
			...data.legal_instrument,
			instrument_type: data?.instrument_type
		};
		return {
			id: data.id,
			procedure: data.proceedings_number,
			instrument: newInstrument,
			date_legal_instrument: data.date_legal_instrument,
			date_delivery_certificate: data.date_delivery_certificate,
			hasErrors: false
		} as IBajaStepOne;
	};

	const onHandleDataStepOne = async (data: IBajaStepOne | undefined) => {
		if (!data) {
			toast.error(
				'Para avanzar al siguiente paso debe indicar un resgitro de Baja existente o bien rellenar el formulario!'
			);
			return false;
		}
		contextoX?.onHandleShowSpinner();
		const response = await tryCreateOrUpdateDeregister({ ...data, id: dataStepOne?.id });
		contextoX?.onHandleHideSpinner();

		if (response && response?.error) {
			const message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'Ocurrio un error al intentar guardar los datos de la baja!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		const parsedData = data ? parseResponseDataStepOne(response) : undefined;

		changeDataStepOne(parsedData);
		if (data.id == undefined) {
			changeDataStepTwo(dataStepTwo ? { ...dataStepTwo, id: response.id } : ({ id: response.id } as IBajaStepTwo));
			changeDataStepThree(
				dataStepThree ? { ...dataStepThree, id: response.id } : ({ id: response.id } as IBajaStepThree)
			);
		}
		toast.success('Datos de la Baja en el paso 1 guardados.');
		changeActiveStep(activeStep + 1);
		return true;
	};

	const onHandleDataStepTwo = async (data: IBajaStepTwo | undefined) => {
		if (data && !data.hasErrors) {
			changeActiveStep(activeStep + 1);
		}
		return true;
	};

	const onHandleDataStepThree = async (data: IBajaStepThree | undefined) => {
		changeActiveStep(activeStep + 1);
		return true;
	};

	const parseResponseDataStepThree = (data: IBaja | undefined): IBajaStepThree | undefined => {
		if (!data) {
			return undefined;
		}
		return {
			id: data.id,
			items: data.property_deregistration_item
				? data.property_deregistration_item.map((el) => {
						if (!el.property) el.property = el.property_timeless;

						if (el.property_timeless && el.property) {
							el.property.item_inventory_number = el.property_timeless.inventory_number;
							el.property.nomenclator_code = el.property.account_nomenclature.account;
							el.property.discharge_date = el.property_timeless.date;
							el.property.registration_timeless_id = el.property_timeless.id;
						}

						return el;
				  })
				: [],
			timelessItems: [],
			hasErrors: false
		} as unknown as IBajaStepThree;
	};

	const onHandleGetDataStepThree = async (id: number | null) => {
		if (id == null) {
			return;
		}
		const response = await tryGetData(id);

		if (response && response?.error) {
			return false;
		}

		const parsedDataStepThree = parseResponseDataStepThree(response);
		changeDataStepThree(parsedDataStepThree);
		return response;
	};

	const onHandleGetDataStepOne = async (id: number | null) => {
		if (id == null) {
			toast.error('Para editar una baja patrimonial es necesario que indique una!');
			return false;
		}
		changeDataStepOne(undefined);
		changeDataStepTwo(undefined);
		changeDataStepThree(undefined);
		setActiveStep(0);
		contextoX?.onHandleShowSpinner();
		const response = await tryGetData(id);
		contextoX?.onHandleHideSpinner();

		if (response && response?.error) {
			const message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'Ocurrio un error al intentar recuperar los datos de la baja patrimonial!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		const parsedDataStepOne = parseResponseDataStepOne(response);
		changeDataStepOne(parsedDataStepOne);
		changeDataStepTwo({ id: response.id } as IBajaStepTwo);
		changeDataStepThree({ id: response.id } as IBajaStepThree);
		return true;
	};

	const changeDataStepOne = (data: IBajaStepOne | undefined) => {
		localStorage.removeItem(LOCAL_STORAGE_STEP_ONE);
		if (data) {
			localStorage.setItem(LOCAL_STORAGE_STEP_ONE, JSON.stringify(data));
		}
		setDataStepOne(data);
	};

	const changeDataStepTwo = (data: IBajaStepTwo | undefined) => {
		localStorage.removeItem(LOCAL_STORAGE_STEP_TWO);
		if (data) {
			localStorage.setItem(LOCAL_STORAGE_STEP_TWO, JSON.stringify(data));
		}
		setDataStepTwo(data);
		return true;
	};

	const changeDataStepThree = (data: IBajaStepThree | undefined) => {
		localStorage.removeItem(LOCAL_STORAGE_STEP_THREE);
		if (data) {
			localStorage.setItem(LOCAL_STORAGE_STEP_THREE, JSON.stringify(data));
		}
		setDataStepThree(data);
		if (data && data.items) {
			let singleTaxId;
			const deregistration_type_id = [];
			for (let i = 0; i < data?.items?.length; i++) {
				const item = data?.items[i];
				if (item?.property?.property_registration && item?.property?.property_registration.single_tax_id) {
					singleTaxId = item?.property?.property_registration.single_tax_id;
					break;
				}
			}
			for (let i = 0; i < data?.items?.length; i++) {
				const item = data?.items[i];
				if (item?.deregistration_type_id && item?.deregistration_type?.id) {
					deregistration_type_id.push(item?.deregistration_type?.id);
				}
			}

			const dataStepOneStoraged = JSON.parse(localStorage.getItem(LOCAL_STORAGE_STEP_ONE) as string);
			const newSteponedata = {
				...dataStepOneStoraged,
				single_tax_id: singleTaxId,
				deregistration_type_id: deregistration_type_id
			};
			setDataStepOne(newSteponedata);
		}
		return true;
	};

	const changeActiveStep = (index: number) => {
		localStorage.removeItem(STORAGED_DEREGISTER_ACTIVE_STEP);
		localStorage.setItem(STORAGED_DEREGISTER_ACTIVE_STEP, index.toString());
		setActiveStep(index);
	};

	// START Search Item ummary
	const [searchedItem, setSearchedItem] = useState(undefined);
	const onHandleSearchItem = async (inventaryNumber: string, accessoryNumber: string | null) => {
		setSearchedItem(undefined);
		contextoX?.onHandleShowSpinner();
		const response = await trySearchItem(inventaryNumber, accessoryNumber);
		contextoX?.onHandleHideSpinner();

		if (response?.error) {
			const message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'No se ha encontrado el bien buscado!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		setSearchedItem(response);
		toast.success('Bien encontrado!');
		return true;
	};
	// END Search Item ummary

	// START Add Item to Properties
	const onHandleAddItem = async (id: number | null, item: IBajaPropertyItem | null) => {
		if (id == null) {
			toast.error('Para agregar un Bien debe indicar un registro de baja existente!');
			return false;
		}
		if (item == null) {
			toast.error('Para agregar un Bien debe completar los campos del formulario!');
			return false;
		}
		contextoX?.onHandleShowSpinner();
		const response = await tryAddItem(id, item);

		if (response?.error) {
			contextoX?.onHandleHideSpinner();
			const message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'No se ha podido añadir el bien a la lista, inténtalo más tarde!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		setSearchedItem(undefined);
		const result = await onHandleGetDataStepThree(id);
		contextoX?.onHandleHideSpinner();
		toast.success('Bien Patrimonial agregado!');
		return result;
	};
	// END Add Item to Properties

	// START Edit Item to Properties
	const onHandleEditItem = async (id: number | null, item: IBajaPropertyItem | null) => {
		if (id == null) {
			toast.error('Para editar un Bien debe indicar un registro de baja existente!');
			return false;
		}
		if (item == null) {
			toast.error('Para editar un Bien debe completar los campos del formulario!');
			return false;
		}
		contextoX?.onHandleShowSpinner();
		const response = await tryEditItem(item);

		if (response?.error) {
			contextoX?.onHandleHideSpinner();
			const message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'No se ha podido editar el bien de la lista, inténtalo más tarde!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		setSearchedItem(undefined);
		const result = await onHandleGetDataStepThree(id);
		contextoX?.onHandleHideSpinner();
		toast.success('Bien Patrimonial editado!');
		return result;
	};
	// END Edit Item to Properties

	// START Remove Item to Properties
	const onHandleRemoveItem = async (id: number | null) => {
		if (id === null) {
			toast.error('Debe indicar un Bien Patrimonial para removerlo!');
			return false;
		}
		contextoX?.onHandleShowSpinner();
		const response = await tryRemoveItem(id);

		if (response?.error) {
			contextoX?.onHandleHideSpinner();
			const message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'No se ha podido remover el bien de la lista, inténtalo más tarde!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		const result = await onHandleGetDataStepThree(dataStepThree?.id ? dataStepThree?.id : 0);
		contextoX?.onHandleHideSpinner();
		toast.success('Bien Patrimonial removido!');
		return result;
	};
	// END Remove Item to Properties

	// START Timeless Item
	const onHandleAddTimelessItem = async (id?: number, item?: ITimelessProperty) => {
		if (!id) {
			toast.error('Para agregar un Item Atemporal debe indicar un registro de baja existente!');
			return false;
		}

		if (!item) {
			toast.error('Para agregar un Item Atemporal debe completar los campos del formulario!');
			return false;
		}
		try {
			contextoX?.onHandleShowSpinner();

			const response = await createTimelessRegistration(item);

			toast.success('Alta temporal registrada!');

			return response;
		} catch (e: any) {
			if (e.response.data.statusCode === 400 && Array.isArray(e.response.data.message))
				toast.error(e.response.data.message[0]);
			toast.error(e.response.data.message);
		} finally {
			contextoX?.onHandleHideSpinner();
		}
	};
	// END Timeless Item

	// START Heritage Assets
	const [allHeritageAssets, setAllHeritageAssets] = useState<IHeritageAsset[]>([] as IHeritageAsset[]);
	const [heritageAssets, setHeritageAssets] = useState<IHeritageAsset[]>([] as IHeritageAsset[]);
	const [heritageAssetsMeta, setHeritageAssetsMeta] = useState<IMeta>({ totalPages: 1 } as IMeta);

	const onHandleTryGetAllHeritageAssets = async (id: number, { page = 1, limit = 10 }: IPaginationRequest) => {
		if (id == null) {
			toast.error('Para recuperar los bienes asociados debe indicar un registro de baja existente!');
			return false;
		}
		const allEntities: IHeritageAsset[] = [];
		setAllHeritageAssets([]);
		setHeritageAssets([]);

		const desde = (page - 1) * limit;
		const hasta = desde + limit;
		setHeritageAssets(allEntities.slice(desde, hasta));
		setAllHeritageAssets(allEntities);
		const pages = allEntities.length > 0 ? parseFloat((allEntities.length / limit).toString()) : 0;
		const totalPages = parseInt(pages.toString());
		setHeritageAssetsMeta({
			currentPage: page,
			itemCount: allEntities.length ? allEntities.length : 0,
			totalItems: allEntities.length ? allEntities.length : 0,
			itemsPerPage: limit,
			totalPages: pages - totalPages > 0 ? totalPages + 1 : totalPages
		});

		toast.success('Bienes Patrimoniales recuperados!');
		return true;
	};

	const onHandleChangePageHeritageAssets = async ({
		page = 1,
		limit = heritageAssetsMeta.itemsPerPage
	}: IPaginationRequest) => {
		const desde = (page - 1) * limit;
		const hasta = desde + limit;
		const newCollection = allHeritageAssets ? allHeritageAssets.slice(desde, hasta) : [];
		setHeritageAssets(newCollection);
		const pages = parseFloat((allHeritageAssets.length / limit).toString());
		const totalPages = parseInt(pages.toString());
		setHeritageAssetsMeta({
			currentPage: page,
			itemCount: heritageAssetsMeta.totalItems,
			totalItems: heritageAssetsMeta.totalItems,
			itemsPerPage: limit,
			totalPages: pages - totalPages > 0 ? totalPages + 1 : totalPages
		});
	};
	// END Heritage Assets

	// START Handle Steps
	const [activeStep, setActiveStep] = useState<number>(0);
	// END Handle Steps

	// START Handle Files
	const [isFilesFetching, setIsFilesFetching] = useState<boolean>(false);
	const onHandleGetFilesStepTwo = async (id: number | null) => {
		if (id == null) {
			toast.error('Para recuperar los documentos adjuntos asociados debe indicar un registro de baja existente!');
			return false;
		}
		const newData: IBajaStepTwo = {
			...dataStepTwo,
			files: []
		};
		changeDataStepTwo(newData);

		setIsFilesFetching(true);
		contextoX?.onHandleShowSpinner();
		const response = await getFiles(`property-deregistration-files/${id}`);
		contextoX?.onHandleHideSpinner();
		setIsFilesFetching(false);

		if (response && response?.error) {
			console.error(response.error);
			return false;
		}

		if (Array.isArray(response)) {
			const newData: IBajaStepTwo = {
				...dataStepTwo,
				files: response.map((f) => {
					return {
						...f,
						name: f?.name ? f.name : '',
						size: f?.size ? f.size : 0
					} as File;
				})
			};
			changeDataStepTwo(newData);
		}
		return true;
	};

	const onHandleDeleteFile = async (file: any | null) => {
		if (!file) {
			toast.error('Para eliminar un documento adjunto debe indicar uno!');
			return false;
		}
		contextoX?.onHandleShowSpinner();
		const response = await deleteFile(`property-deregistration-files/${file?.id}`);

		if (response?.error) {
			const message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'No se ha podido eliminar el documento adjunto!';
			contextoX?.onHandleHideSpinner();
			toast.error(message);
			console.error(response.error);
			return false;
		}

		await onHandleGetFilesStepTwo(dataStepTwo?.id ? dataStepTwo?.id : null);
		contextoX?.onHandleHideSpinner();
		toast.success('Documento Adjunto eliminado!');
		return response;
	};

	const onHandleDeleteAllFiles = async (files: any[] | []) => {
		/* contextoX?.onHandleShowSpinner();
		const response = await tryAddItem(deregistrationNumber, itemNumber);
		contextoX?.onHandleHideSpinner();

		if (response?.error) {
			let message = response.messages
				? 'Respuesta del servidor: '.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages).concat('!')
				: 'No se ha podido añadir el elemento a la lista, inténtalo más tarde!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		setSearchedItem(undefined);
		toast.success('Bien Patrimonial agregado!');
		return response; */
	};
	// END Handle Files

	// START Finalice
	const onHandleFinalice = async (id: number | null) => {
		if (id === null) {
			toast.error('Para poder finalizar el proceso debe indicar un registro de baja existente!');
			return false;
		}
		contextoX?.onHandleShowSpinner();
		const response = await tryFinalice(id);
		contextoX?.onHandleHideSpinner();

		if (response?.error) {
			const message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'No se ha podido finalizar el proceso, inténtalo más tarde!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		toast.success('Guardado correctamente. A la espera de confirmación por el Encargado Patrimonial.');
		/* changeDataStepOne(undefined);
		changeDataStepTwo(undefined);
		changeDataStepThree(undefined);
		changeActiveStep(0); */
		return true;
	};
	// END Finalice

	// START Delete Deregistration Movement
	const onHandleDelete = async (id: number | null) => {
		if (id === null) {
			toast.error('Para poder eliminar un movimiento debe indicar un registro existente!');
			return false;
		}
		contextoX?.onHandleShowSpinner();
		const response = await tryDelete(id);
		contextoX?.onHandleHideSpinner();

		if (response?.error) {
			const message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'No se ha podido eliminar la baja patrimonial, inténtalo más tarde!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		toast.success('Baja patrimonial eliminada exitosamente.');
		return true;
	};
	// END Delete Deregistration Movement

	return (
		<DeregistrationContext.Provider
			value={{
				onHandleDataStepOne,
				dataStepOne,
				onHandleDataStepTwo,
				dataStepTwo,
				onHandleDataStepThree,
				dataStepThree,
				onHandleSearchItem,
				searchedItem,
				onHandleAddItem,
				onHandleEditItem,
				onHandleRemoveItem,
				onHandleAddTimelessItem,
				onHandleTryGetAllHeritageAssets,
				onHandleChangePageHeritageAssets,
				heritageAssets,
				heritageAssetsMeta,
				activeStep,
				setActiveStep,
				setDataStepOne,
				setDataStepTwo,
				setDataStepThree,
				onHandleGetFilesStepTwo,
				isFilesFetching,
				changeDataStepOne,
				changeDataStepTwo,
				changeDataStepThree,
				changeActiveStep,
				onHandleDeleteFile,
				onHandleDeleteAllFiles,
				onHandleGetDataStepThree,
				onHandleFinalice,
				onHandleGetDataStepOne,
				onHandleDelete,
				setHeritageAssetsMeta
			}}>
			{children}
		</DeregistrationContext.Provider>
	);
};
