import { Dispatch, SetStateAction, useEffect, useState, memo, useRef } from 'react';
import debounce from 'debounce-promise';
import { customStyles } from './customStyles';

//Select
import AsyncSelect from 'react-select/async';

// Services
import { getNomenclatureCode } from 'services/crudService';

// Material UI
import { Box, Typography } from '@mui/material';
import { usePromiseTracker } from 'react-promise-tracker';

interface INomenclature {
	value?: string;
	setNomenclatureCode: Dispatch<SetStateAction<string>>;
	setNomenclatureCodeId: Dispatch<SetStateAction<number>>;
	submited: boolean;
}

interface ISelectState {
	readonly isClearable: boolean;
	readonly isDisabled: boolean;
	readonly isLoading: boolean;
	readonly isRtl: boolean;
	readonly isSearchable: boolean;
}
interface ISelectItems {
	id: number;
	value: string;
	label?: string;
}

const NomenclatureCode = memo(({ value, submited, setNomenclatureCode, setNomenclatureCodeId }: INomenclature) => {
	const [selectState] = useState<ISelectState>({
		isClearable: true,
		isDisabled: false,
		isLoading: false,
		isRtl: false,
		isSearchable: true
	});
	const [nomenclatureCode, setNomenclatureCodeChild] = useState<ISelectItems | null>(null);
	const selectRef = useRef<any>(null);
	const { promiseInProgress } = usePromiseTracker({ area: 'getNomenclatureCodeTracker' });

	useEffect(() => {
		if (submited) {
			setNomenclatureCode('');
			setNomenclatureCodeId(0);
			setNomenclatureCodeChild({ value: '', label: '', id: 0 });
			if (selectRef.current) {
				selectRef.current.clearValue();
			}
		}
	}, [submited]);

	useEffect(() => {
		if (nomenclatureCode?.value) {
			setNomenclatureCode(nomenclatureCode.value);
			setNomenclatureCodeId(nomenclatureCode.id);
		} else {
			setNomenclatureCode('');
			setNomenclatureCodeId(0);
		}
	}, [nomenclatureCode]);

	useEffect(() => {
		if (!value) return;
		if (value && value?.trim().length > 0) {
			getNomenclatureCode(value).then((res) => {
				if (res.data[0]) {
					let codigo = '';
					if (res.data[0].sub_group !== null) {
						codigo = `${res.data[0].sub_group}`;
					}
					if (res.data[0].account !== null) {
						codigo = `${res.data[0].account}`;
					}
					if (res.data[0].sub_account !== null) {
						codigo = `${res.data[0].sub_account}`;
					}
					const selectedItem = {
						value: codigo,
						label: `${codigo} - ${res.data[0].group} | ${res.data[0].sub_group_description} | ${
							res.data[0].description !== null ? res.data[0].description : ''
						} | ${res.data[0].sub_description !== null ? res.data[0].sub_description : ''}`
					};
					selectRef.current.setValue(selectedItem);
				}
			});
		}
	}, [value]);

	/**
	 * Handle nomenclature code call to service and set new state.
	 * @returns a new state for nomenclatureCode
	 */
	const handleNomenclatureCodeSearch = debounce(async (inputValue): Promise<ISelectItems[]> => {
		return getNomenclatureCode(inputValue)
			.then((res) => {
				const arrData: ISelectItems[] = [];
				if (res.data?.length >= 0) {
					res.data.map((item: any) => {
						let codigo = '';
						if (item?.sub_group !== null) {
							codigo = `${item?.sub_group}`;
						}
						if (item?.account !== null) {
							codigo = `${item?.account}`;
						}
						if (item?.sub_account !== null) {
							codigo = `${item?.sub_account}`;
						}
						arrData.push({
							id: item.id,
							value: `${codigo}`,
							label: `${codigo} - ${item.group} | ${item.sub_group_description} | ${item.description !== null ? item.description : ''} | ${
								item.sub_description !== null ? item.sub_description : ''
							}`
						});
					});
					return arrData;
				}
				return [{ id: 0, value: '', label: 'No existen coincidencias...' }];
			})
			.catch((err) => {
				return [{ id: 0, value: '', label: 'No existen coincidencias...' }];
			});
	}, 500);

	const handleDescriptionNC = () => {
		const description = nomenclatureCode?.label?.split('|');
		return (
			<Box sx={{ display: 'flex', width: '400px', rowGap: '5px', flexDirection: 'column', flexWrap: 'wrap', ml: 1 }}>
				{description?.map((item, index) => (
					<Typography
						key={index}
						variant="body2"
						color={index % 2 !== 0 ? 'primary' : 'secondary'}
						fontWeight="500"
						sx={{ mb: 1, whiteSpace: 'wrap', wordBreak: 'break-word' }}>
						{item}
					</Typography>
				))}
			</Box>
		);
	};

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					marginBottom: '20px',
					marginTop: '15px',
					flexWrap: 'wrap'
				}}>
				<div style={{ marginRight: '30px' }}>
					<AsyncSelect
						cacheOptions
						ref={selectRef}
						onChange={(select) => setNomenclatureCodeChild(select as ISelectItems)}
						styles={customStyles}
						isClearable={selectState.isClearable}
						name="nomenclatureCodeSelect"
						loadOptions={handleNomenclatureCodeSearch}
						loadingMessage={() => 'Cargando...'}
						placeholder="Ingrese código nomenclador  *"
						noOptionsMessage={() => 'No existen coincidencias...'}
						isLoading={promiseInProgress}
					/>
				</div>
				<div>{handleDescriptionNC()}</div>
			</div>
		</>
	);
});

NomenclatureCode.displayName = 'NomenclatureCode';

export { NomenclatureCode };
