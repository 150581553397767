import { ColumnDef } from '@tanstack/react-table';
import { Button, IconButton, Box, Typography } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ClearIcon from '@mui/icons-material/Clear';
import AddCardIcon from '@mui/icons-material/AddCard';
import Edit from '@mui/icons-material/Edit';
import BorderColorIcon from '@mui/icons-material/BorderColor';

export const defaultColumnsPaso3Table = () => {
	const columns: ColumnDef<any>[] = [
		{
			accessorKey: 'vehicleType',
			header: () => <span>Tipo de Bien</span>,
			cell: ({ row }) => (
				<Typography>
					{row.original.rightType === 'Genérico' || row.original.rightType === 'Inmueble'
						? row.original.rightType
						: `${row.original.rightType} - ${row.original.vehicleType}`}
				</Typography>
			)
		},
		{
			accessorKey: 'nomenclatureCode',
			header: () => <span>Código Nomenclador</span>,
			cell: ({ getValue }) => <Typography>{getValue() as string}</Typography>
		},
		{
			accessorKey: 'description',
			header: () => <span>Descripción</span>,
			cell: ({ row }) => {
				return (
					<Button
						variant="outlined"
						disabled={row.original.rightType !== 'Genérico'}
						onClick={() => {
							row.toggleExpanded();
						}}>
						Ver Detalles
					</Button>
				);
			}
		},
		{
			accessorKey: 'quantity',
			header: () => <span>Cantidad</span>,
			cell: ({ getValue }) => <Typography>{getValue() as string}</Typography>
		},
		{
			accessorKey: 'unitPrice',
			header: () => <span>Precio Unitario</span>,
			cell: ({ row }) => (
				<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'center' }}>
					<Typography sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', flexWrap: 'nowrap' }}>
						{Number(row.original.unitPrice).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}
					</Typography>
				</Box>
			)
		},
		{
			accessorKey: 'Accesorios',
			enableSorting: false,
			header: () => <span>Accesorios</span>,
			columns: [
				{
					accessorKey: 'accessoriesAdd',
					enableHiding: true,
					header: () => <span>Agregar</span>,
					cell: ({ row: { original } }) => (
						<div className="action-btn">
							<IconButton id="addAccessory">
								<AddCardIcon
									color="primary"
									sx={{ zIndex: '-10' }}
								/>
							</IconButton>
						</div>
					)
				},
				{
					accessorKey: 'accessoriesEdit',
					enableHiding: true,
					header: () => <span>Editar Accesorio</span>,
					cell: ({ row: { original } }) => (
						<div className="action-btn">
							<IconButton id="editAccessory">
								<BorderColorIcon
									color="primary"
									sx={{ zIndex: '-10' }}
								/>
							</IconButton>
						</div>
					)
				}
			]
		},
		{
			accessorKey: 'Ver',
			enableSorting: false,
			header: () => <span>Ver Detalles</span>,
			cell: () => (
				<div className="action-btn">
					<IconButton size="large">
						<RemoveRedEyeIcon
							color="primary"
							sx={{ zIndex: '-10' }}
						/>
					</IconButton>
				</div>
			)
		},
		{
			accessorKey: 'Editar',
			enableSorting: false,
			header: () => <span>Editar</span>,
			cell: () => (
				<div className="action-btn">
					<IconButton
						size="large"
						id="editItem">
						<Edit
							color="primary"
							sx={{ zIndex: '-10' }}
						/>
					</IconButton>
				</div>
			)
		},
		{
			accessorKey: 'Borrar',
			enableSorting: false,
			enableHiding: true,
			header: () => <span>Eliminar</span>,
			cell: ({ row, table }) => (
				<div className="action-btn">
					<IconButton
						id="iconButton"
						size="large">
						<ClearIcon
							id="iconButton2"
							color={'error'}
							sx={{ zIndex: '-10' }}
						/>
					</IconButton>
				</div>
			)
		}
	];

	return columns;
};
