import { ChangeEvent, FC, useState, useRef, useEffect, useMemo } from 'react';

// Interfaces
import { IFechasValues, ITipoAltaFechasProps } from 'components/EncPatrimonial/altaStepper/paso1/interfaces';

// Enums
import { FileType, MovementStatusType } from 'enums';

// Utils
import { fileValidate } from 'components/EncPatrimonial/altaStepper/paso1/validate';

// Services
import { getLastPropertyRegistered } from 'services';

//Material UI
import {
	InputAdornment,
	Button,
	OutlinedInput,
	FormControl,
	Box,
	InputLabel,
	FormHelperText,
	TextField,
	Tooltip,
	Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';

//Components
import { validateFechas } from 'components/EncPatrimonial/altaStepper/paso1/validate';

//Context
import { useMovContext } from 'hooks/useMovContext';
import { IRecepAndInstMovProvider } from 'interfaces/movements';

export const FechaFactRemitoEdit: FC<ITipoAltaFechasProps> = ({ tipoAlta, setPaso1Fechas }) => {
	const movContext = useMovContext();
	const [fecha, setFecha] = useState<string>(movContext?.editMovement?.property_registration?.date_voucher || '');
	const [fechaInstLegal, setFechaInstLegal] = useState<string>(
		movContext?.editMovement?.property_registration?.date_legal_instrument || ''
	);
	const [dateLastPropertyRegistered, setDateLastPropertyRegistered] = useState<string>('');
	const [observacionFile, setObservacionFile] = useState<File>({} as File);
	const [observacion, setObservacion] = useState<string>(movContext?.editMovement?.property_registration?.description || '');
	const [errorFile, setErrorFile] = useState<string | null>(null);
	const [dateToolTip, setDateToolTip] = useState<string | null>(null);
	const [isSameMonth, setIsSameMonth] = useState<boolean>(true);
	const [isErrorCorrelative, setIsErrorCorrelative] = useState<boolean>(false);

	const fileInputRef = useRef<HTMLInputElement>(null);
	const dateInputRef = useRef<HTMLInputElement>(null);
	const dateInputRefInstLegal = useRef<HTMLInputElement>(null);

	const memoizedDate = useMemo(() => {
		setDateToolTip(null);
		getLastPropertyRegistered()
			.then((res) => {
				if (res.data.movement_date) {
					setDateLastPropertyRegistered(res.data.movement_date);
					const splitDate = res.data.movement_date.split('-');
					const date = new Date(parseInt(splitDate[0]), parseInt(splitDate[1]) - 1, parseInt(splitDate[2]));
					setDateToolTip(date.toLocaleDateString());
				}
			})
			.catch((err) => {
				if (err.response.status === 404) {
					const anyDate = new Date();
					anyDate.setDate(0);
					const formatedDate = anyDate.toLocaleDateString().split('/').reverse().join('-');
					setDateLastPropertyRegistered(formatedDate);
				}
			});
	}, []);

	useEffect(() => {
		memoizedDate;
	}, []);

	/**
	 * @description If the date is valid, it will be delete the attachment justification, otherwise it will be set the attachment justification.
	 */
	useEffect(() => {
		const fileFromBE = movContext?.editMovement?.property_registration?.files?.find(
			(file: any) => file?.file_type_id === FileType.Resolución_o_Disposición
		);
		if (fileFromBE && !isErrorCorrelative && isSameMonth) {
			const deleteFile = {
				id: movContext?.editMovement?.property_registration?.id,
				idProperty: 0,
				name: fileFromBE?.name,
				operation: 'delete' as const,
				key: 'timeless_justification' as const
			};
			movContext?.setRecepAndInstAttachs((prev) => [...prev, deleteFile]);
		}
		if (fileFromBE && (isErrorCorrelative || !isSameMonth)) {
			setObservacionFile(fileFromBE);
			const filterMovs = movContext?.recepAndInstAttachs?.filter((item) => item.key !== 'timeless_justification');
			if (filterMovs) {
				movContext?.setRecepAndInstAttachs(filterMovs);
			}
		}
	}, [isErrorCorrelative, isSameMonth, fecha]);

	/**
	 * @description check if the attachment justification has been changed.
	 */
	useEffect(() => {
		const fileFromBE = movContext?.editMovement?.property_registration?.files?.find(
			(file: any) => file?.file_type_id === FileType.Resolución_o_Disposición
		);

		if (observacionFile && observacionFile?.name) {
			const newFiles = movContext?.recepAndInstAttachs;
			if (fileFromBE) {
				if (fileFromBE?.name && observacionFile?.size > 0) {
					if (newFiles && newFiles?.length === 0 && movContext?.editMovement?.property_registration?.id) {
						newFiles.push({
							id: fileFromBE?.id,
							idProperty: movContext?.editMovement?.property_registration?.id,
							name: fileFromBE?.name,
							operation: 'delete',
							key: 'timeless_justification'
						});
						newFiles.push({
							idProperty: movContext?.editMovement?.property_registration?.id,
							name: observacionFile?.name,
							file: observacionFile,
							operation: 'create',
							key: 'timeless_justification'
						});
						movContext?.setRecepAndInstAttachs([...newFiles]);
						return;
					}
					if (newFiles && newFiles?.length > 0 && movContext?.editMovement?.property_registration?.id) {
						const filterFiles = newFiles?.filter((file: any) => file?.key !== 'timeless_justification');
						filterFiles.push({
							id: fileFromBE?.id,
							idProperty: movContext?.editMovement?.property_registration?.id,
							name: fileFromBE?.name,
							operation: 'delete',
							key: 'timeless_justification'
						});
						filterFiles.push({
							idProperty: movContext?.editMovement?.property_registration?.id,
							name: observacionFile?.name,
							file: observacionFile,
							operation: 'create',
							key: 'timeless_justification'
						});
						movContext?.setRecepAndInstAttachs([...filterFiles]);
					}
				}
			} else {
				if (newFiles && newFiles?.length === 0 && movContext?.editMovement?.property_registration?.id) {
					newFiles.push({
						idProperty: movContext?.editMovement?.property_registration?.id,
						name: observacionFile?.name,
						file: observacionFile,
						operation: 'create',
						key: 'timeless_justification'
					});
					movContext?.setRecepAndInstAttachs([...newFiles]);
				}
				if (newFiles && newFiles?.length > 0 && movContext?.editMovement?.property_registration?.id) {
					const filterFiles = newFiles?.filter((file: any) => file?.key !== 'timeless_justification');
					filterFiles.push({
						idProperty: movContext?.editMovement?.property_registration?.id,
						name: observacionFile?.name,
						file: observacionFile,
						operation: 'create',
						key: 'timeless_justification'
					});
					movContext?.setRecepAndInstAttachs([...filterFiles]);
				}
			}
		} else {
			const newFiles = movContext?.recepAndInstAttachs.filter((file: any) => file?.key !== 'timeless_justification');
			if (newFiles) {
				movContext?.setRecepAndInstAttachs([...newFiles]);
				return;
			}
			movContext?.setRecepAndInstAttachs([] as any);
		}
	}, [observacionFile, fileInputRef]);

	/* Saving the data in local storage and validating the data. */
	useEffect(() => {
		const allData: IFechasValues = { fecha, fechaInstLegal, observacion, observacionFile };
		const fechasValidadas = validateFechas(fecha, isSameMonth, isErrorCorrelative, fechaInstLegal, observacion, observacionFile);
		if (fechasValidadas) {
			setPaso1Fechas(allData);
		} else {
			setPaso1Fechas(null);
		}
	}, [
		fecha,
		fechaInstLegal,
		observacionFile,
		observacion,
		dateLastPropertyRegistered,
		fileInputRef,
		dateInputRef,
		dateInputRefInstLegal,
		isErrorCorrelative
	]);

	/**
	 * A function that handles the change of the input field.
	 * @param e - React.ChangeEvent<HTMLInputElement>
	 */
	const handleObservacion = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length <= 255) {
			setObservacion(e.target.value);
		} else {
			setObservacion(e.target.value.slice(0, 255));
		}
	};

	/**
	 *
	 * The `e` parameter is of type `React.ChangeEvent<HTMLInputElement>`
	 *
	 * The `React.ChangeEvent<HTMLInputElement>` type is a generic type. The generic type is
	 * `HTMLInputElement`
	 * @param evt - React.ChangeEvent<HTMLInputElement>
	 *
	 */
	const handleFileChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		evt.preventDefault();
		const file: File = evt.target.files?.[0] || ({} as File);
		if (!file) return;
		if (file.name === undefined) {
			return;
		}
		if (file && fileValidate(file) === null) {
			const newFile = new File([file], file.name.toLocaleLowerCase(), { type: file.type, lastModified: file.lastModified });
			setObservacionFile(newFile);
			setErrorFile(null);
		} else {
			setErrorFile(fileValidate(file));
		}
	};

	/**
	 * It deletes the file from the input field
	 */
	const deleteFile = () => {
		if (fileInputRef?.current) {
			fileInputRef.current.value = '';
		}
		setObservacionFile({} as File);
	};

	/**
	 * A function that handles the change of the date of the legal instrument.
	 * @param evt - React.ChangeEvent<HTMLInputElement>
	 */
	const handleFechaIntrumento = (evt: React.ChangeEvent<HTMLInputElement>) => {
		evt.preventDefault();
		setFechaInstLegal(evt.target.value);
	};

	useEffect(() => {
		const todayDate = new Date();
		todayDate.setHours(0, 0, 0, 0);
		const currentMonth = todayDate.getMonth();
		const dateSelected = new Date(fecha);
		dateSelected.setDate(dateSelected.getDate() + 1);
		dateSelected.setHours(0, 0, 0, 0);
		const dateFromLastProperty = new Date(dateLastPropertyRegistered);
		dateFromLastProperty.setDate(dateFromLastProperty.getDate() + 1);
		dateFromLastProperty.setHours(0, 0, 0, 0);
		const monthSelected = dateSelected.getMonth();

		// Validate same month
		if (monthSelected === currentMonth) {
			setFechaInstLegal('');
			setObservacionFile({} as File);
			setIsSameMonth(true);
			if (dateSelected < dateFromLastProperty) {
				setIsErrorCorrelative(true);
				return;
			} else {
				setIsErrorCorrelative(false);
				return;
			}
		}
		// Validate different month
		if (monthSelected < currentMonth) {
			setIsSameMonth(false);
			setFechaInstLegal('');
			setObservacionFile({} as File);
			return;
		}
	}, [fecha, dateLastPropertyRegistered]);

	useEffect(() => {
		checkFileAndInstLegal();
	}, [fecha, dateLastPropertyRegistered]);

	/**
	 * A function that handles the date input.
	 * @param evt - ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	 */
	const handleFecha = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setFecha(evt.target.value);
	};

	/**
	 * Check if the movement have a file. If it does, it set a fake file to show it.
	 */
	const checkFileAndInstLegal = () => {
		if (movContext?.editMovement?.property_registration?.files) {
			movContext?.editMovement?.property_registration?.files.map((file: any) => {
				if (file?.file_type_id === FileType.Resolución_o_Disposición) {
					setObservacionFile(file);
				}
			});
		}
		if (movContext?.editMovement?.property_registration?.date_legal_instrument) {
			setFechaInstLegal(movContext?.editMovement?.property_registration?.date_legal_instrument);
			if (dateInputRefInstLegal.current) {
				dateInputRefInstLegal.current.value = movContext?.editMovement?.property_registration?.date_legal_instrument;
			}
		}
	};

	useEffect(() => {
		/* Setting the max date of the input field. */
		const date = new Date();
		if (dateInputRef.current) {
			dateInputRef.current.max = date.toISOString().split('T')[0];
		}
		if (dateInputRefInstLegal.current) {
			dateInputRefInstLegal.current.max = date.toISOString().split('T')[0];
		}
	}, [fecha, dateInputRef, dateInputRefInstLegal, fechaInstLegal]);

	return (
		<FormControl sx={{ width: '300px', mr: 2, mt: 1 }}>
			<Box sx={{ ml: '-10px' }}>
				<InputLabel
					sx={{ position: 'inherit', pb: 2 }}
					htmlFor="startDate">
					Fecha de Factura/Remito/Sello *
				</InputLabel>
				<Tooltip title={dateToolTip !== null ? `Fecha de ultima alta ${dateToolTip}` : ``}>
					<input
						disabled={movContext?.editMovement?.movement_status_id !== MovementStatusType.PENDIENTE}
						ref={dateInputRef}
						value={fecha}
						onChange={handleFecha}
						id="startDate"
						className="form-control"
						type="date"
						style={{ width: '300px' }}
					/>
				</Tooltip>
				<span id="startDateSelected"></span>
			</Box>
			{dateLastPropertyRegistered.trim().length === 0 && (
				<FormHelperText sx={{ color: '#d32f2f', mt: -1, ml: 1, mb: 1.5 }}>
					* No se pudo obtener la fecha de la ultima Adquisición registrada, consulte al administrador.
				</FormHelperText>
			)}
			{tipoAlta !== 'Donación' && tipoAlta !== 'Transferencia' && (
				<>
					{isErrorCorrelative && isSameMonth && (
						<FormHelperText sx={{ ml: 1 }}>
							* ERROR EN CORRELATIVIDAD DE Nº DE ACTAS DE RECEPCION; Incumplimiento Art. 22º Pto 6 inc b) Decreto 3421/86 Reglamentario Ley
							VII Nº 11 de Contabilidad
						</FormHelperText>
					)}
					{!isSameMonth && (
						<FormHelperText sx={{ ml: 1 }}>
							* INGRESO DE BIENES ATEMPORAL; Incumplimiento Art. 22º Pto 4 Decreto 3421/86 Reglamentario Ley VII Nº 11 de Contabilidad
						</FormHelperText>
					)}
					{(isErrorCorrelative || !isSameMonth) && (
						<>
							<Box>
								<TextField
									sx={{ mt: 3, width: '300px' }}
									InputLabelProps={{ shrink: true }}
									id="outlined-multiline-static"
									label="Observaciones *"
									value={observacion}
									onChange={handleObservacion}
									multiline
									rows={4}
								/>
							</Box>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Box sx={{ mt: 1 }}>
									<Typography sx={{ mt: 1, mb: 1 }}>Instrumento Legal *</Typography>
									<FormControl variant="outlined">
										<InputLabel htmlFor="fileObs">Documento de respaldo*</InputLabel>
										<OutlinedInput
											sx={{ width: '250px' }}
											label="Documento de respaldo *"
											disabled
											autoFocus
											autoComplete="off"
											name="fileObs"
											id="fileObs"
											value={''}
											endAdornment={
												<InputAdornment position="end">
													<Tooltip
														arrow
														title="Adjuntar Comprobante">
														<Button
															sx={{
																backgroundColor: 'rgba(0, 0, 0, 0.05)',
																color: '#000',
																padding: '5px',
																minWidth: '40px',
																'&:hover': {
																	backgroundColor: 'rgba(0, 0, 0, 0.05)'
																}
															}}
															variant="contained"
															component="label">
															<AttachFileIcon />
															<FormControl>
																<input
																	ref={fileInputRef}
																	id="adjunto"
																	name="adjunto"
																	type="file"
																	accept="image/*, application/pdf"
																	hidden
																	onChange={handleFileChange}
																/>
															</FormControl>
														</Button>
													</Tooltip>
												</InputAdornment>
											}></OutlinedInput>
									</FormControl>
								</Box>
								{observacionFile && observacionFile?.name && (
									<Box sx={{ mt: 1 }}>
										<Tooltip
											arrow
											title="Eliminar adjunto">
											<DeleteIcon
												sx={{
													cursor: 'pointer',
													fontSize: '30px',
													backgroundColor: '#575757',
													color: '#fff',
													padding: '3px',
													borderRadius: '5px',
													m: 1,
													mt: 5,
													transition: 'all 0.2s ease-in-out',
													'&:hover': {
														transition: 'all 0.2s ease-in-out',
														backgroundColor: '#161616',
														color: '#fff',
														transform: 'scale(1.2)'
													}
												}}
												color="error"
												onClick={deleteFile}
											/>
										</Tooltip>
									</Box>
								)}
							</Box>
							<Box>
								{observacionFile && observacionFile?.name && (
									<>
										<Typography
											sx={{
												pt: 1,
												pl: 1,
												width: '200px',
												wordBreak: 'break-all'
											}}>
											{observacionFile.name}
										</Typography>
									</>
								)}
								{!observacionFile?.name && (
									<>
										<FormHelperText sx={{ pt: 1, ml: 1, position: 'relative' }}>* Requerido documento de respaldo</FormHelperText>
										<FormHelperText sx={{ pt: 1, ml: 1, position: 'relative' }}>
											* Extensiones permitidas: .png, .jpeg, .jpg, .pdf
										</FormHelperText>
										<FormHelperText sx={{ pt: 1, ml: 1, position: 'relative' }}>* Tamaño máximo: 100MB</FormHelperText>
									</>
								)}
							</Box>
						</>
					)}
					{!isSameMonth && (
						<Box sx={{ mt: 3, ml: -1 }}>
							<InputLabel
								sx={{ position: 'inherit', pb: 2 }}
								htmlFor="startDate">
								Fecha de Instrumento Legal *
							</InputLabel>
							<input
								max={new Date().toISOString().split('T')[0]}
								ref={dateInputRefInstLegal}
								value={fechaInstLegal}
								onChange={handleFechaIntrumento}
								id="startDateIntrument"
								className="form-control"
								type="date"
							/>
							<span id="startDateInsSelected"></span>
						</Box>
					)}
				</>
			)}
		</FormControl>
	);
};
