// Enums
import { ApiPaths } from 'enums/apiPaths';
import { IPaginationRequest } from 'interfaces/commons/IParameters';
import { IChangePasswordResquestBody, IUserFilters, IUserResquestBody } from 'interfaces/user/IUser';

import {
	httpPost,
	handleHttpError,
	httpPatch,
	buildDefaultOptions,
	httpGet,
	httpDelete,
	buildURLQuery
} from './httpService';

const URL_BASE = `${ApiPaths.USERS}`;
const URL_SIGN_IN = `${ApiPaths.SIGN_IN}`;
const CHANGE_PASSWORD = `${ApiPaths.CHANGE_PASSWORD}`;
const GET_URL_TOGGLE_STATUS = (id: number) => {
	return URL_BASE + `/status/${id}`;
};
const GET_URL_RESET_PASSWORD = (id: number) => {
	return URL_BASE + `/reset/${id}`;
};
const GET_URL_WITH_ID = (id: number) => {
	return URL_BASE + `/${id}`;
};
const GET_URL_CHANGE_PASSWORD = (id: number) => {
	return CHANGE_PASSWORD + `/${id}`;
};
const GET_URL_UPDATE_PERSON = (id: number) => {
	return URL_BASE + `/${id}`;
};

export const tryGetAllUsers = async ({ term }: IUserFilters, { page, limit }: IPaginationRequest) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const queryString = buildURLQuery({ term, page, limit });
		const URI = `${URL_BASE}?${queryString}`;
		const response = await httpGet(URI, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryGetUserByID = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpGet(GET_URL_WITH_ID(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryCreateUser = async (user: IUserResquestBody) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpPost(URL_SIGN_IN, user, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryUpdateUser = async (user: IUserResquestBody) => {
	try {
		const { id, ...data } = user;
		const OPTIONS = buildDefaultOptions();
		const response = await httpPatch(GET_URL_WITH_ID(id ? id : 0), data, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryDeleteUser = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpDelete(GET_URL_WITH_ID(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryToggleUserStatus = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpPatch(GET_URL_TOGGLE_STATUS(id), {}, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryUserResetPassword = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpPatch(GET_URL_RESET_PASSWORD(id), {}, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryUpdatePersonData = async (user: IUserResquestBody) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpPatch(GET_URL_UPDATE_PERSON(user?.id ? user.id : 0), user, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryChangePassword = async (user: IChangePasswordResquestBody, id: any) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpPatch(GET_URL_CHANGE_PASSWORD(id ? id : 0), user, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};
