import { useState, useEffect, FormEvent, useRef } from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import apiInstance from 'interceptors/axiosInterceptor';
import { AxiosError } from 'axios';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress
					variant="determinate"
					{...props}
				/>
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography
					variant="body2"
					color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	);
}

interface IProgressBarProps {
	value?: number;
}

export const ProgressBar = ({ value = 0 }: IProgressBarProps) => {
	const [progress, setProgress] = useState(value);

	useEffect(() => {
		setProgress(value);
	}, [value]);

	return (
		<Box sx={{ width: '100%' }}>
			<LinearProgressWithLabel
				id="progress-bar"
				value={progress}
			/>
		</Box>
	);
};
