import { useMemo, FC } from 'react';
import { Row, Cell } from '@tanstack/react-table';

import { defaultColumns } from './OrganizationalUnitColumnDef';
import { PaginationTable } from 'components/globals/tabs/PaginationTable';
import { useOrganizationalUnitContext } from 'hooks/useUnidadOrganizacionalContext';
import { IOrganizationalUnitTableProps } from 'interfaces/organism/IOrganism';
import { CustomTable } from 'components/globals/table/customTable';
import { Box, Typography } from '@mui/material';

export const OrganizationalUnitTable: FC<IOrganizationalUnitTableProps> = ({
	elements,
	handleUpdate,
	handleDelete
}) => {
	const context = useOrganizationalUnitContext();
	const columns = useMemo<typeof defaultColumns>(() => [...defaultColumns], [defaultColumns]);

	const handleAction = (row: Row<any>, cell?: Cell<any, any>, evt?: any) => {
		if (!cell?.id?.includes('actions')) {
			return;
		}
		switch (row.original?.action) {
			case 'edit':
				handleUpdate(row.original);
				break;
			case 'delete':
				handleDelete(row.original);
				break;
			default:
				break;
		}
	};

	return (
		<div className={'customBodyContainerTable'}>
			<Box>
				<Typography
					variant="h6"
					ml={2}
					mt={2}>
					Lista de Unidades Organizacionales
				</Typography>

				<PaginationTable
					currentPage={context?.meta?.currentPage}
					totalPages={context?.meta?.totalPages}
					showDetails={true}
					itemsPerPage={context?.meta?.itemsPerPage}
					totalItems={context?.meta?.itemCount}
					handleChangePage={(page: number, limit?: number) => context?.onHandleChangePage({ page: page, limit })}
				/>
			</Box>

			<CustomTable
				columns={columns}
				elements={elements}
				itemsPerPage={context?.meta?.itemsPerPage}
				handleAction={(row: Row<any>, cell?: Cell<any, any>, evt?: any) => handleAction(row, cell, evt)}
			/>

			<div className="h-2" />
			<PaginationTable
				currentPage={context?.meta?.currentPage}
				totalPages={context?.meta?.totalPages}
				itemsPerPage={context?.meta?.itemsPerPage}
				totalItems={context?.meta?.totalItems}
				handleChangePage={(page: number, limit?: number) => context?.onHandleChangePage({ page: page, limit })}
			/>
		</div>
	);
};
