import { INovedadesFilters } from 'contexts/novedadesContext/NovedadesContext';
import apiInstance from 'interceptors/axiosInterceptor';
import { trackPromise } from 'react-promise-tracker';

export const getAllNotifications = async (
	id_dep_pat: number | null,
	page = 1,
	limit = 10,
	filters?: INovedadesFilters
) => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');
	const patrimonial_dependence_id = id_dep_pat === null ? 0 : id_dep_pat;
	const promise = trackPromise(
		new Promise((resolve, reject) => {
			resolve(
				apiInstance
					.get(
						'/notifications',

						{
							headers: { Authorization: `Bearer ${token}` },
							params: { patrimonial_dependence_id, page, limit, ...filters }
						}
					)
					.then((response) => {
						return response;
					})
					.catch((error) => {
						return error;
					})
			);
		}),
		'getAllNotificationsTracker'
	);

	return promise;
};

export const getVersion = async () => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');
	const promise = trackPromise(
		new Promise((resolve, reject) => {
			resolve(
				apiInstance
					.get('/notifications/version', {
						headers: { Authorization: `Bearer ${token}` }
					})
					.then((response) => {
						return response;
					})
					.catch((error) => {
						console.error(error);
						return error;
					})
			);
		}),
		'getAllNotificationsTracker'
	);

	return promise;
};

export const updateNotification = async (id: number, status_id: number, cancelToken: any) => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');
	const promise = trackPromise(
		new Promise((resolve, reject) => {
			resolve(
				apiInstance
					.patch(
						`/notifications/status/${Number(id)}`,
						{ status_id },
						{
							headers: { Authorization: `Bearer ${token}` },
							cancelToken
						}
					)
					.then((response) => {
						return response;
					})
					.catch((error) => {
						return error;
					})
			);
		}),
		'patchNotificationTracker'
	);

	return promise;
};
