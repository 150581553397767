import { useState, useEffect, useRef, Dispatch, SetStateAction, ChangeEvent } from 'react';
import { toast } from 'react-toastify';
import style from './pdfDoc.module.css';

const styleModal = {
	position: 'absolute' as const,
	top: '48%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '44%',
	minWidth: '532px',
	maxHeight: '95%',
	minHeight: '30%',
	bgcolor: '#e4e4e4',
	border: '2px solid #000000b9',
	boxShadow: 24,
	overflow: 'auto'
};

// Services
import { deleteMinuteSigned, uploadMinuteSigned } from 'services/Movements/crudMovements';
import { getPdfSigned } from 'services';
import { fileValidate } from 'components/EncPatrimonial/altaStepper/paso1/validate';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// Hooks
import { useMovContext } from 'hooks/useMovContext';

// Material UI
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
	Typography,
	Box,
	Button,
	Modal,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	Tooltip,
	FormHelperText
} from '@mui/material';
import { BounceLoader } from 'react-spinners';
import { FormDialog } from 'components/globals/dialog/FormDialog';

interface IModalMinuteSignedProps {
	openModal: boolean;
	setOpenModal: Dispatch<SetStateAction<boolean>>;
	selectedRow: any;
	onSuccess?: CallableFunction;
	movementType?: string;
}

export const UploadMinuteSigned = ({
	openModal,
	setOpenModal,
	selectedRow,
	onSuccess,
	movementType
}: IModalMinuteSignedProps) => {
	const [attachment, setAttachment] = useState<File | null>(null);
	const [errorAttach, setErrorAttach] = useState<string | null>(null);
	const [previewAttach, setPreviewAttach] = useState<any | null>(null);
	const [attachImage, setAttachImage] = useState<any | null>(null);
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [pdfFromServer, setPdfFromServer] = useState<any | null>(null);
	const [idPdfFromServer, setIdPdfFromServer] = useState<number | null>(null);

	const [open, setOpen] = useState(true);
	const [loading, setLoading] = useState(false);
	const [opacity, setOpacity] = useState(false);

	const attachmentRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (selectedRow) {
			getPdfSigned(selectedRow.id).then((res) => {
				if (res?.data[0]?.id) {
					setIdPdfFromServer(res.data[0].id);
				}
				if (res?.data[0]?.path) {
					if (res.data[0].path.includes('pdf')) {
						const newFile = new File([res.data[0].path], res.data[0].name, { type: 'application/pdf' });
						setAttachment(newFile);
						// Se debe arreglar desde el back luego el protocolo
						if (import.meta.env.DEV) {
							setPdfFromServer(res.data[0].path.replace('https://', 'http://'));
						} else {
							setPdfFromServer(res.data[0].path);
						}
					} else {
						const newFile = new File([res.data[0].path.replace('https://', 'http://')], res.data[0].name, {
							type: 'image/jpeg'
						});
						setAttachment(newFile);
						if (import.meta.env.DEV) {
							setAttachImage(String(res.data[0].path.replace('https://', 'http://')));
						} else {
							setAttachImage(res.data[0].path);
						}
					}
				}
			});
		}
	}, [selectedRow, pdfFromServer]);

	useEffect(() => {
		if (idPdfFromServer) return;
		if (attachment) {
			if (attachment.type.split('/')[1] !== 'pdf') {
				const newImg = URL.createObjectURL(attachment);
				setAttachImage(newImg);
			} else {
				setAttachImage(null);
			}
		}
	}, [attachment]);

	const handleClose = () => {
		setOpen(false);
		setOpenModal(false);
	};

	const handleLoading = () => {
		setLoading(true);
		setTimeout(() => {
			setOpacity(true);
		}, 300);
		setTimeout(() => {
			handleUpload();
		}, 2000);
	};

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		const file = event.target.files?.[0];
		if (!file) return;
		if (file && fileValidate(file) === null) {
			setAttachment(file);
			setErrorAttach(null);
		} else {
			setErrorAttach(fileValidate(file));
		}
	};

	const handleDeleteFile = () => {
		if (idPdfFromServer) {
			deleteMinuteSigned(idPdfFromServer).then((res) => {
				if (res) {
					toast.success('Se ha eliminado el archivo correctamente');
					setAttachment(null);
					setPreviewAttach(null);
					setErrorAttach(null);
					setPdfFromServer(null);
					setPageNumber(1);
					setAttachImage(null);
					if (attachmentRef.current) attachmentRef.current.value = '';
					return;
				}
			});
		} else {
			setAttachment(null);
			setPreviewAttach(null);
			setErrorAttach(null);
			setAttachImage(null);
			setPageNumber(1);
			if (attachmentRef.current) attachmentRef.current.value = '';
		}
	};

	function onDocumentLoadSuccess({ numPages }: any) {
		setNumPages(numPages);
	}

	const handleUpload = () => {
		if (!attachment) {
			toast.error('Debe seleccionar un archivo');
			return;
		}
		uploadMinuteSigned(selectedRow.id, attachment).then((e) => {
			onSuccess && onSuccess();
			handleClose();
			if (e.status === 201) {
				toast('Acta firmada cargada correctamente', { type: 'success', autoClose: 2000 });
			} else {
				toast(`${e.response.data.message}`, { type: 'error', autoClose: 2000 });
			}
		});
	};

	const handleDisableSave = (): boolean => {
		if (pdfFromServer) return true;

		if (!attachment || attachment === null) {
			return true;
		}
		return false;
	};

	const handleDisableSaveAttach = (): boolean => {
		if (pdfFromServer) {
			return true;
		}
		if (attachment || attachment !== null) {
			return true;
		}
		return false;
	};

	return (
		<FormDialog
			title={`Cargar Acta Firmada`}
			content={
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}>
					{selectedRow.property_registration ? (
						<Typography
							variant="body1"
							fontWeight={600}
							sx={{ mb: 1, pl: 2, textAlign: 'center' }}>
							{`Cargar Acta Firmada para Acta Nro: ${selectedRow.property_registration?.minute_number || ''}`}
						</Typography>
					) : null}
					<Box>
						{attachment && attachImage && (
							<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
								<img
									src={attachImage}
									alt="preview"
									style={{ width: '90%', height: '90%', objectFit: 'contain' }}
								/>
							</Box>
						)}
						{attachment && attachment.type.split('/')[1] === 'pdf' && (
							<Box
								sx={{
									minHeight: '450px',
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									flexDirection: 'column'
								}}>
								<Document
									file={pdfFromServer || attachment}
									onLoadSuccess={onDocumentLoadSuccess}
									onLoadError={console.error}
									className={style.pdfDocument}>
									<Page
										pageNumber={pageNumber}
										renderTextLayer={false}
										renderAnnotationLayer={false}
										className={style.pdfPage}
									/>
								</Document>
								<Box>
									<Button
										color="secondary"
										disabled={pageNumber <= 1}
										onClick={() => setPageNumber((prev) => prev - 1)}>
										Anterior
									</Button>
									<Button
										disabled={numPages === pageNumber}
										onClick={() => setPageNumber((prev) => prev + 1)}>
										Siguiente
									</Button>
								</Box>
								<p>{`Página ${pageNumber} de ${numPages}`}</p>
							</Box>
						)}
					</Box>

					<FormControl
						sx={{ display: 'flex', flexDirection: 'row', columnGap: '10px' }}
						variant="outlined">
						<InputLabel
							htmlFor="ncomprobante"
							sx={{ width: '55%' }}>
							{attachment?.name ? attachment.name : 'Acta Firmada'}
						</InputLabel>
						<OutlinedInput
							sx={{ width: '250px' }}
							label={attachment?.name ? attachment.name : 'Acta Firmada'}
							autoComplete="off"
							disabled
							endAdornment={
								<InputAdornment position="end">
									<Button
										disabled={handleDisableSaveAttach()}
										sx={{
											backgroundColor: 'rgba(0, 0, 0, 0.05)',
											color: '#000',
											padding: '5px',
											minWidth: '40px',
											'&:hover': {
												backgroundColor: 'rgba(0, 0, 0, 0.05)'
											}
										}}
										variant="contained"
										component="label">
										<AttachFileIcon />
										<FormControl>
											<input
												ref={attachmentRef}
												id="adjunto"
												name="adjunto"
												type="file"
												accept=".jpeg, .png, .jpg, .pdf"
												hidden
												onChange={handleFileChange}
											/>
										</FormControl>
									</Button>
								</InputAdornment>
							}></OutlinedInput>
						{errorAttach !== null && <FormHelperText sx={{ color: '#575757' }}>{errorAttach}</FormHelperText>}
					</FormControl>

					{loading && (
						<Box
							sx={{
								zIndex: '9999',
								opacity: opacity ? '1' : '0',
								position: 'absolute',
								backdropFilter: 'blur(2px)',
								height: '100%',
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								transition: 'all 800ms ease-in-out'
							}}>
							<BounceLoader
								size={100}
								speedMultiplier={1.5}
							/>
						</Box>
					)}
				</Box>
			}
			show={openModal}
			actions={
				<Box pb={2}>
					<Button
						sx={{ mr: 2, width: '170px' }}
						variant="contained"
						color="secondary"
						onClick={() => handleClose()}>
						Salir
					</Button>
					<Button
						sx={{ mr: 2, width: '170px' }}
						disabled={!attachment || attachment === null}
						variant="contained"
						color="primary"
						onClick={() => handleDeleteFile()}>
						Eliminar Adjunto
					</Button>
					<Button
						disabled={handleDisableSave()}
						sx={{ width: '170px' }}
						variant="contained"
						color="success"
						onClick={() => handleLoading()}>
						Guardar
					</Button>
				</Box>
			}
			handleClose={() => setOpenModal(false)}
		/>
	);
};
