import React from 'react';
import { Box } from '@mui/material';
import { FieldExpedientDetails } from 'components/globals/fields/FieldExpedientDetails';
import { FieldlegalInstrument } from 'components/globals/fields/FieldLegalInstrument';
import { FieldLabel } from 'components/globals/fields/FieldLabel';

interface ISummaryStepTwo {
	entity: any;
}

export const SummaryStepTwo = ({ entity }: ISummaryStepTwo) => {
	return (
		<Box sx={{ padding: '4%', display: 'flex', flexDirection: 'column', gap: 4 }}>
			<FieldExpedientDetails
				agencyCode={entity?.proceedings_number?.agency_code || '-'}
				number={entity?.proceedings_number?.number || '-'}
				year={entity?.proceedings_number?.year || '-'}
			/>
			<FieldlegalInstrument
				instrumetType={entity?.instrument_type || '-'}
				number={entity?.legal_instrument?.instrument_number || '-'}
				year={entity?.legal_instrument?.instrument_year || '-'}
			/>
			<>
				{entity?.high_type_id === 1 && (
					<>
						<FieldlegalInstrument
							label="Orden de Provisión:"
							instrumetType={undefined}
							number={entity?.provision_order.provision_number || '-'}
							year={entity?.provision_order.provision_year || '-'}
						/>
						<FieldLabel label={`Tipo de Contratación: ${entity?.contract_type}`} />
					</>
				)}
			</>
		</Box>
	);
};
