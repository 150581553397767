import { HeaderForm } from '../../components/globals/headerForm/HeaderForm';
import { memo, useEffect, useState } from 'react';
import { useBalanceContext } from 'hooks/useBalanceContext';
import { useAppContext } from 'hooks/useAppContext';
import { Spinner } from 'components/globals/spinner/Spinner';
import { Box, Grid } from '@mui/material';
import { FormGenericSelect } from 'components/globals/inputs/FormGenericSelect';
import { ISelectOption } from 'interfaces/commons/IParameters';
import { DEFAULT_NO_ERROR, requiredValidator } from 'helpers/Validators/Validators';
import { ButtonConfirm, ButtonDecline } from 'components/globals/customsBtn/customBtn';
import { useNavigate } from 'react-router-dom';
import { BalanceUnitsTable } from 'components/balance/BalanceUnitsTable';
import { IOrganizationalUnit } from 'interfaces/organism/IOrganism';
import { IJurisdiction } from 'interfaces/jurisdictions/IJurisdictions';
import lodash from 'lodash';
import { CustomDecideDialog } from 'components/globals/dialog/CustomDeleteDialog';

export interface IErrorsBalanceForm {
	jurisdiction?: string | undefined;
}

export const LABELS_BALANCE_FORM = {
	jurisdiction: 'Jurisdicción'
};

const BalancePage = memo(() => {
	const balanceContext = useBalanceContext();
	const appContext = useAppContext();
	const navigate = useNavigate();

	const [organizationalUnits, setOrganizationalUnits] = useState<IOrganizationalUnit[]>([]);
	const [jurisdictions, setJurisdictions] = useState<ISelectOption[]>([]);
	const [selectedJurisdiction, setSelectedJurisdiction] = useState<ISelectOption | undefined>(undefined);
	const [jurisdiction, setJurisdiction] = useState<IJurisdiction | undefined>(undefined);
	const [errors, setErrors] = useState<IErrorsBalanceForm>({} as IErrorsBalanceForm);

	useEffect(() => {
		// TODO Llamar al Servicio para recuperar Jurisdicciones
		appContext?.onHandleTryGetAllJurisdictions();
	}, []);

	useEffect(() => {
		setJurisdictions(
			appContext?.jurisdictions.map((j) => {
				return {
					id: j.id,
					label: j.name
				};
			})
		);
	}, [appContext?.jurisdictions]);

	const validateForm = (): boolean => {
		let newErrors: IErrorsBalanceForm = {} as IErrorsBalanceForm;
		setErrors(newErrors);

		let jurisdictionError = requiredValidator({ value: selectedJurisdiction?.label, label: LABELS_BALANCE_FORM.jurisdiction });
		if (jurisdictionError !== DEFAULT_NO_ERROR) {
			newErrors.jurisdiction = jurisdictionError;
		}

		if (Object.entries(newErrors).length !== 0) {
			setErrors(newErrors);
			return false;
		}

		return true;
	};

	const onHandleSearch = async () => {
		if (!validateForm()) {
			return false;
		}
		let jurisdiction = appContext?.jurisdictions?.find((j) => {
			return j.id === selectedJurisdiction?.id;
		});
		setJurisdiction(jurisdiction);
		await balanceContext?.onHandleTryGetAllOrganizationalUnits(jurisdiction, {
			page: 1,
			limit: 10
		});
	};

	useEffect(() => {
		setOrganizationalUnits([]);
	}, [selectedJurisdiction]);

	useEffect(() => {
		if (balanceContext?.organizationalUnits) {
			setOrganizationalUnits(balanceContext?.organizationalUnits);
		} else {
			setOrganizationalUnits([]);
		}
	}, [balanceContext?.organizationalUnits]);

	const onHandleCancel = () => {
		navigate('/home');
	};

	const onHandleChangeStatus = async (entity: IOrganizationalUnit) => {
		if (!entity) {
			console.error('Para cambiar el estado de una Unidad Organizacional es necesario que indique una primero.');
			return;
		}
		const result = await balanceContext?.onHandleTryChangeStatus(entity.id ? entity.id : 0, !entity.sheets_presentation);
		if (result) {
			let index = organizationalUnits.findIndex((e) => {
				return e.id === entity.id;
			});
			if (index !== -1) {
				organizationalUnits[index].sheets_presentation = !entity.sheets_presentation;
				setOrganizationalUnits(organizationalUnits);
			}
		}
	};

	const onHandleChangeStatusAll = async (status: boolean) => {
		const result = await balanceContext?.onHandleTryChangeStatusAll(status);
		if (result) {
			let newUpdatedEntities: IOrganizationalUnit[] = [];
			organizationalUnits.forEach((unit: IOrganizationalUnit) => {
				unit.sheets_presentation = status;
				newUpdatedEntities.push(unit);
			});
			setOrganizationalUnits(newUpdatedEntities);
		}
	};

	const isEnableToClose = () => {
		if (organizationalUnits.length === 0) {
			return false;
		}
		if (!selectedJurisdiction) {
			return false;
		}
		if (jurisdiction && jurisdiction.organizationals) {
			return !!!jurisdiction.organizationals.find((uo) => {
				return uo.sheets_presentation === true;
			});
		}
		return true;
	};

	// START Search Jurisdictions by Text Term
	const [jurisdictionsSearchTerm, setJurisdictionsSearchTerm] = useState<string>('');
	const debounceInputJuridictions = lodash.debounce((e) => setJurisdictionsSearchTerm(e), 1000);
	useEffect(() => {
		if (jurisdictionsSearchTerm !== undefined && jurisdictionsSearchTerm !== null && jurisdictionsSearchTerm !== '') {
			appContext?.onHandleTryGetAllJurisdictions();
		}
	}, [jurisdictionsSearchTerm]);
	// END Search Jurisdictions by Text Term

	// START Handle Close Balance Modal
	const [showCloseBalanceModal, toggleCloseBalanceModal] = useState(false);

	const handleShowCloseBalanceModal = () => {
		if (!validateForm()) {
			return false;
		}
		if (!isEnableToClose()) {
			return false;
		}
		if (!jurisdiction) {
			return;
		}
		toggleCloseBalanceModal(true);
	};

	const handleHideCloseBalanceModal = () => {
		toggleCloseBalanceModal(false);
	};

	const handleSubmitCloseBalance = async () => {
		if (!validateForm()) {
			return false;
		}
		if (!isEnableToClose()) {
			return false;
		}
		await balanceContext?.onHandleTryCloseBalance(selectedJurisdiction?.id);
	};
	// END Handle Close Balance Modal

	return (
		<>
			{appContext?.showSpinner ? <Spinner /> : null}

			<HeaderForm title={'Cierre de ejercicio'} />

			{showCloseBalanceModal && (
				<CustomDecideDialog
					title={'Cerrar Ejercicio'}
					message={'¿Esta seguro de Cerrar el ejercicio para la Jurisdicción Seleccionada?'}
					handleSubmit={() => {
						handleSubmitCloseBalance();
					}}
					handleDecline={() => {
						handleHideCloseBalanceModal();
					}}
				/>
			)}

			<Grid
				container
				spacing={2}
				mt={1}
				mb={1}
				justifyContent="flex-end">
				<Grid
					item
					xs={12}
					sm={8}
					md={8}
					xl={8}
					lg={8}>
					<FormGenericSelect
						id={'jurisdiction'}
						value={selectedJurisdiction}
						label={'Seleccione una '.concat(LABELS_BALANCE_FORM.jurisdiction)}
						handleChange={setSelectedJurisdiction}
						handleInputChange={debounceInputJuridictions}
						options={jurisdictions}
						error={errors.jurisdiction}
						isLoading={appContext?.isLoadingJurisdictions}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					sm={2}
					md={2}
					xl={2}
					lg={2}>
					<ButtonConfirm
						sx={{ ml: 3, width: '80%' }}
						variant="contained"
						onClick={() => onHandleSearch()}
						disabled={selectedJurisdiction === undefined}>
						Buscar
					</ButtonConfirm>
				</Grid>
				<Grid
					item
					xs={12}
					sm={2}
					md={2}
					xl={2}
					lg={2}>
					<ButtonConfirm
						sx={{ ml: 3, width: '80%' }}
						variant="contained"
						onClick={() => handleShowCloseBalanceModal()}
						disabled={!isEnableToClose()}>
						Cerrar ejercicio
					</ButtonConfirm>
				</Grid>
			</Grid>

			<Box sx={{ width: '100%', typography: 'body1' }}>
				<BalanceUnitsTable
					elements={organizationalUnits}
					handleToggleStatus={(entity: IOrganizationalUnit) => {
						onHandleChangeStatus(entity);
					}}
					handleToggleAllStatus={(newStatus: boolean) => {
						onHandleChangeStatusAll(newStatus);
					}}
				/>
			</Box>

			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
				<ButtonDecline
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => {
						onHandleCancel();
					}}>
					Volver
				</ButtonDecline>
			</Box>
		</>
	);
});

BalancePage.displayName = 'Balance';

export { BalancePage };
