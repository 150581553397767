/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useRef } from 'react';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { ButtonConfirm, ButtonDecline, ButtonNextPrev, ButtonSearch } from 'components/globals/customsBtn/customBtn';
import { FormGenericInput } from 'components/globals/inputs/FormGenericInput';
import { DEFAULT_NO_ERROR, requiredNumber, requiredValidator } from 'helpers/Validators/Validators';
import {
	IBajaAccessories,
	IBajaAtemporal,
	IBajaPropertyItem,
	IBajaStepThree,
	IErrorsStepThreeForm,
	IHeritageAsset,
	ITimelessProperty
} from 'interfaces/baja/IBaja';
import { MovementStatusType } from 'enums';
import { ISelectOption } from 'interfaces/commons/IParameters';
import { FormGenericSwitch } from 'components/globals/inputs/FormGenericSwitch';
import { FormGenericMultiline } from 'components/globals/inputs/FormGenericMultiline';
import { currencyLocalFormat, parsedFormatDate } from 'helpers/Formaters/Formaters';
import lodash from 'lodash';
import { FormGenericSelect } from 'components/globals/inputs/FormGenericSelect';
import SearchIcon from '@mui/icons-material/Search';
import { DeregistrationTypes } from 'enums';
import { useDeregistrationContext } from 'hooks/useDeregistrationContext';
import { FormDialog } from 'components/globals/dialog/FormDialog';
import { TimelessPropertyFormData, TimelessPropertyOverviewForm } from './timelessPropertieOverview';
import { HeritageAssetsTable } from '../heritageAssets/HeritageAssetsTable';
import { CustomDialog } from 'components/globals/dialog/CustomDialog';
import { SummaryItem } from '../summary/SummaryItem';
import { DialogType, ICustomButtonProps } from 'components/globals/dialog/utils';
import { CustomDecideDialog } from 'components/globals/dialog/CustomDeleteDialog';
import { useAppContext } from 'hooks/useAppContext';
import { toast } from 'react-toastify';

export interface IOverviewBajaStepThreeForm {
	entity: IBajaStepThree | undefined;
	propertiesOrAccessories: IBajaAccessories[] | [];
	propertiesTimeless: IBajaAtemporal[] | [];
	handleSubmit: (data: IBajaStepThree) => void;
	handleCancel: (hasPendingData: boolean) => void;
	handleBack: () => void;
	buttonLabelCancel?: string;
	buttonLabelSubmit?: string;
	buttonLabelBack?: string;
	handleSearchDependencies: (term: string) => void;
	deregistrationTypes: ISelectOption[];
	patrimonialDependences: ISelectOption[];
	movementStatusId?: number;
}

export const LABELS_STEP_THREE_FORM = {
	propertyLabel: 'Detalles del Bien',
	inventary_number: 'N° de Inventario',
	is_accessory: '¿Es Accesorio?',
	accessory_number: 'N° de Accesorio',
	nomenclador_code: 'Código Nomenclador',
	date: 'Fecha de Alta',
	unit_price: 'Precio Unitario',
	observations: 'Observaciones',
	type_id: 'Tipo de Baja',
	patrimonial_dependence: 'Dependencia Patrimonial de Destino',
	destiny: 'Destino',
	add_item_label: 'Agregar Bien',
	edit_item_label: 'Guardar Bien',
	clear_item_label: 'Limpiar',
	search_label: 'Buscar'
};

export const BajaStepThreeForm = ({
	entity,
	propertiesOrAccessories = [],
	propertiesTimeless = [],
	handleSubmit,
	handleCancel,
	handleBack,
	buttonLabelCancel = 'Cancelar',
	buttonLabelSubmit = 'Siguiente',
	buttonLabelBack = 'Atrás',
	handleSearchDependencies,
	deregistrationTypes = [],
	patrimonialDependences = [],
	movementStatusId
}: IOverviewBajaStepThreeForm) => {
	const deregistrationContext = useDeregistrationContext();
	const appContext = useAppContext();

	// Detalles del Bien
	const [inventaryNumber, setInventaryNumber] = useState<string>('');
	const [isAccessory, setIsAccessory] = useState<boolean>(false);
	const [accessoryNumber, setAccessoryNumber] = useState<string | undefined>();
	const [deregistrationType, setDeregistrationType] = useState<ISelectOption | undefined>();
	const [observations, setObservations] = useState<string | undefined>();
	const [toPatrimonialDependence, setToPatrimonialDependence] = useState<ISelectOption | undefined>();
	const [destiny, setDestiny] = useState<string | undefined>();

	// Bien o accesorio hallado
	const [selectedProperty, setSelectedProperty] = useState<any | undefined>(undefined);
	const [selectedToEdit, setSelectedToEdit] = useState<IHeritageAsset | undefined>(undefined);

	const [errors, setErrors] = useState<IErrorsStepThreeForm | undefined>({} as IErrorsStepThreeForm);
	const [internalPropertiesOrAccessories, setInternalPropertiesOrAccessories] = useState<IBajaAccessories[] | []>([]);
	const [internalPropertiesTimeless, setinternalPropertiesTimeless] = useState<IBajaAtemporal[] | []>([]);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [isSubmittingTimelessRegistration, setIsSubmittingTimelessRegistration] = useState(false);

	useEffect(() => {
		if (entity?.id !== undefined) {
			deregistrationContext.onHandleGetDataStepThree(entity.id);
		}
	}, []);

	useEffect(() => {
		if (entity) {
			if (entity?.id !== undefined && entity.id > 0) {
				setIsEdit(true);
			}
		}
	}, [entity]);

	useEffect(() => {
		if (selectedToEdit) {
			const dependence = patrimonialDependences.find(
				(searched) => searched.id == selectedToEdit?.to_patrimonial_dependence_id
			);
			setToPatrimonialDependence(dependence);
		}
	}, [patrimonialDependences]);

	useEffect(() => {
		if (propertiesOrAccessories && propertiesOrAccessories.length > 0) {
			setInternalPropertiesOrAccessories(propertiesOrAccessories);
		}
	}, [propertiesOrAccessories]);

	useEffect(() => {
		appContext?.onHandleTryGetAllPatrimonialDependences();
		if (propertiesTimeless && propertiesTimeless.length > 0) {
			setinternalPropertiesTimeless(propertiesTimeless);
		}
	}, [propertiesTimeless]);

	// START Search Patrimonial Dependencies by Text Term
	const [dependencySearchTerm, setDependencySearchTerm] = useState<string>('');
	const debounceInputDependende = lodash.debounce((e) => setDependencySearchTerm(e), 1000);
	useEffect(() => {
		if (dependencySearchTerm !== undefined && dependencySearchTerm !== null && dependencySearchTerm !== '') {
			handleSearchDependencies(dependencySearchTerm);
		}
	}, [dependencySearchTerm]);
	// END Search Patrimonial Dependencies by Text Term

	const validateSearchItemForm = (): boolean => {
		const newErrors: IErrorsStepThreeForm = {} as IErrorsStepThreeForm;
		setErrors(newErrors);

		const inventaryNumberError = requiredNumber({
			value: inventaryNumber,
			label: LABELS_STEP_THREE_FORM.inventary_number
		});
		if (inventaryNumberError !== DEFAULT_NO_ERROR) {
			newErrors.inventary_number = inventaryNumberError;
		}

		if (isAccessory) {
			const accessoryNumberError = requiredNumber({
				value: accessoryNumber,
				label: LABELS_STEP_THREE_FORM.accessory_number
			});
			if (accessoryNumberError !== DEFAULT_NO_ERROR) {
				newErrors.accessory_number = accessoryNumberError;
			}
		}

		setErrors(newErrors);
		if (Object.entries(newErrors).length !== 0) {
			return false;
		}

		return true;
	};

	const validateForm = (): boolean => {
		const newErrors: IErrorsStepThreeForm = {} as IErrorsStepThreeForm;
		setErrors(newErrors);

		const deregistrationTypeError = requiredValidator({
			value: deregistrationType?.label,
			label: LABELS_STEP_THREE_FORM.type_id
		});
		if (deregistrationTypeError !== DEFAULT_NO_ERROR) {
			newErrors.type = deregistrationTypeError;
		}

		if (deregistrationType !== undefined && deregistrationType.id === DeregistrationTypes.TRANSFERENCIA) {
			const toPatrimonialDependenceError = requiredValidator({
				value: toPatrimonialDependence?.label,
				label: LABELS_STEP_THREE_FORM.patrimonial_dependence
			});
			if (toPatrimonialDependenceError !== DEFAULT_NO_ERROR) {
				newErrors.patrimonial_dependence = toPatrimonialDependenceError;
			}
		}

		setErrors(newErrors);
		if (Object.entries(newErrors).length !== 0) {
			return false;
		}

		return true;
	};

	const getParsedData = (hasErrors = false): IBajaStepThree => {
		return {
			items: internalPropertiesOrAccessories as IHeritageAsset[],
			timelessItems: internalPropertiesTimeless,
			hasErrors: hasErrors
		};
	};

	const onHandleSubmit = () => {
		if (!(internalPropertiesOrAccessories.length > 0)) {
			return false;
		}
		handleSubmit(getParsedData(false));
	};

	useEffect(() => {
		setSelectedProperty(deregistrationContext?.searchedItem);
	}, [deregistrationContext?.searchedItem]);

	const clearSearchPropertyInputs = () => {
		setInventaryNumber('');
		setIsAccessory(false);
		setAccessoryNumber(undefined);
	};

	const clearSearchAllInputs = (searchToo = true) => {
		if (searchToo) {
			clearSearchPropertyInputs();
		}
		setObservations(undefined);
		setToPatrimonialDependence(undefined);
		setDestiny(undefined);
	};

	const getParsedPropertyData = (hasErrors: boolean): IBajaPropertyItem | null => {
		if (hasErrors) {
			return null;
		}

		return {
			id: selectedToEdit?.id,
			deregistration_type_id: deregistrationType?.id,
			to_patrimonial_dependence_id:
				deregistrationType?.id === DeregistrationTypes.TRANSFERENCIA ? toPatrimonialDependence?.id : undefined,
			description_destiny: destiny ? destiny.trim() : undefined,
			property_id: selectedProperty?.id,
			description: observations ? observations.trim() : undefined,
			registration_timeless_id: selectedProperty.registration_timeless_id
		};
	};

	const onHandleAddProperty = async () => {
		if (!selectedProperty) {
			return;
		}
		const hasErrors = !validateForm();
		if (hasErrors) {
			return;
		}
		const result = await deregistrationContext?.onHandleAddItem(
			entity?.id ? entity?.id : null,
			getParsedPropertyData(hasErrors)
		);
		if (result) {
			clearSearchAllInputs(true);
			onHandleClearProperty();
		}
		return result;
	};

	const onHandleClearProperty = () => {
		setSelectedToEdit(undefined);
		setSelectedProperty(undefined);
		setInventaryNumber('');
		setIsAccessory(false);
		setAccessoryNumber(undefined);
		setObservations(undefined);
		setToPatrimonialDependence(undefined);
		setDestiny(undefined);
	};

	const handleEditProperty = (entity: any) => {
		if (selectedToEdit) {
			toast.error('Debe guardar los cambios del bien seleccionado');
			location.href = '#inventaryNumber';
			return;
		}
		setSelectedToEdit(entity);
		setSelectedProperty(entity?.property);
		appContext?.onHandleTryGetAllPatrimonialDependences();
		setInventaryNumber(entity?.property?.item_inventory_number ? entity?.property?.item_inventory_number : '');
		setIsAccessory(!!entity?.property?.accessory_number);
		setAccessoryNumber(entity?.property?.accessory_number ? entity?.property?.accessory_number.toString() : undefined);
		const type = deregistrationTypes.find((searched) => searched.id == entity?.deregistration_type_id);
		setDeregistrationType(type);
		setObservations(entity?.description ? entity?.description : undefined);
		setDestiny(entity?.description_destiny ? entity?.description_destiny : undefined);
	};

	const onHandleEditProperty = async () => {
		if (!selectedToEdit) {
			return;
		}
		const hasErrors = !validateForm();
		if (hasErrors) {
			return;
		}
		const result = await deregistrationContext?.onHandleEditItem(
			entity?.id ? entity?.id : null,
			getParsedPropertyData(hasErrors)
		);
		if (result) {
			onHandleClearProperty();
		}
		return result;
	};

	const [showTimelessModal, toggleTimelessModal] = useState(false);

	const handleSubmitTimelessItem = async (data: TimelessPropertyFormData) => {
		const payload = {
			id: entity?.id,
			reception_number: data.reception_number,
			is_accessory: data.is_accessory,
			inventory_number: data.inventory_number,
			accessory_number: data.accessory_number,
			account_nomenclature_id: data.nomenclador_code?.id,
			date: data.date?.toJSON(),
			quantity: Number(data.quantity),
			unit_price: Number(data.unit_price.value),
			description: data.description
		} as ITimelessProperty;

		setIsSubmittingTimelessRegistration(true);
		const result = await deregistrationContext?.onHandleAddTimelessItem(entity?.id, payload);
		setIsSubmittingTimelessRegistration(false);

		if (!result) return false;

		toggleTimelessModal(false);

		appContext?.onHandleTryGetAllPatrimonialDependences();

		setSelectedProperty({
			nomenclator_code: data.nomenclador_code?.label,
			discharge_date: result.date,
			unit_price: result.unit_price,
			registration_timeless_id: result.id
		});

		return true;
	};
	const validateInputInventory = (inputValue: any): boolean => {
		return /^\d+\/?\d*(?![\d\/])$/.test(inputValue);
	};
	const onHandleSearchProperty = async () => {
		if (!validateInputInventory(inventaryNumber))
			return toast('El número de inventario debe cumplir con el formato 999999/9999999 o 9999999', {
				type: 'error',
				autoClose: 1000
			});

		setSelectedProperty(undefined);
		clearSearchAllInputs(false);

		// const hasErrors = !validateSearchItemForm();

		// if (hasErrors) return;
		if (propertiesOrAccessories.some((item: any) => item.property?.item_inventory_number === inventaryNumber)) {
			return toast('El número de inventario ya fue registrado.', { type: 'error', autoClose: 1000 });
		}
		let inventaryNumberToUse = inventaryNumber;
		let accesoryNumberToUse = undefined;
		if (inventaryNumber.includes('/')) {
			inventaryNumberToUse = inventaryNumber.split('/')[0];
			accesoryNumberToUse = inventaryNumber.split('/')[1];
		} else {
			if (Number(inventaryNumber) == 0)
				return toast('El número de inventario no puede ser 0', { type: 'error', autoClose: 1000 });
		}
		const result = await deregistrationContext?.onHandleSearchItem(
			inventaryNumberToUse,
			accesoryNumberToUse !== undefined ? accesoryNumberToUse : null
		);

		if (!result) toggleDecideModal(true);

		return result;
	};

	const isSearchButtonDisabled = () => {
		return !inventaryNumber || selectedToEdit !== undefined;
	};

	useEffect(() => {
		if (deregistrationType !== undefined && deregistrationType.id === DeregistrationTypes.TRANSFERENCIA) {
			setToPatrimonialDependence(undefined);
			setDestiny(undefined);
		}
	}, [deregistrationType]);

	const isToPatrimonialDependenceDisabled = () => {
		if (deregistrationType == undefined) {
			return true;
		}

		if (deregistrationType.id !== DeregistrationTypes.TRANSFERENCIA) {
			return true;
		}
		return false;
	};

	const isEnableContinue = (): boolean => {
		if (internalPropertiesOrAccessories.length > 0) {
			return true;
		}
		return false;
	};

	// START Handle Cancel Modal
	const [showDecideModal, toggleDecideModal] = useState(false);
	// END Handle Cancel Modal

	// START Show Item Details
	const [showItemDetailsModal, toggleItemDetailsModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState(undefined);

	const handleShowItemDetailsModal = (entity: any | undefined) => {
		setSelectedItem(entity);
		toggleItemDetailsModal(true);
	};

	const handleHideItemDetailsmodal = () => {
		toggleItemDetailsModal(false);
		setSelectedItem(undefined);
	};
	// END Show Item Dateils

	// START Delete Item
	const [showDeleteModal, toggleDeleteModal] = useState(false);
	const [selectedItemToDelete, setSelectedItemToDelete] = useState<IHeritageAsset | undefined>(undefined);

	const handleShowDeleteModal = (entity: any) => {
		setSelectedItemToDelete(entity);
		toggleDeleteModal(true);
	};

	const handleHideDeleteModal = () => {
		setSelectedItemToDelete(undefined);
		toggleDeleteModal(false);
	};

	const handleDeleteConfirm = async () => {
		const result = await deregistrationContext?.onHandleRemoveItem(
			selectedItemToDelete?.id ? selectedItemToDelete?.id : null
		);
		if (result) {
			setInternalPropertiesOrAccessories(
				internalPropertiesOrAccessories.filter((item) => item?.id !== selectedItemToDelete?.id)
			);
			handleHideDeleteModal();
		}
	};

	useEffect(() => {
		if (propertiesOrAccessories.length === 0) setDeregistrationType(undefined);
	}, [propertiesOrAccessories]);

	return (
		<>
			{showTimelessModal && (
				<FormDialog
					show={showTimelessModal}
					title={'Alta Atemporal'}
					content={
						<TimelessPropertyOverviewForm
							inventoryNumber={inventaryNumber}
							accessoryNumber={accessoryNumber}
							isAccessory={!!accessoryNumber}
							handleCancel={() => toggleTimelessModal(false)}
							onSubmit={handleSubmitTimelessItem}
							loading={isSubmittingTimelessRegistration}
						/>
					}
					actions={<></>}
					handleClose={() => toggleTimelessModal(false)}
				/>
			)}

			{showItemDetailsModal && (
				<FormDialog
					show={showItemDetailsModal}
					title={'Detalles del Bien Patrimonial'}
					content={<SummaryItem entity={selectedItem} />}
					actions={<></>}
					handleClose={() => handleHideItemDetailsmodal()}
				/>
			)}

			{showDecideModal && (
				<CustomDecideDialog
					title={'Bien Patrimonial no encontrado'}
					message={
						'No se encuentra el N° de Inventario ingresado. \nEs posible que el Bien a dar de Baja no se encuentre registrado en el sistema. ¿Desea registrarlo?'
					}
					handleSubmit={() => {
						toggleDecideModal(false);
						toggleTimelessModal(true);
					}}
					handleDecline={() => {
						toggleDecideModal(false);
						toggleTimelessModal(false);
					}}
				/>
			)}

			{showDeleteModal && (
				<CustomDecideDialog
					title={'Remover Bien Patrimonial'}
					message={'¿Desea remover el bien patrimonial?'}
					handleSubmit={() => {
						handleDeleteConfirm();
					}}
					handleDecline={() => {
						handleHideDeleteModal();
					}}
				/>
			)}

			<div className="borderedBox">
				<Grid
					container
					spacing={2}
					pt={3}>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}>
						<Typography variant="subtitle2">{LABELS_STEP_THREE_FORM.propertyLabel}</Typography>
					</Grid>

					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}>
						<Grid
							container
							rowSpacing={2}
							sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, flexWrap: 'nowrap' }}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={6}
								xl={6}
								sx={{ display: 'flex', gap: 2 }}>
								<FormGenericInput
									value={inventaryNumber}
									label={LABELS_STEP_THREE_FORM.inventary_number}
									name="inventaryNumber"
									id="inventaryNumber"
									regexValidation={/^\d+\/?\d*(?![\d\/])$/}
									messageFormatError={'El número de inventario debe cumplir con el formato 999999/9999999 o 9999999'}
									handleChange={setInventaryNumber}
									error={errors?.inventary_number}
									size={'medium'}
									required={true}
									disabled={selectedToEdit !== undefined}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={6}
								xl={6}
								sx={{ display: 'flex', gap: 2 }}>
								<ButtonSearch
									style={{ height: '55px' }}
									disabled={isSearchButtonDisabled()}
									variant="contained"
									onClick={onHandleSearchProperty}
									endIcon={<SearchIcon />}></ButtonSearch>
							</Grid>
						</Grid>
					</Grid>

					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}>
						<Grid
							container
							spacing={2}>
							<Grid
								item
								xs={4}
								sm={4}
								md={4}
								lg={4}
								xl={4}>
								<FormGenericInput
									value={selectedProperty?.nomenclator_code ? selectedProperty?.nomenclator_code : '-'}
									label={LABELS_STEP_THREE_FORM.nomenclador_code}
									name="nomenclatorCode"
									id="nomenclatorCode"
									size={'medium'}
									required={false}
									disabled={true}
								/>
							</Grid>
							<Grid
								item
								xs={4}
								sm={4}
								md={4}
								lg={4}
								xl={4}>
								<FormGenericInput
									value={
										selectedProperty?.discharge_date
											? parsedFormatDate(selectedProperty.discharge_date)
											: selectedProperty?.date
											? parsedFormatDate(selectedProperty.date)
											: '-'
									}
									label={LABELS_STEP_THREE_FORM.date}
									name="dischargeDate"
									id="dischargeDate"
									size={'medium'}
									required={false}
									disabled={true}
								/>
							</Grid>
							<Grid
								item
								xs={4}
								sm={4}
								md={4}
								lg={4}
								xl={4}>
								<FormGenericInput
									value={selectedProperty?.unit_price ? currencyLocalFormat(selectedProperty?.unit_price) : '-'}
									label={LABELS_STEP_THREE_FORM.unit_price}
									name="unitPrice"
									id="unitPrice"
									size={'medium'}
									required={false}
									disabled={true}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid
					container
					spacing={2}
					pt={1}>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={6}
						xl={6}>
						<FormGenericSelect
							value={deregistrationType}
							label={LABELS_STEP_THREE_FORM.type_id}
							id="deregistrationType"
							options={deregistrationTypes}
							handleChange={setDeregistrationType}
							disabled={!!propertiesOrAccessories.length}
							error={errors?.type}
							required={true}
						/>
					</Grid>

					{deregistrationType?.label === 'Transferencia' && (
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							lg={6}
							xl={6}>
							<FormGenericSelect
								value={toPatrimonialDependence}
								label={LABELS_STEP_THREE_FORM.patrimonial_dependence}
								id="toPatrimonialDependence"
								options={patrimonialDependences}
								handleChange={setToPatrimonialDependence}
								//handleInputChange={debounceInputDependende}
								error={errors?.patrimonial_dependence}
								disabled={
									isToPatrimonialDependenceDisabled() ||
									Boolean(movementStatusId && movementStatusId == MovementStatusType.PENDIENTE)
								}
								required={true}
							/>
						</Grid>
					)}
					{deregistrationType?.label !== 'Transferencia' && (
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							lg={6}
							xl={6}>
							<FormGenericInput
								value={destiny}
								label={LABELS_STEP_THREE_FORM.destiny}
								name="destiny"
								id="destiny"
								handleChange={setDestiny}
								error={errors?.destiny}
								size={'medium'}
								required={false}
								disabled={!isToPatrimonialDependenceDisabled() || Boolean(movementStatusId && movementStatusId !== 1)}
							/>
						</Grid>
					)}

					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}>
						<FormGenericMultiline
							value={observations}
							label={LABELS_STEP_THREE_FORM.observations}
							name="observations"
							id="observations"
							handleChange={setObservations}
							error={errors?.observations}
							required={false}
							disabled={Boolean(movementStatusId && movementStatusId !== 1)}
						/>
					</Grid>

					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}>
						<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
							<ButtonNextPrev
								sx={{ ml: 2, mr: 2 }}
								variant="contained"
								onClick={() => {
									onHandleClearProperty();
									if (!propertiesOrAccessories.length) {
										setDeregistrationType(undefined);
									}
								}}
								disabled={!selectedToEdit && !selectedProperty}>
								{LABELS_STEP_THREE_FORM.clear_item_label}
							</ButtonNextPrev>
							{!selectedToEdit ? (
								<ButtonConfirm
									sx={{ mr: 3 }}
									variant="contained"
									onClick={() => onHandleAddProperty()}
									disabled={!selectedProperty}>
									{LABELS_STEP_THREE_FORM.add_item_label}
								</ButtonConfirm>
							) : (
								<ButtonConfirm
									sx={{ mr: 3 }}
									//ref={editElementRef}
									variant="contained"
									onClick={() => onHandleEditProperty()}
									disabled={!selectedToEdit}>
									{LABELS_STEP_THREE_FORM.edit_item_label}
								</ButtonConfirm>
							)}
						</Box>
					</Grid>

					<Typography
						variant="subtitle2"
						style={{ width: '100%', paddingLeft: '10px' }}
						mt={1}>{`(*) Campos Obligatorios.`}</Typography>
				</Grid>

				<Box>
					<hr />
					<Typography
						sx={{ pb: 1, pt: 1 }}
						variant="h6">
						Bienes a dar de Baja
					</Typography>
					<HeritageAssetsTable
						elements={
							propertiesOrAccessories.map((item) => {
								return {
									property: {
										...item.property,
										id: item.id,
										unit_price: item.property?.unit_price,
										item_inventory_number: item.property?.item_inventory_number,
										accessory_number: item.property?.accessory_number,
										account_nomenclature: { account: item.property?.account_nomenclature.account },
										nomenclature_description: item.property?.account_nomenclature.sub_group_description
									},
									...item
								} as {
									property: {
										id: number;
										unit_price: string;
										item_inventory_number: string;
										accessory_number: string;
										account_nomenclature: { account: string };
										nomenclature_description: string;
										[k: string]: unknown;
									};
									[k: string]: unknown;
								};
							}) as unknown as IHeritageAsset[]
						}
						handleShow={(entity: IHeritageAsset) => {
							handleShowItemDetailsModal(entity);
						}}
						handleDelete={(entity: IHeritageAsset) => {
							handleShowDeleteModal(entity.property);
						}}
						handleEdit={(entity: IHeritageAsset) => {
							handleEditProperty(entity);
						}}
					/>
				</Box>

				<Box
					sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
					<ButtonDecline
						sx={{ mr: 1 }}
						variant="contained"
						onClick={() => {
							handleCancel(false);
						}}>
						{buttonLabelCancel}
					</ButtonDecline>

					<ButtonNextPrev
						sx={{ ml: 2, mr: 2 }}
						variant="contained"
						onClick={handleBack}>
						{buttonLabelBack}
					</ButtonNextPrev>

					<ButtonConfirm
						sx={{ ml: 1 }}
						variant="contained"
						disabled={!isEnableContinue() || propertiesOrAccessories.length === 0}
						onClick={() => onHandleSubmit()}>
						{buttonLabelSubmit}
					</ButtonConfirm>
				</Box>
			</div>
		</>
	);
};
