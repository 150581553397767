/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { NomenclatureCodeInput } from 'components/globals/NomenclatureCodeInput/NomenclatureCodeInput';
import { FormInputUnitPrice } from 'components/globals/inputs/FormInputUnitPrice';
import { NumberFormatValues } from 'react-number-format';
import { FormGenericInput } from 'components/globals/inputs/FormGenericInput';
import { FormGenericMultiline } from 'components/globals/inputs/FormGenericMultiline';
import { NomenclatureCode } from 'services/nomenclatureCodes';
import { FormGenericSelect } from 'components/globals/inputs/FormGenericSelect';
import { ISelectOption } from 'interfaces/commons/IParameters';
import { Department, getAllDepartments } from 'services/departments';
import { Switch } from 'components/globals/switch/Switch';
import { FileAttachementManager } from 'components/globals/inputs/FileManager';
import { Attachment } from 'interfaces/file/IFile';
import { Spinner } from 'components/globals/spinner/Spinner';
import { toast } from 'react-toastify';

interface InmuebleFormProps {
	onChange?(data: InmuebleFormData): void;
	onSubmit?(data: InmuebleFormData): Promise<boolean | void>;
	onReset?: CallableFunction;
	onSuccess?: CallableFunction;
	value?: InmuebleFormData;
	loading?: boolean;
	submitBtnLabel?: string;
	readonly?: boolean;
	filesPath: string;
	attachments: Attachment[];
	customInputDisable?: {
		[K in keyof InmuebleFormData]?: {
			[key in keyof InmuebleFormData[K]]?: boolean;
		};
	};
	isAccessory?: boolean;
	showRowNumberField?: boolean;
}
export interface InmuebleFormData {
	general: {
		nomenclatureCode?: NomenclatureCode;
		quantity: string;
		unitPrice: NumberFormatValues;
		rowNumber: string;
	};
	cadastre: {
		department?: ISelectOption;
		municipality?: ISelectOption;
		municipio?: ISelectOption;
		section: string;
		parcel: string;
		ranch: string;
		block: string;
		functionalUnit: string;
		realEstateItem: string;
		planeNumber: string;
	};
	registry: {
		department: string;
		registrationNumber: string;
		tome: string;
		invoice: string;
		farm: string;
		owner: string;
		propertyDescription: string;
		observations: string;
	};
	ownership: {
		isNameOfPatrimonialDependency: boolean;
		propertyTitle: File[];
		hasPropertyTitle: boolean;
		reason: string;
	};
	address: {
		value: string;
	};
}

const gridContainerProps = {
	spacing: 2,
	container: true,
	pt: 2
};

const gridItemProps = {
	item: true,
	xs: 12,
	sm: 12,
	md: 12,
	lg: 6,
	xl: 6
};

const defaultFormData: InmuebleFormData = {
	general: {
		nomenclatureCode: undefined,
		quantity: '1',
		unitPrice: { value: '', formattedValue: '', floatValue: 0 },
		rowNumber: ''
	},
	cadastre: {
		department: undefined,
		municipality: undefined,
		section: '',
		parcel: '',
		ranch: '',
		block: '',
		functionalUnit: '',
		realEstateItem: '',
		planeNumber: ''
	},
	registry: {
		department: '',
		registrationNumber: '',
		tome: '',
		invoice: '',
		farm: '',
		owner: '',
		propertyDescription: '',
		observations: ''
	},
	ownership: {
		isNameOfPatrimonialDependency: false,
		hasPropertyTitle: false,
		reason: '',
		propertyTitle: []
	},
	address: {
		value: ''
	}
};

const isFullfiled = (obj: Record<string, unknown>) => {
	return Object.values(obj).every((val) => val);
};

function isValidFormData(data: InmuebleFormData) {
	const {
		general: { nomenclatureCode, unitPrice },
		cadastre: { functionalUnit, planeNumber, ...restCadestre },
		address
	} = data;

	return isFullfiled(restCadestre) && isFullfiled(address) && isFullfiled({ nomenclatureCode }) && unitPrice.value;
}

const InmuebleForm: React.FC<InmuebleFormProps> = ({
	onSubmit,
	onChange,
	loading,
	submitBtnLabel,
	value,
	readonly,
	attachments,
	onSuccess,
	isAccessory,
	showRowNumberField
}: InmuebleFormProps) => {
	const [formData, setFormData] = useState<InmuebleFormData>(structuredClone(defaultFormData));
	const [departments, setDepartments] = useState<ISelectOption[]>([]);
	const [departmentsData, setDepartmentsData] = useState<Department[]>([]);
	const [localities, setLocalities] = useState<ISelectOption[]>([]);

	const isSubmitDisabled = useMemo(() => {
		const rowNum = showRowNumberField ? Number(formData.general.rowNumber) : true;

		if (isAccessory) {
			const {
				general: { nomenclatureCode, unitPrice }
			} = formData;

			return !isFullfiled({ nomenclatureCode }) || !unitPrice.value;
		}

		return !isValidFormData(formData);
	}, [formData]);

	function handleFieldChange<K extends keyof InmuebleFormData>(name: K, subName: keyof InmuebleFormData[K]) {
		return (value: unknown) => {
			setFormData((curr) => ({
				...curr,
				[name]: {
					...curr[name],
					[subName]: value
				}
			}));
			onChange && onChange(formData);
		};
	}

	const handleSubmit = async () => {
		if (!onSubmit) return;

		if (formData.general.unitPrice?.floatValue !== undefined && formData.general.unitPrice?.floatValue === 0) {
			return toast('El precio unitario no puede ser 0', { type: 'error' });
		}
		if (formData.general.unitPrice?.floatValue !== undefined && formData.general.unitPrice?.floatValue < 0) {
			return toast('El precio unitario no puede ser negativo', { type: 'error' });
		}
		if (formData.general.unitPrice?.floatValue !== undefined && formData.general.unitPrice?.floatValue > 99999999.99) {
			return toast('El precio unitario no puede ser mayor a 99999999.99', { type: 'error' });
		}
		const shouldResetForm = await onSubmit(formData);

		if (shouldResetForm) {
			setFormData(defaultFormData);
			onSuccess && onSuccess();
		}
	};

	const fetchDepartments = async () => {
		const data = await getAllDepartments();

		setDepartmentsData(data);

		setDepartments(
			data.map((item) => {
				return {
					id: item.id,
					label: item.name,
					data: item
				};
			})
		);
	};

	useEffect(() => {
		void fetchDepartments();
		value && setFormData(value);
	}, []);

	useEffect(() => {
		const selected = formData.cadastre.department;
		setLocalities([]);

		if (selected) {
			const department = departmentsData.find((el) => el.id === selected.id);

			if (department) {
				setLocalities(department.locations.map((item) => ({ id: item.id, label: item.name, data: item })));
			}
		}
	}, [formData.cadastre.department, departmentsData]);

	useEffect(() => {
		if (!formData.ownership.hasPropertyTitle) {
			formData.ownership.propertyTitle = [];
		}
	}, [formData.ownership.hasPropertyTitle]);

	return (
		<Grid
			{...gridContainerProps}
			spacing={4}>
			{/* General */}
			<Grid
				item
				width="100%">
				<Grid item>
					<Typography
						variant="h1"
						fontSize={24}
						fontWeight={500}
						borderBottom="1px solid"
						borderColor="primary"
						pb={1}
						mb={2}>
						Datos Generales
					</Typography>
				</Grid>

				<Grid
					item
					width={'100%'}>
					<NomenclatureCodeInput
						disabled={readonly}
						value={formData.general.nomenclatureCode}
						onChange={handleFieldChange('general', 'nomenclatureCode')}
						spacing={4}
						propertyTypeId={3}
					/>
				</Grid>

				<Grid
					{...gridContainerProps}
					spacing={4}>
					<Grid {...gridItemProps}>
						<FormGenericInput
							disabled
							size="small"
							type="number"
							label="Cantidad"
							value="1"
						/>
					</Grid>

					<Grid {...gridItemProps}>
						<FormInputUnitPrice
							disabled={readonly}
							handleChange={handleFieldChange('general', 'unitPrice')}
							id="price-input"
							name="unit-price-input"
							label="Precio Unitario"
							value={formData.general.unitPrice}
							size="small"
						/>
					</Grid>

					{showRowNumberField ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								disabled={readonly}
								handleChange={handleFieldChange('general', 'rowNumber')}
								size="small"
								type="number"
								label="Renglón"
								value={formData.general.rowNumber}
							/>
						</Grid>
					) : null}
				</Grid>
			</Grid>

			{!isAccessory && !readonly ? (
				<>
					{/* Direction */}
					<Grid
						item
						width={'100%'}>
						<Grid
							item
							width={'100%'}>
							<Typography
								variant="h1"
								fontSize={24}
								fontWeight={500}
								borderBottom="1px solid"
								borderColor="primary"
								pb={1}>
								Dirección del Inmueble
							</Typography>
						</Grid>

						<Grid
							width="100%"
							mt={2}>
							<FormGenericInput
								disabled={readonly}
								size="small"
								required
								name="address"
								id="address-input"
								type="text"
								label="Dirección"
								value={formData.address.value}
								handleChange={handleFieldChange('address', 'value')}
							/>
						</Grid>
					</Grid>

					{/* Cadestre */}
					<Grid
						item
						width={'50%'}>
						<Grid item>
							<Typography
								variant="h1"
								fontSize={24}
								fontWeight={500}
								borderBottom="1px solid"
								borderColor="primary"
								pb={1}>
								Información de Catastro
							</Typography>
						</Grid>

						<Grid {...gridContainerProps}>
							<Grid {...gridItemProps}>
								<FormGenericSelect
									disabled={readonly}
									handleChange={(value) => {
										handleFieldChange('cadastre', 'department')(value);
										handleFieldChange('cadastre', 'municipality')(undefined);
									}}
									id="departament"
									label="Departamento"
									options={departments}
									size="small"
									value={formData.cadastre.department}
								/>
							</Grid>

							<Grid {...gridItemProps}>
								<FormGenericSelect
									disabled={readonly}
									handleChange={handleFieldChange('cadastre', 'municipality')}
									id="municipio"
									label="Municipio"
									options={localities}
									size="small"
									value={formData.cadastre.municipality}
								/>
							</Grid>
						</Grid>

						<Grid {...gridContainerProps}>
							<Grid
								{...gridItemProps}
								xl={4}
								lg={4}>
								<FormGenericInput
									disabled={readonly}
									size="small"
									handleChange={handleFieldChange('cadastre', 'section')}
									name="section"
									required
									label="Sección"
									value={formData.cadastre.section}
								/>
							</Grid>

							<Grid
								{...gridItemProps}
								xl={4}
								lg={4}>
								<FormGenericInput
									disabled={readonly}
									size="small"
									handleChange={handleFieldChange('cadastre', 'parcel')}
									name="parcela"
									required
									label="Parcela"
									type="number"
									value={formData.cadastre.parcel}
								/>
							</Grid>

							<Grid
								{...gridItemProps}
								xl={4}
								lg={4}>
								<FormGenericInput
									disabled={readonly}
									size="small"
									handleChange={handleFieldChange('cadastre', 'ranch')}
									name="ranch"
									required
									label="Chacra"
									type="number"
									value={formData.cadastre.ranch}
								/>
							</Grid>
						</Grid>

						<Grid {...gridContainerProps}>
							<Grid
								{...gridItemProps}
								xl={4}
								lg={4}>
								<FormGenericInput
									disabled={readonly}
									size="small"
									handleChange={handleFieldChange('cadastre', 'block')}
									name="block"
									required
									label="Manzana"
									type="number"
									value={formData.cadastre.block}
								/>
							</Grid>

							<Grid
								{...gridItemProps}
								xl={4}
								lg={4}>
								<FormGenericInput
									disabled={readonly}
									size="small"
									handleChange={handleFieldChange('cadastre', 'functionalUnit')}
									name="functionalUnit"
									label="Unidad Funcional"
									type="number"
									value={formData.cadastre.functionalUnit}
								/>
							</Grid>

							<Grid
								{...gridItemProps}
								xl={4}
								lg={4}>
								<FormGenericInput
									disabled={readonly}
									size="small"
									handleChange={handleFieldChange('cadastre', 'planeNumber')}
									name="planeNumber"
									label="Número de Plano"
									value={formData.cadastre.planeNumber}
									type="number"
								/>
							</Grid>
						</Grid>

						<Grid {...gridContainerProps}>
							<Grid
								{...gridItemProps}
								width="100%"
								xl={12}
								lg={12}>
								<FormGenericInput
									disabled={readonly}
									size="small"
									handleChange={handleFieldChange('cadastre', 'realEstateItem')}
									name="realStateItem"
									required
									label="Partida Inmobiliaria"
									value={formData.cadastre.realEstateItem}
									type="number"
								/>
							</Grid>
						</Grid>

						<Grid
							{...gridContainerProps}
							mt={2}>
							<Grid
								item
								width="100%">
								<Typography
									variant="h1"
									fontSize={24}
									fontWeight={500}
									borderBottom="1px solid"
									borderColor="primary"
									pb={1}>
									Títulos
								</Typography>
							</Grid>

							<Grid
								{...gridItemProps}
								xl={6}
								lg={6}
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'flex-end'
								}}>
								<Typography
									variant="h1"
									fontSize={16}
									fontWeight={500}
									mb={2}
									height={24}
									color="info">
									¿Posee Título de Propiedad?
								</Typography>

								<Box>
									<Switch
										disabled={readonly}
										onChange={handleFieldChange('ownership', 'hasPropertyTitle')}
										value={formData.ownership.hasPropertyTitle}
									/>
								</Box>

								<Box mt={2}>
									{formData.ownership.hasPropertyTitle ? (
										<FileAttachementManager
											disabled={readonly}
											readonly={readonly}
											disableUploadAction
											allowsMultiple
											size="small"
											attachments={attachments}
											value={formData.ownership.propertyTitle}
											onChange={handleFieldChange('ownership', 'propertyTitle')}
											routeToDelete="/properties-files"
											fieldName="attachments"
										/>
									) : (
										<FormGenericMultiline
											disabled={readonly}
											placeholder="Indique el Motivo"
											required={false}
											handleChange={handleFieldChange('ownership', 'reason')}
											label="Motivo"
											limit={256}
											value={formData.ownership.reason}
										/>
									)}
								</Box>
							</Grid>

							<Grid
								{...gridItemProps}
								xl={6}
								lg={6}>
								<Typography
									variant="h1"
									fontSize={16}
									fontWeight={500}
									mb={2}
									height={24}
									color="info">
									¿Es a nombre de la Dependencia Patrimonial?
								</Typography>

								<Box>
									<Switch
										disabled={readonly}
										value={formData.ownership.isNameOfPatrimonialDependency}
										onChange={handleFieldChange('ownership', 'isNameOfPatrimonialDependency')}
									/>
								</Box>
							</Grid>
						</Grid>
					</Grid>

					{/* Registry */}
					<Grid
						item
						width={'50%'}>
						<Grid
							item
							width={'100%'}>
							<Typography
								variant="h1"
								fontSize={24}
								fontWeight={500}
								borderBottom="1px solid"
								borderColor="primary"
								pb={1}>
								Información Registral
							</Typography>
						</Grid>

						<Grid {...gridContainerProps}>
							<Grid
								{...gridItemProps}
								xl={4}
								lg={4}>
								<FormGenericInput
									disabled={readonly}
									size="small"
									handleChange={handleFieldChange('registry', 'department')}
									name="departamento"
									label="Departamento"
									value={formData.registry.department}
								/>
							</Grid>

							<Grid
								{...gridItemProps}
								xl={4}
								lg={4}>
								<FormGenericInput
									disabled={readonly}
									size="small"
									handleChange={handleFieldChange('registry', 'registrationNumber')}
									name="nro-matricula"
									label="Número de Matrícula"
									value={formData.registry.registrationNumber}
								/>
							</Grid>

							<Grid
								{...gridItemProps}
								xl={4}
								lg={4}>
								<FormGenericInput
									disabled={readonly}
									size="small"
									handleChange={handleFieldChange('registry', 'tome')}
									name="tomo"
									label="Tomo"
									value={formData.registry.tome}
								/>
							</Grid>
						</Grid>

						<Grid {...gridContainerProps}>
							<Grid {...gridItemProps}>
								<FormGenericInput
									disabled={readonly}
									size="small"
									handleChange={handleFieldChange('registry', 'invoice')}
									name="folio"
									label="Folio"
									value={formData.registry.invoice}
								/>
							</Grid>

							<Grid {...gridItemProps}>
								<FormGenericInput
									disabled={readonly}
									size="small"
									handleChange={handleFieldChange('registry', 'farm')}
									name="Finca"
									label="Finca"
									value={formData.registry.farm}
								/>
							</Grid>
						</Grid>

						<Grid {...gridContainerProps}>
							<Grid
								item
								width={'100%'}
								pt={2}>
								<FormGenericInput
									disabled={readonly}
									size="small"
									handleChange={handleFieldChange('registry', 'owner')}
									name="owner"
									label="Propietario"
									value={formData.registry.owner}
								/>
							</Grid>

							<Grid {...gridItemProps}>
								<FormGenericMultiline
									required={false}
									disabled={readonly}
									handleChange={handleFieldChange('registry', 'propertyDescription')}
									name="description"
									id="description"
									label="Descripción del inmueble"
									limit={256}
									value={formData.registry.propertyDescription}
								/>
							</Grid>

							<Grid {...gridItemProps}>
								<FormGenericMultiline
									required={false}
									disabled={readonly}
									handleChange={handleFieldChange('registry', 'observations')}
									name="observations"
									id="observations"
									label="Observaciones"
									limit={256}
									value={formData.registry.observations}
								/>
							</Grid>
						</Grid>
					</Grid>
				</>
			) : null}
			{loading && <Spinner />}
			{!readonly ? (
				<Grid
					item
					width={'100%'}>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							disabled={isSubmitDisabled || loading}
							variant="contained"
							onClick={handleSubmit}>
							{submitBtnLabel || 'Agregar Bien'}
						</Button>
					</Box>
				</Grid>
			) : null}
		</Grid>
	);
};

export default InmuebleForm;
