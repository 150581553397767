/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import { useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ButtonConfirm, ButtonDecline, ButtonNextPrev } from 'components/globals/customsBtn/customBtn';
import { IAltaInstrument, IAltaProcedure, IAltaProvisionOrder, IAltaStepTwo } from 'interfaces/alta/IAlta';
import { ILegalInstrument, LegalInstrumentForm } from 'components/globals/legalsInstrument/legalInstrument';
import { ExpedientForm, IExpedient } from 'components/globals/expedients/expedient';
import { IProvisionOrder, ProvisionOrderForm } from 'components/globals/provisionOrder/ProvisionOrder';
import { FileAttachementManager } from 'components/globals/inputs/FileManager';
import { getFilesByDocumentId } from 'services/altaService';
import { Attachment } from 'interfaces/file/IFile';
import {
	ContractTypeSelect,
	contractTypesOptions,
	defaultContratTypeOption
} from 'components/globals/ContracTypeSelect/ContractTypeSelect';
import { REGISTER_TYPES_ID } from 'enums';
import { ISelectOption } from 'interfaces/commons/IParameters';

export const LABELS_STEP_TWO_FORM = {
	procedure: '',
	procedure_agency_code: 'Código de Organismo',
	procedure_number: 'Número',
	procedure_year: 'Año',
	instrument: 'Instrumento Legal',
	instrument_number: 'Número',
	instrument_year: 'Año',
	date_legal_instrument: 'Fecha del Instrumento Legal',
	date_delivery_certificate: 'Fecha de Acta *'
};

export interface IOverviewAltaStepTwoForm {
	entity?: IAltaStepTwo;
	handleSubmit(data: IAltaStepTwo): void;
	handleCancel(hasPendingData: boolean): void;
	handleBack(): void;
	buttonLabelCancel?: string;
	buttonLabelSubmit?: string;
	buttonLabelBack?: string;
}

export const AltaStepTwoForm = ({
	entity,
	handleSubmit,
	handleCancel,
	handleBack,
	buttonLabelCancel = 'Cancelar',
	buttonLabelSubmit = 'Siguiente',
	buttonLabelBack = 'Atrás'
}: IOverviewAltaStepTwoForm) => {
	// Expediente de contratación
	const [procedureAgencyCode, setProcedureAgencyCode] = useState<string | undefined>();
	const [procedureNumber, setProcedureNumber] = useState<string | undefined>();
	const [procedureYear, setProcedureYear] = useState<string | undefined>();
	// Instrumento Legal
	const [instrumentNumber, setInstrumentNumber] = useState<string | undefined>();
	const [instrumentYear, setInstrumentYear] = useState<string | undefined>();
	const [instrumentType, setInstrumentType] = useState<string>('');
	const [instrumentFiles, setInstrumentFiles] = useState<File[] | undefined>();
	// Orden de Provisión
	const [provisionNumber, setProvisionNumber] = useState<string | undefined>();
	const [provisionYear, setProvisionYear] = useState<string | undefined>();
	// Decreto, Resolución o Disposición
	const [selectedFiles, setSelectedFiles] = useState<Attachment[]>([]);
	const [contractType, setContractType] = useState<ISelectOption>(defaultContratTypeOption);

	const [hasErrors, setHasErros] = useState<boolean>(false);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [formErrors, setFormErrors] = useState({
		instrumentNumber: false,
		instrumentYear: false
	});

	useEffect(() => {
		if (entity) {
			if (entity?.id !== undefined && entity.id > 0) {
				setIsEdit(true);
			}

			if (entity.procedure) {
				// Expediente de contratación
				setProcedureAgencyCode(entity.procedure?.agency_code || undefined);
				setProcedureNumber(entity.procedure?.number || undefined);
				setProcedureYear(entity.procedure?.year || undefined);
			}

			if (entity.instrument) {
				// Instrumento Legal
				setInstrumentNumber(entity.instrument?.instrument_number || undefined);
				setInstrumentYear(entity.instrument?.instrument_year || undefined);
				setInstrumentType(entity.instrument.instrument_type || '');
			}

			if (entity.provision) {
				// Orden de Provisión
				setProvisionNumber(entity.provision.provision_number || undefined);
				setProvisionYear(entity.provision.provision_year || undefined);
			}

			if (entity.contract_type) {
				setContractType(
					contractTypesOptions.find((opt) => opt.label === entity.contract_type) ?? defaultContratTypeOption
				);
			}
		}
	}, [entity]);

	const softValidateForm = (): boolean => {
		return true;
	};

	const stepOneData = JSON.parse(localStorage.getItem('STORAGED_REGISTER_STEP_ONE') || '{}');

	const getParsedData = (hasErrors = false): IAltaStepTwo => {
		return {
			id: entity?.id,
			high_type_id: stepOneData.high_type_id,
			procedure: {
				agency_code: procedureAgencyCode,
				number: procedureNumber,
				year: procedureYear
			} as IAltaProcedure,
			instrument: {
				instrument_number: instrumentNumber,
				instrument_year: instrumentYear,
				instrument_type: instrumentType
			} as IAltaInstrument,
			provision: {
				provision_number: provisionNumber,
				provision_year: provisionYear
			} as IAltaProvisionOrder,
			files: instrumentFiles,
			hasErrors: hasErrors,
			contract_type: contractType.label
		} as IAltaStepTwo;
	};

	const onHandleSubmit = (): void => {
		const hasErrors = softValidateForm();
		const parsedDataToSend = getParsedData(!hasErrors);
		handleSubmit(parsedDataToSend);
	};

	const isSubmitEnabled = useMemo(() => {
		const hasValues = [
			instrumentYear,
			instrumentType,
			instrumentNumber,
			procedureAgencyCode,
			procedureNumber,
			procedureYear,
			stepOneData?.high_type_id === REGISTER_TYPES_ID.ACQUISITION ? contractType.id : true
		].every((val) => val);
		const hasNoError = Object.values(formErrors).every((error) => !error);
		return hasValues && hasNoError;
	}, [
		procedureAgencyCode,
		procedureNumber,
		procedureYear,
		instrumentNumber,
		instrumentYear,
		instrumentType,
		contractType
	]);

	const fetchFiles = async () => {
		if (!entity?.id) return;

		const data = await getFilesByDocumentId(entity?.id as number);
		setSelectedFiles(data.filter((el) => el.file_type_id === 1));
	};

	useEffect(() => void fetchFiles(), []);

	return (
		<div className="borderedBox">
			<Grid
				container
				spacing={2}
				pt={3}>
				<Grid
					item
					xs={12}
					sm={12}
					md={4}
					lg={4}
					xl={4}>
					<ExpedientForm
						title={'Expediente'}
						agencyCode={procedureAgencyCode || undefined}
						number={procedureNumber || undefined}
						year={procedureYear || undefined}
						handleChange={(data: IExpedient) => {
							setProcedureAgencyCode(data.agency_code);
							setProcedureNumber(data.number);
							setProcedureYear(data.year);
							setHasErros(data.hasErrors);
						}}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					md={4}
					lg={4}
					xl={4}>
					<LegalInstrumentForm
						validateInstrumentNumber
						validateYear
						type={instrumentType}
						number={instrumentNumber || undefined}
						year={instrumentYear || undefined}
						handleChange={(data: ILegalInstrument) => {
							setInstrumentNumber(data.number);
							setInstrumentYear(data.year);
							setInstrumentType(data.type || '');
							setHasErros(data.hasErrors);
						}}
						onError={(instrumentErrors) => {
							setFormErrors((curr) => ({
								...curr,
								instrumentNumber: !!instrumentErrors.number,
								instrumentYear: !!instrumentErrors.year
							}));
						}}
					/>
				</Grid>

				{stepOneData.high_type_id === REGISTER_TYPES_ID.ACQUISITION ? (
					<Grid
						item
						xs={12}
						sm={12}
						md={4}
						lg={4}
						xl={4}>
						<ProvisionOrderForm
							number={provisionNumber}
							year={provisionYear}
							handleChange={(data: IProvisionOrder) => {
								setProvisionNumber(data.number);
								setProvisionYear(data.year);
								setHasErros(data.hasErrors);
							}}
						/>
					</Grid>
				) : null}

				{stepOneData.high_type_id === REGISTER_TYPES_ID.ACQUISITION ? (
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}>
						<Typography
							mb={2}
							variant="subtitle2">
							Tipo de Contratación
						</Typography>
						<ContractTypeSelect
							value={contractType}
							onChange={(val) => {
								setContractType(val as ISelectOption);
							}}
							showLabel={false}
						/>
					</Grid>
				) : null}

				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}>
					<FileAttachementManager
						disableUploadAction
						attachments={selectedFiles}
						label="Adjuntar Decreto, Resolución o Disposición"
						routeToDelete="/property-registration-files"
						onDeleteSuccess={fetchFiles}
						onChange={setInstrumentFiles}
						fieldName="attachments"
						allowsMultiple
					/>
				</Grid>
			</Grid>

			<Typography
				variant="subtitle2"
				style={{ width: '100%' }}
				pl={1}
				mt={1}>{`(*) Campos Obligatorios.`}</Typography>

			<Box
				sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
				<ButtonDecline
					sx={{ mr: 1 }}
					variant="contained"
					onClick={() => {
						handleCancel(hasErrors);
					}}>
					{buttonLabelCancel}
				</ButtonDecline>

				<ButtonNextPrev
					sx={{ ml: 2, mr: 2 }}
					variant="contained"
					onClick={handleBack}>
					{buttonLabelBack}
				</ButtonNextPrev>

				<ButtonConfirm
					sx={{ ml: 1 }}
					variant="contained"
					disabled={stepOneData?.high_type_id === REGISTER_TYPES_ID.ACQUISITION ? false : !isSubmitEnabled}
					onClick={() => onHandleSubmit()}>
					{buttonLabelSubmit}
				</ButtonConfirm>
			</Box>
		</div>
	);
};
