import { useState, useEffect, SetStateAction, Dispatch, memo } from 'react';
import { InputGeneric } from 'components/globals/inputs/InputGeneric';
import { InputAttachGenForEdit } from '../../inputAttachGenForEdit/InputAttachGenForEdit';
import { IStep3VehicleValues } from '../../interfaces';
import { ValidateMotovehiculo } from '../validateVehicle';
import useDebounce from 'hooks/useDebounce';
import { toast } from 'react-toastify';
import { useMovContext } from 'hooks/useMovContext';
import { Typography, Button, Box } from '@mui/material';

export interface IPaso3MotovehiculoProps {
	memoizedDataFromChild: (data: SetStateAction<IStep3VehicleValues>) => void;
	setAttachmentFromChild: Dispatch<SetStateAction<File | null>>;
	setUuid: Dispatch<SetStateAction<string>>;
	uuid: string;
	submited: boolean;
	setSubmited: Dispatch<SetStateAction<boolean>>;
}

const Motovehiculo = memo(
	({
		setUuid,
		submited,
		setSubmited,
		memoizedDataFromChild,
		setAttachmentFromChild,
		uuid
	}: IPaso3MotovehiculoProps) => {
		const movContext = useMovContext();
		const [genericList, setGenericList] = useState<IStep3VehicleValues>({
			vehicleType: 'Motovehículos'
		} as IStep3VehicleValues);
		const [bikeFrameNumber, setbikeFrameNumber] = useState(movContext?.itemSelected?.bikeFrameNumber || '');
		const [domainRegistartion, setDomainRegistration] = useState(movContext?.itemSelected?.domainRegistartion || '');
		const [engineNumber, setengineNumber] = useState(movContext?.itemSelected?.engineNumber || '');
		const [brand, setMarca] = useState(movContext?.itemSelected?.brand || '');
		const [model, setModelo] = useState(movContext?.itemSelected?.model || '');
		const [year, setAnio] = useState(movContext?.itemSelected?.year || '');
		const [origin, setOrigen] = useState(movContext?.itemSelected?.origin || '');
		const [owner, setTitular] = useState(movContext?.itemSelected?.owner || '');
		const [type, setTipo] = useState(movContext?.itemSelected?.type || '');
		const [propertyTitle, setTituloPropiedad] = useState<File | null>(null);
		const debouncedValue = useDebounce(genericList, 400);

		// Modal edit
		const [editProTitle, setEditProTitle] = useState(false);

		useEffect(() => {
			if (movContext?.itemSelected === null) {
				setEditProTitle(false);
				return;
			}
			const editMovement = movContext?.editMovement;
			let hasAttachmentInDB = false;
			let hasAttachmentInLocal = false;
			if (editMovement) {
				// Check in context
				const attachmentsInContext = movContext?.attachments;
				if (attachmentsInContext && attachmentsInContext?.length > 0) {
					const searchAttachment = attachmentsInContext.find(
						(attachment) => attachment?.id === movContext?.itemSelected?.id
					);
					if (searchAttachment && searchAttachment?.operation === 'create' && movContext?.itemSelected !== null) {
						hasAttachmentInLocal = true;
					}
					if (searchAttachment && searchAttachment?.operation === 'delete' && movContext?.itemSelected !== null) {
						hasAttachmentInLocal = false;
					}
				}
				// Check the movement first.
				const files = editMovement?.property_registration?.files;
				if (files && files?.length > 0) {
					const searchPropertyTitle = files.find((file) => file?.temporary_id === movContext?.itemSelected?.id);
					if (!searchPropertyTitle) {
						hasAttachmentInDB = false;
					} else {
						hasAttachmentInDB = true;
					}
				} else {
					hasAttachmentInDB = false;
				}
				setEditProTitle(hasAttachmentInDB || hasAttachmentInLocal);
			}
		}, []);

		const handleAdd = (evt: any) => {
			evt.preventDefault();
			if (evt.target.name === 'adjunto') {
				setGenericList({ ...genericList, [evt.target.name]: evt.target.files[0] as File });
			}
			setGenericList({ ...genericList, [evt.target.name]: evt.target.value });
		};

		useEffect(() => {
			const valid = ValidateMotovehiculo(genericList as any);
			valid ? memoizedDataFromChild(genericList) : memoizedDataFromChild({} as IStep3VehicleValues);
		}, [debouncedValue]);

		useEffect(() => {
			if (propertyTitle?.name) {
				setAttachmentFromChild(propertyTitle);
			}
		}, [propertyTitle]);

		useEffect(() => {
			if (submited) {
				setDomainRegistration('');
				setengineNumber('');
				setbikeFrameNumber('');
				setMarca('');
				setModelo('');
				setAnio('');
				setOrigen('');
				setTitular('');
				setTipo('');
				setTituloPropiedad({} as File);
				setGenericList({ vehicleType: 'Motovehículos' } as IStep3VehicleValues);
			}
		}, [submited]);

		useEffect(() => {
			setGenericList({
				domainRegistartion,
				engineNumber,
				bikeFrameNumber,
				brand,
				model,
				year,
				origin,
				owner,
				type,
				vehicleType: 'Motovehículos'
			});
			const valid = ValidateMotovehiculo(genericList as any);
			valid ? memoizedDataFromChild(genericList) : memoizedDataFromChild({} as IStep3VehicleValues);
		}, [movContext?.itemSelected]);

		const handleDeleteAttachment = () => {
			let hasAttachmentInDB = false;

			const editMovement = movContext?.editMovement;
			const files = editMovement?.property_registration?.files;
			if (files && files?.length > 0) {
				const searchPropertyTitle = files.find((file) => file?.temporary_id === movContext?.itemSelected?.id);
				if (!searchPropertyTitle) {
					hasAttachmentInDB = false;
				} else {
					hasAttachmentInDB = true;
				}
			} else {
				hasAttachmentInDB = false;
			}
			///////
			const attachmentsInContext = movContext?.attachments;
			if (attachmentsInContext && attachmentsInContext?.length >= 0) {
				const attachments = attachmentsInContext.filter(
					(attachment) => attachment?.id !== movContext?.itemSelected?.id
				);
				if (hasAttachmentInDB) {
					attachments.push({ id: movContext?.itemSelected?.id, operation: 'delete' });
				} else {
					movContext?.setAttachments(attachments);
				}
			}
			toast.success('El titulo será eliminado después de guardar el movimiento');
			setEditProTitle(false);
		};

		return (
			<div
				onChange={handleAdd}
				style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '55px', rowGap: '55px' }}>
				<InputGeneric
					stateValue={domainRegistartion}
					setStateValue={setDomainRegistration}
					label={'Dominio/Matricula'}
					name={'domainRegistartion'}
					required={false}
					pattern={/^[a-zA-Z0-9-]*$/}
				/>
				<InputGeneric
					stateValue={engineNumber}
					setStateValue={setengineNumber}
					label={'Nro. de Motor'}
					name={'engineNumber'}
					required={true}
				/>
				<InputGeneric
					stateValue={bikeFrameNumber}
					setStateValue={setbikeFrameNumber}
					label={'Nro. de Cuadro'}
					name={'bikeFrameNumber'}
					required={true}
				/>
				<InputGeneric
					stateValue={brand}
					setStateValue={setMarca}
					label={'Marca'}
					name={'brand'}
					required={true}
					pattern={/^[a-zA-Z\s]*$/}
				/>
				<InputGeneric
					stateValue={model}
					setStateValue={setModelo}
					label={'Modelo'}
					name={'model'}
					required={true}
				/>
				<InputGeneric
					stateValue={year}
					setStateValue={setAnio}
					label={'Año'}
					name={'year'}
					pattern={/^[0-9]{0,4}$/}
				/>
				<InputGeneric
					stateValue={origin}
					setStateValue={setOrigen}
					label={'Origen/Procedencia'}
					name={'origin'}
					required={true}
				/>
				<InputGeneric
					stateValue={owner}
					setStateValue={setTitular}
					label={'Titular'}
					name={'owner'}
				/>
				<InputGeneric
					stateValue={type}
					setStateValue={setTipo}
					label={'Tipo'}
					name={'type'}
					required={true}
				/>
				{!editProTitle ? (
					<InputAttachGenForEdit
						setFileGen={setTituloPropiedad}
						setUuid={setUuid}
						uuid={uuid}
						submited={submited}
						setSubmited={setSubmited}
					/>
				) : (
					<Box sx={{ width: '100%', flexDirection: 'column' }}>
						<Typography sx={{ wordBreak: 'break-word', width: '300px', fontWeight: '600', mb: 1 }}>
							* El vehículo actualmente posee un titulo de propiedad adjunto.
						</Typography>
						<Box sx={{ display: 'flex', flexDirection: 'column', width: '350px', rowGap: '10px' }}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => setEditProTitle(false)}>
								¿Desea editar el titulo de propiedad?
							</Button>
							<Button
								variant="contained"
								color="secondary"
								onClick={handleDeleteAttachment}>
								¿Desea eliminar el titulo de propiedad?
							</Button>
						</Box>
					</Box>
				)}
			</div>
		);
	}
);

Motovehiculo.displayName = 'Motovehiculo';

export { Motovehiculo };
