import React from 'react';
import { Divider, Grid } from '@mui/material';
import { GenericFieldDetail } from 'components/globals/fields/FieldDetail';
import { GenericFormData } from './Generic';
import { currencyLocalFormat } from 'helpers/Formaters/Formaters';

interface GenericDetailsProps extends GenericFormData {
	isInventoriable: boolean;
	isAccessory?: boolean;
	inventoryNumber?: string;
}

export const GenericDetails: React.FC<GenericDetailsProps> = ({
	nomenclatureCode,
	quantity,
	description,
	unitPrice,
	isInventoriable,
	inventoryNumber,
	rowNumber
}) => {
	return (
		<Grid
			container
			spacing={1}
			mt={1}>
			<GenericFieldDetail
				label={'Tipo de Bien:'}
				value="Genérico"
			/>
			<Divider className="customDivider" />

			<GenericFieldDetail
				label={'Inventariable:'}
				value={isInventoriable ? 'Sí' : 'No'}
			/>
			<Divider className="customDivider" />

			{isInventoriable ? (
				<>
					<GenericFieldDetail
						label={'Código de Nomenclador:'}
						value={nomenclatureCode.value}
					/>
					<Divider className="customDivider" />

					<GenericFieldDetail
						label={'Descripción de Nomenclador:'}
						value={nomenclatureCode.label}
					/>
					<Divider className="customDivider" />

					<GenericFieldDetail
						label={'N° de Inventario:'}
						value={inventoryNumber}
					/>
					<Divider className="customDivider" />
				</>
			) : null}

			<GenericFieldDetail
				label="Cantidad:"
				value={quantity}
			/>
			<Divider className="customDivider" />

			<GenericFieldDetail
				label={'Precio Unitario:'}
				value={currencyLocalFormat(Number(unitPrice.floatValue))}
			/>
			<Divider className="customDivider" />

			{rowNumber ? (
				<>
					<GenericFieldDetail
						label={'Renglón:'}
						value={rowNumber}
					/>
					<Divider className="customDivider" />{' '}
				</>
			) : null}

			<GenericFieldDetail
				label={'Descripción:'}
				value={description}
			/>
		</Grid>
	);
};
