import { ReactNode, useState } from 'react';
import { ToastContext } from './ToastContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import style from './toast.module.css';

import { IToastProvider } from 'interfaces/ToastConfig';

export const ToastProvider = ({ children }: { children: ReactNode }) => {
	const [toastDefConfig, setToastDefConfig] = useState<IToastProvider>({
		position: 'bottom-right',
		type: 'default',
		autoClose: 4500,
		hideProgressBar: false,
		closeOnClick: true,
		rtl: false,
		pauseOnFocusLoss: false
	});

	const data = {
		toast
	};

	return (
		<ToastContext.Provider value={data}>
			<>
				{children}
				<ToastContainer
					{...toastDefConfig}
					toastClassName={style.toast}
					bodyClassName={style.body}
					limit={3}
				/>
			</>
		</ToastContext.Provider>
	);
};
