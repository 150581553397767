/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ButtonConfirm, ButtonDecline, ButtonNextPrev } from 'components/globals/customsBtn/customBtn';
import { IBajaStepTwo, IErrorsStepTwoForm } from 'interfaces/baja/IBaja';
import { FileAttachementManager } from 'components/globals/inputs/FileManager';
import { WarningMessage } from 'components/globals/messages/WarningMessage';
import { Attachment } from 'interfaces/file/IFile';
import { getFilesByDocumentId, uploadDeregistrationDocumentFiles } from 'services/bajaService';
import { toast } from 'react-toastify';
import { Spinner } from 'components/globals/spinner/Spinner';

export interface IOverviewBajaStepTwoForm {
	entity?: IBajaStepTwo;
	handleSubmit: (data: IBajaStepTwo) => void;
	handleCancel: (hasPendingData: boolean) => void;
	handleBack: () => void;
	buttonLabelCancel?: string;
	buttonLabelSubmit?: string;
	buttonLabelBack?: string;
	movementStatusId?: number;
}

export const BajaStepTwoForm = ({
	entity,
	handleSubmit,
	handleCancel,
	handleBack,
	buttonLabelCancel = 'Cancelar',
	buttonLabelSubmit = 'Siguiente',
	buttonLabelBack = 'Atrás',
	movementStatusId
}: IOverviewBajaStepTwoForm) => {
	const [errors, setErrors] = useState<IErrorsStepTwoForm | undefined>({} as IErrorsStepTwoForm);
	const [attachementsFiles, setAttachmentsFiles] = useState<File[]>([]);
	const [documentAttachments, setDocumentAttachments] = useState<Attachment[]>([]);
	const [isLoadingFiles, setIsLoadingFiles] = useState(false);

	const fetchFiles = async () => {
		const stepOneData = JSON.parse(localStorage.getItem('STORAGED_DEREGISTER_STEP_ONE') || 'null');

		if (stepOneData) {
			setIsLoadingFiles(true);

			const data = await getFilesByDocumentId(stepOneData?.id).finally(() => {
				setIsLoadingFiles(false);
			});

			setDocumentAttachments(data);
		}
	};

	useEffect(() => {
		if (entity) fetchFiles();
	}, []);

	const isHasPendingData = () => {
		if (attachementsFiles == null || attachementsFiles?.length < 1) {
			return true;
		}

		return false;
	};

	const getParsedData = (): IBajaStepTwo => {
		return {
			files: attachementsFiles
		};
	};

	const onHandleSubmit = async () => {
		const filesToUpload = attachementsFiles.filter((file) => !('id' in file));

		setIsLoadingFiles(true);

		try {
			if (filesToUpload.length && !isLoadingFiles) {
				await uploadDeregistrationDocumentFiles(
					entity?.id as number,
					attachementsFiles.filter((file) => {
						return !('id' in file);
					})
				);
				toast('Los archivos adjuntos se han cargado correctamente', { type: 'success' });
			}

			handleSubmit(getParsedData());
		} catch {
			toast('Ha ocurrido un error al cargar los archivos adjuntos', { type: 'error' });
		} finally {
			setIsLoadingFiles(false);
		}
	};

	return (
		<div className="borderedBox">
			{isLoadingFiles ? <Spinner /> : null}

			<Grid
				container
				spacing={2}
				pt={3}>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}>
					{errors?.files && <WarningMessage message={errors.files} />}
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}>
					<FileAttachementManager
						allowsMultiple
						attachments={documentAttachments}
						disableUploadAction
						disabled={Boolean(movementStatusId && movementStatusId !== 1)}
						fieldName="attachments"
						label={'Archivos Adjuntos *'}
						onChange={setAttachmentsFiles}
						onDeleteSuccess={fetchFiles}
						routeToDelete="/property-deregistration-files"
					/>
				</Grid>
				<Typography
					variant="subtitle2"
					style={{ width: '100%' }}
					pl={1}
					mt={1}>{`(*) Campos Obligatorios.`}</Typography>
			</Grid>

			<Box
				sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
				<ButtonDecline
					sx={{ mr: 1 }}
					variant="contained"
					onClick={() => {
						handleCancel(isHasPendingData());
					}}>
					{buttonLabelCancel}
				</ButtonDecline>

				<ButtonNextPrev
					sx={{ ml: 2, mr: 2 }}
					variant="contained"
					onClick={handleBack}>
					{buttonLabelBack}
				</ButtonNextPrev>

				<ButtonConfirm
					sx={{ ml: 1 }}
					variant="contained"
					disabled={documentAttachments.length === 0 && attachementsFiles.length === 0}
					onClick={() => onHandleSubmit()}>
					{buttonLabelSubmit}
				</ButtonConfirm>
			</Box>
		</div>
	);
};
