import { ReactNode, useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { AuthContext } from './AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';

//Interfaces
import { IRole, IUser } from 'interfaces/User';
import { IToken } from 'interfaces/AuthContextData';

//Services
import { getUserDataService, loginService } from 'services/authService';

const LocalStorageHighSteps = [
	'paso1Alta',
	'paso1TipoAlta',
	'paso1AltaFecha',
	'paso1AltaAdquisicion',
	'paso1AltaDonacion',
	'paso1AltaTransferencia',
	'paso1AltaTransferenciaDep',
	'paso3RightList',
	'paso2Alta',
	'paso3RightType',
	'paso3VehicleType',
	'itemsTemporalID',
	'tipoComprobante'
];

/**
 * If the user is not logged in and they are not on the login page, redirect them to the login page
 * @param  - {children: ReactNode}
 */
export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const [user, setUser] = useState<IUser>({} as IUser);
	const [token, setToken] = useState<IToken>({});
	const [loginError, setLoginError] = useState<string | null>(null);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (location.pathname.includes('reset-password') || location.pathname.includes('login/send-reset-email')) return;
		if (!localStorage.getItem('token') || !localStorage.getItem('user')) {
			logout();
		}
		if (location.pathname !== 'alta' && location.pathname !== 'login') {
			LocalStorageHighSteps.map((step) => {
				localStorage.removeItem(step);
			});
		}
		if (location.pathname.includes('login')) {
			const user = JSON.parse(localStorage.getItem('user') || '{}');
			const token = JSON.parse(localStorage.getItem('token') || '{}');
			if (user?.email && token?.access_token) {
				setUser({ ...user });
				setToken({ ...token });
				localStorage.getItem('lastPath') ? navigate(`${localStorage.getItem('lastPath')}`) : navigate('/home');
			} else {
				logout();
				return;
			}
		}
		localStorage.setItem('lastPath', location.pathname);
	}, [location.pathname]);

	useEffect(() => {
		if (location.pathname.includes('reset-password') || location.pathname.includes('login/send-reset-email')) return;
		const localUser = JSON.parse(localStorage.getItem('user') || '{}');
		if (localUser?.email) {
			return;
		}
		if (token.access_token) {
			getUserDataService(token).then((res: IUser) => {
				setUser({ ...user, ...res });
				localStorage.setItem('user', JSON.stringify(res));
				if (!token.access_token || !user.email) {
					logout();
				}
			});
			return;
		}
	}, [token]);

	/**
	 * Signin is a function that takes in a user and a callback function, and then calls loginService with
	 * the user's email and password, and then sets the token, and then sets the access_token and
	 * refresh_token in localStorage, and then calls the callback function.
	 * @param {IUser} logUser - IUser - this is the user object that is passed in from the form.
	 * @param {VoidFunction} callback - VoidFunction
	 */
	const login = (logUser: IUser, callback: VoidFunction) => {
		setUser({ ...user, ...logUser });
		if (logUser.email && logUser.password) {
			setLoginError(null);
			loginService(logUser.email, logUser.password)
				.then((data: IToken | any) => {
					if (data.response) {
						if (data?.response?.status === 500) {
							setLoginError(data.response.statusText);
							return;
						}
						if (data?.response?.status === 401) {
							setLoginError('Credenciales incorrectas');
							return;
						}
						if (data?.response?.status === 403) {
							setLoginError('Usuario deshabilitado');
							return;
						}
					}
					setToken(data);
					localStorage.setItem('token', JSON.stringify(data));
					callback();
					setTimeout(() => {
						navigate('/home', { replace: true });
					}, 500);
				})
				.catch((err: any) => {
					setLoginError(err);
					return;
				});
		}
	};

	const persistLoginUser = () => {
		const savedLogin = localStorage.getItem('savePassword');
		const loginEmail = localStorage.getItem('loginEmail');
		const loginPassword = localStorage.getItem('loginPassword');
		localStorage.clear();
		localStorage.setItem('savePassword', savedLogin || 'false');
		localStorage.setItem('loginEmail', loginEmail || '');
		localStorage.setItem('loginPassword', loginPassword || '');
		navigate('/login', { replace: true });
	};

	/**
	 * Signout is a function that takes a callback as an argument and returns a fakeAuth.
	 * Also signout function takes a callback as an argument and returns a setUser function that set an empty User and
	 * 0deleteLocalUser function that clear the user in localStorage.
	 * function that takes no arguments and returns nothing.
	 *
	 * @returns The return value of the fakeAuth.signout() function.
	 */
	const logout = () => {
		setUser({} as IUser);
		setToken({} as IToken);
		persistLoginUser();
	};

	const value = { user, token, login, logout, loginError };

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
