import apiInstance from 'interceptors/axiosInterceptor';

export type Locality = {
	id: number;
	name: string;
	location_id: number;
	department_id: number;
	department: string;
};

export type Department = {
	id: number;
	name: string;
	department_id: number;
	locations: Array<Locality>;
};

export async function getAllDepartments(): Promise<Department[]> {
	try {
		const response = await apiInstance.get('/departments');
		return response.data;
	} catch {
		return [];
	}
}
