import { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import contaduria from '../../assets/contaduria-general.svg';
import misionesBlanco from '../../assets/Misiones-Blanco.svg';
import style from './login.module.css';
import { ButtonAuth } from 'components/globals/customsBtn/customBtn';

//Components
import { Spinner } from '../../components/globals/spinner/Spinner';

// Formik
import { FormikValues, useFormik } from 'formik';

//Material-UI
import {
	FormHelperText,
	Typography,
	CssBaseline,
	FormControlLabel,
	Checkbox,
	Grid,
	InputAdornment,
	Box,
	Container,
	Link,
	IconButton,
	TextField
} from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';

// Formik & Yup Validations
import { validationLoginSchema } from '../../utils/yupSchemas';

export const Login = () => {
	const [loginError, setLoginError] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [checked, setChecked] = useState(localStorage.getItem('savePassword') === 'true');
	const auth = useAuth();
	const navigate = useNavigate();

	/* A hook that is used to validate the form. */
	const formik = useFormik({
		initialValues: {
			email: localStorage.getItem('loginEmail') || '',
			password: localStorage.getItem('loginPassword') || ''
		},

		validationSchema: validationLoginSchema,
		onSubmit: (values) => {
			auth.login(values, () => {
				setIsLoading(true);
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			});
		}
	});

	useEffect(() => {
		handleSaveCredentials();
	}, [checked, formik.values]);

	/**
	 * When the user clicks on the eye icon, toggle the showPassword state variable between true and false.
	 */
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleCheckBox = () => {
		setChecked(!checked);
	};

	const handleSaveCredentials = () => {
		if (checked) {
			localStorage.setItem('loginEmail', formik.values.email);
			localStorage.setItem('loginPassword', formik.values.password);
			localStorage.setItem('savePassword', 'true');
		} else {
			localStorage.removeItem('loginEmail');
			localStorage.removeItem('loginPassword');
			localStorage.setItem('savePassword', 'false');
		}
	};

	useEffect(() => {
		if (auth.loginError) {
			setLoginError(auth.loginError);
		}
	}, [auth.loginError]);

	if (localStorage.getItem('token') && localStorage.getItem('user')) {
		navigate('/home', { replace: true });
		return null;
	}

	return (
		<>
			{isLoading ? <Spinner /> : null}
			{
				<div className={style.login}>
					<Box
						sx={{
							width: '80%',
							mt: '-85px',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}>
						<img
							src={misionesBlanco}
							alt="Misiones"
							width={170}
						/>
						<img
							src={contaduria}
							alt="Contaduria"
							width={80}
						/>
					</Box>
					<Container
						component="main"
						maxWidth="xs"
						sx={{ pb: 10 }}>
						<CssBaseline />

						<Box
							sx={{
								marginTop: 8,
								marginLeft: 2,
								marginRight: 2,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center'
							}}>
							<Typography
								component="h1"
								variant="h5"
								className={style.loginTitle}>
								Administración de Bienes Patrimoniales
							</Typography>
							<hr />
							<Box
								component="form"
								onSubmit={formik.handleSubmit}
								sx={{ mt: 1 }}>
								<TextField
									id="email"
									name="email"
									label="Email"
									value={formik.values.email}
									onChange={formik.handleChange}
									error={formik.touched.email && Boolean(formik.errors.email)}
									helperText={formik.touched.email && formik.errors.email}
									fullWidth
									sx={{ mt: 1 }}
									inputProps={{
										form: {
											autoComplete: 'off'
										}
									}}
								/>
								<TextField
									id="password"
									name="password"
									label="Contraseña"
									type={showPassword ? 'text' : 'password'}
									value={formik.values.password}
									onChange={formik.handleChange}
									error={formik.touched.password && Boolean(formik.errors.password)}
									helperText={formik.touched.password && formik.errors.password}
									fullWidth
									sx={{ mt: 3 }}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													edge="end">
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
								{loginError === 'Usuario deshabilitado' && (
									<FormHelperText sx={{ color: '#d32f2f', fontSize: '14px', pl: 1 }}>
										Usuario deshabilitado
									</FormHelperText>
								)}
								{loginError === 'Credenciales incorrectas' && !loginError?.split(' ').includes('Internal') && (
									<FormHelperText sx={{ color: '#d32f2f', fontSize: '14px', pl: 1 }}>
										Credenciales Incorrectas
									</FormHelperText>
								)}
								{loginError && loginError?.split(' ').includes('Internal') && (
									<FormHelperText sx={{ color: '#d32f2f', fontSize: '14px', pl: 1 }}>
										Error Interno, por favor comuníquese con el administrador.
									</FormHelperText>
								)}
								<FormControlLabel
									control={
										<Checkbox
											value="remember"
											color="primary"
											checked={checked}
											onChange={handleCheckBox}
										/>
									}
									label="Recordar contraseña"
								/>
								<Grid
									container
									justifyContent={'center'}>
									<Link
										className={style.resetPassword}
										href="/login/send-reset-email"
										sx={{
											textDecoration: 'none',
											color: '#8f8f8f',
											fontWeight: '400',
											fontSize: '20px',
											marginTop: 4
										}}>
										Recuperar Contraseña
									</Link>
								</Grid>
								<ButtonAuth
									type="submit"
									variant="contained"
									fullWidth
									sx={{ mt: 6, height: '50px', alignContent: 'center', zIndex: '0' }}>
									Ingresar
								</ButtonAuth>
							</Box>
						</Box>
					</Container>
				</div>
			}
		</>
	);
};
