import { useMemo, FC } from 'react';
import { Row, Cell } from '@tanstack/react-table';
import { defaultColumns } from './BalanceUnitsColumnDef';
import { IBalanceUnitsTableProps } from 'interfaces/balance/IBalance';
import { useBalanceContext } from 'hooks/useBalanceContext';
import { PaginationTable } from 'components/globals/tabs/PaginationTable';
import { IOrganizationalUnit } from 'interfaces/organism/IOrganism';
import { CustomTable } from 'components/globals/table/customTable';

export const BalanceUnitsTable: FC<IBalanceUnitsTableProps> = ({
	elements,
	handleToggleStatus = (entity: IOrganizationalUnit) => {},
	handleToggleAllStatus = (status: boolean) => {}
}) => {
	const balanceContext = useBalanceContext();
	const columns = useMemo<typeof defaultColumns>(() => [...defaultColumns], [defaultColumns]);

	const handleAction = (row: Row<any>) => {
		switch (row.original?.action) {
			case 'toggleStatus':
				handleToggleStatus(row.original);
				break;
			default:
				break;
		}
	};

	return (
		<div className={'customBodyContainerTable'}>
			<PaginationTable
				currentPage={balanceContext?.organizationalUnitsMeta?.currentPage}
				totalPages={balanceContext?.organizationalUnitsMeta?.totalPages}
				showDetails={true}
				itemsPerPage={balanceContext?.organizationalUnitsMeta?.itemsPerPage}
				totalItems={balanceContext?.organizationalUnitsMeta?.itemCount}
				handleChangePage={(page: number, limit?: number) => balanceContext?.onHandleChangePageOrganizationalUnits({ page: page, limit })}
			/>

			<CustomTable
				columns={columns}
				elements={elements}
				handleAction={(row: Row<any>, cell?: Cell<any, any>, evt?: any) => handleAction(row)}
				itemsPerPage={balanceContext?.organizationalUnitsMeta?.itemsPerPage}
			/>

			<div className="h-2" />
			<PaginationTable
				currentPage={balanceContext?.organizationalUnitsMeta?.currentPage}
				totalPages={balanceContext?.organizationalUnitsMeta?.totalPages}
				itemsPerPage={balanceContext?.organizationalUnitsMeta?.itemsPerPage}
				totalItems={balanceContext?.organizationalUnitsMeta?.totalItems}
				handleChangePage={(page: number, limit?: number) => balanceContext?.onHandleChangePageOrganizationalUnits({ page: page, limit })}
			/>
		</div>
	);
};
