import { SetStateAction, useState, useEffect, useCallback } from 'react';
import style from './vehicle.module.css';

import lodash from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { NomenclatureCode } from 'components/globals/nomenclatureCode/nomenClatureCode';
import { useMovContext } from 'hooks/useMovContext';

//Services
import { postItemAttachment } from 'services/crudService';
import {
	ValidateAeronave,
	ValidateMaquinas,
	ValidateAutomotor,
	ValidateMotovehiculo,
	ValidateEmbarcaciones
} from './validateVehicle';

//Interfaces
import { IStep3Rights, IStep3VehicleValues } from '../interfaces';

//Components
import { QuantityInput } from 'components/EncPatrimonial/altaStepper/paso3/quantityInput/QuantityInput';
import { UnitPriceInput } from '../unitPriceInput/UnitPriceInput';
import { Automotores } from './automotores/Automotores';
import { Aeronaves } from './aeronaves/Aeronaves';
import { Motovehiculo } from './motovehiculos/Motovehiculos';
import { Embarcaciones } from './embarcaciones/Embarcaciones';
import { Maquinas } from './maquinas/Maquinas';

//Material UI
import { Button, FormControl, Box, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { ProgressBar } from 'components/globals/progressBar/ProgressBar';

const vehicleTypeArr = ['Automotores', 'Motovehículos', 'Embarcaciones', 'Aeronaves', 'Máquinas'];

export const Vehiculo = ({ rightList, setRightList }: IStep3Rights) => {
	const [vehicleType, setVehicleType] = useState(
		JSON.parse(localStorage?.getItem('paso3VehicleType') || 'null') || 'Automotores'
	);
	const [dataFromChild, setDataFromChild] = useState<IStep3VehicleValues>({} as IStep3VehicleValues);
	const [attachmentFromChild, setAttachmentFromChild] = useState<File | null>(null);
	const [nomenclatureCode, setNomenclatureCode] = useState('');
	const [nomenclatureCodeId, setNomenclatureCodeId] = useState<number>(0);
	const [quantity, setQuantity] = useState('');
	const [unitPrice, setUnitPrice] = useState('');
	const [disabled, setDisabled] = useState(true);
	const [uuid, setUuid] = useState(uuidv4());
	const [submited, setSubmited] = useState(false);

	const movContext = useMovContext();

	const rightType = 'Vehículo';

	const memoizedDataFromChild = useCallback((data: SetStateAction<IStep3VehicleValues>) => {
		setDataFromChild(data);
	}, []);

	useEffect(() => {
		const isValidGenerics = handleGenericsDisabled();

		if (vehicleType === 'Automotores') {
			const isValid = ValidateAutomotor(dataFromChild as any);
			setDisabled(!isValid || !isValidGenerics);
		}
		if (vehicleType === 'Aeronaves') {
			const isValid = ValidateAeronave(dataFromChild as any);
			setDisabled(!isValid || !isValidGenerics);
		}
		if (vehicleType === 'Motovehículos') {
			const isValid = ValidateMotovehiculo(dataFromChild as any);
			setDisabled(!isValid || !isValidGenerics);
		}
		if (vehicleType === 'Embarcaciones') {
			const isValid = ValidateEmbarcaciones(dataFromChild as any);
			setDisabled(!isValid || !isValidGenerics);
		}
		if (vehicleType === 'Máquinas') {
			const isValid = ValidateMaquinas(dataFromChild as any);

			setDisabled(!isValid || !isValidGenerics);
		}
	}, [vehicleType, nomenclatureCode, quantity, unitPrice, dataFromChild]);

	const handleGenericsDisabled = () => {
		setDisabled(true);
		if (nomenclatureCode.trim().length === 0 || quantity.trim().length === 0 || unitPrice.trim().length === 0)
			return false;
		return true;
	};

	const handleVehicleType = (evt: SelectChangeEvent) => {
		setVehicleType(evt.target.value as string);
	};

	const resetValues = () => {
		setNomenclatureCode('');
		setQuantity('');
		setUnitPrice('');
		setSubmited(true);
		setAttachmentFromChild(null);
	};

	const handleSubmit = () => {
		if (attachmentFromChild?.name) {
			dataFromChild.propertyTitleUuid = uuid;
		}
		const list = [...rightList];
		const newItem = {
			rightType,
			id: uuid,
			nomenclatureCode,
			nomenclatureCodeId,
			quantity,
			unitPrice,
			operation: 'create',
			...dataFromChild
		};
		list.unshift(newItem);
		movContext?.setItemsFromTable((prev) => [...prev, newItem]);
		setRightList([...list]);
		delete dataFromChild.propertyTitle;
		delete dataFromChild.propertyTitleUuid;
		setSubmited(true);
		resetValues();
	};

	const debouncedSubmit = lodash.debounce(handleSubmit, 1000);

	return (
		<div style={{ marginBottom: '40px' }}>
			<FormControl sx={{ width: '300px', ml: '302px', mt: -2 }}>
				<Box>
					<InputLabel htmlFor="tipoAlta">Seleccione el Tipo de Vehículo</InputLabel>
					<Select
						sx={{ width: '220px', position: 'absolute' }}
						size="small"
						id="tipoVehiculo"
						name="tipoVehiculo"
						label="Seleccione el Tipo de Vehículo"
						value={vehicleType}
						onChange={handleVehicleType}>
						{vehicleTypeArr.map((data: string, index: number) => (
							<MenuItem
								key={index}
								value={data}>
								{data}
							</MenuItem>
						))}
					</Select>
				</Box>
			</FormControl>
			<div className={style.form}>
				<NomenclatureCode
					setNomenclatureCode={setNomenclatureCode}
					setNomenclatureCodeId={setNomenclatureCodeId}
					submited={submited}
				/>
				<QuantityInput
					style={{ marginLeft: '15px' }}
					setQuantity={setQuantity}
					submited={submited}
				/>
				<UnitPriceInput
					setUnitPrice={setUnitPrice}
					submited={submited}
				/>
			</div>
			<hr
				className="solid"
				style={{ borderTop: '1px solid #5c5c5c71', marginTop: '35px' }}
			/>
			<div style={{ marginTop: '20px' }}>
				{vehicleType === 'Automotores' && (
					<div>
						<Automotores
							memoizedDataFromChild={memoizedDataFromChild}
							setAttachmentFromChild={setAttachmentFromChild}
							setUuid={setUuid}
							uuid={uuid}
							submited={submited}
							setSubmited={setSubmited}
						/>
					</div>
				)}
				{vehicleType === 'Aeronaves' && (
					<div>
						<Aeronaves memoizedDataFromChild={memoizedDataFromChild} />
					</div>
				)}
				{vehicleType === 'Motovehículos' && (
					<div>
						<Motovehiculo
							memoizedDataFromChild={memoizedDataFromChild}
							setAttachmentFromChild={setAttachmentFromChild}
							setUuid={setUuid}
							uuid={uuid}
							submited={submited}
							setSubmited={setSubmited}
						/>
					</div>
				)}
				{vehicleType === 'Embarcaciones' && (
					<div>
						<Embarcaciones
							memoizedDataFromChild={memoizedDataFromChild}
							submited={submited}
							setSubmited={setSubmited}
						/>
					</div>
				)}
				{vehicleType === 'Máquinas' && (
					<div>
						<Maquinas
							memoizedDataFromChild={memoizedDataFromChild}
							setAttachmentFromChild={setAttachmentFromChild}
							setUuid={setUuid}
							uuid={uuid}
							submited={submited}
							setSubmited={setSubmited}
						/>
					</div>
				)}
			</div>
			<div>
				<Button
					id="addButton"
					disabled={disabled}
					sx={{ mt: 4 }}
					variant="contained"
					onClick={debouncedSubmit}>
					Agregar Bien
				</Button>
			</div>
			<hr
				className="solid"
				style={{ borderTop: '1px solid #5c5c5c71', marginBottom: '-20px' }}
			/>
		</div>
	);
};
