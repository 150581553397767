import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { IBaja, IBajaStepOne, IBajaStepThree, IBajaStepTwo } from 'interfaces/baja/IBaja';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { BajaStepOneForm } from './forms/stepOne';
import { BajaStepTwoForm } from './forms/stepTwo';
import { BajaStepThreeForm } from './forms/stepThree';
import { useAppContext } from 'hooks/useAppContext';
import { Spinner } from 'components/globals/spinner/Spinner';
import { useDeregistrationContext } from 'hooks/useDeregistrationContext';
import { BajaSummary } from './forms/summary';
import { CustomDecideDialog } from 'components/globals/dialog/CustomDeleteDialog';
import { cancelPropertyDeRegistration } from 'services/bajaService';

const LOCAL_STORAGE_STEP_ONE = 'STORAGED_DEREGISTER_STEP_ONE';
const LOCAL_STORAGE_STEP_TWO = 'STORAGED_DEREGISTER_STEP_TWO';
const LOCAL_STORAGE_STEP_THREE = 'STORAGED_DEREGISTER_STEP_THREE';
const STORAGED_DEREGISTER_ACTIVE_STEP = 'STORAGED_DEREGISTER_ACTIVE_STEP';

export const BajaOverview = () => {
	const appContext = useAppContext();
	const deregistrationContext = useDeregistrationContext();
	const navigate = useNavigate();
	const [activeStep, setActiveStep] = useState(0);
	const [parametersPatrimonialDependences, setParametersPatrimonialDependences] = useState<any[]>([]);
	const [parametersDeregistrationTypes, setParametersDeregistrationTypes] = useState<any[]>([]);
	const [showBackHomeButton, setShowBackHomeButton] = useState<boolean>(false);
	const { registrationId } = useParams();
	const search = useLocation().search;
	const movementStatusId = new URLSearchParams(search).get('movementStatusId');

	useEffect(() => {
		appContext?.onHandleTryGetAllDeregistrationTypes();
	}, []);

	useEffect(() => {
		setActiveStep(deregistrationContext?.activeStep);
	}, [deregistrationContext?.activeStep]);

	const handleBack = (step: number) => {
		deregistrationContext?.changeActiveStep(step);
	};

	const handleSubmit = async (
		stepFormNumber = -1,
		dataReceived: IBajaStepOne | IBajaStepTwo | IBajaStepThree | undefined
	) => {
		switch (stepFormNumber) {
			case 0: // Instrumento Legal
				if (dataReceived?.hasErrors) {
					return false;
				}
				await deregistrationContext?.onHandleDataStepOne(dataReceived as IBajaStepOne);
				break;
			case 1: // Documentos Adjuntos
				if (dataReceived?.hasErrors) {
					return false;
				}
				deregistrationContext?.onHandleDataStepTwo(dataReceived as IBajaStepTwo);
				break;
			case 2: // Bienes Patrimoniales
				if (dataReceived?.hasErrors) {
					return false;
				}
				deregistrationContext?.onHandleDataStepThree(dataReceived as IBajaStepThree);
				break;
			case 3: // Finalizar
				{
					const result = await deregistrationContext?.onHandleFinalice(
						deregistrationContext?.dataStepOne?.id !== undefined ? deregistrationContext?.dataStepOne.id : null
					);
					if (result) {
						//navigate('/home', { replace: true });
						setShowBackHomeButton(true);
					}
				}
				break;
			default:
				break;
		}
	};

	// START Handle Cancel Modal
	const [showCancelModal, toggleCancelModal] = useState(false);

	const handleCancel = async () => {
		const documentId = deregistrationContext.dataStepOne?.id;

		if (documentId && activeStep < 3 && !registrationId) {
			cancelPropertyDeRegistration(documentId);
		}

		if (registrationId) navigate('/movimientos/bajas', { replace: true });
		else navigate('/home', { replace: true });

		deregistrationContext?.changeDataStepOne(undefined);
		deregistrationContext?.changeDataStepTwo(undefined);
		deregistrationContext?.changeDataStepThree(undefined);
		deregistrationContext?.changeActiveStep(0);
		clearStepsLocalStorageData();
	};

	const clearStepsLocalStorageData = () => {
		localStorage.removeItem(LOCAL_STORAGE_STEP_ONE);
		localStorage.removeItem(LOCAL_STORAGE_STEP_TWO);
		localStorage.removeItem(LOCAL_STORAGE_STEP_THREE);
		localStorage.removeItem(STORAGED_DEREGISTER_ACTIVE_STEP);
	};

	// END Handle Cancel Modal

	// START Handle Finalice Modal
	const [showFinaliceModal, toggleFinaliceModal] = useState(false);
	// END Handle Finalice Modal

	const onHandleSearchDependencies = (term: string | null) => {
		if (term !== null && term.length > 0) {
			appContext?.onHandleTryGetAllPatrimonialDependences(term);
		} else {
			setParametersPatrimonialDependences([]);
		}
	};

	useEffect(() => {
		if (appContext?.patrimonialDependences.length > 0) {
			setParametersPatrimonialDependences(
				appContext?.patrimonialDependences.map((p) => {
					return {
						id: p.id,
						label: p.patrimonial_dependence
					};
				})
			);
		}
	}, [appContext?.patrimonialDependences]);

	useEffect(() => {
		if (appContext?.deregistrationTypes.length > 0) {
			setParametersDeregistrationTypes(
				appContext?.deregistrationTypes.map((p) => {
					return {
						id: p.id,
						label: p.name
					};
				})
			);
		}
	}, [appContext?.deregistrationTypes]);

	const onHandleCancel = () => {
		toggleCancelModal(true);
	};

	useEffect(() => {
		// return () => clearStepsLocalStorageData();
	}, []);

	if (!movementStatusId && location.pathname.includes('editar')) {
		return (
			<Navigate
				to="/home"
				replace
			/>
		);
	}

	return (
		<>
			{appContext?.showSpinner ? <Spinner /> : null}

			{showCancelModal && (
				<CustomDecideDialog
					title={'Cancelar Operación'}
					message={'¿Está seguro que desea cancelar la operación?'}
					handleSubmit={() => {
						toggleCancelModal(false);
						handleCancel();
					}}
					handleDecline={() => {
						toggleCancelModal(false);
					}}
				/>
			)}

			{showFinaliceModal && (
				<CustomDecideDialog
					title={'Finalizar Operación'}
					message={'¿Desea guardar los datos?'}
					handleSubmit={() => {
						toggleFinaliceModal(false);
						handleSubmit(activeStep, undefined);
					}}
					handleDecline={() => {
						toggleFinaliceModal(false);
					}}
					labelSubmit={'Confirmar'}
				/>
			)}

			<Box sx={{ padding: '0px 5%', minHeight: '300px', pb: 3 }}>
				{activeStep === 0 && (
					<BajaStepOneForm
						movementStatusId={movementStatusId ? Number(movementStatusId) : undefined}
						entity={{ instruments: deregistrationContext?.dataStepOne } as IBaja}
						handleSubmit={(data: IBajaStepOne) => {
							handleSubmit(activeStep, data);
						}}
						handleCancel={() => {
							onHandleCancel();
						}}
					/>
				)}

				{activeStep === 1 && (
					<BajaStepTwoForm
						movementStatusId={movementStatusId ? Number(movementStatusId) : undefined}
						entity={deregistrationContext?.dataStepTwo}
						handleSubmit={(data: IBajaStepTwo) => {
							handleSubmit(activeStep, data);
						}}
						handleCancel={() => {
							onHandleCancel();
						}}
						handleBack={() => {
							handleBack(activeStep - 1);
						}}
					/>
				)}

				{activeStep === 2 && (
					<BajaStepThreeForm
						movementStatusId={movementStatusId ? Number(movementStatusId) : undefined}
						entity={deregistrationContext?.dataStepThree}
						propertiesOrAccessories={
							deregistrationContext?.dataStepThree?.items ? deregistrationContext?.dataStepThree?.items : []
						}
						propertiesTimeless={
							deregistrationContext?.dataStepThree?.timelessItems
								? deregistrationContext?.dataStepThree?.timelessItems
								: []
						}
						handleSubmit={(data: IBajaStepThree) => {
							handleSubmit(activeStep, data);
						}}
						handleSearchDependencies={(term: string) => {
							onHandleSearchDependencies(term);
						}}
						handleCancel={() => {
							onHandleCancel();
						}}
						handleBack={() => {
							handleBack(activeStep - 1);
						}}
						patrimonialDependences={parametersPatrimonialDependences}
						deregistrationTypes={parametersDeregistrationTypes}
					/>
				)}

				{activeStep === 3 && (
					<BajaSummary
						dataStepOne={deregistrationContext?.dataStepOne ? deregistrationContext?.dataStepOne : ({} as IBajaStepOne)}
						dataStepTwo={deregistrationContext?.dataStepTwo ? deregistrationContext?.dataStepTwo : ({} as IBajaStepTwo)}
						dataStepThree={
							deregistrationContext?.dataStepThree ? deregistrationContext?.dataStepThree : ({} as IBajaStepThree)
						}
						handleBack={() => {
							handleBack(activeStep - 1);
						}}
						handleCancel={() => {
							onHandleCancel();
						}}
						handleSubmit={() => {
							toggleFinaliceModal(true);
						}}
						handleBackHome={() => {
							handleCancel();
						}}
						showBackHomeButton={showBackHomeButton}
					/>
				)}
			</Box>
		</>
	);
};
