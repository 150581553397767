import { ReactNode, useEffect, useState } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { OrganizationalUnitContext } from './OrganizationalUnitContext';
import { toast } from 'react-toastify';
import { useAppContext } from 'hooks/useAppContext';
import { ILinks, IMeta } from 'interfaces/commons/IResponse';
import { IPaginationRequest } from 'interfaces/commons/IParameters';
import { IOrganizationalUnit, IOrganizationalUnitFilters } from 'interfaces/organism/IOrganism';
import {
	tryCreateOrUpdateOrganizationalUnit,
	tryDeleteOrganizationalUnit,
	tryGetAllOrganizationalUnitsBySearchPaginated,
	tryGetOrganizationalUnitByID
} from 'services/organismsService';
import { captureMessageError } from 'services/httpService';

const ORGANIZATIONAL_UNIT_FILTERS = 'ORGANIZATIONAL_UNIT_FILTERS';

export const OrganizationalUnitState = ({ children }: { children: ReactNode | ReactJSXElement }) => {
	const appContext = useAppContext();

	useEffect(() => {
		// Get Search parameters
		const persistedValue = localStorage.getItem(ORGANIZATIONAL_UNIT_FILTERS);
		let searchFilters = { term: undefined } as IOrganizationalUnitFilters;
		if (persistedValue !== null) {
			searchFilters = JSON.parse(persistedValue);
		}
		setFilters(searchFilters);
	}, []);

	// START Get All Organizational Units
	const [entities, setEntities] = useState<IOrganizationalUnit[]>([] as IOrganizationalUnit[]);
	const [meta, setMeta] = useState<IMeta>({} as IMeta);
	const [links, setLinks] = useState<ILinks>({} as ILinks);

	const onHandleGetAll = async ({ term }: IOrganizationalUnitFilters, { page, limit }: IPaginationRequest) => {
		setEntities([]);
		setMeta({} as IMeta);
		setLinks({} as ILinks);
		appContext?.onHandleShowSpinner();
		const response = await tryGetAllOrganizationalUnitsBySearchPaginated({ term }, { page, limit });
		appContext?.onHandleHideSpinner();

		let defaultMessageError = captureMessageError(response, 'Ocurrio un error al recuperar las unidades organizacionales!');
		if (defaultMessageError.hasError) {
			toast.error(defaultMessageError.message);
			return false;
		}

		setEntities(response.items);
		setMeta(response.meta);
		setLinks(response.links);
		toast.success('Unidades Organizacionales recuperadas!');
		return true;
	};
	// END Get All Organizational Units

	// START Filter Organizational Unit
	const storagedFilters = localStorage.getItem(ORGANIZATIONAL_UNIT_FILTERS);
	const [filters, setFilters] = useState<IOrganizationalUnitFilters>(
		storagedFilters ? JSON.parse(storagedFilters) : ({ term: undefined } as IOrganizationalUnitFilters)
	);

	const onHandleSearByFilters = async ({ term = '' }: IOrganizationalUnitFilters) => {
		if (term && term !== '') {
			setFilters({ term });
			localStorage.setItem(ORGANIZATIONAL_UNIT_FILTERS, JSON.stringify({ term }));
			return onHandleGetAll({ term }, { page: 1, limit: meta.itemsPerPage });
		}
		return false;
	};

	const onHandleClearFilters = async () => {
		setFilters({ term: undefined } as IOrganizationalUnitFilters);
		localStorage.removeItem(ORGANIZATIONAL_UNIT_FILTERS);
		return onHandleGetAll({ term: undefined }, { page: 1, limit: meta.itemsPerPage });
	};
	// END Filter Organizational Unit

	// START Handle Change Page
	const onHandleChangePage = async ({ page = 1, limit = meta.itemsPerPage }: IPaginationRequest) => {
		return await onHandleGetAll({ ...filters }, { page, limit });
	};
	// END Handle Change Page

	// START Create or Update Organizational Unit
	const onHandleCreateOrUpdate = async (entity: IOrganizationalUnit) => {
		if (!entity) {
			toast.error('Debe indicar una nueva unidad organizacional para poder agregarla!');
			return false;
		}

		appContext?.onHandleShowSpinner();
		const response = await tryCreateOrUpdateOrganizationalUnit(entity);
		appContext?.onHandleHideSpinner();

		let defaultMessageError = captureMessageError(response, 'Ocurrio un error al guardar los datos de la unidad organizacional!');
		if (defaultMessageError.hasError) {
			toast.error(defaultMessageError.message);
			return false;
		}

		toast.success('Unidad Organizacional guardada exitosamente.');
		return true;
	};
	// END Create or Update Organizational Unit

	// START Delete Organizational Unit
	const onHandleDelete = async (id: number | null) => {
		if (id == null) {
			toast.error('Debe indicar una unidad organizacional para poder eliminarla!');
			return false;
		}

		appContext?.onHandleShowSpinner();
		const response = await tryDeleteOrganizationalUnit(id);
		appContext?.onHandleHideSpinner();

		let defaultMessageError = captureMessageError(response, 'Ocurrio un error al eliminar la unidad organizacional!');
		if (defaultMessageError.hasError) {
			toast.error(defaultMessageError.message);
			return false;
		}

		toast.success('Unidad Organizacional eliminada exitosamente.');
		return true;
	};
	// END Delete Organizational Unit

	// START Get Data of Organizational Unit
	const onHandleGetData = async (id: number | null, showMessage: boolean = true) => {
		if (id == null) {
			toast.error('Debe indicar una unidad organizacional para poder recuperar sus datos!');
			return false;
		}

		appContext?.onHandleShowSpinner();
		const response = await tryGetOrganizationalUnitByID(id);
		appContext?.onHandleHideSpinner();

		let defaultMessageError = captureMessageError(response, 'Ocurrio un error al recuperar datos de la unidad organizacional!');
		if (defaultMessageError.hasError) {
			toast.error(defaultMessageError.message);
			return false;
		}

		if (showMessage) {
			toast.success('Unidad Organizacional recuperada exitosamente.');
		}
		return true;
	};
	// END Get Data of Organizational Unit

	return (
		<OrganizationalUnitContext.Provider
			value={{
				entities,
				meta,
				links,
				onHandleGetAll,
				onHandleSearByFilters,
				onHandleClearFilters,
				filters,
				onHandleChangePage,
				onHandleCreateOrUpdate,
				onHandleDelete,
				onHandleGetData
			}}>
			{children}
		</OrganizationalUnitContext.Provider>
	);
};
