import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Tooltip,
	Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { FileAttachementManager } from 'components/globals/inputs/FileManager';
import { Attachment } from 'interfaces/file/IFile';
import { Document, Page } from 'react-pdf';
import './styles.css';

interface UploadSheetModalProps {
	onClose?: CallableFunction;
	onConfirm?(files: [File, File]): Promise<boolean>;
	open?: boolean;
	loading?: boolean;
	attachments?: Attachment[];
}

export const UploadSheetModal: React.FC<UploadSheetModalProps> = ({ open, onClose, onConfirm, loading }) => {
	const [isOpen, setOpen] = useState(false);
	const [files, setFiles] = useState<File[]>([]);

	const handleClose = () => {
		if (loading) return;

		setOpen(false);
		setFiles([]);
		onClose && onClose();
	};

	const handleConfirm = async () => {
		if (!onConfirm) return;

		const shouldClose = await onConfirm([files[0], files[1]]);

		shouldClose && handleClose();
	};

	const pdfFiles = useMemo(() => {
		return files.filter((file) => file.type.includes('pdf'));
	}, [files]);

	const imgFiles = useMemo(() => {
		const images = files.filter((file) => file.type.includes('image'));
		return images.map((img) => ({ name: img.name, src: URL.createObjectURL(img) }));
	}, [files]);

	useEffect(() => {
		setOpen(!!open);
		return () => setFiles([]);
	}, [open]);

	return (
		<Dialog
			maxWidth="sm"
			open={isOpen}>
			<DialogTitle sx={{ color: 'white', textAlign: 'center', backgroundColor: '#1A3762' }}>
				Cargar Planilla
				<Box
					sx={{
						position: 'absolute',
						right: 8,
						top: 8
					}}>
					<Tooltip title={'Cerrar'}>
						<IconButton
							sx={{
								color: 'white'
							}}
							onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Tooltip>
				</Box>
			</DialogTitle>

			<DialogContent sx={{ minWidth: '600px' }}>
				<Box padding={2}>
					<FileAttachementManager
						fieldName="attachments"
						disableUploadAction
						value={files}
						onChange={setFiles}
					/>
				</Box>

				<Box>
					{pdfFiles.map((file, id) => {
						return (
							<Box key={id}>
								<Typography
									mb={1}
									variant="h6">
									{file.name}
								</Typography>
								<Document file={file}>
									<Page
										pageNumber={1}
										renderTextLayer={false}
										renderAnnotationLayer={false}
										className="upload-sheet-modal-page"
									/>
								</Document>
							</Box>
						);
					})}

					{imgFiles.map((file, id) => {
						return (
							<Box
								key={id}
								sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
								<Typography
									mb={1}
									sx={{ wordWrap: 'break-word' }}
									variant="h6">
									{file.name}
								</Typography>
								<img
									src={file.src}
									alt="preview"
									style={{ width: '100%', objectFit: 'contain' }}
								/>
							</Box>
						);
					})}
				</Box>
			</DialogContent>

			<DialogActions sx={{ justifyContent: 'center', gap: 2 }}>
				<Button
					disabled={loading}
					variant="contained"
					color="secondary"
					onClick={handleClose}>
					Cancelar
				</Button>

				<Button
					disabled={!files.length || loading}
					variant="contained"
					onClick={handleConfirm}>
					Confirmar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
