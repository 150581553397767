import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { es } from 'date-fns/locale';
import { TextField } from '@mui/material';
import { isValid, isFuture } from 'date-fns';
import { PickersDayProps } from '@mui/x-date-pickers';

interface AppDatePickerProps {
	label?: string;
	value?: Date | null;
	onChange?(date: Date | null): void;
	disableFuture?: boolean;
	size?: 'medium' | 'small';
	disabled?: boolean;
	renderDay?(day: Date, selectedDays: Date[], pickersDayProps: PickersDayProps<Date>): JSX.Element;
	required?: boolean;
	onInvalid?(state: boolean): void;
}

export function getDateFromStr(date?: string) {
	if (!date) return new Date();

	const [y, m, d] = date.split('-');
	if (!y || !m || !d) return new Date();
	return new Date(Number(y), Number(m) - 1, Number(d.slice(0, 2)));
}

const MIN_YEAR = 1900;

export const AppDatePicker = ({
	value,
	label,
	onChange,
	disableFuture,
	size,
	renderDay,
	disabled,
	required,
	onInvalid
}: AppDatePickerProps) => {
	const [date, setDate] = React.useState<Date | null>(null);
	const [isValidDate, setIsValidDate] = React.useState(true);

	const dateValidity = (date: Date) => {
		const validity = isValid(date) && date.getFullYear() >= MIN_YEAR;
		return disableFuture ? validity && !isFuture(date) : validity;
	};

	const handleChange = (newValue: Date | null) => {
		if (!onChange || !newValue) return setIsValidDate(true);

		const isNewDateValid = dateValidity(newValue);

		onInvalid?.(!isNewDateValid);
		setIsValidDate(isNewDateValid);

		if (!isNewDateValid) return onChange(null);

		setDate(newValue);
		onChange(newValue);
	};

	React.useEffect(() => setDate(value as Date), [value]);

	return (
		<LocalizationProvider
			adapterLocale={es}
			dateAdapter={AdapterDateFns}>
			<DatePicker
				disabled={disabled}
				disableFuture={disableFuture}
				label={label}
				inputFormat="dd/MM/yyyy"
				value={date}
				onChange={handleChange}
				renderInput={(params) => (
					<TextField
						{...params}
						fullWidth
						onChange={({ target }) => {
							if (!target.value) {
								setDate(null);
								onChange?.(null);
								onInvalid?.(false);
							}
						}}
						error={!isValidDate}
						required={required}
						InputLabelProps={{ shrink: true }}
						size={size}
						autoComplete="off"
					/>
				)}
				renderDay={renderDay}
			/>
		</LocalizationProvider>
	);
};
