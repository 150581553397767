import { Dispatch, SetStateAction, useState, useEffect } from 'react';

// Hooks
import { useMovContext } from 'hooks/useMovContext';

//Service
import { getNomenclatureCode, getPropertyTypes } from 'services/crudService';
import { BounceLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

// Custom components
import { CustomDialog } from 'components/globals/dialog/CustomDialog';
import { NomenclatureCode } from 'components/globals/nomenclatureCode/nomenClatureCode';
import { QuantityEditInput } from '../quantityInput/QuantityEditInput';
import { UnitPriceInput } from '../unitPriceInput/UnitPriceInput';
import { DescriptionInput } from '../generico/descriptionInput/DescriptionInput';
import PulseLoader from 'react-spinners/PulseLoader';

// Interfaces
import { IStep3Accessories, IStep3GenericValues } from '../interfaces';

//Material UI
import { Typography, Box, Button, Modal } from '@mui/material';
import { MovementStatusType } from 'enums';
import { DialogType, ICustomButtonProps } from 'components/globals/dialog/utils';

const styleModal = {
	position: 'absolute' as const,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '33%',
	minWidth: '532px',
	maxHeight: '95%',
	bgcolor: '#e4e4e4',
	border: '2px solid #000000b9',
	boxShadow: 24
};

interface IModalAccessoriesProps {
	openModal: boolean;
	setOpenModal: Dispatch<SetStateAction<boolean>>;
	rightList: IStep3GenericValues[];
	setRightList: Dispatch<SetStateAction<IStep3GenericValues[]>>;
	selectedRow: IStep3GenericValues;
	selectedAccessoryRow: IStep3Accessories | null;
	setSelectedAccessoryRow: Dispatch<SetStateAction<IStep3Accessories | null>>;
}

interface IPropertyType {
	id: number;
	name: string;
}

export const ModalEditAccessory = ({
	openModal,
	setOpenModal,
	rightList,
	setRightList,
	selectedRow,
	selectedAccessoryRow,
	setSelectedAccessoryRow
}: IModalAccessoriesProps) => {
	const [open, setOpen] = useState(true);
	const [nomenclatureCode, setNomenclatureCode] = useState(selectedAccessoryRow?.nomenclatureCode || '');
	const [nomenclatureCodeId, setNomenclatureCodeId] = useState(selectedAccessoryRow?.nomenclatureCodeId || 0);
	const [description, setDescription] = useState(selectedAccessoryRow?.description || '');
	const [quantity, setQuantity] = useState(selectedAccessoryRow?.quantity.toString() || '');
	const [unitPrice, setUnitPrice] = useState(selectedAccessoryRow?.unitPrice || '');
	const [loading, setLoading] = useState(false);
	const [opacity, setOpacity] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [submited, setSubmited] = useState(false);
	const [nomenclatureDescription, setNomenclatureDescription] = useState('');
	const [propertyType, setPropertyType] = useState<IPropertyType>();

	const [showDeleteDialog, setShowDeleteDialog] = useState(false);

	const movContext = useMovContext();

	useEffect(() => {
		getNomenclatureCode(selectedRow.nomenclatureCode).then((res) => {
			if (res.data[0]) {
				setNomenclatureDescription(res.data[0].description);
			}
		});
	}, [selectedRow]);

	useEffect(() => {
		getPropertyTypes().then((res) => {
			const dataNormalized = removeAccents(res.data);
			const type = dataNormalized.find((item: any) => item.name === selectedRow.rightType);
			setPropertyType(type);
		});
	}, []);

	/* Call handleDisabled function when one of the dependencies change. */
	function removeAccents(strArr: any[]) {
		const newArr: any = [];
		strArr.map((item) => {
			newArr.push({ id: item.id, name: item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '') });
		});
		return newArr;
	}

	useEffect(() => {
		handleDisabled();
	}, [nomenclatureCode, description, quantity, unitPrice]);

	useEffect(() => {
		if (selectedAccessoryRow) {
			const selectedItem = selectedRow?.accessories?.find((item) => item.id === selectedAccessoryRow.id);
			if (selectedItem) {
				setNomenclatureCode(selectedItem.nomenclatureCode);
				setNomenclatureCodeId(selectedItem.nomenclatureCodeId);
				setDescription(selectedItem.description);
				setQuantity(String(selectedItem.quantity));
				setUnitPrice(selectedItem.unitPrice);
			}
		}
	}, [selectedAccessoryRow]);

	/**
	 * If any of the four input fields are empty, the button is disabled
	 * @returns void
	 */
	const handleDisabled = (): boolean => {
		setDisabled(true);
		if (nomenclatureCode.trim().length === 0) {
			return true;
		}
		if (description.trim().length === 0) {
			return true;
		}
		if (quantity.trim().length === 0) {
			return true;
		}
		if (unitPrice?.trim().length === 0) {
			return true;
		}
		setDisabled(false);
		return false;
	};

	const handleClose = () => {
		setSelectedAccessoryRow(null);
		setOpen(false);
		setOpenModal(false);
	};

	const handleLoading = () => {
		setLoading(true);
		setTimeout(() => {
			setOpacity(true);
		}, 300);
		setTimeout(() => {
			handleClose();
			toast(`Accesorio modificado correctamente`, { type: 'success', autoClose: 2000 });
			setSelectedAccessoryRow(null);
		}, 2000);
	};
	const handleLoadingDelete = () => {
		setLoading(true);
		setTimeout(() => {
			setOpacity(true);
		}, 300);
		setTimeout(() => {
			handleClose();
			toast(`Accesorio eliminado correctamente`, { type: 'success', autoClose: 2000 });
			setSelectedAccessoryRow(null);
		}, 2000);
	};

	const handleAddAccessories = () => {
		handleLoading();
		const accessoryExistDB = movContext?.editMovement?.property_registration?.properties_items?.find(
			(item) => item.temporary_id === selectedAccessoryRow?.temporary_id
		);
		const newRightList = [...rightList];
		const oldItem = newRightList.find((item) => item.id === selectedRow.id);
		if (oldItem !== undefined && oldItem?.accessories) {
			if (oldItem.accessories.length > 0) {
				const newAccesory = oldItem?.accessories?.find((item) => item.temporary_id === selectedAccessoryRow?.temporary_id);
				if (newAccesory && accessoryExistDB) {
					newAccesory.nomenclatureCode = nomenclatureCode;
					newAccesory.nomenclatureCodeId = nomenclatureCodeId;
					newAccesory.description = description;
					newAccesory.quantity = parseInt(quantity);
					newAccesory.unitPrice = unitPrice;
					newAccesory.operation = 'update';
					addorUpdateAccessoryContext(newAccesory);
				}
				if (newAccesory && !accessoryExistDB) {
					newAccesory.nomenclatureCode = nomenclatureCode;
					newAccesory.nomenclatureCodeId = nomenclatureCodeId;
					newAccesory.description = description;
					newAccesory.quantity = parseInt(quantity);
					newAccesory.unitPrice = unitPrice;
					addorUpdateAccessoryContext(newAccesory);
				}
			}
		}
		setRightList([...newRightList]);
		setSubmited(true);
		setSelectedAccessoryRow(null);
	};

	const addorUpdateAccessoryContext = (accessory: any) => {
		const listInContext = movContext?.accessoryFromTable;
		const itemInContext = listInContext?.find((item) => item.temporary_id === accessory?.temporary_id);
		if (itemInContext) {
			const newList = listInContext?.filter((item) => item.temporary_id !== itemInContext.temporary_id);
			if (newList) {
				newList?.push(accessory);
				movContext?.setAccessoryFromTable(newList);
			}
			return;
		}
		if (!itemInContext) {
			movContext?.setAccessoryFromTable((prev) => [...prev, accessory]);
		}
	};

	const handleDeleteAccessories = () => {
		handleLoadingDelete();
		const newRightList = [...rightList];
		const oldItem = newRightList.find((item) => item.id === selectedRow.id);
		if (oldItem !== undefined && oldItem?.accessories) {
			if (oldItem.accessories.length > 0) {
				const newList = oldItem?.accessories?.filter((item) => item.temporary_id !== selectedAccessoryRow?.temporary_id);
				oldItem.accessories = newList;
			}
		}
		deleteAccessoryContext();
		setRightList([...newRightList]);
		setSubmited(true);
		setSelectedAccessoryRow(null);
	};

	const deleteAccessoryContext = () => {
		const listInContext = movContext?.accessoryFromTable;
		const itemInContext = listInContext?.find((item) => item.temporary_id === selectedAccessoryRow?.temporary_id);
		if (itemInContext && itemInContext.is_accessory) {
			if (itemInContext.operation === 'create' || itemInContext.operation === 'update') {
				const newList = listInContext?.filter((item) => item.temporary_id !== selectedAccessoryRow?.temporary_id);
				if (newList) {
					movContext?.setAccessoryFromTable(newList);
				} else {
					movContext?.setAccessoryFromTable([]);
				}
				return;
			}
		}
		if (!itemInContext && selectedAccessoryRow) {
			selectedAccessoryRow.operation = 'delete';
			movContext?.setAccessoryFromTable((prev) => [selectedAccessoryRow, ...prev]);
		}
	};

	const dialogDelete: ICustomButtonProps[] = [
		{
			name: 'Si',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				handleDeleteAccessories();
			}
		},
		{
			name: 'No',
			color: 'secondary',
			variant: 'contained',
			onClick: () => {
				return;
			}
		}
	];

	return (
		<div>
			<Modal
				keepMounted
				open={openModal}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<Box sx={styleModal}>
					<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
						<>
							<div style={{ width: '100%' }}>
								<Typography
									sx={{ p: 3, backgroundColor: 'rgb(51, 82, 100)', color: 'white', textAlign: 'center' }}
									fontStyle={'italic'}
									id="modal-modal-title"
									variant="h6">
									Editar Accesorio
								</Typography>
							</div>
							<div style={{ backgroundColor: '#79888f53', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<Typography
									variant="h6"
									fontStyle={'italic'}
									id="modal-modal-description"
									sx={{ mt: 1, fontWeight: '600' }}>
									{`Codigo Nomenclador: ${selectedRow.nomenclatureCode}`}
								</Typography>
								{nomenclatureDescription?.trim().length > 0 ? (
									<Typography
										sx={{ p: 1 }}
										id="modal-modal-title"
										variant="subtitle1"
										textAlign={'center'}>
										{nomenclatureDescription}
									</Typography>
								) : (
									<PulseLoader
										color={'rgb(51, 82, 100)'}
										loading={true}
										size={15}
										cssOverride={{ padding: '15px 10px' }}
									/>
								)}
							</div>
							<Box
								sx={{
									minWidth: '532px',
									width: '100%',
									pl: 3,
									pr: 3,
									mt: 2,
									mb: 4,
									display: 'flex',
									flexWrap: 'wrap',
									rowGap: '50px',
									columnGap: '20px'
								}}>
								<div style={{ width: '100%' }}>
									<NomenclatureCode
										value={nomenclatureCode}
										submited={submited}
										setNomenclatureCode={setNomenclatureCode}
										setNomenclatureCodeId={setNomenclatureCodeId}
									/>
								</div>
								<div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '15px' }}>
									<QuantityEditInput
										value={quantity}
										submited={submited}
										setQuantity={setQuantity}
										disabled={true}
									/>
									<UnitPriceInput
										value={unitPrice}
										submited={submited}
										setUnitPrice={setUnitPrice}
									/>
								</div>

								<DescriptionInput
									value={description}
									submited={submited}
									setSubmited={setSubmited}
									required={true}
									setDescription={setDescription}
								/>
							</Box>
							<Box sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<Box>
									<Button
										disabled={disabled}
										variant="contained"
										color="primary"
										onClick={handleAddAccessories}>
										{selectedAccessoryRow ? 'Guardar cambios' : 'Agregar'}
									</Button>
									<Button
										variant="contained"
										color="secondary"
										onClick={handleClose}
										sx={{ ml: 2, width: '160px' }}>
										Cancelar
									</Button>
								</Box>
								{movContext?.editMovement?.movement_status_id === MovementStatusType.PENDIENTE ? (
									<Button
										variant="contained"
										color="warning"
										onClick={() => setShowDeleteDialog(true)}
										sx={{ m: 2, mt: 4, width: '150px' }}>
										Eliminar Item
									</Button>
								) : null}
							</Box>
						</>

						{loading && (
							<Box
								sx={{
									zIndex: '9999',
									opacity: opacity ? '1' : '0',
									position: 'absolute',
									backdropFilter: 'blur(2px)',
									height: '100%',
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									transition: 'all 800ms ease-in-out'
								}}>
								<BounceLoader
									size={100}
									speedMultiplier={1.5}
								/>
							</Box>
						)}
					</Box>
					{showDeleteDialog && (
						<CustomDialog
							type={DialogType.SECONDARY}
							Custombuttons={dialogDelete}
							content={'¿Está seguro que desea eliminar el accesorio?'}
							buttonPosition={'center'}
							setShowDialog={setShowDeleteDialog}
						/>
					)}
				</Box>
			</Modal>
		</div>
	);
};
