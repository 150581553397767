import { HeaderForm } from '../../components/globals/headerForm/HeaderForm';
import { memo, useEffect, useState } from 'react';
import { usePresentationSheetContext } from 'hooks/usePresentationSheetContext';
import { useAppContext } from 'hooks/useAppContext';
import { Spinner } from 'components/globals/spinner/Spinner';
import { Box, Grid, Typography } from '@mui/material';
import { PresentationSheetTable } from 'components/presentationSheet/PresentationSheetTable';
import { IPresentationSheet } from 'interfaces/presentationSheet/IPresentationSheet';
import { useAuth } from 'hooks/useAuth';
import { FormGenericSelect } from 'components/globals/inputs/FormGenericSelect';
import { ISelectOption } from 'interfaces/commons/IParameters';
import { DEFAULT_NO_ERROR, requiredValidator } from 'helpers/Validators/Validators';
import { currencyLocalFormat } from 'helpers/Formaters/Formaters';
import { ButtonConfirm, ButtonDecline } from 'components/globals/customsBtn/customBtn';
import { useNavigate } from 'react-router-dom';
import { FormDialog } from 'components/globals/dialog/FormDialog';
import { AttachmentsFiles } from 'components/attachmentsFiles/AttachmentsFiles';
import { CustomDecideDialog } from 'components/globals/dialog/CustomDeleteDialog';
import { IFile } from 'interfaces/file/IFile';

export interface IErrorsForm {
	year?: string | undefined;
	month?: string | undefined;
	movementType?: string | undefined;
}

export const LABELS_PRESENTATION_FORM = {
	year: 'Año',
	month: 'Mes',
	movementType: 'Tipo de Movimiento'
};

const ALL_MONTHS: ISelectOption[] = [
	{ id: 1, label: 'Enero' },
	{ id: 2, label: 'Febrero' },
	{ id: 3, label: 'Marzo' },
	{ id: 4, label: 'Abril' },
	{ id: 5, label: 'Mayo' },
	{ id: 6, label: 'Junio' },
	{ id: 7, label: 'Julio' },
	{ id: 8, label: 'Agosto' },
	{ id: 9, label: 'Septiembre' },
	{ id: 10, label: 'Octubre' },
	{ id: 11, label: 'Noviembre' },
	{ id: 12, label: 'Diciembre' }
];
const ALL_MOVEMENTS_TYPE = [
	{ id: 1, label: 'Alta' },
	{ id: 2, label: 'Baja' }
];
const PresentationSheetPage = memo(() => {
	const presentationContext = usePresentationSheetContext();
	const appContext = useAppContext();
	const auth = useAuth();
	const navigate = useNavigate();

	const [movements, setMovements] = useState<IPresentationSheet[]>([]);
	const [years, setYears] = useState<ISelectOption[]>([]);
	const [months, setMonths] = useState<ISelectOption[]>([]);
	const [movementsType, setMovementsType] = useState<ISelectOption[]>([]);
	const [year, setYear] = useState<ISelectOption | undefined>(undefined);
	const [month, setMonth] = useState<ISelectOption | undefined>(undefined);
	const [movementType, setMovementType] = useState<ISelectOption | undefined>(undefined);
	const [userId, setUserId] = useState<number | undefined>(auth?.user?.id);
	const [errors, setErrors] = useState<IErrorsForm>({} as IErrorsForm);
	const [total, setTotal] = useState<number | bigint>(0);
	const [disabledSend, setDisabledSend] = useState<boolean>(false);

	// Enable or disable send button
	useEffect(() => {
		setDisabledSend(presentationContext?.canSent);
	}, [presentationContext?.canSent, movements]);

	useEffect(() => {
		setMonths(ALL_MONTHS);
		const day = new Date();
		const startYear = 2022;
		const allYears: ISelectOption[] = [];
		for (let index = startYear; index < day.getFullYear() + 1; index++) {
			allYears.push({
				id: index,
				label: index.toString()
			});
		}
		setYears(
			allYears.sort((a: ISelectOption, b: ISelectOption) => {
				return a.id && b.id ? b.id - a.id : 0;
			})
		);
		setMovementsType(ALL_MOVEMENTS_TYPE);
	}, []);

	useEffect(() => {
		setMovements(presentationContext?.presentations);
	}, [presentationContext?.presentations]);

	useEffect(() => {
		setTotal(presentationContext?.total);
	}, [presentationContext?.total]);

	useEffect(() => {
		if (year !== undefined) {
			const selectedYear = year.id;
			const day = new Date();
			if (selectedYear === day.getFullYear()) {
				const filteredMonths: ISelectOption[] = [];
				for (let index = 1; index < day.getMonth() + 2; index++) {
					filteredMonths.push(ALL_MONTHS[index - 1]);
				}
				setMonths(filteredMonths);
			} else {
				setMonths(ALL_MONTHS);
			}
		}
	}, [year]);

	const validateForm = (): boolean => {
		const newErrors: IErrorsForm = {} as IErrorsForm;
		setErrors(newErrors);

		const yearError = requiredValidator({ value: year?.label, label: LABELS_PRESENTATION_FORM.year });
		if (yearError !== DEFAULT_NO_ERROR) {
			newErrors.year = yearError;
		}

		const monthError = requiredValidator({ value: month?.label, label: LABELS_PRESENTATION_FORM.month });
		if (monthError !== DEFAULT_NO_ERROR) {
			newErrors.month = monthError;
		}

		const movementTypeError = requiredValidator({
			value: movementType?.label,
			label: LABELS_PRESENTATION_FORM.movementType
		});
		if (movementTypeError !== DEFAULT_NO_ERROR) {
			newErrors.movementType = movementTypeError;
		}

		if (Object.entries(newErrors).length !== 0) {
			setErrors(newErrors);
			return false;
		}

		return true;
	};

	const onHandleGenerate = async () => {
		if (!validateForm()) {
			return false;
		}

		await presentationContext?.onHandleTryGetAllMovements(
			{
				year: year?.id,
				month: month?.id,
				movement_type: movementType?.id,
				user_id: userId,
				presentation_period: year?.id
			},
			{ page: 1, limit: 10 }
		);
	};

	const onHandleCancel = () => {
		navigate('/home');
	};

	// START Handle Send Modal
	const [showConfirmDialog, toggleConfirmDialog] = useState(false);
	const [showAdjustDialog, toggleAdjustDialog] = useState(false);

	const onHandleSend = async () => {
		if (!validateForm()) {
			return false;
		}
		const fullYeard = new Date().getFullYear();
		if (year?.id !== undefined && year.id < fullYeard) {
			toggleConfirmDialog(false);
			toggleAdjustDialog(true);
			return false;
		}
		const result = await presentationContext?.onHandleTrySendPresentationSheet({
			year: year?.id,
			month: month?.id,
			movement_type: movementType?.id,
			user_id: userId,
			presentation_period: year?.id
		});
		if (result) {
			toggleConfirmDialog(false);
		}
		setTotal(0);
	};

	const confirmSendMovements = async () => {
		toggleAdjustDialog(false);
		return await presentationContext?.onHandleTrySendPresentationSheet({
			year: year?.id,
			month: month?.id,
			movement_type: movementType?.id,
			user_id: userId,
			presentation_period: year?.id
		});
	};
	// END Handle Send Modal

	// START Handle Files Modal
	const [showFilesModal, toggleFilesModal] = useState(false);
	const [selectedRecord, setSelectedRecord] = useState<IPresentationSheet | undefined>(undefined);

	const onHandleShowModalFiles = (entity: IPresentationSheet) => {
		if (!entity) {
			return;
		}
		toggleFilesModal(true);
		setSelectedRecord(entity);
	};

	const onHideModalFiles = () => {
		toggleFilesModal(false);
		setSelectedRecord(undefined);
	};
	// END Handle Files Modal

	return (
		<>
			{appContext?.showSpinner ? <Spinner /> : null}

			<HeaderForm title={'Planilla de Movimiento Mensuales'} />

			{showConfirmDialog && (
				<CustomDecideDialog
					title={'Envío de Planilla Mensual'}
					message={'¿Está seguro de realizar el envío de la Planilla Mensual?'}
					handleSubmit={() => {
						onHandleSend();
					}}
					handleDecline={() => {
						toggleConfirmDialog(false);
					}}
				/>
			)}

			{showAdjustDialog && (
				<CustomDecideDialog
					title={'Envío de Planilla Mensual'}
					message={
						'La planilla mensual ingresará como Ajuste del Ejercicio del año siguiente ¿Está seguro de realizar el envío?'
					}
					handleSubmit={() => {
						confirmSendMovements();
					}}
					handleDecline={() => {
						toggleAdjustDialog(false);
					}}
				/>
			)}

			{showFilesModal && (
				<FormDialog
					show={showFilesModal}
					title={'Documentos adjuntos'}
					content={
						<AttachmentsFiles files={(selectedRecord?.files as IFile[]) ? (selectedRecord?.files as IFile[]) : []} />
					}
					actions={<></>}
					handleClose={(value) => onHideModalFiles()}
				/>
			)}

			<Grid
				container
				spacing={2}
				mt={1}
				mb={1}>
				<Grid
					item
					xs={10}
					sm={10}
					md={10}
					xl={10}
					lg={10}>
					<Grid
						container
						spacing={2}
						direction="row"
						justifyContent="space-between"
						alignItems="center">
						<Grid
							item
							xs={12}
							sm={12}
							md={4}
							xl={4}
							lg={4}>
							<FormGenericSelect
								id={'year'}
								value={year}
								label={LABELS_PRESENTATION_FORM.year}
								handleChange={setYear}
								options={years}
								error={errors.year}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={4}
							xl={4}
							lg={4}>
							<FormGenericSelect
								value={year ? month : undefined}
								label={LABELS_PRESENTATION_FORM.month}
								id="month"
								options={year ? months : []}
								disabled={year === undefined}
								handleChange={setMonth}
								error={errors.month}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={4}
							xl={4}
							lg={4}>
							<FormGenericSelect
								value={month ? movementType : undefined}
								label={LABELS_PRESENTATION_FORM.movementType}
								id="movementType"
								options={month ? movementsType : []}
								disabled={month === undefined}
								handleChange={setMovementType}
								error={errors.movementType}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={2}
					sm={2}
					md={2}
					xl={2}
					lg={2}>
					<ButtonConfirm
						sx={{ ml: 3 }}
						variant="contained"
						onClick={() => onHandleGenerate()}
						disabled={movementType === undefined}>
						Generar Planilla
					</ButtonConfirm>
				</Grid>
			</Grid>

			<Grid
				container
				spacing={2}
				mb={1}
				direction="row"
				justifyContent="flex-start"
				alignItems="center">
				<Grid
					item
					xs={12}
					sm={9}
					md={9}
					xl={9}
					lg={9}
					style={{ textAlign: 'right' }}>
					<Typography
						variant="h6"
						gutterBottom
						p={1}>
						Total General
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					sm={3}
					md={3}
					xl={3}
					lg={3}
					style={{ textAlign: 'center', fontSize: '1.8rem' }}>
					<Typography
						variant="h4"
						gutterBottom
						p={1}>
						{currencyLocalFormat(total)}
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					md={6}
					xl={6}
					lg={6}
					style={{ textAlign: 'left' }}>
					<Typography
						variant="body2"
						sx={{ opacity: 0.8 }}
						gutterBottom>
						Todos los movimientos deben tener su acta cargada para poder enviar la planilla mensual. *
					</Typography>
				</Grid>
			</Grid>

			<Box sx={{ width: '100%', typography: 'body1' }}>
				<PresentationSheetTable
					elements={movements}
					handleAttachments={(entity: IPresentationSheet) => {
						onHandleShowModalFiles(entity);
					}}
				/>
			</Box>

			<Box
				sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
				<ButtonDecline
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => {
						onHandleCancel();
					}}>
					Cancelar
				</ButtonDecline>
				<ButtonConfirm
					sx={{ ml: 3 }}
					variant="contained"
					disabled={disabledSend}
					onClick={() => {
						if (!validateForm()) {
							return false;
						}
						toggleConfirmDialog(true);
					}}>
					Enviar
				</ButtonConfirm>
			</Box>
		</>
	);
});

PresentationSheetPage.displayName = 'Planilla de Presentación';

export { PresentationSheetPage };
