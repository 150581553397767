export enum MovementsStatus {
	PENDIENTE = 1,
	APROBADO = 2,
	ENVIADO = 3,
	OBSERVADO = 4,
	CONSOLIDADO = 5,
	RECTIFICADO = 6,
	OBSERVADO_PROVISORIO = 7,
	APROBADO_PROVISORIO = 8
}
