import { createContext, SetStateAction, Dispatch } from 'react';
import { IMeta } from 'interfaces/Pagination';
import { INotifications } from 'interfaces/notifications/INotifications';

export interface INovedadesContext {
	meta: IMeta;
	setMeta: Dispatch<SetStateAction<IMeta>>;
	notifications: INotifications[];
	setNotifications: Dispatch<SetStateAction<INotifications[]>>;
	readFilter: number;
	setReadFilter: Dispatch<SetStateAction<number>>;
	startDate: string;
	setStartDate: Dispatch<SetStateAction<string>>;
	endDate: string;
	setEndDate: Dispatch<SetStateAction<string>>;
	updateNotifications: IUpdateNotifications;
	setUpdateNotifications: Dispatch<SetStateAction<IUpdateNotifications>>;
}

export interface INovedadesFilters {
	status_id?: number;
	date_from?: string;
	date_to?: string;
}

export interface IUpdateNotifications {
	notification_id: number;
	status_id: number;
}

export const NovedadesContext = createContext<INovedadesContext>(null!);
