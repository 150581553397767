import { FC, useState, useEffect } from 'react';
import style from './generic.module.css';
import lodash from 'lodash';

import { useMovContext } from 'hooks/useMovContext';
import { QuantityEditInput } from '../quantityInput/QuantityEditInput';
import { UnitPriceInput } from '../unitPriceInput/UnitPriceInput';
import { DescriptionInput } from './descriptionInput/DescriptionInput';
import { NomenclatureCode } from 'components/globals/nomenclatureCode/nomenClatureCode';

import { v4 as uuidv4 } from 'uuid';
import { IStep3Rights } from '../interfaces';
import { IStep3Accessories } from '../interfaces';
import { toast } from 'react-toastify';

import { Box, FormControlLabel, Switch, Button } from '@mui/material';

export const Generic: FC<IStep3Rights> = ({ rightList, setRightList }) => {
	const [accessories, setAccessories] = useState<IStep3Accessories[]>([]);
	const [nomenclatureCode, setNomenclatureCode] = useState('');
	const [nomenclatureCodeId, setNomenclatureCodeId] = useState<number>(0);
	const [description, setDescription] = useState('');
	const [quantity, setQuantity] = useState('');
	const [unitPrice, setUnitPrice] = useState('');
	const [inventoriable, setInventoriable] = useState(true);
	const [disabled, setDisabled] = useState(true);
	const [submited, setSubmited] = useState(false);

	const movContext = useMovContext();

	const rightType = 'Genérico';

	/* Call handleDisabled function when one of the dependencies change. */
	useEffect(() => {
		handleDisabled();
	}, [nomenclatureCode, description, quantity, unitPrice, inventoriable]);

	/**
	 * If any of the four input fields are empty, the button is disabled
	 * @returns void
	 */
	const handleDisabled = (): boolean => {
		setDisabled(true);
		if (nomenclatureCode.trim().length === 0) {
			return true;
		}
		if (description.trim().length === 0 || description.trim().length >= 254) {
			return true;
		}
		if (quantity.trim().length === 0) {
			return true;
		}
		if (unitPrice.trim().length === 0) {
			return true;
		}
		setDisabled(false);
		return false;
	};

	/**
	 * If the nomenclatureCode, description, quantity, and unitPrice are not empty, then add a new item to
	 * the rightList
	 *
	 * @returns void
	 */
	const handleSubmit = () => {
		if (nomenclatureCode.trim().length === 0) {
			return;
		}
		if (description.trim().length === 0) {
			return;
		}
		if (quantity.trim().length === 0) {
			return;
		}
		if (unitPrice.trim().length === 0) {
			return;
		}

		const list = [...rightList];
		const newItem = {
			rightType,
			temporary_id: uuidv4(),
			nomenclatureCode,
			nomenclatureCodeId,
			description,
			quantity,
			unitPrice,
			inventoriable,
			inventoriableNumber: '',
			operation: 'create'
		};
		list.unshift(newItem);
		movContext?.setItemsFromTable((prev) => [...prev, newItem]);
		toast('Bien agregado correctamente', { type: 'success', autoClose: 2000 });
		setRightList([...list]);
		setSubmited(true);
	};

	const debouncedSubmit = lodash.debounce(handleSubmit, 1000);

	return (
		<>
			<form style={{ maxWidth: '1000px', minHeight: '370px' }}>
				<div>
					<FormControlLabel
						className={style.switchLabel}
						control={
							<Switch
								checked={inventoriable}
								name="inventariable"
								onClick={() => setInventoriable(!inventoriable)}
							/>
						}
						label="Inventariable"
						sx={{ width: '100%', mt: '-40px', ml: '265px' }}
					/>
				</div>
				<div>
					<Box sx={{ mt: 1, mb: 5, display: 'flex', flexWrap: 'wrap' }}>
						<NomenclatureCode
							submited={submited}
							setNomenclatureCode={setNomenclatureCode}
							setNomenclatureCodeId={setNomenclatureCodeId}
						/>
						<div style={{ display: 'flex', marginLeft: '15px' }}>
							<QuantityEditInput
								submited={submited}
								setQuantity={setQuantity}
							/>
							<div style={{ marginLeft: '50px' }}>
								<UnitPriceInput
									submited={submited}
									setUnitPrice={setUnitPrice}
								/>
							</div>
						</div>
					</Box>
				</div>
				<div>
					<DescriptionInput
						setSubmited={setSubmited}
						submited={submited}
						setDescription={setDescription}
						required={true}
					/>
				</div>
				<div>
					<Button
						disabled={disabled}
						sx={{ mt: 4 }}
						variant="contained"
						onClick={debouncedSubmit}>
						Agregar Bien
					</Button>
				</div>
			</form>
			<div>
				<hr
					className="solid"
					style={{ borderTop: '1px solid #5c5c5c71' }}
				/>
			</div>
		</>
	);
};
