import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { IPaso } from 'components/EncPatrimonial/altaStepper/AltaStepper';
import { ResumePaso1 } from './ResumePaso1';
import { ResumenPaso2 } from './ResumenPaso2';
import { ResumenPaso3 } from './resumenPaso3/ResumenPaso3';
import { v4 as uuidv4 } from 'uuid';

import { CreateAssetRegistration, Item } from 'interfaces/crud/createHighData';

import { setAccessories, setItems } from './utils';
import { IHighDataAssets } from 'interfaces/crud/highDataAssets';
export interface EditMovMapper {
	paso1: IPaso;
	paso2: IPaso;
	paso3: IPaso;
	setPaso4: Dispatch<SetStateAction<CreateAssetRegistration | null>>;
	paso4Assets: IHighDataAssets;
	setPaso4Assets: Dispatch<SetStateAction<IHighDataAssets>>;
}

export const EditMovMapper = ({ paso1, paso2, paso3, setPaso4, setPaso4Assets }: EditMovMapper) => {
	let dataMapped: CreateAssetRegistration = {};
	const today = new Date();
	const items: Item[] = [];

	setItems(paso3, items);
	setAccessories(paso3, items);
	const paso4AssetsValues: IHighDataAssets = {} as IHighDataAssets;
	if (paso2?.data?.adjuntos) {
		if (Object.keys(paso2?.data?.adjuntos).length > 0) {
			const adjuntos: File[] = [];
			Object.keys(paso2.data.adjuntos).forEach((key) => {
				adjuntos.push(paso2.data.adjuntos[key]);
			});
			paso4AssetsValues.attachments = adjuntos;
		}
	}
	if (paso1.data.tipoAlta === 'Adquisición') {
		let dateLegalInst = today;
		if (paso1.data.fechaInstLegal) {
			dateLegalInst = new Date(paso1?.data?.fechaInstLegal);
		}
		if (paso1?.data?.file) {
			paso4AssetsValues.reception = paso1?.data?.file;
		}
		if (paso1?.data?.observacionFile) {
			paso4AssetsValues.timeless_justification = paso1?.data?.observacionFile;
		}
		const CreateAssetRegistrationValues: CreateAssetRegistration = {
			reception: {
				single_tax_id: paso1.data.cuit,
				invoice_number: paso1.data.ncomprobante,
				high_type_id: paso1.data.idTipoAlta,
				voucher_type_id: paso1.data.comprobante,
				date_voucher: new Date(paso1.data.fecha)?.toISOString(),
				date_legal_instrument: dateLegalInst?.toISOString(),
				description: paso1.data.observacion,
				donor_name: null,
				date_donation: null,
				date_transfer: null,
				to_patrimonial_dependence_id: null
			},
			instruments: {
				procedure: {
					agency_code: paso2.data.expediente.codigo || '0',
					number: paso2.data.expediente.numero || '0',
					year: paso2.data.expediente.anio || '0'
				},
				instrument: {
					instrument_number: paso2.data.instrumentoLegal.numero || '0',
					instrument_year: paso2.data.instrumentoLegal.anio || '0'
				},
				provision: {
					provision_number: paso2.data.ordenProvision.numero || '0',
					provision_year: paso2.data.ordenProvision.anio || '0'
				}
			},
			items
		};

		dataMapped = CreateAssetRegistrationValues;
		/* setDataMapped(CreateAssetRegistrationValues);
			setPaso4(CreateAssetRegistrationValues); */
	}
	if (paso1.data.tipoAlta === 'donacion') {
		//Donacion
		const CreateAssetRegistrationValues: CreateAssetRegistration = {
			reception: {
				single_tax_id: paso1.data.cuit || '',
				donor_name: paso1.data.donante || '',
				high_type_id: paso1.data.idTipoAlta,
				date_donation: today.toISOString(),
				invoice_number: null,
				voucher_type_id: null,
				date_voucher: null,
				date_legal_instrument: null,
				description: null,
				to_patrimonial_dependence_id: null,
				date_transfer: null
			},
			instruments: {
				procedure: {
					agency_code: paso2.data.expediente.codigo || '0',
					number: paso2.data.expediente.numero || '0',
					year: paso2.data.expediente.anio || '0'
				},
				instrument: {
					instrument_number: paso2.data.instrumentoLegal.numero || '0',
					instrument_year: paso2.data.instrumentoLegal.anio || '0'
				},
				provision: {
					provision_number: paso2.data.ordenProvision.numero || '0',
					provision_year: paso2.data.ordenProvision.anio || '0'
				}
			},
			items
		};

		dataMapped = CreateAssetRegistrationValues;
		/* 	setDataMapped(CreateAssetRegistrationValues);
			setPaso4(CreateAssetRegistrationValues); */
	}
	if (paso1.data.tipoAlta === 'Transferencia') {
		//Transferencia
		const CreateAssetRegistrationValues: CreateAssetRegistration = {
			reception: {
				to_patrimonial_dependence_id: paso1.data.id,
				date_transfer: new Date(paso1.data.fecha).toISOString(),
				high_type_id: paso1.data.idTipoAlta,
				donor_name: null,
				date_legal_instrument: null,
				single_tax_id: null,
				invoice_number: null,
				voucher_type_id: null,
				date_voucher: null,
				description: null,
				date_donation: null
			},
			instruments: {
				procedure: {
					agency_code: paso2.data.expediente.codigo || '0',
					number: paso2.data.expediente.numero || '0',
					year: paso2.data.expediente.anio || '0'
				},
				instrument: {
					instrument_number: paso2.data.instrumentoLegal.numero || '0',
					instrument_year: paso2.data.instrumentoLegal.anio || '0'
				},
				provision: {
					provision_number: paso2.data.ordenProvision.numero || '0',
					provision_year: paso2.data.ordenProvision.anio || '0'
				}
			},
			items
		};

		dataMapped = CreateAssetRegistrationValues;
	}

	return { reception: dataMapped.reception, instruments: dataMapped.instruments };
};
