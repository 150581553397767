import { FormGenericInput } from '../inputs/FormGenericInput';
import { toast } from 'react-toastify';

interface QuantityInputProps {
	required?: boolean;
	disabled?: boolean;
	value?: string;
	onChange?(value: string): void;
	label?: string;
	size?: 'small' | 'medium';
	maxLength?: number;
}

export const QuantityInput = ({ disabled, value, onChange, required, label, size, maxLength }: QuantityInputProps) => {
	const handleQuantity = (quantity: any) => {
		let value = quantity;

		if (value.includes('.')) {
			value = value.split('.')[0];
		}

		onChange?.(value);

		if ((Number(value) > 500 || Number(value) < 1) && value !== '') {
			toast.error('El valor de cantidad permitido es entre 1 a 500 unidades', {
				position: 'top-right',
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true
			});
		}
	};

	return (
		<FormGenericInput
			required={required}
			disabled={disabled}
			maxLength={maxLength}
			type="number"
			size={size}
			label={label}
			value={value}
			onChange={handleQuantity}
			onKeydown={(event) => event.key === '.' && event.preventDefault()}
		/>
	);
};
