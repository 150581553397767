import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from '../contexts/authContext/AuthProvider';
import {
	Login,
	Home,
	ResetPassword,
	SendResetEmail,
	RegistrationPage,
	UsersPage,
	PresentationSheetPage,
	PresentationSheetAuditPage,
	BalancePage,
	ParametersPage,
	DeregistrationPage
} from '../pages';
import { ProtectedRoutes } from './ProtectedRoutes';
import { Layout } from '../components/globals/layouts';
import { Movements } from 'pages/movement/Movements';
import { MovementEdit } from 'pages/movement';
import { MovProvider } from 'contexts/movementsContext/MovProvider';
import { UsersState } from 'contexts/usersContext/UsersState';
import { RolesState } from 'contexts/rolesContext/RolesState';
import { AppState } from 'contexts/global/AppState';
import { PresentationSheetState } from 'contexts/presentationSheetContext/PresentationSheetState';
import { BalanceState } from 'contexts/balanceContext/BalanceState';
import { DeregistrationState } from 'contexts/deregistrationContext/DeregistrationState';
import { BalanceSummaryPage } from 'pages/balanceSummary/BalanceSummary';
import { PresentationSheetManagement } from 'pages/presentationSheet/PresentationSheetManagement';
import { PresentationSheetGeneration } from 'pages/presentationSheet/PresentationSheetGeneration';

export const Views = () => {
	return (
		<AuthProvider>
			<AppState>
				<MovProvider>
					<Routes>
						{/* Reset password debe ser privada, cuando tengamos endpoint se configurara. */}
						<Route
							path="/reset-password"
							element={<ResetPassword />}
						/>
						<Route
							path="/login"
							element={<Login />}
						/>
						<Route
							path="/login/send-reset-email"
							element={<SendResetEmail />}
						/>
						<Route element={<ProtectedRoutes />}>
							<Route element={<Layout />}>
								<Route
									path="*"
									element={
										<Navigate
											to="home"
											replace
										/>
									}
								/>
								<Route
									path="home"
									element={<Home />}
								/>

								<Route
									path="alta"
									element={<RegistrationPage />}
								/>

								<Route
									path="alta/editar/:registrationId"
									element={<RegistrationPage />}
								/>

								<Route
									path="movimientos/:movement_type"
									element={
										<DeregistrationState>
											<Movements />
										</DeregistrationState>
									}
								/>

								<Route
									path="movimientos/:movement_type/editar"
									element={<MovementEdit />}
								/>
								<Route
									path="configuracion/usuarios"
									element={
										<UsersState>
											<RolesState>
												<UsersPage />
											</RolesState>
										</UsersState>
									}
								/>
								<Route
									path="configuracion/parametros"
									element={<ParametersPage />}
								/>
								<Route
									path="planilla-mensual"
									element={
										<PresentationSheetState>
											<PresentationSheetPage />
										</PresentationSheetState>
									}
								/>

								<Route
									path="planilla-mensual-auditoria"
									element={
										<PresentationSheetState>
											<PresentationSheetAuditPage />
										</PresentationSheetState>
									}
								/>

								<Route
									path="planilla-mensual-gestionar"
									element={
										<PresentationSheetState>
											<PresentationSheetManagement />
										</PresentationSheetState>
									}
								/>

								<Route
									path="planilla-mensual-generar"
									element={
										<PresentationSheetState>
											<PresentationSheetGeneration />
										</PresentationSheetState>
									}
								/>

								<Route
									path="balance/cierre-ejercicio"
									element={
										<BalanceState>
											<BalancePage />
										</BalanceState>
									}
								/>

								<Route
									path="balance/resumen"
									element={
										<BalanceState>
											<BalanceSummaryPage />
										</BalanceState>
									}
								/>

								<Route
									path="baja"
									element={<DeregistrationPage />}
								/>

								<Route
									path="baja/editar/:registrationId"
									element={<DeregistrationPage />}
								/>
							</Route>
						</Route>
					</Routes>
				</MovProvider>
			</AppState>
		</AuthProvider>
	);
};
