import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { GenericFieldDetail } from 'components/globals/fields/FieldDetail';
import { InmuebleFormData } from './Inmueble';
import { currencyLocalFormat } from 'helpers/Formaters/Formaters';

const gridContainerProps = {
	spacing: 2,
	container: true,
	pt: 1
};

interface InmuebleDetailsProps {
	isAccessory: boolean;
	nomenclatureCode: InmuebleFormData['general']['nomenclatureCode'];
	quantity: string;
	unitPrice: number;
	cadestre: InmuebleFormData['cadastre'];
	address: InmuebleFormData['address']['value'];
	registry: InmuebleFormData['registry'];
	inventoryNumber?: string;
	rowNumber?: string;
}

export const InmuebleDetails: React.FC<InmuebleDetailsProps> = ({
	isAccessory,
	nomenclatureCode,
	quantity,
	unitPrice,
	address,
	cadestre,
	registry,
	inventoryNumber,
	rowNumber
}) => {
	return (
		<Grid
			{...gridContainerProps}
			spacing={4}>
			<Grid
				item
				{...gridContainerProps}
				width="100%">
				<Grid item>
					<Typography
						variant="h1"
						fontSize={24}
						fontWeight={500}
						mb={1}>
						{'Datos Generales'}
					</Typography>
				</Grid>

				<Grid
					item
					width={'100%'}>
					<GenericFieldDetail
						label={'Tipo de Bien:'}
						value="Inmueble"
					/>
					<Divider />
				</Grid>

				<Grid
					item
					width={'100%'}>
					<GenericFieldDetail
						label={'Código de Nomenclador:'}
						value={nomenclatureCode?.value}
					/>
					<Divider />
				</Grid>

				<Grid
					item
					width={'100%'}>
					<GenericFieldDetail
						label={'Descripción de Nomenclador:'}
						value={nomenclatureCode?.label}
					/>
					<Divider className="customDivider" />
				</Grid>

				<Grid
					item
					width={'100%'}>
					<GenericFieldDetail
						label={'N° de Inventario:'}
						value={inventoryNumber}
					/>
					<Divider className="customDivider" />
				</Grid>

				<Grid
					item
					width={'100%'}>
					<GenericFieldDetail
						label={'Cantidad:'}
						value={quantity}
					/>
					<Divider />
				</Grid>

				<Grid
					item
					width={'100%'}>
					<GenericFieldDetail
						label={'Precio Unitario:'}
						value={currencyLocalFormat(unitPrice)}
					/>
					<Divider />
				</Grid>

				{rowNumber ? (
					<Grid
						item
						width={'100%'}>
						<GenericFieldDetail
							label={'Renglón:'}
							value={rowNumber}
						/>
						<Divider />
					</Grid>
				) : null}

				{!isAccessory ? (
					<Grid
						item
						width={'100%'}>
						<GenericFieldDetail
							label={'Dirección del Inmueble:'}
							value={address}
						/>
						<Divider />
					</Grid>
				) : null}
			</Grid>

			{!isAccessory ? (
				<>
					<Grid
						{...gridContainerProps}
						marginLeft={0}
						mt={1}
						padding={2}>
						<Grid
							{...gridContainerProps}
							marginLeft={0}
							width={'50%'}>
							<Grid item>
								<Typography
									variant="h1"
									fontSize={24}
									fontWeight={500}
									mb={2}>
									Información de Catastro
								</Typography>
							</Grid>

							<Grid
								item
								width={'100%'}>
								<GenericFieldDetail
									label="Departamento:"
									value={cadestre.department?.label}
								/>
								<Divider />
							</Grid>

							<Grid
								item
								width={'100%'}>
								<GenericFieldDetail
									label="Municipio:"
									value={cadestre.municipality?.label}
								/>
								<Divider />
							</Grid>

							<Grid
								item
								width={'100%'}>
								<GenericFieldDetail
									label="Sección:"
									value={cadestre.section}
								/>
								<Divider />
							</Grid>

							<Grid
								item
								width={'100%'}>
								<GenericFieldDetail
									label="Parcela:"
									value={cadestre.parcel}
								/>
								<Divider />
							</Grid>

							<Grid
								item
								width={'100%'}>
								<GenericFieldDetail
									label="Chacra:"
									value={cadestre.ranch}
								/>
								<Divider />
							</Grid>

							<Grid
								item
								width={'100%'}>
								<GenericFieldDetail
									label="Manzana:"
									value={cadestre.block}
								/>
								<Divider />
							</Grid>

							<Grid
								item
								width={'100%'}>
								<GenericFieldDetail
									label="Unidad Funcional:"
									value={cadestre.functionalUnit}
								/>
								<Divider />
							</Grid>

							<Grid
								item
								width={'100%'}>
								<GenericFieldDetail
									label="Número de Plano:"
									value={cadestre.planeNumber}
								/>
								<Divider />
							</Grid>

							<Grid
								item
								width={'100%'}>
								<GenericFieldDetail
									label="Partida Inmobiliaria:"
									value={cadestre.realEstateItem}
								/>
								<Divider />
							</Grid>
						</Grid>

						<Grid
							{...gridContainerProps}
							marginLeft={0}
							width={'50%'}>
							<Grid item>
								<Typography
									variant="h1"
									fontSize={24}
									fontWeight={500}
									mb={2}>
									Información Registral
								</Typography>
							</Grid>

							<Grid
								item
								width={'100%'}>
								<GenericFieldDetail
									label="Departamento:"
									value={registry.department}
								/>
								<Divider />
							</Grid>

							<Grid
								item
								width={'100%'}>
								<GenericFieldDetail
									label="Número de Matrícula:"
									value={registry.registrationNumber}
								/>
								<Divider />
							</Grid>

							<Grid
								item
								width={'100%'}>
								<GenericFieldDetail
									label="Tomo:"
									value={registry.tome}
								/>
								<Divider />
							</Grid>

							<Grid
								item
								width={'100%'}>
								<GenericFieldDetail
									label="Folio:"
									value={registry.invoice}
								/>
								<Divider />
							</Grid>

							<Grid
								item
								width={'100%'}>
								<GenericFieldDetail
									label="Finca:"
									value={registry.farm}
								/>
								<Divider />
							</Grid>

							<Grid
								item
								width={'100%'}>
								<GenericFieldDetail
									label="Propietario:"
									value={registry.owner}
								/>
								<Divider />
							</Grid>

							<Grid
								item
								width={'100%'}>
								<GenericFieldDetail
									label="Descripción del Inmueble:"
									value={registry.propertyDescription}
								/>
								<Divider />
							</Grid>

							<Grid
								item
								width={'100%'}>
								<GenericFieldDetail
									label="Observaciones:"
									value={registry.observations}
								/>
								<Divider />
							</Grid>

							<Grid
								item
								sx={{ visibility: 'hidden' }}
								width={'100%'}>
								<GenericFieldDetail
									label="Observaciones"
									value={registry.observations}
								/>
								<Divider />
							</Grid>
						</Grid>
					</Grid>
				</>
			) : null}
		</Grid>
	);
};
