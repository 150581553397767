import { ColumnDef } from '@tanstack/react-table';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { currencyLocalFormat } from 'helpers/Formaters/Formaters';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { useState } from 'react';

export const defaultColumns: ColumnDef<any, any>[] = [
	{
		accessorKey: 'property.item_inventory_number',
		enableSorting: true,
		header: () => <span>N° de Inventario</span>,
		cell: ({ getValue }) => <p style={{ textAlign: 'center', paddingLeft: '10px' }}>{getValue() as string}</p>
	},
	{
		accessorKey: 'property.account_nomenclature.account',
		accessorFn: (row) => row?.property?.account_nomenclature?.account,
		header: () => <span>Código de Nomenclador</span>,
		cell: ({ row: { original } }) => (
			<p style={{ textAlign: 'center', paddingLeft: '10px' }}>
				{original?.property?.account_nomenclature?.account ??
					original?.property?.account_nomenclature?.sub_group ??
					'-'}
			</p>
		)
	},
	{
		enableColumnFilter: false,
		accessorKey: 'property.accessory_number',
		accessorFn: (row) => row?.property?.accessory_number,
		header: () => <span>N° de Accesorio</span>,
		cell: ({ row: { original } }) => (
			<p style={{ textAlign: 'center', paddingLeft: '10px' }}>{original?.property?.accessory_number || '-'}</p>
		)
	},
	{
		enableColumnFilter: false,
		accessorKey: 'nomenclature_description',
		header: () => <span>Descripción de Nomenclador</span>,
		cell: ({ row: { original } }) => {
			const [show, setShow] = useState(false);
			const value = original?.property?.nomenclature_description || '';

			const styles = {
				whiteSpace: show ? 'normal' : 'nowrap',
				width: '220px',
				overflow: show ? 'visible' : 'hidden',
				textOverflow: 'ellipsis',
				textAlign: 'left'
			};

			return (
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						padding: 1,
						overflow: 'hidden',
						wordBreak: 'break-word',
						justifyContent: 'space-between',
						alignItems: 'flex-start'
					}}>
					<Typography sx={styles}>{value}</Typography>

					{value.length >= 30 ? (
						<Button
							disableElevation
							disableRipple
							disableFocusRipple
							variant="text"
							onClick={() => setShow((v) => !v)}
							size="small"
							sx={{ padding: 0, minWidth: 'auto' }}>
							{show ? <ArrowDropUp /> : <ArrowDropDown />}
						</Button>
					) : null}
				</Box>
			);
		}
	},
	{
		enableColumnFilter: false,
		accessorKey: 'unit_price',
		header: () => <span>Precio Unitario</span>,
		cell: ({ row: { original } }) => (
			<p style={{ textAlign: 'right', paddingRight: '10px' }}>
				{original?.property?.unit_price !== undefined && original?.property?.unit_price !== null
					? currencyLocalFormat(original.property.unit_price)
					: '-'}
			</p>
		)
	},
	{
		accessorKey: 'actions',
		enableSorting: false,
		enableGlobalFilter: false,
		enableColumnFilter: false,
		header: () => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>Acciones</span>
			</div>
		),
		cell: ({ row, table }) => (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'nowrap',
					width: '100%',
					justifyContent: 'space-around'
				}}>
				<IconButton
					onClick={() => {
						row.original.action = 'show';
					}}
					size="small"
					sx={{
						transition: 'all 200ms ease',
						'&:hover': {
							transform: 'scale(1.3)',
							transition: 'all 200ms ease'
						}
					}}>
					<Tooltip
						placement="right-start"
						arrow
						title="Ver Detalle">
						<VisibilityIcon color="info" />
					</Tooltip>
				</IconButton>

				<IconButton
					onClick={() => {
						row.original.action = 'edit';
					}}
					size="small"
					sx={{
						transition: 'all 200ms ease',
						'&:hover': {
							transform: 'scale(1.3)',
							transition: 'all 200ms ease'
						}
					}}>
					<Tooltip
						placement="right-start"
						arrow
						title="Editar">
						<EditIcon color="success" />
					</Tooltip>
				</IconButton>

				<IconButton
					onClick={() => {
						row.original.action = 'delete';
					}}
					size="small"
					sx={{
						transition: 'all 200ms ease',
						'&:hover': {
							transform: 'scale(1.3)',
							transition: 'all 200ms ease'
						}
					}}>
					<Tooltip
						placement="right-start"
						arrow
						title="Eliminar">
						<DeleteIcon color="error" />
					</Tooltip>
				</IconButton>
			</div>
		)
	}
];
