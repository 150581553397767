import { useState, useEffect, memo, Dispatch, SetStateAction } from 'react';
import useDebounce from 'hooks/useDebounce';
import { TextField, Typography } from '@mui/material';
import { useMovContext } from 'hooks/useMovContext';
import { MovementStatusType } from 'enums';

interface IQuantityEditInput {
	value?: string;
	setQuantity: Dispatch<SetStateAction<string>>;
	submited: boolean;
	style?: object;
	disabled?: boolean;
}

const QuantityEditInput = memo(({ disabled, value, setQuantity, submited, style }: IQuantityEditInput) => {
	const [quantity, setQuantityChild] = useState(value || '');
	const debouncedValue = useDebounce<string>(quantity, 100);
	const movContext = useMovContext();

	useEffect(() => {
		if (value) {
			setQuantityChild(value);
		}
	}, [value]);

	useEffect(() => {
		if (submited) {
			setQuantity('');
			setQuantityChild('');
		}
	}, [submited]);

	useEffect(() => {
		setQuantity(quantity);
	}, [debouncedValue]);

	const handleQuantityChange = (value: string) => {
		if (!RegExp(/^[0-9]*$/).test(value)) return;
		setQuantityChild(value);
	};

	return (
		<div style={style ? { ...style, marginRight: '40px' } : {}}>
			<TextField
				disabled={movContext?.editMovement?.movement_status_id !== MovementStatusType.PENDIENTE || disabled}
				type="text"
				sx={{ width: '220px', marginLeft: '-15px' }}
				value={quantity}
				name="quantity"
				id="quantity"
				label="Cantidad *"
				size="small"
				onChange={(evt) => handleQuantityChange(evt.target.value)}
			/>
		</div>
	);
});

QuantityEditInput.displayName = 'QuantityEditInput';

export { QuantityEditInput };
