import { ColumnDef } from '@tanstack/react-table';
import { Chip, IconButton, Tooltip } from '@mui/material';
import { currencyLocalFormat, parsedFormatDate } from 'helpers/Formaters/Formaters';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import HistoryIcon from '@mui/icons-material/History';
import SellIcon from '@mui/icons-material/Sell';
import { usePresentationSheetContext } from 'hooks/usePresentationSheetContext';

export const defaultColumns: ColumnDef<any, any>[] = [
	// {
	// 	accessorKey: 'nomenclator_code',
	// 	enableSorting: true,
	// 	header: () => <span>Código</span>,
	// 	cell: ({ getValue }) => <p style={{ textAlign: 'center', paddingLeft: '10px' }}>{getValue() as string}</p>
	// },
	{
		accessorKey: 'item_inventory_number',
		enableSorting: true,
		accessorFn: (row) => String(row.item_inventory_number),
		header: () => <span>Nro. de Inventario</span>,
		//cell: ({ row }) => <p style={{ textAlign: 'center', paddingLeft: '10px' }}>{String(row.original.item_inventory_number)}</p>
		cell: ({ row: { original } }) => {
			let arr = original.item_inventory_number.split(', ');
			let min = Math.min(...arr);
			let max = Math.max(...arr);
			let result = '';
			if (arr.length > 1) {
				result = String(min) + ' - ' + String(max);
			} else {
				result = original.item_inventory_number;
			}

			return <p>{result}</p>;
		}
	},
	// {
	// 	accessorKey: 'description',
	// 	header: () => <span>Descripción del Bien</span>,
	// 	cell: ({ getValue }) => <p style={{ textAlign: 'justify', paddingLeft: '10px' }}>{getValue() as string}</p>
	// },
	{
		accessorKey: 'date',
		header: () => <span>Fecha de Alta</span>,
		//cell: ({ getValue }) => <p style={{ textAlign: 'center', paddingLeft: '10px' }}>{parsedFormatDate(getValue())}</p>
		cell: ({ row: { original } }) => {
			return <p>{parsedFormatDate(original.date)}</p>;
		}
	},
	{
		accessorKey: 'quantity',
		accessorFn: (row) => String(row.quantity),
		header: () => <span>Cantidad</span>,
		cell: ({ row }) => <p style={{ textAlign: 'center', paddingLeft: '10px' }}>{String(row.original.quantity)}</p>
	},
	// {
	// 	accessorKey: 'price',
	// 	header: () => <span>Precio Unitario</span>,
	// 	cell: ({ getValue }) => <p style={{ textAlign: 'right', paddingLeft: '10px' }}>{currencyLocalFormat(getValue())}</p>
	// },
	{
		accessorKey: 'total',
		header: () => <span>Importe Total</span>,
		cell: ({ getValue }) => (
			<p style={{ textAlign: 'center', paddingLeft: '10px' }}>{currencyLocalFormat(getValue())}</p>
		)
	},
	{
		accessorKey: 'reception_number',
		header: () => <span>Nro. Acta Recepción</span>,
		cell: ({ getValue }) => <p style={{ textAlign: 'center', paddingLeft: '10px' }}>{getValue() as string}</p>
	},
	{
		accessorKey: 'reponsability',
		header: () => <span>Subresponsable</span>,
		cell: ({ getValue }) => <p style={{ textAlign: 'left', paddingLeft: '10px' }}>{getValue() as string}</p>
	},
	{
		accessorKey: 'status_label',
		accessorFn: (row) => String(row?.status_label ? row.status_label : ''),
		header: () => <span>Estado</span>,
		cell: ({ getValue }) => (
			<>
				<p style={{ textAlign: 'center', paddingLeft: '10px' }}>{getValue() as string}</p>
			</>
		)
	},
	{
		accessorKey: 'actions',
		enableSorting: false,
		enableGlobalFilter: false,
		enableColumnFilter: false,
		header: () => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>Acciones</span>
			</div>
		),
		cell: ({ row }) => {
			const presentationContext = usePresentationSheetContext();
			const isEnableToEditSheetToAudit = presentationContext?.isEnableToEditSheetToAudit();
			const handleToggleStatus = () => {
				if (isEnableToEditSheetToAudit) {
					row.original.action = 'toggleStatus';
				}
			};
			return (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						flexWrap: 'nowrap',
						width: '100%',
						justifyContent: 'space-around'
					}}>
					<IconButton
						onClick={handleToggleStatus}
						size="small"
						sx={{
							transition: 'all 200ms ease',
							'&:hover': {
								transform: 'scale(1.3)',
								transition: 'all 200ms ease'
							}
						}}>
						<Tooltip
							placement="right-start"
							arrow
							title={
								isEnableToEditSheetToAudit
									? 'Cambiar de estado'
									: 'Esta planilla ya fue revisada y se encuentra en estado consolidado. No podra editar los estados ya definidos.'
							}>
							<SellIcon />
						</Tooltip>
					</IconButton>

					<IconButton
						onClick={() => {
							row.original.action = 'attachments';
						}}
						size="small"
						sx={{
							transition: 'all 200ms ease',
							'&:hover': {
								transform: 'scale(1.3)',
								transition: 'all 200ms ease'
							}
						}}>
						<Tooltip
							placement="right-start"
							arrow
							title={'Documentos Adjuntos'}>
							<FolderCopyIcon />
						</Tooltip>
					</IconButton>

					<IconButton
						onClick={() => {
							row.original.action = 'history';
						}}
						size="small"
						sx={{
							transition: 'all 200ms ease',
							'&:hover': {
								transform: 'scale(1.3)',
								transition: 'all 200ms ease'
							}
						}}>
						<Tooltip
							placement="right-start"
							arrow
							title={'Histórico'}>
							<HistoryIcon />
						</Tooltip>
					</IconButton>
				</div>
			);
		}
	}
];
