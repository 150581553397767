import { CSSProperties } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import { Box } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';

const override: CSSProperties = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	marginTop: '-50px',
	marginLeft: '-50px',
	width: '50px',
	height: '50px',
	textAlign: 'center',
	fontSize: '10px',
	zIndex: '9999',
	borderColor: 'black',
	opacity: '1',
	transition: 'all 1s ease-in-out'
};

export const Spinner = () => {
	return (
		<Box
			sx={{
				backdropFilter: 'blur(2.5px)',
				zIndex: '100',
				position: 'fixed',
				background: 'rgba(0,0,0,0.8)',
				top: 0,
				left: 0,
				bottom: 0,
				right: 0,
				opacity: '1',
				transition: 'all 1s ease-in-out'
			}}>
			<BounceLoader
				color={'#E72260'}
				loading={true}
				cssOverride={override}
				size={120}
				speedMultiplier={1}
			/>
		</Box>
	);
};
