import { ColumnDef } from '@tanstack/react-table';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import { currencyLocalFormat } from 'helpers/Formaters/Formaters';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CreditCard, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCard from '@mui/icons-material/AddCard';
import EditIcon from '@mui/icons-material/Edit';
import { PROPERTY_TYPE } from 'enums';
import { Box } from '@mui/system';
import { useState } from 'react';

export const defaultColumns: ColumnDef<any, any>[] = [
	{
		filterFn(col, _, value) {
			const colData = col.original;
			if (!value.length) return true;
			return value.includes(colData.property_id);
		},
		accessorKey: 'property_id',
		header: () => <span>Tipo de Bien</span>,
		cell: ({ row }) => (
			<Typography>
				<span>{PROPERTY_TYPE.find((el) => el.id === row.original.property_id)?.label}</span>
			</Typography>
		)
	},
	{
		accessorKey: 'account_nomenclature_id',
		header: () => <span>Código Nomenclador</span>,
		cell: ({ row }) => <Typography>{row.original.account_nomenclature_id}</Typography>
	},
	{
		enableColumnFilter: false,
		accessorKey: 'description',
		header: () => <span>Descripción</span>,
		cell: ({ getValue, row }) => {
			const [show, setShow] = useState(false);
			const value =
				(getValue()?.trim() === 'undefined undefined' ? null : getValue()?.trim()) || row?.original?.label || '';
			const styles = {
				whiteSpace: show ? 'normal' : 'nowrap',
				width: '220px',
				overflow: show ? 'visible' : 'hidden',
				textOverflow: 'ellipsis',
				textAlign: 'left'
			};

			return (
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						padding: 1,
						overflow: 'hidden',
						wordBreak: 'break-word',
						justifyContent: 'space-between',
						alignItems: 'flex-start'
					}}>
					<Typography sx={styles}>{value}</Typography>

					{value.length >= 30 ? (
						<Button
							disableElevation
							disableRipple
							disableFocusRipple
							variant="text"
							onClick={() => setShow((v) => !v)}
							size="small"
							sx={{ padding: 0, minWidth: 'auto' }}>
							{show ? <ArrowDropUp /> : <ArrowDropDown />}
						</Button>
					) : null}
				</Box>
			);
		}
	},
	{
		enableColumnFilter: false,
		accessorKey: 'inventory_number',
		header: () => <span>Nro. Inventario</span>,
		cell: ({ row }) => {
			return <Box>{row.original.inventory_number || '-'}</Box>;
		}
	},
	{
		enableColumnFilter: false,
		accessorKey: 'unit_price',
		header: () => <span>Precio Unitario</span>,
		cell: ({ row: { original } }) => (
			<p style={{ textAlign: 'right', paddingRight: '10px' }}>
				{original?.unit_price !== undefined && original?.unit_price !== null
					? currencyLocalFormat(original.unit_price)
					: '-'}
			</p>
		)
	},
	{
		enableColumnFilter: false,
		accessorKey: 'quantity',
		header: () => <span>Cantidad</span>,
		cell: ({ getValue }) => <Typography>{getValue() as string}</Typography>
	},
	{
		accessorKey: 'accessories',
		enableColumnFilter: false,
		header: () => <span>Accesorios</span>,
		cell: ({ row: { original } }) => (
			<p style={{ textAlign: 'center' }}>
				{original.accesories_count}

				<IconButton
					disableFocusRipple
					disableRipple
					disableTouchRipple
					color="info"
					disabled={!original.accesories_count}
					onClick={() => {
						original.action = 'show-accessories';
					}}
					size="small">
					<Tooltip
						placement="right-start"
						arrow
						title="Mostrar Accesorios">
						<>
							<CreditCard />
							{original.showAccessories ? <ArrowDropUp /> : <ArrowDropDown />}
						</>
					</Tooltip>
				</IconButton>
			</p>
		)
	},
	{
		accessorKey: 'actions',
		enableSorting: false,
		enableGlobalFilter: false,
		enableColumnFilter: false,
		header: () => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>Acciones</span>
			</div>
		),
		cell: ({ row }) => (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'nowrap',
					width: '100%',
					justifyContent: 'space-around'
				}}>
				<IconButton
					onClick={() => {
						row.original.action = 'show';
					}}
					size="small"
					sx={{
						transition: 'all 200ms ease',
						'&:hover': {
							transform: 'scale(1.3)',
							transition: 'all 200ms ease'
						}
					}}>
					<Tooltip
						placement="right-start"
						arrow
						title="Ver Detalle">
						<VisibilityIcon color="info" />
					</Tooltip>
				</IconButton>

				<IconButton
					onClick={() => {
						row.original.action = 'edit';
					}}
					size="small"
					sx={{
						transition: 'all 200ms ease',
						'&:hover': {
							transform: 'scale(1.3)',
							transition: 'all 200ms ease'
						}
					}}>
					<Tooltip
						placement="right-start"
						arrow
						title="Editar">
						<EditIcon color="success" />
					</Tooltip>
				</IconButton>

				<IconButton
					onClick={() => {
						row.original.action = 'delete';
					}}
					size="small"
					sx={{
						transition: 'all 200ms ease',
						'&:hover': {
							transform: 'scale(1.3)',
							transition: 'all 200ms ease'
						}
					}}>
					<Tooltip
						placement="right-start"
						arrow
						title="Eliminar">
						<DeleteIcon color="error" />
					</Tooltip>
				</IconButton>
				{!row.original.is_accessory && isNaN(row.original.minute_number) ? (
					<IconButton
						onClick={() => {
							row.original.action = 'accessory';
						}}
						size="small"
						sx={{
							transition: 'all 200ms ease',
							'&:hover': {
								transform: 'scale(1.3)',
								transition: 'all 200ms ease'
							}
						}}>
						<Tooltip
							placement="right-start"
							arrow
							title="Agregar Accesorios">
							<AddCard color="primary" />
						</Tooltip>
					</IconButton>
				) : null}
			</div>
		)
	}
];
