import { ReactNode, useEffect, useState } from 'react';

// Hooks
import { useAuth } from 'hooks/useAuth';

// Services
import axios from 'axios';
import { getAllNotifications, updateNotification } from 'services';
import { toast } from 'react-toastify';

// Interfaces
import { IMeta } from 'interfaces/Pagination';
import { INotifications } from 'interfaces/notifications/INotifications';
import { isArray } from 'lodash';
import { NovedadesContext, INovedadesFilters, IUpdateNotifications } from './NovedadesContext';

export const NovedadesProvider = ({ children }: { children: ReactNode }) => {
	const auth = useAuth();
	const [updateTable, setUpdatetable] = useState(false);
	const [notifications, setNotifications] = useState<INotifications[]>([]);
	const [meta, setMeta] = useState<IMeta>({ currentPage: 1, itemsPerPage: 10, totalPages: 1 } as IMeta);
	const [readFilter, setReadFilter] = useState(0);
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [updateNotifications, setUpdateNotifications] = useState<IUpdateNotifications>({
		notification_id: 0,
		status_id: 0
	});

	useEffect(() => {
		if (auth.user.patrimonial_dependence_id) {
			if (readFilter || (startDate && endDate)) {
				const filters: INovedadesFilters = {
					status_id: readFilter,
					date_from: startDate,
					date_to: endDate
				};

				getAllNotifications(auth.user.patrimonial_dependence_id, meta.currentPage, meta.itemsPerPage, filters).then(
					(res: any) => {
						setMeta(res.data.meta);
						const notifications: INotifications[] = [];
						if (isArray(res.data.items)) {
							res.data.items.map((item: any) => {
								notifications.push(item);
							});
						}
						setNotifications(notifications);
						setUpdatetable(false);
					}
				);
			} else {
				getAllNotifications(auth.user.patrimonial_dependence_id, meta.currentPage, meta.itemsPerPage).then(
					(res: any) => {
						const notifications: INotifications[] = [];
						if (res?.data?.meta) {
							setMeta(res.data.meta);
						}
						if (isArray(res?.data?.items)) {
							res.data.items.map((item: any) => {
								notifications.push(item);
							});
						}
						setNotifications(notifications);
						setUpdatetable(false);
					}
				);
			}
		}
	}, [readFilter, startDate, endDate, auth.user, updateTable, meta.currentPage]);

	useEffect(() => {
		if (updateNotifications.notification_id === 0 || updateNotifications.status_id === 0) return;
		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		const sendData = () => {
			try {
				updateNotification(updateNotifications.notification_id, updateNotifications.status_id, source.token).then(
					(res: any) => {
						toast.success(res.data.message);
						setUpdateNotifications({ notification_id: 0, status_id: 0 });
						setUpdatetable(true);
					}
				);
			} catch (error: any) {
				toast.error(error.message);
			}
		};

		sendData();
		return () => {
			source.cancel();
		};
	}, [updateNotifications]);

	const values = {
		meta,
		setMeta,
		notifications,
		setNotifications,
		readFilter,
		setReadFilter,
		startDate,
		setStartDate,
		endDate,
		setEndDate,
		updateNotifications,
		setUpdateNotifications
	};
	return <NovedadesContext.Provider value={values}>{children}</NovedadesContext.Provider>;
};
