import { useState, useEffect } from 'react';
import { Grid, Typography, Divider } from '@mui/material';
import { FormGenericInput } from 'components/globals/inputs/FormGenericInput';
import { FileAttachementManager } from 'components/globals/inputs/FileManager';
import { requiredListValidator, DEFAULT_NO_ERROR, requiredRangeNumberByLength } from 'helpers/Validators/Validators';
import { Select } from 'components/globals/select/Select';
import { ISelectOption } from 'interfaces/commons/IParameters';
import { Attachment } from 'interfaces/file/IFile';

export const LABELS_COMPROBANTE_COMPRA_FORM = {
	titulo: 'Comprobante Fiscal',
	tipo: 'Tipo de Comprobante Fiscal',
	numero: 'Número',
	puntoVenta: 'Punto de Venta',
	archivo: 'Comprobante Fiscal'
};

export interface IComprobante {
	tipo: number | undefined;
	numero: string | undefined;
	puntoVenta: string | undefined;
	adjunto: File | undefined;
	hasErrors: boolean;
	errors?: any;
}

interface ComprobanteComponentProps {
	entity_id?: number;
	tipo: number;
	numero: string | undefined;
	adjunto: File | undefined;
	puntoVenta: string | undefined;
	comprobanteList: ISelectOption[];
	handleChange(data: IComprobante): void;
	attachments?: Attachment[];
	onDeleteFile?(): void;
}

export interface IErrorsComprobanteForm {
	tipo?: string;
	numero?: string;
	puntoVenta?: string;
	adjunto?: string;
}

const ComprobanteComponent = ({
	tipo,
	puntoVenta,
	numero,
	comprobanteList,
	handleChange,
	adjunto,
	attachments,
	onDeleteFile
}: ComprobanteComponentProps) => {
	const [selectedType, setTipoComprobante] = useState<ISelectOption>(comprobanteList[0]);
	const [ncomprobanteLocal, setNumeroComprobanteLocal] = useState<string>('');
	const [puntoVentaLocal, setPuntoVentaLocal] = useState<string>('');
	const [selectedFile, setSelectedFile] = useState<File | undefined>();
	const [dataFormHasChanged, setDataFormHasChanged] = useState<number>(0);

	useEffect(() => {
		if (numero && puntoVenta && !ncomprobanteLocal && !puntoVentaLocal) {
			setNumeroComprobanteLocal(numero);
			setPuntoVentaLocal(puntoVenta);
			setDataFormHasChanged((v) => v + 1);
		}
	}, [numero, puntoVenta]);

	useEffect(() => {
		setSelectedFile(adjunto);
	}, [adjunto]);

	useEffect(() => {
		//Tipo de Alta
		const finded = comprobanteList.find((vt) => {
			return vt.id == tipo;
		});
		if (finded) setTipoComprobante(finded);
	}, [tipo, comprobanteList]);

	const [errors, setErrors] = useState<IErrorsComprobanteForm | undefined>({} as IErrorsComprobanteForm);

	const validateForm = (): boolean => {
		if (dataFormHasChanged <= 1) return true;

		const newErrors: IErrorsComprobanteForm = {} as IErrorsComprobanteForm;
		setErrors(newErrors);

		const typeError = requiredListValidator({ value: selectedType, label: LABELS_COMPROBANTE_COMPRA_FORM.tipo });
		if (typeError !== DEFAULT_NO_ERROR) {
			newErrors.tipo = typeError;
		}

		const numberError = requiredRangeNumberByLength(
			{ value: ncomprobanteLocal, label: LABELS_COMPROBANTE_COMPRA_FORM.numero },
			true,
			true,
			8,
			8
		);

		if (numberError !== DEFAULT_NO_ERROR) {
			newErrors.numero = numberError;
		}

		if (Number(ncomprobanteLocal) === 0) {
			newErrors.numero = 'Introduzca un Número válido';
		}

		const puntoVentaError = requiredRangeNumberByLength(
			{ value: puntoVentaLocal, label: LABELS_COMPROBANTE_COMPRA_FORM.puntoVenta },
			true,
			true,
			4,
			5
		);

		if (puntoVentaError !== DEFAULT_NO_ERROR) {
			newErrors.puntoVenta = puntoVentaError;
		}

		if (Number(puntoVentaLocal) === 0) {
			newErrors.puntoVenta = 'Introduzca un Punto de Venta válido';
		}

		const adjuntoError = selectedFile
			? DEFAULT_NO_ERROR
			: 'Debe adjuntar un '.concat(LABELS_COMPROBANTE_COMPRA_FORM.archivo);
		if (adjuntoError !== DEFAULT_NO_ERROR) {
			newErrors.adjunto = adjuntoError;
		}

		setErrors(newErrors);
		if (Object.entries(newErrors).length !== 0) {
			return false;
		}

		return true;
	};

	const getParsedData = (hasErrors: boolean): IComprobante => {
		return {
			tipo: selectedType?.id || 0,
			numero: ncomprobanteLocal,
			puntoVenta: puntoVentaLocal,
			adjunto: selectedFile,
			hasErrors: hasErrors,
			errors: hasErrors ? errors : ({} as IErrorsComprobanteForm)
		} as IComprobante;
	};

	useEffect(() => {
		if (dataFormHasChanged > 0) {
			const hasErrors = !validateForm();
			const dataToSend = getParsedData(hasErrors);
			handleChange(dataToSend);
		}
	}, [dataFormHasChanged]);

	useEffect(() => {
		// Reset form data when component is unmounted

		return () => {
			handleChange({
				tipo: 0,
				numero: '',
				puntoVenta: '',
				adjunto: undefined,
				hasErrors: false,
				errors: {}
			});
		};
	}, []);

	return (
		<>
			<Typography
				variant="subtitle2"
				style={{ paddingLeft: '25px' }}
				fontSize={'1em'}
				mt={2}>
				{LABELS_COMPROBANTE_COMPRA_FORM.titulo}
			</Typography>
			<Divider className="customDividerLight" />

			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={4}
				xl={4}>
				<Select
					value={selectedType}
					options={comprobanteList}
					onChange={(val) => {
						setTipoComprobante(val as ISelectOption);
					}}
				/>
			</Grid>

			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={4}
				xl={4}>
				<FormGenericInput
					maxLength={5}
					type="number"
					value={puntoVentaLocal}
					label={LABELS_COMPROBANTE_COMPRA_FORM.puntoVenta}
					name="puntoVenta"
					id="puntoVenta"
					onChange={(value: string) => {
						setPuntoVentaLocal(value);
						setDataFormHasChanged((v) => v + 1);
					}}
					size={'medium'}
					required={true}
					error={errors?.puntoVenta}
				/>
			</Grid>

			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={4}
				xl={4}>
				<FormGenericInput
					maxLength={8}
					type="number"
					value={ncomprobanteLocal}
					label={LABELS_COMPROBANTE_COMPRA_FORM.numero}
					name="ncomprobante"
					id="ncomprobante"
					onChange={(value: string) => {
						setNumeroComprobanteLocal(value);
						setDataFormHasChanged((v) => v + 1);
					}}
					size={'medium'}
					required={true}
					error={errors?.numero}
				/>
			</Grid>

			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}>
				<FileAttachementManager
					onDeleteSuccess={onDeleteFile}
					attachments={attachments}
					disableUploadAction
					label="Adjuntar Comprobante Fiscal"
					routeToDelete="/property-registration-files"
					onChange={(file) => {
						setSelectedFile(file[0]);
						setDataFormHasChanged((v) => v + 1);
					}}
					fieldName="reception"
				/>
			</Grid>
		</>
	);
};

ComprobanteComponent.displayName = 'Ncomprobante';

export { ComprobanteComponent };
