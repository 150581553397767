import { ReactNode, useEffect, useState } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { UsersContext } from './UsersContext';
import { IChangePasswordResquestBody, IUser, IUserFilters, IUserResquestBody } from 'interfaces/user/IUser';
import { toast } from 'react-toastify';
import {
	tryChangePassword,
	tryCreateUser,
	tryGetAllUsers,
	tryToggleUserStatus,
	tryUpdatePersonData,
	tryUpdateUser,
	tryUserResetPassword
} from 'services/UsersService';
import { useAppContext } from 'hooks/useAppContext';
import { ILinks, IMeta } from 'interfaces/commons/IResponse';
import { IPaginationRequest } from 'interfaces/commons/IParameters';

const USERS_FILTERS = 'USERS_FILTERS';

export const UsersState = ({ children }: { children: ReactNode | ReactJSXElement }) => {
	const contextoX = useAppContext();

	useEffect(() => {
		// Get Search parameters
		const persistedValue = localStorage.getItem(USERS_FILTERS);
		let searchFilters = { term: undefined } as IUserFilters;
		if (persistedValue !== null) {
			searchFilters = JSON.parse(persistedValue);
		}
		setFilters(searchFilters);
	}, []);

	// START Get All Users
	const [users, setUsers] = useState<IUser[]>([] as IUser[]);
	const [usersMeta, setMeta] = useState<IMeta>({} as IMeta);
	const [usersLinks, setLinks] = useState<ILinks>({} as ILinks);

	const onHandleTryGetAllUsers = async ({ term }: IUserFilters, { page, limit }: IPaginationRequest) => {
		setUsers([]);
		contextoX?.onHandleShowSpinner();
		const response = await tryGetAllUsers({ term }, { page, limit });
		contextoX?.onHandleHideSpinner();

		if (response.hasOwnProperty('error')) {
			let message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'Ocurrio un error al recuperar los usuarios!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		setUsers(
			response.items?.map((u: IUser) => {
				return {
					...u,
					fullName: u.first_name + ' ' + u.last_name,
					patrimonial_dependence: u?.patrimonial_dependence ? u.patrimonial_dependence : { type: null }
				};
			})
		);
		setMeta(response.meta);
		setLinks(response.links);
		toast.success('Usuarios recuperados!');
		return true;
	};
	// END Get All Users

	// START Filter Users
	const storagedFilters = localStorage.getItem(USERS_FILTERS);
	const [filters, setFilters] = useState<IUserFilters>(
		storagedFilters ? JSON.parse(storagedFilters) : ({ term: undefined } as IUserFilters)
	);

	const onHandleSearByFilters = async ({ term = '' }: IUserFilters) => {
		if (term && term !== '') {
			setFilters({ term });
			localStorage.setItem(USERS_FILTERS, JSON.stringify({ term }));
			return onHandleTryGetAllUsers({ term }, { page: 1, limit: usersMeta.itemsPerPage });
		}
		return false;
	};

	const onHandleClearFilters = async () => {
		setFilters({ term: undefined });
		localStorage.removeItem(USERS_FILTERS);
		return onHandleTryGetAllUsers({ term: undefined }, { page: 1, limit: usersMeta.itemsPerPage });
	};
	// END Filter Users

	// START Handle Change Page
	const onHandleChangePage = async ({ page = 1, limit = usersMeta.itemsPerPage }: IPaginationRequest) => {
		return await onHandleTryGetAllUsers({ ...filters }, { page, limit });
	};
	// END Handle Change Page

	// START Toggle User Status
	const onHandleUserToggleStatus = async (id: number) => {
		contextoX?.onHandleShowSpinner();
		const response = await tryToggleUserStatus(id);
		contextoX?.onHandleHideSpinner();

		if (response.hasOwnProperty('error')) {
			toast.error('Ocurrio un error al cambiar estado del usaurio!');
			console.error(response.error);
			return false;
		}
		toast.success('Estado de usuario cambiado!');
		return true;
	};
	// END Toggle User Status

	// START Reset User Password
	const onHandleUserResetPassword = async (id: number) => {
		contextoX?.onHandleShowSpinner();
		const response = await tryUserResetPassword(id);
		contextoX?.onHandleHideSpinner();

		if (response.hasOwnProperty('error')) {
			toast.error('Ocurrio un error al resetear contraseña!');
			console.error(response.error);
			return false;
		}
		toast.success('Reseteo de contraseña exitoso!');
		return true;
	};
	// END Reset User Password

	// START Create User
	const onHandleTryAddUser = async (entity: IUserResquestBody) => {
		if (!entity) {
			toast.error('Debe indicar un nuevo usuario para poder agregarlo');
			return false;
		}

		contextoX?.onHandleShowSpinner();
		const response = await tryCreateUser(entity);
		contextoX?.onHandleHideSpinner();

		if (response.hasOwnProperty('error')) {
			let message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'Ocurrio un error al agregar el usuario!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		toast.success('Usuario agregado!');
		return true;
	};
	// END Create User

	// START Edit User
	const onHandleTryUpdateUser = async (entity: IUserResquestBody) => {
		if (!entity) {
			toast.error('Debe indicar un usuario para poder editarlo');
			return false;
		}

		contextoX?.onHandleShowSpinner();
		const response = await tryUpdateUser(entity);
		contextoX?.onHandleHideSpinner();

		if (response.hasOwnProperty('error')) {
			let message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'Ocurrio un error al editar el usuario!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		toast.success('Usuario editado!');
		return true;
	};
	// END Edit User

	// START Update Person Data
	const onHandleTryUpdatePersonData = async (entity: IUserResquestBody) => {
		if (!entity) {
			toast.error('Para modificar datos de un usaurio es necesario indicar uno previamente');
			return false;
		}

		contextoX?.onHandleShowSpinner();
		const response = await tryUpdatePersonData(entity);
		contextoX?.onHandleHideSpinner();

		if (response.hasOwnProperty('error')) {
			let message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'Ocurrio un error al editar detos del usuario!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		toast.success(
			'Datos del usuario editados. Se recomienda cerrar sesión y volver a iniciarla para ver reflejados los cambios!'
		);
		return true;
	};
	// END Update Person Data

	// START ChangePassword
	const onHandleTryChangePassword = async (entity: IChangePasswordResquestBody, id: any) => {
		if (!entity) {
			toast.error('Para cambiar la contraseña primero debe indicar un usuario');
			return false;
		}
		contextoX?.onHandleShowSpinner();
		const response = await tryChangePassword(entity, id);
		contextoX?.onHandleHideSpinner();

		if (response.hasOwnProperty('error')) {
			let message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'Ocurrio un error al cambiar la contraseña!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		toast.success('Su contraseña se ha modificado correctamente, cierre sesión e inicie nuevamente!');
		return true;
	};
	// END ChangePassword

	return (
		<UsersContext.Provider
			value={{
				users,
				usersMeta,
				usersLinks,
				onHandleTryGetAllUsers,
				onHandleSearByFilters,
				onHandleClearFilters,
				filters,
				onHandleUserToggleStatus,
				onHandleUserResetPassword,
				onHandleChangePage,
				onHandleTryAddUser,
				onHandleTryUpdateUser,
				onHandleTryUpdatePersonData,
				onHandleTryChangePassword
			}}>
			{children}
		</UsersContext.Provider>
	);
};
