import { useState, useEffect, memo, Dispatch, SetStateAction } from 'react';
import useDebounce from 'hooks/useDebounce';
import { TextField, Typography } from '@mui/material';

interface IQuantityInput {
	setQuantity: Dispatch<SetStateAction<string>>;
	submited: boolean;
	style?: object;
	value?: string;
	disabled?: boolean;
}

const QuantityInput = memo(({ value, disabled, setQuantity, submited, style }: IQuantityInput) => {
	const [quantity, setQuantityChild] = useState(value || '');
	const debouncedValue = useDebounce<string>(quantity, 500);

	useEffect(() => {
		if (submited) {
			setQuantity('');
			setQuantityChild('');
		}
	}, [submited]);

	useEffect(() => {
		setQuantity(quantity);
	}, [debouncedValue]);

	const handleQuantityChange = (value: string) => {
		if (!RegExp(/^[0-9]*$/).test(value)) return;
		setQuantityChild(value);
	};

	return (
		<div style={style ? { ...style, marginRight: '40px' } : {}}>
			<TextField
				disabled={disabled}
				type="text"
				sx={{ width: '220px', marginLeft: '-15px' }}
				value={quantity}
				name="quantity"
				id="quantity"
				label="Cantidad *"
				size="small"
				onChange={(evt) => handleQuantityChange(evt.target.value)}
			/>
		</div>
	);
});

QuantityInput.displayName = 'QuantityInput';

export { QuantityInput };
