import * as yup from 'yup';

export const validationLoginSchema = yup.object({
	email: yup.string().email('Debe ingresar un email válido.').required('Debe ingresar un email.'),
	password: yup.string().required('Debe ingresar una contraseña.')
});

export const validationResetPasswordSchema = yup.object({
	password: yup.string().min(8, 'La contraseña debe tener al menos 8 caracteres.').required('La contraseña es requerida.'),
	passwordConfirmation: yup
		.string()
		.oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden.')
		.required('Debe confirmar la contraseña.')
});

export const validationSendResetSchema = yup.object({
	sendEmail: yup.string().email('Debe ingresar un email válido').required('Debe ingresar un email.')
});

export const validationEmailResetSchema = yup.object({
	resetEmail: yup.string().email('Debe ingresar un email válido').required('Debe ingresar un email.')
});
