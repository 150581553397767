import dayjs from 'dayjs';
import apiInstance from 'interceptors/axiosInterceptor';

async function getPropertyMovementObservationHistory(id: number) {
	const response = await apiInstance.get(`/property-movements/${id}/observations`);

	const data = response.data;

	return data.map((item: any) => {
		return {
			date: dayjs(item.created_at).format('DD/MM/YYYY'),
			movementStatusName: item.movement_status.name,
			userName: item.user ? `${item.user?.first_name} ${item.user?.last_name}` : '-',
			observation: item.observation
		};
	});
}

export { getPropertyMovementObservationHistory };
