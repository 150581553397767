export const customStyles = {
	option: (provided: any, { isFocused, isSelected }: any) => ({
		...provided,
		/* color: state.isSelected ? '#ffffff' : '#ffffff',
		backgroundColor: state.isSelected ? '#ffffff' : 'rgba(0, 0, 0, 0.1)', */
		fontFamily: 'Segoe UI',
		fontSize: '13px',
		maxWidth: '780px',
		padding: 10,
		borderBottom: '0.2px solid #0000005e',
		color: isFocused ? '#ffffff' : 'black',
		background: !isFocused ? '#EDEDED' : 'rgba(10, 121, 165,1)'
	}),
	menu: (provided: any) => ({
		...provided,
		zIndex: 9999,
		opacity: 1,
		maxWidth: '800px',
		fontFamily: 'Segoe UI',
		fontSize: '13px',
		backgroundColor: '#EDEDED'
	}),
	control: (provided: any) => ({
		// none of react-select's styles are passed to <Control />
		...provided,
		display: 'flex',
		flexWrap: 'nowrap',
		fontFamily: 'Segoe UI',
		fontSize: '14px',
		border: '1px solid lightgray',
		borderRadius: '4px',
		height: '40px',
		width: '420px',
		minWidth: '200px',
		maxWidth: '600px',
		marginBottom: '10px'
	}),
	noOptionsMessage: (provided: any) => ({
		...provided,
		color: '#fff',
		backgroundColor: 'rgba(10 ,121 ,165,1)'
	}),
	loadingMessage: (provided: any) => ({
		...provided,
		color: '#fff',
		backgroundColor: 'rgba(10, 121, 165,1)'
	})
};
