/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, SetStateAction, useRef, memo } from 'react';
import { InputGeneric } from 'components/globals/inputs/InputGeneric';
import { IStep3VehicleValues } from '../../interfaces';
import { ValidateAeronave } from '../validateVehicle';
import useDebounce from 'hooks/useDebounce';
import { useMovContext } from 'hooks/useMovContext';
import { Box, InputLabel } from '@mui/material';

export interface IPaso3AeronavesProps {
	memoizedDataFromChild: (data: SetStateAction<IStep3VehicleValues>) => void;
}

const Aeronaves = memo(({ memoizedDataFromChild }: IPaso3AeronavesProps) => {
	const movContext = useMovContext();
	const [genericList, setGenericList] = useState<IStep3VehicleValues>({
		vehicleType: 'Aeronaves'
	} as IStep3VehicleValues);
	const [domainRegistartion, setDominio] = useState(movContext?.itemSelected?.domainRegistartion || '');
	const [serialNumber, setSerialNumber] = useState(movContext?.itemSelected?.serialNumber || '');
	const [brand, setBrand] = useState(movContext?.itemSelected?.brand || '');
	const [model, setModel] = useState(movContext?.itemSelected?.model || '');
	const [type, setTipo] = useState(movContext?.itemSelected?.type || '');
	const [dateOfConstruction, setDateOfConstruction] = useState(
		movContext?.itemSelected?.dateOfConstruction
			? new Date(movContext?.itemSelected?.dateOfConstruction).toISOString().slice(0, 10)
			: '' || ''
	);
	const debouncedValue = useDebounce(genericList, 400);
	const dateInputRef = useRef<HTMLInputElement>(null);

	/* Setting the max date of the input field. */
	if (dateInputRef.current) {
		const date = new Date();
		date.setDate(date.getDate());
		dateInputRef.current.max = date.toISOString().split('T')[0];
	}

	const handleAdd = (evt: any) => {
		evt.preventDefault();
		setGenericList({ ...genericList, [evt.target.name]: evt.target.value });
	};

	useEffect(() => {
		const valid = ValidateAeronave(genericList as any);
		valid ? memoizedDataFromChild(genericList) : memoizedDataFromChild({} as IStep3VehicleValues);
	}, [debouncedValue]);

	useEffect(() => {
		if (movContext?.itemSelected) {
			setGenericList({
				domainRegistartion,
				serialNumber,
				brand,
				model,
				type,
				dateOfConstruction,
				vehicleType: 'Aeronaves'
			});
			const valid = ValidateAeronave(genericList as any);
			valid ? memoizedDataFromChild(genericList) : memoizedDataFromChild({} as IStep3VehicleValues);
		}
	}, [movContext?.itemSelected]);

	return (
		<div
			onChange={handleAdd}
			style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '55px', rowGap: '55px' }}>
			<InputGeneric
				stateValue={domainRegistartion}
				setStateValue={setDominio}
				label={'Dominio/Matricula'}
				name={'domainRegistartion'}
				pattern={/^[a-zA-Z0-9-]*$/}
				required={true}
			/>
			<InputGeneric
				stateValue={serialNumber}
				setStateValue={setSerialNumber}
				label={'Nro. de Serie'}
				name={'serialNumber'}
				required={true}
			/>
			<InputGeneric
				stateValue={brand}
				setStateValue={setBrand}
				label={'Marca'}
				name={'brand'}
				required={true}
				pattern={/^[a-zA-Z\s]*$/}
			/>
			<InputGeneric
				stateValue={model}
				setStateValue={setModel}
				label={'Modelo'}
				name={'model'}
				required={true}
			/>
			<div style={{ marginTop: '0px' }}>
				<InputGeneric
					stateValue={type}
					setStateValue={setTipo}
					label={'Tipo'}
					name={'type'}
					required={true}
				/>
			</div>
			<Box sx={{ width: '220px', ml: '-10px', mt: '-14.5px' }}>
				<InputLabel
					sx={{ position: 'inherit', mb: '-3px' }}
					htmlFor="dateOfConstruction">
					Fecha de Fabricación
				</InputLabel>
				<input
					name="dateOfConstruction"
					ref={dateInputRef}
					value={dateOfConstruction}
					onChange={(e) => setDateOfConstruction(e.target.value)}
					id="dateOfConstruction"
					className="form-control"
					type="date"
				/>
				<span id="dateOfConstruction"></span>
			</Box>
		</div>
	);
});

Aeronaves.displayName = 'Aeronaves';

export { Aeronaves };
