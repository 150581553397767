import style from './toolbar.module.css';

import { RoleById } from 'enums';

import { styled } from '@mui/material/styles';
import { AppBar, Box, Toolbar } from '@mui/material';
import { HeaderBtn, HeaderListBtn } from 'components/globals/headerBtn/HeaderBtn';
import { UseRPermissions } from 'utils/userPermissions/UserPermissions';

import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TuneIcon from '@mui/icons-material/Tune';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-around',

	// Override media queries injected by theme.mixins.toolbar
	'@media all': {
		minHeight: 75,
		width: '100%'
	}
}));

const renderSettingsOptions = (): any[] => {
	const options = [];
	const user = (
		<UseRPermissions
			role={[RoleById.ITDirector, RoleById.Super_Admin]}
			resource={['manage:Settings', 'manage:Users', 'manage:Authz']}>
			<HeaderBtn
				text={'Usuarios'}
				icon={<PeopleIcon style={{ margin: '0 5px 0 5px' }} />}
				goTo={'/configuracion/usuarios'}
				key={'users'}
			/>
		</UseRPermissions>
	);

	if (user) {
		options.push(user);
	}

	const parameters = (
		<UseRPermissions
			role={[RoleById.ITDirector, RoleById.Director_de_BienesFiscales, RoleById.Super_Admin]}
			resource={['manage:Settings', 'manage:Users', 'manage:Authz']}>
			<HeaderBtn
				text={'Parámetros'}
				icon={<TuneIcon style={{ margin: '0 5px 0 5px' }} />}
				goTo={'/configuracion/parametros'}
				key={'parameters'}
			/>
		</UseRPermissions>
	);

	if (parameters) {
		options.push(parameters);
	}

	return options;
};

const renderSheetOptions = (): any[] => {
	const options = [];

	const auditSheet = (
		<UseRPermissions
			key={'1'}
			role={[RoleById.Director_de_BienesFiscales, RoleById.Operador_Dir_de_BienesFiscales]}
			resource={['manage:Sheets']}>
			<HeaderBtn
				fullWidth
				text={'Auditar Planillas de Movimientos Mensuales'}
				icon={<ListAltIcon style={{ margin: '0 5px 0 5px' }} />}
				goTo={'/planilla-mensual-auditoria'}
				key={'auditSheet'}
			/>
		</UseRPermissions>
	);

	if (auditSheet) {
		options.push(auditSheet);
	}

	const generateSheet = (
		<UseRPermissions
			key={'3'}
			role={[RoleById.Responsable_Patrimonial]}
			resource={['manage:Sheets']}>
			<HeaderBtn
				fullWidth
				text={'Generación de Planilla de Movimientos'}
				icon={<ListAltIcon style={{ margin: '0 5px 0 5px' }} />}
				goTo={'/planilla-mensual-generar'}
				key={'presentationSheet'}
			/>
		</UseRPermissions>
	);

	const manageSheet = (
		<UseRPermissions
			key={'4'}
			role={[RoleById.Responsable_Patrimonial]}
			resource={['manage:Sheets']}>
			<HeaderBtn
				fullWidth
				text={'Gestionar Planillas de Movimientos Mensuales'}
				icon={<SettingsApplicationsIcon style={{ margin: '0 5px 0 5px' }} />}
				goTo={'/planilla-mensual-gestionar'}
				key={'manageSheet'}
			/>
		</UseRPermissions>
	);

	if (manageSheet) {
		options.push(manageSheet, generateSheet);
	}

	return options;
};

const renderBalanceOptions = (): any[] => {
	const options = [];
	const balance = (
		<UseRPermissions
			role={[RoleById.Director_de_BienesFiscales, RoleById.ITDirector, RoleById.Operador_Dir_de_BienesFiscales]}
			resource={['manage:Balance']}>
			<HeaderBtn
				text={'Cierre de Ejercicio'}
				icon={<EqualizerIcon style={{ margin: '0 5px 0 5px' }} />}
				goTo={'/balance/cierre-ejercicio'}
				key={'balance-close'}
			/>
		</UseRPermissions>
	);

	if (balance) {
		options.push(balance);
	}

	const summary = (
		<UseRPermissions
			role={[RoleById.Director_de_BienesFiscales, RoleById.Operador_Dir_de_BienesFiscales]}
			resource={['manage:Balance']}>
			<HeaderBtn
				text={'Balance Anual'}
				icon={<EqualizerIcon style={{ margin: '0 5px 0 5px' }} />}
				goTo={'/balance/resumen'}
				key={'balance-summary'}
			/>
		</UseRPermissions>
	);

	if (summary) {
		options.push(summary);
	}
	return options;
};

export const ToolBar = () => {
	return (
		<Box className={style.box}>
			<AppBar
				position="static"
				sx={{
					backgroundColor: 'transparent',
					boxShadow: 'none'
				}}>
				<StyledToolbar
					sx={{
						display: 'flex',
						flexDirection: 'row',
						flexWrap: 'nowrap',
						gap: 2
					}}>
					<HeaderBtn
						text={'Inicio'}
						icon={<HomeIcon />}
						goTo={'/home'}
					/>

					<UseRPermissions
						role={[RoleById.Responsable_Patrimonial, RoleById.Operador_Dependencia_Patrimonial]}
						resource={['manage:Properties']}>
						<HeaderListBtn
							title="Registro de Movimientos"
							icon={<EqualizerIcon />}
							key={'balance'}
							options={[
								<HeaderBtn
									fullWidth
									key="alta"
									text={'Alta'}
									icon={<AddIcon />}
									goTo={'/alta'}
								/>,
								<HeaderBtn
									fullWidth
									key="baja"
									text={'Baja'}
									icon={<RemoveIcon />}
									goTo={'/baja'}
								/>
							]}
						/>
					</UseRPermissions>

					<UseRPermissions
						role={[
							RoleById.Operador_Dir_de_BienesFiscales,
							RoleById.Responsable_Patrimonial,
							RoleById.Operador_Dependencia_Patrimonial
						]}
						resource={['manage:Movements']}>
						<HeaderListBtn
							title={'Gestión de Movimientos'}
							icon={<ImportContactsIcon />}
							key={'movements'}
							options={[
								<HeaderBtn
									fullWidth
									key="altas"
									text={'Altas'}
									icon={<AddIcon />}
									goTo={'/movimientos/altas'}
								/>,
								<HeaderBtn
									fullWidth
									key="bajas"
									text={'Bajas'}
									icon={<RemoveIcon />}
									goTo={'/movimientos/bajas'}
								/>
							]}
						/>
					</UseRPermissions>

					<UseRPermissions
						role={[
							RoleById.Responsable_Patrimonial,
							RoleById.Director_de_BienesFiscales,
							RoleById.Operador_Dir_de_BienesFiscales
						]}
						resource={['manage:Sheets']}>
						<HeaderListBtn
							title={'Planilla Mensual'}
							icon={<DescriptionIcon />}
							key={'presentation'}
							options={renderSheetOptions()}
						/>
					</UseRPermissions>

					<UseRPermissions
						role={[RoleById.Director_de_BienesFiscales, RoleById.Operador_Dir_de_BienesFiscales]}
						resource={['manage:Balance']}>
						<HeaderListBtn
							title={'Balances'}
							icon={<EqualizerIcon />}
							key={'balance'}
							options={renderBalanceOptions()}
						/>
					</UseRPermissions>

					<UseRPermissions
						role={[RoleById.ITDirector, RoleById.Director_de_BienesFiscales]}
						resource={['manage:Settings']}>
						<HeaderListBtn
							title={'Configuración'}
							icon={<SettingsIcon />}
							key={'settings'}
							options={renderSettingsOptions()}
						/>
					</UseRPermissions>

					<UseRPermissions
						role={[0]}
						resource={['null']}>
						<HeaderBtn
							text={'Auditoría'}
							icon={<FactCheckIcon />}
							goTo={'/auditoria'}
						/>
					</UseRPermissions>
				</StyledToolbar>
			</AppBar>
		</Box>
	);
};
