import { IStep3Accessories, IStep3VehicleValues } from 'components/EncPatrimonial/altaStepper/paso3/interfaces';

export function ValidateAutomotor(data: IStep3VehicleValues): boolean {
	if (data.vehicleType === 'Automotores') {
		if (data.engineNumber === undefined || data.engineNumber?.trim().length === 0) {
			return false;
		}
		if (data.chassisNumber === undefined || data.chassisNumber?.trim().length === 0) {
			return false;
		}
		if (data.brand === undefined || data.brand?.trim().length === 0) {
			return false;
		}
		if (data.model === undefined || data.model?.trim().length === 0) {
			return false;
		}
		if (data.origin === undefined || data.origin?.trim().length === 0) {
			return false;
		}
		if (data.type === undefined || data.type?.trim().length === 0) {
			return false;
		}
		return true;
	}
	return false;
}

export function ValidateAeronave(data: IStep3VehicleValues): boolean {
	if (data.vehicleType === 'Aeronaves') {
		if (data.domainRegistartion === undefined || data.domainRegistartion?.trim().length === 0) {
			return false;
		}
		if (data.serialNumber === undefined || data.serialNumber?.trim().length === 0) {
			return false;
		}
		if (data.brand === undefined || data.brand?.trim().length === 0) {
			return false;
		}
		if (data.model === undefined || data.model?.trim().length === 0) {
			return false;
		}
		if (data.type === undefined || data.type?.trim().length === 0) {
			return false;
		}
		return true;
	}

	return false;
}

export function ValidateMotovehiculo(data: IStep3VehicleValues): boolean {
	if (data.vehicleType === 'Motovehículos') {
		if (data.engineNumber === undefined || data.engineNumber?.trim().length === 0) {
			return false;
		}
		if (data.bikeFrameNumber === undefined || data.bikeFrameNumber?.trim().length === 0) {
			return false;
		}
		if (data.brand === undefined || data.brand?.trim().length === 0) {
			return false;
		}
		if (data.model === undefined || data.model?.trim().length === 0) {
			return false;
		}
		if (data.origin === undefined || data.origin?.trim().length === 0) {
			return false;
		}
		if (data.type === undefined || data.type?.trim().length === 0) {
			return false;
		}
		return true;
	}
	return false;
}

export function ValidateEmbarcaciones(data: IStep3VehicleValues): boolean {
	if (data.vehicleType === 'Embarcaciones') {
		if (data.domainRegistartion === undefined || data.domainRegistartion?.trim().length === 0) {
			return false;
		}
		if (data.type === undefined || data.type?.trim().length === 0) {
			return false;
		}
		if (data.boatHullNumber === undefined || data.boatHullNumber?.trim().length === 0) {
			return false;
		}
		if (data.modelHull === undefined || data.modelHull?.trim().length === 0) {
			return false;
		}
		return true;
	}
	return false;
}

export function ValidateMaquinas(data: IStep3VehicleValues): boolean {
	if (data.vehicleType === 'Máquinas') {
		if (data.engineNumber === undefined || data.engineNumber?.trim().length === 0) {
			return false;
		}
		if (data.chassisNumber === undefined || data.chassisNumber?.trim().length === 0) {
			return false;
		}
		if (data.brand === undefined || data.brand?.trim().length === 0) {
			return false;
		}
		if (data.model === undefined || data.model?.trim().length === 0) {
			return false;
		}
		if (data.origin === undefined || data.origin?.trim().length === 0) {
			return false;
		}
		if (data.type === undefined || data.type?.trim().length === 0) {
			return false;
		}
		if (data.year === undefined || data.year?.trim().length === 0) {
			return false;
		}
		return true;
	}
	return false;
}
