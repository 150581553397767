/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { FormGenericSelect } from 'components/globals/inputs/FormGenericSelect';
import { ISelectOption } from 'interfaces/commons/IParameters';
import { ButtonConfirm, ButtonDecline } from 'components/globals/customsBtn/customBtn';
import { FormGenericInput } from 'components/globals/inputs/FormGenericInput';
import { DEFAULT_NO_ERROR, requiredPartialNomenclatureCode, requiredValidator } from 'helpers/Validators/Validators';
import { IOrganizationalUnit } from 'interfaces/organism/IOrganism';
import { IPatrimonialDependence } from 'interfaces/patrimonial/IPatrimonial';

export interface IOverviewPatrimonialDependenceForm {
	entity: IPatrimonialDependence | undefined;
	handleSubmit: (data: IPatrimonialDependence) => void;
	handleCancel: () => void;
	organizationalUnits: IOrganizationalUnit[];
}

export interface IErrorsForm {
	organizationalUnit?: string | undefined;
	code?: string | undefined;
	name?: string | undefined;
}

export const LABELS_ORGANIZATIONAL_UNIT_FORM = {
	organizationalUnit: 'Unidad Organizacional',
	code: 'Código',
	name: 'Nombre de la Dependencia Patrimonial'
};

const SEPARATOR = '.';

export const OverviewPatrimonialDependenceForm = ({
	entity,
	handleSubmit,
	handleCancel,
	organizationalUnits
}: IOverviewPatrimonialDependenceForm) => {
	const [organizationalUnit, setOrganizationalUnit] = useState<ISelectOption | undefined>();
	const [code, setCode] = useState<string | undefined>();
	const [prefixCode, setPrefixCode] = useState<string | undefined>();
	const [name, setName] = useState<string | undefined>(entity?.patrimonial_dependence);
	const [errors, setErrors] = useState<IErrorsForm>({} as IErrorsForm);
	const isEdit = !!entity;

	useEffect(() => {
		let unit: ISelectOption = { id: 0, label: '' };
		let prefixCode = entity?.type ? entity.type.split(SEPARATOR) : [];
		if (entity && prefixCode && prefixCode.length > 3) {
			let prefix = prefixCode[0].concat(SEPARATOR.concat(prefixCode[1]).concat(SEPARATOR).concat(prefixCode[2]));
			let finded = organizationalUnits.find((uo) => uo.code == prefix);
			if (finded) {
				unit = { id: finded.id, label: finded.name } as ISelectOption;
			}
			setCode(prefixCode[3]);
		}
		setOrganizationalUnit(unit);
	}, [entity]);

	useEffect(() => {
		setPrefixCode(undefined);
		if (organizationalUnit) {
			let prefix = organizationalUnits.find((j) => j.id == organizationalUnit?.id)?.code;
			if (prefix) {
				setPrefixCode(prefix.concat(SEPARATOR));
			}
		}
	}, [organizationalUnit]);

	const validateForm = (): boolean => {
		let newErrors: IErrorsForm = {} as IErrorsForm;
		setErrors(newErrors);

		let organizationalUnitError = requiredValidator({
			value: organizationalUnit?.label,
			label: LABELS_ORGANIZATIONAL_UNIT_FORM.organizationalUnit
		});
		if (organizationalUnitError !== DEFAULT_NO_ERROR) {
			newErrors.organizationalUnit = organizationalUnitError;
		}

		let codeError = requiredPartialNomenclatureCode({ value: code, label: LABELS_ORGANIZATIONAL_UNIT_FORM.code });
		if (codeError !== DEFAULT_NO_ERROR) {
			newErrors.code = codeError;
		}

		let nameError = requiredValidator({ value: name, label: LABELS_ORGANIZATIONAL_UNIT_FORM.name });
		if (nameError !== DEFAULT_NO_ERROR) {
			newErrors.name = nameError;
		}

		if (Object.entries(newErrors).length !== 0) {
			setErrors(newErrors);
			return false;
		}

		return true;
	};

	const getParsedData = (): IPatrimonialDependence => {
		return {
			id: isEdit ? entity.id : undefined,
			type: code && prefixCode ? prefixCode?.concat(code.trim()).trim() : '',
			patrimonial_dependence: name ? name.trim() : '',
			last_inventory: 0,
			last_minute: 0,
			organizational_id: organizationalUnit?.id,
			jurisdiction_id: organizationalUnits.find((j) => j.id == organizationalUnit?.id)?.jurisdictions_id
		};
	};

	const onHandleSubmit = (): void => {
		if (!validateForm()) {
			return;
		}
		handleSubmit(getParsedData());
	};

	return (
		<div className="borderedBox">
			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '30px', rowGap: '30px', paddingTop: '30px' }}>
				<FormGenericSelect
					value={organizationalUnit}
					label={'Selecciona una ' + LABELS_ORGANIZATIONAL_UNIT_FORM.organizationalUnit}
					id="organizationalUnit"
					options={organizationalUnits.map((ou) => {
						return { id: ou.id, label: ou.name } as ISelectOption;
					})}
					handleChange={setOrganizationalUnit}
					error={errors.organizationalUnit}
				/>

				<FormGenericInput
					value={code}
					label={LABELS_ORGANIZATIONAL_UNIT_FORM.code}
					name="code"
					id="code"
					startAdornment={prefixCode}
					handleChange={setCode}
					error={errors.code}
				/>

				<FormGenericInput
					value={name}
					label={LABELS_ORGANIZATIONAL_UNIT_FORM.name}
					name="name"
					id="name"
					handleChange={setName}
					error={errors.name}
				/>
			</Box>

			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
				<ButtonDecline
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => {
						handleCancel();
					}}>
					Cancelar
				</ButtonDecline>
				<ButtonConfirm
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => onHandleSubmit()}>
					Agregar
				</ButtonConfirm>
			</Box>
		</div>
	);
};
