import './App.css';
import './reset-MUI.css';
import { Views } from './routes/Views';

function App() {
  return (
    <div className="App">
      <Views />
    </div>
  );
}

export default App;
