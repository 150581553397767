import React, { FC, useState } from 'react';
import style from './customDialog.module.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogType, ICustomButtonProps, Transition } from './utils';

export interface IDialogProps {
	title?: string;
	message: string;
	labelSubmit?: string;
	labelDecline?: string;
	handleSubmit: () => void;
	handleDecline: () => void;
}

export const CustomDecideDialog: FC<IDialogProps> = ({
	title = 'Eliminar',
	message,
	labelSubmit = 'Aceptar',
	labelDecline = 'Cancelar',
	handleSubmit,
	handleDecline
}) => {
	const [open, setShowDialog] = useState(true);

	const custombuttons: ICustomButtonProps[] = [
		{
			name: labelDecline,
			color: 'secondary',
			variant: 'contained',
			onClick: () => {
				handleDecline();
			}
		},
		{
			name: labelSubmit,
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				handleSubmit();
			}
		}
	];

	const handleClose = (index: number) => {
		setShowDialog(false);
		handleDecline();
		if (custombuttons[index]) {
			custombuttons[index].onClick();
		}
	};

	return (
		<div>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby="alert">
				<DialogTitle sx={{ backgroundColor: DialogType.PRIMARY, color: 'white', textAlign: 'center' }}>{title}</DialogTitle>
				<DialogContent className={style.myDialog}>
					<DialogContentText id="alert">{message}</DialogContentText>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					{custombuttons.map((button, index) => (
						<Button
							key={index}
							onClick={() => handleClose(index)}
							color={button.color}
							variant={button.variant}
							sx={{ minWidth: '110px' }}>
							{button.name}
						</Button>
					))}
				</DialogActions>
			</Dialog>
		</div>
	);
};
