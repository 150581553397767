import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { boolean } from 'yup';
import './styles.css';

interface SwitchProps {
	onChange?(value: boolean): void;
	value?: boolean;
	disabled?: boolean;
	size?: 'small' | 'medium' | 'large';
}

export const Switch: React.FC<SwitchProps> = ({ onChange, value, disabled, size }) => {
	const [isSelected, setIsSelected] = useState(value);

	useEffect(() => setIsSelected(!!value), [value]);

	const handleChange = (val: boolean) => {
		setIsSelected(val);
		onChange && onChange(val);
	};

	return (
		<Box
			width="100%"
			className="switch-selector">
			<Button
				size={size ?? 'small'}
				disabled={disabled}
				disableFocusRipple
				disableElevation
				disableRipple
				className="switch-selector-btn"
				variant={isSelected ? 'contained' : 'outlined'}
				onClick={() => handleChange(true)}
				fullWidth>
				Si
			</Button>
			<Button
				disabled={disabled}
				disableFocusRipple
				disableElevation
				disableRipple
				className="switch-selector-btn"
				variant={!isSelected ? 'contained' : 'outlined'}
				onClick={() => handleChange(false)}
				fullWidth>
				No
			</Button>
		</Box>
	);
};
