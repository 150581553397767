import React, { useEffect, useState } from 'react';
import { IAltaStepTwo } from 'interfaces/alta/IAlta';
import { Box, Typography } from '@mui/material';
import { Attachment } from 'interfaces/file/IFile';
import { getFilesByDocumentId } from 'services/bajaService';
import { FileAttachementManager } from 'components/globals/inputs/FileManager';

interface ISummaryStepTwo {
	entity: IAltaStepTwo;
}

export const SummaryFiles = ({ entity }: ISummaryStepTwo) => {
	const [documentAttachments, setDocumentAttachments] = useState<Attachment[]>([]);

	useEffect(() => {}, []);
	return (
		<Box sx={{ padding: '4%' }}>
			{entity?.files?.length !== undefined && entity?.files?.length > 0 ? (
				<FileAttachementManager
					attachments={entity?.files}
					hideInputToUpload={true}
					disabled={true}
					fieldName="attachments"
					label={'Archivos Adjuntos'}
				/>
			) : (
				<Typography
					variant="body1"
					sx={{ pl: '30px' }}>
					No posee documentos adjuntos.
				</Typography>
			)}
		</Box>
	);
};
