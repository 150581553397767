export const tryGetAllAdministrativeTypes = async () => {
	return [
		{
			id: 1,
			name: 'Administración Central',
			code: '01'
		},
		{
			id: 2,
			name: 'Organismo Descentralizado',
			code: '02'
		}
	];
};
