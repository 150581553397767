import { FC, useState, useRef, ChangeEvent, useEffect } from 'react';
import { customStyles } from './styles';
import lodash from 'lodash';

//Hooks
import { useMovContext } from 'hooks/useMovContext';
import { usePromiseTracker } from 'react-promise-tracker';

// Components
import Select from 'react-select';

// Material UI
import { FormControl, Box, FormHelperText, Typography } from '@mui/material';

// Interfaces
import { ITipoAltaTransferenciaProps, ITransferenciaValues } from 'components/EncPatrimonial/altaStepper/paso1/interfaces';

// Services
import { getPatrimonialDependencies } from 'services/crudService';

// Interface for select
interface ISelectState {
	readonly isClearable: boolean;
	readonly isDisabled: boolean;
	readonly isLoading: boolean;
	readonly isRtl: boolean;
	readonly isSearchable: boolean;
}
interface ISelectItems {
	value: number;
	label: string;
}

export const TransferenciaEdit: FC<ITipoAltaTransferenciaProps> = ({ setPaso1Transferencia }) => {
	const movContext = useMovContext();

	const [selectState] = useState<ISelectState>({
		isClearable: true,
		isDisabled: false,
		isLoading: false,
		isRtl: false,
		isSearchable: true
	});
	const [dependenciaPat, setDependenciaPat] = useState<ITransferenciaValues>({
		tipoAlta: 'Transferencia',
		dependencia: '',
		fecha: '',
		id: 0
	});
	const [dependency, setDependency] = useState<ISelectItems | null>(null);
	const [allDependencies, setAllDependencies] = useState<any[]>([]);
	const [dependencyNameArr, setDependencyNameArr] = useState<ISelectItems[]>([]);
	const [dependencyTerm, setDependencyTerm] = useState<string>(
		String(movContext?.editMovement?.property_registration?.to_patrimonial_dependence_id) || ''
	);
	const { promiseInProgress } = usePromiseTracker({ area: 'patrimonialDependenciesTracker' });

	const dateInputRef = useRef<HTMLInputElement>(null);

	const debounceInputTerm = lodash.debounce((e) => setDependencyTerm(e), 1000);

	useEffect(() => {
		if (movContext?.editMovement?.property_registration?.to_patrimonial_dependence_id) {
			const id = String(movContext?.editMovement?.property_registration?.to_patrimonial_dependence_id);
			getPatrimonialDependencies(id).then((res) => {
				const dependency: ITransferenciaValues = { ...dependenciaPat };
				dependency.dependencia = res.data[0].patrimonial_dependence;
				dependency.id = res.data[0].id;
				dependency.fecha = movContext?.editMovement?.property_registration?.date_transfer || '';
				setDependency({ value: res.data[0].id, label: res.data[0].patrimonial_dependence });
				setDependenciaPat(dependency);
			});
		}
	}, []);

	useEffect(() => {
		if (dependencyTerm.length > 2) {
			searchDependency();
		} else {
			setDependencyNameArr([]);
		}
	}, [dependencyTerm]);

	const searchDependency = () => {
		const data = getPatrimonialDependencies(dependencyTerm);
		data.then((res) => {
			const dependencies: ISelectItems[] = [];
			setAllDependencies(res.data);
			res?.data?.map((item: any) => {
				dependencies.push({ label: item.patrimonial_dependence, value: item.id });
				if (movContext?.editMovement?.property_registration?.to_patrimonial_dependence_id == item.id) {
					setDependency({ ...dependency, label: item.patrimonial_dependence, value: item.id });
					setDependenciaPat({ ...dependenciaPat, fecha: movContext?.editMovement?.property_registration?.date_transfer || '' });
				}
			});
			setDependencyNameArr(dependencies);
		});
	};

	useEffect(() => {
		dependenciaPat.dependencia = dependency?.label || '';
		dependenciaPat.id = dependency?.value || 0;
		setPaso1Transferencia({ ...dependenciaPat });
	}, [dependenciaPat, dependency]);

	/* Setting the max date of the input field. */
	if (dateInputRef.current) {
		const date = new Date();
		date.setDate(date.getDate());
		dateInputRef.current.max = date.toISOString().split('T')[0];
	}

	const handleFecha = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		evt.preventDefault();
		const fecha = evt.target.value;
		setDependenciaPat({ ...dependenciaPat, fecha });
	};

	useEffect(() => {
		if (dependencyTerm.length > 0) {
			searchDependency();
		}
	}, []);

	return (
		<>
			<Box
				id="transferencia"
				sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', mt: -3 }}>
				<FormControl>
					<Typography>Dependencia Patrimonial *</Typography>
					<Select
						onInputChange={(e) => debounceInputTerm(e)}
						onChange={(value) => setDependency(value)}
						value={dependency}
						styles={customStyles}
						isDisabled={selectState.isDisabled}
						isClearable={selectState.isClearable}
						isSearchable={selectState.isSearchable}
						name="jurisdiction"
						options={dependencyNameArr}
						loadingMessage={() => 'Cargando...'}
						isLoading={promiseInProgress}
						placeholder="Buscar dependencia patrimonial"
					/>
				</FormControl>
				<FormControl sx={{ mt: 2, ml: -1 }}>
					<label
						style={{ fontSize: '13px', fontFamily: 'Segoe UI', fontWeight: '400', paddingLeft: '12px' }}
						htmlFor="startDate">
						Fecha de Transferencia *
					</label>
					<input
						style={{ width: '250px' }}
						ref={dateInputRef}
						value={dependenciaPat.fecha}
						onChange={handleFecha}
						id="startDate"
						className="form-control"
						type="date"
					/>
				</FormControl>
			</Box>
		</>
	);
};
