import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { HeaderForm } from '../../components/globals/headerForm/HeaderForm';
import { memo, useEffect, useState } from 'react';
import { SearchBarByText } from 'components/globals/searchBar/SearchBarByText';
import { UsersTable } from 'components/user/UserTable';
import { IUser, IUserResquestBody } from 'interfaces/user/IUser';
import { useUsersContext } from 'hooks/useUsersContext';
import { ProfileTable } from 'components/profiles/ProfileTable';
import { IRole, IRoleResquestBody } from 'interfaces/role/IRole';
import { useRolesContext } from 'hooks/useRolesContext';
import { FormDialog } from 'components/globals/dialog/FormDialog';
import { OverviewUserForm } from 'components/user/Overview';
import { useAppContext } from 'hooks/useAppContext';
import { OverviewProfileForm } from 'components/profiles/Overview';
import { Spinner } from 'components/globals/spinner/Spinner';
import { CustomDecideDialog } from 'components/globals/dialog/CustomDeleteDialog';
import { tryGetAllPatrimonialDependences } from 'services/patrimonialDependencesService';

const UsersPage = memo(() => {
	const usersContext = useUsersContext();
	const rolesContext = useRolesContext();
	const appContext = useAppContext();

	const [selectedTab, setSelectedTab] = useState('0');

	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue);
		if (newValue == '0') {
			usersContext?.onHandleTryGetAllUsers(usersContext?.filters, {
				page: usersContext?.usersMeta?.currentPage,
				limit: usersContext?.usersMeta?.itemsPerPage
			});
		}
		if (newValue == '1') {
			rolesContext?.onHandleTryGetAllRoles(rolesContext?.filters, {
				page: rolesContext?.rolesMeta?.currentPage,
				limit: rolesContext?.rolesMeta?.itemsPerPage
			});
		}
	};

	const [users, setUsers] = useState<IUser[]>([]);
	const [roles, setRoles] = useState<IRole[]>([]);

	//Parameters
	const [parametersGroups, setParametersGroups] = useState<any[]>([]);
	const [parametersProfiles, setParametersProfiles] = useState<any[]>([]);
	const [parametersPatrimonialDependences, setParametersPatrimonialDependences] = useState<any[]>([]);

	useEffect(() => {
		usersContext?.onHandleTryGetAllUsers({ term: undefined }, { page: 1, limit: 10 });
		appContext?.onHandleTryGetAllGroups();
		appContext?.onHandleTryGetAllProfiles();
	}, []);

	const fetchPatrimonialDependences = async () => {
		const data = await tryGetAllPatrimonialDependences();

		setParametersPatrimonialDependences(
			data.map((p: any) => {
				return {
					id: p.id,
					label: p.patrimonial_dependence
				};
			})
		);
	};

	useEffect(() => void fetchPatrimonialDependences(), []);

	useEffect(() => {
		setUsers(usersContext?.users);
	}, [usersContext?.users]);

	useEffect(() => {
		setRoles(rolesContext?.roles);
	}, [rolesContext?.roles]);

	// START Handle User Modal
	const [showUserModal, toggleUserModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState<IUser | undefined>(undefined);

	const handleAddUser = () => {
		toggleUserModal(true);
		setSelectedUser(undefined);
	};

	const handleToggleUserState = async (entity: IUser | undefined) => {
		if (!entity) {
			return;
		}

		const result = await usersContext?.onHandleUserToggleStatus(entity.id);
		if (result === true) {
			usersContext?.onHandleTryGetAllUsers(usersContext?.filters, {
				page: usersContext?.usersMeta?.currentPage,
				limit: usersContext?.usersMeta?.itemsPerPage
			});
		}
	};

	const handleSubmitUser = async (entity: IUserResquestBody | undefined) => {
		if (!entity) {
			return;
		}
		let result;
		if (entity.id === undefined) {
			result = await usersContext?.onHandleTryAddUser(entity);
		} else {
			if (entity.group_id === 2) {
				entity = {
					...entity,
					patrimonial_dependence_id: null
				};
			}
			result = await usersContext?.onHandleTryUpdateUser(entity);
		}

		if (result) {
			toggleUserModal(false);
			setSelectedUser(undefined);
			usersContext?.onHandleTryGetAllUsers(usersContext?.filters, {
				page: usersContext?.usersMeta?.currentPage,
				limit: usersContext?.usersMeta?.itemsPerPage
			});
		}
	};
	// END Handle User Modal

	// START Handle Role Modal
	const [showRoleModal, toggleRoleModal] = useState(false);
	const [selectedRole, setSelectedRole] = useState<IRole | undefined>(undefined);
	const [showDeleteRoleModal, toggleDeleteRoleModal] = useState(false);

	const handleToggledeleteRoleModal = (entity: IRole | undefined, value: boolean) => {
		setSelectedRole(entity);
		toggleDeleteRoleModal(value);
	};

	const handleDeleteRole = async (entity: IRole | undefined) => {
		if (!entity) {
			return;
		}

		const result = await rolesContext?.onHandleTryDeleteRole(entity.id);
		if (result === true) {
			handleToggledeleteRoleModal(undefined, false);
			rolesContext?.onHandleTryGetAllRoles(rolesContext?.filters, {
				page: rolesContext?.rolesMeta?.currentPage,
				limit: rolesContext?.rolesMeta?.itemsPerPage
			});
		}
	};

	const handleSubmitRole = async (entity: IRoleResquestBody | undefined) => {
		if (!entity) {
			return;
		}
		let result;

		if (entity.id === 0) {
			const newEntity = {
				...entity,
				id: undefined
			};
			result = await rolesContext?.onHandleTryAddRole(newEntity);
		} else {
			result = await rolesContext?.onHandleTryUpdateRole(entity);
		}

		if (result) {
			toggleRoleModal(false);
			rolesContext?.onHandleTryGetAllRoles(rolesContext?.filters, {
				page: rolesContext?.rolesMeta?.currentPage,
				limit: rolesContext?.rolesMeta?.itemsPerPage
			});
		}
	};
	// END Handle Role Modal

	const onHandleSearchDependencies = (term: string | null) => {
		if (term !== null && term.length > 0) {
			appContext?.onHandleTryGetAllPatrimonialDependences(term);
		} else {
			setParametersPatrimonialDependences([]);
		}
	};

	// START Handle ResetPassword Modal
	const [showResetPasswordModal, toggleResetPasswordModal] = useState(false);

	const handleShowResetPasswordModal = (entity: IUser | undefined) => {
		if (!entity) {
			return;
		}
		setSelectedUser(entity);
		toggleResetPasswordModal(true);
	};

	const handleHideResetPasswordModal = () => {
		setSelectedUser(undefined);
		toggleResetPasswordModal(false);
	};

	const handleSubmitResetPassword = async (entity: IUser | undefined) => {
		if (!entity) {
			return;
		}
		const result = await usersContext?.onHandleUserResetPassword(entity?.id);

		if (result) {
			toggleResetPasswordModal(false);
			setSelectedUser(undefined);
			usersContext?.onHandleTryGetAllUsers(usersContext?.filters, {
				page: usersContext?.usersMeta?.currentPage,
				limit: usersContext?.usersMeta?.itemsPerPage
			});
		}
	};
	// END Handle Role Modal

	return (
		<>
			{appContext?.showSpinner ? <Spinner /> : null}

			<HeaderForm title={'Gestión de Usuarios'} />

			{showUserModal && (
				<FormDialog
					show={showUserModal}
					title={selectedUser ? 'Editar Usuario' : 'Agregar Usuario'}
					content={
						<OverviewUserForm
							entity={selectedUser}
							handleSubmit={(entity) => {
								handleSubmitUser(entity);
							}}
							handleCancel={() => {
								toggleUserModal(false);
							}}
							handleSearchDependencies={(term: string) => {
								onHandleSearchDependencies(term);
							}}
							groups={parametersGroups}
							profiles={parametersProfiles}
							patrimonialDependences={parametersPatrimonialDependences}
						/>
					}
					actions={<></>}
					handleClose={(value) => toggleUserModal(value)}
				/>
			)}

			{showRoleModal && (
				<FormDialog
					show={showRoleModal}
					title={selectedRole ? 'Editar Perfil' : 'Crear Perfil'}
					content={
						<OverviewProfileForm
							entity={selectedRole}
							handleSubmit={(entity) => {
								handleSubmitRole(entity);
							}}
							handleCancel={() => {
								toggleRoleModal(false);
							}}
						/>
					}
					actions={<></>}
					handleClose={(value) => toggleRoleModal(value)}
				/>
			)}

			{showDeleteRoleModal && (
				<CustomDecideDialog
					title={'Eliminar Perfil'}
					message={'¿Desea eliminar el perfil?'}
					handleSubmit={() => {
						handleDeleteRole(selectedRole);
					}}
					handleDecline={() => {
						handleToggledeleteRoleModal(undefined, false);
					}}
				/>
			)}

			{showResetPasswordModal && (
				<CustomDecideDialog
					title={'Resetear Contraseña'}
					message={'¿Desea resetear la contraseña del usuario?'}
					handleSubmit={() => {
						handleSubmitResetPassword(selectedUser);
					}}
					handleDecline={() => {
						handleHideResetPasswordModal();
					}}
				/>
			)}

			<Box sx={{ width: '100%', typography: 'body1' }}>
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList
							onChange={handleChangeTab}
							aria-label={'Opciones para gestión de Usuarios'}>
							<Tab
								label={'Usuarios'}
								value={'0'}
							/>

							<Tab
								label={'Perfiles'}
								value={'1'}
							/>
						</TabList>
					</Box>

					<TabPanel
						value={'0'}
						style={{ paddingLeft: '0px', paddingRight: '0px' }}>
						<SearchBarByText
							label={'Ingrese usuario'}
							defaultValue={usersContext?.filters?.term}
							onHandleSearch={(ev: any) => {
								usersContext?.onHandleSearByFilters({ term: ev });
							}}
							submitLabel="Agregar usuario"
							onHandleSubmit={(ev: any) => {
								handleAddUser();
							}}
							onHandleClear={(ev: any) => {
								usersContext?.onHandleClearFilters();
							}}
						/>

						<Box sx={{ overflowX: 'scroll', marginTop: '5px' }}>
							<UsersTable
								elements={users}
								handleToggleStatus={(entity: IUser) => {
									handleToggleUserState(entity);
								}}
								handleEditUser={(entity: IUser) => {
									toggleUserModal(true);
									setSelectedUser(entity);
								}}
								handleResetPassword={(entity: IUser) => {
									handleShowResetPasswordModal(entity);
								}}
							/>
						</Box>
					</TabPanel>

					<TabPanel
						value={'1'}
						style={{ paddingLeft: '0px', paddingRight: '0px' }}>
						<SearchBarByText
							label={'Ingrese nombre del perfil'}
							defaultValue={rolesContext?.filters?.term}
							onHandleSearch={(ev: any) => {
								rolesContext?.onHandleSearByFilters({ term: ev });
							}}
							submitLabel="Agregar perfil"
							onHandleSubmit={(ev: any) => {
								setSelectedRole(undefined);
								toggleRoleModal(!showRoleModal);
							}}
							onHandleClear={(ev: any) => {
								rolesContext?.onHandleClearFilters();
							}}
						/>

						<Box sx={{ overflowX: 'scroll', marginTop: '5px' }}>
							<ProfileTable
								elements={roles}
								handleEditRole={(entity: IRole) => {
									toggleRoleModal(true);
									setSelectedRole(entity);
								}}
								handleDeleteRole={(entity: IRole) => {
									handleToggledeleteRoleModal(entity, true);
								}}
							/>
						</Box>
					</TabPanel>
				</TabContext>
			</Box>
		</>
	);
});

UsersPage.displayName = 'Usuarios';

export { UsersPage };
