import { IMessage, Message } from './index';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export const WarningMessage = ({ title, message, aditionalMessage, isDissmisable = false, icon = <WarningAmberIcon /> }: IMessage) => {
	return (
		<Message
			variant="warning"
			title={title}
			message={message}
			aditionalMessage={aditionalMessage}
			isDissmisable={isDissmisable}
			icon={icon}></Message>
	);
};

export default Message;
