import { useState, useEffect, memo, Dispatch, SetStateAction } from 'react';
import useDebounce from 'hooks/useDebounce';
import { TextField, Typography } from '@mui/material';

interface IDescriptionInput {
	submited: boolean;
	setSubmited?: Dispatch<SetStateAction<boolean>>;
	value?: string;
	setDescription: Dispatch<SetStateAction<string>>;
	required?: boolean;
	label?: string;
	name?: string;
	placeholder?: string;
}

const DescriptionInput = memo(({ value, placeholder, submited, setSubmited, setDescription, required, label, name }: IDescriptionInput) => {
	const [description, setDescriptionChild] = useState('');
	const debouncedValue = useDebounce<string>(description, 200);

	useEffect(() => {
		if (value) {
			setDescriptionChild(value);
		}
	}, [value]);

	useEffect(() => {
		if (submited) {
			setDescriptionChild('');
			if (setSubmited) setSubmited(false);
		}
	}, [submited]);

	useEffect(() => {
		setDescription(description);
	}, [debouncedValue]);

	return (
		<div>
			<TextField
				sx={{ mb: 1, width: '30rem' }}
				InputLabelProps={{ shrink: true }}
				id="outlined-multiline-static"
				name={name ? name : 'description'}
				label={label ? label : 'Descripción *'}
				value={description}
				onChange={(e) => {
					if (description.length <= 254) {
						setDescriptionChild(e.target.value);
					} else {
						setDescriptionChild(e.target.value.slice(0, 254));
					}
				}}
				placeholder={placeholder ? placeholder : 'Agregue una descripción del bien...'}
				multiline
				rows={4}
			/>

			{description.trim().length >= 254 && (
				<Typography sx={{ fontSize: '12px', ml: 0.5, position: 'absolute' }}>*Máximo 255 caracteres</Typography>
			)}
		</div>
	);
});

DescriptionInput.displayName = 'DescriptionInput';

export { DescriptionInput };
