// Enums
import { ApiPaths } from 'enums/apiPaths';
import { IPaginationRequest } from 'interfaces/commons/IParameters';
import { IGroupFilters } from 'interfaces/group/IGroup';

import { handleHttpError, buildDefaultOptions, httpGet, buildURLQuery } from './httpService';

const URL_BASE = `${ApiPaths.USERS_GROUPS}`;
const URL_WITH_ID = (id: number) => {
	return URL_BASE + `/${id}`;
};

export const tryGetAllGroups = async ({ term }: IGroupFilters, { page, limit }: IPaginationRequest) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const queryString = buildURLQuery({ term, page, limit });
		const URI = `${URL_BASE}?${queryString}`;
		let response = await httpGet(URI, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};
