/* eslint-disable max-lines */
import { Box } from '@mui/system';
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography, Tooltip } from '@mui/material';
import { HeaderRoutes } from 'components/globals/headerRoutes/HeaderRoutes';
import { PresentationSheetManagementTable } from 'components/presentationSheet/PresentationSheetManagementTable';
import { UploadSheetModal } from 'components/presentationSheet/UploadSheetModal/UploadSheetModal';
import React, { useState, useMemo } from 'react';
import {
	createPresentationSheet,
	downloadSheetFile,
	tryGetAllMovements,
	uploadSheetFile
} from 'services/presentationSheetService';
import { useAuth } from 'hooks/useAuth';
import ErrorIcon from '@mui/icons-material/Error';
import {
	useReactTable,
	getCoreRowModel,
	getFilteredRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFacetedMinMaxValues,
	getPaginationRowModel,
	getSortedRowModel,
	flexRender
} from '@tanstack/react-table';
import { RemoveRedEye } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { CustomDialog } from '../../components/globals/dialog/CustomDialog';
import { DialogType, ICustomButtonProps } from 'components/globals/dialog/utils';
import { SheetStatus } from 'enums/sheetStatus';

interface PresentationSheetDetailsTableProps {
	elements: Array<any>;
	onViewDocument?: CallableFunction;
}

const PresentationSheetDetailsTable: React.FC<PresentationSheetDetailsTableProps> = ({ elements, onViewDocument }) => {
	const table = useReactTable({
		data: elements,
		columns: [
			{
				accessorKey: 'code',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Código</span>,
				cell: ({ row: { original } }) => {
					return <div>{original.nomenclator_code || '-'}</div>;
				}
			},
			{
				accessorKey: 'inventory_number',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Nro Inventario</span>,
				cell: ({ row: { original } }) => {
					return <div>{original.item_inventory_number || '-'}</div>;
				}
			},
			{
				accessorKey: 'description',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Descripción del Bien</span>,
				cell: ({ row: { original } }) => {
					return <div>{original.description || '-'}</div>;
				}
			},
			{
				accessorKey: 'total',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Importe Total</span>,
				cell: ({ row: { original } }) => {
					return (
						<div style={{ justifyContent: 'right' }}>
							{parseFloat(original.total).toLocaleString('es-ES', { minimumFractionDigits: 2 }) || '-'}
						</div>
					);
				}
			},
			/*{
				accessorKey: 'minute_number',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Nro Acta RecepciónXXX</span>,
				cell: ({ row: { original } }) => {
					return <div>{original.minute_number || '-'}</div>;
				}
			},*/
			{
				accessorKey: 'subresponsable',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Subresponsable</span>,
				cell: ({ row: { original } }) => {
					return <div>{original.reponsability || '-'}</div>;
				}
			},
			{
				accessorKey: 'view-document',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Ver Acta</span>,
				cell: ({ row: { original } }) => {
					return (
						<Tooltip
							title={`${(original.files.length == 0 && 'Existen movimientos sin actas firmadas adjuntas') || ''}`}>
							<div>
								<Button
									onClick={() => {
										onViewDocument && onViewDocument(original);
									}}
									disabled={original.files.length == 0}
									variant="text">
									<RemoveRedEye />
								</Button>
							</div>
						</Tooltip>
					);
				}
			}
		],
		filterFns: { fuzzy: () => false },
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedMinMaxValues: getFacetedMinMaxValues()
	});

	table.initialState.pagination.pageSize = elements.length;

	return (
		<Box>
			<table className="movements-table">
				<thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<th
										key={header.id}
										colSpan={header.colSpan}>
										{header.isPlaceholder ? null : (
											<div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
										)}
									</th>
								);
							})}
						</tr>
					))}
				</thead>

				<tbody>
					{table.getRowModel().rows.map((row, id) => {
						return (
							<React.Fragment key={id}>
								<tr key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<td
												onClick={() => void 0}
												key={cell.id}>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										);
									})}
								</tr>
							</React.Fragment>
						);
					})}

					{elements.length === 0 ? (
						<tr>
							<td colSpan={7}>
								<Typography variant="subtitle1">La planilla no posee movimientos registrados.</Typography>
							</td>
						</tr>
					) : null}
				</tbody>
			</table>
		</Box>
	);
};

const monthOptions = [
	{ id: 1, label: 'Enero' },
	{ id: 2, label: 'Febrero' },
	{ id: 3, label: 'Marzo' },
	{ id: 4, label: 'Abril' },
	{ id: 5, label: 'Mayo' },
	{ id: 6, label: 'Junio' },
	{ id: 7, label: 'Julio' },
	{ id: 8, label: 'Agosto' },
	{ id: 9, label: 'Septiembre' },
	{ id: 10, label: 'Octubre' },
	{ id: 11, label: 'Noviembre' },
	{ id: 12, label: 'Diciembre' }
];

const yearOptions = [
	{ id: new Date().getFullYear() - 1, label: `${new Date().getFullYear() - 1}` },
	{ id: new Date().getFullYear(), label: `${new Date().getFullYear()}` }
];

const defaultFilters = { year: '', month: '' };

export const PresentationSheetGeneration: React.FC = () => {
	const [filters, setFilters] = useState(structuredClone(defaultFilters));
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [showSheetMovementDetails, setShowSheetMovementDetails] = useState(false);
	const [selectedRow, setSelectedRow] = useState<any>(null);
	const [sheetElement, setSheetElement] = useState<any>(null);
	const [isUploadingSheet, setIsUploadingSheet] = useState(false);
	const [pendingLastMonth, setPendingLastMonth] = useState(false);
	const [isSendingSheet, setIsSendingSheet] = useState(false);
	const [generateSheetError, setGenerateSheetError] = useState({ alta: '', baja: '' });
	const [sheetMovementDetails, setSheetMovementDetails] = useState<any[]>([]);
	const [enableSheetSend, setEnableSheetSend] = useState({ alta: false, baja: false });
	const auth = useAuth();
	const filteredMonthOptions = useMemo(() => {
		if (parseInt(filters.year) === new Date().getFullYear()) {
			return monthOptions.filter((month) => month.id <= new Date().getMonth() + 1);
		} else {
			return monthOptions;
		}
	}, [filters.year]);

	const handleFiltersChange = (name: keyof typeof defaultFilters) => {
		return (value: unknown) => setFilters((curr) => ({ ...curr, [name]: value }));
	};
	const isSameMonth = () => {
		const actualMonth = new Date().getMonth() + 1;
		return +filters.month == actualMonth;
	};
	const validationSend = () => {
		return (
			!enableSheetSend.alta ||
			!enableSheetSend.baja ||
			isSendingSheet ||
			pendingLastMonth ||
			sheetElement.status == SheetStatus.CONSOLIDADA ||
			isSameMonth()
		);
	};
	const handleUploadSheet = (row: any) => {
		setShowSheetMovementDetails(false);
		setShowUploadModal(true);
		setSelectedRow(row);
	};

	const handleSubmitFiles = async (files: File[]) => {
		try {
			setIsUploadingSheet(true);
			await uploadSheetFile({ sheetId: selectedRow.id, file: files[0] });

			const movementType = selectedRow.type.toLowerCase();
			setEnableSheetSend((curr) => ({ ...curr, [movementType]: true }));

			return true;
		} catch {
			return false;
		} finally {
			setIsUploadingSheet(false);
		}
	};
	const getMovementsSheetStatusLabel = (status: number) => {
		switch (status) {
			case SheetStatus.PENDIENTE:
				return 'Pendiente';
			case SheetStatus.APROBADA:
				return 'Aprobada';
			case SheetStatus.ENVIADA:
				return 'Enviada';
			case SheetStatus.OBSERVADA:
				return 'Observada';
			case SheetStatus.RECTIFICADA:
				return 'Rectificada';
			case SheetStatus.CONSOLIDADA:
				return 'Consolidada';
			case SheetStatus.NOPRESENTADA:
				return 'No presentada';
		}
	};
	const handleGenerateSheet = async () => {
		handleResetState();
		setSelectedRow(null);
		setShowSheetMovementDetails(false);
		const payload = {
			year: Number(filters.year),
			month: Number(filters.month),
			user_id: auth?.user.id,
			presentation_period: Number(filters.year)
		};

		const altaResponse = await tryGetAllMovements({
			...payload,
			movement_type: 1
		});

		const bajaResponse = await tryGetAllMovements({
			...payload,
			movement_type: 2
		});
		if (altaResponse && bajaResponse) {
			if ((altaResponse?.lastMonth == false || bajaResponse?.lastMonth == false) && parseInt(filters.month) != 1) {
				setPendingLastMonth(true);
				toast.warning('Para enviar la planilla actual debe enviar la planilla del mes anterior');
			} else if (altaResponse?.lastMonth == true || bajaResponse?.lastMonth == true) {
				setPendingLastMonth(false);
			} else {
				setPendingLastMonth(false);
			}
			const sheets = [];
			if (altaResponse.data?.status_id === 3 && bajaResponse.data?.status_id === 3) {
				setGenerateSheetError({
					baja: 'La Planilla de Alta ya fue enviada. Se consultan sus datos.',
					alta: 'La Planilla de Baja ya fue enviada. Se consultan sus datos.'
				});
			}
			sheets.push({
				sheetId: altaResponse?.sheetId,
				type: 'Alta',
				movementTypeId: '1',
				movements: altaResponse.items || [],
				message: altaResponse?.message,
				status: getMovementsSheetStatusLabel(altaResponse?.status) || 'Pendiente',
				statusCode: altaResponse?.statusCode
			});

			sheets.push({
				sheetId: bajaResponse?.sheetId,
				type: 'Baja',
				movementTypeId: '2',
				movements: bajaResponse.items || [],
				message: bajaResponse?.message,
				status: getMovementsSheetStatusLabel(bajaResponse?.status) || 'Pendiente',
				statusCode: bajaResponse?.statusCode
			});
			if (getMovementsSheetStatusLabel(altaResponse?.status) == 'Consolidada') {
				setEnableSheetSend((curr) => ({ ...curr, alta: true }));
			}
			if (getMovementsSheetStatusLabel(bajaResponse?.status) == 'Consolidada') {
				setEnableSheetSend((curr) => ({ ...curr, baja: true }));
			}
			const status =
				altaResponse.data?.status_id === bajaResponse.data?.status_id ? altaResponse.data?.status_id ?? 1 : 1;

			setSheetElement({
				year: filters.year,
				month: monthOptions.find((m: any) => m.id === filters.month)?.label,
				status,
				sheets
			});
		}
	};

	const handleCreateSheet = async (data: { movement_type: string; sheetId: any }) => {
		if (data.sheetId === undefined) {
			data.sheetId = null;
		}

		const payload = {
			year: Number(filters.year),
			month: Number(filters.month),
			user_id: auth?.user.id as number,
			presentation_period: Number(filters.year),
			patrimonial_dependence_id: auth.user.patrimonial_dependence_id || 0,
			sheet_id: data.sheetId,
			send: 0
		};
		const response = await createPresentationSheet({ movement_type: Number(data.movement_type), ...payload });

		if (response?.id) {
			await downloadSheetFile({
				sheetId: response.id,
				movement_type: data.movement_type,
				month: monthOptions.find((m: any) => m.id === filters.month)?.label
			});
		}

		setSheetElement((curr: any) => ({
			...curr,
			sheets: curr.sheets.map((item: any) => {
				if (data.movement_type === item.movementTypeId) {
					item.id = response.id;
				}
				return item;
			})
		}));
	};

	const handleViewDetails = (row: any) => {
		if (selectedRow && selectedRow.type === row.type) {
			setSelectedRow(false);
			setSelectedRow(null);
			return;
		}

		setSelectedRow(row);
		setShowSheetMovementDetails(true);

		const sheet = sheetElement.sheets.find((sheet: any) => sheet.movementTypeId === row.movementTypeId);

		if (!sheet) return;

		setSheetMovementDetails(sheet.movements);
	};

	const handleViewSheetMovementDocument = (movement: any) => {
		const file = movement.files.at(0);
		window.open(file.path);
	};

	const handleResetState = () => {
		setSheetElement(null);
		setEnableSheetSend({ alta: false, baja: false });
		setGenerateSheetError({ alta: '', baja: '' });
	};
	const [openModal, setOpenModal] = useState(false);
	const handleSendSheetOpenModal = async () => {
		setOpenModal(true);
	};
	const sendDialogBtns: ICustomButtonProps[] = [
		{
			name: 'No',
			color: 'secondary',
			variant: 'contained',
			onClick: () => {
				return;
			}
		},
		{
			name: 'Si',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				handleSendSheet();
			}
		}
	];
	const handleSendSheet = async () => {
		setOpenModal(false);
		const [alta, baja] = sheetElement.sheets;
		if (alta.status == 'Consolidada') {
			if (!baja.id) return;
		} else if (baja.status == 'Consolidada') {
			if (!alta.id) return;
		} else {
			if (!alta.id || !baja.id) return;
		}

		try {
			const payload = {
				year: Number(filters.year),
				month: Number(filters.month),
				user_id: auth?.user.id as number,
				presentation_period: Number(filters.year),
				patrimonial_dependence_id: auth.user.patrimonial_dependence_id || 0,
				send: 1
			};

			setIsSendingSheet(true);

			alta.status !== 'Consolidada' &&
				(await createPresentationSheet({ movement_type: 1, sheet_id: alta.id, ...payload }));
			baja.status !== 'Consolidada' &&
				(await createPresentationSheet({ movement_type: 2, sheet_id: baja.id, ...payload }));

			toast('Las planillas fueron enviadas correctamente', { autoClose: 1000, type: 'success' });

			handleResetState();
			setFilters(defaultFilters);
		} catch {
			toast('Ocurrion un error al enviar las planillas', { autoClose: 1000, type: 'error' });
		} finally {
			setIsSendingSheet(false);
		}
	};

	return (
		<HeaderRoutes
			title={'Generación y Envío de:'}
			strongTitle={`Planillas de Movimientos Mensuales`}>
			<Box sx={{ my: 2 }}>
				<Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', flexWrap: 'wrap' }}>
					<FormControl sx={{ width: '240px' }}>
						<InputLabel htmlFor="stateFilter">Año</InputLabel>
						<Select
							value={filters.year}
							onChange={(event) => {
								handleFiltersChange('year')(Number(event.target.value));
							}}
							size="small"
							label="Año">
							{yearOptions.map((item) => {
								return (
									<MenuItem
										key={item.id}
										value={item.id}>
										{item.label}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>

					<FormControl sx={{ width: '240px' }}>
						<InputLabel htmlFor="stateFilter">Mes</InputLabel>
						<Select
							disabled={!filters.year}
							value={filters.month}
							size="small"
							onChange={(event) => {
								handleFiltersChange('month')(Number(event.target.value));
							}}
							label="Mes">
							{filteredMonthOptions.map((item) => {
								return (
									<MenuItem
										key={item.id}
										value={item.id}>
										{item.label}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>

					<Button
						sx={{ flexShrink: 0 }}
						size="small"
						disabled={filters.month === ''}
						onClick={handleGenerateSheet}
						variant="contained">
						Generar Planilla
					</Button>
				</Box>

				<Box sx={{ display: 'flex', gap: 1, mt: 2, flexDirection: 'column' }}>
					{generateSheetError.alta ? (
						<FormHelperText sx={{ fontSize: '14px' }}>
							<ErrorIcon
								color="warning"
								fontSize="small"
								sx={{ mr: '6px' }}
							/>
							{generateSheetError.alta}
						</FormHelperText>
					) : null}

					{generateSheetError.baja ? (
						<FormHelperText sx={{ fontSize: '14px' }}>
							<ErrorIcon
								color="warning"
								fontSize="small"
								sx={{ mr: '6px' }}
							/>
							{generateSheetError.baja}
						</FormHelperText>
					) : null}
				</Box>
			</Box>

			<UploadSheetModal
				open={showUploadModal && !!selectedRow}
				onClose={() => {
					setShowUploadModal(false);
					setSelectedRow(null);
				}}
				onConfirm={handleSubmitFiles}
				loading={isUploadingSheet}
			/>

			<Box>
				<PresentationSheetManagementTable
					elements={sheetElement ? [sheetElement] : []}
					onUploadSheet={handleUploadSheet}
					onDownloadSheet={handleCreateSheet}
					onViewDetail={handleViewDetails}
				/>

				<Typography
					width="100%"
					textAlign="right"
					variant="body2"
					sx={{ opacity: 0.8 }}>
					Todos los movimientos deben tener su acta cargada para poder enviar la planilla mensual. *
				</Typography>
			</Box>

			<Box
				padding={2}
				sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Tooltip
					title={`${
						validationSend()
							? 'Debe cumplir con las validaciones indicadas como no tener meses pendientes y no se puede enviar el mes actual'
							: ''
					}`}>
					<div>
						<Button
							disabled={validationSend()}
							onClick={handleSendSheetOpenModal}
							variant="contained">
							Enviar
						</Button>
					</div>
				</Tooltip>
			</Box>
			{openModal && (
				<CustomDialog
					title="Envío de Movimientos"
					type={DialogType.PRIMARY}
					content={'¿Está seguro de realizar el envío de la Planilla Mensual?'}
					Custombuttons={sendDialogBtns}
					buttonPosition={'center'}
					setShowDialog={setOpenModal}
				/>
			)}
			{selectedRow && showSheetMovementDetails ? (
				<Box>
					<Typography
						sx={{ my: 2 }}
						fontWeight={600}
						variant="h5">
						Detalles de Planilla de {selectedRow.type}
					</Typography>

					<PresentationSheetDetailsTable
						elements={sheetMovementDetails}
						onViewDocument={handleViewSheetMovementDocument}
					/>
				</Box>
			) : null}
		</HeaderRoutes>
	);
};
