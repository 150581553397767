/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ButtonConfirm, ButtonDecline } from 'components/globals/customsBtn/customBtn';
import { FormGenericInput } from 'components/globals/inputs/FormGenericInput';
import { FormGenericMultiline } from 'components/globals/inputs/FormGenericMultiline';
import { FormInputUnitPrice } from 'components/globals/inputs/FormInputUnitPrice';
import { NumberFormatValues } from 'react-number-format';
import { NomenclatureCodeInput } from 'components/globals/NomenclatureCodeInput/NomenclatureCodeInput';
import { ISelectOption } from 'interfaces/commons/IParameters';
import { AppDatePicker } from 'components/globals/AppDatePicker/AppDatePicker';
import { toast } from 'react-toastify';

export interface TimelessPropertyFormData {
	reception_number: string;
	inventory_number: string;
	accessory_number: string;
	nomenclador_code?: ISelectOption;
	date: Date | null;
	quantity: string;
	unit_price: NumberFormatValues;
	description: string;
	is_accessory: boolean;
}

export interface IOverviewTimelessPropertieForm {
	onSubmit?(data: TimelessPropertyFormData): Promise<unknown>;
	onSuccess?: CallableFunction;
	handleCancel(): void;
	isAccessory?: boolean;
	loading?: boolean;
	inventoryNumber?: string;
	accessoryNumber?: string;
}

export const LABELS_TIMELESS_PROPERTY_FORM = {
	number: 'Nro. de Acta de Recepción',
	inventaryNumber: 'N° de Inventario',
	accessoryNumber: 'N° de Accesorio',
	nomenclatureCode: 'Código de Nomenclador',
	date: 'Fecha de Alta',
	amount: 'Cantidad',
	unitPrice: 'Precio Unitario',
	description: 'Descripción'
};

const defaultFormData: TimelessPropertyFormData = {
	reception_number: '',
	inventory_number: '',
	accessory_number: '',
	nomenclador_code: undefined,
	date: null,
	quantity: '',
	unit_price: {
		value: '',
		floatValue: 0,
		formattedValue: ''
	},
	description: '',
	is_accessory: false
};

function isValidFormData(data: TimelessPropertyFormData, fieldsToValidate: Array<keyof TimelessPropertyFormData>) {
	const { unit_price, is_accessory, accessory_number, ...rest } = data;

	return (
		fieldsToValidate.reduce((validation, key) => {
			return !!data[key] && validation;
		}, true) && !!unit_price.value
	);
}

export const TimelessPropertyOverviewForm = ({
	onSubmit,
	onSuccess,
	handleCancel,
	isAccessory,
	loading,
	inventoryNumber,
	accessoryNumber
}: IOverviewTimelessPropertieForm) => {
	const [formData, setFormData] = useState<TimelessPropertyFormData>(structuredClone(defaultFormData));

	const handleFieldChange = (name: keyof TimelessPropertyFormData) => {
		return (value: unknown) => {
			setFormData((curr) => ({ ...curr, [name]: value }));
		};
	};

	const isSubmitEnabled = useMemo(() => {
		const fields: Array<keyof TimelessPropertyFormData> = [
			'reception_number',
			'inventory_number',
			'date',
			'nomenclador_code',
			'unit_price',
			'description'
		];

		if (isAccessory) fields.push('accessory_number');

		return isValidFormData(formData, fields);
	}, [formData, isAccessory]);

	const handleSubmit = async () => {
		if (Number(formData.inventory_number) == 0) return toast.error('El número de inventario no puede ser 0');
		if (Number(formData.reception_number) == 0) return toast.error('El número de acta de recepción no puede ser 0');
		if (onSubmit) {
			const shouldResetForm = await onSubmit({ ...formData, is_accessory: !!formData.accessory_number });

			if (shouldResetForm) {
				setFormData(defaultFormData);
				onSuccess && onSuccess();
			}
		}
	};

	useEffect(() => {
		handleFieldChange('inventory_number')(inventoryNumber);
		handleFieldChange('accessory_number')(accessoryNumber);
	}, []);

	return (
		<div className="borderedBox">
			<Grid
				container
				spacing={2}
				pt={3}>
				<Grid
					item
					xs={12}
					sm={6}
					md={6}
					lg={6}
					xl={6}>
					<FormGenericInput
						value={formData.reception_number}
						label={LABELS_TIMELESS_PROPERTY_FORM.number}
						type="number"
						handleChange={handleFieldChange('reception_number')}
						size={'medium'}
						required={true}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					md={6}
					lg={6}
					xl={6}>
					<FormGenericInput
						value={formData.inventory_number}
						label={LABELS_TIMELESS_PROPERTY_FORM.inventaryNumber}
						type="number"
						handleChange={handleFieldChange('inventory_number')}
						size={'medium'}
						required={true}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					md={6}
					lg={6}
					xl={6}>
					<FormGenericInput
						value={formData.accessory_number}
						label={LABELS_TIMELESS_PROPERTY_FORM.accessoryNumber}
						type="number"
						handleChange={handleFieldChange('accessory_number')}
						size={'medium'}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					md={6}
					lg={6}
					xl={6}>
					<AppDatePicker
						required
						disableFuture
						value={formData.date}
						label={LABELS_TIMELESS_PROPERTY_FORM.date}
						onChange={handleFieldChange('date')}
						size={'medium'}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}>
					<NomenclatureCodeInput
						hideDetails
						size="medium"
						value={formData.nomenclador_code}
						onChange={handleFieldChange('nomenclador_code')}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					md={6}
					lg={6}
					xl={6}>
					<FormGenericInput
						value={(formData.quantity = '1')}
						label={LABELS_TIMELESS_PROPERTY_FORM.amount}
						type="number"
						handleChange={handleFieldChange('quantity')}
						size={'medium'}
						disabled
						required={false}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={6}
					md={6}
					lg={6}
					xl={6}>
					<FormInputUnitPrice
						value={formData.unit_price}
						label={LABELS_TIMELESS_PROPERTY_FORM.unitPrice}
						handleChange={handleFieldChange('unit_price')}
						required={true}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}>
					<FormGenericMultiline
						value={formData.description}
						label={LABELS_TIMELESS_PROPERTY_FORM.description}
						handleChange={handleFieldChange('description')}
						required={true}
					/>
				</Grid>

				<Typography
					variant="subtitle2"
					style={{ width: '100%' }}
					pl={1}
					mt={1}>{`(*) Campos Obligatorios.`}</Typography>
			</Grid>

			<Box
				sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
				<ButtonDecline
					variant="contained"
					onClick={() => {
						handleCancel();
					}}>
					Cancelar
				</ButtonDecline>
				<ButtonConfirm
					sx={{ ml: 3 }}
					disabled={!isSubmitEnabled || loading}
					onClick={handleSubmit}
					variant="contained">
					Confirmar
				</ButtonConfirm>
			</Box>
		</div>
	);
};
