import { useEffect, useState } from 'react';
import {
	Paper,
	Grid,
	FormControl,
	InputLabel,
	OutlinedInput,
	FormHelperText,
	IconButton,
	InputAdornment,
	Tooltip,
	Typography,
	TextField,
	Box
} from '@mui/material';
import { ButtonRegister, ButtonSearch } from 'components/globals/customsBtn/customBtn';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { DEFAULT_NO_ERROR, fieldValidator } from 'helpers/Validators/Validators';

interface ISearchBarProps {
	label?: string;
	defaultValue?: string;
	searchLabel?: string;
	onHandleSearch: any;
	submitLabel?: string;
	onHandleSubmit?: any;
	clearLabel?: string;
	onHandleClear?: any;
}

export const SearchBarByText = ({
	label = 'Texto de búsqueda',
	defaultValue = '',
	searchLabel = 'Buscar',
	onHandleSearch,
	submitLabel = 'Registrar',
	onHandleSubmit,
	clearLabel = 'Limpiar',
	onHandleClear
}: ISearchBarProps) => {
	const [filter, setFilter] = useState('');
	const [hasError, setHasError] = useState(false);
	const [messageError, setMessageError] = useState('');

	const handleChange = (value: string) => {
		if (!value) {
			setFilter(value);
			return;
		}
		let result = fieldValidator({ value: value, label: label });

		setHasError(result !== DEFAULT_NO_ERROR);
		setMessageError(result !== DEFAULT_NO_ERROR ? result : '');

		setFilter(value);
	};

	const handleSearch = () => {
		let result = fieldValidator({ value: filter, label: label });

		setHasError(result !== DEFAULT_NO_ERROR);
		setMessageError(result !== DEFAULT_NO_ERROR ? result : '');

		if (result !== DEFAULT_NO_ERROR) {
			return;
		}
		return onHandleSearch(filter);
	};

	const handleClear = () => {
		setHasError(false);
		setMessageError('');
		setFilter('');
		if (onHandleClear !== undefined) {
			onHandleClear();
		}
	};

	useEffect(() => {
		setFilter(defaultValue);
	}, [defaultValue]);

	return (
		<Box className="header-search-bar">
			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
				rowSpacing={{ xs: 2, md: 1 }}>
				<Grid
					item
					xs={12}
					sm={10}
					md={10}
					lg={10}
					xl={10}>
					<FormControl
						variant="outlined"
						style={{ width: '90%', marginLeft: '20px' }}>
						<InputLabel htmlFor="search-text">{label}</InputLabel>
						<OutlinedInput
							required={true}
							error={hasError}
							id="search-text"
							type={'text'}
							value={filter}
							onChange={(e) => {
								handleChange(e.target.value);
							}}
							endAdornment={
								<InputAdornment position="end">
									{filter !== '' || hasError ? (
										<Tooltip title={clearLabel}>
											<IconButton
												onClick={() => {
													handleClear();
												}}
												edge="end">
												<ClearIcon />
											</IconButton>
										</Tooltip>
									) : null}
								</InputAdornment>
							}
							label={label}
						/>
						{hasError ? (
							<FormHelperText
								error={true}
								id="outlined-text-helper-text">
								{messageError}
							</FormHelperText>
						) : null}
					</FormControl>

					<Tooltip
						title={searchLabel}
						style={{ marginLeft: '5px', justifyContent: 'flex-start', height: '3.4rem' }}>
						<ButtonSearch
							variant="contained"
							onClick={() => {
								handleSearch();
							}}
							endIcon={<SearchIcon />}></ButtonSearch>
					</Tooltip>
				</Grid>
				<Grid
					item
					xs={12}
					sm={2}
					md={2}
					lg={2}
					xl={2}>
					{onHandleSubmit !== undefined ? (
						<Tooltip title={submitLabel}>
							<ButtonRegister
								sx={{ m: 2 }}
								variant="contained"
								onClick={() => {
									onHandleSubmit();
								}}>
								{submitLabel}
							</ButtonRegister>
						</Tooltip>
					) : null}
				</Grid>
			</Grid>
		</Box>
	);
};
