import { Dispatch, SetStateAction, useState } from 'react';

import { Typography, Box, Modal, List, ListItem } from '@mui/material';
import { IStep3Accessories, IStep3VehicleValues } from 'components/EncPatrimonial/altaStepper/paso3/interfaces';
import { ModalCar } from './ModalCar';
import { ModalShip } from './ModalShip';
import { ModalAirPlane } from './ModalAirPlane';
import { ModalMachines } from './ModalMachines';

interface IModalAccessoriesProps {
	openDetails: boolean;
	setOpenDetails: Dispatch<SetStateAction<boolean>>;
	selectedRow: IStep3VehicleValues;
}

export const ModalVehicle = ({ openDetails, setOpenDetails, selectedRow }: IModalAccessoriesProps) => {
	let formattedDate = '';
	if (selectedRow.dateOfConstruction) {
		const dateOfContruction = new Date(selectedRow.dateOfConstruction);
		formattedDate = dateOfContruction.toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' });
	} else {
		formattedDate = 'No disponible';
	}

	return (
		<>
			{selectedRow.vehicleType === 'Máquinas' && (
				<ModalMachines
					openDetails={openDetails}
					setOpenDetails={setOpenDetails}
					selectedRow={selectedRow}
				/>
			)}
			{selectedRow.vehicleType === 'Embarcaciones' && (
				<ModalShip
					openDetails={openDetails}
					setOpenDetails={setOpenDetails}
					selectedRow={selectedRow}
				/>
			)}
			{(selectedRow.vehicleType === 'Automotores' || selectedRow.vehicleType === 'Motovehículos') && (
				<ModalCar
					openDetails={openDetails}
					setOpenDetails={setOpenDetails}
					selectedRow={selectedRow as any}
				/>
			)}
			{selectedRow.vehicleType === 'Aeronaves' && (
				<ModalAirPlane
					openDetails={openDetails}
					setOpenDetails={setOpenDetails}
					selectedRow={selectedRow}
				/>
			)}
		</>
	);
};
