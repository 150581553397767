import { SetStateAction, useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import lodash from 'lodash';
import { useMovContext } from 'hooks/useMovContext';
import { NomenclatureCode } from 'components/globals/nomenclatureCode/nomenClatureCode';

import { IStep3PropertyValues, IStep3Rights } from '../interfaces';
import { Button, Typography } from '@mui/material';
import { QuantityInput } from 'components/EncPatrimonial/altaStepper/paso3/quantityInput/QuantityInput';
import { UnitPriceInput } from '../unitPriceInput/UnitPriceInput';
import { CatastroInf } from './CatastroInf';
import { RegisterInf } from './RegisterInf';
import { InputGeneric } from 'components/globals/inputs/InputGeneric';
import { InputAttachGenForEdit } from '../inputAttachGenForEdit/InputAttachGenForEdit';
import { ValidateProperty } from './validateProperty';

import { FormControlLabel, Switch } from '@mui/material';
import { DescriptionInput } from '../generico/descriptionInput/DescriptionInput';

export const Inmueble = ({ rightList, setRightList }: IStep3Rights) => {
	const [dataFromChild, setDataFromChild] = useState<IStep3PropertyValues>({} as IStep3PropertyValues);
	const [nomenclatureCode, setNomenclatureCode] = useState('');
	const [nomenclatureCodeId, setNomenclatureCodeId] = useState<number>(0);
	const [quantity, setQuantity] = useState('');
	const [unitPrice, setUnitPrice] = useState('');
	const [address, setAddress] = useState('');
	const [attachmentFromChild, setAttachmentFromChild] = useState<File | null>(null);
	const [observationPropertyTitle, setObservationPropertyTitle] = useState('');
	const [titleOfPatrimonialDependency, setTitleOfPatrimonialDependency] = useState(false);
	const [propertyTitleSwitch, setPropertyTitleSwitch] = useState(true);
	const [disabled, setDisabled] = useState(true);
	const [submited, setSubmited] = useState(false);
	const [uuid, setUuid] = useState(uuidv4());

	const movContext = useMovContext();

	const rightType = 'Inmueble';

	useEffect(() => {
		if (!submited) {
			setPropertyTitleSwitch(false);
			setUuid(uuidv4());
		}
	}, [submited]);

	const memoizedDataFromChild = useCallback((data: SetStateAction<IStep3PropertyValues>) => {
		setDataFromChild(data);
	}, []);

	const resetValues = () => {
		setNomenclatureCode('');
		setQuantity('');
		setUnitPrice('');
		setAddress('');
		setTitleOfPatrimonialDependency(false);
		setAttachmentFromChild(null);
		setObservationPropertyTitle('');
	};

	const handleSubmit = () => {
		const list = [...rightList];
		const newItem = {
			rightType,
			id: uuid,
			nomenclatureCode,
			nomenclatureCodeId,
			quantity,
			unitPrice,
			...dataFromChild,
			address,
			propertyTitleSwitch,
			observationPropertyTitle,
			titleOfPatrimonialDependency,
			operation: 'create'
		};
		list.unshift(newItem);
		movContext?.setItemsFromTable((prev) => [...prev, newItem]);
		setRightList([...list]);
		setSubmited(true);
		resetValues();
	};

	const debouncedSubmit = lodash.debounce(handleSubmit, 1000);

	useEffect(() => {
		const valid = ValidateProperty(dataFromChild, nomenclatureCode, quantity, unitPrice, address, attachmentFromChild, propertyTitleSwitch);
		setDisabled(!valid);
	}, [debouncedSubmit, nomenclatureCode, quantity, unitPrice, address]);

	return (
		<div style={{ marginBottom: '40px' }}>
			<div style={{ marginTop: '45px', display: 'flex', columnGap: '15px', flexWrap: 'wrap' }}>
				<NomenclatureCode
					submited={submited}
					setNomenclatureCode={setNomenclatureCode}
					setNomenclatureCodeId={setNomenclatureCodeId}
				/>
				<QuantityInput
					style={{ marginLeft: '15px' }}
					submited={submited}
					setQuantity={setQuantity}
				/>
				<UnitPriceInput
					submited={submited}
					setUnitPrice={setUnitPrice}
				/>
			</div>
			<div style={{ marginTop: '40px' }}>
				<hr
					className="solid"
					style={{ borderTop: '1px solid #5c5c5c71' }}
				/>
			</div>
			<div>
				<div style={{ display: 'flex', alignItems: 'center', marginTop: '-15px' }}>
					<InputGeneric
						stateValue={address}
						label="Dirección"
						name="address"
						setStateValue={setAddress}
						sx={{ width: '400px !important' }}
					/>
					<div style={{ width: '100%' }}>
						<FormControlLabel
							control={
								<Switch
									checked={titleOfPatrimonialDependency}
									name="propertyTitleSwitch"
									onClick={() => setTitleOfPatrimonialDependency(!titleOfPatrimonialDependency)}
								/>
							}
							label="Título a Nombre de Dependencia Patrimonial"
							sx={{ width: '100%', m: '10px', mb: '20px' }}
						/>
					</div>
				</div>
				<CatastroInf
					submited={submited}
					dataFromChild={dataFromChild}
					setDataFromChild={memoizedDataFromChild}
				/>
				<RegisterInf
					submited={submited}
					dataFromChild={dataFromChild}
					setDataFromChild={memoizedDataFromChild}
				/>
				<div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }}>
					<div style={{ width: '100%', marginBottom: '20px' }}>
						<FormControlLabel
							control={
								<Switch
									checked={propertyTitleSwitch}
									name="propertyTitleSwitch"
									onClick={() => setPropertyTitleSwitch(!propertyTitleSwitch)}
								/>
							}
							label="Título de Propiedad"
							sx={{ width: '100%', m: '10px', mb: '20px' }}
						/>
					</div>
					{propertyTitleSwitch ? (
						<>
							<InputAttachGenForEdit
								uuid={uuid}
								setUuid={setUuid}
								submited={submited}
								setSubmited={setSubmited}
								setFileGen={setAttachmentFromChild}
							/>
							{!submited && (
								<Typography sx={{ color: '#d32f2f', fontSize: '12px', ml: 1, mt: 1, position: 'relative', width: '100%' }}>
									*Requerido
								</Typography>
							)}
						</>
					) : (
						<DescriptionInput
							submited={submited}
							setSubmited={setSubmited}
							setDescription={setObservationPropertyTitle}
							label="Motivo"
							placeholder="Indique el Motivo"
						/>
					)}
				</div>
			</div>
			<div>
				<Button
					disabled={disabled}
					sx={{ mt: 5.5 }}
					variant="contained"
					onClick={debouncedSubmit}>
					Agregar Bien
				</Button>
			</div>
			<hr
				className="solid"
				style={{ borderTop: '1px solid #5c5c5c71', marginBottom: '-20px' }}
			/>
		</div>
	);
};
