/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { Box } from '@mui/material';
import { ButtonConfirm, ButtonDecline } from 'components/globals/customsBtn/customBtn';
import { FormGenericInput } from 'components/globals/inputs/FormGenericInput';
import { DEFAULT_NO_ERROR, requiredValidator } from 'helpers/Validators/Validators';
import { IUser, IUserResquestBody } from 'interfaces/user/IUser';

export interface IOverviewSummaryUserForm {
	entity: IUser | undefined;
	handleSubmit: (data: IUserResquestBody) => void;
	handleCancel: () => void;
}

export interface IErrorsForm {
	firstName?: string | undefined;
	lastName?: string | undefined;
}

export const LABELS_USER_FORM = {
	firstName: 'Nombre',
	lastName: 'Apellido',
	email: 'Correo electrónico',
	group: 'Grupo',
	perfil: 'Perfil',
	patrimonialDependence: 'Dependencia Patrimonial'
};

export const OverviewSummaryUserForm = ({ entity, handleSubmit, handleCancel }: IOverviewSummaryUserForm) => {
	const [id, setId] = useState<number | undefined>(entity?.id);
	const [firstName, setFirstName] = useState<string | undefined>(entity?.first_name);
	const [lastName, setLastName] = useState<string | undefined>(entity?.last_name);
	const [email, setEmail] = useState<string | undefined>(entity?.email);
	const [group, setGroup] = useState<string | undefined>(entity?.group?.description);
	const [perfil, setPerfil] = useState<string | undefined>(entity?.patrimonial_dependence?.type);
	const [patrimonialDependence, setPatrimonialDependence] = useState<string | undefined>(entity?.patrimonial_dependence?.type);
	const [errors, setErrors] = useState<IErrorsForm>({} as IErrorsForm);
	const isEdit = !!entity;

	const validateForm = (): boolean => {
		let newErrors: IErrorsForm = {} as IErrorsForm;
		setErrors(newErrors);

		let firstNameError = requiredValidator({ value: firstName, label: LABELS_USER_FORM.firstName });
		if (firstNameError !== DEFAULT_NO_ERROR) {
			newErrors.firstName = firstNameError;
		}

		let lastNameError = requiredValidator({ value: lastName, label: LABELS_USER_FORM.lastName });
		if (lastNameError !== DEFAULT_NO_ERROR) {
			newErrors.lastName = lastNameError;
		}

		if (Object.entries(newErrors).length !== 0) {
			setErrors(newErrors);
			return false;
		}

		return true;
	};

	const getParsedData = (): IUserResquestBody => {
		return {
			id: isEdit ? entity.id : undefined,
			first_name: firstName ? firstName.trim() : '',
			last_name: lastName ? lastName.trim() : ''
		};
	};

	const onHandleSubmit = (): void => {
		if (!validateForm()) {
			return;
		}
		handleSubmit(getParsedData());
	};

	return (
		<div className="borderedBox">
			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '30px', rowGap: '30px', paddingTop: '30px' }}>
				<FormGenericInput
					value={firstName}
					label={LABELS_USER_FORM.firstName}
					name="first_name"
					id="first_name"
					handleChange={setFirstName}
					error={errors.firstName}
				/>

				<FormGenericInput
					value={lastName}
					label={LABELS_USER_FORM.lastName}
					name="last_name"
					id="last_name"
					handleChange={setLastName}
					error={errors.lastName}
				/>

				<FormGenericInput
					value={email}
					label={LABELS_USER_FORM.email}
					name="email"
					id="email"
					disabled={true}
					required={false}
				/>

				<FormGenericInput
					value={group}
					label={LABELS_USER_FORM.group}
					name="group"
					id="group"
					disabled={true}
					required={false}
				/>

				<FormGenericInput
					value={perfil}
					label={LABELS_USER_FORM.perfil}
					name="perfil"
					id="perfil"
					disabled={true}
					required={false}
				/>

				<FormGenericInput
					value={patrimonialDependence}
					label={LABELS_USER_FORM.patrimonialDependence}
					name="patrimonialDependence"
					id="patrimonialDependence"
					disabled={true}
					required={false}
				/>
			</Box>

			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
				<ButtonDecline
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => {
						handleCancel();
					}}>
					Cancelar
				</ButtonDecline>
				<ButtonConfirm
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => onHandleSubmit()}>
					Agregar
				</ButtonConfirm>
			</Box>
		</div>
	);
};
