// Axios Instance
import apiInstance from 'interceptors/axiosInterceptor';
import { IResponseError, IResponseGeneric } from 'interfaces/commons/IResponse';
import { toast } from 'react-toastify';

export const httpGet = async (uri: string, options: any) => await apiInstance.get(uri, options);

export const httpPost = async (uri: string, params: any, options: any) => await apiInstance.post(uri, params, options);

export const httpPut = async (uri: string, params: any, options: any) => await apiInstance.put(uri, params, options);

export const httpPatch = async (uri: string, params: any, options: any) =>
	await apiInstance.patch(uri, params, options);

export const httpDelete = async (uri: string, options: any) => await apiInstance.delete(uri, options);

export const handleHttpError = (error: any): IResponseError => {
	console.error(error);
	toast.error(error.response.data?.message);
	return {
		error,
		success: false,
		messages: error.response.data?.message
	};
};

export const buildURLQuery = (params: any) => {
	var esc = encodeURIComponent;
	var query = Object.keys(params)
		.map((k) => {
			if (params[k] !== undefined && params[k] !== null) {
				let dato = params[k];
				let url = esc(k) + '=' + esc(dato);
				return url;
			}
		})
		.join('&');

	while (query.startsWith('&')) {
		query = query.substring(1, query.length);
	}
	while (query.endsWith('&')) {
		query = query.substring(0, query.lastIndexOf('&'));
	}
	return query;
};

export const buildAuthorization = (accesToken: string | null) => `Bearer ${accesToken}`;

export const buildDefaultOptions = () => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');
	return {
		headers: {
			Authorization: buildAuthorization(token?.access_token)
		}
	};
};

export const captureMessageError = (
	response: any | undefined,
	defaultMessageError: string = 'Ocurrió un error, inténtelo más tarde!'
) => {
	if (!response) {
		return {
			hasError: true,
			message: defaultMessageError
		};
	}
	if (response.hasOwnProperty('error')) {
		if (response?.message) {
			defaultMessageError = ''
				.concat(Array.isArray(response.message) ? response.message[0] : response.message)
				.concat('!');
		}
		if (response?.messages) {
			defaultMessageError = ''
				.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
				.concat('!');
		}
		console.error(response.error);
		return {
			hasError: true,
			message: defaultMessageError
		};
	}
	return {
		hasError: false,
		message: defaultMessageError
	};
};

// START Download Report
export const downloadFile = async (
	uri: string,
	name: string,
	extension: 'xls' | 'pdf' = 'xls'
): Promise<void | IResponseGeneric> => {
	apiInstance({
		...buildDefaultOptions(),
		url: uri,
		method: 'GET',
		responseType: 'blob'
	})
		.then((response) => {
			let responseService: IResponseGeneric = {} as IResponseGeneric;
			if (response?.data.size > 150) {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				let nameFile = `${name}.${extension}`;
				link.setAttribute('download', nameFile);
				document.body.appendChild(link);
				link.click();
				link.remove();
				responseService.success = true;
				responseService.error = '';
				responseService.data = response;
				toast.success('¡Consulta exitosa! Su reporte debe estar descargándose en este momento.');
			} else {
				responseService.success = false;
				responseService.error = 'No se ha encontrado datos con esta consulta';
			}
			return responseService;
		})
		.catch((error) => {
			switch (error.response.status) {
				case 404:
					toast.error('Reporte no encontrado');
					break;
				default:
					toast.error('Ocurrio un error durante la consulta, contacte con el área de mantenimiento.');
					break;
			}
			let responseService: IResponseGeneric = {} as IResponseGeneric;
			responseService.error = error;
			responseService.success = false;
			responseService.data = undefined;
			return responseService;
		});
};
// END Download Report

// START Send by E-mail
export const sendFile = async (uri: string) => {
	apiInstance({
		...buildDefaultOptions(),
		url: uri,
		method: 'GET',
		responseType: 'blob'
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			switch (error.response.status) {
				case 404:
					toast.error('Reporte no encontrado');
					break;
				default:
					toast.error('Ocurrio un error durante la consulta, contacte con el área de mantenimiento.');
					break;
			}
			return error;
		});
};
// END Send by E-Mail
