import { createTheme } from '@mui/material/styles';

// Create a theme instance.
export const LightTheme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#1a3762',
			light: '#395987',
			dark: '#0a1f3d'
		},
		secondary: {
			main: '#E72260'
		}
	},
	typography: {
		fontFamily: 'Segoe UI'
	}
});

export default LightTheme;
