import './novedades.css';
import { useState, useEffect } from 'react';

// Services
import { usePromiseTracker } from 'react-promise-tracker';

// Hooks
import { useNovedadesContext } from 'hooks/useNovedadesContext';

// Interfaces
import { NotificationsPaginator } from './NotificationsPaginator';

// Components
import { BounceLoader } from 'react-spinners';
import { FormControl, InputLabel, MenuItem, Select, Box, Button, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

// Tanstack Table
import { defaultColumnsNovedades } from './NovedadesColumnDef';
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';
import {
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	FilterFn,
	useReactTable,
	SortingState,
	Row
} from '@tanstack/react-table';
import { INotifications } from 'interfaces/notifications/INotifications';
import { AppDateRangePicker } from 'components/globals/AppDateRangePicker/AppDateRangePicker';
import { DateRange } from '@mui/lab';
import { FormDialog } from 'components/globals/dialog/FormDialog';
declare module '@tanstack/table-core' {
	interface FilterFns {
		fuzzy: FilterFn<unknown>;
	}
	interface FilterMeta {
		itemRank: RankingInfo;
	}
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
	// Rank the item
	const itemRank = rankItem(row.getValue(columnId), value);

	// Store the itemRank info
	addMeta({
		itemRank
	});

	// Return if the item should be filtered in/out
	return itemRank.passed;
};

export const NovedadesTable = () => {
	const novedadesContext = useNovedadesContext();
	const [data, setData] = useState<INotifications[]>([]);
	const [columns] = useState<typeof defaultColumnsNovedades>(() => [...defaultColumnsNovedades]);
	const [sorting, setSorting] = useState<SortingState>([]);
	const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);
	const { promiseInProgress: allNotificationsTracker } = usePromiseTracker({ area: 'getAllNotificationsTracker' });
	const [showDetailModal, setShowDetailModal] = useState(false);
	const [selectedNotification, setSelectedNotification] = useState<null | INotifications>(null);

	useEffect(() => {
		if (novedadesContext?.notifications) {
			setData(novedadesContext?.notifications);
		}
	}, [novedadesContext?.notifications]);

	useEffect(() => {
		if (novedadesContext?.readFilter > 0) {
			setData((prev) => [...prev.filter((item) => item.status_id === novedadesContext?.readFilter)]);
		} else {
			setData(novedadesContext?.notifications);
		}
	}, [novedadesContext?.readFilter]);

	// Create the table and pass your options
	const table = useReactTable({
		data,
		columns,
		filterFns: {
			fuzzy: fuzzyFilter
		},
		state: {
			sorting
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel()
	});

	const handleReadUnreadNotitifacion = (notification: INotifications) => {
		novedadesContext?.setUpdateNotifications({
			notification_id: notification.id,
			status_id: notification.status_id === 1 ? 2 : 1
		});
	};

	const handleViewNotificationDetail = (notification: INotifications) => {
		setSelectedNotification(notification);
		setShowDetailModal(true);
	};

	const handleActions = (row: Row<INotifications>) => {
		if (row.original.action === 'read-unread') {
			handleReadUnreadNotitifacion(row.original);
		}

		if (row.original.action === 'view-detail') {
			handleViewNotificationDetail(row.original);
		}

		delete row.original.action;
	};

	useEffect(() => {
		const [start, end] = dateRange;

		if (!start && !end) {
			novedadesContext?.setStartDate('');
			novedadesContext?.setEndDate('');
		}

		if (start && end) {
			novedadesContext?.setStartDate(start.toJSON());
			novedadesContext?.setEndDate(end.toJSON());
		}
	}, [dateRange]);

	const resetFilters = () => {
		novedadesContext?.setStartDate('');
		novedadesContext?.setEndDate('');
		novedadesContext?.setReadFilter(0);
		setDateRange([null, null]);
	};

	return (
		<Box>
			<div>
				<Typography
					sx={{ my: 2 }}
					fontWeight={600}
					variant="h5">
					Filtrar por:
				</Typography>

				<Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
					<FormControl fullWidth>
						<InputLabel htmlFor="stateFilter">Filtrar Notificaciones</InputLabel>
						<Select
							size="small"
							id="filterReadSelect"
							name="filterReadSelect"
							value={novedadesContext?.readFilter > 0 ? novedadesContext?.readFilter : '0'}
							label="Filtrar Notificaciones"
							sx={{ width: '100%' }}
							onChange={(e) => novedadesContext?.setReadFilter(Number(e.target.value))}>
							<MenuItem value="0">Ninguno</MenuItem>
							<MenuItem value="1">No Leídas</MenuItem>
							<MenuItem value="2">Leídas</MenuItem>
						</Select>
					</FormControl>

					<Box width="100%">
						<AppDateRangePicker
							disableFuture
							size="small"
							value={dateRange}
							onChange={setDateRange}
						/>
					</Box>

					<FormControl fullWidth>
						{<InputLabel id="select-label">Cantidad de resultados</InputLabel>}
						<Select
							size="small"
							label="Cantidad de resultados"
							value={table.getState().pagination.pageSize}>
							{[10, 20, 30, 40, 50].map((pageSize) => (
								<MenuItem
									key={pageSize}
									value={pageSize}>
									Mostrar {pageSize}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<Button
						sx={{ flexShrink: 0 }}
						size="small"
						onClick={resetFilters}
						variant="contained">
						Limpiar
						<ClearIcon />
					</Button>
				</Box>

				<Box
					sx={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						my: 1
					}}>
					<Typography variant="h6">{`Total de Novedades: ${novedadesContext?.meta.totalItems || 0}`}</Typography>
				</Box>
			</div>

			{selectedNotification && showDetailModal ? (
				<FormDialog
					title="Detalle de la Notificación"
					show={showDetailModal}
					content={<Typography variant="subtitle1">{selectedNotification.content}</Typography>}
					handleClose={() => {
						setSelectedNotification(null);
						setShowDetailModal(false);
					}}
					actions={<></>}
				/>
			) : null}

			{allNotificationsTracker ? (
				<div className="table-loading">
					<BounceLoader
						size={100}
						speedMultiplier={1.5}
					/>
				</div>
			) : (
				<>
					<table className="notifications-table">
						<thead>
							{table.getHeaderGroups().map((headerGroup) => (
								<tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => (
										<th
											key={header.id}
											colSpan={header.colSpan}>
											{header.isPlaceholder ? null : (
												<div
													{...{
														className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
														onClick: header.column.getToggleSortingHandler()
													}}>
													{flexRender(header.column.columnDef.header, header.getContext())}
													{{
														asc: ' 🔼',
														desc: ' 🔽'
													}[header.column.getIsSorted() as string] ?? null}
												</div>
											)}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody>
							{table.getRowModel().rows.map((row) => (
								<tr
									key={row.id}
									style={{ backgroundColor: `${row.original.status_id === 1 ? '#00678f4c' : ''}` }}>
									{row.getVisibleCells().map((cell) => (
										<td
											onClick={() => handleActions(row)}
											key={cell.id}>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</td>
									))}
								</tr>
							))}

							{data.length === 0 ? (
								<tr>
									<td colSpan={3}>
										<Box sx={{ padding: 1 }}>{`No se encontraron resultados en la búsqueda realizada`}</Box>
									</td>
								</tr>
							) : null}
						</tbody>
					</table>
					<NotificationsPaginator
						showInputPage={false}
						meta={novedadesContext?.meta}
						setMeta={novedadesContext?.setMeta}
					/>
				</>
			)}
		</Box>
	);
};
