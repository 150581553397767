import style from './styles.module.css';

interface IheaderFormProps {
	title: string;
}

export const HeaderForm = ({ title }: IheaderFormProps) => {
	return (
		<div className={style.alta}>
			<div className={style.title}>{title}</div>
		</div>
	);
};
