import { useMemo, FC } from 'react';
import { Row, Cell } from '@tanstack/react-table';
import { defaultColumns } from './PresentationSheetColumnDef';
import { IPresentationSheet, IPresentationSheetTableProps } from 'interfaces/presentationSheet/IPresentationSheet';
import { usePresentationSheetContext } from 'hooks/usePresentationSheetContext';
import { PaginationTable } from 'components/globals/tabs/PaginationTable';
import { CustomTable } from 'components/globals/table/customTable';

export const PresentationSheetTable: FC<IPresentationSheetTableProps> = ({
	elements,
	handleAttachments = (entity: IPresentationSheet) => void 0
}) => {
	const presentationsContext = usePresentationSheetContext();
	const columns = useMemo<typeof defaultColumns>(() => [...defaultColumns], [defaultColumns]);

	const handleDownload = (ruta: string) => {
		if (ruta === undefined || ruta === null || ruta === '#' || ruta === '') {
			return;
		}
		const lastIndex = ruta.lastIndexOf('/');
		const fileName = ruta.substring(lastIndex + 1);
		fetch(ruta, {
			method: 'GET',
			headers: {}
		})
			.then((response) => {
				response.arrayBuffer().then(function (buffer) {
					const url = window.URL.createObjectURL(new Blob([buffer]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleAction = (row: Row<any>) => {
		switch (row.original?.action) {
			case 'download':
				handleDownload(row.original.documentPatch);
				break;
			case 'attachments':
				/* handleAttachments(row.original); */
				window.open(row.original.files[0]?.path, '_blank');
				break;
			default:
				break;
		}
	};

	return (
		<div className={'customBodyContainerTable'}>
			<PaginationTable
				currentPage={presentationsContext?.presentationMeta?.currentPage}
				totalPages={presentationsContext?.presentationMeta?.totalPages}
				showDetails={true}
				itemsPerPage={presentationsContext?.presentationMeta?.itemsPerPage}
				totalItems={presentationsContext?.presentationMeta?.itemCount}
				handleChangePage={(page: number, limit?: number) =>
					presentationsContext?.onHandleChangePage({ page: page, limit })
				}
			/>

			<CustomTable
				columns={columns}
				elements={elements}
				defaultSortinField={'description'}
				handleAction={(row: Row<any>) => handleAction(row)}
				itemsPerPage={presentationsContext?.presentationMeta?.itemsPerPage}
			/>

			<div className="h-2" />
			<PaginationTable
				currentPage={presentationsContext?.presentationMeta?.currentPage}
				totalPages={presentationsContext?.presentationMeta?.totalPages}
				itemsPerPage={presentationsContext?.presentationMeta?.itemsPerPage}
				totalItems={presentationsContext?.presentationMeta?.totalItems}
				handleChangePage={(page: number, limit?: number) =>
					presentationsContext?.onHandleChangePage({ page: page, limit })
				}
			/>
		</div>
	);
};
