/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid, Typography } from '@mui/material';
import { ButtonConfirm, ButtonDecline, ButtonNextPrev } from 'components/globals/customsBtn/customBtn';
import { CustomDecideDialog } from 'components/globals/dialog/CustomDeleteDialog';
import {
	deletePropertyItem,
	editGernericItem,
	editInmuebleItem,
	editVehicleItem,
	GenericItemPayload,
	getPropertyItems,
	InmueblePayload,
	saveGenericItem,
	saveInmuebleItem,
	saveVehicleItem,
	VehiclePayload
} from 'services/altaService';
import { FormDialog } from 'components/globals/dialog/FormDialog';
import { HeritageAssetsTable } from '../heritageAssets/HeritageAssetsTable';
import { IAltaStepThree } from 'interfaces/alta/IAlta';
import { IHeritageAsset } from 'interfaces/alta/IAlta';
import { ISelectOption } from 'interfaces/commons/IParameters';
import { PROPERTY_TYPE, PROPERTY_TYPE_IDS, REGISTER_TYPES_ID, VEHICLES_TYPES, VEHICLE_TYPE } from 'enums';
import { Select } from 'components/globals/select/Select';
import { Switch } from 'components/globals/switch/Switch';
import { toast } from 'react-toastify';
import { useState, useEffect, useMemo } from 'react';
import GenericForm, { GenericFormData } from './heritageAssets/generico/Generic';
import InmuebleForm, { InmuebleFormData } from './heritageAssets/inmueble/Inmueble';
import VehicleForm, { VehicleFormData } from './heritageAssets/vehicle/Vehicle';
import { NomenclatureCode } from 'services/nomenclatureCodes';
import { GenericDetails } from './heritageAssets/generico/GenericDetails';
import { VehicleDetails } from './heritageAssets/vehicle/VehicleDetails';
import { InmuebleDetails } from './heritageAssets/inmueble/InmuebleDetails';
import { useLocation } from 'react-router-dom';

export interface IOverviewAltaStepThreeForm {
	entity: IAltaStepThree | undefined;
	handleSubmit(): void;
	handleCancel(): void;
	handleBack(): void;
	buttonLabelCancel?: string;
	buttonLabelSubmit?: string;
	buttonLabelBack?: string;
	itemTypes: ISelectOption[];
	movementStatusId?: number;
}

export const LABELS_STEP_THREE_FORM = {
	type: 'Seleccionar Tipo de Bien',
	vehicleTypes: 'Seleccionar tipo de vehículo',
	isInventoriable: 'Inventariable',
	table: 'Listado de Bienes Registrados'
};

const toSnakeCaseObj = (obj: Record<string, unknown>) => {
	const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

	return Object.keys(obj).reduce((result, key) => {
		result[camelToSnakeCase(key)] = obj[key];
		return result;
	}, {} as { [k: string]: unknown });
};

const getPropertyType = () => {
	const currentData = JSON.parse(localStorage.getItem('STORAGED_REGISTER_STEP_ONE') || '{}');

	if (!currentData) return PROPERTY_TYPE[0];

	return PROPERTY_TYPE.find((el) => el.id === currentData.property_type_id);
};

const getMinuteNumber = () => {
	const currentDataStepOne = JSON.parse(localStorage.getItem('STORAGED_REGISTER_STEP_ONE') || '{}');
	return currentDataStepOne.minute_number;
};

export const AltaStepThreeForm = ({
	entity,
	handleSubmit,
	handleCancel,
	handleBack,
	buttonLabelCancel = 'Cancelar',
	buttonLabelSubmit = 'Siguiente',
	buttonLabelBack = 'Atrás',
	itemTypes,
	movementStatusId
}: IOverviewAltaStepThreeForm) => {
	const [selectedType, setSelectedType] = useState<ISelectOption>(
		JSON.parse(localStorage.getItem('SELECTED_ITEM_TYPE') as string) || getPropertyType() || PROPERTY_TYPE[0]
	);
	const minuteNumber = getMinuteNumber();
	const [vehicleSubType, setVehicleSubType] = useState<ISelectOption>(VEHICLES_TYPES[0]);
	const [isInventoriable, setIsInventoriable] = useState<boolean>(false);
	const [items, setItems] = useState<IHeritageAsset[]>([]);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [editItemModal, setEditItemModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [propertyItems, setPropertyItems] = useState<any[]>([]);
	const [selectedProperty, setSelectedProperty] = useState<any>(null);
	const [showAddAccessoryModal, setShowAddAccessoryModal] = useState(false);
	const location = useLocation();
	const isEditing = location.pathname.includes('editar');

	const selectedPropertyData = useMemo<GenericFormData | VehicleFormData | InmuebleFormData>(() => {
		if (!selectedProperty) return {} as GenericFormData | VehicleFormData | InmuebleFormData;

		const data = {
			general: {
				nomenclatureCode: {
					value: selectedProperty.account_nomenclature_details?.code || '',
					label: selectedProperty.account_nomenclature_details?.code || '',
					id: selectedProperty.account_nomenclature_id || 0
				},
				quantity: selectedProperty.quantity,
				unitPrice: {
					value: String(selectedProperty.unit_price),
					floatValue: Number(selectedProperty.unit_price),
					formattedValue: `${Number(selectedProperty.unit_price)}`
				},
				rowNumber: selectedProperty.row_number
			}
		};

		if (selectedProperty?.property_type_id === PROPERTY_TYPE_IDS.GENERIC) {
			return {
				...data.general,
				description: selectedProperty.description
			};
		}

		if (selectedProperty?.property_type_id === PROPERTY_TYPE_IDS.VEHICLE) {
			return {
				general: data.general,
				vehicle: {
					brand: selectedProperty.details.brand,
					chassisNumber: selectedProperty.details.chassis_number,
					description: selectedProperty.description || '',
					domain: selectedProperty.details.domain,
					engineBrand: selectedProperty.details.engine_brand,
					engineHolder: selectedProperty.details.engine_holder,
					engineNumber: selectedProperty.details.engine_number,
					frameNumber: selectedProperty.details.frame_number,
					headline: selectedProperty.details.headline,
					helmetModel: selectedProperty.details.helmet_model,
					helmetNumber: selectedProperty.details.helmet_number,
					manufacturingDate: selectedProperty.details.manufacturing_date,
					model: selectedProperty.details.model,
					modelSeries: selectedProperty.details.model_series,
					origin: selectedProperty.details.origin,
					propertyTitle: !!selectedProperty.property_files?.length,
					registration: selectedProperty.details.registration,
					serialNumber: selectedProperty.details.serial_number,
					shipName: selectedProperty.details.ship_name,
					type: selectedProperty.details.type,
					year: selectedProperty.details.year,
					helmetBrand: selectedProperty.details.helmet_brand
				},
				ownership: { propertyTitle: [] }
			};
		}

		if (selectedProperty?.property_type_id === PROPERTY_TYPE_IDS.INMUEBLE) {
			return {
				general: data.general,
				cadastre: {
					department: {
						id: selectedProperty.details.cadastral_information.department.id,
						label: selectedProperty.details.cadastral_information.department.name,
						data: selectedProperty.details.cadastral_information.department
					},
					municipality: {
						id: selectedProperty.details.cadastral_information.location.id,
						label: selectedProperty.details.cadastral_information.location.name,
						data: selectedProperty.details.cadastral_information.location
					},
					section: selectedProperty.details.cadastral_information.section,
					ranch: selectedProperty.details.cadastral_information.farm,
					realEstateItem: selectedProperty.details.cadastral_information.real_estate_item,
					block: selectedProperty.details.cadastral_information.street,
					functionalUnit: selectedProperty.details.cadastral_information.functional_unit,
					planeNumber: selectedProperty.details.cadastral_information.plane_number,
					parcel: selectedProperty.details.cadastral_information.parcel
				},
				registry: {
					department: selectedProperty.details.registry_information.department,
					farm: selectedProperty.details.registry_information.farm,
					invoice: selectedProperty.details.registry_information.invoice,
					observations: selectedProperty.details.registry_information.observations,
					tome: selectedProperty.details.registry_information.took,
					propertyDescription: selectedProperty.details.registry_information.property_description,
					owner: selectedProperty.details.registry_information.owner,
					registrationNumber: selectedProperty.details.registry_information.registration_number
				},
				address: {
					value: selectedProperty.details.address
				},
				ownership: {
					hasPropertyTitle: selectedProperty.details.property_title,
					isNameOfPatrimonialDependency: selectedProperty.details.title_name_patrimonial_unit,
					reason: selectedProperty.description,
					propertyTitle: []
				}
			};
		}

		return {} as GenericFormData | VehicleFormData | InmuebleFormData;
	}, [selectedProperty]);

	const handleShowDeleteModal = (entity: any) => {
		handleSelectProperty(entity);
		setShowDeleteModal(true);
	};

	const handleHideDeleteModal = () => {
		setSelectedProperty(null);
		setShowDeleteModal(false);
	};

	const handleDeleteConfirm = async () => {
		try {
			await deletePropertyItem({ propertyRegistrationId: entity?.id as number, propertyId: selectedProperty.id });
			fetchPropertyItems();
		} catch {
			toast('Ocurrio un error al eliminar el bien.', { type: 'error', autoClose: 1000 });
		}
	};

	const handleSelectProperty = (item: IHeritageAsset) => {
		const items = item.is_accessory ? item.nodes || [] : propertyItems;

		const selected = items.find(
			({ id, property_type_id }: any) => id === item.id && property_type_id === item.property_id
		);

		if (!selected) return;

		setSelectedProperty(selected);
	};

	const handleAddProperty = async (handler: CallableFunction) => {
		setIsSubmitting(true);
		const response = await handler();
		setIsSubmitting(false);

		if (response.error) {
			toast(response.error, { type: 'error', autoClose: 2000 });
			return false;
		}

		fetchPropertyItems();

		toast('Bien agregado correctamente', { type: 'success', autoClose: 2000 });
		return true;
	};

	const handleEditProperty = async (handler: CallableFunction) => {
		setIsSubmitting(true);
		const response = await handler();
		setIsSubmitting(false);

		if (response.error) {
			toast(response.error, { type: 'error', autoClose: 2000 });
			return false;
		}

		fetchPropertyItems();

		toast('Bien editado correctamente', { type: 'success', autoClose: 2000 });
		setEditItemModal(false);
		setSelectedProperty(null);
		return true;
	};

	const getDescription = (property: any) => {
		let description = property.description;

		if (property.property_type_id === PROPERTY_TYPE_IDS.VEHICLE) {
			description = ['brand', 'model', 'type', 'engine_number', 'chassis_number', 'origin'].reduce((val, key) => {
				const newValue = property.details[key] ? `${property.details[key]} ` : '';
				return `${val}${newValue}`;
			}, '');

			if (property.property_sub_type_id === 5) {
				description = property.description;
			}
		}

		if (property.property_type_id === PROPERTY_TYPE_IDS.INMUEBLE) {
			const department = property.details.cadastral_information.department.name;
			const location = property.details.cadastral_information.location.name;

			const inmuebleDetails = {
				...property.details.cadastral_information,
				...property.details.registry_information,
				address: property.details.address,
				department,
				location
			};

			description = [
				'address',
				'department',
				'location',
				'section',
				'farm',
				'street',
				'parcel',
				'functional_unit',
				'real_estate_item'
			].reduce((val, key) => {
				return `${val}${inmuebleDetails[key]} `;
			}, '');
		}

		return description;
	};

	const fetchPropertyItems = async () => {
		const data = await getPropertyItems(entity?.id as number);

		setPropertyItems(data);

		const getItemData = (entity: any) => {
			return {
				account_nomenclature_id: entity.account_nomenclature_details?.code || '-',
				deregistration_type_id: 0,
				description_destiny: null,
				description: getDescription(entity) || '-',
				id: entity.id,
				label: entity.account_nomenclature_details?.description || '-',
				property_id: entity.property_type_id,
				property: undefined,
				quantity: entity.quantity,
				to_patrimonial_dependence_id: 0,
				unit_price: entity.unit_price,
				inventory_number: entity.item_inventory_number
			};
		};

		setItems(
			data.map((parent: any) => {
				const accessories = parent.children.map((child: any) => {
					return {
						is_accessory: true,
						nodes: parent.children,
						...getItemData(child)
					};
				});

				const data: IHeritageAsset = {
					accesories_count: parent.children.length,
					is_accessory: false,
					showAccessories: false,
					accessoriesTable: (
						<tr>
							<td colSpan={isEditing ? 8 : 7}>
								<Box
									padding="12px 24px"
									bgcolor="#f9f9f9">
									<Typography
										mb={1}
										variant="subtitle2"
										fontSize={18}
										lineHeight={1}
										textAlign="left">
										Accesorios
									</Typography>
									<HeritageAssetsTable
										stepData={minuteNumber}
										showItemInventoryNumber={isEditing}
										elements={accessories}
										handleShow={handleShowPropertyDetails}
										handleDelete={handleShowDeleteModal}
										handleEdit={handleEditPropertyDetails}
										handleAccesory={() => void 0}
									/>
								</Box>
							</td>
						</tr>
					),
					...getItemData(parent)
				};

				return data;
			})
		);
	};

	const getInmueblePayload = (data: InmuebleFormData) => {
		const department = data.cadastre.department?.data.name;
		const location = data.cadastre.municipality?.data.name as string;

		const inmuebleDetails = {
			...data.cadastre,
			...data.registry,
			address: data.address.value,
			department,
			location
		} as any;

		const description = [
			'address',
			'department',
			'location',
			'section',
			'farm',
			'street',
			'parcel',
			'functional_unit',
			'real_estate_item'
		].reduce((val, key) => {
			const value = inmuebleDetails[key];
			if (value !== undefined && value !== null) {
				return `${val}${value} `;
			} else {
				return val;
			}
		}, '');

		const payload: InmueblePayload = {
			is_quantifiable: isInventoriable,
			account_nomenclature_id: data.general.nomenclatureCode?.id as number,
			description: showAddAccessoryModal ? data.general.nomenclatureCode?.label : description ?? data.ownership.reason,
			discharge_date: new Date().toJSON(),
			is_accessory: selectedProperty?.is_accessory || showAddAccessoryModal,
			property_type_id: PROPERTY_TYPE_IDS.INMUEBLE,
			quantity: Number(data.general.quantity),
			unit_price: Number(data.general.unitPrice.value),
			details: {
				address: data.address.value,
				cadastral_information: {
					department: {
						id: data.cadastre.department?.id as number,
						name: data.cadastre.department?.data.name,
						department_id: data.cadastre.department?.data.department_id
					},
					location: {
						id: data.cadastre.municipality?.id as number,
						name: data.cadastre.municipality?.data.name as string,
						location_id: data.cadastre.municipality?.data.location_id,
						department_id: data.cadastre.municipality?.data.department_id
					},
					section: data.cadastre.section,
					farm: data.cadastre.ranch,
					street: data.cadastre.block,
					parcel: data.cadastre.parcel,
					functional_unit: data.cadastre.functionalUnit,
					real_estate_item: data.cadastre.realEstateItem,
					plane_number: data.cadastre.planeNumber
				},
				registry_information: {
					department: data.registry.department,
					registration_number: data.registry.registrationNumber,
					took: data.registry.tome,
					invoice: data.registry.invoice,
					farm: data.registry.farm,
					property_description: data.registry.propertyDescription,
					owner: data.registry.owner,
					observations: data.registry.observations
				},
				property_title: data.ownership.hasPropertyTitle,
				title_name_patrimonial_unit: data.ownership.isNameOfPatrimonialDependency
			},
			files: data.ownership.propertyTitle,
			parent_property_id: selectedProperty?.id || 0,
			high_type_id: entity?.high_type_id
		};

		if (entity?.high_type_id === REGISTER_TYPES_ID.ACQUISITION) {
			payload.row_number = Number(data.general.rowNumber);
		}

		return payload;
	};

	const validatePriceRange = (price: number) => {
		if (price === 0) {
			toast('El precio unitario no puede ser 0', { type: 'error' });
			return false;
		}
		if (price < 0) {
			toast('El precio unitario no puede ser negativo', { type: 'error' });
			return false;
		}
		if (price > 99999999.99) {
			toast('El precio unitario no puede ser mayor a 99999999.99', { type: 'error' });
			return false;
		}
	};

	const handleAddGeneric = (data: GenericFormData) => {
		const payload: GenericItemPayload = {
			account_nomenclature_id: data?.nomenclatureCode?.id,
			description: data.description,
			discharge_date: new Date().toJSON(),
			is_accessory: showAddAccessoryModal,
			is_quantifiable: isInventoriable || selectedProperty?.is_quantifiable,
			property_type_id: PROPERTY_TYPE_IDS.GENERIC,
			details: {},
			quantity: Number(data.quantity),
			unit_price: Number(data.unitPrice.value),
			parent_property_id: showAddAccessoryModal ? selectedProperty.id : undefined,
			high_type_id: entity?.high_type_id
		};

		if (entity?.high_type_id === REGISTER_TYPES_ID.ACQUISITION) {
			payload.row_number = Number(data.rowNumber);
		}

		if (!isInventoriable && !selectedProperty?.is_quantifiable) delete payload.account_nomenclature_id;
		if (validatePriceRange(payload.unit_price) == false) return Promise.resolve(false);
		return handleAddProperty(() => saveGenericItem(entity?.id as number, payload));
	};

	const handleAddInmueble = (data: InmuebleFormData) => {
		return handleAddProperty(() => saveInmuebleItem(entity?.id as number, getInmueblePayload(data)));
	};

	const handleAddVehicle = (data: VehicleFormData) => {
		const subTypeID = showAddAccessoryModal ? (VEHICLES_TYPES.at(-1)?.id as number) : (vehicleSubType.id as number);

		const payload: VehiclePayload = {
			is_quantifiable: isInventoriable,
			account_nomenclature_id: data.general.nomenclatureCode?.id as number,
			is_accessory: showAddAccessoryModal,
			quantity: Number(data.general.quantity),
			discharge_date: data.vehicle.manufacturingDate,
			unit_price: Number(data.general.unitPrice.value),
			property_type_id: PROPERTY_TYPE_IDS.VEHICLE,
			property_sub_type_id: subTypeID,
			details: toSnakeCaseObj(data.vehicle),
			files: data.ownership.propertyTitle,
			parent_property_id: showAddAccessoryModal ? selectedProperty.id : undefined,
			high_type_id: entity?.high_type_id
		};

		if (entity?.high_type_id === REGISTER_TYPES_ID.ACQUISITION) {
			payload.row_number = Number(data.general.rowNumber);
		}

		if (payload.property_sub_type_id === 5 /* OTHERS */) {
			payload.description = data.vehicle.description;
			payload.details = {};
		}

		if (!payload.details.manufacturing_date) {
			delete payload.details.manufacturing_date;
			delete payload.discharge_date;
		}

		if (validatePriceRange(payload.unit_price) == false) return Promise.resolve(false);
		return handleAddProperty(() => saveVehicleItem(entity?.id as number, payload));
	};

	const handleEditGeneric = (data: GenericFormData) => {
		const payload: GenericItemPayload = {
			id: selectedProperty.id,
			account_nomenclature_id: data?.nomenclatureCode?.id,
			description: data.description,
			discharge_date: new Date().toJSON(),
			is_accessory: selectedProperty.is_accessory || false,
			is_quantifiable: selectedProperty.is_quantifiable,
			property_type_id: selectedProperty.property_type_id ? selectedProperty.property_type_id : Number(selectedType.id),
			details: {},
			quantity: Number(data.quantity),
			unit_price: Number(data.unitPrice.value),
			parent_property_id: selectedProperty.parent_property_id || 0,
			row_number: Number(data.rowNumber),
			high_type_id: entity?.high_type_id
		};

		if (entity?.high_type_id === REGISTER_TYPES_ID.ACQUISITION) {
			payload.row_number = Number(data.rowNumber);
		}

		if (!selectedProperty.is_quantifiable) {
			delete payload.account_nomenclature_id;
		}
		if (validatePriceRange(payload.unit_price) == false) return Promise.resolve(false);
		return handleEditProperty(() => editGernericItem(entity?.id as number, payload));
	};

	const handleEditVehicle = (data: VehicleFormData) => {
		const payload: VehiclePayload = {
			is_quantifiable: isInventoriable,
			account_nomenclature_id: data.general.nomenclatureCode?.id as number,
			details: toSnakeCaseObj(data.vehicle),
			discharge_date: data.vehicle.manufacturingDate,
			files: data.ownership.propertyTitle,
			id: selectedProperty.id,
			is_accessory: selectedProperty.is_accessory,
			property_sub_type_id: selectedProperty.property_sub_type_id,
			property_type_id: PROPERTY_TYPE_IDS.VEHICLE,
			quantity: Number(data.general.quantity),
			unit_price: Number(data.general.unitPrice.value),
			parent_property_id: selectedProperty.parent_property_id || 0,
			high_type_id: entity?.high_type_id
		};

		const vehicleType = VEHICLES_TYPES.find((type) => type.id === selectedProperty.property_sub_type_id);

		if (entity?.high_type_id === REGISTER_TYPES_ID.ACQUISITION) {
			payload.row_number = Number(data.general.rowNumber);
		}

		if (vehicleType?.label === VEHICLE_TYPE.OTHERS) {
			payload.description = data.vehicle.description;
			payload.details = {};
		}

		if (!payload.details.manufacturing_date) {
			delete payload.details.manufacturing_date;
			delete payload.discharge_date;
		}

		if (validatePriceRange(payload.unit_price) == false) return Promise.resolve(false);
		return handleEditProperty(() => editVehicleItem(entity?.id as number, payload));
	};

	const handleEditInmueble = (data: InmuebleFormData) => {
		return handleEditProperty(() =>
			editInmuebleItem(entity?.id as number, {
				id: selectedProperty.id,
				...getInmueblePayload(data)
			})
		);
	};

	const EditFormComponent = () => {
		if (!selectedProperty) return <></>;

		if (selectedProperty.property_type_id === PROPERTY_TYPE_IDS.GENERIC) {
			return (
				<GenericForm
					showRowNumberField={entity?.high_type_id === REGISTER_TYPES_ID.ACQUISITION}
					isAccessory={selectedProperty.is_accessory}
					customInputDisable={{
						quantity: selectedProperty.is_accessory || Boolean(movementStatusId && movementStatusId !== 1),
						nomenclatureCode: !selectedProperty.is_quantifiable
					}}
					value={selectedPropertyData as GenericFormData}
					submitBtnLabel="Guardar"
					onSubmit={handleEditGeneric}
					loading={isSubmitting}
					readonly={showDetailsModal}
				/>
			);
		}

		if (selectedProperty.property_type_id === PROPERTY_TYPE_IDS.INMUEBLE) {
			return (
				<InmuebleForm
					showRowNumberField={entity?.high_type_id === REGISTER_TYPES_ID.ACQUISITION}
					customInputDisable={{ general: { quantity: Boolean(movementStatusId && movementStatusId !== 1) } }}
					loading={isSubmitting}
					value={selectedPropertyData as InmuebleFormData}
					onSubmit={handleEditInmueble}
					submitBtnLabel="Guardar"
					readonly={showDetailsModal}
					filesPath={`/properties-files/${entity?.id}`}
					attachments={selectedProperty.property_files}
				/>
			);
		}

		const vehicleType = VEHICLES_TYPES.find((type) => type.id === selectedProperty.property_sub_type_id);

		if (selectedProperty.property_type_id === PROPERTY_TYPE_IDS.VEHICLE && vehicleType) {
			return (
				<VehicleForm
					showRowNumberField={entity?.high_type_id === REGISTER_TYPES_ID.ACQUISITION}
					customInputDisable={{ general: { quantity: Boolean(movementStatusId && movementStatusId !== 1) } }}
					isAccessory={selectedProperty.is_accessory}
					attachments={selectedProperty.property_files}
					vehicleType={vehicleType.label}
					loading={isSubmitting}
					onSubmit={handleEditVehicle}
					value={selectedPropertyData as VehicleFormData}
					submitBtnLabel="Guardar"
					readonly={showDetailsModal}
					propertySubTypeId={VEHICLES_TYPES.find((el) => el.label === vehicleType.label)?.id}
				/>
			);
		}

		return <></>;
	};

	const DetailsPropertyComponent = () => {
		if (!selectedProperty) return <></>;

		if (selectedProperty.property_type_id === PROPERTY_TYPE_IDS.GENERIC) {
			return (
				<GenericDetails
					{...(selectedPropertyData as GenericFormData)}
					nomenclatureCode={{
						label: selectedProperty.account_nomenclature_details?.description?.split('-')[1],
						value: selectedProperty.account_nomenclature_details?.code,
						id: 0
					}}
					isInventoriable={selectedProperty.is_quantifiable}
				/>
			);
		}

		if (selectedProperty.property_type_id === PROPERTY_TYPE_IDS.VEHICLE) {
			const data = selectedPropertyData as VehicleFormData;
			const vehicleType = VEHICLES_TYPES.find((type) => type.id === selectedProperty.property_sub_type_id);

			return (
				<VehicleDetails
					isAccessory={selectedProperty.is_accessory}
					vehicleType={vehicleType?.label}
					nomenclatureCode={{
						label: selectedProperty.account_nomenclature_details.description,
						value: selectedProperty.account_nomenclature_details.code,
						id: 0
					}}
					quantity={data.general.quantity}
					unitPrice={data.general.unitPrice.floatValue as number}
					details={data.vehicle}
					rowNumber={data.general.rowNumber}
				/>
			);
		}

		if (selectedProperty.property_type_id === PROPERTY_TYPE_IDS.INMUEBLE) {
			const data = selectedPropertyData as InmuebleFormData;
			return (
				<InmuebleDetails
					isAccessory={selectedProperty.is_accessory}
					nomenclatureCode={{
						label: selectedProperty.account_nomenclature_details.description,
						value: selectedProperty.account_nomenclature_details.code,
						id: 0
					}}
					quantity={data.general.quantity}
					unitPrice={data.general.unitPrice.floatValue as number}
					address={data.address.value}
					cadestre={data.cadastre}
					registry={data.registry}
					rowNumber={data.general.rowNumber}
				/>
			);
		}

		return <></>;
	};

	const AddAccesoryFormComponent = () => {
		if (!selectedProperty) return <></>;

		if (selectedProperty.property_type_id === PROPERTY_TYPE_IDS.GENERIC) {
			return (
				<GenericForm
					isAccessory
					showRowNumberField={entity?.high_type_id === REGISTER_TYPES_ID.ACQUISITION}
					customInputDisable={{ quantity: true, nomenclatureCode: !selectedProperty.is_quantifiable }}
					onSubmit={handleAddGeneric}
					onSuccess={() => {
						setShowAddAccessoryModal(false);
						setSelectedProperty(null);
					}}
					loading={isSubmitting}
					value={{
						nomenclatureCode: selectedProperty.account_nomenclature,
						quantity: selectedProperty.quantity,
						unitPrice: { value: '', formattedValue: '', floatValue: 0 },
						description: '',
						rowNumber: ''
					}}
					submitBtnLabel="Agregar Accesorio"
				/>
			);
		}

		if (selectedProperty.property_type_id === PROPERTY_TYPE_IDS.INMUEBLE) {
			return (
				<InmuebleForm
					showRowNumberField={entity?.high_type_id === REGISTER_TYPES_ID.ACQUISITION}
					isAccessory
					loading={isSubmitting}
					value={{
						general: {
							nomenclatureCode: undefined,
							quantity: '1',
							unitPrice: { value: '', formattedValue: '', floatValue: 0 },
							rowNumber: ''
						},
						cadastre: {
							department: undefined,
							municipality: undefined,
							section: '',
							parcel: '',
							ranch: '',
							block: '',
							functionalUnit: '',
							realEstateItem: '',
							planeNumber: ''
						},
						registry: {
							department: '',
							registrationNumber: '',
							tome: '',
							invoice: '',
							farm: '',
							owner: '',
							propertyDescription: '',
							observations: ''
						},
						ownership: {
							isNameOfPatrimonialDependency: false,
							hasPropertyTitle: false,
							reason: '',
							propertyTitle: []
						},
						address: {
							value: ''
						}
					}}
					onSubmit={handleAddInmueble}
					onSuccess={() => {
						setShowAddAccessoryModal(false);
						setSelectedProperty(null);
					}}
					submitBtnLabel="Agregar accesorio"
					readonly={showDetailsModal}
					filesPath={`/properties-files/${entity?.id}`}
					attachments={[]}
				/>
			);
		}

		const vehicleType = VEHICLES_TYPES.find((type) => type.id === selectedProperty.property_sub_type_id);

		if (selectedProperty.property_type_id === PROPERTY_TYPE_IDS.VEHICLE && vehicleType) {
			return (
				<VehicleForm
					isAccessory
					showRowNumberField={entity?.high_type_id === REGISTER_TYPES_ID.ACQUISITION}
					onSubmit={handleAddVehicle}
					onSuccess={() => {
						setShowAddAccessoryModal(false);
						setSelectedProperty(null);
					}}
					loading={isSubmitting}
					value={{
						general: {
							nomenclatureCode: undefined,
							quantity: selectedProperty.quantity,
							unitPrice: { value: '', floatValue: 0, formattedValue: '' },
							rowNumber: ''
						},
						vehicle: {
							registration: '',
							serialNumber: '',
							brand: '',
							model: '',
							manufacturingDate: '',
							type: '',
							shipName: '',
							helmetNumber: '',
							helmetModel: '',
							headline: '',
							engineBrand: '',
							engineNumber: '',
							modelSeries: '',
							engineHolder: '',
							domain: '',
							chassisNumber: '',
							year: '',
							origin: '',
							frameNumber: '',
							description: '',
							propertyTitle: false,
							helmetBrand: ''
						},
						ownership: {
							propertyTitle: []
						}
					}}
					vehicleType={VEHICLE_TYPE.OTHERS}
					submitBtnLabel="Agregar Accesorio"
					propertySubTypeId={VEHICLES_TYPES.find((el) => el.label === vehicleType.label)?.id}
				/>
			);
		}

		return <></>;
	};

	const handleShowPropertyDetails = (entity: any) => {
		handleSelectProperty(entity);
		setShowDetailsModal(true);
	};

	const handleEditPropertyDetails = (entity: any) => {
		handleSelectProperty(entity);
		setEditItemModal(true);
	};

	const handleAddPropertyAccessory = (entity: any) => {
		handleSelectProperty(entity);
		setShowAddAccessoryModal(true);
	};

	useEffect(() => void fetchPropertyItems(), []);

	useEffect(() => setVehicleSubType(VEHICLES_TYPES[0]), [selectedType]);

	useEffect(() => {
		if (!selectedProperty) {
			setShowAddAccessoryModal(false);
			setShowDeleteModal(false);
			setShowDetailsModal(false);
		}
	}, [selectedProperty]);

	useEffect(() => {
		if (selectedType?.id === PROPERTY_TYPE_IDS.INMUEBLE || selectedType?.id === PROPERTY_TYPE_IDS.VEHICLE) {
			setIsInventoriable(true);
		}

		if (selectedType?.id === PROPERTY_TYPE_IDS.GENERIC) {
			setIsInventoriable(false);
		}
	}, [selectedType]);

	return (
		<>
			{showDetailsModal && selectedProperty && (
				<FormDialog
					maxWidth="lg"
					show={showDetailsModal}
					title={selectedProperty.is_accessory ? 'Detalles del Accesorio' : 'Detalles del Bien Patrimonial'}
					content={<DetailsPropertyComponent />}
					actions={<></>}
					handleClose={() => {
						setShowDetailsModal(false);
						setSelectedProperty(null);
					}}
				/>
			)}

			{showDeleteModal && selectedProperty && (
				<CustomDecideDialog
					title={selectedProperty.is_accessory ? 'Remover el Accesorio' : 'Remover Bien Patrimonial'}
					message={
						selectedProperty.is_accessory ? '¿Desea remover el Accesorio?' : '¿Desea remover el Bien Patrimonial?'
					}
					handleSubmit={handleDeleteConfirm}
					handleDecline={handleHideDeleteModal}
				/>
			)}

			{editItemModal && selectedProperty && (
				<FormDialog
					maxWidth="lg"
					show={editItemModal}
					title={selectedProperty.is_accessory ? 'Editar Accesorio' : 'Editar Bien Patrimonial'}
					content={<EditFormComponent />}
					actions={<></>}
					handleClose={() => {
						setEditItemModal(false);
						setSelectedProperty(null);
					}}
				/>
			)}

			{showAddAccessoryModal && selectedProperty && (
				<FormDialog
					maxWidth="lg"
					show={showAddAccessoryModal}
					title={'Agregar Accesorio a Bien Patrimonial'}
					content={<AddAccesoryFormComponent />}
					actions={<></>}
					handleClose={() => {
						setShowAddAccessoryModal(false);
						setSelectedProperty(null);
					}}
				/>
			)}

			<Box className="borderedBox">
				<Grid
					container
					spacing={3}
					pt={3}>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={4}
						xl={3}>
						<Select
							label="Tipo de Bien"
							options={itemTypes.map((el) => ({ ...el, disabled: el.id === PROPERTY_TYPE_IDS.INMUEBLE }))}
							value={selectedType}
							onChange={(val) => {
								setSelectedType(val as ISelectOption);
							}}
							disabled={
								selectedType.id === PROPERTY_TYPE_IDS.INMUEBLE || Boolean(movementStatusId && movementStatusId !== 1)
							}
						/>
					</Grid>

					{selectedType.id === PROPERTY_TYPE_IDS.VEHICLE ? (
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={4}
							xl={4}>
							<Select
								label="Tipo de Vehículo"
								options={VEHICLES_TYPES}
								value={vehicleSubType}
								onChange={(val) => {
									setVehicleSubType(val as ISelectOption);
								}}
							/>
						</Grid>
					) : null}

					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={4}
						xl={4}
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: 2
						}}>
						<Typography
							variant="h1"
							fontSize={16}
							fontWeight={500}
							color="info">
							¿Es Inventariable?
						</Typography>

						<Box>
							<Switch
								disabled={
									selectedType.id === PROPERTY_TYPE_IDS.VEHICLE ||
									selectedType.id === PROPERTY_TYPE_IDS.INMUEBLE ||
									Boolean(movementStatusId && movementStatusId !== 1)
								}
								onChange={setIsInventoriable}
								value={isInventoriable}
							/>
						</Box>
					</Grid>
				</Grid>

				<Grid container>
					{selectedType.id === PROPERTY_TYPE_IDS.GENERIC && (
						<GenericForm
							showRowNumberField={entity?.high_type_id === REGISTER_TYPES_ID.ACQUISITION}
							customInputDisable={{
								nomenclatureCode: !isInventoriable
							}}
							onSubmit={handleAddGeneric}
							loading={isSubmitting}
						/>
					)}

					{selectedType.id === PROPERTY_TYPE_IDS.VEHICLE && (
						<VehicleForm
							showRowNumberField={entity?.high_type_id === REGISTER_TYPES_ID.ACQUISITION}
							attachments={[]}
							vehicleType={vehicleSubType.label as VEHICLE_TYPE}
							loading={isSubmitting}
							onSubmit={handleAddVehicle}
							propertySubTypeId={VEHICLES_TYPES.find((el) => el.label === vehicleSubType.label)?.id}
						/>
					)}

					{selectedType.id === PROPERTY_TYPE_IDS.INMUEBLE && (
						<InmuebleForm
							showRowNumberField={entity?.high_type_id === REGISTER_TYPES_ID.ACQUISITION}
							attachments={[]}
							onSubmit={handleAddInmueble}
							loading={isSubmitting}
							filesPath="/properties-files"
						/>
					)}
				</Grid>

				<Typography
					variant="subtitle2"
					style={{ width: '100%' }}
					pl={1}
					mt={1}>{`(*) Campos Obligatorios.`}</Typography>

				<Box>
					<hr />

					<Typography
						sx={{ pb: 1, pt: 1 }}
						variant="h6">
						{LABELS_STEP_THREE_FORM.table}
					</Typography>

					<HeritageAssetsTable
						stepData={minuteNumber}
						showAccesories
						elements={items}
						showItemInventoryNumber={isEditing}
						handleShow={handleShowPropertyDetails}
						handleDelete={handleShowDeleteModal}
						handleEdit={handleEditPropertyDetails}
						handleAccesory={handleAddPropertyAccessory}
					/>
				</Box>

				<Box
					sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
					<ButtonDecline
						sx={{ mr: 1 }}
						variant="contained"
						onClick={handleCancel}>
						{buttonLabelCancel}
					</ButtonDecline>

					<ButtonNextPrev
						sx={{ ml: 2, mr: 2 }}
						variant="contained"
						onClick={handleBack}>
						{buttonLabelBack}
					</ButtonNextPrev>

					<ButtonConfirm
						disabled={propertyItems.length === 0}
						sx={{ ml: 1 }}
						variant="contained"
						onClick={handleSubmit}>
						{buttonLabelSubmit}
					</ButtonConfirm>
				</Box>
			</Box>
		</>
	);
};
