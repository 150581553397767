import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ButtonConfirm, ButtonDecline, ButtonNextPrev } from 'components/globals/customsBtn/customBtn';
import { CustomAccordion, IAccordionProps } from 'components/globals/accordion/CustomAccordion';
import { SummaryStepOne } from './../summary/SummaryStepOne';
import { SummaryStepTwo } from '../summary/SummaryStepTwo';
import { SummaryStepThree } from '../summary/SummaryStepThree';
import { SummaryFiles } from '../summary/SummaryFiles';
import { IAltaStepOne, IAltaStepThree, IAltaStepTwo } from 'interfaces/alta/IAlta';
import { getPropertyData } from 'services/altaService';

export interface IOverviewAltaSummaryForm {
	dataStepOne: IAltaStepOne;
	dataStepTwo: IAltaStepTwo;
	dataStepThree: IAltaStepThree;
	handleSubmit: () => void;
	handleCancel: () => void;
	handleBack: () => void;
	buttonLabelCancel?: string;
	buttonLabelSubmit?: string;
	buttonLabelBack?: string;
	handleBackHome?: () => void;
	buttonLabelBackHome?: string;
	showBackHomeButton?: boolean;
}

export const AltaSummary = ({
	handleSubmit,
	handleCancel,
	handleBack,
	handleBackHome,
	buttonLabelCancel = 'Cancelar',
	buttonLabelSubmit = 'Finalizar',
	buttonLabelBack = 'Atrás',
	buttonLabelBackHome = 'Aceptar',
	showBackHomeButton = false
}: IOverviewAltaSummaryForm) => {
	const [summaryData, setSummaryData] = useState<any>({});

	const { id: documentId } = JSON.parse(localStorage.getItem('STORAGED_REGISTER_STEP_THREE') || '{}') as { id: number };
	const fetchDocumentData = async () => {
		setSummaryData(await getPropertyData(documentId));
	};

	const accordionData: IAccordionProps[] = [
		{
			title: 'Paso 1: Registro de Recepción',
			children: <SummaryStepOne entity={summaryData as IAltaStepOne} />
		},
		{
			title: 'Paso 2: Registro de Instrumentos',
			children: <SummaryStepTwo entity={summaryData as IAltaStepTwo} />
		},
		{
			title: 'Paso 3: Detalle de Items',
			children: <SummaryStepThree entity={summaryData as IAltaStepThree} />
		},
		{
			title: 'Documentos Adjuntos',
			children: <SummaryFiles entity={summaryData as IAltaStepTwo} />
		}
	];

	useEffect(() => void fetchDocumentData(), []);

	return (
		<>
			<Box pt={1}>{summaryData.id ? <CustomAccordion accordions={accordionData} /> : null}</Box>
			<Box
				sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}
				mt={2}>
				<ButtonDecline
					sx={{ mr: 1 }}
					variant="contained"
					onClick={() => {
						handleCancel();
					}}>
					{buttonLabelCancel}
				</ButtonDecline>

				<ButtonNextPrev
					sx={{ ml: 2, mr: 2 }}
					variant="contained"
					onClick={handleBack}>
					{buttonLabelBack}
				</ButtonNextPrev>

				{showBackHomeButton ? (
					<ButtonNextPrev
						sx={{ ml: 2 }}
						variant="contained"
						onClick={handleBackHome}>
						{buttonLabelBackHome}
					</ButtonNextPrev>
				) : (
					<ButtonConfirm
						sx={{ ml: 1 }}
						variant="contained"
						onClick={() => handleSubmit()}>
						{buttonLabelSubmit}
					</ButtonConfirm>
				)}
			</Box>
		</>
	);
};
