import { FC, useState, useEffect } from 'react';
import { Row } from '@tanstack/react-table';
import { defaultColumns } from './HeritageAssetColumnDef';
//import { PaginationTable } from 'components/globals/tabs/PaginationTable';
import { IHeritageAssetTableProps } from 'interfaces/alta/IAlta';
import { CustomTable } from 'components/globals/table/customTable';
import { Typography } from '@mui/material';

export const HeritageAssetsTable: FC<IHeritageAssetTableProps> = ({
	stepData,
	elements,
	handleShow,
	handleDelete,
	handleEdit,
	handleAccesory,
	showAccesories,
	showItemInventoryNumber
}) => {
	const [propertyColumns, setPropertyColumns] = useState<any[]>([]);
	const [tableElements, setTableElements] = useState<any[]>([]);

	const handleAction = (row: Row<any>) => {
		const action = row.original.action;
		delete row.original.action;

		if (action === 'show') return handleShow(row.original);

		if (action === 'edit') return handleEdit(row.original);

		if (action === 'delete') return handleDelete(row.original);

		if (action === 'accessory') return handleAccesory(row.original);

		if (action === 'show-accessories') {
			row.original.showAccessories = !row.original.showAccessories;

			setPropertyColumns((curr) =>
				curr.map((el) => {
					return {
						...el,
						showAccessories: el.id === row.original.id ? !el.showAccesories : el.showAccesories
					};
				})
			);

			setTableElements((curr) =>
				curr.map((el) => {
					if (el.id !== row.original.id) el.showAccessories = false;

					return el;
				})
			);
		}
	};

	useEffect(() => {
		setPropertyColumns(
			defaultColumns.map((el) => {
				if (!showAccesories) el.enableColumnFilter = false;
				return el;
			})
		);

		if (!showItemInventoryNumber) {
			setPropertyColumns((curr) => {
				return curr.filter((col) => col.accessorKey !== 'inventory_number');
			});
		}
		elements = elements.map((obj) => ({ ...obj, minute_number: stepData ? Number(stepData) : undefined }));

		setTableElements(elements);
	}, [elements]);

	return (
		<div className={'customBodyContainerTable'}>
			<CustomTable
				disableRowClick
				columns={
					showAccesories ? propertyColumns : propertyColumns.filter((col: any) => col.accessorKey !== 'accessories')
				}
				elements={tableElements}
				defaultSortinField={'id'}
				handleAction={(row: Row<unknown>) => handleAction(row)}
				itemsPerPage={elements.length}
			/>

			{!elements.length ? (
				<Typography
					padding={2}
					textAlign="center"
					variant="h6">
					No hay bienes registrados
				</Typography>
			) : null}
		</div>
	);
};
