import { useState, SyntheticEvent, ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion
		disableGutters
		elevation={0}
		square
		{...props}
	/>
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0
	},
	'&:before': {
		display: 'none'
	}
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)'
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1)
	}
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

export interface IAccordionProps {
	title: string | ReactElement | ReactNode;
	children: ReactElement | ReactNode | null;
	isExpanded?: boolean;
}

export interface ICustomAccordionProps {
	accordions: IAccordionProps[];
}
/**
 * @param accordions: Array of objects of type IAccordionProps
 * @returns
 */
export const CustomAccordion = ({ accordions = [] as IAccordionProps[] }: ICustomAccordionProps) => {
	const [expanded, setExpanded] = useState<string | false>(!accordions[0]?.isExpanded ? false : 'panel1');

	const handleChange = (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
		setExpanded(newExpanded ? panel : false);
	};

	return (
		<div>
			{accordions.map((accordion, index) => (
				<Accordion
					key={index}
					expanded={expanded === `panel${index + 1}`}
					onChange={handleChange(`panel${index + 1}`)}>
					<AccordionSummary
						aria-controls={`panel${index + 1}d-content`}
						id={`panel${index + 1}d-header`}>
						<Typography>{accordion.title}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<div>{accordion.children}</div>
					</AccordionDetails>
				</Accordion>
			))}
		</div>
	);
};
