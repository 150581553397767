import { Divider, Grid, Typography } from '@mui/material';
import { IFieldLegalInstrumentPropertie } from 'interfaces/commons/IParameters';
import { GenericFieldDetail } from './FieldDetail';

export const FieldlegalInstrument = ({
	label = 'Instrumento Legal:',
	number = '-',
	year = '-',
	instrumetType = undefined,
	defaultPaddingLeft = '15px'
}: IFieldLegalInstrumentPropertie) => {
	return (
		<Grid
			item
			xs={12}
			sm={12}
			md={12}
			lg={12}
			xl={12}>
			<Grid
				container
				spacing={2}>
				{label !== undefined && label !== null ? (
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}>
						<Typography
							fontSize={16}
							variant="h6"
							sx={{ textAlign: 'start' }}>
							{label}
						</Typography>
					</Grid>
				) : null}

				{instrumetType !== undefined && instrumetType !== null ? (
					<>
						<GenericFieldDetail
							label={'Tipo de Instrumento:'}
							value={instrumetType}
							paddingLeft={defaultPaddingLeft}
						/>
						<Divider className="customDivider" />
					</>
				) : null}

				{number !== undefined && number !== null ? (
					<>
						<GenericFieldDetail
							label={'Número:'}
							value={number}
							paddingLeft={defaultPaddingLeft}
						/>
						<Divider className="customDivider" />
					</>
				) : null}

				{year !== undefined && year !== null ? (
					<>
						<GenericFieldDetail
							label={'Año:'}
							value={year}
							paddingLeft={defaultPaddingLeft}
						/>
						<Divider className="customDivider" />
					</>
				) : null}
			</Grid>
		</Grid>
	);
};
