export interface NomenclatureCode {
	value: string;
	label: string;
	id: number;
}

import { buildDefaultOptions, httpGet } from './httpService';

export const fetchNomenclatureCodes = async (
	code?: string,
	propertyTypeId?: number,
	propertySubtypeId?: number
): Promise<NomenclatureCode[]> => {
	const OPTIONS = buildDefaultOptions();

	try {
		const resp = await httpGet(`account-nomenclature/check`, {
			...OPTIONS,
			params: { code, property_type_id: propertyTypeId, property_sub_type_id: propertySubtypeId }
		});

		const data = resp.data.map((item: any) => {
			const { sub_account, sub_description, sub_group, account, sub_group_description, description, group } = item;

			const code = sub_account || account || sub_group;

			const descriptionLabel = [group, sub_group_description, sub_description, description].filter((val) => val);
			return { value: code, label: `${code} - ${descriptionLabel.join(' | ')}`, id: item.id };
		}) as NomenclatureCode[];

		return data;
	} catch {
		return [];
	}
};
