import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { IAltaStepOne, IAltaStepThree, IAltaStepTwo } from 'interfaces/alta/IAlta';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AltaStepTwoForm } from './forms/stepTwo';
import { AltaStepThreeForm } from './forms/stepThree';
import { useAppContext } from 'hooks/useAppContext';
import { Spinner } from 'components/globals/spinner/Spinner';
import { useRegistrationContext } from 'hooks/useRegistrationContext';
import { AltaSummary } from './forms/summary';
import { CustomDecideDialog } from 'components/globals/dialog/CustomDeleteDialog';
import { cancelPropertyRegistration } from 'services/altaService';
import { PROPERTY_TYPE, REGISTER_TYPES } from 'enums';
import { AltaStepOneForm } from './forms/stepOne';

const LOCAL_STORAGE_STEP_ONE = 'STORAGED_REGISTER_STEP_ONE';
const LOCAL_STORAGE_STEP_TWO = 'STORAGED_REGISTER_STEP_TWO';
const LOCAL_STORAGE_STEP_THREE = 'STORAGED_REGISTER_STEP_THREE';
const STORAGED_REGISTER_ACTIVE_STEP = 'STORAGED_REGISTER_ACTIVE_STEP';

export const AltasOverview = () => {
	const appContext = useAppContext();
	const registrationContext = useRegistrationContext();
	const navigate = useNavigate();
	const [activeStep, setActiveStep] = useState(0);
	const [showBackHomeButton, setShowBackHomeButton] = useState<boolean>(false);
	const { registrationId } = useParams();
	const search = useLocation().search;
	const movementStatusId = new URLSearchParams(search).get('movementStatusId');

	useEffect(() => {
		registrationContext?.changeActiveStep(0);

		if (registrationId) {
			registrationContext.onHandleGetAllData(Number(registrationId));
		}
	}, []);

	useEffect(() => {
		setActiveStep(registrationContext?.activeStep);
	}, [registrationContext?.activeStep]);

	const handleBack = (step: number) => {
		registrationContext?.changeActiveStep(step);
	};

	const handleSubmit = async (
		stepFormNumber: number,
		dataReceived: IAltaStepOne | IAltaStepTwo | IAltaStepThree | undefined
	) => {
		if (stepFormNumber === 0) {
			registrationContext?.onHandleDataStepOne(dataReceived as IAltaStepOne);
			return;
		}

		if (stepFormNumber === 1) {
			registrationContext?.onHandleDataStepTwo(dataReceived as IAltaStepTwo);
			return;
		}

		if (stepFormNumber === 2) {
			registrationContext?.changeActiveStep(3);
			return;
		}

		// Finalizar
		const documentId = registrationContext.dataStepOne?.id as number;

		documentId && !registrationId && (await registrationContext?.onHandleFinalice(documentId));
		setShowBackHomeButton(true);
	};

	const [showCancelModal, toggleCancelModal] = useState(false);

	const handleCancel = async () => {
		const documentId = registrationContext.dataStepOne?.id;

		if (documentId && activeStep < 3 && !registrationId) {
			cancelPropertyRegistration(documentId);
		}

		if (registrationId) navigate('/movimientos/altas', { replace: true });
		else navigate('/home', { replace: true });

		registrationContext?.changeDataStepOne(undefined);
		registrationContext?.changeDataStepTwo(undefined);
		registrationContext?.changeDataStepThree(undefined);
		registrationContext?.changeActiveStep(0);

		deleteFormDataCache();
	};

	const deleteFormDataCache = () => {
		localStorage.removeItem(LOCAL_STORAGE_STEP_ONE);
		localStorage.removeItem(LOCAL_STORAGE_STEP_TWO);
		localStorage.removeItem(LOCAL_STORAGE_STEP_THREE);
		localStorage.removeItem(STORAGED_REGISTER_ACTIVE_STEP);
	};

	const [showFinaliceModal, toggleFinaliceModal] = useState(false);

	const onHandleCancel = () => {
		toggleCancelModal(true);
	};

	useEffect(() => {
		return () => deleteFormDataCache();
	}, []);

	if (!movementStatusId && location.pathname.includes('editar')) {
		return (
			<Navigate
				to="/home"
				replace
			/>
		);
	}

	return (
		<>
			{appContext?.showSpinner ? <Spinner /> : null}

			{showCancelModal && (
				<CustomDecideDialog
					title={'Cancelar Operación'}
					message={'¿Está seguro que desea cancelar la operación?'}
					handleSubmit={() => {
						toggleCancelModal(false);
						handleCancel();
					}}
					handleDecline={() => {
						toggleCancelModal(false);
					}}
				/>
			)}

			{showFinaliceModal && (
				<CustomDecideDialog
					title={'Finalizar Operación'}
					message={'¿Desea guardar los datos?'}
					handleSubmit={() => {
						toggleFinaliceModal(false);
						handleSubmit(activeStep, undefined);
					}}
					handleDecline={() => {
						toggleFinaliceModal(false);
					}}
					labelSubmit={'Confirmar'}
				/>
			)}

			<Box pt={1}>
				{activeStep === 0 && (
					<AltaStepOneForm
						registrationId={registrationId ? Number(registrationId) : undefined}
						movementStatusId={movementStatusId ? Number(movementStatusId) : undefined}
						entity={registrationContext?.dataStepOne as IAltaStepOne}
						registerTypes={REGISTER_TYPES}
						itemTypes={PROPERTY_TYPE}
						handleSubmit={(data: IAltaStepOne) => {
							handleSubmit(activeStep, data);
						}}
						handleCancel={onHandleCancel}
						onChangeItemType={registrationContext.updateSelectedItemType}
					/>
				)}

				{activeStep === 1 && (
					<AltaStepTwoForm
						entity={registrationContext?.dataStepTwo}
						handleSubmit={(data: IAltaStepTwo) => {
							handleSubmit(activeStep, data);
						}}
						handleCancel={onHandleCancel}
						handleBack={() => {
							handleBack(activeStep - 1);
						}}
					/>
				)}

				{activeStep === 2 && (
					<AltaStepThreeForm
						movementStatusId={movementStatusId ? Number(movementStatusId) : undefined}
						entity={registrationContext?.dataStepThree}
						handleSubmit={() => {
							handleSubmit(activeStep, undefined);
						}}
						handleCancel={onHandleCancel}
						handleBack={() => {
							handleBack(activeStep - 1);
						}}
						itemTypes={PROPERTY_TYPE}
					/>
				)}

				{activeStep === 3 && (
					<AltaSummary
						dataStepOne={registrationContext?.dataStepOne ? registrationContext?.dataStepOne : ({} as IAltaStepOne)}
						dataStepTwo={registrationContext?.dataStepTwo ? registrationContext?.dataStepTwo : ({} as IAltaStepTwo)}
						dataStepThree={
							registrationContext?.dataStepThree ? registrationContext?.dataStepThree : ({} as IAltaStepThree)
						}
						handleBack={() => {
							handleBack(activeStep - 1);
						}}
						handleCancel={onHandleCancel}
						handleSubmit={() => {
							toggleFinaliceModal(true);
						}}
						handleBackHome={() => {
							handleCancel();
						}}
						showBackHomeButton={showBackHomeButton}
					/>
				)}
			</Box>
		</>
	);
};
