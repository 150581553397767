import { Dispatch, SetStateAction, useState } from 'react';
import { styleScrollBar } from './styleScrollbar';
import { Typography, Box, Modal, List, ListItem, Button } from '@mui/material';
import { IStep3Accessories, IStep3GenericValues, IStep3PropertyValues } from '../interfaces';

const styleModal = {
	position: 'absolute' as const,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '33%',
	minWidth: '532px',
	maxHeight: '95%',
	overflowY: 'auto',
	bgcolor: '#383838',
	border: '2px solid #000000b9',
	boxShadow: 24,
	color: 'white'
};

interface IModalProps {
	openDetails: boolean;
	setOpenDetails: Dispatch<SetStateAction<boolean>>;
	selectedRow: IStep3PropertyValues;
}

export const ModalProperty = ({ openDetails, setOpenDetails, selectedRow }: IModalProps) => {
	const [open, setOpen] = useState(true);

	const handleClose = () => {
		setOpen(false);
		setOpenDetails(false);
	};

	return (
		<div>
			<Modal
				sx={styleScrollBar}
				keepMounted
				open={openDetails}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<>
					<Box sx={styleModal}>
						<Box sx={{ width: '100%' }}>
							<Typography
								variant="h4"
								sx={{ textAlign: 'center', pb: 2, mb: 3, pt: 1, fontWeight: 500, width: '100%', backgroundColor: 'rgb(51, 82, 100)' }}>
								Detalles del Bien
							</Typography>
						</Box>
						<Box sx={{ pl: 3, pr: 3 }}>
							<Typography variant="h6">Tipo de Bien</Typography>
							<Typography>{selectedRow?.rightType}</Typography>
							<Typography variant="h6">Código de Nomenclatura</Typography>
							<Typography>{selectedRow?.nomenclatureCode}</Typography>
							<Typography variant="h6">Cantidad</Typography>
							<Typography>{selectedRow?.quantity} unidades</Typography>
							<Typography variant="h6">Precio Unitario</Typography>
							<Typography>{Number(selectedRow?.unitPrice).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</Typography>
							<Typography variant="h6">Dirección</Typography>
							<Typography>{selectedRow?.address}</Typography>
							<Typography variant="h6">Título a Nombre de Dependencia Patrimonial</Typography>
							{selectedRow?.titleOfPatrimonialDependency ? <Typography>Si</Typography> : <Typography>No</Typography>}
							<hr style={{ border: '1px solid #bbbbbb' }} />
							<Typography
								variant="h5"
								sx={{ pb: 1, textDecoration: 'underline' }}>
								Información de Catastro
							</Typography>
							<Typography variant="h6">Departamento</Typography>
							<Typography>{selectedRow?.catastroValues?.department}</Typography>
							<Typography variant="h6">Municipio</Typography>
							<Typography>{selectedRow?.catastroValues?.municipality}</Typography>
							<Typography variant="h6">Sección</Typography>
							<Typography>{selectedRow?.catastroValues?.section}</Typography>
							<Typography variant="h6">Chacra</Typography>
							<Typography>{selectedRow?.catastroValues?.ranch}</Typography>
							<Typography variant="h6">Manzana</Typography>
							<Typography>{selectedRow?.catastroValues?.apple}</Typography>
							<Typography variant="h6">Parcela</Typography>
							<Typography>{selectedRow?.catastroValues?.parcel}</Typography>
							<Typography variant="h6">Unidad Funcional</Typography>
							<Typography>{selectedRow?.catastroValues?.functionalUnity}</Typography>
							<Typography variant="h6">Partida Inmobiliaria</Typography>
							<Typography>{selectedRow?.catastroValues?.realStateItem}</Typography>
							<Typography variant="h6">Nro. de Plano</Typography>
							<Typography>{selectedRow?.catastroValues?.planeNumber}</Typography>
							<hr style={{ border: '1px solid #bbbbbb' }} />
							<Typography
								variant="h5"
								sx={{ pb: 1, textDecoration: 'underline' }}>
								Información Registral
							</Typography>
							<Typography variant="h6">Departamento</Typography>
							<Typography>{selectedRow?.registerInformation?.department}</Typography>
							<Typography variant="h6">Nro de Matricula</Typography>
							<Typography>{selectedRow?.registerInformation?.domainNumber}</Typography>
							<Typography variant="h6">Tomo</Typography>
							<Typography>{selectedRow?.registerInformation?.volumeFolder}</Typography>
							<Typography variant="h6">Folio</Typography>
							<Typography>{selectedRow?.registerInformation?.folioFolder}</Typography>
							<Typography variant="h6">Finca</Typography>
							<Typography>{selectedRow?.registerInformation?.estate}</Typography>
							<Typography variant="h6">Descripción del Inmueble</Typography>
							<Typography>{selectedRow?.registerInformation?.propertyDescription}</Typography>
							<Typography variant="h6">Propietario del Inmueble</Typography>
							<Typography>{selectedRow?.registerInformation?.propertyOwner}</Typography>
							<Typography variant="h6">Observaciones</Typography>
							<Typography>{selectedRow?.registerInformation?.observations}</Typography>
							<Typography variant="h6">Título de Propiedad</Typography>
							{selectedRow?.propertyTitle ? <Typography>Si</Typography> : <Typography>No</Typography>}
							{selectedRow?.observationPropertyTitle && (
								<>
									<Typography variant="h6">Descripción</Typography>
									<Typography sx={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>
										{selectedRow?.observationPropertyTitle}
									</Typography>
								</>
							)}
						</Box>
						{selectedRow?.accessories && selectedRow?.accessories?.length > 0 && (
							<Typography
								variant="h6"
								sx={{ ml: 3, mt: 2, textDecoration: 'underline' }}>
								Accesorios
							</Typography>
						)}
						<List sx={{ ml: 3, mr: 3, mb: 1, p: 4 }}>
							{selectedRow?.accessories &&
								selectedRow?.accessories.length > 0 &&
								selectedRow?.accessories.map((accessory: IStep3Accessories, index) => (
									<Box
										key={index}
										sx={{ p: 2, border: '3px solid #929292', borderRadius: '5px', mb: 1 }}>
										Accesorio N° {index + 1}
										<ListItem>
											<span>▪</span>
											<Typography sx={{ fontSize: '13px', ml: '5px' }}>Código Nomenclador= {accessory.nomenclatureCode}</Typography>
										</ListItem>
										<ListItem>
											<span>▪</span>
											<Typography sx={{ fontSize: '13px', ml: '5px' }}>Cantidad= {accessory.quantity}</Typography>
										</ListItem>
										<ListItem>
											<span>▪</span>
											<Typography sx={{ fontSize: '13px', ml: '5px' }}>
												Precio Unitario= {Number(accessory?.unitPrice).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}
											</Typography>
										</ListItem>
										<ListItem sx={{ display: 'flex', alignItems: 'flex-start' }}>
											<span>▪</span>
											<Typography sx={{ fontSize: '13px', ml: '5px' }}>Descripción= {accessory.description}</Typography>
										</ListItem>
									</Box>
								))}
						</List>
						<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', pb: 5 }}>
							<Button
								variant="contained"
								color="primary"
								sx={{ width: '200px' }}
								onClick={handleClose}>
								Salir
							</Button>
						</Box>
					</Box>
				</>
			</Modal>
		</div>
	);
};
