import * as React from 'react';
import { Badge, Box } from '@mui/material';
import { isAfter, isBefore, isValid } from 'date-fns';
import { AppDatePicker } from '../AppDatePicker/AppDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

export type DateRange = [Date | null, Date | null];

interface AppDatePickerProps {
	label?: string;
	value?: DateRange;
	onChange?(date: DateRange): void;
	disableFuture?: boolean;
	size?: 'medium' | 'small';
}

export function getDateFromStr(date: string) {
	const [y, m, d] = date.split('-');
	if (!y || !m || !d) return new Date();
	return new Date(Number(y), Number(m) - 1, Number(d));
}

export const AppDateRangePicker = ({ value, onChange, disableFuture, size }: AppDatePickerProps) => {
	const [start, setStart] = React.useState<Date | null>(null);
	const [end, setEnd] = React.useState<Date | null>(null);

	React.useEffect(() => {
		onChange && onChange([start, end]);
	}, [start, end]);

	React.useEffect(() => {
		if (value) {
			const [start, end] = value;
			setStart(start);
			setEnd(end);
		}

		() => {
			setStart(null);
			setEnd(null);
		};
	}, [value]);

	React.useEffect(() => {
		if (!start) setEnd(null);
	}, [start]);

	return (
		<Box sx={{ display: 'flex', gap: 1 }}>
			<AppDatePicker
				size={size}
				value={start}
				onChange={(val) => {
					setStart(val);
					setEnd(null);
				}}
				disableFuture={disableFuture}
				label="Desde"
			/>
			<AppDatePicker
				key={String(start)}
				disabled={!start}
				size={size}
				value={end}
				onChange={setEnd}
				disableFuture={disableFuture}
				label="Hasta"
				renderDay={(day, _value, DayComponentProps) => {
					return (
						<PickersDay
							{...DayComponentProps}
							disabled={(!!start && isBefore(day, start)) || isAfter(day, new Date())}
						/>
					);
				}}
			/>
		</Box>
	);
};
