import { ColumnDef } from '@tanstack/react-table';
import { IconButton, Switch, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';

export const defaultColumns: ColumnDef<any, any>[] = [
	{
		accessorKey: 'fullName',
		enableSorting: true,
		header: () => <span>Nombre y Apellido</span>,
		cell: ({ getValue }) => <p style={{ textAlign: 'left', paddingLeft: '10px' }}>{getValue() as string}</p>
	},
	{
		accessorKey: 'email',
		header: () => <span>Correo Electrónico</span>,
		cell: ({ getValue }) => (
			<>
				<a href={`mailto: ${getValue() as string}`}>
					<p style={{ textAlign: 'left', paddingLeft: '10px' }}>{getValue() as string}</p>
				</a>
			</>
		)
	},
	{
		accessorKey: 'role.name',
		header: () => <span>Perfil</span>,
		cell: ({ getValue }) => <p style={{ textAlign: 'left', paddingLeft: '10px' }}>{getValue() as string}</p>
	},
	{
		accessorKey: 'patrimonial_dependence',
		enableColumnFilter: true,
		filterFn(col, _, value) {
			const colData = col.original?.patrimonial_dependence?.patrimonial_dependence;
			if (!value.length || !colData) return true;
			return colData.toLowerCase().includes(value.toLowerCase());
		},
		header: () => <span>Dependencia Patrimonial</span>,
		cell: ({ row }) => (
			<p style={{ textAlign: 'left', paddingLeft: '10px' }}>
				{row.original.patrimonial_dependence?.patrimonial_dependence}
			</p>
		)
	},
	{
		accessorKey: 'is_active',
		enableSorting: false,
		enableGlobalFilter: false,
		enableColumnFilter: false,
		header: () => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>Habilitar</span>
			</div>
		),
		cell: ({ getValue, row }) => (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'nowrap',
					width: '100%',
					justifyContent: 'space-around'
				}}>
				<Tooltip
					placement="right-start"
					arrow
					title={(getValue() as boolean) ? 'Deshabilitar Usuario' : 'Habilitar Usuario'}>
					<Switch
						checked={getValue() as boolean}
						onClick={() => {
							row.original.action = 'changeStatus';
						}}
					/>
				</Tooltip>
			</div>
		)
	},
	{
		accessorKey: 'actions',
		enableSorting: false,
		enableGlobalFilter: false,
		enableColumnFilter: false,
		header: () => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>Acciones</span>
			</div>
		),
		cell: ({ row, table }) => (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'nowrap',
					width: '100%',
					justifyContent: 'space-around'
				}}>
				<IconButton
					onClick={() => {
						row.original.action = 'edit';
					}}
					size="small"
					sx={{
						transition: 'all 200ms ease',
						'&:hover': {
							transform: 'scale(1.3)',
							transition: 'all 200ms ease'
						}
					}}>
					<Tooltip
						placement="right-start"
						arrow
						title="Editar Usuario">
						<EditIcon />
					</Tooltip>
				</IconButton>

				<IconButton
					onClick={() => {
						row.original.action = 'refresh';
					}}
					size="small"
					sx={{
						transition: 'all 200ms ease',
						'&:hover': {
							transform: 'scale(1.3)',
							transition: 'all 200ms ease'
						}
					}}>
					<Tooltip
						placement="right-start"
						arrow
						title="Resetear Contraseña">
						<RefreshIcon />
					</Tooltip>
				</IconButton>
			</div>
		)
	}
];
