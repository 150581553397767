import React, { FC, useState, Dispatch, SetStateAction } from 'react';
import style from './customDialog.module.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { DialogType, Transition } from './utils';

export interface IFormDialogProps {
	show: boolean;
	type?: DialogType;
	title?: string;
	content: React.ReactNode;
	actions: React.ReactNode;
	buttonPosition?: 'start' | 'center' | 'end';
	handleClose: Dispatch<SetStateAction<boolean>>;
	maxWidth?: 'sm' | 'md' | 'lg';
	enableFullscreen?: boolean;
}

export const FormDialog: FC<IFormDialogProps> = ({
	show = false,
	type = DialogType.PRIMARY,
	title = 'Título no definido',
	content,
	actions,
	buttonPosition = 'center',
	handleClose,
	maxWidth,
	enableFullscreen
}) => {
	const [isFullScreen, setFullScreen] = useState(false);

	return (
		<Dialog
			maxWidth={maxWidth ?? 'sm'}
			fullScreen={isFullScreen}
			open={show}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => handleClose(false)}
			aria-describedby="alert">
			<DialogTitle sx={{ backgroundColor: type, color: 'white' }}>
				{title}
				<Box
					sx={{
						position: 'absolute',
						right: 8,
						top: 8
					}}>
					{enableFullscreen ? (
						<Tooltip title={isFullScreen ? 'Quitar pantalla completa' : 'Ver en pantalla completa'}>
							<IconButton
								sx={{
									color: 'white'
								}}
								aria-label="close"
								onClick={() => {
									setFullScreen(!isFullScreen);
								}}>
								{isFullScreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
							</IconButton>
						</Tooltip>
					) : null}

					{handleClose ? (
						<Tooltip title={'Cerrar'}>
							<IconButton
								sx={{
									color: 'white'
								}}
								aria-label="close"
								onClick={() => {
									handleClose(false);
								}}>
								<CloseIcon />
							</IconButton>
						</Tooltip>
					) : null}
				</Box>
			</DialogTitle>
			<DialogContent className={style.myDialog}>{content}</DialogContent>
			<DialogActions sx={{ justifyContent: buttonPosition }}>{actions}</DialogActions>
		</Dialog>
	);
};
