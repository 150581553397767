import { useState, useEffect } from 'react';
import style from './genericEditItem.module.css';

// Hooks
import { useMovContext } from 'hooks/useMovContext';

//Service
import { BounceLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import lodash from 'lodash';

// Custom components
import { NomenclatureCode } from 'components/globals/nomenclatureCode/nomenClatureCode';
import { QuantityEditInput } from '../../quantityInput/QuantityEditInput';
import { UnitPriceInput } from '../../unitPriceInput/UnitPriceInput';
import { DescriptionInput } from '../../generico/descriptionInput/DescriptionInput';

// Interfaces
import { IEditItemModalProps } from '../EditItemModal';

//Material UI
import { Box, Button, Modal, FormControlLabel, Switch } from '@mui/material';
import { MovementStatusType } from 'enums';

const styleModal = {
	position: 'absolute' as const,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '50%',
	minWidth: '532px',
	bgcolor: '#e4e4e4',
	border: '2px solid #000000b9',
	boxShadow: 24,
	padding: '50px'
};

export const EditItemGen = ({ openModal, setOpenModal, rightList, setRightList, selectedRow, setSelectedRow }: IEditItemModalProps) => {
	const [open, setOpen] = useState(true);
	const [nomenclatureCode, setNomenclatureCode] = useState(selectedRow?.nomenclatureCode || '');
	const [nomenclatureCodeId, setNomenclatureCodeId] = useState(selectedRow?.nomenclatureCodeId || 0);
	const [description, setDescription] = useState(selectedRow?.description || '');
	const [quantity, setQuantity] = useState(String(selectedRow?.quantity) || '');
	const [unitPrice, setUnitPrice] = useState(selectedRow?.unitPrice || '');
	const [inventoriable, setInventoriable] = useState(selectedRow?.inventoriable || false);
	const [disabled, setDisabled] = useState(true);
	const [submited, setSubmited] = useState(false);
	const [loading, setLoading] = useState(false);
	const [opacity, setOpacity] = useState(false);
	const movContext = useMovContext();

	const rightType = 'Genérico';

	/* Call handleDisabled function when one of the dependencies change. */
	useEffect(() => {
		handleDisabled();
	}, [nomenclatureCode, description, quantity, unitPrice, inventoriable]);

	/**
	 * If any of the four input fields are empty, the button is disabled
	 * @returns void
	 */
	const handleDisabled = (): boolean => {
		setDisabled(true);
		if (nomenclatureCode.trim().length === 0) {
			return true;
		}
		if (description.trim().length === 0 || description.trim().length >= 254) {
			return true;
		}
		if (quantity.trim().length === 0) {
			return true;
		}
		if (unitPrice.trim().length === 0) {
			return true;
		}
		setDisabled(false);
		return false;
	};

	/**
	 * If the nomenclatureCode, description, quantity, and unitPrice are not empty, then add a new item to
	 * the rightList
	 *
	 * @returns void
	 */
	const handleSubmit = () => {
		if (nomenclatureCode.trim().length === 0) {
			return;
		}
		if (description.trim().length === 0) {
			return;
		}
		if (quantity.trim().length === 0) {
			return;
		}
		if (unitPrice.trim().length === 0) {
			return;
		}

		const list = [...rightList];
		const item = list.find((item) => item.id === selectedRow?.id);
		if (item) {
			item.nomenclatureCode = nomenclatureCode;
			item.nomenclatureCodeId = nomenclatureCodeId;
			item.description = description;
			item.quantity = quantity;
			item.unitPrice = unitPrice;
			item.inventoriable = inventoriable;
			item.operation = 'update';
		}
		const itemForContext = movContext?.itemsFromTable.find((item) => item.temporary_id === selectedRow?.id);
		if (itemForContext) {
			const listOfItemsContext = movContext?.itemsFromTable.filter((item) => item.id !== selectedRow?.idInDatabase);
			if (listOfItemsContext) {
				const newItem = { ...item };
				newItem.id = selectedRow?.idInDatabase;
				newItem.temporary_id = selectedRow?.id;
				listOfItemsContext.push(newItem);
				movContext?.setItemsFromTable(listOfItemsContext);
			}
		} else {
			const newItem = { ...item };
			newItem.id = Number(selectedRow?.idInDatabase);
			newItem.temporary_id = selectedRow?.id;
			movContext?.setItemsFromTable((prev) => [...prev, newItem]);
		}
		setRightList([...list]);
		handleLoading();
		setSubmited(true);
	};

	const debouncedSubmit = lodash.debounce(handleSubmit, 1000);

	const handleClose = () => {
		setOpen(false);
		setOpenModal(false);
	};

	const handleLoading = () => {
		setLoading(true);
		setTimeout(() => {
			setOpacity(true);
		}, 300);
		setTimeout(() => {
			handleClose();
			toast('Bien editado correctamente', { type: 'success', autoClose: 2000 });
		}, 2000);
	};

	return (
		<div>
			<Modal
				keepMounted
				open={openModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<Box sx={styleModal}>
					<form style={{ maxWidth: '1000px', minHeight: '370px' }}>
						<div>
							<FormControlLabel
								className={style.switchLabel}
								control={
									<Switch
										disabled={movContext?.editMovement?.movement_status_id !== MovementStatusType.PENDIENTE}
										checked={inventoriable}
										name="inventariable"
										onClick={() => setInventoriable(!inventoriable)}
									/>
								}
								label="Inventariable"
								sx={{ width: '100%', mt: '-40px', ml: '0px' }}
							/>
						</div>
						<div>
							<Box sx={{ mt: 1, mb: 5, display: 'flex', flexWrap: 'wrap' }}>
								<NomenclatureCode
									value={nomenclatureCode}
									submited={submited}
									setNomenclatureCode={setNomenclatureCode}
									setNomenclatureCodeId={setNomenclatureCodeId}
								/>
								<div style={{ display: 'flex', marginLeft: '15px' }}>
									<QuantityEditInput
										value={quantity}
										submited={submited}
										setQuantity={setQuantity}
									/>
									<div style={{ marginLeft: '50px' }}>
										<UnitPriceInput
											value={unitPrice}
											submited={submited}
											setUnitPrice={setUnitPrice}
										/>
									</div>
								</div>
							</Box>
						</div>
						<div>
							<DescriptionInput
								value={description}
								setSubmited={setSubmited}
								submited={submited}
								setDescription={setDescription}
								required={true}
							/>
						</div>
						<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
							<Button
								disabled={disabled}
								sx={{ mt: 4 }}
								variant="contained"
								onClick={debouncedSubmit}>
								Guardar cambios
							</Button>
							<Button
								sx={{ mt: 4, ml: 2 }}
								variant="contained"
								color="secondary"
								onClick={handleClose}>
								Cancelar
							</Button>
						</div>
					</form>
					<div>
						<hr
							className="solid"
							style={{ borderTop: '1px solid #5c5c5c71' }}
						/>
					</div>
					{loading && (
						<Box
							sx={{
								zIndex: '9999',
								opacity: opacity ? '1' : '0',
								position: 'absolute',
								backdropFilter: 'blur(2px)',
								height: '100%',
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								transition: 'all 700ms ease-in-out',
								top: '0',
								left: '0'
							}}>
							<BounceLoader
								size={100}
								speedMultiplier={1.5}
							/>
						</Box>
					)}
				</Box>
			</Modal>
		</div>
	);
};
