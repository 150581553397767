export enum ItemType {
	Propiedad = 'Propiedad',
	Generico = 'Generico',
	Automotor = 'Automotor',
	Motocicleta = 'Motocicleta',
	Embarcación = 'Embarcación',
	Aeronave = 'Aeronave',
	Máquinas = 'Máquinas'
}

enum VEHICLE_TYPE {
	AUTOMOTIVE = 'Automotores',
	MOTOVEHICLES = 'Motovehículos',
	VESSELS = 'Embarcaciones',
	AIRCRAFT = 'Aeronaves',
	OTHERS = 'Otros'
}

enum VEHICLE_TYPE_ID {
	AUTOMOTIVE = 1,
	MOTOVEHICLES = 2,
	VESSELS = 3,
	AIRCRAFT = 4,
	OTHERS = 5
}

enum REGISTER_TYPES_ID {
	ACQUISITION = 1,
	TRANSFERENCE = 2,
	DONATION = 3
}

const VEHICLES_TYPES = [
	{ label: VEHICLE_TYPE.AUTOMOTIVE, id: 1 },
	{ label: VEHICLE_TYPE.MOTOVEHICLES, id: 2 },
	{ label: VEHICLE_TYPE.VESSELS, id: 3 },
	{ label: VEHICLE_TYPE.AIRCRAFT, id: 4 },
	{ label: VEHICLE_TYPE.OTHERS, id: 5 }
];

enum PROPERTY_TYPE_IDS {
	GENERIC = 1,
	VEHICLE = 2,
	INMUEBLE = 3
}

const REGISTER_TYPES = [
	{ label: 'Adquisición', id: 1 },
	{ label: 'Transferencia', id: 2 },
	{ label: 'Donación', id: 3 }
];

const PROPERTY_TYPE = [
	{ label: 'Genérico', id: PROPERTY_TYPE_IDS.GENERIC },
	{ label: 'Vehículo', id: PROPERTY_TYPE_IDS.VEHICLE },
	{ label: 'Inmueble', id: PROPERTY_TYPE_IDS.INMUEBLE }
];

export {
	REGISTER_TYPES,
	PROPERTY_TYPE,
	VEHICLES_TYPES,
	VEHICLE_TYPE,
	PROPERTY_TYPE_IDS,
	REGISTER_TYPES_ID,
	VEHICLE_TYPE_ID
};
