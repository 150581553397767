import { ReactNode } from 'react';
import style from './shr.module.css';
import { Box } from '@mui/material';

interface ISubHeaderRoutesProps {
	title?: string;
	strongTitle?: string;
	background?: string;
	backgroundAfter?: string;
	children: ReactNode;
}

export const SubHeaderRoutes = ({ title, strongTitle, children, background, backgroundAfter }: ISubHeaderRoutesProps) => {
	return (
		<Box
			className={style.alta}
			sx={{ mt: 0 }}>
			<Box
				className={style.title}
				sx={{ background: background, '&:after': { background: backgroundAfter } }}>
				{title && <p className={style.word}>{title}</p>}
				{strongTitle && <p className={style.strongWord}>{strongTitle}</p>}
			</Box>
			{children}
		</Box>
	);
};
