export enum MovementType {
	ALTA = 1,
	BAJA = 2
}

export enum MovementStatusType {
	PENDIENTE = 1,
	APROBADO = 2,
	ENVIADO = 3,
	OBSERVADO = 4,
	CONSOLIDADO = 5,
	RECTIFICADO = 6
}

export type MovementStatusTypeByID = {
	1: 'Pendiente';
	2: 'Aprobado';
	3: 'Enviado';
	4: 'Observado';
	5: 'Consolidado';
	6: 'Rectificado';
};

export const MOVEMENT_TYPES: MovementStatusTypeByID = {
	1: 'Pendiente',
	2: 'Aprobado',
	3: 'Enviado',
	4: 'Observado',
	5: 'Consolidado',
	6: 'Rectificado'
};
