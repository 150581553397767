/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ButtonConfirm, ButtonDecline } from 'components/globals/customsBtn/customBtn';
import { DEFAULT_NO_ERROR, requiredValidator } from 'helpers/Validators/Validators';
import { FormGenericMultiline } from 'components/globals/inputs/FormGenericMultiline';
import { IPresentationSheetMovementAudit } from 'interfaces/presentationSheet/IPresentationSheet';
import { MovementsStatus } from 'enums';
import { ISelectOption } from 'interfaces/commons/IParameters';
import { FormGenericSelect } from 'components/globals/inputs/FormGenericSelect';

export interface IOverviewMovementStatusForm {
	entity: IPresentationSheetMovementAudit | undefined;
	handleSubmit: (data: IPresentationSheetMovementAudit | undefined) => void;
	handleCancel: () => void;
	movementsStatus: ISelectOption[] | [];
}

export interface IErrorsForm {
	observation?: string | undefined;
	status?: string | undefined;
}

export const LABELS_PROFILE_FORM = {
	status: 'Estado',
	observation: 'Observación'
};

export const OverviewMovementStatusForm = ({
	entity,
	handleSubmit = () => {},
	handleCancel = () => {},
	movementsStatus = []
}: IOverviewMovementStatusForm) => {
	const [name, setName] = useState<string | undefined>(entity?.description);
	const [observation, setObservation] = useState<string | undefined>(undefined);
	const [errors, setErrors] = useState<IErrorsForm>({} as IErrorsForm);
	const [status, setStatus] = useState<ISelectOption | undefined>();
	const [statusList, setStatusList] = useState<ISelectOption[] | []>(movementsStatus);

	const validateForm = (): boolean => {
		let newErrors: IErrorsForm = {} as IErrorsForm;
		setErrors(newErrors);

		let statusError = requiredValidator({ value: status?.label, label: LABELS_PROFILE_FORM.status });
		if (statusError !== DEFAULT_NO_ERROR) {
			newErrors.status = statusError;
		}

		if (status && (status.id === MovementsStatus.OBSERVADO_PROVISORIO || status.id === MovementsStatus.OBSERVADO)) {
			let observationError = requiredValidator({ value: observation, label: LABELS_PROFILE_FORM.observation });
			if (observationError !== DEFAULT_NO_ERROR) {
				newErrors.observation = observationError;
			}
		}

		if (Object.entries(newErrors).length !== 0) {
			setErrors(newErrors);
			return false;
		}

		return true;
	};

	const getParsedData = (entity: IPresentationSheetMovementAudit | undefined): IPresentationSheetMovementAudit | undefined => {
		if (!entity) {
			return;
		}
		return {
			...entity,
			observation: observation ? observation.trim() : null,
			status_id: status?.id !== undefined ? status.id : MovementsStatus.ENVIADO
		};
	};

	const onHandleSubmit = (): void => {
		if (!validateForm()) {
			return;
		}
		handleSubmit(getParsedData(entity));
	};

	useEffect(() => {
		if (status && status.id !== MovementsStatus.OBSERVADO && status.id !== MovementsStatus.OBSERVADO_PROVISORIO) {
			setObservation('');
		}
	}, [status]);

	useEffect(() => {
		let finded = statusList.find((s) => {
			return s.id === entity?.status_id;
		});
		if (finded) {
			setStatus(finded);
		}
		if (entity) {
			setObservation(entity.observation ? entity.observation : '');
		}
	}, [entity]);

	return (
		<div className="borderedBox">
			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '30px', rowGap: '30px', paddingTop: '30px' }}>
				<FormGenericSelect
					id={'status'}
					value={status}
					label={'Seleccione un '.concat(LABELS_PROFILE_FORM.status)}
					handleChange={setStatus}
					options={statusList}
					error={errors.status}
				/>

				{status && (status.id === MovementsStatus.OBSERVADO_PROVISORIO || status.id === MovementsStatus.OBSERVADO) ? (
					<FormGenericMultiline
						value={observation}
						label={LABELS_PROFILE_FORM.observation}
						name="observation"
						id="observation"
						rows={10}
						handleChange={setObservation}
						error={errors.observation}
					/>
				) : null}
			</Box>

			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
				<ButtonDecline
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => {
						handleCancel();
					}}>
					Cancelar
				</ButtonDecline>
				<ButtonConfirm
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => onHandleSubmit()}>
					Confirmar
				</ButtonConfirm>
			</Box>
		</div>
	);
};
