// Enums
import { ApiPaths } from 'enums/apiPaths';
import { IPaginationRequest } from 'interfaces/commons/IParameters';
import { IOrganizationalFilters, IOrganizationalUnit, IOrganizationalUnitFilters } from 'interfaces/organism/IOrganism';

import {
	handleHttpError,
	buildDefaultOptions,
	httpGet,
	buildURLQuery,
	httpPost,
	httpPut,
	httpDelete,
	httpPatch
} from './httpService';

const URL_BASE = `${ApiPaths.ORGANISMS}`;
const URL_SEARCH = `${URL_BASE}/search`;
const URL_WITH_ID = (id: number) => {
	return URL_BASE + `/${id}`;
};

export const tryGetAllOrganisms = async (filters?: IOrganizationalFilters, pagination?: IPaginationRequest) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let parameters = {};
		if (filters) {
			parameters = { ...filters };
		}
		if (pagination) {
			parameters = { ...parameters, ...pagination };
		}
		const queryString = buildURLQuery(parameters);
		let URI;
		if (filters) {
			URI = `${URL_SEARCH}?${queryString}`;
		} else {
			URI = `${URL_BASE}?${queryString}`;
		}
		let response = await httpGet(URI, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryGetAllOrganizationalUnitsBySearchPaginated = async (
	filters?: IOrganizationalUnitFilters,
	pagination?: IPaginationRequest
) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let parameters = {};
		if (filters) {
			parameters = { ...filters };
		}
		if (pagination) {
			parameters = { ...parameters, ...pagination };
		}
		const queryString = buildURLQuery(parameters);
		const URI = `${URL_BASE}?${queryString}`;

		let response = await httpGet(URI, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryGetOrganizationalUnitByID = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpGet(URL_WITH_ID(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryCreateOrUpdateOrganizationalUnit = async (entity: IOrganizationalUnit) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response;
		if (!entity.id) {
			response = await httpPost(URL_BASE, entity, OPTIONS);
		} else {
			let id = entity.id;
			delete entity.id;
			delete entity.jurisdictions_id;
			response = await httpPatch(URL_WITH_ID(id), entity, OPTIONS);
		}
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryDeleteOrganizationalUnit = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpDelete(URL_WITH_ID(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};
