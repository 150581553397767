import { FC, useState, useEffect } from 'react';
import { Grid, Divider, Typography, Box } from '@mui/material';
import { useAppContext } from 'hooks/useAppContext';
import { ISelectOption } from 'interfaces/commons/IParameters';
import { FormGenericSelect } from 'components/globals/inputs/FormGenericSelect';
import { requiredValidator, DEFAULT_NO_ERROR, requiredListValidator } from 'helpers/Validators/Validators';
import { FechaFactRemito, IFechaComprobante } from '../FechaFactRemito';
import { Attachment } from 'interfaces/file/IFile';
import { REGISTER_TYPES_ID } from 'enums';
import { useAuth } from 'hooks/useAuth';

export const LABELS_TRANSFER_FORM = {
	titulo: 'Datos de la Transferencia',
	patrimonialDependence: 'Seleccione una Dependencia Patrimonial',
	date: 'Fecha de Transferencia *'
};

export interface ITransferenceValues {
	id?: number;
	patrimonialDependence?: number;
	date?: string;
	hasErrors?: boolean;
	errors?: any;
	observations?: string | undefined;
	attached?: File | undefined;
	dateIntrument?: any | undefined;
}

export interface IOverviewTransferenceForm {
	entity: ITransferenceValues;
	handleChange: (data: ITransferenceValues) => void;
	attachments?: Attachment[];
	documentId?: number;
	onDeleteFiles?: CallableFunction;
	onTimelessError?: CallableFunction;
}

export interface IErrorsTransferenceForm {
	patrimonialDependence?: string;
	date?: string;
}

export const Transferencia: FC<IOverviewTransferenceForm> = ({
	entity,
	handleChange,
	attachments,
	documentId,
	onDeleteFiles,
	onTimelessError
}: IOverviewTransferenceForm) => {
	// Parámetros generales
	const [patrimonialDependences, setPatrimonialDependences] = useState<any[] | []>([]);
	const [errors, setErrors] = useState<IErrorsTransferenceForm>({} as IErrorsTransferenceForm);
	const [dataFormHasChanged, setDataFormHasChanged] = useState<number>(0);
	const appContext = useAppContext();
	const [transferenceDateDetails, setTransferenceDateDetails] = useState<IFechaComprobante | undefined>();
	const [patrimonialDependence, setPatrimonialDependence] = useState<ISelectOption | undefined>();
	const { user } = useAuth();
	const actualPatrimonialDependence = user?.patrimonial_dependence_id;
	useEffect(() => {
		if (entity) {
			if (patrimonialDependences.length) {
				const finded = patrimonialDependences.find((rt) => {
					return rt.id === entity?.patrimonialDependence;
				});

				if (finded && !patrimonialDependence) {
					setPatrimonialDependence({ id: finded.id, label: finded.patrimonial_dependence } as ISelectOption);
					setDataFormHasChanged((v) => v + 1);
				}
			}

			setTransferenceDateDetails({
				date: entity.date,
				dateIntrument: entity.dateIntrument,
				observations: entity.observations,
				attached: entity.attached ?? undefined,
				hasErrors: entity.hasErrors ?? false
			});

			if (dataFormHasChanged === 0) setDataFormHasChanged((v) => v + 1);
		}
	}, [entity, patrimonialDependences]);

	useEffect(() => {
		appContext?.onHandleTryGetAllPatrimonialDependences();
	}, []);

	useEffect(() => {
		if (appContext?.patrimonialDependences.length) {
			setPatrimonialDependences(appContext?.patrimonialDependences);
		}
	}, [appContext?.patrimonialDependences]);

	const validateForm = (): boolean => {
		if (dataFormHasChanged <= 1) return true;

		const newErrors: IErrorsTransferenceForm = {} as IErrorsTransferenceForm;
		setErrors(newErrors);

		const patrimonialDependenceError = requiredListValidator({
			value: patrimonialDependence
		});

		if (patrimonialDependenceError !== DEFAULT_NO_ERROR) {
			newErrors.patrimonialDependence = patrimonialDependenceError;
		}

		const dateError = requiredValidator({ value: transferenceDateDetails?.date, label: LABELS_TRANSFER_FORM.date });
		if (dateError !== DEFAULT_NO_ERROR) {
			newErrors.date = dateError;
		}

		setErrors(newErrors);
		if (Object.entries(newErrors).length !== 0) {
			return false;
		}

		return true;
	};

	const getParsedData = (hasErrors = false): ITransferenceValues => {
		return {
			patrimonialDependence: patrimonialDependence?.id,
			date: transferenceDateDetails?.date,
			hasErrors: hasErrors,
			dateIntrument: transferenceDateDetails?.dateIntrument,
			attached: transferenceDateDetails?.attached,
			observations: transferenceDateDetails?.observations,
			errors: hasErrors ? errors : ({} as IErrorsTransferenceForm)
		};
	};

	useEffect(() => {
		if (dataFormHasChanged) {
			const hasErrors = !validateForm();
			handleChange(getParsedData(hasErrors));
		}
	}, [dataFormHasChanged]);

	return (
		<>
			<Grid
				container
				spacing={2}
				className={'mt-2'}>
				<Typography
					fontSize={'1rem'}
					variant="subtitle2"
					style={{ paddingLeft: '35px' }}>
					{LABELS_TRANSFER_FORM.titulo}
				</Typography>
				<Divider className="customDividerLight" />

				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}>
					<FormGenericSelect
						value={patrimonialDependence}
						label={LABELS_TRANSFER_FORM.patrimonialDependence}
						id="patrimonialDependence"
						options={patrimonialDependences
							.filter((pd) => pd.id !== actualPatrimonialDependence)
							.map((pd) => {
								return { id: pd.id, label: pd.patrimonial_dependence } as ISelectOption;
							})}
						handleChange={(value: any) => {
							setPatrimonialDependence(value);
							setDataFormHasChanged(dataFormHasChanged + 1);
						}}
						error={errors?.patrimonialDependence}
						required={true}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				spacing={2}>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}>
					<Box mt={2}>
						<FechaFactRemito
							registerTypeId={REGISTER_TYPES_ID.TRANSFERENCE}
							onTimelessError={onTimelessError}
							label="Fecha de Transferencia *"
							onDeleteFiles={onDeleteFiles}
							attachments={attachments?.filter((el) => el.file_type_id === 2 || el.file_type_id === 3)}
							entity_id={documentId}
							date={transferenceDateDetails?.date}
							observations={transferenceDateDetails?.observations}
							attached={transferenceDateDetails?.attached}
							dateIntrument={transferenceDateDetails?.dateIntrument}
							handleChange={(data: IFechaComprobante) => {
								setTransferenceDateDetails({
									date: data.date,
									observations: data.observations,
									attached: data.attached,
									dateIntrument: data.dateIntrument
								} as IFechaComprobante);
								setErrors({ ...data.errors });
								setDataFormHasChanged((v) => v + 1);
							}}
						/>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};
