// Axios Instance
import apiInstance from 'interceptors/axiosInterceptor';

//Interfaces
import { IToken } from 'interfaces/AuthContextData';

// Enums
import { ApiPaths } from 'enums/apiPaths';

/**
 * This function takes in an email and password, sends them to the server, and returns the response
 * data.
 * @param {string} email - string, password: string
 * @param {string} password - string
 * @returns The response from the server.
 */
export const loginService = async (email: string, password: string) => {
	const response = await apiInstance
		.post(`/${ApiPaths.LOGIN}`, {
			email: email,
			password: password
		})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
	return response;
};

/**
 * This function will return the user data from the API if the token is valid, otherwise it will return
 * undefined.
 * @param {IToken} token - IToken
 * @returns The response.data is being returned.
 */
export const getUserDataService = async (token: IToken) => {
	if (!token.access_token === undefined) return;
	if (token.access_token) {
		const response = await apiInstance.get(`/${ApiPaths.ME}`, {
			headers: { Authorization: `Bearer ${token.access_token}` }
		});
		const data = await response.data;

		return data;
	}
};

export const sendUserResetPasswordEmail = async (email: string) => {
	const response = await apiInstance.post(`/${ApiPaths.RESET_PASSWORD}`, { email });
	const data = await response.data;
	return data;
};

export const sendRecoveryToken = async (token: string) => {
	const response = await apiInstance.post(`/${ApiPaths.RECOVERY_TOKEN}`, { recovery_password_token: token });
	const data = await response.data;
	return data;
};

export const confirmPassword = async (id: number, password: string, confirm_password: string, token: string) => {
	const response = await apiInstance.patch(`/${ApiPaths.CONFIRM_PASSWORD}/${id}`, {
		password,
		confirm_password,
		token
	});
	const data = await response.data;
	return data;
};
