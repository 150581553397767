import { Divider, Grid } from '@mui/material';
import { FieldExpedientDetails } from './../../globals/fields/FieldExpedientDetails';
import { FieldlegalInstrument } from './../../globals/fields/FieldLegalInstrument';
import { IBajaStepOne } from './../../../interfaces/baja/IBaja';
import { FieldDate } from './../../globals/fields/FieldDate';
import { LABELS_STEP_ONE_FORM } from '../forms/stepOne';
import { DeregistrationTypes } from 'enums';

interface ISummaryStepOne {
	entity: IBajaStepOne;
}

export const SummaryStepOne = ({ entity }: ISummaryStepOne) => {
	const canShowTaxId = entity?.deregistration_type_id?.some((id) => {
		return (
			id === DeregistrationTypes.DESUSO || id === DeregistrationTypes.REZAGO || id === DeregistrationTypes.DONACION
		);
	});

	return (
		<Grid
			container
			spacing={1}>
			<FieldExpedientDetails
				label={LABELS_STEP_ONE_FORM.procedure}
				agencyCode={entity?.procedure?.agency_code || '-'}
				number={entity?.procedure?.number || '-'}
				year={entity?.procedure?.year || '-'}
			/>

			<FieldlegalInstrument
				label={LABELS_STEP_ONE_FORM.instrument}
				number={entity?.instrument?.instrument_number || '-'}
				year={entity?.instrument?.instrument_year || '-'}
				instrumetType={entity?.instrument?.instrument_type}
			/>

			<FieldDate
				label={LABELS_STEP_ONE_FORM.date_delivery_certificate}
				value={entity?.date_delivery_certificate || '-'}
			/>
			<Divider className="customDivider" />

			{canShowTaxId && entity.single_tax_id ? (
				<>
					<FieldDate
						label="CUIT/CUIL:"
						value={entity?.single_tax_id}
					/>
					<Divider className="customDivider" />
				</>
			) : null}
		</Grid>
	);
};
