import { useMemo, FC } from 'react';
import { Row } from '@tanstack/react-table';
import { defaultColumns } from './PresentationSheetAuditColumnDef';
import {
	IPresentationSheetMovementAudit,
	IPresentationSheetAuditTableProps
} from 'interfaces/presentationSheet/IPresentationSheet';
import { usePresentationSheetContext } from 'hooks/usePresentationSheetContext';
import { CustomTable } from 'components/globals/table/customTable';

export const PresentationSheetAuditTable: FC<IPresentationSheetAuditTableProps> = ({
	elements = [],
	handleToggleStatus = (entity: IPresentationSheetMovementAudit) => void 0,
	handleAttachments = (entity: IPresentationSheetMovementAudit) => void 0,
	handleHistory = (entity: IPresentationSheetMovementAudit) => void 0
}) => {
	const presentationsContext = usePresentationSheetContext();
	const columns = useMemo<typeof defaultColumns>(() => [...defaultColumns], [defaultColumns]);

	const handleAction = (row: Row<any>) => {
		switch (row.original?.action) {
			case 'toggleStatus':
				handleToggleStatus(row.original);
				break;
			case 'attachments':
				handleAttachments(row.original);
				break;
			case 'history':
				handleHistory(row.original);
				break;
			default:
				break;
		}
	};

	return (
		<div className={'customBodyContainerTable'}>
			<CustomTable
				columns={columns}
				elements={elements}
				defaultSortinField={'description'}
				handleAction={(row: Row<any>) => handleAction(row)}
				itemsPerPage={presentationsContext?.presentationToAuditMeta?.itemsPerPage}
			/>

			<div className="h-2" />
		</div>
	);
};
