import { memo } from 'react';
import { Box } from '@mui/material';
import { Navbar } from './Navbar/Navbar';
import { Outlet } from 'react-router-dom';
import style from './layout.module.css';
import { Footer } from './Footer/Footer';
import { UsersState } from 'contexts/usersContext/UsersState';

const Layout = memo(() => {
	return (
		<Box
			className={style.layout}
			accessKey="layout"
			sx={{ flexFlow: 1, width: '100%' }}>
			<UsersState>
				<Navbar />
			</UsersState>
			<div className={style.outlet}>
				<Outlet />
			</div>
			<Footer />
		</Box>
	);
});

Layout.displayName = 'Layout';

export { Layout };
