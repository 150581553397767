import { ColumnDef } from '@tanstack/react-table';
import { meses } from 'utils/meses';
import { MovementType } from 'enums';
import { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { MoreHoriz as MenuIcon, RemoveRedEye, Download } from '@mui/icons-material/';

export const defaultColumns: ColumnDef<any>[] = [
	{
		accessorKey: 'tipo_movimiento',
		header: () => <span>Tipo de Movimiento</span>,
		cell: ({ row: { original } }) => {
			if (original.movement_type === MovementType.ALTA) {
				return <div>ALTA</div>;
			} else if (original.movement_type === MovementType.BAJA) {
				return <div>BAJA</div>;
			}
		}
	},
	{
		accessorKey: 'patrimonial_dependency',
		accessorFn: (row) => row.patrimonial_dependency.value,
		header: () => <span>Dependencia Patrimonial</span>,
		cell: ({
			row: {
				original: { patrimonial_dependency }
			}
		}) => <div className="dep-pat">{patrimonial_dependency}</div>
	},
	{
		accessorKey: 'patrimonial_jurisdiction',
		accessorFn: (row) => row.patrimonial_dependency.value,
		header: () => <span>Jurisdicción</span>,
		cell: ({
			row: {
				original: { jurisdiction }
			}
		}) => <div className="dep-pat">{jurisdiction || '-'}</div>
	},
	{
		accessorFn: (row) => row.month,
		id: 'mes',
		header: () => <span>Mes</span>,
		cell: ({ row }) => <div className="month">{meses.find((mes) => Number(mes.id) === row.original.month)?.name}</div>
	},
	{
		accessorKey: 'year',
		header: () => <span>Año</span>,
		cell: ({ row: { original } }) => <div className="year">{original.year}</div>
	},
	{
		accessorKey: 'Acciones',
		enableSorting: false,
		enableGlobalFilter: false,
		enableColumnFilter: false,
		header: () => <span>Acciones</span>,
		cell: ({ row: { original } }) => {
			const [anchorEl, setAnchorEl] = useState(null);
			const open = Boolean(anchorEl);

			const handleClick = (event: any) => {
				setAnchorEl(event.currentTarget);
			};

			const handleClose = () => {
				setAnchorEl(null);
			};

			return (
				<div>
					<Button
						id="menu-btn"
						onClick={handleClick}>
						<MenuIcon />
					</Button>
					<Menu
						id="menu-btn"
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}>
						<MenuItem
							color="primary"
							onClick={() => {
								original.action = 'view-detail';
								handleClose();
							}}>
							<RemoveRedEye
								color="primary"
								sx={{ mr: 1 }}
							/>
							Ver Detalle
						</MenuItem>
						<MenuItem
							color="primary"
							onClick={() => {
								original.action = 'download-sheet';
								handleClose();
							}}>
							<Download
								color="primary"
								sx={{ mr: 1 }}
							/>
							Descargar Planilla
						</MenuItem>
					</Menu>
				</div>
			);
		}
	}
];
