import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import PulseLoader from 'react-spinners/PulseLoader';

// Hooks
import { useMovContext } from 'hooks/useMovContext';

//Service
import { getNomenclatureCode, getPropertyTypes } from 'services/crudService';

import { Typography, Box, TextField, Button, Modal } from '@mui/material';
import { NomenclatureCode } from 'components/globals/nomenclatureCode/nomenClatureCode';
import { Nomenclature } from '../nomenclatureInput/Nomenclature';
import { QuantityInput } from 'components/EncPatrimonial/altaStepper/paso3/quantityInput/QuantityInput';
import { UnitPriceInput } from '../unitPriceInput/UnitPriceInput';
import { DescriptionInput } from '../generico/descriptionInput/DescriptionInput';
import { BounceLoader } from 'react-spinners';
import { IStep3Accessories, IStep3GenericValues } from '../interfaces';

const styleModal = {
	position: 'absolute' as const,
	top: '45%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '33%',
	minWidth: '532px',
	maxHeight: '95%',
	bgcolor: '#e4e4e4',
	border: '2px solid #000000b9',
	boxShadow: 24
};

interface IModalAccessoriesProps {
	openModal: boolean;
	setOpenModal: Dispatch<SetStateAction<boolean>>;
	rightList: IStep3GenericValues[];
	setRightList: Dispatch<SetStateAction<IStep3GenericValues[]>>;
	selectedRow: IStep3GenericValues;
}

interface IPropertyType {
	id: number;
	name: string;
}

export const ModalAccessories = ({ openModal, setOpenModal, rightList, setRightList, selectedRow }: IModalAccessoriesProps) => {
	const [open, setOpen] = useState(true);
	const [nomenclatureCode, setNomenclatureCode] = useState('');
	const [nomenclatureCodeId, setNomenclatureCodeId] = useState<number>(0);
	const [description, setDescription] = useState('');
	const [quantity, setQuantity] = useState(String(selectedRow.quantity) || '');
	const [unitPrice, setUnitPrice] = useState('');
	const [loading, setLoading] = useState(false);
	const [opacity, setOpacity] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [submited, setSubmited] = useState(false);
	const [nomenclatureDescription, setNomenclatureDescription] = useState('');
	const [propertyType, setPropertyType] = useState<IPropertyType>();

	const movContext = useMovContext();

	useEffect(() => {
		getNomenclatureCode(selectedRow.nomenclatureCode).then((res) => {
			if (res.data[0]) {
				setNomenclatureDescription(res.data[0].description);
			}
		});
	}, [selectedRow]);

	useEffect(() => {
		getPropertyTypes().then((res) => {
			const dataNormalized = removeAccents(res.data);
			const type = dataNormalized.find((item: any) => item.name === selectedRow.rightType);
			setPropertyType(type);
		});
	}, []);

	/* Call handleDisabled function when one of the dependencies change. */
	function removeAccents(strArr: any[]) {
		const newArr: any = [];
		strArr.map((item) => {
			newArr.push({ id: item.id, name: item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '') });
		});
		return newArr;
	}

	useEffect(() => {
		handleDisabled();
	}, [nomenclatureCode, description, quantity, unitPrice]);

	/**
	 * If any of the four input fields are empty, the button is disabled
	 * @returns void
	 */
	const handleDisabled = (): boolean => {
		setDisabled(true);
		if (nomenclatureCode.trim().length === 0) {
			return true;
		}
		if (description.trim().length === 0) {
			return true;
		}
		if (quantity.trim().length === 0) {
			return true;
		}
		if (unitPrice.trim().length === 0) {
			return true;
		}
		setDisabled(false);
		return false;
	};

	const handleClose = () => {
		setOpen(false);
		setOpenModal(false);
	};

	const handleLoading = () => {
		setLoading(true);
		setTimeout(() => {
			setOpacity(true);
		}, 300);
		setTimeout(() => {
			handleClose();
			toast('Accesorio agregado correctamente', { type: 'success', autoClose: 2000 });
		}, 2000);
	};

	const getMaxAccessoryId = () => {
		if (!selectedRow?.accessories || selectedRow?.accessories?.length === 0) return '1';
		const maxId = selectedRow?.accessories?.reduce(
			(max, item) => (item.accessory_number > max ? item.accessory_number : max),
			selectedRow?.accessories[0].accessory_number
		);
		if (maxId) {
			return (Number(maxId) + 1).toString();
		} else {
			return '1';
		}
	};
	const handleAddAccessories = () => {
		handleLoading();
		const accessoriesInContext = movContext?.accessoryFromTable;

		const date = new Date();
		const newQuantity = parseInt(quantity);
		const accessoriesItem: IStep3Accessories = {
			nomenclatureCode,
			nomenclatureCodeId,
			description,
			quantity: newQuantity,
			unitPrice: unitPrice,
			parent_temporary_id: selectedRow.id,
			property_id: movContext?.editMovement?.property_registration?.id,
			temporary_id: uuidv4(),
			discharge_date: date.toLocaleDateString(),
			is_accessory: true,
			accessory_number: getMaxAccessoryId(),
			property_type_id: propertyType?.id || 0,
			operation: 'create'
		};
		if (accessoriesInContext && accessoriesInContext.length > 0) {
			movContext?.setAccessoryFromTable((prev) => [...prev, accessoriesItem]);
		} else {
			movContext?.setAccessoryFromTable([accessoriesItem]);
		}
		const newRightList = [...rightList];
		const oldItem = newRightList.find((item) => item.id === selectedRow.id);
		if (oldItem !== undefined) {
			const oldAccessories = oldItem.accessories;
			if (oldAccessories !== undefined) {
				oldAccessories.push(accessoriesItem);
				oldItem.accessories = oldAccessories;
			} else {
				oldItem.accessories = [accessoriesItem];
			}
			setRightList([...newRightList]);
			setSubmited(true);
		}
	};

	return (
		<div>
			<Modal
				keepMounted
				open={openModal}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<Box sx={styleModal}>
					<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
						<>
							<div style={{ width: '100%' }}>
								<Typography
									sx={{ p: 3, backgroundColor: 'rgb(51, 82, 100)', color: 'white', textAlign: 'center' }}
									fontStyle={'italic'}
									id="modal-modal-title"
									variant="h6">
									Agregar Accesorios
								</Typography>
							</div>
							<div style={{ backgroundColor: '#79888f53', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<Typography
									variant="h6"
									fontStyle={'italic'}
									id="modal-modal-description"
									sx={{ mt: 1, fontWeight: '600' }}>
									{`Codigo Nomenclador: ${selectedRow.nomenclatureCode}`}
								</Typography>
								{nomenclatureDescription?.trim().length > 0 ? (
									<Typography
										sx={{ p: 1 }}
										id="modal-modal-title"
										variant="subtitle1"
										textAlign={'center'}>
										{nomenclatureDescription}
									</Typography>
								) : (
									<PulseLoader
										color={'rgb(51, 82, 100)'}
										loading={true}
										size={15}
										cssOverride={{ padding: '15px 10px' }}
									/>
								)}
							</div>
							<Box
								sx={{
									minWidth: '532px',
									width: '100%',
									pl: 3,
									pr: 3,
									mt: 2,
									mb: 4,
									display: 'flex',
									flexWrap: 'wrap',
									rowGap: '50px',
									columnGap: '20px'
								}}>
								<div style={{ width: '100%' }}>
									<NomenclatureCode
										submited={submited}
										setNomenclatureCode={setNomenclatureCode}
										setNomenclatureCodeId={setNomenclatureCodeId}
									/>
								</div>
								<div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '15px' }}>
									<QuantityInput
										submited={submited}
										setQuantity={setQuantity}
										value={quantity}
										disabled={true}
									/>
									<UnitPriceInput
										submited={submited}
										setUnitPrice={setUnitPrice}
									/>
								</div>

								<DescriptionInput
									submited={submited}
									setSubmited={setSubmited}
									required={true}
									setDescription={setDescription}
								/>
							</Box>
							<Box sx={{ p: 3 }}>
								<Button
									disabled={disabled}
									variant="contained"
									color="primary"
									onClick={handleAddAccessories}>
									Agregar
								</Button>
								<Button
									variant="contained"
									color="secondary"
									onClick={handleClose}
									sx={{ ml: 2 }}>
									Cancelar
								</Button>
							</Box>
						</>

						{loading && (
							<Box
								sx={{
									zIndex: '9999',
									opacity: opacity ? '1' : '0',
									position: 'absolute',
									backdropFilter: 'blur(2px)',
									height: '100%',
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									transition: 'all 800ms ease-in-out'
								}}>
								<BounceLoader
									size={100}
									speedMultiplier={1.5}
								/>
							</Box>
						)}
					</Box>
				</Box>
			</Modal>
		</div>
	);
};
