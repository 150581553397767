import { useState, useEffect, memo, ChangeEvent, LegacyRef, Dispatch, SetStateAction } from 'react';
import useDebounce from 'hooks/useDebounce';

// Material UI
import { FormControl, InputLabel, OutlinedInput, InputAdornment, Tooltip, Button, FormHelperText } from '@mui/material';

import AttachFileIcon from '@mui/icons-material/AttachFile';

interface nComprobanteProps {
	ncomprobante: string;
	handleNcomprobante: (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	errorFile: string | null;
	adjunto: LegacyRef<HTMLInputElement> | undefined;
	handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
	puntoVenta: string;
	setPuntoVenta: Dispatch<SetStateAction<string>>;
}

const Ncomprobante = memo(
	({ puntoVenta, setPuntoVenta, ncomprobante, handleNcomprobante, errorFile, adjunto, handleFileChange }: nComprobanteProps) => {
		const [ncomprobanteLocal, setNcomprobanteLocal] = useState(ncomprobante);
		const [puntoVentaLocal, setPuntoVentaLocal] = useState(puntoVenta);
		const debouncedValue = useDebounce<string>(ncomprobanteLocal, 400);

		const handleNcomprobanteLocal = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			evt.preventDefault();
			const name: string = evt.target.value;
			if (name.trim().length >= 9) return;
			if (RegExp(/^[0-9]*$/g).test(name) || name === '') {
				setNcomprobanteLocal(evt.target.value);
			}
		};

		const handlePuntoVenta = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			evt.preventDefault();
			const name: string = evt.target.value;
			if (name.trim().length >= 6) return;
			if (RegExp(/^[0-9]*$/g).test(name) || name === '') {
				setPuntoVentaLocal(evt.target.value);
				setPuntoVenta(evt.target.value);
			}
		};

		useEffect(() => {
			handleNcomprobante({ target: { value: debouncedValue } } as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
		}, [debouncedValue]);

		return (
			<div style={{ width: '250px' }}>
				<FormControl
					variant="outlined"
					sx={{ mb: '25px' }}>
					<InputLabel htmlFor="puntoVenta">N° Punto de Venta *</InputLabel>
					<OutlinedInput
						sx={{ width: '200px' }}
						label="N° Punto de Venta *"
						autoComplete="off"
						name="puntoVenta"
						id="puntoVenta"
						value={puntoVentaLocal}
						onChange={handlePuntoVenta}
					/>
					{puntoVentaLocal?.trim().length < 4 && (
						<FormHelperText sx={{ position: 'relative', bottom: 0 }}>* Mínimo 4 caracteres</FormHelperText>
					)}
				</FormControl>
				<FormControl variant="outlined">
					<InputLabel htmlFor="ncomprobante">N° de Comprobante *</InputLabel>
					<OutlinedInput
						sx={{ width: '250px' }}
						label="N° de Comprobante *"
						autoComplete="off"
						name="ncomprobante"
						id="ncomprobante"
						value={ncomprobanteLocal}
						onChange={handleNcomprobanteLocal}
						endAdornment={
							<InputAdornment position="end">
								<Tooltip
									arrow
									title="Adjuntar Comprobante">
									<Button
										sx={{
											backgroundColor: 'rgba(0, 0, 0, 0.05)',
											color: '#000',
											padding: '5px',
											minWidth: '40px',
											'&:hover': {
												backgroundColor: 'rgba(0, 0, 0, 0.05)'
											}
										}}
										variant="contained"
										component="label">
										<AttachFileIcon />
										<FormControl>
											<input
												ref={adjunto}
												id="adjunto"
												name="adjunto"
												type="file"
												accept="image/*, application/pdf"
												hidden
												onChange={handleFileChange}
											/>
										</FormControl>
									</Button>
								</Tooltip>
							</InputAdornment>
						}></OutlinedInput>
					{ncomprobante.trim().length !== 0 && ncomprobante.trim().length < 8 && (
						<FormHelperText sx={{ position: 'absolute', mt: 8 }}>* Debe contener 8 caracteres.</FormHelperText>
					)}
					{errorFile !== null && <FormHelperText>{errorFile}</FormHelperText>}
				</FormControl>
			</div>
		);
	}
);

Ncomprobante.displayName = 'Ncomprobante';

export { Ncomprobante };
