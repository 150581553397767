import {createTheme} from '@mui/material/styles';
import {grey} from '@mui/material/colors';

// Create a theme instance.
export const DarkTheme = createTheme({
  palette: {
    mode: 'dark'
  },
  components: {
    MuiFormGroup: {
      styleOverrides: {
        root: {
          backgroundColor: grey[800]
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          backgroundColor: grey[800]
        }
      }
    }
  }
});

export default DarkTheme;
