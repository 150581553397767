import { FC, Dispatch, SetStateAction, useState, useEffect } from 'react';

import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export interface IInputPasswordProps {
	value?: string;
	handleChange: Dispatch<SetStateAction<any>>;
	sx?: object;
	name: string;
	id: string;
	label?: string;
	required?: boolean;
	pattern?: RegExp;
	hint?: string;
	fullWidth?: boolean;
	error?: string;
	size?: 'small' | 'medium' | undefined;
}

export const FormGenericPassword: FC<IInputPasswordProps> = ({
	value,
	handleChange,
	sx,
	label = 'Contraseña',
	required = true,
	name = 'generic',
	id = 'generic',
	hint,
	fullWidth = true,
	error,
	size = 'medium'
}) => {
	const [internalState, setInternalState] = useState('');

	useEffect(() => {
		setInternalState(value ? value : '');
	}, [value]);

	useEffect(() => {
		handleChange(internalState);
	}, [internalState]);

	const hintId = `${id}_hint`;
	const errorId = `${id}_error`;

	const [showPassword, setShowPassword] = useState(false);

	return (
		<FormControl
			required={required}
			fullWidth={fullWidth}>
			<InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
			<OutlinedInput
				id={id}
				type={showPassword ? 'text' : 'password'}
				name={name}
				size={size}
				sx={sx ? { ...sx } : {}}
				value={internalState}
				onChange={(ev) => setInternalState(ev.target.value)}
				error={error !== undefined}
				label={label}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={() => setShowPassword(!showPassword)}
							onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
								event.preventDefault();
							}}
							edge="end">
							{showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				}
				inputProps={{
					autoComplete: 'new-password',
					form: {
						autoComplete: 'off'
					}
				}}
			/>
			{hint !== undefined ? <FormHelperText id={hintId}>{hint}</FormHelperText> : null}
			{error !== undefined ? (
				<FormHelperText
					id={errorId}
					error={true}>
					{error}
				</FormHelperText>
			) : null}
		</FormControl>
	);
};
