import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

const API = import.meta.env.VITE_API_URL;

const apiInstance = axios.create({ baseURL: `${API}` });

let retrying = 0;

const getLocalRefreshToken = () => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');
	return token?.refresh_token;
};

apiInstance.interceptors.request.use(async (req) => {
	if (req.url === '/auth/login' || req.url === '/auth/forgot-password') return req;
	const authToken = JSON.parse(localStorage.getItem('token') || '{}');

	if (authToken?.access_token) {
		req.headers = {
			...req.headers,
			Authorization: `Bearer ${authToken.access_token}`
		};
	}
	return req;
});

apiInstance.interceptors.response.use(
	(res) => {
		retrying = 0;
		return res;
	},
	async (error) => {
		toast.error(error.code.includes('NETWORK') && 'Algo salió mal, error de conexión');
		if (error.message === 'Network Error' && error.config.url !== '/auth/login') {
			retrying = retrying + 1;
			refreshTokens();
		}

		if (retrying < 1 && error.config.url !== '/auth/login') {
			const originalRequest = error.config;
			if (error.response.status === 401 && error.response.statusText === 'Unauthorized' && !originalRequest._retry && retrying < 2) {
				originalRequest._retry = true;
				retrying = retrying + 1;

				try {
					toast.warning('Su sesión a expirado, renovando sesión...');
					const refreshToken: AxiosResponse = await refreshTokens();
					const { access_token } = refreshToken.data;
					localStorage.removeItem('token');
					localStorage.setItem('token', JSON.stringify(refreshToken.data));
					apiInstance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

					return apiInstance(originalRequest);
				} catch (_error: any) {
					if (_error?.response && _error?.response?.data) {
						return Promise.reject(_error?.response?.data);
					}
					return Promise.reject(_error);
				}
			}
		}
		return Promise.reject(error);
	}
);

export async function refreshTokens() {
	const refreshToken = getLocalRefreshToken();
	return await apiInstance
		.post('auth/refresh', {
			refreshToken: `${refreshToken}`
		})
		.then((res) => {
			localStorage.setItem('token', JSON.stringify(res.data));
			return res;
		})
		.catch((error) => {
			/*
			  If the refresh token is expired, 
				and the interceptor is trying to refresh
				the token more than 2 times,
				the user will be logged out and  
				the user will be redirected to the login page 
			*/
			const savedLogin = localStorage.getItem('savePassword');
			const loginEmail = localStorage.getItem('loginEmail');
			const loginPassword = localStorage.getItem('loginPassword');
			localStorage.clear();
			localStorage.setItem('savePassword', savedLogin || 'false');
			localStorage.setItem('loginEmail', loginEmail || '');
			localStorage.setItem('loginPassword', loginPassword || '');
			window.location.href = '/login';
			return error;
		});
}

export default apiInstance;
