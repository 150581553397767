/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, SetStateAction, Dispatch, memo, useRef } from 'react';
import { InputGeneric } from 'components/globals/inputs/InputGeneric';
import { IStep3VehicleValues } from '../../interfaces';
import { ValidateEmbarcaciones } from '../validateVehicle';
import useDebounce from 'hooks/useDebounce';
import { useMovContext } from 'hooks/useMovContext';

import { Box, InputLabel } from '@mui/material';

export interface IPaso3EmbarcacionesProps {
	memoizedDataFromChild: (data: SetStateAction<IStep3VehicleValues>) => void;
	submited: boolean;
	setSubmited: Dispatch<SetStateAction<boolean>>;
}

const Embarcaciones = memo(({ memoizedDataFromChild, submited, setSubmited }: IPaso3EmbarcacionesProps) => {
	const movContext = useMovContext();
	const [genericList, setGenericList] = useState<IStep3VehicleValues>({
		vehicleType: 'Embarcaciones'
	} as IStep3VehicleValues);
	const [domainRegistartion, setDomainRegistration] = useState(movContext?.itemSelected?.domainRegistartion || '');
	const [boatName, setBoatName] = useState(movContext?.itemSelected?.boatName || '');
	const [type, setTipo] = useState(movContext?.itemSelected?.type || '');
	const [boatHullNumber, setBoatHullNumber] = useState(movContext?.itemSelected?.boatHullNumber || '');
	const [modelHull, setModelHull] = useState(movContext?.itemSelected?.modelHull || '');
	const [owner, setOwner] = useState(movContext?.itemSelected?.owner || '');
	const [motorBrandName, setMotorBrandName] = useState(movContext?.itemSelected?.engineBrandName || '');
	const [motorNumber, setMotorNumber] = useState(movContext?.itemSelected?.engineNumber || '');
	const [motorModelSerie, setMotorModelSerie] = useState(movContext?.itemSelected?.engineModelSerie || '');
	const [motorOwner, setMotorOwner] = useState(movContext?.itemSelected?.engineOwner || '');
	const [dateOfConstruction, setDateOfConstruction] = useState(
		movContext?.itemSelected?.dateOfConstruction
			? new Date(movContext?.itemSelected?.dateOfConstruction).toISOString().slice(0, 10)
			: '' || ''
	);
	const debouncedValue = useDebounce(genericList, 400);

	const dateInputRef = useRef<HTMLInputElement>(null);

	/* Setting the max date of the input field. */
	if (dateInputRef.current) {
		const date = new Date();
		date.setDate(date.getDate());
		dateInputRef.current.max = date.toISOString().split('T')[0];
	}

	const handleAdd = (evt: any) => {
		evt.preventDefault();
		setGenericList({ ...genericList, [evt.target.name]: evt.target.value });
	};

	useEffect(() => {
		const valid = ValidateEmbarcaciones(genericList as any);
		valid ? memoizedDataFromChild(genericList) : memoizedDataFromChild({} as IStep3VehicleValues);
	}, [debouncedValue]);

	useEffect(() => {
		const genericList = {
			domainRegistartion,
			boatName,
			type,
			boatHullNumber,
			modelHull,
			owner,
			engineBrandName: motorBrandName,
			engineNumber: motorNumber,
			engineModelSerie: motorModelSerie,
			engineOwner: motorOwner,
			dateOfConstruction,
			vehicleType: 'Embarcaciones' as const
		};
		const valid = ValidateEmbarcaciones(genericList);
		valid ? memoizedDataFromChild(genericList) : memoizedDataFromChild({} as IStep3VehicleValues);
	}, [movContext?.itemSelected, debouncedValue]);

	useEffect(() => {
		if (submited) {
			setDomainRegistration('');
			setBoatName('');
			setTipo('');
			setBoatHullNumber('');
			setModelHull('');
			setOwner('');
			setMotorBrandName('');
			setMotorNumber('');
			setMotorModelSerie('');
			setMotorOwner('');
			setDateOfConstruction('');
			setGenericList({ vehicleType: 'Embarcaciones' } as IStep3VehicleValues);
			setSubmited(false);
		}
	}, [submited]);

	return (
		<div
			onChange={handleAdd}
			style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '55px', rowGap: '55px' }}>
			<InputGeneric
				stateValue={domainRegistartion}
				setStateValue={setDomainRegistration}
				label={'Dominio/Matricula'}
				name={'domainRegistartion'}
				required={true}
			/>
			<InputGeneric
				stateValue={boatName}
				setStateValue={setBoatName}
				label={'Nombre de la Embarcación'}
				name={'boatName'}
			/>
			<InputGeneric
				stateValue={type}
				setStateValue={setTipo}
				label={'Tipo'}
				name={'type'}
				required={true}
			/>
			<InputGeneric
				stateValue={boatHullNumber}
				setStateValue={setBoatHullNumber}
				label={'Nro de Casco'}
				name={'boatHullNumber'}
				required={true}
			/>
			<InputGeneric
				stateValue={modelHull}
				setStateValue={setModelHull}
				label={'Modelo de Casco'}
				name={'modelHull'}
				required={true}
			/>
			<InputGeneric
				stateValue={owner}
				setStateValue={setOwner}
				label={'Titular'}
				name={'owner'}
			/>
			<InputGeneric
				stateValue={motorBrandName}
				setStateValue={setMotorBrandName}
				label={'Marca Motor'}
				name={'engineBrandName'}
			/>
			<InputGeneric
				stateValue={motorNumber}
				setStateValue={setMotorNumber}
				label={'Nro de Motor'}
				name={'engineNumber'}
			/>
			<InputGeneric
				stateValue={motorModelSerie}
				setStateValue={setMotorModelSerie}
				label={'Modelo/Serie'}
				name={'engineModelSerie'}
			/>
			<InputGeneric
				stateValue={motorOwner}
				setStateValue={setMotorOwner}
				label={'Titular Motor'}
				name={'engineOwner'}
			/>
			<Box sx={{ width: '220px', ml: '-10px', mt: '-14.5px' }}>
				<InputLabel
					sx={{ position: 'inherit', mb: '-3px' }}
					htmlFor="dateOfConstruction">
					Fecha de Fabricación
				</InputLabel>
				<input
					name="dateOfConstruction"
					ref={dateInputRef}
					value={dateOfConstruction}
					onChange={(e) => setDateOfConstruction(e.target.value)}
					id="dateOfConstruction"
					className="form-control"
					type="date"
				/>
				<span id="dateOfConstruction"></span>
			</Box>
		</div>
	);
});

Embarcaciones.displayName = 'Embarcaciones';

export { Embarcaciones };
