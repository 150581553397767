import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { GenericFieldDetail } from 'components/globals/fields/FieldDetail';
import { currencyLocalFormat } from 'helpers/Formaters/Formaters';
import { VehicleFormData } from './Vehicle';
import { VEHICLE_TYPE } from 'enums';
import dayjs from 'dayjs';

const gridContainerProps = {
	spacing: 2,
	container: true,
	pt: 2
};

interface VehicleDetailsProps {
	vehicleType?: VEHICLE_TYPE;
	isAccessory: boolean;
	nomenclatureCode: VehicleFormData['general']['nomenclatureCode'];
	quantity: string;
	unitPrice: number;
	details: VehicleFormData['vehicle'];
	inventoryNumber?: string;
	rowNumber?: string;
}

export const VehicleDetails: React.FC<VehicleDetailsProps> = ({
	vehicleType,
	isAccessory,
	nomenclatureCode,
	quantity,
	unitPrice,
	details,
	inventoryNumber,
	rowNumber
}) => {
	const shouldRenderOn = (...types: VEHICLE_TYPE[]) => {
		if (!vehicleType) return false;
		return types.includes(vehicleType);
	};

	return (
		<Grid
			{...gridContainerProps}
			spacing={4}>
			{/* General */}
			<Grid
				item
				{...gridContainerProps}
				width="100%">
				<Grid item>
					<Typography
						variant="h1"
						fontSize={24}
						fontWeight={500}
						mb={1}>
						{isAccessory ? 'Datos del Accesorio' : 'Datos Generales'}
					</Typography>
				</Grid>

				<Grid
					item
					width={'100%'}>
					<GenericFieldDetail
						label={'Tipo de Bien:'}
						value="Vehículo"
					/>
				</Grid>

				<Grid
					item
					width={'100%'}>
					<GenericFieldDetail
						label={'Tipo de Vehículo:'}
						value={vehicleType}
					/>
				</Grid>

				<Grid
					item
					width={'100%'}>
					<GenericFieldDetail
						label={'Código de Nomenclador:'}
						value={nomenclatureCode?.value}
					/>
					<Divider />
				</Grid>

				<Grid
					item
					width={'100%'}>
					<GenericFieldDetail
						label={'N° de Inventario:'}
						value={inventoryNumber}
					/>
					<Divider className="customDivider" />
				</Grid>

				<Grid
					item
					width={'100%'}>
					<GenericFieldDetail
						label={'Descripción de Nomenclador:'}
						value={nomenclatureCode?.label}
					/>
					<Divider className="customDivider" />
				</Grid>

				<Grid
					item
					width={'100%'}>
					<GenericFieldDetail
						label={'Cantidad:'}
						value={quantity}
					/>
					<Divider />
				</Grid>

				<Grid
					item
					width={'100%'}>
					<GenericFieldDetail
						label={'Precio Unitario:'}
						value={currencyLocalFormat(unitPrice)}
					/>
					<Divider />
				</Grid>

				{rowNumber ? (
					<>
						<GenericFieldDetail
							label={'Renglón:'}
							value={rowNumber}
						/>
						<Divider className="customDivider" />{' '}
					</>
				) : null}

				{shouldRenderOn(VEHICLE_TYPE.OTHERS) ? (
					<Grid
						item
						width={'100%'}>
						<GenericFieldDetail
							label={'Descripción:'}
							value={details.description}
						/>
					</Grid>
				) : null}
			</Grid>

			{/* Vehicle*/}
			{vehicleType !== VEHICLE_TYPE.OTHERS && !isAccessory ? (
				<Grid
					{...gridContainerProps}
					marginLeft={0}
					padding={2}>
					<Grid
						item
						width={'100%'}>
						<Typography
							variant="h1"
							fontSize={24}
							fontWeight={500}
							mb={1}>
							Detalle del Vehículo
						</Typography>
					</Grid>

					{shouldRenderOn(VEHICLE_TYPE.AIRCRAFT, VEHICLE_TYPE.AUTOMOTIVE, VEHICLE_TYPE.MOTOVEHICLES) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Marca:'}
								value={details.brand}
							/>
							<Divider />
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.AIRCRAFT, VEHICLE_TYPE.AUTOMOTIVE, VEHICLE_TYPE.MOTOVEHICLES) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Modelo:'}
								value={details.model}
							/>
							<Divider />
						</Grid>
					) : null}

					{shouldRenderOn(
						VEHICLE_TYPE.AUTOMOTIVE,
						VEHICLE_TYPE.MOTOVEHICLES,
						VEHICLE_TYPE.AIRCRAFT,
						VEHICLE_TYPE.VESSELS
					) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Tipo:'}
								value={details.type}
							/>
							<Divider />
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.AUTOMOTIVE, VEHICLE_TYPE.MOTOVEHICLES) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Origen:'}
								value={details.origin}
							/>
							<Divider />
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.AUTOMOTIVE, VEHICLE_TYPE.MOTOVEHICLES, VEHICLE_TYPE.VESSELS) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Número de Motor:'}
								value={details.engineNumber}
							/>
							<Divider />
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.MOTOVEHICLES) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Número de Cuadro:'}
								value={details.frameNumber}
							/>
							<Divider />
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.AUTOMOTIVE) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Número de Chasis:'}
								value={details.chassisNumber}
							/>
							<Divider />
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.VESSELS) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Número de Casco:'}
								value={details.helmetNumber}
							/>
							<Divider />
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.VESSELS) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Modelo de Casco:'}
								value={details.helmetModel}
							/>
							<Divider />
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.VESSELS) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Marca de Casco:'}
								value={details.helmetBrand}
							/>
							<Divider />
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.VESSELS) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Modelo/Serie:'}
								value={details.modelSeries}
							/>
							<Divider />
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.VESSELS) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Titular Motor:'}
								value={details.engineHolder}
							/>
							<Divider />
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.VESSELS) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Nombre de la Embarcación:'}
								value={details.shipName}
							/>
							<Divider />
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.AUTOMOTIVE, VEHICLE_TYPE.MOTOVEHICLES) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Año:'}
								value={details.year}
							/>
							<Divider />
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.AUTOMOTIVE, VEHICLE_TYPE.MOTOVEHICLES, VEHICLE_TYPE.VESSELS) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Titular:'}
								value={details.headline}
							/>
							<Divider />
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.VESSELS) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Marca Motor:'}
								value={details.engineBrand}
							/>
							<Divider />
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.AIRCRAFT) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Número de Serie:'}
								value={details.serialNumber}
							/>
							<Divider />
						</Grid>
					) : null}

					{shouldRenderOn(
						VEHICLE_TYPE.VESSELS,
						VEHICLE_TYPE.AIRCRAFT,
						VEHICLE_TYPE.AUTOMOTIVE,
						VEHICLE_TYPE.MOTOVEHICLES
					) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Dominio/Matrícula:'}
								value={details.domain || details.registration}
							/>
							<Divider />
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.VESSELS, VEHICLE_TYPE.AIRCRAFT) ? (
						<Grid
							width="100%"
							item>
							<GenericFieldDetail
								label={'Fecha de Fabricación:'}
								value={details.manufacturingDate ? dayjs(details.manufacturingDate).format('DD/MM/YYYY') : ''}
							/>
							<Divider />
						</Grid>
					) : null}
				</Grid>
			) : null}
		</Grid>
	);
};
