import { ColumnDef } from '@tanstack/react-table';
import { INotifications } from 'interfaces/notifications/INotifications';
import { StatusNotification } from 'enums';
import { Button, Menu, MenuItem } from '@mui/material';
import DraftsIcon from '@mui/icons-material/Drafts';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import { MoreHoriz as MenuIcon, RemoveRedEye } from '@mui/icons-material/';
import { useState } from 'react';

export const defaultColumnsNovedades: ColumnDef<INotifications>[] = [
	{
		accessorKey: 'sender_person',
		enableSorting: false,
		header: () => <span>Remitente</span>,
		cell: ({ row: { original } }) => <p className="remitente">{original.sender_person}</p>
	},
	{
		accessorKey: 'period',
		enableSorting: false,
		header: () => <span>Periodo</span>,
		cell: ({ row: { original } }) => <p className="remitente">{original?.period}</p>
	},
	{
		accessorKey: 'created_at',
		enableSorting: false,
		header: () => <span>Fecha</span>,
		cell: ({ row: { original } }) => {
			if (typeof original.created_at === 'string') {
				const date = new Date(original.created_at);
				return <p className="fecha">{date.toLocaleDateString()}</p>;
			} else {
				return <p className="fecha">{original.created_at.toLocaleDateString()}</p>;
			}
		}
	},
	{
		accessorKey: 'Acciones',
		enableSorting: false,
		enableGlobalFilter: false,
		enableColumnFilter: false,
		header: () => <span>Acciones</span>,
		cell: ({ row: { original } }) => {
			const [anchorEl, setAnchorEl] = useState(null);
			const open = Boolean(anchorEl);

			const handleClick = (event: any) => {
				setAnchorEl(event.currentTarget);
			};

			const handleClose = () => {
				setAnchorEl(null);
			};

			return (
				<div>
					<Button
						id="menu-btn"
						onClick={handleClick}>
						<MenuIcon />
					</Button>
					<Menu
						id="menu-btn"
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}>
						<MenuItem
							color="primary"
							onClick={() => {
								original.action = 'read-unread';
								handleClose();
							}}>
							{original.status_id === StatusNotification.READ ? (
								<>
									<MarkunreadIcon
										sx={{ mr: 1 }}
										color="primary"
									/>
									Marcar como leída
								</>
							) : (
								<>
									<DraftsIcon
										sx={{ mr: 1 }}
										color="primary"
									/>
									Marcar como no leída
								</>
							)}
						</MenuItem>

						<MenuItem
							color="primary"
							onClick={() => {
								original.action = 'view-detail';
								handleClose();
							}}>
							<RemoveRedEye
								color="primary"
								sx={{ mr: 1 }}
							/>
							Ver detalle
						</MenuItem>
					</Menu>
				</div>
			);
		}
	}
];
