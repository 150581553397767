import { useState, useEffect, ChangeEvent, useRef } from 'react';

// Hooks
import { useMovContext } from 'hooks/useMovContext';

// Interfaces
import { Ipaso2Props, IExpediente, IinstrumentoLegal, IOrdenProvisional } from 'components/EncPatrimonial/altaStepper/paso2/interfaces';

// Material UI
import {
	Box,
	Button,
	Grid,
	TextField,
	Typography,
	FormControl,
	FormHelperText,
	OutlinedInput,
	InputAdornment,
	Tooltip
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import ArticleIcon from '@mui/icons-material/Article';
import { FileType } from 'enums';
import { IRecepAndInstMovProvider } from 'interfaces/movements';

export const InstrumentsEdit = ({ setPaso2 }: Ipaso2Props) => {
	const movContext = useMovContext();

	const [expediente, setExpediente] = useState<IExpediente>({} as IExpediente);
	const [instrumentoLegal, setInstrumentoLegal] = useState<IinstrumentoLegal>({} as IinstrumentoLegal);
	const [ordenProvision, setOrdenProvision] = useState<IOrdenProvisional>({} as IOrdenProvisional);
	const [adjuntos, setAdjuntos] = useState<any[] | null>(null);
	const [errorFile, setErrorFile] = useState<string | null>(null);

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		movContext?.setRecepAndInstAttachs([]);
		if (movContext?.editMovement?.property_registration) {
			setExpediente({
				codigo:
					movContext?.editMovement?.property_registration?.proceedings_number?.agency_code !== '0'
						? movContext?.editMovement?.property_registration?.proceedings_number?.agency_code
						: '',
				numero:
					movContext?.editMovement?.property_registration?.proceedings_number?.number !== '0'
						? movContext?.editMovement?.property_registration?.proceedings_number?.number
						: '',
				anio:
					movContext?.editMovement?.property_registration?.proceedings_number?.year !== '0'
						? movContext?.editMovement?.property_registration?.proceedings_number?.year
						: ''
			});
			setInstrumentoLegal({
				numero:
					movContext?.editMovement?.property_registration?.legal_instrument?.instrument_number !== '0'
						? movContext?.editMovement?.property_registration?.legal_instrument?.instrument_number
						: '',
				anio:
					movContext?.editMovement?.property_registration?.legal_instrument?.instrument_year !== '0'
						? movContext?.editMovement?.property_registration?.legal_instrument?.instrument_year
						: ''
			});
			setOrdenProvision({
				numero:
					movContext?.editMovement?.property_registration?.provision_order?.provision_number !== '0'
						? movContext?.editMovement?.property_registration?.provision_order?.provision_number
						: '',
				anio:
					movContext?.editMovement?.property_registration?.provision_order?.provision_year !== '0'
						? movContext?.editMovement?.property_registration?.provision_order?.provision_year
						: ''
			});
		}
		if (movContext?.editMovement?.property_registration?.files && movContext?.editMovement?.property_registration?.files.length > 0) {
			const files = movContext?.editMovement?.property_registration?.files.map((file: any) => {
				if (file.file_type_id === FileType.Decretos) {
					return file;
				}
				return null;
			});
			if (files.length > 0) {
				const setFiles = files.filter((file: any) => file !== null);
				if (setFiles.length > 0) {
					setAdjuntos(setFiles);
				}
			}
		}
	}, [movContext?.editMovement]);

	/* It's a hook that runs after the component is mounted and after every update
	 * of expediente, instrumentoLegal and ordenProvision.
	 */
	useEffect(() => {
		const isValid = true;
		setPaso2({
			data: { expediente, ordenProvision, instrumentoLegal, adjuntos },
			valid: isValid
		});
		setLocal();
	}, [expediente, instrumentoLegal, ordenProvision, adjuntos]);

	/**
	 * It takes the values of the three inputs and stores them in localStorage
	 */
	const setLocal = () => {
		const values = { ordenProvision, expediente, instrumentoLegal };
	};

	/**
	 * A function that handles the change of the input expediente field.
	 * @param evt - ChangeEvent<HTMLInputElement>
	 */
	const handleExpediente = (evt: ChangeEvent<HTMLInputElement>) => {
		evt.preventDefault();
		if (evt.target.name === 'anio' && !RegExp(/^\d{0,4}$/).test(evt.target.value)) return;
		setExpediente({ ...expediente, [evt.target.name]: evt.target.value });
	};

	/**
	 * A function that handles the change of the input instrumentoLegal field.
	 * @param evt - ChangeEvent<HTMLInputElement>
	 */
	const handleInstrumento = (evt: ChangeEvent<HTMLInputElement>) => {
		evt.preventDefault();
		if (evt.target.name === 'anio' && !RegExp(/^\d{0,4}$/).test(evt.target.value)) return;
		setInstrumentoLegal({ ...instrumentoLegal, [evt.target.name]: evt.target.value });
	};

	/**
	 * A function that handles the change of the input ordenProvision fields.
	 * @param evt - ChangeEvent<HTMLInputElement>
	 */
	const handleOrdenProvision = (evt: ChangeEvent<HTMLInputElement>) => {
		evt.preventDefault();

		if (evt.target.name === 'anio' && !RegExp(/^\d{0,4}$/).test(evt.target.value)) return;
		setOrdenProvision({ ...ordenProvision, [evt.target.name]: evt.target.value });
	};

	/**
	 * It takes an event, checks if the files are greater than 100mb, if they are, it sets an error
	 * message and returns. If they're not, it sets the files
	 * @param evt - ChangeEvent<HTMLInputElement>
	 */
	const handleAdjuntos = (evt: ChangeEvent<HTMLInputElement>) => {
		setErrorFile(null);
		let fileSize = 0;
		const {
			target: { files }
		} = evt;

		if (files && files.length) {
			const valids = ['pdf', 'jpg', 'jpeg', 'png'];
			Object.entries(files).map(([key, file]) => {
				fileSize += file.size;
				if (!valids.includes(file.type.split('/')[1])) {
					setErrorFile('Solo se permiten archivos .pdf, .jpeg, .png, .jpg');
					setAdjuntos(null);
					deleteAdjuntos();
					return;
				}
			});
			if (fileSize / 1024 / 1024 > 100) {
				setErrorFile('* La suma de los archivos adjuntos excede el tamaño máximo permitido (100mb)');
				setAdjuntos(null);
				deleteAdjuntos();
				return;
			}

			const newAdjuntosAux = Array.from(files);
			const newAdjuntos: File[] = [];
			newAdjuntosAux.map((adjunto) => {
				const newFile = new File([adjunto], adjunto.name.toLocaleLowerCase(), { type: adjunto.type, lastModified: adjunto.lastModified });
				newAdjuntos.push(newFile);
			});

			if (inputRef.current) {
				inputRef.current.value = '';
			}
			if (!adjuntos) {
				setAdjuntos(newAdjuntos);
				addAttachmentToProvider(newAdjuntos);
				return;
			}
			if (adjuntos?.length > 0) {
				setAdjuntos([...adjuntos, ...newAdjuntos]);
				addAttachmentToProvider([...adjuntos, ...newAdjuntos]);
				return;
			} else {
				setAdjuntos(newAdjuntos);
				addAttachmentToProvider(newAdjuntos);
				return;
			}
		}
	};

	const addAttachmentToProvider = (files: File[]) => {
		const haveFilesToDelete = movContext?.recepAndInstAttachs?.filter((file: any) => file.id);
		const Files: IRecepAndInstMovProvider[] = [];
		files.map((file: any) => {
			if (!file?.id) {
				const newFile: IRecepAndInstMovProvider = {
					idProperty: movContext?.editMovement?.property_registration?.id || 0,
					name: file.name,
					file: file,
					operation: 'create',
					key: 'attachments'
				};
				Files.push(newFile);
			}
		});
		if (haveFilesToDelete && haveFilesToDelete?.length > 0) {
			movContext?.setRecepAndInstAttachs([...haveFilesToDelete, ...Files]);
		} else {
			movContext?.setRecepAndInstAttachs([...Files]);
		}
	};

	const deleteAdjuntos = () => {
		if (adjuntos && adjuntos.length > 0) {
			Object.entries(adjuntos).map(([index, file]) => {
				deleteAttachUnique(index);
			});
		}

		if (inputRef.current) {
			inputRef.current.value = '';
		}
		if (movContext?.editMovement?.property_registration?.files && movContext?.editMovement?.property_registration?.files.length > 0) {
			const files = movContext?.editMovement?.property_registration?.files.map((file: any) => {
				if (file.file_type_id === FileType.Decretos) {
					return file;
				}
				return null;
			});
			if (files.length > 0) {
				const setFiles = files.filter((file: any) => file !== null);
				if (setFiles.length > 0) {
					setFiles.forEach((file: any) => {
						file.operation = 'delete';
						file.key = 'attachments';
					});
					movContext?.setRecepAndInstAttachs(setFiles);
				}
			}
		}
		setAdjuntos(null);
	};

	const deleteAttachUnique = (index: any) => {
		if (adjuntos && adjuntos[index] !== null) {
			const checkIfFromBE = adjuntos[index];
			if (checkIfFromBE?.id) {
				const newAttachment: IRecepAndInstMovProvider = {
					id: checkIfFromBE?.id,
					idProperty: checkIfFromBE?.property_id,
					name: checkIfFromBE?.name,
					operation: 'delete',
					key: 'attachments'
				};
				movContext?.setRecepAndInstAttachs((prev) => [newAttachment, ...prev]);
				const newAdjuntos = adjuntos.filter((file, index2) => index2 !== Number(index));
				setAdjuntos([...newAdjuntos]);
				return;
			}
		}
		if (adjuntos !== null) {
			const attachmentInProvider = movContext?.recepAndInstAttachs?.filter(
				(item) => item.name !== adjuntos[index]?.name && item.file?.lastModified !== adjuntos[index]?.lastModified
			);
			if (attachmentInProvider) {
				movContext?.setRecepAndInstAttachs(attachmentInProvider);
			}
			const newAdjuntos = adjuntos.filter((file, index2) => index2 !== Number(index));
			setAdjuntos([...newAdjuntos]);
		}
	};

	return (
		<Box sx={{ padding: '0px 3%', minHeight: '300px', pb: 3, pt: 3 }}>
			<Box>
				<Typography sx={{ fontWeight: '500' }}>Completa los siguientes campos.</Typography>
			</Box>
			<Box>
				<Grid
					container
					spacing={2}
					pt={3}>
					<Grid
						item
						xs={12}
						md={4}
						lg={4}>
						<Typography sx={{ fontWeight: '500', fontSize: '13px' }}>N° de Expediente</Typography>
						<Grid
							item
							xs={12}
							md={10}
							lg={10}
							onChange={handleExpediente}>
							<TextField
								name="codigo"
								size="small"
								value={expediente?.codigo || ''}
								sx={{ m: 1, ml: 0, width: '100%' }}
								label={'Código de Organismo'}
							/>
							<TextField
								size="small"
								value={expediente?.numero || ''}
								sx={{ m: 1, ml: 0 }}
								fullWidth
								name="numero"
								label={'Número'}
								type="text"
							/>
							<TextField
								size="small"
								value={expediente?.anio || ''}
								sx={{ m: 1, ml: 0 }}
								fullWidth
								name="anio"
								label={'Año'}
								type="text"
							/>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						lg={4}>
						<FormControl fullWidth>
							<Typography sx={{ fontWeight: '500', fontSize: '13px' }}>Intrumento Legal</Typography>
							<Grid
								item
								xs={12}
								md={10}
								lg={10}
								onChange={handleInstrumento}>
								<TextField
									name="numero"
									size="small"
									value={instrumentoLegal?.numero || ''}
									sx={{ m: 1, ml: 0 }}
									fullWidth
									label={'Número'}
									type="text"
								/>
								<TextField
									name="anio"
									size="small"
									value={instrumentoLegal?.anio || ''}
									sx={{ m: 1, ml: 0 }}
									fullWidth
									label={'Año'}
									type="text"
								/>
							</Grid>
						</FormControl>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						lg={4}>
						<FormControl fullWidth>
							<Typography sx={{ fontWeight: '500', fontSize: '13px' }}> Orden de Provisión</Typography>
							<Grid
								item
								xs={12}
								md={10}
								lg={10}
								onChange={handleOrdenProvision}>
								<TextField
									name="numero"
									size="small"
									value={ordenProvision?.numero || ''}
									sx={{ m: 1, ml: 0 }}
									fullWidth
									label={'Número'}
									type="text"
								/>

								<TextField
									name="anio"
									size="small"
									value={ordenProvision?.anio || ''}
									sx={{ m: 1, ml: 0 }}
									fullWidth
									label={'Año'}
									type="text"
								/>
							</Grid>
						</FormControl>
					</Grid>
					<Grid
						item
						xs={12}
						md={3}
						lg={3}>
						<Typography sx={{ fontWeight: '500', fontSize: '13px', whiteSpace: 'nowrap' }}>
							Adjuntá el Decreto, Resolución o Disposición
						</Typography>
						<FormControl
							variant="standard"
							sx={{ mb: 2, display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
							<OutlinedInput
								onChange={handleAdjuntos}
								size="small"
								sx={{ width: '250px', mt: 1 }}
								disabled
								placeholder="Cargar Archivos"
								autoComplete="off"
								endAdornment={
									<InputAdornment position="end">
										<Button
											sx={{
												backgroundColor: 'rgba(0, 0, 0, 0.05)',
												color: '#000',
												padding: '2.5px',
												minWidth: '30px',
												'&:hover': {
													backgroundColor: 'rgba(0, 0, 0, 0.05)'
												}
											}}
											variant="contained"
											component="label">
											<AttachFileIcon />
											<input
												ref={inputRef}
												multiple
												id="adjunto"
												name="adjunto"
												type="file"
												accept=".pdf,.jpg,.jpeg,.png"
												hidden
												onChange={handleAdjuntos}
											/>
										</Button>
									</InputAdornment>
								}></OutlinedInput>
							{adjuntos && adjuntos[0] !== undefined && (
								<Box sx={{ pt: 1 }}>
									<Tooltip
										placement="right-start"
										arrow
										color="blue"
										title="Eliminar todos los adjuntos">
										<DeleteIcon
											sx={{
												cursor: 'pointer',
												fontSize: '30px',
												backgroundColor: '#575757',
												color: '#fff',
												padding: '3px',
												borderRadius: '5px',
												m: 1,
												mt: 1.2,
												transition: 'all 0.2s ease-in-out',
												'&:hover': {
													transition: 'all 0.2s ease-in-out',
													backgroundColor: '#161616',
													color: '#fff',
													transform: 'scale(1.2)'
												}
											}}
											color="error"
											onClick={deleteAdjuntos}
										/>
									</Tooltip>
								</Box>
							)}
						</FormControl>
						{errorFile !== null && <FormHelperText>{errorFile}</FormHelperText>}
						<Grid sx={{ overflowY: 'auto', wordBreak: 'break-all', ml: 1 }}>
							{adjuntos &&
								Object.entries(adjuntos).map(([index, file]) => (
									<Grid
										item
										xs={12}
										md={10}
										lg={10}
										key={index}
										sx={{ display: 'flex', alignItems: 'center' }}>
										<Typography sx={{ fontWeight: '500', fontSize: '13px', color: '#000000', pr: '2px', whiteSpace: 'nowrap' }}>
											{Number(index) + 1}
											<ArticleIcon
												sx={{ ml: 1, mr: 0.2 }}
												color="primary"
											/>
										</Typography>
										<Typography sx={{ fontWeight: '500', fontSize: '13px' }}>{` - ${file.name}`}</Typography>
										<Tooltip
											placement="right-start"
											arrow
											color="blue"
											title="Eliminar adjunto">
											<DeleteIcon
												sx={{
													cursor: 'pointer',
													backgroundColor: '#575757',
													color: '#fff',
													padding: '3px',
													borderRadius: '5px',
													m: 1,
													mt: 1.2,
													transition: 'all 0.2s ease-in-out',
													'&:hover': {
														transition: 'all 0.2s ease-in-out',
														backgroundColor: '#161616',
														color: '#fff',
														transform: 'scale(1.2)'
													}
												}}
												color="error"
												onClick={() => deleteAttachUnique(index)}
											/>
										</Tooltip>
									</Grid>
								))}
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};
