import { ReactNode, useEffect, useState } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { PatrimonialDependenceContext } from './PatrimonialDependenceContext';
import { toast } from 'react-toastify';
import {
	tryGetAllPatrimonialDependences,
	tryCreateOrUpdatePatrimonialDependence,
	tryGetPatrimonialDependenceByID,
	tryDeletePatrimonialDependence,
	tryGetAllPatrimonialDependencesPaginated
} from 'services/patrimonialDependencesService';
import { useAppContext } from 'hooks/useAppContext';
import { ILinks, IMeta } from 'interfaces/commons/IResponse';
import { IPaginationRequest } from 'interfaces/commons/IParameters';
import { IPatrimonialDependence, IPatrimonialDependenceFilters } from 'interfaces/patrimonial/IPatrimonial';

const PATRIMONIAL_DEPENDENCE_FILTERS = 'PATRIMONIAL_DEPENDENCE_FILTERS';

export const PatrimonialDependenceState = ({ children }: { children: ReactNode | ReactJSXElement }) => {
	const appContext = useAppContext();

	useEffect(() => {
		// Get Search parameters
		const persistedValue = localStorage.getItem(PATRIMONIAL_DEPENDENCE_FILTERS);
		let searchFilters = { term: undefined } as IPatrimonialDependenceFilters;
		if (persistedValue !== null) {
			searchFilters = JSON.parse(persistedValue);
		}
		setFilters(searchFilters);
	}, []);

	// START Get All Patrimonial Dependences
	const [entities, setEntities] = useState<IPatrimonialDependence[]>([] as IPatrimonialDependence[]);
	const [meta, setMeta] = useState<IMeta>({} as IMeta);
	const [links, setLinks] = useState<ILinks>({} as ILinks);

	const onHandleGetAll = async ({ term }: IPatrimonialDependenceFilters, { page, limit }: IPaginationRequest) => {
		setEntities([]);
		appContext?.onHandleShowSpinner();
		const response = await tryGetAllPatrimonialDependencesPaginated({ term }, { page, limit });
		appContext?.onHandleHideSpinner();

		if (response.hasOwnProperty('error')) {
			let message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'Ocurrio un error al recuperar las dependencias patrimoniales!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		setEntities(response.items);
		setMeta(response.meta);
		setLinks(response.links);
		toast.success('Dependencias Patrimoniales recuperadas!');
		return true;
	};
	// END Get All Patrimonial Dependences

	// START Filter Patrimonial Dependence
	const storagedFilters = localStorage.getItem(PATRIMONIAL_DEPENDENCE_FILTERS);
	const [filters, setFilters] = useState<IPatrimonialDependenceFilters>(
		storagedFilters ? JSON.parse(storagedFilters) : ({ term: undefined } as IPatrimonialDependenceFilters)
	);

	const onHandleSearByFilters = async ({ term = '' }: IPatrimonialDependenceFilters) => {
		if (term && term !== '') {
			setFilters({ term });
			localStorage.setItem(PATRIMONIAL_DEPENDENCE_FILTERS, JSON.stringify({ term }));
			return onHandleGetAll({ term }, { page: 1, limit: meta.itemsPerPage });
		}
		return false;
	};

	const onHandleClearFilters = async () => {
		setFilters({ term: undefined } as IPatrimonialDependenceFilters);
		localStorage.removeItem(PATRIMONIAL_DEPENDENCE_FILTERS);
		return onHandleGetAll({ term: undefined }, { page: 1, limit: meta.itemsPerPage });
	};
	// END Filter Patrimonial Dependence

	// START Handle Change Page
	const onHandleChangePage = async ({ page = 1, limit = meta.itemsPerPage }: IPaginationRequest) => {
		return await onHandleGetAll({ ...filters }, { page, limit });
	};
	// END Handle Change Page

	// START Create or Update Patrimonial Dependence
	const onHandleCreateOrUpdate = async (entity: IPatrimonialDependence) => {
		if (!entity) {
			toast.error('Debe indicar una nueva dependencia patrimonial para poder agregarla!');
			return false;
		}

		appContext?.onHandleShowSpinner();
		const response = await tryCreateOrUpdatePatrimonialDependence(entity);
		appContext?.onHandleHideSpinner();

		if (response.hasOwnProperty('error')) {
			let message = response?.success
				? 'Ocurrio un error al guardar los datos de la dependencia patrimonial!'
				: 'Respuesta del servidor: '
						.concat(Array.isArray(response?.messages) ? response?.messages[0] : response?.message)
						.concat('!');
			toast.error(message);
			console.error(response.error);
			return false;
		}

		toast.success('Dependencia Patrimonial guardada exitosamente.');
		return true;
	};
	// END Create or Update Patrimonial Dependence

	// START Delete Patrimonial Dependence
	const onHandleDelete = async (id: number | null) => {
		if (id == null) {
			toast.error('Debe indicar una dependencia patrimonial para poder eliminarla!');
			return false;
		}

		appContext?.onHandleShowSpinner();
		const response = await tryDeletePatrimonialDependence(id);
		appContext?.onHandleHideSpinner();

		if (response.hasOwnProperty('error')) {
			let message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'Ocurrio un error al eliminar la dependencia patrimonial!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		toast.success('Dependencia Patrimonial eliminada exitosamente.');
		return true;
	};
	// END Delete Patrimonial Dependence

	// START Get Data of Patrimonial Dependence
	const onHandleGetData = async (id: number | null, showMessage: boolean = true) => {
		if (id == null) {
			toast.error('Debe indicar una dependencia patrimonial para poder recuperar sus datos!');
			return false;
		}

		appContext?.onHandleShowSpinner();
		const response = await tryGetPatrimonialDependenceByID(id);
		appContext?.onHandleHideSpinner();

		if (response.hasOwnProperty('error')) {
			let message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'Ocurrio un error al recuperar datos de la dependencia patrimonial!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		if (showMessage) {
			toast.success('Dependencia Patrimonial recuperada exitosamente.');
		}
		return true;
	};
	// END Get Data of Patrimonial Dependence

	return (
		<PatrimonialDependenceContext.Provider
			value={{
				entities,
				meta,
				links,
				onHandleGetAll,
				onHandleSearByFilters,
				onHandleClearFilters,
				filters,
				onHandleChangePage,
				onHandleCreateOrUpdate,
				onHandleDelete,
				onHandleGetData
			}}>
			{children}
		</PatrimonialDependenceContext.Provider>
	);
};
