import { IStep3PropertyCatastroValues, IStep3PropertyValues } from '../interfaces';

export function ValidateCatastroInf(data: IStep3PropertyCatastroValues): boolean {
	if (data?.department === undefined) return false;
	if (data?.municipality === undefined) return false;
	if (data?.section === undefined) return false;
	if (data?.ranch === undefined) return false;
	if (data?.apple === undefined) return false;
	if (data?.parcel === undefined) return false;
	if (data?.realStateItem === undefined) return false;
	if (data?.department?.trim().length === 0) {
		return false;
	}
	if (data?.municipality?.trim().length === 0) {
		return false;
	}
	if (data?.section?.trim().length === 0) {
		return false;
	}
	if (data?.ranch?.trim().length === 0) {
		return false;
	}
	if (data?.apple?.trim().length === 0) {
		return false;
	}
	if (data?.parcel?.trim().length === 0) {
		return false;
	}
	if (data?.realStateItem?.trim().length === 0) {
		return false;
	}
	return true;
}

export function ValidateProperty(
	dataFromChild: IStep3PropertyValues,
	nomenclatureCode: string,
	quantity: string,
	unitPrice: string,
	address: string,
	attachmentFromChild: File | null,
	propertyTitleSwitch: boolean
): boolean {
	if (propertyTitleSwitch) {
		if (attachmentFromChild?.name) {
			if (attachmentFromChild?.name?.trim().length === 0) {
				return false;
			}
		} else {
			return false;
		}
	}
	if (nomenclatureCode === undefined || nomenclatureCode.trim().length === 0) {
		return false;
	}
	if (quantity === undefined || quantity.trim().length === 0) {
		return false;
	}
	if (unitPrice === undefined || unitPrice.trim().length === 0) {
		return false;
	}
	/* if (address === undefined || address.trim().length === 0) {
		return false;
	} */
	if (ValidateCatastroInf(dataFromChild.catastroValues) === false) {
		return false;
	}
	return true;
}
