/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { IStep3PropertyValues, IStep3PropertyCatastroValues, IStep3PropertyRegisterInformation } from '../interfaces';

import { Button, FormControl, Box, Typography, SelectChangeEvent } from '@mui/material';
import { InputGeneric } from 'components/globals/inputs/InputGeneric';
import useDebounce from 'hooks/useDebounce';
import { DescriptionInput } from '../generico/descriptionInput/DescriptionInput';

export interface IPaso3AutomotoresProps {
	selectedRow?: any | null;
	submited: boolean;
	dataFromChild: IStep3PropertyValues;
	setDataFromChild: (data: SetStateAction<IStep3PropertyValues>) => void;
}

export const RegisterInf = ({ selectedRow, submited, dataFromChild, setDataFromChild }: IPaso3AutomotoresProps) => {
	const [registerData, setRegisterData] = useState<IStep3PropertyRegisterInformation>({} as IStep3PropertyRegisterInformation);
	const [department, setDepartment] = useState(selectedRow?.registerInformation?.department || '');
	const [domainNumber, setDomainNumber] = useState(selectedRow?.registerInformation?.domainNumber || '');
	const [volumeFolder, setVolumeFolder] = useState(selectedRow?.registerInformation?.volumeFolder || '');
	const [folioFolder, setFolioFolder] = useState(selectedRow?.registerInformation?.folioFolder || '');
	const [estate, setEstate] = useState(selectedRow?.registerInformation?.estate || '');
	const [propertyDescription, setPropertyDescription] = useState(selectedRow?.registerInformation?.propertyDescription || '');
	const [propertyOwner, setPropertyOwner] = useState(selectedRow?.registerInformation?.propertyOwner || '');
	const [observations, setObservations] = useState(selectedRow?.registerInformation?.observations || '');
	const debouncedValue = useDebounce(registerData, 400);

	useEffect(() => {
		if (submited) {
			setRegisterData({} as IStep3PropertyRegisterInformation);
			setDepartment('');
			setDomainNumber('');
			setVolumeFolder('');
			setFolioFolder('');
			setEstate('');
			setPropertyDescription('');
			setPropertyOwner('');
			setObservations('');
		}
	}, [submited]);

	const handleAdd = (evt: any) => {
		evt.preventDefault();
		setRegisterData({ ...registerData, [evt.target.name]: evt.target.value });
	};

	useEffect(() => {
		setDataFromChild({ ...dataFromChild, registerInformation: { ...registerData } });
	}, [debouncedValue]);

	useEffect(() => {
		if (selectedRow) {
			const newItem = { department, domainNumber, volumeFolder, folioFolder, estate, propertyDescription, propertyOwner, observations };
			setRegisterData(newItem);
		}
	}, [selectedRow]);

	return (
		<div className="borderedBox">
			<Typography
				sx={{ pb: 1, pt: 3 }}
				variant="h6">
				Información Registral
			</Typography>
			<Box
				onChange={handleAdd}
				sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '55px', rowGap: '55px' }}>
				<InputGeneric
					stateValue={department}
					label="Departamento"
					name="department"
					setStateValue={setDepartment}
				/>
				<InputGeneric
					stateValue={domainNumber}
					label="N° de Matrícula"
					name="domainNumber"
					setStateValue={setDomainNumber}
				/>
				<InputGeneric
					stateValue={volumeFolder}
					label="Tomo"
					name="volumeFolder"
					setStateValue={setVolumeFolder}
				/>
				<InputGeneric
					stateValue={folioFolder}
					label="Folio"
					name="folioFolder"
					setStateValue={setFolioFolder}
				/>
				<InputGeneric
					stateValue={estate}
					label="Finca"
					name="estate"
					setStateValue={setEstate}
				/>
				<InputGeneric
					stateValue={propertyDescription}
					label="Descripción del Inmueble"
					name="propertyDescription"
					setStateValue={setPropertyDescription}
				/>
				<InputGeneric
					stateValue={propertyOwner}
					label="Propietario del Inmueble"
					name="propertyOwner"
					setStateValue={setPropertyOwner}
				/>

				<DescriptionInput
					value={observations}
					submited={submited}
					name="observations"
					label={'Observaciones'}
					setDescription={setObservations}
					placeholder={'Agregue una observación'}
				/>
			</Box>
		</div>
	);
};
