import { HeaderForm } from '../../components/globals/headerForm/HeaderForm';
import { memo, useEffect, useState } from 'react';
import { useBalanceContext } from 'hooks/useBalanceContext';
import { useAppContext } from 'hooks/useAppContext';
import { Spinner } from 'components/globals/spinner/Spinner';
import { Box, Grid, Divider } from '@mui/material';
import { FormGenericSelect } from 'components/globals/inputs/FormGenericSelect';
import { ISelectOption } from 'interfaces/commons/IParameters';
import { DEFAULT_NO_ERROR, requiredValidator } from 'helpers/Validators/Validators';
import { ButtonConfirm, ButtonDecline } from 'components/globals/customsBtn/customBtn';
import { useNavigate } from 'react-router-dom';
import { IJurisdiction } from 'interfaces/jurisdictions/IJurisdictions';
import lodash from 'lodash';
import { BalanceSummaryComponent } from 'components/balanceSummary/BalanceSummaryComponent';
import { IBalanceSummaryComponentProps } from 'interfaces/balance/IBalance';

export interface IErrorsBalanceSummaryForm {
	jurisdiction?: string | undefined;
}

export const LABELS_BALANCE_SUMMARY_FORM = {
	jurisdiction: 'Jurisdicción'
};

const BalanceSummaryPage = memo(() => {
	const balanceContext = useBalanceContext();
	const appContext = useAppContext();
	const navigate = useNavigate();

	const [summary, setSummary] = useState<IBalanceSummaryComponentProps | undefined>(undefined);
	const [jurisdictions, setJurisdictions] = useState<ISelectOption[]>([]);
	const [selectedJurisdiction, setSelectedJurisdiction] = useState<ISelectOption | undefined>(undefined);
	const [jurisdiction, setJurisdiction] = useState<IJurisdiction | undefined>(undefined);
	const [errors, setErrors] = useState<IErrorsBalanceSummaryForm>({} as IErrorsBalanceSummaryForm);

	useEffect(() => {
		appContext?.onHandleTryGetAllJurisdictions();
	}, []);

	useEffect(() => {
		setJurisdictions(
			appContext?.jurisdictions.map((j) => {
				return {
					id: j.id,
					label: j.name
				};
			})
		);
	}, [appContext?.jurisdictions]);

	const validateForm = (): boolean => {
		let newErrors: IErrorsBalanceSummaryForm = {} as IErrorsBalanceSummaryForm;
		setErrors(newErrors);

		let jurisdictionError = requiredValidator({
			value: selectedJurisdiction?.label,
			label: LABELS_BALANCE_SUMMARY_FORM.jurisdiction
		});
		if (jurisdictionError !== DEFAULT_NO_ERROR) {
			newErrors.jurisdiction = jurisdictionError;
		}

		if (Object.entries(newErrors).length !== 0) {
			setErrors(newErrors);
			return false;
		}

		return true;
	};

	const onHandleSearch = async () => {
		if (!validateForm()) {
			return false;
		}
		let jurisdiction = appContext?.jurisdictions?.find((j) => {
			return j.id === selectedJurisdiction?.id;
		});
		setJurisdiction(jurisdiction);
		await balanceContext?.onHandleTryGetBalanceSummary(jurisdiction, {
			page: 1,
			limit: 10
		});
	};

	useEffect(() => {
		setSummary(undefined);
	}, [selectedJurisdiction]);

	useEffect(() => {
		setSummary(balanceContext?.summary);
	}, [balanceContext?.summary]);

	const onHandleCancel = () => {
		navigate('/home');
	};

	// START Search Jurisdictions by Text Term
	const [jurisdictionsSearchTerm, setJurisdictionsSearchTerm] = useState<string>('');
	const debounceInputJuridictions = lodash.debounce((e) => setJurisdictionsSearchTerm(e), 1000);
	useEffect(() => {
		if (jurisdictionsSearchTerm !== undefined && jurisdictionsSearchTerm !== null && jurisdictionsSearchTerm !== '') {
			appContext?.onHandleTryGetAllJurisdictions();
		}
	}, [jurisdictionsSearchTerm]);
	// END Search Jurisdictions by Text Term

	const handleDownloadComplete = async () => {
		await balanceContext?.onHandleTryDownloadAnualBalanceSummary();
	};

	const handleDownloadPDF = async () => {
		let jurisdiction = appContext?.jurisdictions?.find((j) => {
			return j.id === selectedJurisdiction?.id;
		});
		await balanceContext?.onHandleTryDownloadByJurisdictionBalanceSummary(jurisdiction);
	};

	return (
		<>
			{appContext?.showSpinner ? <Spinner /> : null}

			<HeaderForm title={'Balance Anual'} />

			<Grid
				container
				spacing={2}
				mt={1}
				mb={1}
				justifyContent="flex-end">
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
					xl={6}
					lg={6}>
					<FormGenericSelect
						id={'jurisdiction'}
						value={selectedJurisdiction}
						label={'Seleccione una '.concat(LABELS_BALANCE_SUMMARY_FORM.jurisdiction)}
						handleChange={setSelectedJurisdiction}
						handleInputChange={debounceInputJuridictions}
						options={jurisdictions}
						error={errors.jurisdiction}
						isLoading={appContext?.isLoadingJurisdictions}
						disabled={true}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					md={2}
					xl={2}
					lg={2}>
					<ButtonConfirm
						sx={{ width: '100%' }}
						variant="contained"
						onClick={() => onHandleSearch()}
						disabled={true}>
						Buscar
					</ButtonConfirm>
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					md={4}
					xl={4}
					lg={4}>
					<Grid
						container
						spacing={2}>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							xl={12}
							lg={12}>
							<ButtonConfirm
								sx={{ width: '100%' }}
								variant="contained"
								onClick={() => handleDownloadComplete()}>
								Descargar Balance Completo
							</ButtonConfirm>
						</Grid>

						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							xl={12}
							lg={12}>
							<ButtonConfirm
								sx={{ width: '100%' }}
								variant="contained"
								onClick={() => handleDownloadPDF()}
								disabled={true}>
								Descargar PDF
							</ButtonConfirm>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Divider className="customDividerLayout" />

			{/* <BalanceSummaryComponent
				totals={summary?.totals}
				details={summary?.details}
				key={'BalanceSummaryComponent'}
			/> */}

			<Box
				sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
				<ButtonDecline
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => {
						onHandleCancel();
					}}>
					Volver
				</ButtonDecline>
			</Box>
		</>
	);
});

BalanceSummaryPage.displayName = 'Balance Resumen';

export { BalanceSummaryPage };
