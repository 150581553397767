export enum PropertyType {
	Acquisition = 1,
	Transfer = 2,
	Donation = 3
}

export const TypeOfVoucher = {
	1: 'Factura',
	2: 'Remito'
};
