/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { FormGenericInput } from 'components/globals/inputs/FormGenericInput';
import lodash from 'lodash';
import { MAX_AGENCY_CODE_VALUE, MAX_NUMBER_VALUE, isValidNumber, isValidYear } from 'components/bajas/forms/stepOne';
import { REGISTER_TYPES_ID } from 'enums/itemType/itemType';

export interface IExpedient {
	agency_code: string | undefined;
	number: string | undefined;
	year: string | undefined;
	hasErrors: boolean;
}

export interface IOverviewExpedientForm {
	agencyCode?: string | undefined;
	number?: string | undefined;
	year?: string | undefined;
	title?: string;
	handleChange(data: IExpedient): void;
	disabled?: boolean;
	required?: boolean;
	validateYear?: boolean;
	validateProcedureAgencyCode?: boolean;
	validateProcedureNumber?: boolean;
	onError?(errors: IErrorsExpedientForm): void;
}

export interface IErrorsExpedientForm {
	procedure_agency_code?: string | undefined;
	procedure_number?: string | undefined;
	procedure_year?: string | undefined;
}

export const LABELS_EXPEDIENT_FORM = {
	procedure: 'Expediente de Baja',
	procedure_agency_code: 'Código de Organismo',
	procedure_number: 'Número',
	procedure_year: 'Año'
};

export const ExpedientForm = ({
	agencyCode,
	number,
	year,
	title = LABELS_EXPEDIENT_FORM.procedure,
	handleChange,
	disabled,
	required,
	validateYear,
	validateProcedureAgencyCode,
	validateProcedureNumber,
	onError
}: IOverviewExpedientForm) => {
	const [procedureAgencyCode, setProcedureAgencyCode] = useState<string | undefined>();
	const [procedureNumber, setProcedureNumber] = useState<string | undefined>();
	const [procedureYear, setProcedureYear] = useState<string | undefined>();
	const [dataFormHasChnaged, setDataFormHasChnaged] = useState<number>(0);
	const stepOneData = JSON.parse(localStorage.getItem('STORAGED_REGISTER_STEP_ONE') || '{}');
	useEffect(() => {
		setProcedureAgencyCode(agencyCode);
	}, [agencyCode]);

	useEffect(() => {
		setProcedureNumber(number);
	}, [number]);

	useEffect(() => {
		setProcedureYear(year);
	}, [year]);

	const getParsedData = (hasErrors = false): IExpedient => {
		return {
			agency_code: procedureAgencyCode,
			number: procedureNumber,
			year: procedureYear,
			hasErrors: hasErrors
		};
	};

	const yearError = useMemo(() => {
		const year = Number(procedureYear);
		return !isValidYear(year) && dataFormHasChnaged && validateYear ? ' ' : undefined;
	}, [procedureYear]);

	const agencyCodeError = useMemo(() => {
		return validateProcedureAgencyCode &&
			procedureAgencyCode &&
			!isValidNumber(procedureAgencyCode, MAX_AGENCY_CODE_VALUE)
			? 'Ingrese un código de organismo válido'
			: undefined;
	}, [procedureAgencyCode]);

	const procedureNumberError = useMemo(() => {
		return validateProcedureNumber && procedureNumber && !isValidNumber(procedureNumber, MAX_NUMBER_VALUE)
			? 'Ingrese un número de expediente válido'
			: undefined;
	}, [procedureNumber]);

	const debounceAgencyCode = lodash.debounce((e) => {
		setProcedureAgencyCode(e == '' ? undefined : e);
		setDataFormHasChnaged(dataFormHasChnaged + 1);
	}, 300);

	const debounceNumber = lodash.debounce((e) => {
		setProcedureNumber(e == '' ? undefined : e);
		setDataFormHasChnaged(dataFormHasChnaged + 1);
	}, 300);

	const debounceYear = lodash.debounce((e) => {
		setProcedureYear(e == '' ? undefined : e);
		setDataFormHasChnaged(dataFormHasChnaged + 1);
	}, 300);

	useEffect(() => {
		if (dataFormHasChnaged) handleChange(getParsedData());
	}, [dataFormHasChnaged]);

	useEffect(() => {
		onError?.({
			procedure_agency_code: agencyCodeError,
			procedure_year: yearError,
			procedure_number: procedureNumberError
		});
	}, [procedureNumberError, agencyCodeError, yearError]);

	const isRequired =
		title !== LABELS_EXPEDIENT_FORM.procedure
			? stepOneData.high_type_id === REGISTER_TYPES_ID.ACQUISITION
				? ''
				: '*'
			: '';

	return (
		<Grid
			container
			spacing={2}>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}>
				<Typography variant="subtitle2">{title}</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}>
				<FormGenericInput
					disabled={disabled}
					value={procedureAgencyCode}
					label={`${LABELS_EXPEDIENT_FORM.procedure_agency_code} ${isRequired}`}
					name="procedureAgencyCode"
					id="procedureAgencyCode"
					type="number"
					handleChange={debounceAgencyCode}
					error={agencyCodeError}
					size={'medium'}
					required={required}
					maxLength={4}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}>
				<FormGenericInput
					disabled={disabled}
					value={procedureNumber}
					label={`${LABELS_EXPEDIENT_FORM.procedure_number}${isRequired}`}
					name="procedureNumber"
					id="procedureNumber"
					type="number"
					handleChange={debounceNumber}
					error={procedureNumberError}
					size={'medium'}
					required={required}
					maxLength={5}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}>
				<FormGenericInput
					disabled={disabled}
					maxLength={4}
					value={procedureYear}
					label={`${LABELS_EXPEDIENT_FORM.procedure_year}${isRequired}`}
					name="procedureYear"
					id="procedureYear"
					type="number"
					handleChange={debounceYear}
					error={yearError}
					size={'medium'}
					required={required}
				/>
			</Grid>
		</Grid>
	);
};
