import { SetStateAction, useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import lodash from 'lodash';
import { useMovContext } from 'hooks/useMovContext';
import { toast } from 'react-toastify';
import { NomenclatureCode } from 'components/globals/nomenclatureCode/nomenClatureCode';

import { QuantityEditInput } from '../../quantityInput/QuantityEditInput';
import { UnitPriceInput } from '../../unitPriceInput/UnitPriceInput';
import { CatastroInf } from '../../inmueble/CatastroInf';
import { RegisterInf } from '../../inmueble/RegisterInf';
import { InputGeneric } from 'components/globals/inputs/InputGeneric';
import { InputAttachGenForEdit } from '../../inputAttachGenForEdit/InputAttachGenForEdit';
import { ValidateProperty } from '../../inmueble/validateProperty';

import { DescriptionInput } from '../../generico/descriptionInput/DescriptionInput';

// Interfaces
import { IStep3PropertyValues, IStep3Rights } from '../../interfaces';
import { IEditItemModalProps } from '../EditItemModal';

//Material UI
import { BounceLoader } from 'react-spinners';
import { Box, Button, Modal, FormControlLabel, Switch, Typography } from '@mui/material';

const styleModal = {
	position: 'absolute' as const,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '50%',
	minWidth: '532px',
	maxHeight: '95%',
	bgcolor: '#e4e4e4',
	border: '2px solid #000000b9',
	boxShadow: 24,
	paddingLeft: '30px',
	paddingRight: '30px',
	overflowY: 'auto' as const
};

export const EditItemProp = ({ openModal, setOpenModal, rightList, setRightList, selectedRow, setSelectedRow }: IEditItemModalProps) => {
	const [dataFromChild, setDataFromChild] = useState<IStep3PropertyValues>({} as IStep3PropertyValues);
	const [nomenclatureCode, setNomenclatureCode] = useState(selectedRow?.nomenclatureCode);
	const [nomenclatureCodeId, setNomenclatureCodeId] = useState(selectedRow?.nomenclatureCodeId || 0);
	const [quantity, setQuantity] = useState(String(selectedRow?.quantity));
	const [unitPrice, setUnitPrice] = useState(selectedRow?.unitPrice);
	const [address, setAddress] = useState(selectedRow?.address);
	const [attachmentFromChild, setAttachmentFromChild] = useState<File | null>(null);
	// ADAPTAR ESTO CUANDO RAMON CREE EL CAMPO MOTIVO EN EL BACKEND
	const [observationPropertyTitle, setObservationPropertyTitle] = useState(selectedRow?.observationPropertyTitle || '');
	const [titleOfPatrimonialDependency, setTitleOfPatrimonialDependency] = useState(selectedRow.titleOfPatrimonialDependency);
	const [propertyTitleSwitch, setPropertyTitleSwitch] = useState(selectedRow.propertyTitle);
	const [disabled, setDisabled] = useState(true);
	const [submited, setSubmited] = useState(false);
	const [uuid, setUuid] = useState(selectedRow.id);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [opacity, setOpacity] = useState(false);
	const movContext = useMovContext();

	const rightType = 'Inmueble';

	// Modal edit
	const [editProTitle, setEditProTitle] = useState(false);

	useEffect(() => {
		if (movContext?.itemSelected === null) {
			setEditProTitle(true);
			return;
		}
		const editMovement = movContext?.editMovement;
		let hasAttachmentInDB = false;
		let hasAttachmentInLocal = false;
		if (editMovement) {
			// Check in context
			const attachmentsInContext = movContext?.attachments;
			if (attachmentsInContext && attachmentsInContext?.length > 0) {
				const searchAttachment = attachmentsInContext.find((attachment) => attachment?.id === movContext?.itemSelected?.id);
				if (searchAttachment && searchAttachment?.operation === 'create' && movContext?.itemSelected !== null) {
					hasAttachmentInLocal = true;
				}
				if (searchAttachment && searchAttachment?.operation === 'delete' && movContext?.itemSelected !== null) {
					hasAttachmentInLocal = false;
				}
			}
			// Check the movement first.
			const files = editMovement?.property_registration?.files;
			if (files && files?.length > 0) {
				const searchPropertyTitle = files.find((file) => file?.temporary_id === movContext?.itemSelected?.id);
				if (!searchPropertyTitle) {
					hasAttachmentInDB = false;
				} else {
					hasAttachmentInDB = true;
				}
			} else {
				hasAttachmentInDB = false;
			}
			setEditProTitle(hasAttachmentInDB || hasAttachmentInLocal);
		}
	}, []);

	const memoizedDataFromChild = useCallback((data: SetStateAction<IStep3PropertyValues>) => {
		setDataFromChild(data);
	}, []);

	const resetValues = () => {
		setNomenclatureCode('');
		setNomenclatureCodeId(0);
		setQuantity('');
		setUnitPrice('');
		setAddress('');
		setTitleOfPatrimonialDependency(false);
		setAttachmentFromChild(null);
		setObservationPropertyTitle('');
	};

	const handleSubmit = () => {
		const newItem = {
			rightType,
			id: uuid,
			nomenclatureCode,
			nomenclatureCodeId,
			quantity,
			unitPrice,
			...dataFromChild,
			address,
			propertyTitle: propertyTitleSwitch,
			observationPropertyTitle,
			titleOfPatrimonialDependency,
			operation: 'update',
			accessories: selectedRow.accessories
		};
		const list = rightList.filter((item) => item.id !== selectedRow?.id);
		const itemForContext = movContext?.itemsFromTable.find((item) => item.id === selectedRow?.id);
		if (itemForContext) {
			const listOfItemsContext = movContext?.itemsFromTable.filter((item) => item.id !== selectedRow?.id);
			if (listOfItemsContext) {
				listOfItemsContext.push(newItem);
				movContext?.setItemsFromTable(listOfItemsContext);
			}
		} else {
			movContext?.setItemsFromTable((prev) => [...prev, newItem]);
		}
		setRightList([newItem, ...list]);
		setSubmited(true);
		handleLoading();
		resetValues();
	};

	const debouncedSubmit = lodash.debounce(handleSubmit, 1000);

	useEffect(() => {
		const valid = ValidateProperty(dataFromChild, nomenclatureCode, quantity, unitPrice, address, attachmentFromChild, propertyTitleSwitch);
		setDisabled(!valid);
	}, [debouncedSubmit, nomenclatureCode, quantity, unitPrice, address]);

	const handleClose = () => {
		setOpen(false);
		setOpenModal(false);
	};

	const handleLoading = () => {
		setLoading(true);
		setTimeout(() => {
			setOpacity(true);
		}, 300);
		setTimeout(() => {
			handleClose();
			toast('Bien editado correctamente', { type: 'success', autoClose: 2000 });
		}, 2000);
	};

	const handleDeleteAttachment = () => {
		let hasAttachmentInDB = false;

		const editMovement = movContext?.editMovement;
		const files = editMovement?.property_registration?.files;
		if (files && files?.length > 0) {
			const searchPropertyTitle = files.find((file) => file?.temporary_id === movContext?.itemSelected?.id);
			if (!searchPropertyTitle) {
				hasAttachmentInDB = false;
			} else {
				hasAttachmentInDB = true;
			}
		} else {
			hasAttachmentInDB = false;
		}
		///////
		const attachmentsInContext = movContext?.attachments;
		if (attachmentsInContext && attachmentsInContext?.length >= 0) {
			const attachments = attachmentsInContext.filter((attachment) => attachment?.id !== movContext?.itemSelected?.id);
			if (hasAttachmentInDB) {
				attachments.push({ id: movContext?.itemSelected?.id, operation: 'delete' });
			} else {
				movContext?.setAttachments(attachments);
			}
		}
		toast.success('El titulo será eliminado después de guardar el movimiento');
		setEditProTitle(false);
	};

	return (
		<div>
			<Modal
				keepMounted
				open={openModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<Box sx={styleModal}>
					<div style={{ marginBottom: '40px' }}>
						<div style={{ marginTop: '45px', display: 'flex', columnGap: '15px', flexWrap: 'wrap' }}>
							<NomenclatureCode
								value={nomenclatureCode}
								submited={submited}
								setNomenclatureCode={setNomenclatureCode}
								setNomenclatureCodeId={setNomenclatureCodeId}
							/>
							<QuantityEditInput
								value={quantity}
								style={{ marginLeft: '15px' }}
								submited={submited}
								setQuantity={setQuantity}
							/>
							<UnitPriceInput
								value={unitPrice}
								submited={submited}
								setUnitPrice={setUnitPrice}
							/>
						</div>
						<div style={{ marginTop: '40px' }}>
							<hr
								className="solid"
								style={{ borderTop: '1px solid #5c5c5c71' }}
							/>
						</div>
						<div>
							<div style={{ display: 'flex', alignItems: 'center', marginTop: '-15px' }}>
								<InputGeneric
									stateValue={address}
									label="Dirección"
									name="address"
									setStateValue={setAddress}
									sx={{ width: '400px !important' }}
								/>
								<div style={{ width: '100%' }}>
									<FormControlLabel
										control={
											<Switch
												checked={titleOfPatrimonialDependency}
												name="propertyTitleSwitch"
												onClick={() => setTitleOfPatrimonialDependency(!titleOfPatrimonialDependency)}
											/>
										}
										label="Título a Nombre de Dependencia Patrimonial"
										sx={{ width: '100%', m: '10px', mb: '20px' }}
									/>
								</div>
							</div>
							<CatastroInf
								selectedRow={selectedRow}
								submited={submited}
								dataFromChild={dataFromChild}
								setDataFromChild={memoizedDataFromChild}
							/>
							<RegisterInf
								selectedRow={selectedRow}
								submited={submited}
								dataFromChild={dataFromChild}
								setDataFromChild={memoizedDataFromChild}
							/>
							<div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }}>
								<div style={{ width: '100%', marginBottom: '20px' }}>
									<FormControlLabel
										control={
											<Switch
												checked={propertyTitleSwitch}
												name="propertyTitleSwitch"
												onClick={() => setPropertyTitleSwitch(!propertyTitleSwitch)}
											/>
										}
										label="Título de Propiedad"
										sx={{ width: '100%', m: '10px', mb: '20px' }}
									/>
								</div>
								{propertyTitleSwitch ? (
									<>
										{!editProTitle ? (
											<InputAttachGenForEdit
												setFileGen={setAttachmentFromChild}
												setUuid={setUuid}
												uuid={uuid}
												submited={submited}
												setSubmited={setSubmited}
											/>
										) : (
											<Box sx={{ width: '100%', flexDirection: 'column' }}>
												<Typography sx={{ wordBreak: 'break-word', width: '300px', fontWeight: '600', mb: 1 }}>
													* El vehículo actualmente posee un titulo de propiedad adjunto.
												</Typography>
												<Box sx={{ display: 'flex', flexDirection: 'column', width: '350px', rowGap: '10px' }}>
													<Button
														variant="contained"
														color="primary"
														onClick={() => setEditProTitle(false)}>
														¿Desea editar el titulo de propiedad?
													</Button>
													<Button
														variant="contained"
														color="secondary"
														onClick={handleDeleteAttachment}>
														¿Desea eliminar el titulo de propiedad?
													</Button>
												</Box>
											</Box>
										)}
										{!editProTitle && (
											<Typography sx={{ color: '#d32f2f', fontSize: '12px', ml: 1, mt: 1, position: 'relative', width: '100%' }}>
												*Requerido
											</Typography>
										)}
									</>
								) : (
									<DescriptionInput
										value={observationPropertyTitle}
										submited={submited}
										setSubmited={setSubmited}
										setDescription={setObservationPropertyTitle}
										name="observationPropertyTitle"
										label="Motivo"
										placeholder="Indique el Motivo"
									/>
								)}
							</div>
						</div>
						<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
							<Button
								disabled={disabled}
								sx={{ mt: 4 }}
								variant="contained"
								onClick={debouncedSubmit}>
								Guardar cambios
							</Button>
							<Button
								sx={{ mt: 4, ml: 2 }}
								variant="contained"
								color="secondary"
								onClick={handleClose}>
								Cancelar
							</Button>
						</div>
						<hr
							className="solid"
							style={{ borderTop: '1px solid #5c5c5c71', marginBottom: '-20px' }}
						/>
						{loading && (
							<Box
								sx={{
									zIndex: '9999',
									opacity: opacity ? '1' : '0',
									position: 'absolute',
									backdropFilter: 'blur(2px)',
									height: '170%',
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									transition: 'all 700ms ease-in-out',
									top: '0',
									left: '0'
								}}>
								<BounceLoader
									size={100}
									speedMultiplier={1.5}
								/>
							</Box>
						)}
					</div>
				</Box>
			</Modal>
		</div>
	);
};
