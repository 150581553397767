import { FC, Dispatch, SetStateAction, useState, useEffect } from 'react';

import { Autocomplete, FormControl, FormHelperText, TextField } from '@mui/material';
import { ISelectOption } from 'interfaces/commons/IParameters';

interface IInputTextProps {
	value?: ISelectOption;
	handleChange: Dispatch<SetStateAction<any>>;
	handleInputChange?: Dispatch<SetStateAction<any>>;
	sx?: object;
	id: string;
	label?: string;
	required?: boolean;
	disabled?: boolean;
	hint?: string;
	fullWidth?: boolean;
	error?: string;
	startAdornment?: React.ReactNode;
	endAdornment?: React.ReactNode;
	options: ISelectOption[];
	size?: 'small' | 'medium' | undefined;
	isLoading?: boolean;
	freeSolo?: boolean;
}

const defaultOption = '';

export const FormGenericSelect: FC<IInputTextProps> = ({
	value,
	handleChange,
	handleInputChange = () => void 0,
	label,
	required = true,
	id = 'genericSelect',
	disabled,
	hint,
	fullWidth = true,
	error,
	options = [],
	size = 'medium',
	isLoading = false,
	freeSolo
}) => {
	const [internalState, setInternalState] = useState<string>('');
	const [internalSearchTerm] = useState<string | null>(null);
	const [valueHasChnaged, setValueHasChnaged] = useState<number>(0);

	useEffect(() => {
		if (!internalState && valueHasChnaged) {
			handleChange(
				options.find((finded) => {
					return finded.label === internalState;
				})
			);
		}
	}, [internalState]);

	useEffect(() => {
		if (valueHasChnaged) {
			handleChange(
				options.find((finded) => {
					return finded.label === internalState;
				})
			);
		}
	}, [valueHasChnaged]);

	useEffect(() => {
		if (value) {
			setInternalState(value.label);
		} else {
			setInternalState(defaultOption);
		}
	}, [value]);

	useEffect(() => {
		if (!valueHasChnaged) {
			const status = JSON.parse(JSON.stringify(internalState));
			if (status == null) {
				handleInputChange(internalSearchTerm);
			}
		}
	}, [internalSearchTerm]);

	const hintId = `${id}_hint`;
	const errorId = `${id}_error`;

	return (
		<FormControl
			required={required}
			fullWidth={fullWidth}>
			<Autocomplete
				disablePortal
				id={id}
				options={[...options.map((mapped) => mapped.label), defaultOption]}
				renderInput={(params) => (
					<TextField
						{...params}
						label={required ? label + ' *' : label}
						InputLabelProps={{
							shrink: true
						}}
					/>
				)}
				clearText="Limpiar"
				closeText="Cerrar"
				loadingText="Cargando..."
				noOptionsText="No hay elementos"
				openText="Abrir"
				size={size}
				disabled={disabled}
				fullWidth={fullWidth}
				value={internalState}
				getOptionDisabled={(val) => val === defaultOption}
				onChange={(event: any, newValue: any) => {
					setInternalState(newValue);
					setValueHasChnaged(valueHasChnaged + 1);
				}}
				onInputChange={(event: any, newInputValue: string) => {
					handleInputChange(newInputValue);
				}}
				loading={isLoading}
				freeSolo={freeSolo}
			/>

			{hint !== undefined ? <FormHelperText id={hintId}>{hint}</FormHelperText> : null}
			{error !== undefined ? (
				<FormHelperText
					id={errorId}
					error={true}>
					{error}
				</FormHelperText>
			) : null}
		</FormControl>
	);
};
