/* eslint-disable max-lines */
import React, { useState, useMemo, useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { NomenclatureCodeInput } from 'components/globals/NomenclatureCodeInput/NomenclatureCodeInput';
import { FormGenericInput } from 'components/globals/inputs/FormGenericInput';
import { FormInputUnitPrice } from 'components/globals/inputs/FormInputUnitPrice';
import { VEHICLE_TYPE } from 'enums';
import { NomenclatureCode } from 'services/nomenclatureCodes';
import { NumberFormatValues } from 'react-number-format';
import { FileAttachementManager } from 'components/globals/inputs/FileManager';
import { Switch } from 'components/globals/switch/Switch';
import { FormGenericMultiline } from 'components/globals/inputs/FormGenericMultiline';
import { Attachment } from 'interfaces/file/IFile';
import { AppDatePicker, getDateFromStr } from 'components/globals/AppDatePicker/AppDatePicker';
import { Spinner } from 'components/globals/spinner/Spinner';
import { isValidYear } from 'components/bajas/forms/stepOne';
import { FormGenericSelect } from 'components/globals/inputs/FormGenericSelect';
import { is } from 'date-fns/locale';
import { ISelectOption } from 'interfaces/commons/IParameters';

interface VehicleFormProps {
	onChange?(data: any): void;
	onSubmit?(data: any): Promise<boolean | void>;
	onReset?: CallableFunction;
	onSuccess?: CallableFunction;
	value?: VehicleFormData;
	loading?: boolean;
	submitBtnLabel?: string;
	readonly?: boolean;
	vehicleType: VEHICLE_TYPE;
	attachments?: Attachment[];
	customInputDisable?: {
		[K in keyof VehicleFormData]?: {
			[key in keyof VehicleFormData[K]]?: boolean;
		};
	};
	isAccessory?: boolean;
	propertySubTypeId?: number;
	showRowNumberField?: boolean;
}

export interface VehicleFormData {
	general: {
		nomenclatureCode?: NomenclatureCode;
		quantity: string;
		unitPrice: NumberFormatValues;
		rowNumber: string;
	};
	vehicle: {
		registration: string;
		serialNumber: string;
		brand: string;
		model: string;
		manufacturingDate: string;
		type: string;
		shipName: string;
		helmetNumber: string;
		helmetModel: string;
		headline: string;
		engineBrand: string;
		engineNumber: string;
		modelSeries: string;
		engineHolder: string;
		domain: string;
		chassisNumber: string;
		year: string;
		origin: string;
		frameNumber: string;
		description: string;
		propertyTitle: boolean;
		helmetBrand: string;
	};
	ownership: {
		propertyTitle: File[];
	};
}

const defaultFormData: VehicleFormData = {
	general: {
		nomenclatureCode: undefined,
		quantity: '1',
		unitPrice: { value: '', floatValue: 0, formattedValue: '' },
		rowNumber: ''
	},
	vehicle: {
		registration: '',
		serialNumber: '',
		brand: '',
		model: '',
		manufacturingDate: '',
		type: '',
		shipName: '',
		helmetNumber: '',
		helmetModel: '',
		headline: '',
		engineBrand: '',
		engineNumber: '',
		modelSeries: '',
		engineHolder: '',
		domain: '',
		chassisNumber: '',
		year: '',
		origin: '',
		frameNumber: '',
		description: '',
		helmetBrand: '',
		propertyTitle: false
	},
	ownership: {
		propertyTitle: []
	}
};

const gridContainerProps = {
	spacing: 2,
	container: true,
	pt: 2
};

const gridItemProps = {
	item: true,
	xs: 12,
	sm: 12,
	md: 12,
	lg: 4,
	xl: 4
};

function isValidFormData(data: VehicleFormData, vehicleType: VEHICLE_TYPE) {
	const isFullfiled = (obj: Record<string, unknown>, type = 1) => {
		if (type === 1)
			return Object.values(obj).every((val) => val !== undefined && typeof val === 'string' && val?.trim() !== '');
		return Object.values(obj).every((val) => val !== undefined && val !== '');
	};
	const {
		general: { nomenclatureCode, unitPrice },
		vehicle
	} = data;

	const unrequiredFields = nonRequiredfields[vehicleType];
	const formData = getVehicleData(vehicleType, vehicle, unrequiredFields);
	let yearValidation = true;

	if (unrequiredFields.includes('year') && vehicle.year) {
		yearValidation = isValidYear(Number(vehicle.year));
	}
	return isFullfiled(formData) && isFullfiled({ nomenclatureCode }, 2) && !!unitPrice.value && yearValidation;
}

const fields: { [key in VEHICLE_TYPE]: Array<keyof VehicleFormData['vehicle']> } = {
	[VEHICLE_TYPE.AIRCRAFT]: ['domain', 'type', 'brand', 'model', 'serialNumber', 'manufacturingDate'],
	[VEHICLE_TYPE.VESSELS]: [
		'type',
		'engineNumber',
		'headline',
		'shipName',
		'helmetNumber',
		'helmetModel',
		'helmetBrand',
		'engineBrand',
		'modelSeries',
		'engineHolder',
		'manufacturingDate',
		'registration'
	],
	[VEHICLE_TYPE.AUTOMOTIVE]: [
		'engineNumber',
		'headline',
		'type',
		'brand',
		'model',
		'year',
		'origin',
		'chassisNumber',
		'domain'
	],
	[VEHICLE_TYPE.MOTOVEHICLES]: [
		'engineNumber',
		'headline',
		'type',
		'brand',
		'model',
		'year',
		'origin',
		'frameNumber',
		'domain'
	],
	[VEHICLE_TYPE.OTHERS]: ['description']
};

const nonRequiredfields: { [key in VEHICLE_TYPE]: Array<keyof VehicleFormData['vehicle']> } = {
	[VEHICLE_TYPE.AIRCRAFT]: ['domain', 'type', 'brand', 'model', 'manufacturingDate'],
	[VEHICLE_TYPE.VESSELS]: [
		'headline',
		'shipName',
		'engineBrand',
		'modelSeries',
		'engineHolder',
		'manufacturingDate',
		'registration'
	],
	[VEHICLE_TYPE.AUTOMOTIVE]: ['headline', 'year', 'domain'],
	[VEHICLE_TYPE.MOTOVEHICLES]: ['headline', 'year', 'domain'],
	[VEHICLE_TYPE.OTHERS]: []
};

function getVehicleData(type: VEHICLE_TYPE, data: VehicleFormData['vehicle'], omits: (string | number)[]) {
	return fields[type].reduce((result, field) => {
		if (omits.includes(field)) return result;

		const val = data[field];

		if (typeof val === 'string') {
			(result[field] as string) = val;
		}

		if (typeof val === 'boolean') {
			(result[field] as boolean) = val;
		}

		return result;
	}, {} as VehicleFormData['vehicle']);
}

const Vehicle: React.FC<VehicleFormProps> = ({
	readonly,
	onChange,
	submitBtnLabel,
	loading,
	onSubmit,
	vehicleType,
	value,
	attachments,
	onSuccess,
	isAccessory,
	propertySubTypeId,
	showRowNumberField
}) => {
	const [formData, setFormData] = useState(structuredClone(defaultFormData));
	const [dateValue, setDateValue] = React.useState<Date | null>(null);
	const [manufacturingDateValidity, setManufacturingDateValidity] = React.useState('');

	let vehicleValues: ISelectOption[] = [
		{
			id: 1,
			label: 'Nacional',
			value: 'NACIONAL'
		},
		{
			id: 1,
			label: 'Importado',
			value: 'IMPORTADO'
		}
	];

	const [selectedOption, setSelectedOption] = useState<ISelectOption | undefined>();
	const [options, setOptions] = useState<ISelectOption[]>(vehicleValues);

	const isSubmitEnabled = useMemo(() => {
		//const rowNum = showRowNumberField ? Number(formData.general.rowNumber) : true;
		return isValidFormData(formData, vehicleType) && manufacturingDateValidity !== 'Invalid date';
	}, [formData, isAccessory, manufacturingDateValidity]);

	const yearValidation = useMemo(() => {
		const year = Number(formData.vehicle.year);
		if (!formData.vehicle.year) return undefined;
		return !isValidYear(year) ? 'El año no es correcto' : undefined;
	}, [formData.vehicle.year]);

	function handleFieldChange<K extends keyof VehicleFormData>(name: K, subName: keyof VehicleFormData[K]) {
		return (value: any) => {
			if (name === 'vehicle' && subName === 'origin') {
				value = value?.value;
			}
			setFormData((curr) => ({
				...curr,
				[name]: {
					...curr[name],
					[subName]: value
				}
			}));
			onChange && onChange(formData);
		};
	}

	const shouldRenderOn = (...types: VEHICLE_TYPE[]) => {
		return types.includes(vehicleType);
	};

	const resetFormData = () => {
		setFormData(structuredClone(defaultFormData));
		setDateValue(null);
		handleFieldChange('vehicle', 'origin')(options[0]);
	};

	const handleSubmit = async () => {
		if (!onSubmit) return;

		const vehicleData = getVehicleData(vehicleType, formData.vehicle, []);
		const shouldResetForm = await onSubmit({ ...formData, vehicle: vehicleData });

		if (shouldResetForm) {
			resetFormData();
			onSuccess && onSuccess();
		}
	};

	const handleSelectChange = (value: ISelectOption) => {
		onChange && onChange(value);
		setSelectedOption(value);
	};

	useEffect(() => resetFormData(), [vehicleType]);

	useEffect(() => {
		handleFieldChange('vehicle', 'origin')(options[0]);
		if (value) {
			setFormData(value);
			setDateValue(value.vehicle.manufacturingDate ? getDateFromStr(value.vehicle.manufacturingDate) : null);
		}
	}, []);

	return (
		<Grid
			{...gridContainerProps}
			spacing={4}>
			{/* General */}
			<Grid
				item
				width="100%">
				<Grid item>
					<Typography
						variant="h1"
						fontSize={24}
						fontWeight={500}
						borderBottom="1px solid"
						borderColor="primary"
						pb={1}
						mb={2}>
						{isAccessory ? 'Datos del Accesorio' : 'Datos Generales'}
					</Typography>
				</Grid>

				<Grid
					item
					width={'100%'}>
					<NomenclatureCodeInput
						disabled={readonly}
						value={formData.general.nomenclatureCode}
						onChange={handleFieldChange('general', 'nomenclatureCode')}
						spacing={2}
						propertyTypeId={2}
						propertySubtypeId={propertySubTypeId}
						isAccessory={isAccessory}
					/>
				</Grid>

				<Grid
					{...gridContainerProps}
					spacing={2}>
					<Grid {...gridItemProps}>
						<FormGenericInput
							required
							disabled
							size="small"
							type="number"
							label="Cantidad"
							value="1"
						/>
					</Grid>

					<Grid {...gridItemProps}>
						<FormInputUnitPrice
							disabled={readonly}
							handleChange={handleFieldChange('general', 'unitPrice')}
							id="price-input"
							name="unit-price-input"
							label="Precio Unitario"
							value={formData.general.unitPrice}
							size="small"
						/>
					</Grid>

					{showRowNumberField ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								disabled={readonly}
								handleChange={handleFieldChange('general', 'rowNumber')}
								size="small"
								type="number"
								label="Renglón"
								value={formData.general.rowNumber}
							/>
						</Grid>
					) : null}
				</Grid>

				{shouldRenderOn(VEHICLE_TYPE.OTHERS) ? (
					<Grid
						mt={2}
						item
						width={'100%'}>
						<FormGenericMultiline
							limit={1000}
							disabled={readonly}
							handleChange={handleFieldChange('vehicle', 'description')}
							label="Descripción del Bien"
							value={formData.vehicle.description}
						/>
					</Grid>
				) : null}
			</Grid>

			{/* Vehicle*/}
			{vehicleType !== VEHICLE_TYPE.OTHERS && !isAccessory ? (
				<Grid
					{...gridContainerProps}
					marginLeft={0}
					padding={2}>
					<Grid
						item
						width={'100%'}>
						<Typography
							variant="h1"
							fontSize={24}
							fontWeight={500}
							borderBottom="1px solid"
							borderColor="primary"
							pb={1}>
							Información del Vehículo ({vehicleType})
						</Typography>
					</Grid>

					{shouldRenderOn(VEHICLE_TYPE.AIRCRAFT, VEHICLE_TYPE.AUTOMOTIVE, VEHICLE_TYPE.MOTOVEHICLES) ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								required
								disabled={readonly}
								size="small"
								label="Marca"
								value={formData.vehicle.brand}
								handleChange={handleFieldChange('vehicle', 'brand')}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.AIRCRAFT, VEHICLE_TYPE.AUTOMOTIVE, VEHICLE_TYPE.MOTOVEHICLES) ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								required
								disabled={readonly}
								size="small"
								label="Modelo"
								value={formData.vehicle.model}
								handleChange={handleFieldChange('vehicle', 'model')}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(
						VEHICLE_TYPE.AUTOMOTIVE,
						VEHICLE_TYPE.MOTOVEHICLES,
						VEHICLE_TYPE.AIRCRAFT,
						VEHICLE_TYPE.VESSELS
					) ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								required
								disabled={readonly}
								size="small"
								label="Tipo"
								value={formData.vehicle.type}
								handleChange={handleFieldChange('vehicle', 'type')}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.AUTOMOTIVE, VEHICLE_TYPE.MOTOVEHICLES) ? (
						<Grid {...gridItemProps}>
							{/* <FormGenericInput
								required
								disabled={readonly}
								size="small"
								label="Origen/Procedencia"
								value={formData.vehicle.origin}
								handleChange={handleFieldChange('vehicle', 'origin')}
							/> */}
							<FormGenericSelect
								//required={required}
								//disabled={disabled}
								handleChange={handleFieldChange('vehicle', 'origin')}
								//handleInputChange={getNomenclatureCodes}
								id="nomenclature-code"
								label={'Origen/Procedencia'}
								options={options}
								size="small"
								value={options.find((item) => item.value === formData.vehicle.origin) || selectedOption}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.AUTOMOTIVE, VEHICLE_TYPE.MOTOVEHICLES, VEHICLE_TYPE.VESSELS) ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								required
								disabled={readonly}
								size="small"
								label="Número de Motor"
								value={formData.vehicle.engineNumber}
								handleChange={handleFieldChange('vehicle', 'engineNumber')}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.MOTOVEHICLES) ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								required
								disabled={readonly}
								size="small"
								label="Número de Cuadro"
								value={formData.vehicle.frameNumber}
								handleChange={handleFieldChange('vehicle', 'frameNumber')}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.AUTOMOTIVE) ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								required
								disabled={readonly}
								size="small"
								label="Número de Chasis"
								value={formData.vehicle.chassisNumber}
								handleChange={handleFieldChange('vehicle', 'chassisNumber')}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.VESSELS) ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								required
								disabled={readonly}
								size="small"
								label="Número de Casco"
								value={formData.vehicle.helmetNumber}
								handleChange={handleFieldChange('vehicle', 'helmetNumber')}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.VESSELS) ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								required
								disabled={readonly}
								size="small"
								label="Modelo de Casco"
								value={formData.vehicle.helmetModel}
								handleChange={handleFieldChange('vehicle', 'helmetModel')}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.VESSELS) ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								required
								disabled={readonly}
								size="small"
								label="Marca de Casco"
								value={formData.vehicle.helmetBrand}
								handleChange={handleFieldChange('vehicle', 'helmetBrand')}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.VESSELS) ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								disabled={readonly}
								size="small"
								label="Modelo/Serie"
								value={formData.vehicle.modelSeries}
								handleChange={handleFieldChange('vehicle', 'modelSeries')}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.VESSELS) ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								disabled={readonly}
								size="small"
								label="Titular Motor"
								value={formData.vehicle.engineHolder}
								handleChange={handleFieldChange('vehicle', 'engineHolder')}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.VESSELS) ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								disabled={readonly}
								size="small"
								label="Nombre de la Embarcación"
								value={formData.vehicle.shipName}
								handleChange={handleFieldChange('vehicle', 'shipName')}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.AUTOMOTIVE, VEHICLE_TYPE.MOTOVEHICLES) ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								disabled={readonly}
								size="small"
								label="Año"
								maxLength={4}
								error={yearValidation}
								type="number"
								value={formData.vehicle.year}
								handleChange={handleFieldChange('vehicle', 'year')}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.AUTOMOTIVE, VEHICLE_TYPE.MOTOVEHICLES, VEHICLE_TYPE.VESSELS) ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								disabled={readonly}
								size="small"
								label="Titular"
								value={formData.vehicle.headline}
								handleChange={handleFieldChange('vehicle', 'headline')}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.VESSELS) ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								disabled={readonly}
								size="small"
								label="Marca Motor"
								value={formData.vehicle.engineBrand}
								handleChange={handleFieldChange('vehicle', 'engineBrand')}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.AIRCRAFT) ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								required
								disabled={readonly}
								size="small"
								label="Número de Serie"
								value={formData.vehicle.serialNumber}
								handleChange={handleFieldChange('vehicle', 'serialNumber')}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(
						VEHICLE_TYPE.VESSELS,
						VEHICLE_TYPE.AIRCRAFT,
						VEHICLE_TYPE.AUTOMOTIVE,
						VEHICLE_TYPE.MOTOVEHICLES
					) ? (
						<Grid {...gridItemProps}>
							<FormGenericInput
								disabled={readonly}
								size="small"
								label="Dominio/Matrícula"
								value={vehicleType !== VEHICLE_TYPE.VESSELS ? formData.vehicle.domain : formData.vehicle.registration}
								handleChange={handleFieldChange(
									'vehicle',
									vehicleType !== VEHICLE_TYPE.VESSELS ? 'domain' : 'registration'
								)}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.VESSELS, VEHICLE_TYPE.AIRCRAFT) ? (
						<Grid {...gridItemProps}>
							<AppDatePicker
								label="Fecha de Fabricación"
								disableFuture
								size="small"
								value={dateValue}
								onChange={(newValue) => {
									setDateValue(newValue);
									handleFieldChange('vehicle', 'manufacturingDate')(newValue?.toJSON() || '');
								}}
								onInvalid={(isValid) => {
									setManufacturingDateValidity(!isValid ? '' : 'Invalid date');
								}}
							/>
						</Grid>
					) : null}

					{shouldRenderOn(VEHICLE_TYPE.AUTOMOTIVE, VEHICLE_TYPE.MOTOVEHICLES) ? (
						<>
							<Grid
								item
								width={'100%'}>
								<Typography
									variant="h1"
									fontSize={24}
									fontWeight={500}
									borderBottom="1px solid"
									borderColor="primary"
									pb={1}>
									Títulos
								</Typography>
							</Grid>
							<Grid
								{...gridItemProps}
								lg={12}
								xl={12}
								sx={{
									display: 'flex',
									alignItems: 'flex-start',
									flexDirection: 'column'
								}}>
								<Box
									width="100%"
									maxWidth={328}>
									<Typography
										variant="h1"
										fontSize={16}
										fontWeight={500}
										mb={2}
										color="info">
										¿Posee Título de Propiedad?
									</Typography>

									<Switch
										disabled={readonly}
										value={formData.vehicle.propertyTitle}
										onChange={handleFieldChange('vehicle', 'propertyTitle')}
									/>
								</Box>

								{formData.vehicle.propertyTitle ? (
									<Box
										mt={3}
										width="100%"
										maxWidth={328}>
										<FileAttachementManager
											disabled={readonly}
											disableUploadAction
											allowsMultiple
											size="small"
											attachments={attachments}
											routeToDelete="/properties-files"
											fieldName="attachments"
											value={formData.ownership.propertyTitle}
											onChange={handleFieldChange('ownership', 'propertyTitle')}
										/>
									</Box>
								) : null}
							</Grid>
						</>
					) : null}
				</Grid>
			) : null}

			{loading && <Spinner />}

			{!readonly && vehicleType ? (
				<Grid
					item
					width={'100%'}>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							disabled={!isSubmitEnabled || loading}
							onClick={handleSubmit}
							variant="contained">
							{submitBtnLabel ?? 'Agregar Bien'}
						</Button>
					</Box>
				</Grid>
			) : null}
		</Grid>
	);
};

export default Vehicle;
