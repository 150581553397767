import React, { useEffect, useState } from 'react';
import { IBajaStepTwo } from './../../../interfaces/baja/IBaja';
import { Box, Typography } from '@mui/material';
import { Attachment } from 'interfaces/file/IFile';
import { getFilesByDocumentId } from 'services/bajaService';
import { FileAttachementManager } from 'components/globals/inputs/FileManager';

interface ISummaryStepTwo {
	entity: IBajaStepTwo;
}

export const SummaryStepTwo = ({ entity }: ISummaryStepTwo) => {
	const [documentAttachments, setDocumentAttachments] = useState<Attachment[]>([]);
	const fetchFiles = async () => {
		if (entity) {
			const data = await getFilesByDocumentId(entity.id);
			setDocumentAttachments(data);
		}
	};

	useEffect(() => {
		if (entity) fetchFiles();
	}, []);
	return (
		<Box sx={{ padding: '4%' }}>
			{documentAttachments?.length > 0 ? (
				<FileAttachementManager
					attachments={documentAttachments}
					hideInputToUpload={true}
					disabled={true}
					fieldName="attachments"
					label={'Archivos Adjuntos'}
				/>
			) : (
				<Typography
					variant="body1"
					sx={{ pl: '30px' }}>
					No posee documentos adjuntos.
				</Typography>
			)}
		</Box>
	);
};
