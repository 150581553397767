import { SetStateAction, useState, useEffect, useCallback } from 'react';
import style from '../../vehiculo/vehicle.module.css';

import lodash from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { NomenclatureCode } from 'components/globals/nomenclatureCode/nomenClatureCode';
import { useMovContext } from 'hooks/useMovContext';

//Services
import { toast } from 'react-toastify';

import {
	ValidateAeronave,
	ValidateMaquinas,
	ValidateAutomotor,
	ValidateMotovehiculo,
	ValidateEmbarcaciones
} from '../../vehiculo/validateVehicle';

//Interfaces
import { IStep3VehicleValues } from '../../interfaces';
import { IEditItemModalProps } from '../EditItemModal';

//Components
import { QuantityEditInput } from '../../quantityInput/QuantityEditInput';
import { UnitPriceInput } from '../../unitPriceInput/UnitPriceInput';
import { Automotores } from '../../vehiculo/automotores/Automotores';
import { Aeronaves } from '../../vehiculo/aeronaves/Aeronaves';
import { Motovehiculo } from '../../vehiculo/motovehiculos/Motovehiculos';
import { Embarcaciones } from '../../vehiculo/embarcaciones/Embarcaciones';
import { Maquinas } from '../../vehiculo/maquinas/Maquinas';

//Material UI
import { BounceLoader } from 'react-spinners';
import { Button, FormControl, Modal, Box, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { ProgressBar } from 'components/globals/progressBar/ProgressBar';

const vehicleTypeArr = ['Automotores', 'Motovehículos', 'Embarcaciones', 'Aeronaves', 'Máquinas'];

const styleModal = {
	position: 'absolute' as const,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '50%',
	minWidth: '532px',
	maxHeight: '90%',
	bgcolor: '#e4e4e4',
	border: '2px solid #000000b9',
	boxShadow: 24,
	padding: '50px',
	overflowY: 'auto' as const
};

export const EditItemVeh = ({
	openModal,
	setOpenModal,
	rightList,
	setRightList,
	selectedRow,
	setSelectedRow
}: IEditItemModalProps) => {
	const movContext = useMovContext();
	const [vehicleType, setVehicleType] = useState(selectedRow?.vehicleType || '');
	const [dataFromChild, setDataFromChild] = useState<IStep3VehicleValues>({} as IStep3VehicleValues);
	const [attachmentFromChild, setAttachmentFromChild] = useState<File | null>(null);
	const [nomenclatureCode, setNomenclatureCode] = useState(movContext?.itemSelected?.nomenclatureCode || '');
	const [nomenclatureCodeId, setNomenclatureCodeId] = useState(movContext?.itemSelected?.nomenclatureCodeId || 0);
	const [quantity, setQuantity] = useState(String(movContext?.itemSelected?.quantity) || '');
	const [unitPrice, setUnitPrice] = useState(movContext?.itemSelected?.unitPrice || '');
	const [disabled, setDisabled] = useState(true);
	const [uuid, setUuid] = useState(movContext?.itemSelected?.id || '');
	const [submited, setSubmited] = useState(false);

	// Modal
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [opacity, setOpacity] = useState(false);

	// End Modal
	const rightType = 'Vehículo';

	const memoizedDataFromChild = useCallback((data: SetStateAction<IStep3VehicleValues>) => {
		setDataFromChild(data);
	}, []);

	useEffect(() => {
		const isValidGenerics = handleGenericsDisabled();

		if (vehicleType === 'Automotores') {
			const isValid = ValidateAutomotor(dataFromChild as any);
			setDisabled(!isValid || !isValidGenerics);
		}
		if (vehicleType === 'Aeronaves') {
			const isValid = ValidateAeronave(dataFromChild as any);
			setDisabled(!isValid || !isValidGenerics);
		}
		if (vehicleType === 'Motovehículos') {
			const isValid = ValidateMotovehiculo(dataFromChild as any);
			setDisabled(!isValid || !isValidGenerics);
		}
		if (vehicleType === 'Embarcaciones') {
			const isValid = ValidateEmbarcaciones(dataFromChild as any);
			setDisabled(!isValid || !isValidGenerics);
		}
		if (vehicleType === 'Máquinas') {
			const isValid = ValidateMaquinas(dataFromChild as any);

			setDisabled(!isValid || !isValidGenerics);
		}
	}, [vehicleType, nomenclatureCode, quantity, unitPrice, dataFromChild, movContext?.itemSelected]);

	const handleGenericsDisabled = () => {
		setDisabled(true);
		if (nomenclatureCode.trim().length === 0 || quantity.trim().length === 0 || unitPrice.trim().length === 0)
			return false;
		return true;
	};

	const handleVehicleType = (evt: SelectChangeEvent) => {
		setVehicleType(evt.target.value as string);
	};

	const resetValues = () => {
		setNomenclatureCode('');
		setNomenclatureCodeId(0);
		setQuantity('');
		setUnitPrice('');
		setSubmited(true);
		setAttachmentFromChild(null);
	};

	const handleSubmit = () => {
		if (attachmentFromChild?.name) {
			dataFromChild.propertyTitleUuid = uuid;
		}
		const newItem = {
			rightType,
			id: selectedRow?.id,
			nomenclatureCode,
			nomenclatureCodeId,
			quantity,
			unitPrice,
			...dataFromChild,
			operation: 'update',
			accessories: selectedRow?.accessories
		};
		const list = rightList.filter((item) => item.id !== selectedRow?.id);
		const itemForContext = movContext?.itemsFromTable.find((item) => item.id === selectedRow?.id);
		if (itemForContext) {
			const listOfItemsContext = movContext?.itemsFromTable.filter((item) => item.id !== selectedRow?.id);
			if (listOfItemsContext) {
				listOfItemsContext.push(newItem);
				movContext?.setItemsFromTable(listOfItemsContext);
			}
		} else {
			movContext?.setItemsFromTable((prev) => [...prev, newItem]);
		}
		setRightList([newItem, ...list]);
		delete dataFromChild.propertyTitle;
		delete dataFromChild.propertyTitleUuid;
		setSubmited(true);
		resetValues();
		handleLoading();
	};

	const debouncedSubmit = lodash.debounce(handleSubmit, 1000);

	const handleClose = () => {
		setOpen(false);
		setOpenModal(false);
		movContext?.setItemSelected(null);
	};

	const handleLoading = () => {
		setLoading(true);
		setTimeout(() => {
			setOpacity(true);
		}, 300);
		setTimeout(() => {
			handleClose();
			toast('Bien editado correctamente', { type: 'success', autoClose: 2000 });
		}, 2000);
	};

	return (
		<div>
			<Modal
				sx={{ backgroundColor: 'rgba(0,0,0,0.8)', backdropFilter: 'blur(2px)' }}
				keepMounted
				open={openModal}
				disableEscapeKeyDown={true}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<Box sx={styleModal}>
					<div style={{ marginBottom: '40px' }}>
						<FormControl sx={{ width: '300px', ml: '0px', mt: -2 }}>
							<Box>
								<InputLabel htmlFor="tipoAlta">Seleccione el Tipo de Vehículo</InputLabel>
								<Select
									disabled
									sx={{ width: '220px', position: 'absolute' }}
									size="small"
									id="tipoVehiculo"
									name="tipoVehiculo"
									label="Seleccione el Tipo de Vehículo"
									value={vehicleType}
									onChange={handleVehicleType}>
									{vehicleTypeArr.map((data: string, index: number) => (
										<MenuItem
											key={index}
											value={data}>
											{data}
										</MenuItem>
									))}
								</Select>
							</Box>
						</FormControl>
						<div className={style.form}>
							<NomenclatureCode
								value={nomenclatureCode}
								setNomenclatureCode={setNomenclatureCode}
								setNomenclatureCodeId={setNomenclatureCodeId}
								submited={submited}
							/>
							<QuantityEditInput
								value={quantity}
								style={{ marginLeft: '15px' }}
								setQuantity={setQuantity}
								submited={submited}
							/>
							<UnitPriceInput
								value={unitPrice}
								setUnitPrice={setUnitPrice}
								submited={submited}
							/>
						</div>
						<hr
							className="solid"
							style={{ borderTop: '1px solid #5c5c5c71', marginTop: '35px' }}
						/>
						<div style={{ marginTop: '20px' }}>
							{vehicleType === 'Automotores' && (
								<div>
									<Automotores
										memoizedDataFromChild={memoizedDataFromChild}
										setAttachmentFromChild={setAttachmentFromChild}
										setUuid={setUuid}
										uuid={uuid}
										submited={submited}
										setSubmited={setSubmited}
									/>
								</div>
							)}
							{vehicleType === 'Aeronaves' && (
								<div>
									<Aeronaves memoizedDataFromChild={memoizedDataFromChild} />
								</div>
							)}
							{vehicleType === 'Motovehículos' && (
								<div>
									<Motovehiculo
										memoizedDataFromChild={memoizedDataFromChild}
										setAttachmentFromChild={setAttachmentFromChild}
										setUuid={setUuid}
										uuid={uuid}
										submited={submited}
										setSubmited={setSubmited}
									/>
								</div>
							)}
							{vehicleType === 'Embarcaciones' && (
								<div>
									<Embarcaciones
										memoizedDataFromChild={memoizedDataFromChild}
										submited={submited}
										setSubmited={setSubmited}
									/>
								</div>
							)}
							{vehicleType === 'Máquinas' && (
								<div>
									<Maquinas
										memoizedDataFromChild={memoizedDataFromChild}
										setAttachmentFromChild={setAttachmentFromChild}
										setUuid={setUuid}
										uuid={uuid}
										submited={submited}
										setSubmited={setSubmited}
									/>
								</div>
							)}
						</div>
						<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
							<Button
								id="addButton"
								disabled={disabled || loading}
								sx={{ mt: 4 }}
								variant="contained"
								onClick={debouncedSubmit}>
								Guardar cambios
							</Button>
							<Button
								id="cancelButton"
								sx={{ mt: 4, ml: 2 }}
								variant="contained"
								color="secondary"
								onClick={handleClose}
								disabled={loading}>
								Cancelar
							</Button>
						</div>
						<hr
							className="solid"
							style={{ borderTop: '1px solid #5c5c5c71', marginBottom: '-20px' }}
						/>
					</div>
					{loading && (
						<Box
							sx={{
								zIndex: '9999',
								opacity: opacity ? '1' : '0',
								position: 'absolute',
								backdropFilter: 'blur(2px)',
								height: '100%',
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								transition: 'all 700ms ease-in-out',
								top: '0',
								left: '0'
							}}>
							<BounceLoader
								size={100}
								speedMultiplier={1.5}
							/>
						</Box>
					)}
				</Box>
			</Modal>
		</div>
	);
};
