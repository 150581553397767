import { useState, useEffect, ChangeEvent, useRef, Dispatch, SetStateAction } from 'react';
import { ProgressBar } from 'components/globals/progressBar/ProgressBar';
import { useMovContext } from 'hooks/useMovContext';

import { fileValidate } from 'components/EncPatrimonial/altaStepper/paso1/validate';

import { FormControl, Box, TextField, InputAdornment, Tooltip, Button, FormHelperText } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';

interface nComprobanteProps {
	setFileGen: Dispatch<SetStateAction<File | null>>;
	label?: string;
	name?: string;
	uuid: string;
	setUuid: Dispatch<SetStateAction<string>>;
	submited: boolean;
	setSubmited: Dispatch<SetStateAction<boolean>>;
}

export const InputAttachGenForEdit = ({ setUuid, setFileGen, label, name, uuid, submited, setSubmited }: nComprobanteProps) => {
	const movContext = useMovContext();
	const [file, setFile] = useState<File>({} as File);
	const [errorFile, setErrorFile] = useState<string | null>(null);
	const [progress, setProgress] = useState(0);
	const [success, setSuccess] = useState(false);

	const adjunto = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (submited) {
			postFile();
		}
	}, [submited]);

	/**
	 * `const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {`
	 *
	 * The `handleFileChange` function is a function that takes an event of type
	 * `ChangeEvent<HTMLInputElement>` and returns nothing
	 * @param event - ChangeEvent<HTMLInputElement>
	 * @returns The file is being returned.
	 */
	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		setErrorFile(null);
		const file = event.target.files?.[0];
		if (!file) return;
		if (file && fileValidate(file) === null) {
			const newFile = new File([file], file.name.toLocaleLowerCase(), { type: file.type, lastModified: file.lastModified });
			setFile(newFile);
			setFileGen(newFile);
		} else {
			setErrorFile(fileValidate(file));
		}
	};

	const postFile = async () => {
		if (!file?.name) {
			return;
		}
		const currentAttachments = movContext?.attachments;
		const newAttachments = currentAttachments?.filter((attachment) => attachment.id !== uuid);
		if (newAttachments) {
			newAttachments.push({
				id: uuid,
				idProperty: movContext?.editMovement?.property_registration?.id,
				name: file.name,
				file: file,
				operation: 'create',
				key: 'attachments'
			});
			movContext?.setAttachments(newAttachments);
		} else {
			movContext?.setAttachments([
				{
					id: uuid,
					idProperty: movContext?.editMovement?.property_registration?.id,
					name: file.name,
					file: file,
					operation: 'create',
					key: 'attachments'
				}
			]);
		}
		deleteFile();
		setSubmited(false);
	};

	/**
	 * Function who deletes the file.
	 */
	const deleteFile = () => {
		setProgress(0);
		setSuccess(false);
		if (adjunto?.current) {
			adjunto.current.value = '';
		}
		setFile({} as File);
		setFileGen({} as File);
	};

	return (
		<>
			<Box
				sx={{
					width: '100%',
					height: '44px',
					display: 'flex',
					justifyContent: 'start',
					flexDirection: 'row',
					textAlign: 'center'
				}}>
				<FormControl variant="standard">
					<TextField
						label={label ? label : 'Título de la propiedad'}
						name={name ? name : ''}
						id="outlined-start-adornment"
						variant="outlined"
						onChange={handleFileChange}
						value={file?.name ? file.name : ''}
						placeholder={file?.name ? '' : 'Seleccione un archivo'}
						size="small"
						sx={{ width: '222px' }}
						autoComplete="off"
						InputLabelProps={{ shrink: true }}
						disabled
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Tooltip
										arrow
										title="Adjuntar archivo">
										<Button
											sx={{
												backgroundColor: 'rgba(0, 0, 0, 0.05)',
												color: '#000',
												padding: '2.5px',
												minWidth: '30px',
												'&:hover': {
													backgroundColor: 'rgba(0, 0, 0, 0.05)'
												}
											}}
											variant="contained"
											component="label">
											<AttachFileIcon />
											<input
												ref={adjunto}
												id="propertyTitle"
												name="propertyTitle"
												type="file"
												accept="application/pdf, image/*"
												hidden
												onChange={handleFileChange}
											/>
										</Button>
									</Tooltip>
								</InputAdornment>
							)
						}}></TextField>
				</FormControl>
				{!file?.name && (
					<Box sx={{ mt: 1 }}>
						<FormHelperText sx={{ color: '#1a3762', mt: -1, pl: 1, position: 'relative' }}>
							* Extensiones permitidas: .png, .jpeg, .jpg, .pdf
						</FormHelperText>
						<FormHelperText sx={{ color: '#1a3762', mt: 0, pl: 1, position: 'relative' }}>* Tamaño máximo del total: 100MB</FormHelperText>
					</Box>
				)}
				{errorFile !== null && (
					<FormHelperText sx={{ color: '#d32f2f', position: 'relative', mt: 8, ml: -27 }}>{`*${errorFile}`}</FormHelperText>
				)}
				{file && file?.name && progress < 1 && (
					<Box>
						<Tooltip
							arrow
							title="Eliminar archivo">
							<DeleteIcon
								sx={{
									cursor: 'pointer',
									fontSize: '30px',
									backgroundColor: '#575757',
									color: '#fff',
									padding: '3px',
									borderRadius: '5px',
									m: 1,
									mt: 0.5,
									mr: '100px',
									transition: 'all 0.2s ease-in-out',
									'&:hover': {
										transition: 'all 0.2s ease-in-out',
										backgroundColor: '#161616',
										color: '#fff',
										transform: 'scale(1.5)'
									}
								}}
								color="error"
								onClick={deleteFile}
							/>
						</Tooltip>
					</Box>
				)}
			</Box>
			{progress > 1 && file?.name && (
				<Box sx={{ width: '100%', mt: 0 }}>
					<Box sx={{ width: '30%' }}>
						<ProgressBar value={progress} />
					</Box>
				</Box>
			)}
		</>
	);
};
