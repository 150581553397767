import { useState, useEffect, InputHTMLAttributes } from 'react';
import { Column, Table } from '@tanstack/react-table';
import { Select } from '../select/Select';
import { PROPERTY_TYPE } from 'enums';
import { ISelectOption } from 'interfaces/commons/IParameters';

export function Filter({ column, table }: { column: Column<any, unknown>; table: Table<any> }) {
	const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);
	const [selectedPropertyType, setSelectedPropertyType] = useState<ISelectOption[]>([]);
	const [isSelectOpen, setIsSelectOpen] = useState(false);

	if (column.id === 'property_id') {
		return (
			<Select
				multiple
				onOpen={() => setIsSelectOpen(true)}
				onClose={() => setIsSelectOpen(false)}
				sx={{ bgcolor: 'white', width: '160px', margin: 'auto' }}
				options={PROPERTY_TYPE.map((el) => ({ ...el }))}
				value={selectedPropertyType}
				size="small"
				open={isSelectOpen}
				onChange={(newVal) => {
					const value = newVal as Array<ISelectOption>;
					setSelectedPropertyType(value);
					column.setFilterValue(() => value?.map((el) => el.id));
					setIsSelectOpen(false);
				}}
			/>
		);
	}

	const columnFilterValue = column.getFilterValue();

	return typeof firstValue === 'number' ? (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center'
			}}>
			<div className="flex">
				<DebouncedInput
					type="number"
					min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
					max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
					value={(columnFilterValue as [number, number])?.[0] ?? ''}
					onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
					placeholder={`Min ${column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''}`}
					className="border shadow rounded customDebounseNumber"
				/>
			</div>
			<div className="h-1" />
		</div>
	) : (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center'
			}}>
			<DebouncedInput
				type="text"
				value={(columnFilterValue ?? '') as string}
				onChange={(value) => column.setFilterValue(value)}
				placeholder={`Buscar... (${column.getFacetedUniqueValues().size})`}
				className="border shadow rounded customDebounseText"
				list={column.id + 'list'}
			/>
			<div className="h-1" />
		</div>
	);
}

// A debounced input react component
export function DebouncedInput({
	value: initialValue,
	onChange,
	debounce = 500,
	...props
}: {
	value: string | number;
	onChange(value: string | number): void;
	debounce?: number;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
	const [value, setValue] = useState(initialValue);

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			onChange(value);
		}, debounce);

		return () => clearTimeout(timeout);
	}, [value]);

	return (
		<input
			{...props}
			value={value}
			onChange={(e) => {
				if (props?.list == 'totallist' || props?.list == 'pricelist') {
					setValue(e.target.value.replace(',', '.'));
				} else {
					setValue(e.target.value);
				}
			}}
		/>
	);
}
