import { Box } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { formatDataForTable } from './movementDetailTable/utils';
import { useMovContext } from 'hooks/useMovContext';
import { CustomAccordion, IAccordionProps } from 'components/globals/accordion/CustomAccordion';
import { SummaryStepOne } from 'components/bajas/summary/SummaryStepOne';
import { SummaryStepTwo } from 'components/bajas/summary/SummaryStepTwo';
import { SummaryStepThree } from 'components/bajas/summary/SummaryStepThree';
import { SummaryStepOne as SummaryAltaStepOne } from 'components/altas/summary/SummaryStepOne';
import { SummaryStepTwo as SummaryAltaStepTwo } from 'components/altas/summary/SummaryStepTwo';
import { SummaryStepThree as SummaryAltaStepThree } from 'components/altas/summary/SummaryStepThree';
import { IBajaStepOne, IBajaStepTwo, IBajaStepThree } from './../../../interfaces/baja/IBaja';
import { useDeregistrationContext } from 'hooks/useDeregistrationContext';
import { IAltaStepOne, IAltaStepThree, IAltaStepTwo } from 'interfaces/alta/IAlta';

export interface DetailsMovementProps {
	openModal: boolean;
	setOpenModal: Dispatch<SetStateAction<boolean>>;
	selectedRow: any;
}

export const DetailsMovement = ({ selectedRow }: any) => {
	const movContext = useMovContext();
	const [items, setItems] = useState<any[]>([]);
	const [entity, setEntity] = useState<any>({});
	const deregistrationContext = useDeregistrationContext();

	useEffect(() => {
		if (selectedRow) {
			movContext?.setItemSelected(selectedRow);
			const newItems = formatDataForTable(selectedRow);
			if (newItems) {
				setItems(newItems);
			}
			if (selectedRow.property_registration_id !== null) {
				// Registro de Alta
				setEntity({
					...selectedRow.property_registration,
					high_type_id: selectedRow.property_registration.high_type_id,
					files: selectedRow.property_registration.files
				});
			} else {
				setEntity({
					id: selectedRow?.property_deregistration_id,
					procedure: selectedRow?.property_deregistration?.proceedings_number,
					instrument: selectedRow?.property_deregistration?.legal_instrument,
					date_legal_instrument: selectedRow?.property_deregistration?.date_legal_instrument,
					date_delivery_certificate: selectedRow?.property_deregistration?.date_delivery_certificate,
					hasErrors: false
				} as IBajaStepOne);

				deregistrationContext.onHandleGetFilesStepTwo(
					selectedRow?.property_deregistration_id ? selectedRow.property_deregistration_id : null
				);
				deregistrationContext
					.onHandleGetDataStepThree(
						selectedRow?.property_deregistration_id ? selectedRow.property_deregistration_id : null
					)
					.then((data) => {
						const deregistrationItems = data?.property_deregistration_item;

						if (deregistrationItems.length) {
							const propertyWithTaxId = deregistrationItems.find((item: any) => {
								return item.property?.property_registration;
							});

							const deregistrationTypes = deregistrationItems.map((el: any) => {
								return el.deregistration_type_id;
							});

							const { single_tax_id } =
								propertyWithTaxId !== undefined && propertyWithTaxId.property.property_registration;
							setEntity((curr: Record<string, unknown>) => {
								return {
									...curr,
									single_tax_id,
									deregistration_type_id: deregistrationTypes
								};
							});
						}
					});
			}
		}
	}, [selectedRow]);

	const accordionData: IAccordionProps[] = [
		{
			title: 'Paso 1: Registro de Recepción',
			children: <SummaryAltaStepOne entity={entity as IAltaStepOne} />
		},
		{
			title: 'Paso 2: Registro de Instrumentos',
			children: <SummaryAltaStepTwo entity={entity as IAltaStepTwo} />
		},
		{
			title: 'Paso 3: Detalle de Items',
			children: <SummaryAltaStepThree entity={entity as IAltaStepThree} />
		}
	];

	return (
		<>
			{selectedRow?.property_registration !== null ? (
				<Box pt={1}>{entity.id ? <CustomAccordion accordions={accordionData} /> : null}</Box>
			) : null}

			{selectedRow?.property_deregistration !== null && (
				<Box style={{ width: '100%', paddingTop: '10px' }}>
					<CustomAccordion
						accordions={
							[
								{
									title: 'Paso 1: Instrumento Legal',
									children: <SummaryStepOne entity={entity ? entity : ({} as IBajaStepOne)} />
								},
								{
									title: 'Paso 2: Documentos Adjuntos',
									children: (
										<SummaryStepTwo
											entity={
												deregistrationContext?.dataStepTwo ? deregistrationContext?.dataStepTwo : ({} as IBajaStepTwo)
											}
										/>
									)
								},
								{
									title: 'Paso 3: Bienes Patrimoniales',
									children: (
										<SummaryStepThree
											entity={
												deregistrationContext?.dataStepThree
													? deregistrationContext?.dataStepThree
													: ({} as IBajaStepThree)
											}
										/>
									)
								}
							] as IAccordionProps[]
						}
					/>
				</Box>
			)}
		</>
	);
};
