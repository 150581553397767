export const currencyLocalFormat = (value: number | bigint) => {
	let pesosARG = Intl.NumberFormat('es-AR', {
		style: 'currency',
		currency: 'ARS',
		useGrouping: true
	});
	return pesosARG.format(value);
};

export const parsedFormatDate = (date: string | null = '', format: string = '') => {
	if (date == '' || date == '-' || date == null) {
		return '-';
	}
	let tempData;
	if (format == '') {
		let nueDateFormated = date?.substring(0, 10)?.split('-')?.reverse()?.join('/');
		if (nueDateFormated !== undefined) {
			return nueDateFormated;
		} else {
			return '-';
		}
	} else {
		tempData = new Date(date).toLocaleDateString(format);
	}
	tempData = new Date(date).toLocaleDateString('es-AR');
	if (tempData !== 'Invalid Date') {
		return tempData;
	}
	return '-';
};

export const parsedFormatHour = (date: string, format = 'es-AR') => {
	return new Date(date).toLocaleTimeString(format);
};

export const parsedFormatDateHour = (date: string) => {
	const resultDate = parsedFormatDate(date);
	const resultHour = parsedFormatHour(date);
	return resultDate + ' ' + resultHour;
};
