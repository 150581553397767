import { ReactNode, useState } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { BalanceContext } from './BalanceContext';
import { toast } from 'react-toastify';
import { useAppContext } from 'hooks/useAppContext';
import { IMeta } from 'interfaces/commons/IResponse';
import { IPaginationRequest } from 'interfaces/commons/IParameters';
import {
	tryChangeStatusToOrganizationalUnit,
	tryCloseBalance,
	tryDownloadCompletePDFSummaryBalance,
	tryDownloadReportPDFSummaryBalance,
	trySummaryBalance
} from 'services/balanceService';
import { IJurisdiction } from 'interfaces/jurisdictions/IJurisdictions';
import { IOrganizationalUnit } from 'interfaces/organism/IOrganism';
import { captureMessageError } from 'services/httpService';
import { IBalanceDetails, IBalanceSummaryComponentProps } from 'interfaces/balance/IBalance';

export const BalanceState = ({ children }: { children: ReactNode | ReactJSXElement }) => {
	const contextoX = useAppContext();

	// START Get Organizational Units
	const [allOrganizationalUnits, setAllOrganizationalUnits] = useState<IOrganizationalUnit[]>([] as IOrganizationalUnit[]);
	const [organizationalUnits, setOrganizationalUnits] = useState<IOrganizationalUnit[]>([] as IOrganizationalUnit[]);
	const [organizationalUnitsMeta, setOrganizationalUnitsMeta] = useState<IMeta>({} as IMeta);
	const [jurisdiction, setJuristiction] = useState<IJurisdiction | undefined>(undefined);

	const onHandleTryGetAllOrganizationalUnits = async (
		jurisdiction: IJurisdiction | undefined,
		{ page = 1, limit = 10 }: IPaginationRequest
	) => {
		setOrganizationalUnits([]);
		if (!jurisdiction) {
			return false;
		}
		setJuristiction(jurisdiction);

		const allEntities = jurisdiction?.organizationals ? jurisdiction.organizationals : [];
		const desde = (page - 1) * limit;
		const hasta = desde + limit;
		setOrganizationalUnits(allEntities.slice(desde, hasta));
		setAllOrganizationalUnits(allEntities);
		const pages = allEntities.length > 0 ? parseFloat((allEntities.length / limit).toString()) : 0;
		const totalPages = parseInt(pages.toString());
		setOrganizationalUnitsMeta({
			currentPage: page,
			itemCount: allEntities.length ? allEntities.length : 0,
			totalItems: allEntities.length ? allEntities.length : 0,
			itemsPerPage: limit,
			totalPages: pages - totalPages > 0 ? totalPages + 1 : totalPages
		});

		toast.success('Unidades Organizacionales recuperadas!');
		return true;
	};
	// END Get Organizational Units

	// START Handle Change Page
	const onHandleChangePageOrganizationalUnits = async ({ page = 1, limit = organizationalUnitsMeta.itemsPerPage }: IPaginationRequest) => {
		const desde = (page - 1) * limit;
		const hasta = desde + limit;
		const newCollection = allOrganizationalUnits ? allOrganizationalUnits.slice(desde, hasta) : [];
		setOrganizationalUnits(newCollection);
		const pages = parseFloat((allOrganizationalUnits.length / limit).toString());
		const totalPages = parseInt(pages.toString());
		setOrganizationalUnitsMeta({
			currentPage: page,
			itemCount: organizationalUnitsMeta.totalItems,
			totalItems: organizationalUnitsMeta.totalItems,
			itemsPerPage: limit,
			totalPages: pages - totalPages > 0 ? totalPages + 1 : totalPages
		});
	};
	// END Handle Change Page

	// START Handle Close Balance
	const onHandleTryCloseBalance = async (jurisdiction_id: number | undefined) => {
		if (jurisdiction_id === undefined) {
			toast.error('Debe indicar una Jurisdicción para cerrar su ejercicio!');
			return false;
		}

		setOrganizationalUnits([]);
		setAllOrganizationalUnits([]);
		setJuristiction(undefined);
		contextoX?.onHandleShowSpinner();
		const response = await tryCloseBalance(jurisdiction_id);
		contextoX?.onHandleHideSpinner();

		if (response.hasOwnProperty('error')) {
			let message = response.messages
				? 'Respuesta del servidor: '.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages).concat('!')
				: 'Ocurrio un error al intentar cerrar el balance!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		toast.success('Balance cerrado satisfactoriamente!');
		return true;
	};
	// END Handle Close Balance

	// START Handle Change Status to Organizational Unit
	const onHandleTryChangeStatus = async (unit_id: number, status: boolean = false) => {
		contextoX?.onHandleShowSpinner();
		const response = await tryChangeStatusToOrganizationalUnit(unit_id, status);
		contextoX?.onHandleHideSpinner();

		if (response.hasOwnProperty('error')) {
			let message = response.messages
				? 'Respuesta del servidor: '.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages).concat('!')
				: 'Ocurrio un error al intentar cambiar estado de la unidad organizacional!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		toast.success('Cambio de estado satisfactorio!');
		return true;
	};
	// END Handle Change Status to Organizational Unit

	// START Handle Change Status to All Organizational Unit
	const onHandleTryChangeStatusAll = async (status: boolean = false) => {
		if (!(allOrganizationalUnits.length > 0)) {
			return false;
		}

		let hasError = false;
		contextoX?.onHandleShowSpinner();
		await Promise.all(
			allOrganizationalUnits.map(async (unit: IOrganizationalUnit) => {
				const response = await tryChangeStatusToOrganizationalUnit(unit.id ? unit.id : 0, status);
				if (response.hasOwnProperty('error')) {
					console.error(response.error);
					hasError = true;
				}
			})
		);
		contextoX?.onHandleHideSpinner();

		if (!hasError) {
			toast.success('El cambio de estado para todas las unidades fue satisfactorio!');
		} else {
			toast.warning('El cambio de estado para todas las unidades sufrio algun error! Verifique los estados recargando la vista.');
		}
		return !hasError;
	};
	// END Handle Change Status to All Organizational Unit

	// START Get Summary Balance
	const [summary, setSummary] = useState<IBalanceSummaryComponentProps | undefined>(undefined);

	const onHandleTryGetBalanceSummary = async (jurisdiction: IJurisdiction | undefined, { page = 1, limit = 10 }: IPaginationRequest) => {
		setSummary(undefined);
		if (jurisdiction?.id == undefined) {
			toast.error('Para recuperar el balance anual de la jurisdicción, primero debe seleccionar una!');
			return false;
		}
		setJuristiction(jurisdiction);

		contextoX?.onHandleShowSpinner();
		const response = await trySummaryBalance(jurisdiction.id);
		contextoX?.onHandleHideSpinner();

		let defaultMessageError = captureMessageError(response, 'Ocurrio un error al recuperar el balance anual!');
		if (defaultMessageError.hasError) {
			toast.error(defaultMessageError.message);
			return false;
		}

		setSummary({
			totals: {
				movables: [response.totals.movables],
				realEstate: [response.totals.realEstate]
			},
			details: response.details.map((d: IBalanceDetails) => {
				return { ...d, total: '64868484' };
			})
		});
		toast.success('Balance anual recuperado!');
		return true;
	};
	// END Get Summary Balance

	// START Handle selected Rows Balance Summary
	const [selectedRowsFromSummary, setSelectedRowsFromSummary] = useState({});
	// END Handle selected Rows Balance Summary

	// START Download Anual Report PDF
	const onHandleTryDownloadAnualBalanceSummary = async () => {
		contextoX?.onHandleShowSpinner();
		await tryDownloadCompletePDFSummaryBalance();
		contextoX?.onHandleHideSpinner();

		return true;
	};
	// END Download Anual Report PDF

	// START Download Report PDF by Jurisdiction
	const onHandleTryDownloadByJurisdictionBalanceSummary = async (jurisdiction: IJurisdiction | undefined) => {
		if (jurisdiction?.id == undefined) {
			toast.error('Para descargar el balance por jurisdicción, primero debe seleccionar una!');
			return false;
		}

		contextoX?.onHandleShowSpinner();
		await tryDownloadReportPDFSummaryBalance(jurisdiction.id);
		contextoX?.onHandleHideSpinner();

		return true;
	};
	// END Download Report PDF by Jurisdiction

	return (
		<BalanceContext.Provider
			value={{
				organizationalUnits,
				organizationalUnitsMeta,
				onHandleTryGetAllOrganizationalUnits,
				onHandleChangePageOrganizationalUnits,
				onHandleTryChangeStatus,
				onHandleTryChangeStatusAll,
				onHandleTryCloseBalance,
				onHandleTryGetBalanceSummary,
				summary,
				setSelectedRowsFromSummary,
				selectedRowsFromSummary,
				onHandleTryDownloadAnualBalanceSummary,
				onHandleTryDownloadByJurisdictionBalanceSummary
			}}>
			{children}
		</BalanceContext.Provider>
	);
};
