import { useEffect, useState } from 'react';
import { useMovContext } from 'hooks/useMovContext';
import { useNavigate } from 'react-router-dom';

//Components
import { CustomDialog } from 'components/globals/dialog/CustomDialog';
import { ReceptionEdit } from './reception/ReceptionEdit';
import { InstrumentsEdit } from './instruments/InstrumentsEdit';
import { ItemsEdit } from './items/ItemsEdit';
import { HeaderRoutes } from 'components/globals/headerRoutes/HeaderRoutes';
import { SubHeaderRoutes } from 'components/globals/subHeaderRoutes/SubHeaderRoutes';

//Material UI
import { Button, Box, Typography } from '@mui/material';

//Interfaces
import { IPaso } from 'components/EncPatrimonial/altaStepper/AltaStepper';
import { ButtonNextPrev } from 'components/globals/customsBtn/customBtn';
import { IMovementEdit } from 'interfaces/movements/MovementEdit';
import { CreateAssetRegistration } from 'interfaces/crud/createHighData';
import { IHighDataAssets } from 'interfaces/crud/highDataAssets';

//Components
import { Spinner } from 'components/globals/spinner/Spinner';

//Services
import { getMovement } from 'services';
import { EditMovMapper } from './mapper/EditMovMapper';
import { DialogType, ICustomButtonProps } from 'components/globals/dialog/utils';

export const MovementEdit = () => {
	const movContext = useMovContext();
	const navigate = useNavigate();
	const [movement] = useState<IMovementEdit | null>(null);
	const [paso1, setPaso1] = useState<IPaso>({ data: {}, valid: false });
	const [paso2, setPaso2] = useState<IPaso>({ data: {}, valid: false });
	const [paso3, setPaso3] = useState<IPaso>({ data: [], valid: false });
	const [paso4, setPaso4] = useState<CreateAssetRegistration | null>(null);
	const [paso4Assets, setPaso4Assets] = useState<IHighDataAssets>([] as IHighDataAssets);
	const [timerChange, setTimerChange] = useState<boolean>(false);
	const [btnDisabled, setBtnDisabled] = useState(true);
	const [showCancelDialog, setShowCancelDialog] = useState(false);
	const [showSaveDialog, setShowSaveDialog] = useState(false);

	// After 3 seconds the component will start detecting changes
	useEffect(() => {
		/* if (afipPromise.promiseInProgress) return; */
		const timeout = setTimeout(() => {
			setTimerChange(true);
		}, 3000);
		timeout;
		return () => {
			clearTimeout(timeout);
		};
	}, []);

	useEffect(() => {
		if (movContext?.idMovement) {
			getMovement(movContext.idMovement).then((res) => {
				movContext.setEditMovement({ ...res.data });
			});
		} else {
			navigate('/movimientos', { replace: true });
		}
	}, []);

	useEffect(() => {
		if (timerChange) {
			if (paso1.valid && paso2.valid && paso3.valid) {
				setBtnDisabled(false);
			} else {
				setBtnDisabled(true);
			}
		}
	}, [paso1, paso2, paso3]);

	useEffect(() => {
		const element = document.getElementById('edit');
		const top = element?.getBoundingClientRect() || { x: 0, y: 0 };
		window.scrollTo(top.x, top.y);
	}, []);

	const buttonsCancel: ICustomButtonProps[] = [
		{
			name: 'Si',
			color: 'secondary',
			variant: 'contained',
			onClick: () => {
				navigate('/movimientos', { replace: true });
			}
		},
		{
			name: 'No',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				return;
			}
		}
	];

	const buttonsSaveChanges: ICustomButtonProps[] = [
		{
			name: 'Si',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				const mapper = EditMovMapper({ paso1, paso2, paso3, setPaso4, paso4Assets, setPaso4Assets });
				/* console.log(mapper); */
				if (mapper.instruments && mapper.reception) {
					movContext?.setRecepAndInst([mapper]);
					movContext?.setIsUpdating(true);
				}
			}
		},
		{
			name: 'No',
			color: 'secondary',
			variant: 'contained',
			onClick: () => {
				return;
			}
		}
	];

	return (
		<>
			{movContext?.isUpdating || !timerChange ? <Spinner /> : null}
			{showCancelDialog && (
				<CustomDialog
					type={DialogType.SECONDARY}
					content={'¿Desea cancelar la edición?'}
					Custombuttons={buttonsCancel}
					buttonPosition={'center'}
					setShowDialog={setShowCancelDialog}
				/>
			)}
			{showSaveDialog && (
				<CustomDialog
					type={DialogType.PRIMARY}
					content={'¿Desea guardar los cambios?'}
					Custombuttons={buttonsSaveChanges}
					buttonPosition={'center'}
					setShowDialog={setShowSaveDialog}
				/>
			)}
			<Box
				id="edit"
				sx={{ backgroundColor: '#2341' }}>
				<HeaderRoutes
					title={'Movimientos'}
					strongTitle={'Editar'}>
					<SubHeaderRoutes
						strongTitle={'Registro de Recepción'}
						background={'#1A3762'}
						backgroundAfter={'#0a79a5'}>
						<ReceptionEdit setPaso1={setPaso1} />
					</SubHeaderRoutes>
					<SubHeaderRoutes
						strongTitle={'Registro de Instrumentos'}
						background={'#1A3762'}
						backgroundAfter={'#0a79a5'}>
						<InstrumentsEdit setPaso2={setPaso2} />
					</SubHeaderRoutes>
					<SubHeaderRoutes
						strongTitle={'Detalle de Items'}
						background={'#1A3762'}
						backgroundAfter={'#0a79a5'}>
						<ItemsEdit setPaso3={setPaso3} />
					</SubHeaderRoutes>
				</HeaderRoutes>
			</Box>
			<Box sx={{ mt: 2, mb: 2 }}>
				<Button
					color="secondary"
					variant="contained"
					onClick={() => setShowCancelDialog(true)}>
					Cancelar
				</Button>
				<ButtonNextPrev
					disabled={btnDisabled}
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => setShowSaveDialog(true)}>
					Guardar Cambios
				</ButtonNextPrev>
			</Box>
		</>
	);
};
