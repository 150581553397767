import { IResponseGeneric } from 'interfaces/commons/IResponse';
import { handleHttpError, buildURLQuery, downloadFile, httpGet, buildDefaultOptions } from './httpService';

// START Download Excel
export const _Download_File_XLS = async (entity_uri: string, filters: any, title: string): Promise<void | IResponseGeneric> => {
	const queryString = buildURLQuery({ ...filters, type: 'download' });
	const URI = `${entity_uri}?${queryString}`;
	try {
		const response = await downloadFile(URI, title, 'xls');
		return response;
	} catch (error) {
		return handleHttpError(error);
	}
};
// END Download Excel

// START Download PDF
export const _Download_File_PDF = async (entity_uri: string, filters: any, title: string): Promise<void | IResponseGeneric> => {
	const queryString = buildURLQuery({ ...filters, type: 'download' });
	const URI = `${entity_uri}?${queryString}`;
	try {
		const response = await downloadFile(URI, title, 'pdf');
		return response;
	} catch (error) {
		return handleHttpError(error);
	}
};
// END Download PDF

// START Send File by mail
export const _Send_File_by_Mail = async (entity_uri: string, filters: any) => {
	const queryString = buildURLQuery({ ...filters, type: 'mail' });
	const URI = `${entity_uri}?${queryString}`;
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpGet(URI, OPTIONS);
		return response;
	} catch (error) {
		return handleHttpError(error);
	}
};
// END Send File by mail
