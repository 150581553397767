// Enums
import { ApiPaths } from 'enums/apiPaths';
import apiInstance from 'interceptors/axiosInterceptor';
import {
	IPresentationSheetAuditFilters,
	IPresentationSheetFilters
} from 'interfaces/presentationSheet/IPresentationSheet';

import { httpPost, handleHttpError, buildDefaultOptions, httpGet, buildURLQuery, httpPatch } from './httpService';
import { toast } from 'react-toastify';
import { ca } from 'date-fns/locale';

const URL_BASE = `${ApiPaths.SHEETS}`;
const URL_GENERATE = URL_BASE + '/generate';
const URL_SEND = URL_BASE + '/send';
const URL_SEARCH_TO_AUDIT = URL_BASE + '/patrimonialdependencessheets/search';
const URL_CHANGE_STATUS = (id: number | undefined) => {
	return URL_BASE + '/status' + `/${id}`;
};

export const tryGetAllMovements = async ({
	year,
	month,
	movement_type,
	user_id,
	presentation_period
}: IPresentationSheetFilters): Promise<any> => {
	try {
		const OPTIONS = buildDefaultOptions();
		const queryString = buildURLQuery({ year, month, movement_type, user_id, presentation_period });
		const URI = `${URL_GENERATE}?${queryString}`;
		let response = await httpGet(URI, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const trySendPresentationSheet = async ({
	year,
	month,
	movement_type,
	user_id,
	presentation_period
}: IPresentationSheetFilters): Promise<any> => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpPost(URL_SEND, { year, month, movement_type, user_id, presentation_period }, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryGetAllMovementsToAudit = async ({
	patrimonial_dependence_id,
	year,
	month,
	movement_type,
	user_id
}: IPresentationSheetAuditFilters): Promise<any> => {
	try {
		const OPTIONS = buildDefaultOptions();
		const queryString = buildURLQuery({ patrimonial_dependence_id, year, month, movement_type, user_id });
		const URI = `${URL_SEARCH_TO_AUDIT}?${queryString}`;
		let response = await httpGet(URI, OPTIONS);
		return response?.data;

		/* 		let data = {
			"statusCode": "200",
			"isDirector": false,
			"sheet": {
				"created_at": "2022-10-31T18:44:37.396Z",
				"id": 2,
				"year": 2022,
				"month": 10,
				"total": "296.00",
				"movement_type": 1,
				"presentation_period": 2022,
				"initial_existence": "0.00",
				"status_id": 1,
				"patrimonial_dependence_id": 1,
				"organizational_id": 1
			},
			"items": [
				{
					"movement_id": 1,
					"nomenclator_code": "01.01.03.00",
					"item_inventory_number": null,
					"description": "Prueba 1",
					"date": "2022-10-31T18:44:37.403Z",
					"price": "75.50",
					"quantity": 1,
					"total": "75.50",
					"reception_number": "221",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 1,
							"name": "Documento 1",
							"file_type_id": 1,
							"property_id": 1,
							"path": "http://localhost:3000/attachments/ruta 1",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						},
						{
							"id": 6,
							"name": "Documento 1 6",
							"file_type_id": 2,
							"property_id": 1,
							"path": "http://localhost:3000/attachments/ruta 1 6",
							"temporary_id": null,
							"file_type": {
								"id": 2,
								"name": "Ley",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 2,
					"nomenclator_code": "01.01.03.00",
					"item_inventory_number": null,
					"description": "Prueba 3",
					"date": "2022-10-31T18:44:37.430Z",
					"price": "65.00",
					"quantity": 2,
					"total": "130.00",
					"reception_number": "76777",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 3,
							"name": "Documento 3",
							"file_type_id": 1,
							"property_id": 3,
							"path": "http://localhost:3000/attachments/ruta 3",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 3,
					"nomenclator_code": "01.01.02.00",
					"item_inventory_number": null,
					"description": "Prueba 2",
					"date": "2022-10-31T18:44:37.423Z",
					"price": "45.25",
					"quantity": 2,
					"total": "90.50",
					"reception_number": "523545",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 2,
							"name": "Documento 2",
							"file_type_id": 1,
							"property_id": 2,
							"path": "http://localhost:3000/attachments/ruta 2",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 4,
					"nomenclator_code": "01.01.03.00",
					"item_inventory_number": null,
					"description": "Prueba 1",
					"date": "2022-10-31T18:44:37.403Z",
					"price": "75.50",
					"quantity": 1,
					"total": "75.50",
					"reception_number": "221",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 1,
							"name": "Documento 1",
							"file_type_id": 1,
							"property_id": 1,
							"path": "http://localhost:3000/attachments/ruta 1",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						},
						{
							"id": 6,
							"name": "Documento 1 6",
							"file_type_id": 2,
							"property_id": 1,
							"path": "http://localhost:3000/attachments/ruta 1 6",
							"temporary_id": null,
							"file_type": {
								"id": 2,
								"name": "Ley",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 5,
					"nomenclator_code": "01.01.03.00",
					"item_inventory_number": null,
					"description": "Prueba 3",
					"date": "2022-10-31T18:44:37.430Z",
					"price": "65.00",
					"quantity": 2,
					"total": "130.00",
					"reception_number": "76777",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 3,
							"name": "Documento 3",
							"file_type_id": 1,
							"property_id": 3,
							"path": "http://localhost:3000/attachments/ruta 3",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 6,
					"nomenclator_code": "01.01.02.00",
					"item_inventory_number": null,
					"description": "Prueba 2",
					"date": "2022-10-31T18:44:37.423Z",
					"price": "45.25",
					"quantity": 2,
					"total": "90.50",
					"reception_number": "523545",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 2,
							"name": "Documento 2",
							"file_type_id": 1,
							"property_id": 2,
							"path": "http://localhost:3000/attachments/ruta 2",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 7,
					"nomenclator_code": "01.01.03.00",
					"item_inventory_number": null,
					"description": "Prueba 1",
					"date": "2022-10-31T18:44:37.403Z",
					"price": "75.50",
					"quantity": 1,
					"total": "75.50",
					"reception_number": "221",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 1,
							"name": "Documento 1",
							"file_type_id": 1,
							"property_id": 1,
							"path": "http://localhost:3000/attachments/ruta 1",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						},
						{
							"id": 6,
							"name": "Documento 1 6",
							"file_type_id": 2,
							"property_id": 1,
							"path": "http://localhost:3000/attachments/ruta 1 6",
							"temporary_id": null,
							"file_type": {
								"id": 2,
								"name": "Ley",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 8,
					"nomenclator_code": "01.01.03.00",
					"item_inventory_number": null,
					"description": "Prueba 3",
					"date": "2022-10-31T18:44:37.430Z",
					"price": "65.00",
					"quantity": 2,
					"total": "130.00",
					"reception_number": "76777",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 3,
							"name": "Documento 3",
							"file_type_id": 1,
							"property_id": 3,
							"path": "http://localhost:3000/attachments/ruta 3",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 9,
					"nomenclator_code": "01.01.02.00",
					"item_inventory_number": null,
					"description": "Prueba 2",
					"date": "2022-10-31T18:44:37.423Z",
					"price": "45.25",
					"quantity": 2,
					"total": "90.50",
					"reception_number": "523545",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 2,
							"name": "Documento 2",
							"file_type_id": 1,
							"property_id": 2,
							"path": "http://localhost:3000/attachments/ruta 2",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 10,
					"nomenclator_code": "01.01.03.00",
					"item_inventory_number": null,
					"description": "Prueba 1",
					"date": "2022-10-31T18:44:37.403Z",
					"price": "75.50",
					"quantity": 1,
					"total": "75.50",
					"reception_number": "221",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 1,
							"name": "Documento 1",
							"file_type_id": 1,
							"property_id": 1,
							"path": "http://localhost:3000/attachments/ruta 1",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						},
						{
							"id": 6,
							"name": "Documento 1 6",
							"file_type_id": 2,
							"property_id": 1,
							"path": "http://localhost:3000/attachments/ruta 1 6",
							"temporary_id": null,
							"file_type": {
								"id": 2,
								"name": "Ley",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 11,
					"nomenclator_code": "01.01.03.00",
					"item_inventory_number": null,
					"description": "Prueba 3",
					"date": "2022-10-31T18:44:37.430Z",
					"price": "65.00",
					"quantity": 2,
					"total": "130.00",
					"reception_number": "76777",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 3,
							"name": "Documento 3",
							"file_type_id": 1,
							"property_id": 3,
							"path": "http://localhost:3000/attachments/ruta 3",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 12,
					"nomenclator_code": "01.01.02.00",
					"item_inventory_number": null,
					"description": "Prueba 2",
					"date": "2022-10-31T18:44:37.423Z",
					"price": "45.25",
					"quantity": 2,
					"total": "90.50",
					"reception_number": "523545",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 2,
							"name": "Documento 2",
							"file_type_id": 1,
							"property_id": 2,
							"path": "http://localhost:3000/attachments/ruta 2",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 13,
					"nomenclator_code": "01.01.03.00",
					"item_inventory_number": null,
					"description": "Prueba 1",
					"date": "2022-10-31T18:44:37.403Z",
					"price": "75.50",
					"quantity": 1,
					"total": "75.50",
					"reception_number": "221",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 1,
							"name": "Documento 1",
							"file_type_id": 1,
							"property_id": 1,
							"path": "http://localhost:3000/attachments/ruta 1",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						},
						{
							"id": 6,
							"name": "Documento 1 6",
							"file_type_id": 2,
							"property_id": 1,
							"path": "http://localhost:3000/attachments/ruta 1 6",
							"temporary_id": null,
							"file_type": {
								"id": 2,
								"name": "Ley",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 14,
					"nomenclator_code": "01.01.03.00",
					"item_inventory_number": null,
					"description": "Prueba 3",
					"date": "2022-10-31T18:44:37.430Z",
					"price": "65.00",
					"quantity": 2,
					"total": "130.00",
					"reception_number": "76777",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 3,
							"name": "Documento 3",
							"file_type_id": 1,
							"property_id": 3,
							"path": "http://localhost:3000/attachments/ruta 3",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 15,
					"nomenclator_code": "01.01.02.00",
					"item_inventory_number": null,
					"description": "Prueba 2",
					"date": "2022-10-31T18:44:37.423Z",
					"price": "45.25",
					"quantity": 2,
					"total": "90.50",
					"reception_number": "523545",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 2,
							"name": "Documento 2",
							"file_type_id": 1,
							"property_id": 2,
							"path": "http://localhost:3000/attachments/ruta 2",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				}, {
					"movement_id": 16,
					"nomenclator_code": "01.01.03.00",
					"item_inventory_number": null,
					"description": "Prueba 1",
					"date": "2022-10-31T18:44:37.403Z",
					"price": "75.50",
					"quantity": 1,
					"total": "75.50",
					"reception_number": "221",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 1,
							"name": "Documento 1",
							"file_type_id": 1,
							"property_id": 1,
							"path": "http://localhost:3000/attachments/ruta 1",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						},
						{
							"id": 6,
							"name": "Documento 1 6",
							"file_type_id": 2,
							"property_id": 1,
							"path": "http://localhost:3000/attachments/ruta 1 6",
							"temporary_id": null,
							"file_type": {
								"id": 2,
								"name": "Ley",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 17,
					"nomenclator_code": "01.01.03.00",
					"item_inventory_number": null,
					"description": "Prueba 3",
					"date": "2022-10-31T18:44:37.430Z",
					"price": "65.00",
					"quantity": 2,
					"total": "130.00",
					"reception_number": "76777",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 3,
							"name": "Documento 3",
							"file_type_id": 1,
							"property_id": 3,
							"path": "http://localhost:3000/attachments/ruta 3",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 18,
					"nomenclator_code": "01.01.02.00",
					"item_inventory_number": null,
					"description": "Prueba 2",
					"date": "2022-10-31T18:44:37.423Z",
					"price": "45.25",
					"quantity": 2,
					"total": "90.50",
					"reception_number": "523545",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 2,
							"name": "Documento 2",
							"file_type_id": 1,
							"property_id": 2,
							"path": "http://localhost:3000/attachments/ruta 2",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 19,
					"nomenclator_code": "01.01.03.00",
					"item_inventory_number": null,
					"description": "Prueba 1",
					"date": "2022-10-31T18:44:37.403Z",
					"price": "75.50",
					"quantity": 1,
					"total": "75.50",
					"reception_number": "221",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 1,
							"name": "Documento 1",
							"file_type_id": 1,
							"property_id": 1,
							"path": "http://localhost:3000/attachments/ruta 1",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						},
						{
							"id": 6,
							"name": "Documento 1 6",
							"file_type_id": 2,
							"property_id": 1,
							"path": "http://localhost:3000/attachments/ruta 1 6",
							"temporary_id": null,
							"file_type": {
								"id": 2,
								"name": "Ley",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 20,
					"nomenclator_code": "01.01.03.00",
					"item_inventory_number": null,
					"description": "Prueba 3",
					"date": "2022-10-31T18:44:37.430Z",
					"price": "65.00",
					"quantity": 2,
					"total": "130.00",
					"reception_number": "76777",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 3,
							"name": "Documento 3",
							"file_type_id": 1,
							"property_id": 3,
							"path": "http://localhost:3000/attachments/ruta 3",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 21,
					"nomenclator_code": "01.01.02.00",
					"item_inventory_number": null,
					"description": "Prueba 2",
					"date": "2022-10-31T18:44:37.423Z",
					"price": "45.25",
					"quantity": 2,
					"total": "90.50",
					"reception_number": "523545",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 2,
							"name": "Documento 2",
							"file_type_id": 1,
							"property_id": 2,
							"path": "http://localhost:3000/attachments/ruta 2",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 22,
					"nomenclator_code": "01.01.03.00",
					"item_inventory_number": null,
					"description": "Prueba 1",
					"date": "2022-10-31T18:44:37.403Z",
					"price": "75.50",
					"quantity": 1,
					"total": "75.50",
					"reception_number": "221",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 1,
							"name": "Documento 1",
							"file_type_id": 1,
							"property_id": 1,
							"path": "http://localhost:3000/attachments/ruta 1",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						},
						{
							"id": 6,
							"name": "Documento 1 6",
							"file_type_id": 2,
							"property_id": 1,
							"path": "http://localhost:3000/attachments/ruta 1 6",
							"temporary_id": null,
							"file_type": {
								"id": 2,
								"name": "Ley",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 23,
					"nomenclator_code": "01.01.03.00",
					"item_inventory_number": null,
					"description": "Prueba 3",
					"date": "2022-10-31T18:44:37.430Z",
					"price": "65.00",
					"quantity": 2,
					"total": "130.00",
					"reception_number": "76777",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 3,
							"name": "Documento 3",
							"file_type_id": 1,
							"property_id": 3,
							"path": "http://localhost:3000/attachments/ruta 3",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 24,
					"nomenclator_code": "01.01.02.00",
					"item_inventory_number": null,
					"description": "Prueba 2",
					"date": "2022-10-31T18:44:37.423Z",
					"price": "45.25",
					"quantity": 2,
					"total": "90.50",
					"reception_number": "523545",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 2,
							"name": "Documento 2",
							"file_type_id": 1,
							"property_id": 2,
							"path": "http://localhost:3000/attachments/ruta 2",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 25,
					"nomenclator_code": "01.01.03.00",
					"item_inventory_number": null,
					"description": "Prueba 1",
					"date": "2022-10-31T18:44:37.403Z",
					"price": "75.50",
					"quantity": 1,
					"total": "75.50",
					"reception_number": "221",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 1,
							"name": "Documento 1",
							"file_type_id": 1,
							"property_id": 1,
							"path": "http://localhost:3000/attachments/ruta 1",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						},
						{
							"id": 6,
							"name": "Documento 1 6",
							"file_type_id": 2,
							"property_id": 1,
							"path": "http://localhost:3000/attachments/ruta 1 6",
							"temporary_id": null,
							"file_type": {
								"id": 2,
								"name": "Ley",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 26,
					"nomenclator_code": "01.01.03.00",
					"item_inventory_number": null,
					"description": "Prueba 3",
					"date": "2022-10-31T18:44:37.430Z",
					"price": "65.00",
					"quantity": 2,
					"total": "130.00",
					"reception_number": "76777",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 3,
							"name": "Documento 3",
							"file_type_id": 1,
							"property_id": 3,
							"path": "http://localhost:3000/attachments/ruta 3",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				},
				{
					"movement_id": 27,
					"nomenclator_code": "01.01.02.00",
					"item_inventory_number": null,
					"description": "Prueba 2",
					"date": "2022-10-31T18:44:37.423Z",
					"price": "45.25",
					"quantity": 2,
					"total": "90.50",
					"reception_number": "523545",
					"reponsability": "Ramón Carrasquero",
					"files": [
						{
							"id": 2,
							"name": "Documento 2",
							"file_type_id": 1,
							"property_id": 2,
							"path": "http://localhost:3000/attachments/ruta 2",
							"temporary_id": null,
							"file_type": {
								"id": 1,
								"name": "Decretos",
								"description": "Lorem ipsum",
								"is_active": true
							}
						}
					]
				}
			]
		} */
		/* return data; */
	} catch (error) {
		return handleHttpError(error);
	}
};

export const trySendPresentationToAuditSheet = async (entities: any[], sheetId: number | undefined): Promise<any> => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpPatch(URL_CHANGE_STATUS(sheetId), { items: entities }, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const createPresentationSheet = async (payload: {
	year: number;
	month: number;
	movement_type: number;
	presentation_period: number;
	user_id: number;
	patrimonial_dependence_id: number;
	send: number;
	sheet_id: number | null;
}) => {
	try {
		const response = await apiInstance.post('/sheets/send', payload);
		if (response.data.statusCode === 404) {
			return toast.error(response.data.message);
		}

		return response.data.data;
	} catch (error: any) {
		return toast.error(error?.response?.data?.message);
	}
};

export const uploadSheetFile = async (payload: { sheetId: number; file: File }) => {
	const formData = new FormData();
	formData.append('attachments', payload.file);

	const response = await apiInstance.post(`/sheets-files/signed/${payload.sheetId}`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});

	return response.data;
};

export const downloadSheetFile = async (payload: { sheetId: number; movement_type: string; month?: string }) => {
	function download(pdfBuffer: string, type: string, month?: string) {
		const file = new Blob([pdfBuffer], { type: 'application/pdf' });
		const url = window.URL.createObjectURL(file);
		const link = document.createElement('a');
		link.href = url;
		link.download = `Planilla_de_${type.toString() === '1' ? 'Alta' : 'Baja'}${month ? `_${month}` : ''}`;
		document.body.appendChild(link);
		link.click();
		link.remove();
	}
	const response = await apiInstance.get(`/sheets-documents/generate/${payload.sheetId}`, {
		responseType: 'blob'
	});
	download(response.data, payload.movement_type, payload.month);
};

export const getMonthlySheets = async (filters: { year?: number; month?: number }) => {
	const response = await apiInstance.get(`/sheets-manager`, { params: filters });
	return response.data.items;
};

export const sendSheet = async (id: number) => {
	const response = await apiInstance.patch(`/sheets/update-status-send/${id}`);
	return response.data;
};

export const getOrganizationalDataById = async (id: number) => {
	const response = await apiInstance.get(`/organizational/${id}`);
	return response.data;
};
