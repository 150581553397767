import { ReactNode, useState } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { PresentationSheetContext } from './PresentationContext';
import { toast } from 'react-toastify';
import {
	tryGetAllMovements,
	tryGetAllMovementsToAudit,
	trySendPresentationSheet,
	trySendPresentationToAuditSheet
} from 'services/presentationSheetService';
import { useAppContext } from 'hooks/useAppContext';
import { IMeta } from 'interfaces/commons/IResponse';
import { IPaginationRequest, ISelectOption } from 'interfaces/commons/IParameters';
import {
	IPresentationSheet,
	IPresentationSheetAuditData,
	IPresentationSheetAuditFilters,
	IPresentationSheetFilters,
	IPresentationSheetMovementAudit
} from 'interfaces/presentationSheet/IPresentationSheet';
import { MovementsStatus, SheetStatus } from 'enums';

export const PresentationSheetState = ({ children }: { children: ReactNode | ReactJSXElement }) => {
	const contextoX = useAppContext();

	// START Get Movements
	const [entities, setAllEntities] = useState<IPresentationSheet[]>([] as IPresentationSheet[]);
	const [presentations, setPresentations] = useState<IPresentationSheet[]>([] as IPresentationSheet[]);
	const [presentationMeta, setMeta] = useState<IMeta>({} as IMeta);
	const [filters, setFilters] = useState({} as IPresentationSheetFilters);
	const [total, setTotal] = useState<number>(0);
	const [canSent, setCanSent] = useState<boolean>(false);

	const onHandleTryGetAllMovements = async (
		{ year, month, movement_type, user_id, presentation_period }: IPresentationSheetFilters,
		{ page = 1, limit = 10 }: IPaginationRequest
	) => {
		setPresentations([]);
		setAllEntities([]);
		setTotal(0);
		setMeta({} as IMeta);
		contextoX?.onHandleShowSpinner();
		const response = await tryGetAllMovements({ year, month, movement_type, user_id, presentation_period });
		contextoX?.onHandleHideSpinner();

		if (response?.error) {
			const message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'Ocurrio un error al recuperar registros!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		if (response?.statusCode === 404) {
			toast.warning(response?.message);
			return false;
		}

		setCanSent(response?.canSent);
		setPresentations(response?.items ? response?.items.slice((page - 1) * limit, limit) : []);
		setAllEntities(response?.items);
		const pages = response.items.length > 0 ? parseFloat((response.items.length / limit).toString()) : 0;
		const totalPages = parseInt(pages.toString());
		setMeta({
			currentPage: page,
			itemCount: response.items.length ? response.items.length : 0,
			totalItems: response.items.length ? response.items.length : 0,
			itemsPerPage: limit,
			totalPages: pages - totalPages > 0 ? totalPages + 1 : totalPages
		});
		setTotal(response?.sheetTotal);
		toast.success('Registros recuperados!');
		return true;
	};
	// END Get Movements

	// START Get Movements
	const onHandleTrySendPresentationSheet = async ({
		year,
		month,
		movement_type,
		user_id,
		presentation_period
	}: IPresentationSheetFilters) => {
		setPresentations([]);
		contextoX?.onHandleShowSpinner();
		const response = await trySendPresentationSheet({ year, month, movement_type, user_id, presentation_period });
		contextoX?.onHandleHideSpinner();

		if (response?.error) {
			const message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'Ocurrio un error al presentar planilla!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		toast.success('Movimientos presentados!');
		return true;
	};
	// END Get Movements

	// START Handle Change Page
	const onHandleChangePage = async ({ page = 1, limit = presentationMeta.itemsPerPage }: IPaginationRequest) => {
		const desde = (page - 1) * limit;
		const hasta = desde + limit;
		const newCollection = entities ? entities.slice(desde, hasta) : [];
		setPresentations(newCollection);
		const pages = parseFloat((entities.length / limit).toString());
		const totalPages = parseInt(pages.toString());
		setMeta({
			currentPage: page,
			itemCount: presentationMeta.totalItems,
			totalItems: presentationMeta.totalItems,
			itemsPerPage: limit,
			totalPages: pages - totalPages > 0 ? totalPages + 1 : totalPages
		});
	};
	// END Handle Change Page

	// START Get Movements to Audit
	const [entitiesToAudit, setAllEntitiesToAudit] = useState<IPresentationSheetMovementAudit[]>(
		[] as IPresentationSheetMovementAudit[]
	);
	const [presentationsToAudit, setPresentationsToAudit] = useState<IPresentationSheetMovementAudit[]>(
		[] as IPresentationSheetMovementAudit[]
	);
	const [presentationToAuditMeta, setMetaToAudit] = useState<IMeta>({} as IMeta);
	const [filtersToAudit, setFiltersToAudit] = useState({} as IPresentationSheetAuditFilters);
	const [totalToAudit, setTotalToAudit] = useState<number>(0);
	const [sheetToAudit, setSheetToAudit] = useState<IPresentationSheetAuditData | undefined>(undefined);
	const [isDirector, setIsDirector] = useState<boolean>(false);

	const isObserved = (statusID: number | null): boolean => {
		return statusID === MovementsStatus.OBSERVADO || statusID === MovementsStatus.OBSERVADO_PROVISORIO;
	};

	const isApproved = (statusID: number | null): boolean => {
		return (
			statusID === MovementsStatus.APROBADO ||
			statusID === MovementsStatus.APROBADO_PROVISORIO ||
			statusID === MovementsStatus.CONSOLIDADO
		);
	};

	const getStatusMovementLabel = (statusId: number | null): string => {
		let label = '';
		switch (statusId) {
			case MovementsStatus.APROBADO:
				label = 'Aprobado';
				break;
			case MovementsStatus.APROBADO_PROVISORIO:
				label = 'Aprobado provisorio';
				break;
			case MovementsStatus.CONSOLIDADO:
				label = 'Consolidado';
				break;
			case MovementsStatus.ENVIADO:
				label = 'Enviado';
				break;
			case MovementsStatus.OBSERVADO:
				label = 'Observado';
				break;
			case MovementsStatus.OBSERVADO_PROVISORIO:
				label = 'Observado provisorio';
				break;
			case MovementsStatus.PENDIENTE:
				label = 'Pendiente';
				break;
			case MovementsStatus.RECTIFICADO:
				label = 'Rectificado';
				break;
			default:
				label = '';
				break;
		}
		return label;
	};

	const mapToAuditRecord = (entity: IPresentationSheetMovementAudit, sheet_status_id: number) => {
		return {
			...entity,
			isObserved: isObserved(entity.status_id),
			isApproved: isApproved(entity.status_id),
			sheetStatusID: sheet_status_id,
			status_label: getStatusMovementLabel(entity.status_id)
		};
	};

	const onHandleTryGetAllMovementsToAudit = async (
		{ patrimonial_dependence_id, year, month, movement_type, user_id }: IPresentationSheetAuditFilters,
		{ page = 1, limit = 10 }: IPaginationRequest
	) => {
		setIsDirector(false);
		setPresentationsToAudit([]);
		setAllEntitiesToAudit([]);
		setSheetToAudit(undefined);
		setTotalToAudit(0);
		setMetaToAudit({} as IMeta);
		contextoX?.onHandleShowSpinner();
		const response = await tryGetAllMovementsToAudit({
			patrimonial_dependence_id,
			year,
			month,
			movement_type,
			user_id
		});
		contextoX?.onHandleHideSpinner();

		if (response?.error) {
			const message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'Ocurrio un error al recuperar registros!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		setSheetToAudit(response?.sheet);
		setIsDirector(response?.isDirector);
		setPresentationsToAudit(
			response?.items
				? response?.items.slice((page - 1) * limit, limit).map((e: IPresentationSheetMovementAudit) => {
						return mapToAuditRecord(e, response?.sheet?.status_id);
				  })
				: []
		);
		setAllEntitiesToAudit(
			response?.items.map((e: IPresentationSheetMovementAudit) => {
				return mapToAuditRecord(e, response?.sheet?.status_id);
			})
		);
		const pages = response?.items.length > 0 ? parseFloat((response.items.length / limit).toString()) : 0;
		const totalPages = parseInt(pages.toString());
		setMetaToAudit({
			currentPage: page,
			itemCount: response?.items.length ? response.items.length : 0,
			totalItems: response?.items.length ? response.items.length : 0,
			itemsPerPage: limit,
			totalPages: pages - totalPages > 0 ? totalPages + 1 : totalPages
		});
		setTotalToAudit(response?.sheet?.total);
		onHandleTryGetMovementStatus(response?.isDirector);
		toast.success('Registros recuperados!');
		return true;
	};
	// END Get Movements to Audit

	// START Send Movements to Audit
	const onHandleTrySendPresentationSheetToAudit = async () => {
		const toChangeStatus: any[] = [];
		entitiesToAudit.forEach((element: IPresentationSheetMovementAudit) => {
			toChangeStatus.push({
				id: element.movement_id,
				status: element.status_id,
				observation: element.observation
			});
		});

		contextoX?.onHandleShowSpinner();
		const response = await trySendPresentationToAuditSheet(toChangeStatus, sheetToAudit?.id);
		contextoX?.onHandleHideSpinner();

		if (response?.error) {
			const message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'Ocurrio un error al envíar nuevos estados!';
			toast.error(message);
			console.error(response.error);
			return false;
		}
		toast.success('Cambios de estado enviados!');
		return true;
	};
	// END Send Movements to Audit

	// START Handle Change Page to Audit
	const onHandleChangePageToAudit = async ({
		page = 1,
		limit = presentationToAuditMeta.itemsPerPage
	}: IPaginationRequest) => {
		const desde = (page - 1) * limit;
		const hasta = desde + limit;
		const newCollection = entitiesToAudit ? entitiesToAudit.slice(desde, hasta) : [];
		setPresentationsToAudit(newCollection);
		const pages = parseFloat((entitiesToAudit.length / limit).toString());
		const totalPages = parseInt(pages.toString());
		setMetaToAudit({
			currentPage: page,
			itemCount: presentationToAuditMeta.totalItems,
			totalItems: presentationToAuditMeta.totalItems,
			itemsPerPage: limit,
			totalPages: pages - totalPages > 0 ? totalPages + 1 : totalPages
		});
	};
	// END Handle Change Page to Audit

	// START Toggle Status for Movement to Audit
	const onHandleChangeStatus = (entity: IPresentationSheetMovementAudit) => {
		const updatedEntities = entitiesToAudit.map((old) => {
			if (entity.movement_id === old.movement_id) {
				return {
					...old,
					status_id: entity.status_id,
					isApproved: isApproved(entity.status_id),
					isObserved: isObserved(entity.status_id),
					observation: entity.observation,
					status_label: getStatusMovementLabel(entity.status_id)
				};
			} else {
				return { ...old };
			}
		});
		setAllEntitiesToAudit(updatedEntities);
		const desde = (presentationToAuditMeta.currentPage - 1) * presentationToAuditMeta.itemsPerPage;
		const hasta = desde + presentationToAuditMeta.itemsPerPage;
		const newCollection = updatedEntities ? updatedEntities.slice(desde, hasta) : [];
		setPresentationsToAudit(newCollection);
	};
	// END Toggle Status for Movement to Audit

	const isEnableToEditSheetToAudit = (): boolean => {
		if (!sheetToAudit) {
			return false;
		}
		return sheetToAudit.status_id !== SheetStatus.CONSOLIDADA;
	};

	const [statusList, setStatusList] = useState<ISelectOption[]>([]);
	const onHandleTryGetMovementStatus = (isDirector = false) => {
		const statusList: ISelectOption[] = [];
		const OPERATOR_STATUS = [
			MovementsStatus.ENVIADO,
			MovementsStatus.OBSERVADO_PROVISORIO,
			MovementsStatus.APROBADO_PROVISORIO
		];
		const DIRECTOR_STATUS = [MovementsStatus.ENVIADO, MovementsStatus.OBSERVADO, MovementsStatus.CONSOLIDADO];

		for (const [key, value] of Object.entries(MovementsStatus)) {
			if (typeof value === 'number' && value !== undefined) {
				const label: string = key.toString().replace('_', ' ').toLowerCase();
				statusList.push({
					id: parseInt(value.toString()),
					label: label.charAt(0).toUpperCase() + label.slice(1)
				});
			}
		}
		const definitiveList: ISelectOption[] = [];
		if (isDirector) {
			statusList.forEach((element) => {
				if (
					DIRECTOR_STATUS.find((s) => {
						return s === element.id;
					})
				) {
					definitiveList.push(element);
				}
			});
		} else {
			statusList.forEach((element) => {
				if (
					OPERATOR_STATUS.find((s) => {
						return s === element.id;
					})
				) {
					definitiveList.push(element);
				}
			});
		}
		setStatusList(definitiveList);
	};

	return (
		<PresentationSheetContext.Provider
			value={{
				presentations,
				presentationMeta,
				onHandleTryGetAllMovements,
				onHandleTrySendPresentationSheet,
				onHandleChangePage,
				total,
				onHandleTryGetAllMovementsToAudit,
				onHandleTrySendPresentationSheetToAudit,
				onHandleChangePageToAudit,
				totalToAudit,
				presentationToAuditMeta,
				presentationsToAudit,
				sheetToAudit,
				onHandleChangeStatus,
				isEnableToEditSheetToAudit,
				isDirector: isDirector,
				onHandleTryGetMovementStatus,
				statusList,
				canSent
			}}>
			{children}
		</PresentationSheetContext.Provider>
	);
};
