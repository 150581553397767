import React, { FC, useState, Dispatch, SetStateAction } from 'react';
import style from './customDialog.module.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogType, ICustomButtonProps, Transition } from './utils';
import { IconButton, Tooltip } from '@mui/material';

export interface IDialogProps {
	type: DialogType;
	title?: string;
	content: string;
	Custombuttons: ICustomButtonProps[];
	buttonPosition?: 'start' | 'center' | 'end';
	setShowDialog: Dispatch<SetStateAction<boolean>>;
}

export const CustomDialog: FC<IDialogProps> = ({
	type,
	title,
	content,
	Custombuttons,
	buttonPosition,
	setShowDialog
}) => {
	const [open, setOpen] = useState(true);

	const handleClose = (index: number) => {
		handleCloseButtonx();
		if (Custombuttons[index]) {
			Custombuttons[index].onClick();
		}
	};
	const handleCloseButtonx = () => {
		setOpen(false);
		setShowDialog(false);
	};
	return (
		<div>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby="alert">
				<DialogTitle sx={{ backgroundColor: type, color: 'white', textAlign: 'center' }}>
					{title}
					<Box
						sx={{
							position: 'absolute',
							right: 8,
							top: 8
						}}>
						<Tooltip title={'Cerrar'}>
							<IconButton
								sx={{
									color: 'white'
								}}
								onClick={() => handleCloseButtonx()}>
								<CloseIcon />
							</IconButton>
						</Tooltip>
					</Box>
				</DialogTitle>
				<DialogContent className={style.myDialog}>
					<DialogContentText id="alert">{content}</DialogContentText>
				</DialogContent>
				<DialogActions sx={{ justifyContent: buttonPosition, padding: 2 }}>
					{Custombuttons.map((button, index) => (
						<Button
							key={index}
							onClick={() => handleClose(index)}
							color={button.color}
							variant={button.variant}
							sx={{ minWidth: '110px' }}>
							{button.name}
						</Button>
					))}
				</DialogActions>
			</Dialog>
		</div>
	);
};
