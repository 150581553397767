export enum FileType {
	Decretos = 1,
	Ley = 2,
	Resolución_o_Disposición = 3,
	Factura_y_o_Remito = 4,
	Titulo_de_Propiedad = 5,
	Informe_Dominio = 6,
	Informe_Técnico = 7,
	Seguro_Vehicular = 8,
	Verificación_Técnica = 9
}
