import { useState, useEffect, FC, Fragment } from 'react';
import {
	useReactTable,
	ColumnFiltersState,
	getCoreRowModel,
	getFilteredRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFacetedMinMaxValues,
	getPaginationRowModel,
	getSortedRowModel,
	FilterFn,
	flexRender,
	Row,
	Cell
} from '@tanstack/react-table';
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';
import { Filter } from './filter';
import { Box, Typography } from '@mui/material';
import './index.css';

declare module '@tanstack/table-core' {
	interface FilterFns {
		fuzzy: FilterFn<unknown>;
	}
	interface FilterMeta {
		itemRank: RankingInfo;
	}
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
	// Rank the item
	const itemRank = rankItem(row.getValue(columnId), value);

	// Store the itemRank info
	addMeta({
		itemRank
	});

	// Return if the item should be filtered in/out
	return itemRank.passed;
};

interface ITableProps {
	elements: any[];
	defaultSortinField?: string;
	itemsPerPage?: number;
	columns: any[];
	headerClassName?: string;
	handleAction?: (row: Row<any>, cell?: Cell<any, any>, evt?: any) => void;
	disableRowClick?: boolean;
}

export const CustomTable: FC<ITableProps> = ({
	elements,
	defaultSortinField = 'name',
	itemsPerPage = 10,
	columns = [],
	headerClassName = 'customColumn',
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	handleAction = (row: Row<any>, cell?: Cell<any, any>, evt?: any) => {}
}) => {
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
	const [globalFilter, setGlobalFilter] = useState('');
	const [rowSelection, setRowSelection] = useState({});
	const [data, setData] = useState<any[]>([]);

	useEffect(() => {
		setData(elements);
	}, [elements]);

	const table = useReactTable({
		data,
		columns,
		filterFns: {
			fuzzy: fuzzyFilter
		},
		state: {
			columnFilters,
			globalFilter,
			rowSelection,
			pagination: {
				pageIndex: 0,
				pageSize: itemsPerPage
			}
		},
		onRowSelectionChange: setRowSelection,
		onColumnFiltersChange: setColumnFilters,
		onGlobalFilterChange: setGlobalFilter,
		globalFilterFn: fuzzyFilter,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getRowId: (row) => row.id
	});

	useEffect(() => {
		if (table.getState().columnFilters[0]?.id === defaultSortinField) {
			if (table.getState().sorting[0]?.id !== defaultSortinField) {
				table.setSorting([{ id: defaultSortinField, desc: false }]);
			}
		}
	}, [table.getState().columnFilters[0]?.id]);

	const resetFilters = (evt: any) => {
		if (evt.target.id !== 'clearFilters') return;
		table.setColumnFilters([]);
		table.setGlobalFilter('');
	};

	/**
	 * `renderSubComponent` is a function that takes an object with a `row` property, and returns a React
	 * component
	 * @param  - `row` - The row object that is being rendered.
	 * @returns A function that returns a component.
	 */
	const renderSubComponent = ({ row }: { row: Row<any> }) => {
		return (
			<Typography
				textAlign={'left'}
				sx={{ p: 2, transition: 'all 2s ease', fontSize: '18px' }}>
				{row.original?.property?.nomenclature_description}
			</Typography>
		);
	};

	const handleLocalAction = (row: Row<any>, cell: Cell<any, any>, evt?: any) => {
		if (!cell.id.includes('nomenclature_description')) {
			handleAction(row, cell);
			return;
		}
	};

	return (
		<table className="custom-table">
			<thead>
				{table.getHeaderGroups().map((headerGroup) => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map((header) => {
							return (
								<th
									key={header.id}
									colSpan={header.colSpan}>
									{header.isPlaceholder ? null : (
										<div
											onClick={resetFilters}
											className={headerClassName}>
											<div
												{...{
													className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
													onClick: header.column.getToggleSortingHandler()
												}}>
												{flexRender(header.column.columnDef.header, header.getContext())}
												{{
													asc: ' 🔼',
													desc: ' 🔽'
												}[header.column.getIsSorted() as string] ?? null}
											</div>
											{header.column.getCanFilter() ? (
												<div>
													<Filter
														column={header.column}
														table={table}
													/>
												</div>
											) : null}
										</div>
									)}
								</th>
							);
						})}
					</tr>
				))}
			</thead>
			<tbody>
				{table.getRowModel().rows.map((row) => {
					return (
						<Fragment key={row.id}>
							<tr
								key={row.id}
								className={row.getIsSelected() ? 'selectedRow' : ''}>
								{row.getVisibleCells().map((cell) => {
									return (
										<td
											style={{ cursor: cell.id.includes('nomenclature_description') ? 'pointer' : 'default' }}
											onClick={(evt) => handleLocalAction(row, cell, evt)}
											key={cell.id}>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</td>
									);
								})}
							</tr>
							{row && row.original.accessoriesTable && row.original.showAccessories ? (
								<Fragment key={`${row.original.id}_accessory`}>{row.original.accessoriesTable}</Fragment>
							) : null}
							{row.getIsExpanded() && (
								<tr style={{ transition: 'all 2s ease' }}>
									{/* 2nd row is a custom 1 cell row */}
									<td
										colSpan={row.getVisibleCells().length}
										style={{ wordBreak: 'break-word' }}>
										{renderSubComponent({ row })}
									</td>
								</tr>
							)}
						</Fragment>
					);
				})}
			</tbody>
		</table>
	);
};
