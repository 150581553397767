import { useMemo, FC } from 'react';
import { Cell, Row } from '@tanstack/react-table';
import { defaultColumns } from './HeritageAssetColumnDef';
import { useDeregistrationContext } from 'hooks/useDeregistrationContext';
import { PaginationTable } from 'components/globals/tabs/PaginationTable';
import { IHeritageAssetTableProps } from 'interfaces/baja/IBaja';
import { CustomTable } from 'components/globals/table/customTable';

export const HeritageAssetsTable: FC<IHeritageAssetTableProps> = ({
	elements,
	handleShow,
	handleDelete,
	handleEdit
}) => {
	const deregistrationContext = useDeregistrationContext();
	const columns = useMemo<typeof defaultColumns>(() => [...defaultColumns], [defaultColumns]);

	const handleAction = (row: Row<any>) => {
		switch (row.original?.action) {
			case 'show':
				handleShow(row.original);
				break;
			case 'edit':
				handleEdit(row.original);
				break;
			case 'delete':
				handleDelete(row.original);
				break;
			default:
				break;
		}
	};

	return (
		<div className={'customBodyContainerTable'}>
			<CustomTable
				columns={columns}
				elements={elements}
				defaultSortinField={'inventary_number'}
				handleAction={(row: Row<any>, cell?: Cell<any, any>, evt?: any) => handleAction(row)}
				itemsPerPage={deregistrationContext?.heritageAssetsMeta?.itemsPerPage}
			/>
		</div>
	);
};
