/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, SetStateAction, memo, Dispatch } from 'react';
import { InputGeneric } from 'components/globals/inputs/InputGeneric';
import { InputAttachGenForEdit } from '../../inputAttachGenForEdit/InputAttachGenForEdit';
import { IStep3VehicleValues } from '../../interfaces';
import { ValidateMaquinas } from '../validateVehicle';
import useDebounce from 'hooks/useDebounce';
import { useMovContext } from 'hooks/useMovContext';
import { toast } from 'react-toastify';

import { Button, Typography, Box } from '@mui/material';
export interface IPaso3MaquinasProps {
	memoizedDataFromChild: (data: SetStateAction<IStep3VehicleValues>) => void;
	setAttachmentFromChild: Dispatch<SetStateAction<File | null>>;
	setUuid: Dispatch<SetStateAction<string>>;
	uuid: string;
	submited: boolean;
	setSubmited: Dispatch<SetStateAction<boolean>>;
}

const Maquinas = memo(
	({ setUuid, submited, setSubmited, memoizedDataFromChild, setAttachmentFromChild, uuid }: IPaso3MaquinasProps) => {
		const movContext = useMovContext();
		const [genericList, setGenericList] = useState<IStep3VehicleValues>({
			vehicleType: 'Máquinas'
		} as IStep3VehicleValues);
		const [domainRegistartion, setDominio] = useState(movContext?.itemSelected?.domainRegistartion || '');
		const [engineNumber, setengineNumber] = useState(movContext?.itemSelected?.engineNumber || '');
		const [chassisNumber, setChassisNumber] = useState(movContext?.itemSelected?.chassisNumber || '');
		const [brand, setBrand] = useState(movContext?.itemSelected?.brand || '');
		const [model, setModel] = useState(movContext?.itemSelected?.model || '');
		const [year, setYear] = useState(movContext?.itemSelected?.year || '');
		const [origin, setOrigin] = useState(movContext?.itemSelected?.origin || '');
		const [owner, setOwner] = useState(movContext?.itemSelected?.owner || '');
		const [type, setTipo] = useState(movContext?.itemSelected?.type || '');
		const [propertyTitle, setPropertyTitle] = useState<File | null>(null);

		const debouncedValue = useDebounce(genericList, 400);

		// Modal edit
		const [editProTitle, setEditProTitle] = useState(false);

		useEffect(() => {
			if (movContext?.itemSelected === null) {
				setEditProTitle(false);
				return;
			}
			const editMovement = movContext?.editMovement;
			let hasAttachmentInDB = false;
			let hasAttachmentInLocal = false;
			if (editMovement) {
				// Check in context
				const attachmentsInContext = movContext?.attachments;
				if (attachmentsInContext && attachmentsInContext?.length > 0) {
					const searchAttachment = attachmentsInContext.find(
						(attachment) => attachment?.id === movContext?.itemSelected?.id
					);
					if (searchAttachment && searchAttachment?.operation === 'create' && movContext?.itemSelected !== null) {
						hasAttachmentInLocal = true;
					}
					if (searchAttachment && searchAttachment?.operation === 'delete' && movContext?.itemSelected !== null) {
						hasAttachmentInLocal = false;
					}
				}
				// Check the movement first.
				const files = editMovement?.property_registration?.files;
				if (files && files?.length > 0) {
					const searchPropertyTitle = files.find((file) => file?.temporary_id === movContext?.itemSelected?.id);
					if (!searchPropertyTitle) {
						hasAttachmentInDB = false;
					} else {
						hasAttachmentInDB = true;
					}
				} else {
					hasAttachmentInDB = false;
				}
				setEditProTitle(hasAttachmentInDB || hasAttachmentInLocal);
			}
		}, []);

		useEffect(() => {
			setGenericList({
				vehicleType: 'Máquinas',
				domainRegistartion,
				engineNumber,
				chassisNumber,
				brand,
				model,
				year,
				origin,
				owner,
				type
			});
			const valid = ValidateMaquinas(genericList as any);
			valid ? memoizedDataFromChild(genericList) : memoizedDataFromChild({} as IStep3VehicleValues);
		}, [movContext?.itemSelected]);

		const handleAdd = (evt: any) => {
			evt.preventDefault();
			if (evt.target.name === 'adjunto') {
				setGenericList({ ...genericList, [evt.target.name]: evt.target.files[0] as File });
			}
			setGenericList({ ...genericList, [evt.target.name]: evt.target.value });
		};

		useEffect(() => {
			const valid = ValidateMaquinas(genericList as any);
			valid ? memoizedDataFromChild(genericList) : memoizedDataFromChild({} as IStep3VehicleValues);
		}, [debouncedValue]);

		useEffect(() => {
			if (propertyTitle?.name) {
				setAttachmentFromChild(propertyTitle);
			}
		}, [propertyTitle]);

		useEffect(() => {
			if (submited && propertyTitle?.name) {
				toast.warning('El nuevo archivo será visible luego de guardar los cambios del movimiento.');
			}
			if (submited) {
				setDominio('');
				setengineNumber('');
				setChassisNumber('');
				setBrand('');
				setModel('');
				setYear('');
				setOrigin('');
				setOwner('');
				setTipo('');
				setPropertyTitle({} as File);
				setGenericList({ vehicleType: 'Máquinas' } as IStep3VehicleValues);
			}
		}, [submited]);

		const handleDeleteAttachment = () => {
			let hasAttachmentInDB = false;

			const editMovement = movContext?.editMovement;
			const files = editMovement?.property_registration?.files;
			if (files && files?.length > 0) {
				const searchPropertyTitle = files.find((file) => file?.temporary_id === movContext?.itemSelected?.id);
				if (!searchPropertyTitle) {
					hasAttachmentInDB = false;
				} else {
					hasAttachmentInDB = true;
				}
			} else {
				hasAttachmentInDB = false;
			}
			///////
			const attachmentsInContext = movContext?.attachments;
			if (attachmentsInContext && attachmentsInContext?.length >= 0) {
				const attachments = attachmentsInContext.filter(
					(attachment) => attachment?.id !== movContext?.itemSelected?.id
				);
				if (hasAttachmentInDB) {
					attachments.push({ id: movContext?.itemSelected?.id, operation: 'delete' });
				} else {
					movContext?.setAttachments(attachments);
				}
			}
			toast.success('El titulo será eliminado después de guardar el movimiento');
			setEditProTitle(false);
		};

		return (
			<div
				onChange={handleAdd}
				style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '55px', rowGap: '55px' }}>
				<InputGeneric
					stateValue={domainRegistartion}
					setStateValue={setDominio}
					label={'Dominio/Matricula'}
					name={'domainRegistartion'}
					pattern={/^[a-zA-Z0-9-]*$/}
				/>
				<InputGeneric
					stateValue={engineNumber}
					setStateValue={setengineNumber}
					label={'Nro. de Motor'}
					name={'engineNumber'}
					required={true}
				/>
				<InputGeneric
					stateValue={chassisNumber}
					setStateValue={setChassisNumber}
					label={'Nro. de Chasis'}
					name={'chassisNumber'}
					required={true}
				/>
				<InputGeneric
					stateValue={brand}
					setStateValue={setBrand}
					label={'Marca'}
					name={'brand'}
					required={true}
					pattern={/^[a-zA-Z\s]*$/}
				/>
				<InputGeneric
					stateValue={model}
					setStateValue={setModel}
					label={'Modelo'}
					name={'model'}
					required={true}
				/>
				<InputGeneric
					stateValue={year}
					setStateValue={setYear}
					label={'Año'}
					name={'year'}
					pattern={/^[0-9]{0,4}$/}
					required={true}
				/>
				<InputGeneric
					stateValue={origin}
					setStateValue={setOrigin}
					label={'Origen/Procedencia'}
					name={'origin'}
					required={true}
				/>
				<InputGeneric
					stateValue={owner}
					setStateValue={setOwner}
					label={'Titular'}
					name={'owner'}
				/>
				<InputGeneric
					stateValue={type}
					setStateValue={setTipo}
					label={'Tipo'}
					name={'type'}
					required={true}
				/>
				{!editProTitle ? (
					<InputAttachGenForEdit
						setFileGen={setPropertyTitle}
						setUuid={setUuid}
						uuid={uuid}
						submited={submited}
						setSubmited={setSubmited}
					/>
				) : (
					<Box sx={{ width: '100%', flexDirection: 'column' }}>
						<Typography sx={{ wordBreak: 'break-word', width: '300px', fontWeight: '600', mb: 1 }}>
							* El vehículo actualmente posee un titulo de propiedad adjunto.
						</Typography>
						<Box sx={{ display: 'flex', flexDirection: 'column', width: '350px', rowGap: '10px' }}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => setEditProTitle(false)}>
								¿Desea editar el titulo de propiedad?
							</Button>
							<Button
								variant="contained"
								color="secondary"
								onClick={handleDeleteAttachment}>
								¿Desea eliminar el titulo de propiedad?
							</Button>
						</Box>
					</Box>
				)}
			</div>
		);
	}
);

Maquinas.displayName = 'Máquinas';

export { Maquinas };
