import { ReactNode } from 'react';
import style from './hr.module.css';

interface IHeaderRoutesProps {
	title: string;
	strongTitle: string;
	children: ReactNode;
}

export const HeaderRoutes = ({ title, strongTitle, children }: IHeaderRoutesProps) => {
	return (
		<div className={style.alta}>
			<div className={style.title}>
				<p className={style.formulario}>{title}</p>
				<p className={style.strongLetter}>{strongTitle}</p>
			</div>
			{children}
		</div>
	);
};
