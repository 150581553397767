/* eslint-disable max-lines */
import { Box } from '@mui/system';
import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { HeaderRoutes } from 'components/globals/headerRoutes/HeaderRoutes';
import React, { useEffect, useState } from 'react';
import {
	useReactTable,
	getCoreRowModel,
	getFilteredRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFacetedMinMaxValues,
	getPaginationRowModel,
	getSortedRowModel,
	flexRender
} from '@tanstack/react-table';
import ClearIcon from '@mui/icons-material/Clear';
import { getMonthlySheets } from 'services/presentationSheetService';
import { currencyLocalFormat, parsedFormatDate } from 'helpers/Formaters/Formaters';
import { OpenInBrowser } from '@mui/icons-material';
import { FormDialog } from 'components/globals/dialog/FormDialog';

const monthOptions = [
	{ id: 1, label: 'Enero' },
	{ id: 2, label: 'Febrero' },
	{ id: 3, label: 'Marzo' },
	{ id: 4, label: 'Abril' },
	{ id: 5, label: 'Mayo' },
	{ id: 6, label: 'Junio' },
	{ id: 7, label: 'Julio' },
	{ id: 8, label: 'Agosto' },
	{ id: 9, label: 'Septiembre' },
	{ id: 10, label: 'Octubre' },
	{ id: 11, label: 'Noviembre' },
	{ id: 12, label: 'Diciembre' }
].filter((month) => month.id <= new Date().getMonth() + 1);

const monthOptionsPastYears = [
	{ id: 1, label: 'Enero' },
	{ id: 2, label: 'Febrero' },
	{ id: 3, label: 'Marzo' },
	{ id: 4, label: 'Abril' },
	{ id: 5, label: 'Mayo' },
	{ id: 6, label: 'Junio' },
	{ id: 7, label: 'Julio' },
	{ id: 8, label: 'Agosto' },
	{ id: 9, label: 'Septiembre' },
	{ id: 10, label: 'Octubre' },
	{ id: 11, label: 'Noviembre' },
	{ id: 12, label: 'Diciembre' }
];

const yearOptions = [
	{ id: 2022, label: '2022' },
	{ id: 2023, label: '2023' }
];

const status = {
	1: 'Pendiente',
	2: 'Aprobada',
	3: 'Enviada',
	4: 'Observada',
	5: 'Rectificada',
	6: 'Consolidada',
	7: 'No presentada'
};

const defaultFilters = { year: '', month: '', status: '' };

interface PresentationSheetManagementTableProps {
	elements: Array<any>;
}

const getMovementData = (sheetDetail: any) => {
	if (sheetDetail.movement?.property_registration) {
		const data = sheetDetail.movement.property_registration;

		let inventoryRange =
			data.properties_items.at(0).item_inventory_number === null ||
			data.properties_items.at(-1).item_inventory_number === null
				? ''
				: `${data.properties_items.at(0).item_inventory_number ?? ''}-${
						data.properties_items.at(-1).item_inventory_number ?? ''
				  }`;

		return {
			inventoryNumber:
				data.properties_items.length > 1 ? inventoryRange : data.properties_items.at(0).item_inventory_number ?? '',
			files: data.files
		};
	}

	if (sheetDetail.movement?.property_deregistration) {
		const data = sheetDetail.movement.property_deregistration;
		let result = null;

		switch (true) {
			case data.property_deregistration_item.length > 1 &&
				data.property_deregistration_item.at(0).property != null &&
				data.property_deregistration_item.at(-1).property != null:
				result = `${data.property_deregistration_item.at(0).property?.item_inventory_number} - ${
					data.property_deregistration_item.at(-1).property?.item_inventory_number
				}`;
				break;
			case data.property_deregistration_item.length >= 1 && data.property_deregistration_item.at(0)?.property != null:
				result = data.property_deregistration_item.at(0)?.property?.item_inventory_number;
				break;
			case data.property_deregistration_item.length > 1 &&
				data.property_deregistration_item.at(0)?.property_timeless?.inventory_number != null &&
				data.property_deregistration_item.at(-1).property_timeless.inventory_number != null:
				let max = data.property_deregistration_item.reduce((prev: any, current: any) =>
					prev.property_timeless.inventory_number > current.property_timeless.inventory_number ? prev : current
				);
				let min = data.property_deregistration_item.reduce((prev: any, current: any) =>
					prev.property_timeless.inventory_number < current.property_timeless.inventory_number ? prev : current
				);
				result = `${Number(min.property_timeless?.inventory_number)} - ${Number(
					max.property_timeless?.inventory_number
				)}`;
				break;
			case data.property_deregistration_item.length >= 1 &&
				data.property_deregistration_item.at(0)?.property_timeless?.inventory_number != null:
				result = data.property_deregistration_item.at(0)?.property_timeless?.inventory_number;
				break;
			default:
				break;
		}

		return {
			inventoryNumber: result,
			files: data.files
		};
	}

	return { files: [] };
};

const PresentationSheetManagementDetailsTable: React.FC<
	PresentationSheetManagementTableProps & { onViewFiles?: CallableFunction }
> = ({ elements, onViewFiles }) => {
	const table = useReactTable({
		data: elements,
		columns: [
			{
				accessorKey: 'date',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Fecha del Movimiento</span>,
				cell: ({ row: { original } }) => {
					return <div>{original.date}</div>;
				}
			},
			{
				accessorKey: 'observation',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Observaciones</span>,
				cell: ({ row: { original } }) => {
					return <div>{original.observations && (original.observations !== 'null' ? original.observations : '-')}</div>;
				}
			},
			{
				accessorKey: 'subresponsable',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Subresponsable</span>,
				cell: ({ row: { original } }) => {
					return <div>{original.subresponsable}</div>;
				}
			},
			{
				accessorKey: 'inventoryNumber',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Nro de Inventario</span>,
				cell: ({ row: { original } }) => {
					return <div>{original.inventoryNumber || '-'}</div>;
				}
			},
			{
				accessorKey: 'files',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Acciones</span>,
				cell: ({ row: { original } }) => {
					return (
						<div>
							<Button
								onClick={() => {
									onViewFiles && onViewFiles(original);
								}}
								id="menu-btn">
								Ver Adjuntos
							</Button>
						</div>
					);
				}
			}
		],
		filterFns: { fuzzy: () => false },
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedMinMaxValues: getFacetedMinMaxValues()
	});

	return (
		<Box>
			<table className="movements-table">
				<thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<th
										key={header.id}
										colSpan={header.colSpan}>
										{header.isPlaceholder ? null : (
											<div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
										)}
									</th>
								);
							})}
						</tr>
					))}
				</thead>

				<tbody>
					{table.getRowModel().rows.map((row, id) => {
						return (
							<React.Fragment key={id}>
								<tr key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<td
												onClick={() => void 0}
												key={cell.id}>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										);
									})}
								</tr>
							</React.Fragment>
						);
					})}

					{elements.length === 0 ? (
						<tr>
							<td>
								<Typography variant="subtitle1">No hay movimientos realizados.</Typography>
							</td>
						</tr>
					) : null}
				</tbody>
			</table>
		</Box>
	);
};

const PresentationSheetManagementTable: React.FC<PresentationSheetManagementTableProps> = ({ elements }) => {
	const table = useReactTable({
		data: elements,
		columns: [
			{
				accessorKey: 'year',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Año</span>,
				cell: ({ row: { original } }) => {
					return <div>{original.year}</div>;
				}
			},
			{
				accessorKey: 'month',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Mes</span>,
				cell: ({ row: { original } }) => {
					return (
						<div>
							{monthOptions.find((month) => month.id === original.month)?.label ||
								monthOptionsPastYears.find((month) => month.id === original.month)?.label}
						</div>
					);
				}
			},
			{
				accessorKey: 'type',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Tipo de Planilla</span>,
				cell: ({ row: { original } }) => {
					return <div>{original.movement_type === 1 ? 'Alta' : 'Baja'}</div>;
				}
			},
			{
				accessorKey: 'real_estate_total',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Total Bienes Inmuebles</span>,
				cell: ({ row: { original } }) => {
					return <div>{currencyLocalFormat(Number(original.real_estate_total))}</div>;
				}
			},
			{
				accessorKey: 'movable_total',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Total Bienes Muebles</span>,
				cell: ({ row: { original } }) => {
					return <div>{currencyLocalFormat(Number(original.movable_total))}</div>;
				}
			},
			{
				accessorKey: 'total',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Total</span>,
				cell: ({ row: { original } }) => {
					return <div>{currencyLocalFormat(Number(original.total))}</div>;
				}
			},
			{
				accessorKey: 'status',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Estado</span>,
				cell: ({ row: { original } }) => {
					const colors: { [key: number]: string } = {
						1: '#f8891b', // pendiente
						2: '#B1AFFF', // aprobada
						3: '#FFD56F', // enviada
						4: '#FF8787', // observada
						5: '#86C8B6', // rectificada
						6: '#66DE93', // consolidada
						7: '#B1AFFF' // no presentada
					};

					return (
						<div>
							<Typography
								sx={{ wordBreak: 'break-all', minWidth: '100px' }}
								padding="4px 6px"
								borderRadius="6px"
								bgcolor={colors[original.status_id as keyof typeof colors]}
								fontSize={14}
								color="black"
								display="inline-block"
								height="auto"
								textTransform={'uppercase'}
								fontWeight={800}>
								{status[original.status_id as keyof typeof status] || '-'}
							</Typography>
						</div>
					);
				}
			},
			{
				accessorKey: 'actions',
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				header: () => <span>Acciones</span>,
				cell: ({ row: { original } }) => {
					return (
						<div>
							<Button
								onClick={() => {
									setShowSubTableContent((current) =>
										current.map((el) => {
											if (el.id === original.id) el.show = !el.show;
											else el.show = false;
											return el;
										})
									);
								}}
								id="menu-btn">
								Ver Detalle
							</Button>
						</div>
					);
				}
			}
		],
		filterFns: { fuzzy: () => false },
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedMinMaxValues: getFacetedMinMaxValues()
	});
	const [showSubTableContent, setShowSubTableContent] = useState<Array<{ id: string; show: boolean }>>([]);
	const [showFilesModal, setShowFilesModal] = useState(false);
	const [selectedRow, setSelectedRow] = useState<any>(null);
	const getSubtableRowById = (id: string) => {
		return showSubTableContent.find((el) => el.id === id) || { id: '0', show: false };
	};

	table.initialState.pagination.pageSize = 50;
	const getSubtableElements = (arr: any[]) => {
		return arr.map((item) => {
			return {
				date: parsedFormatDate(item.movement?.movement_date),
				observations: item.movement?.observation || '-',
				subresponsable: item.sub_responsable,
				movementType: item.movement?.property_registration ? 'Alta' : 'Baja',
				...getMovementData(item)
			};
		});
	};

	useEffect(() => {
		setShowSubTableContent(elements.map((el) => ({ id: el.id, show: false })));
	}, [elements]);

	return (
		<Box>
			<table className="movements-table">
				<thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<th
										key={header.id}
										colSpan={header.colSpan}>
										{header.isPlaceholder ? null : (
											<div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
										)}
									</th>
								);
							})}
						</tr>
					))}
				</thead>

				<tbody>
					{table.getRowModel().rows.map((row, id) => {
						return (
							<React.Fragment key={id}>
								<tr key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<td
												onClick={() => void 0}
												key={cell.id}>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										);
									})}
								</tr>

								{getSubtableRowById(row.original.id).show ? (
									<tr key={'subtable'}>
										<td colSpan={table.getAllColumns().length}>
											<PresentationSheetManagementDetailsTable
												elements={getSubtableElements(row.original.sheet_details)}
												onViewFiles={(row: any) => {
													setShowFilesModal(true);
													setSelectedRow(row);
												}}
											/>
										</td>
									</tr>
								) : null}
							</React.Fragment>
						);
					})}

					{elements.length === 0 ? (
						<tr>
							<td colSpan={table.getAllColumns().length}>
								<Typography variant="subtitle1">No se encontraron resultados para la búsqueda realizada.</Typography>
							</td>
						</tr>
					) : null}
				</tbody>
			</table>

			{showFilesModal && selectedRow ? (
				<FormDialog
					title="Archivos Adjuntos del Movimiento"
					content={
						<Box
							width="100%"
							padding={2}>
							{selectedRow.files.map((file: any, i: number) => {
								return (
									<Box
										key={file.id}
										sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
										<Typography
											fontWeight={500}
											variant="subtitle1">{`${i + 1} - ${file.name}`}</Typography>
										<Button
											title="Ver archivo"
											onClick={() => window.open(file.path)}>
											Ver archivo <OpenInBrowser />
										</Button>
									</Box>
								);
							})}

							{!selectedRow.files.length ? <p>El movimiento no posee archivos adjuntos.</p> : null}
						</Box>
					}
					actions={<></>}
					show={showFilesModal}
					handleClose={() => {
						setShowFilesModal(false);
						setSelectedRow(null);
					}}
				/>
			) : null}
		</Box>
	);
};

export const PresentationSheetManagement: React.FC = () => {
	const [filters, setFilters] = useState(structuredClone(defaultFilters));
	const [sheets, setSheets] = useState<any[]>([]);

	const [yearFilter, setYearFilter] = useState<any>([]);

	if (filters.year == '') {
		let name = 'year';
		let value = yearOptions[yearOptions.length - 1].label;
		setFilters((curr) => ({ ...curr, [name]: value }));
	}

	const handleFiltersChange = (name: keyof typeof defaultFilters) => {
		return (value: unknown) => setFilters((curr) => ({ ...curr, [name]: value }));
	};

	const fetchMovements = async () => {
		setSheets([]);
		const data = await getMonthlySheets({
			year: Number(filters.year) || Number(yearOptions[yearOptions.length - 1].label),
			month: Number(filters.month) || undefined
		});
		setSheets(data);
	};

	const handleResetFilters = () => {
		setFilters(structuredClone(defaultFilters));
	};

	useEffect(() => void fetchMovements(), [filters]);

	return (
		<HeaderRoutes
			title={'Gestión de:'}
			strongTitle={`Planillas de Movimientos Mensuales`}>
			<Typography
				sx={{ my: 2 }}
				fontWeight={600}
				variant="h5">
				Filtrar por:
			</Typography>

			<Box sx={{ display: 'flex', gap: 2, my: 2 }}>
				<FormControl fullWidth>
					{<InputLabel id="select-label">Año</InputLabel>}
					<Select
						size="small"
						value={filters.year}
						onChange={(event) => {
							handleFiltersChange('year')(event.target.value);
						}}
						label="Año">
						{yearOptions.map((item) => (
							<MenuItem
								key={item.id}
								value={item.id}>
								{item.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl fullWidth>
					{<InputLabel id="select-label">Mes</InputLabel>}
					{Number(filters.year) == new Date().getFullYear() ? (
						<Select
							size="small"
							value={filters.month}
							onChange={(event) => {
								handleFiltersChange('month')(event.target.value);
							}}
							label="Mes">
							{monthOptions.map((item) => (
								<MenuItem
									key={item.id}
									value={item.id}>
									{item.label}
								</MenuItem>
							))}
						</Select>
					) : (
						<Select
							size="small"
							value={filters.month}
							onChange={(event) => {
								handleFiltersChange('month')(event.target.value);
							}}
							label="Mes">
							{monthOptionsPastYears.map((item) => (
								<MenuItem
									key={item.id}
									value={item.id}>
									{item.label}
								</MenuItem>
							))}
						</Select>
					)}
				</FormControl>

				<Button
					sx={{ flexShrink: 0 }}
					size="small"
					onClick={handleResetFilters}
					variant="contained">
					Limpiar
					<ClearIcon />
				</Button>
			</Box>

			<PresentationSheetManagementTable elements={sheets} />
		</HeaderRoutes>
	);
};
