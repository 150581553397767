import { FC, ReactNode, useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import style from './headerBtn.module.css';

export interface HeaderBtnProps {
	text: string;
	icon?: ReactNode;
	goTo: string;
	fullWidth?: boolean;
}

interface HeaderListProps {
	title: string;
	icon: ReactNode;
	options: any[];
}

export const HeaderBtn: FC<HeaderBtnProps> = ({ text, icon, goTo, fullWidth }) => {
	const navigate = useNavigate();

	return (
		<Button
			fullWidth={fullWidth}
			sx={{ height: '100%' }}
			className={style.headerBtn}
			variant="text"
			startIcon={icon}
			onClick={() => {
				navigate(goTo, { replace: true });
			}}>
			<strong style={{ whiteSpace: 'nowrap' }}>{text}</strong>
		</Button>
	);
};

export const HeaderListBtn = ({ title, icon, options = [] }: HeaderListProps) => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleSelect = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				sx={{ height: '100%' }}
				className={style.headerBtn}
				variant="text"
				startIcon={icon}
				onClick={(ev) => {
					handleSelect(ev);
				}}>
				<strong style={{ whiteSpace: 'nowrap' }}>{title}</strong>
			</Button>

			<Menu
				anchorEl={anchorEl}
				id="settings-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						minWidth: 200,
						textAlign: 'center',
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0
						}
					}
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
				{options.map((opt) => (
					<div key={opt.key}>{opt}</div>
				))}
			</Menu>
		</>
	);
};
