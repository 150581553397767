import { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { ButtonAuth } from '../../components/globals/customsBtn/customBtn';

//Services
import { sendRecoveryToken, confirmPassword } from 'services/authService';

// Formik
import { useFormik } from 'formik';

//Material-UI
import { Grid } from '@mui/material';
import misiones from 'assets/misiones.svg';
import contaduria from 'assets/contaduria-general.svg';
import style from './login.module.css';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

// Formik & Yup Validations
import { validationResetPasswordSchema } from '../../utils/yupSchemas';

export const ResetPassword = () => {
	const [user, setUser] = useState<any>({});
	const [paramToken, setParamToken] = useState('');
	const [tokenValid, setTokenValid] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (searchParams.get('token') === null) {
			navigate('/login');
		} else {
			const token = searchParams.get('token') || '';
			sendRecoveryToken(token)
				.then((res) => {
					setTokenValid(true);
					setUser(res);
					setParamToken(token);
				})
				.catch((err) => {
					setTokenValid(false);
					navigate('/login', { replace: true });
					toast.error('Token inválido', { autoClose: 3000 });
				});
		}
	}, []);

	/* A hook that is used to validate the form. */

	//TODO: Recieve info by params
	const formik = useFormik({
		initialValues: {
			resetEmail: user.email ? user.email : '',
			password: '',
			passwordConfirmation: ''
		},
		validationSchema: validationResetPasswordSchema,
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: (values) => {
			confirmPassword(user.id, values.password, values.passwordConfirmation, paramToken)
				.then((res) => {
					if (res?.statusCode === '200') {
						toast.success('Contraseña actualizada correctamente', { autoClose: 3000 });
						navigate('/login', { replace: true });
					}
					if (res?.statusCode === '406') {
						toast.error('Error al actualizar contraseña', { autoClose: 3000 });
						navigate('/login', { replace: true });
					}
				})
				.catch((err) => {
					return err;
				});
		}
	});

	const handleSubmit = (e?: React.FormEvent<HTMLFormElement> | undefined) => {
		e?.preventDefault();
		formik.handleSubmit(e);
	};

	/**
	 * When the user clicks on the eye icon, toggle the showPassword state variable between true and false.
	 */
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	return (
		<div className={style.login}>
			{tokenValid && (
				<>
					<Box
						sx={{
							width: '80%',
							mt: '-85px',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}>
						<img
							src={misiones}
							alt="Misiones"
							width={170}
						/>
						<img
							src={contaduria}
							alt="Contaduria"
							width={80}
						/>
					</Box>
					<Container
						component="main"
						maxWidth="xs"
						sx={{ pb: 10 }}>
						<CssBaseline />

						<Box
							sx={{
								marginTop: 8,
								marginLeft: 2,
								marginRight: 2,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center'
							}}>
							<Typography
								component="h1"
								variant="h5"
								className={style.loginTitle}>
								Recuperar Contraseña
							</Typography>
							<Typography
								component="h3"
								sx={{ mt: 3, textAlign: 'center' }}
								className={style.subTitle}>
								Bienvenido {`${user?.first_name} ${user?.last_name}`}
							</Typography>
							<hr />
							<Box
								component="form"
								onSubmit={handleSubmit}
								sx={{ mt: 1 }}>
								<TextField
									id="resetEmail"
									name="resetEmail"
									label="Email"
									value={formik.values.resetEmail}
									disabled={true}
									fullWidth
									sx={{ mt: 1 }}
									autoComplete="off"
								/>
								<TextField
									id="password"
									name="password"
									label="Contraseña"
									type={showPassword ? 'text' : 'password'}
									value={formik.values.password}
									onChange={formik.handleChange}
									error={formik.touched.password && Boolean(formik.errors.password)}
									helperText={formik.touched.password && formik.errors.password}
									fullWidth
									sx={{ mt: 3 }}
									autoComplete="off"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													edge="end">
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
								<TextField
									id="passwordConfirmation"
									name="passwordConfirmation"
									label="Confirmar Contraseña"
									type={showPassword ? 'text' : 'password'}
									value={formik.values.passwordConfirmation}
									onChange={formik.handleChange}
									error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
									helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
									fullWidth
									sx={{ mt: 3 }}
									autoComplete="off"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													edge="end">
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center">
									<Grid item>
										<ButtonAuth
											variant="contained"
											sx={{ mt: 6, height: '50px', width: '160px', padding: 0 }}>
											<Link
												className={style.linkHome}
												to={'/'}
												style={{
													textDecoration: 'none',
													width: '100%',
													height: '100%',
													alignItems: 'center',
													justifyContent: 'center',
													display: 'flex'
												}}>
												Cancelar
											</Link>
										</ButtonAuth>
									</Grid>
									<Grid item>
										<ButtonAuth
											type="submit"
											variant="contained"
											sx={{ mt: 6, height: '50px', width: '160px' }}>
											Guardar
										</ButtonAuth>
									</Grid>
								</Grid>
							</Box>
						</Box>
					</Container>
				</>
			)}
		</div>
	);
};
