// Enums
import { ApiPaths } from 'enums/apiPaths';
import { handleHttpError, buildDefaultOptions, httpGet } from './httpService';

const URL_BASE = `${ApiPaths.REGISTRATION_TYPES}`;

export const tryGetAllRegistrationTypes = async () => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpGet(URL_BASE, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};
