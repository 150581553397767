import { ReactNode, useState } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { RolesContext } from './RolesContext';
import { toast } from 'react-toastify';
import { useAppContext } from 'hooks/useAppContext';
import { ILinks, IMeta } from 'interfaces/commons/IResponse';
import { IRole, IRoleFilters, IRoleResquestBody } from 'interfaces/role/IRole';
import { groupsData, tryCreateRole, tryDeleteRole, tryGetAllRoles, tryUpdateRole } from 'services/rolesService';
import { IPaginationRequest } from 'interfaces/commons/IParameters';

const ROLES_FILTERS = 'ROLES_FILTERS';

export const RolesState = ({ children }: { children: ReactNode | ReactJSXElement }) => {
	const contextoX = useAppContext();

	// START Get All Roles
	const [roles, setRoles] = useState<IRole[]>([] as IRole[]);
	const [rolesMeta, setMeta] = useState<IMeta>({} as IMeta);
	const [rolesLinks, setLinks] = useState<ILinks>({} as ILinks);

	const onHandleTryGetAllRoles = async ({ term }: IRoleFilters, { page, limit }: IPaginationRequest) => {
		setRoles([]);
		contextoX?.onHandleShowSpinner();
		const response = await tryGetAllRoles({ term }, { page, limit });
		contextoX?.onHandleHideSpinner();

		if (response.hasOwnProperty('error')) {
			let message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'Ocurrio un error al recuperar los perfiles!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		setRoles(
			response.items?.map((u: IRole) => {
				return {
					...u,
					group: groupsData.find((group) => group.id === u.group_id)
				};
			})
		);
		setMeta(response.meta);
		setLinks(response.links);
		toast.success('Perfiles recuperados!');
		return true;
	};
	// END Get All Roles

	// START Filter Roles
	const storagedFilters = localStorage.getItem(ROLES_FILTERS);
	const [filters, setFilters] = useState<IRoleFilters>(
		storagedFilters ? JSON.parse(storagedFilters) : ({} as IRoleFilters)
	);

	const onHandleSearByFilters = async ({ term }: IRoleFilters) => {
		setFilters({ term });
		localStorage.setItem(ROLES_FILTERS, JSON.stringify({ term }));
		return onHandleTryGetAllRoles({ term }, { page: rolesMeta.currentPage, limit: rolesMeta.itemsPerPage });
	};

	const onHandleClearFilters = async () => {
		setFilters({ term: undefined });
		localStorage.removeItem(ROLES_FILTERS);
		return onHandleTryGetAllRoles({ term: undefined }, { page: rolesMeta.currentPage, limit: rolesMeta.itemsPerPage });
	};
	// END Filter Roles

	// START Handle Change Page
	const onHandleChangePage = async ({ page = 1, limit = rolesMeta.itemsPerPage }: IPaginationRequest) => {
		return await onHandleTryGetAllRoles({ ...filters }, { page, limit });
	};
	// END Handle Change Page

	// START Delete Role
	const onHandleTryDeleteRole = async (id: number) => {
		contextoX?.onHandleShowSpinner();
		const response = await tryDeleteRole(id);
		contextoX?.onHandleHideSpinner();

		if (response.hasOwnProperty('error')) {
			toast.error('Ocurrio un error al eliminar el perfil!');
			console.error(response.error);
			return false;
		}

		toast.success('Perfil eliminado!');
		return true;
	};
	// END Delete Role

	// START Create Role
	const onHandleTryAddRole = async (entity: IRoleResquestBody) => {
		if (!entity) {
			toast.error('Debe indicar un nuevo perfil para poder agregarlo');
			return false;
		}

		contextoX?.onHandleShowSpinner();
		const response = await tryCreateRole(entity);
		contextoX?.onHandleHideSpinner();

		if (response.hasOwnProperty('error')) {
			let message = response.message
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.message) ? response.message[0] : response.message)
						.concat('!')
				: 'Ocurrio un error al agregar el perfil!';
			toast.error(message);
			return false;
		}

		toast.success('Perfil agregado!');
		return true;
	};
	// END Create Role

	// START Edit Role
	const onHandleTryUpdateRole = async (entity: IRoleResquestBody) => {
		if (!entity) {
			toast.error('Debe indicar un perfil para poder editarlo');
			return false;
		}

		contextoX?.onHandleShowSpinner();
		const response = await tryUpdateRole(entity);
		contextoX?.onHandleHideSpinner();

		if (response.hasOwnProperty('error')) {
			let message = response.messages
				? 'Respuesta del servidor: '
						.concat(Array.isArray(response.messages) ? response.messages[0] : response.messages)
						.concat('!')
				: 'Ocurrio un error al editar el perfil!';
			toast.error(message);
			console.error(response.error);
			return false;
		}

		toast.success('Perfil editado!');
		return true;
	};
	// END Edit Role

	return (
		<RolesContext.Provider
			value={{
				roles,
				rolesMeta,
				rolesLinks,
				onHandleTryGetAllRoles,
				onHandleSearByFilters,
				onHandleClearFilters,
				filters,
				onHandleTryDeleteRole,
				onHandleChangePage,
				onHandleTryAddRole,
				onHandleTryUpdateRole
			}}>
			{children}
		</RolesContext.Provider>
	);
};
