import { FC, Dispatch, SetStateAction, useState, useEffect } from 'react';

import { FormControl, FormHelperText, TextField } from '@mui/material';

interface IInputMultilineProps {
	value?: string;
	handleChange?: Dispatch<SetStateAction<any>>;
	sx?: object;
	name?: string;
	id?: string;
	type?: string;
	label?: string;
	required?: boolean;
	pattern?: RegExp;
	disabled?: boolean;
	hint?: string;
	fullWidth?: boolean;
	error?: string;
	startAdornment?: React.ReactNode;
	endAdornment?: React.ReactNode;
	size?: 'small' | 'medium' | undefined;
	rows?: number | undefined;
	limit?: number | undefined;
	placeholder?: string;
	onChange?(value: unknown): void;
}

export const FormGenericMultiline: FC<IInputMultilineProps> = ({
	value,
	handleChange = () => void 0,
	sx,
	label,
	required = true,
	name = 'generic',
	id = 'generic',
	type = 'text',
	disabled,
	hint,
	fullWidth = true,
	error,
	startAdornment,
	endAdornment,
	size = 'medium',
	rows = 4,
	limit = 255,
	placeholder,
	onChange
}) => {
	const [internalState, setInternalState] = useState(value);

	useEffect(() => {
		setInternalState(value !== undefined ? value : '');
	}, [value]);

	useEffect(() => {
		handleChange(internalState);
	}, [internalState]);

	const hintId = `${id}_hint`;
	const errorId = `${id}_error`;

	const handleMaxLimit = (evt: any) => {
		const regexp = new RegExp(`^.{0,${limit}}$`);
		if (regexp.test(evt.target.value)) {
			setInternalState(evt.target.value);
			return;
		}
	};

	return (
		<FormControl
			required={required}
			fullWidth={fullWidth}>
			<TextField
				disabled={disabled}
				placeholder={placeholder ?? ''}
				id={id}
				type={type}
				name={name}
				size={size}
				sx={sx ? { ...sx } : {}}
				value={internalState}
				onChange={(evt) => {
					handleMaxLimit(evt);
					onChange && onChange(evt.target.value);
				}}
				error={error !== undefined}
				InputProps={{
					startAdornment: startAdornment,
					endAdornment: endAdornment
				}}
				InputLabelProps={{
					shrink: true
				}}
				label={required ? label + ' *' : label}
				multiline
				rows={rows}
			/>
			{!disabled ? (
				<FormHelperText id={'limitCharacters'}>{`${internalState ? internalState?.length.toString() : '0'} de ${
					limit > 0 ? limit.toString() : '0'
				} caracteres permitidos.`}</FormHelperText>
			) : null}
			{hint !== undefined ? <FormHelperText id={hintId}>{hint}</FormHelperText> : null}
			{error !== undefined ? (
				<FormHelperText
					id={errorId}
					error={true}>
					{error}
				</FormHelperText>
			) : null}
		</FormControl>
	);
};
