import { useMemo, FC } from 'react';
import { IRoleTableProps } from 'interfaces/role/IRole';
import { Row, Cell } from '@tanstack/react-table';
import { defaultColumns } from './ProfileColumnDef';
import { useRolesContext } from 'hooks/useRolesContext';
import { PaginationTable } from 'components/globals/tabs/PaginationTable';
import { CustomTable } from 'components/globals/table/customTable';

export const ProfileTable: FC<IRoleTableProps> = ({ elements, handleEditRole, handleDeleteRole }) => {
	const rolesContext = useRolesContext();
	const columns = useMemo<typeof defaultColumns>(() => [...defaultColumns], [defaultColumns]);

	const handleAction = (row: Row<any>, cell?: Cell<any, any>, evt?: any) => {
		if (!cell?.id?.includes('actions')) {
			return;
		}
		switch (row.original?.action) {
			case 'edit':
				handleEditRole(row.original);
				break;
			case 'delete':
				handleDeleteRole(row.original);
				break;
			default:
				break;
		}
	};

	return (
		<div className={'customBodyContainerTable'}>
			<PaginationTable
				currentPage={rolesContext?.rolesMeta?.currentPage}
				totalPages={rolesContext?.rolesMeta?.totalPages}
				showDetails={true}
				itemsPerPage={rolesContext?.rolesMeta?.itemsPerPage}
				totalItems={rolesContext?.rolesMeta?.itemCount}
				handleChangePage={(page: number, limit?: number) => rolesContext?.onHandleChangePage({ page: page, limit })}
			/>

			<CustomTable
				columns={columns}
				elements={elements}
				itemsPerPage={rolesContext?.rolesMeta?.itemsPerPage}
				handleAction={(row: Row<any>, cell?: Cell<any, any>, evt?: any) => handleAction(row, cell, evt)}
			/>

			<div className="h-2" />
			<PaginationTable
				currentPage={rolesContext?.rolesMeta?.currentPage}
				totalPages={rolesContext?.rolesMeta?.totalPages}
				itemsPerPage={rolesContext?.rolesMeta?.itemsPerPage}
				totalItems={rolesContext?.rolesMeta?.totalItems}
				handleChangePage={(page: number, limit?: number) => rolesContext?.onHandleChangePage({ page: page, limit })}
			/>
		</div>
	);
};
