import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';

// Services
import { getLastMinuteMovements, updateMovementStatus } from 'services/Movements/crudMovements';

// Interfaces
import { IMovementEdit } from 'interfaces/movements/MovementEdit';

// Material UI
import { Typography, Box, TextField, Button } from '@mui/material';
import { BounceLoader } from 'react-spinners';
import { MovementStatusType } from 'enums';
import { FormDialog } from 'components/globals/dialog/FormDialog';

interface IModalApproveProps {
	openModal: boolean;
	setOpenModal: Dispatch<SetStateAction<boolean>>;
	selectedRow: any;
	data: IMovementEdit[];
	setData: Dispatch<SetStateAction<IMovementEdit[]>>;
	onSuccess?: CallableFunction;
}

export const ApproveMovement = ({
	openModal,
	setOpenModal,
	selectedRow,
	data,
	setData,
	onSuccess
}: IModalApproveProps) => {
	const [receptionNumber, setReceptionNumber] = useState('');
	const [lastReception, setLastReception] = useState<number>(1);
	const [open, setOpen] = useState(true);
	const [loading, setLoading] = useState(false);
	const [opacity, setOpacity] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [loadingData, setLoadingData] = useState(false);
	const [disabledDownload, setDisabledDownload] = useState(false);

	useEffect(() => {
		setLoadingData(true);
		setReceptionNumber(String(Number(lastReception) + 1));

		getLastMinuteMovements()
			.then((res) => {
				if (!res.data) return;
				setLastReception(Number(String(res.data.last_minute)));
			})
			.finally(() => {
				setLoadingData(false);
			});
	}, []);

	useEffect(() => {
		setReceptionNumber(String(lastReception + 1));
	}, [lastReception]);

	useEffect(() => {
		if (selectedRow.movement_status_id !== MovementStatusType.PENDIENTE) {
			setDisabledDownload(true);
			setDisabled(true);
			return;
		}
		if (Number(receptionNumber) <= Number(lastReception)) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	}, [receptionNumber, lastReception, selectedRow]);

	const handleClose = () => {
		setOpen(false);
		setOpenModal(false);
	};

	const handleLoading = () => {
		setLoading(true);
		setTimeout(() => {
			setOpacity(true);
		}, 300);
	};

	const saveChanges = () => {
		handleLoading();
		updateMovementStatus(selectedRow.id, MovementStatusType.APROBADO, Number(receptionNumber))
			.then((res) => {
				if (res?.name?.includes('Error')) {
					setTimeout(() => {
						handleClose();
						setDisabledDownload(true);
						setLoading(false);
						setOpacity(false);
						toast.error(res.response.data.message);
					}, 2000);
					return;
				}
				const selectRow = data.find((item) => item.id === selectedRow.id);
				if (selectRow) {
					selectRow.movement_status_id = MovementStatusType.APROBADO;
				}
				setData([...data]);

				handleClose();
				setDisabledDownload(true);
				setLoading(false);
				setOpacity(false);
				toast.success('Movimiento aprobado con éxito');
				onSuccess && onSuccess();
			})
			.catch((error) => {
				setLoading(false);
				setOpacity(false);
				toast.error(error?.response?.data?.message || 'Error al aprobar el movimiento');
			});
	};

	return (
		<FormDialog
			title="¿Desea confirmar el registro?"
			show={openModal}
			handleClose={() => setOpenModal(false)}
			content={
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
					<Box>
						<Typography
							variant="body1"
							fontWeight={'500'}
							sx={{ color: 'rgb(6, 37, 46)', mb: 2 }}>
							Se generará el número de Acta
						</Typography>
						<TextField
							type="number"
							disabled={selectedRow?.movement_status_id !== MovementStatusType.PENDIENTE}
							autoComplete="off"
							name="actaRecepcion"
							id="actaRecepcion"
							label="Número de Acta de Recepción"
							size="small"
							value={receptionNumber}
							onChange={(evt) => setReceptionNumber(evt.target.value)}
						/>
						{selectedRow?.property_registration?.minute_number === null ? (
							<>
								{' '}
								<Typography
									sx={{
										color: 'rgb(6, 37, 46)',
										mt: 1,
										fontSize: '0.8rem'
									}}>{`* Debe ingresar un número mayor al de la última acta generada`}</Typography>
								<Typography
									variant="body1"
									fontWeight={'500'}
									sx={{ color: 'rgb(6, 46, 9)', mt: 1, ml: 1 }}>
									{`N° de última acta generada: ${lastReception}`}
								</Typography>
								<Typography
									variant="body1"
									fontWeight={'500'}
									sx={{ color: 'rgb(6, 37, 46)', mt: 1, ml: 1 }}>
									{`Se sugiere utilizar el número: ${Number(lastReception) + 1}`}
								</Typography>{' '}
							</>
						) : null}
					</Box>

					{loading && (
						<Box
							sx={{
								zIndex: '9999',
								opacity: opacity ? '1' : '0',
								position: 'absolute',
								backdropFilter: 'blur(2px)',
								height: '100%',
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								transition: 'all 800ms ease-in-out'
							}}>
							<BounceLoader
								size={100}
								speedMultiplier={1.5}
							/>
						</Box>
					)}
				</Box>
			}
			actions={
				<Box
					id="boxButtonsApproveMovement"
					sx={{ display: 'flex', justifyContent: 'center', gap: 3, padding: 2 }}>
					<Button
						onClick={handleClose}
						sx={{ width: '180px' }}
						color="secondary"
						variant="contained">
						Salir
					</Button>

					<Button
						disabled={loadingData || disabled || selectedRow?.movement_status_id !== MovementStatusType.PENDIENTE}
						onClick={saveChanges}
						sx={{ width: '180px', mr: 4 }}
						color="primary"
						variant="contained">
						Confirmar
					</Button>
				</Box>
			}
		/>
	);
};
