import { Typography, Grid } from '@mui/material';
import { IFieldPropertie } from 'interfaces/commons/IParameters';
import { useState } from 'react';

export const GenericFieldDetail = ({ label = '', value = undefined, paddingLeft = '0px' }: IFieldPropertie) => {
	const [showWrap, setShowWrap] = useState(true);

	return (
		<Grid
			item
			xs={12}
			sm={12}
			md={12}
			lg={12}
			xl={12}>
			<Grid
				container
				spacing={2}>
				{label !== undefined && label !== null ? (
					<Grid
						item
						xs={12}
						sm={4}
						md={4}
						lg={4}
						xl={4}>
						<Typography
							variant="subtitle2"
							style={{ fontWeight: 'bold', paddingLeft: paddingLeft }}>
							{label}
						</Typography>
					</Grid>
				) : null}

				<Grid
					item
					xs={12}
					sm={8}
					md={8}
					lg={8}
					xl={8}
					style={{ position: 'relative' }}>
					<Typography
						onClick={() => setShowWrap(!showWrap)}
						noWrap={showWrap}
						variant="body1"
						sx={{ wordBreak: 'break-word' }}>
						{value ? value : '-'}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};
