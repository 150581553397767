import { useState, FC, ReactNode, ReactElement, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { checkRole } from './checkRole';

export interface UsePermissionsProps {
	children: ReactElement | ReactNode;
	role: number[];
	resource: string[];
	style?: object;
}

export const UseRPermissions: FC<UsePermissionsProps> = ({ style, children, role, resource }) => {
	const [isPermitted, setIsPermitted] = useState(true);
	const { user } = useAuth();

	const checkResource = (resource: string[]) => {
		setIsPermitted(true);
		if (resource.length === 0) {
			return false;
		}
		if (resource.length > 0) {
			for (let i = 0; i < resource.length; i++) {
				if (user?.role?.role_actions?.some((action) => action.resource.includes(resource[i]))) {
					return true;
					break;
				}
			}
		}
		return false;
	};

	const checkPermissions = () => {
		if (checkRole(user, role) === false) {
			setIsPermitted(false);
			return;
		}
		if (checkResource(resource) === false) {
			setIsPermitted(false);
			return;
		}
	};

	useEffect(() => {
		setIsPermitted(true);
		if (user?.role?.name?.includes('Super')) return;
		if (user.role) {
			checkPermissions();
		}
	}, [user.role, role, resource]);

	return isPermitted ? (
		<div
			style={style}
			id="userPermissions"
			key={user.id}>
			{children}
		</div>
	) : null;
};
