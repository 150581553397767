// Enums
import { ApiPaths } from 'enums/apiPaths';
import apiInstance from 'interceptors/axiosInterceptor';
import { IPaginationRequest } from 'interfaces/commons/IParameters';
import { IRoleFilters, IRoleResquestBody } from 'interfaces/role/IRole';
import { toast } from 'react-toastify';

import {
	httpPost,
	handleHttpError,
	httpPatch,
	buildDefaultOptions,
	httpGet,
	httpDelete,
	buildURLQuery
} from './httpService';

const URL_BASE = `${ApiPaths.ROLES}`;
const URL_WITH_ID = (id: number) => {
	return URL_BASE + `/${id}`;
};

export const tryGetAllRoles = async ({ term }: IRoleFilters, { page, limit }: IPaginationRequest) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const queryString = buildURLQuery({ term, page, limit });
		const URI = `${URL_BASE}?${queryString}`;
		let response = await httpGet(URI, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryGetRoleByID = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpGet(URL_WITH_ID(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryCreateRole = async (entity: IRoleResquestBody) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpPost(URL_BASE, entity, OPTIONS);
		console.log(response);
		return response?.data;
	} catch (error: any) {
		toast.error(error.response.data?.message[0]);
		return {
			error,
			success: false,
			messages: error.response.data?.message
		};
	}
};

export const tryUpdateRole = async (entity: IRoleResquestBody) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const { id, ...payload } = entity;

		let response = await httpPatch(URL_WITH_ID(id ? id : 0), payload, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryDeleteRole = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpDelete(URL_WITH_ID(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};
export const groupsData = [
	{
		name: 'Dependencia Patrimonial',
		id: 1,
		profiles: [
			{ name: 'Operador de la Dependencia Patrimonial', id: 5 },
			{ name: 'Encargado Patrimonial', id: 4 }
		]
	},
	{
		name: 'Contaduría General',
		id: 2,
		profiles: [
			{ name: 'Director de Bienes Fiscales', id: 2 },
			{ name: 'Operador de Dirección de Bienes Fiscales', id: 3 },
			{ name: 'Director de Informática', id: 1 }
		]
	}
];
export async function getUserGroups() {
	return groupsData;
}
