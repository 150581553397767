import style from './home.module.css';
import { UseRPermissions } from 'utils/userPermissions/UserPermissions';
import { NovedadesTable } from 'components/EncPatrimonial/table/NovedadesTable';
import { BFTabs } from 'components/BienesFiscales/tabs/BFTabs';
import { RoleById } from 'enums';
import { HeaderRoutes } from 'components/globals/headerRoutes/HeaderRoutes';
import { BFProvider } from 'contexts/bienesFiscalesContext/BFProvider';
import { NovedadesProvider } from 'contexts/novedadesContext/NovedadesProvider';

export const Home = () => {
	return (
		<div className={style.home}>
			<UseRPermissions
				style={{ width: '100%' }}
				role={[RoleById.Operador_Dir_de_BienesFiscales, RoleById.Director_de_BienesFiscales]}
				resource={['manage:Dashboard']}>
				<HeaderRoutes
					title={''}
					strongTitle={'Bienes Fiscales'}>
					<BFProvider>
						<BFTabs />
					</BFProvider>
				</HeaderRoutes>
			</UseRPermissions>
			<UseRPermissions
				style={{ width: '100%' }}
				role={[RoleById.Responsable_Patrimonial, RoleById.Operador_Dependencia_Patrimonial]}
				resource={['manage:Dashboard']}>
				<HeaderRoutes
					title={''}
					strongTitle={'Novedades'}>
					<NovedadesProvider>
						<NovedadesTable />
					</NovedadesProvider>
				</HeaderRoutes>
			</UseRPermissions>
		</div>
	);
};
