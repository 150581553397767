import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ButtonAuth } from '../../components/globals/customsBtn/customBtn';
import { toast } from 'react-toastify';

//Components
import { Spinner } from '../../components/globals/spinner/Spinner';

// Formik
import { useFormik } from 'formik';

//Material-UI
import misiones from '../../assets/misiones.svg';
import contaduria from '../../assets/contaduria-general.svg';
import style from './login.module.css';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { Button, Grid } from '@mui/material';

import { sendUserResetPasswordEmail } from 'services/authService';

// Formik & Yup Validations
import { validationEmailResetSchema } from '../../utils/yupSchemas';

export const SendResetEmail = () => {
	const [isLoading, setLoading] = useState(false);
	const [resMessage, setResMessage] = useState('');
	const [resError, setResError] = useState('');
	const navigate = useNavigate();
	//TODO: Recieve info by params
	const formik = useFormik({
		initialValues: {
			resetEmail: ''
		},
		validationSchema: validationEmailResetSchema,
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: (values) => {
			//TODO: Send the values to the server
			setLoading(true);
			setResMessage('');
			setResError('');
			sendUserResetPasswordEmail(values.resetEmail)
				.then((res) => {
					setResMessage(res.message);
					setLoading(false);
				})
				.catch((err) => {
					setResError(err.response.data.message);
					setLoading(false);
				});
		}
	});

	const handleSubmit = (e?: React.FormEvent<HTMLFormElement> | undefined) => {
		e?.preventDefault();
		formik.handleSubmit(e);
	};

	return (
		<>
			{isLoading && <Spinner />}
			<div className={style.login}>
				<Box
					sx={{
						width: '80%',
						mt: '-85px',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center'
					}}>
					<img
						src={misiones}
						alt="Misiones"
						width={170}
					/>
					<img
						src={contaduria}
						alt="Contaduria"
						width={80}
					/>
				</Box>
				<Container
					component="main"
					maxWidth="xs"
					sx={{ pb: 10 }}>
					<CssBaseline />

					<Box
						sx={{
							marginTop: 8,
							marginLeft: 2,
							marginRight: 2,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center'
						}}>
						<Typography
							variant="h5"
							className={style.resetTitle}>
							Vamos a enviarte un link para recuperar la contraseña.
						</Typography>

						<hr />
						<Box
							component="form"
							onSubmit={handleSubmit}
							sx={{ mt: 1 }}>
							<TextField
								id="resetEmail"
								name="resetEmail"
								label="Ingresar su email"
								value={formik.values.resetEmail}
								onChange={formik.handleChange}
								error={formik.touched.resetEmail && Boolean(formik.errors.resetEmail)}
								helperText={formik.touched.resetEmail && formik.errors.resetEmail}
								fullWidth
								sx={{ mt: 1 }}
							/>
							{resMessage && (
								<Box sx={{ display: 'flex', flexDirection: 'column', pl: 1 }}>
									<Typography
										sx={{ pb: 2, pt: 2, color: '#085e00' }}
										variant="body2">
										{resMessage}
									</Typography>
								</Box>
							)}
							{resError && (
								<Box sx={{ display: 'flex', flexDirection: 'column', pl: 1 }}>
									<Typography
										sx={{ pb: 2, pt: 2 }}
										color="error"
										variant="body2">
										{resError}
									</Typography>
								</Box>
							)}
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								sx={{ flexWrap: 'nowrap', justifyContent: 'center' }}>
								{!resMessage && (
									<>
										<Grid item>
											<ButtonAuth
												variant="contained"
												sx={{ mt: 6, mr: 3, height: '50px', width: '160px', padding: 0 }}>
												<Link
													to={'/'}
													style={{
														textDecoration: 'none',
														width: '100%',
														height: '100%',
														alignItems: 'center',
														justifyContent: 'center',
														display: 'flex'
													}}>
													Cancelar
												</Link>
											</ButtonAuth>
										</Grid>
										<Grid item>
											<ButtonAuth
												type="submit"
												variant="contained"
												sx={{ mt: 6, ml: 3, height: '50px', width: '160px' }}>
												Enviar
											</ButtonAuth>
										</Grid>
									</>
								)}
								{resMessage && (
									<Grid item>
										<Button
											onClick={() => navigate('/login', { replace: true })}
											color="primary"
											variant="contained"
											sx={{ mt: 6, height: '50px', width: '160px' }}>
											Ir al login
										</Button>
									</Grid>
								)}
							</Grid>
						</Box>
					</Box>
				</Container>
			</div>
		</>
	);
};
