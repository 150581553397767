import { ColumnDef } from '@tanstack/react-table';
import { IconButton, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { currencyLocalFormat, parsedFormatDate } from 'helpers/Formaters/Formaters';

export const defaultColumns: ColumnDef<any, any>[] = [
	{
		accessorKey: 'nomenclator_code',
		enableSorting: true,
		header: () => <span>Código</span>,
		cell: ({ getValue }) => <p style={{ textAlign: 'center', paddingLeft: '10px' }}>{getValue() as string}</p>
	},
	{
		accessorKey: 'item_inventory_number',
		enableSorting: true,
		header: () => <span>Nro Inventario</span>,
		cell: ({ getValue }) => <p style={{ textAlign: 'center', paddingLeft: '10px' }}>{getValue() as string}</p>
	},
	{
		accessorKey: 'description',
		header: () => <span>Descripción del Bien</span>,
		cell: ({ getValue }) => <p style={{ textAlign: 'justify', paddingLeft: '10px' }}>{getValue() as string}</p>
	},
	{
		accessorKey: 'date',
		header: () => <span>Fecha de Alta</span>,
		cell: ({ getValue }) => <p style={{ textAlign: 'center', paddingLeft: '10px' }}>{parsedFormatDate(getValue())}</p>
	},
	{
		accessorKey: 'total',
		header: () => <span>Importe Total</span>,
		cell: ({ getValue }) => <p style={{ textAlign: 'right', paddingLeft: '10px' }}>{currencyLocalFormat(getValue())}</p>
	},
	{
		accessorKey: 'minute_number',
		header: () => <span>Nro Acta Recepción</span>,
		cell: ({ getValue }) => <p style={{ textAlign: 'center', paddingLeft: '10px' }}>{getValue() as string}</p>
	},
	{
		accessorKey: 'reponsability',
		header: () => <span>Subresponsable</span>,
		cell: ({ getValue }) => <p style={{ textAlign: 'left', paddingLeft: '10px' }}>{getValue() as string}</p>
	},
	{
		accessorKey: 'documentPatch',
		enableSorting: false,
		enableGlobalFilter: false,
		enableColumnFilter: false,
		header: () => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>Ver Acta</span>
			</div>
		),
		cell: ({ row }) => {
			return (
				<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', width: '100%', justifyContent: 'space-around' }}>
					<IconButton
						disabled={row.original?.files?.length === 0}
						onClick={() => {
							if (row.original?.files?.length !== 0) {
								row.original.action = 'attachments';
							}
						}}
						size="small"
						sx={{
							transition: 'all 200ms ease',
							'&:hover': {
								transform: 'scale(1.3)',
								transition: 'all 200ms ease'
							}
						}}>
						<Tooltip
							placement="right-start"
							arrow
							title={'Documentos Adjuntos'}>
							{row.original?.files?.length === 0 ? <VisibilityOffIcon /> : <VisibilityIcon />}
						</Tooltip>
					</IconButton>
				</div>
			);
		}
	}
];
