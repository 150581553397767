import { styled } from '@mui/material/styles';
import { StepIconProps } from '@mui/material/StepIcon';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import DoneIcon from '@mui/icons-material/Done';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import VisibilityIcon from '@mui/icons-material/Visibility';

/*
 * A styled component.
 * Definition of the Stepper connector and classes of the stepper.
 */
export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	width: '50%',
	left: '-30%',

	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 37
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundImage: 'linear-gradient(95deg, rgba(2,0,36,1) 0%,  rgba(163,217,240,1) 100%)'
		}
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundImage: 'linear-gradient( 95deg, rgba(2,0,36,1) 0%,  rgba(163,217,240,1) 100%)'
		}
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 2,
		border: 0,
		backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#575757',
		borderRadius: 1
	},
	':after': {
		content: '""',
		position: 'absolute',
		zIndex: 1,
		right: '-10px',
		top: '-1px',
		width: 0,
		height: 0,
		borderLeft: '15px solid transparent',
		borderRight: '15px solid #575757',
		borderTop: '15px solid transparent',
		clear: 'both',
		transform: 'rotate(315deg)'
	}
}));

export const ColorlibStepIcon = (props: StepIconProps) => {
	const { active, completed, className } = props;

	const icons: { [index: string]: React.ReactElement } = {
		1: completed ? <DoneIcon fontSize="medium" /> : <DescriptionOutlinedIcon fontSize="medium" />,
		2: completed ? <DoneIcon fontSize="medium" /> : <Inventory2Icon fontSize="medium" />,
		3: completed ? <DoneIcon fontSize="medium" /> : <SourceOutlinedIcon fontSize="medium" />,
		4: completed ? <DoneIcon fontSize="medium" /> : <VisibilityIcon fontSize="medium" />
	};

	return (
		<ColorlibStepIconRoot
			ownerState={{ completed, active }}
			className={className}>
			{icons[String(props.icon)]}
		</ColorlibStepIconRoot>
	);
};

/*
 * A styled component.
 * Definition of the Icons of the stepper
 */
export const ColorlibStepIconRoot = styled('div')<{
	ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
	backgroundColor: ownerState.completed ? '#357397' : 'transparent',
	zIndex: 1,
	color: !ownerState.completed ? '#000000' : '#ebebeb',
	border: ownerState.completed || ownerState.active ? '2px solid #e9e9e9' : '2px solid #5e5e5e',
	width: 50,
	height: 50,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...(ownerState.active && {
		background: 'transparent',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
	}),
	...(ownerState.completed && {
		backgroundImage: 'transparent'
	})
}));

/* Steps. */
export const steps = ['Instrumento Legal', 'Documentos Adjuntos', 'Bienes Patrimoniales', 'Finalizar'];
