// Enums
import { ApiPaths } from 'enums/apiPaths';
import { IPaginationRequest } from 'interfaces/commons/IParameters';
import { IPatrimonialDependence, IPatrimonialDependenceFilters } from 'interfaces/patrimonial/IPatrimonial';

import {
	handleHttpError,
	buildDefaultOptions,
	httpGet,
	buildURLQuery,
	httpPost,
	httpDelete,
	httpPatch
} from './httpService';

const URL_BASE = `${ApiPaths.PATRIMONIAL_DEPENDENCES}`;
const URL_SEARCH = `${URL_BASE}/search`;
const URL_WITH_ID = (id: number | undefined) => {
	return URL_BASE + `/${id}`;
};

export const tryGetAllPatrimonialDependences = async (
	filters?: IPatrimonialDependenceFilters,
	pagination?: IPaginationRequest
) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let parameters = {};
		if (filters) {
			parameters = { ...filters };
		}
		if (pagination) {
			parameters = { ...parameters, ...pagination };
		}
		const queryString = buildURLQuery(parameters);
		let URI;
		if (filters?.term !== undefined) {
			URI = `${URL_SEARCH}?${queryString}`;
		} else {
			URI = `${URL_BASE}?${queryString}`;
		}
		const response = await httpGet(URI, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryGetAllPatrimonialDependencesPaginated = async (
	filters?: IPatrimonialDependenceFilters,
	pagination?: IPaginationRequest
) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let parameters = {};
		if (filters) {
			parameters = { ...filters };
		}
		if (pagination) {
			parameters = { ...parameters, ...pagination };
		}
		const queryString = buildURLQuery(parameters);
		let URI = `${URL_BASE}/paginate?${queryString}`;
		let response = await httpGet(URI, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryGetPatrimonialDependenceByID = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpGet(URL_WITH_ID(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryCreateOrUpdatePatrimonialDependence = async (entity: IPatrimonialDependence) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response;
		const id: number | undefined = entity.id;
		if (!entity.id) {
			response = await httpPost(URL_BASE, entity, OPTIONS);
		} else {
			delete entity.id;
			response = await httpPatch(URL_WITH_ID(id), entity, OPTIONS);
		}
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryDeletePatrimonialDependence = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpDelete(URL_WITH_ID(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};
