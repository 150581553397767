/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { Box } from '@mui/material';
import { ButtonConfirm, ButtonDecline } from 'components/globals/customsBtn/customBtn';
import { DEFAULT_NO_ERROR, fieldValidator, passwordsAreEquals } from 'helpers/Validators/Validators';
import { IChangePasswordResquestBody } from 'interfaces/user/IUser';
import { FormCredentials } from 'components/globals/layouts/FormCredentials';
import { FormGenericPassword } from 'components/globals/inputs/FormGenericPassword';

export interface IOverviewUserChangePasswordForm {
	handleSubmit: (data: IChangePasswordResquestBody) => void;
	handleCancel: () => void;
}

export interface IErrorsForm {
	actualPassword?: string | undefined;
	password?: string | undefined;
	repeatPassword?: string | undefined;
}

export const LABELS_CHANGE_PASSWORD_FORM = {
	actualPassword: 'Contraseña Actual',
	password: 'Contraseña Nueva',
	repeatPassword: 'Ingrese nuevamente la Nueva Contraseña'
};

export const OverviewUserChangePasswordForm = ({ handleSubmit, handleCancel }: IOverviewUserChangePasswordForm) => {
	const [actaulPassword, setActualPassword] = useState<string>('');
	const [newPassword, setNewPassword] = useState<string>('');
	const [confirmPassword, setConfirmPassword] = useState<string>('');
	const [errors, setErrors] = useState<IErrorsForm>({} as IErrorsForm);

	const validateForm = (): boolean => {
		let newErrors: IErrorsForm = {} as IErrorsForm;
		setErrors(newErrors);

		let actualPasswordError = fieldValidator({
			value: actaulPassword,
			label: LABELS_CHANGE_PASSWORD_FORM.actualPassword,
			minLength: 8,
			maxLength: 128
		});
		if (actualPasswordError !== DEFAULT_NO_ERROR) {
			newErrors.actualPassword = actualPasswordError;
		}

		let passwordError = fieldValidator({ value: newPassword, label: LABELS_CHANGE_PASSWORD_FORM.password, minLength: 8, maxLength: 128 });
		if (passwordError !== DEFAULT_NO_ERROR) {
			newErrors.password = passwordError;
		}

		let repeatPasswordError = fieldValidator({
			value: confirmPassword,
			label: LABELS_CHANGE_PASSWORD_FORM.repeatPassword,
			minLength: 8,
			maxLength: 128
		});
		if (repeatPasswordError !== DEFAULT_NO_ERROR) {
			newErrors.repeatPassword = repeatPasswordError;
		}

		if (passwordError === DEFAULT_NO_ERROR && repeatPasswordError === DEFAULT_NO_ERROR) {
			let equalsError = passwordsAreEquals({ password1: newPassword, password2: confirmPassword });
			if (equalsError !== DEFAULT_NO_ERROR) {
				newErrors.password = equalsError;
				newErrors.repeatPassword = equalsError;
			}
		}

		if (Object.entries(newErrors).length !== 0) {
			setErrors(newErrors);
			return false;
		}

		return true;
	};

	const getParsedData = (): IChangePasswordResquestBody => {
		return {
			old_password: actaulPassword ? actaulPassword : '',
			password: newPassword ? newPassword : '',
			confirm_password: confirmPassword ? confirmPassword : ''
		};
	};

	const onHandleSubmit = (): void => {
		if (!validateForm()) {
			return;
		}
		handleSubmit(getParsedData());
	};

	return (
		<div className="borderedBox">
			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '30px', rowGap: '30px', paddingTop: '30px' }}>
				<FormGenericPassword
					value={actaulPassword}
					label={LABELS_CHANGE_PASSWORD_FORM.actualPassword}
					name="actualPassword"
					id="actualPassword"
					handleChange={setActualPassword}
					error={errors?.actualPassword}
				/>

				<FormCredentials
					title={'Nuevas Credenciales de Acceso'}
					password={newPassword}
					repeatPassword={confirmPassword}
					handleChangePassword={setNewPassword}
					handleChangeRepeatPassword={setConfirmPassword}
					errorPassword={errors.password}
					errorRepeatPassword={errors.repeatPassword}
					labelPassword={LABELS_CHANGE_PASSWORD_FORM.password}
					labelRepeatPassword={LABELS_CHANGE_PASSWORD_FORM.repeatPassword}
				/>
			</Box>

			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
				<ButtonDecline
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => {
						handleCancel();
					}}>
					Cancelar
				</ButtonDecline>
				<ButtonConfirm
					sx={{ ml: 3 }}
					variant="contained"
					onClick={() => onHandleSubmit()}>
					Guardar
				</ButtonConfirm>
			</Box>
		</div>
	);
};
