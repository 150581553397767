import { FC, useState, useEffect } from 'react';

import { FormControl, FormHelperText, TextField } from '@mui/material';
import { is } from 'date-fns/locale';

interface IInputTextProps {
	value?: string;
	handleChange?: CallableFunction;
	sx?: object;
	name?: string;
	id?: string;
	type?: string;
	label?: string;
	required?: boolean;
	pattern?: RegExp;
	disabled?: boolean;
	hint?: string;
	fullWidth?: boolean;
	error?: string;
	startAdornment?: React.ReactNode;
	endAdornment?: React.ReactNode;
	size?: 'small' | 'medium' | undefined;
	fontSize?: 'unset' | string;
	maxLength?: number;
	onChange?(val: unknown): void;
	onKeydown?(event: React.KeyboardEvent<HTMLDivElement>): void;
	regexValidation?: RegExp;
	messageFormatError?: string;
}

export const FormGenericInput: FC<IInputTextProps> = ({
	value,
	handleChange = () => void 0,
	sx,
	label,
	required,
	name = 'generic',
	id = 'generic',
	type = 'text',
	disabled = false,
	hint,
	fullWidth = true,
	error,
	startAdornment,
	endAdornment,
	size = 'medium',
	maxLength,
	onChange,
	onKeydown,
	regexValidation,
	messageFormatError
}) => {
	const [internalState, setInternalState] = useState('');
	const [valueHasChnaged, setValueHasChnaged] = useState<number>(0);
	const [isValid, setIsValid] = useState<boolean>(true);
	const validateInput = (inputValue: any): boolean => {
		if (!regexValidation || inputValue === '') {
			return true;
		}
		return regexValidation.test(inputValue);
	};
	useEffect(() => {
		setIsValid(validateInput(value));
		isValid && setInternalState(value !== undefined ? value : '');
	}, [value]);

	useEffect(() => {
		if (valueHasChnaged) {
			handleChange(internalState);
		}
	}, [valueHasChnaged]);

	const hintId = `${id}_hint`;
	const errorId = `${id}_error`;

	return (
		<FormControl fullWidth={fullWidth}>
			<TextField
				id={id}
				type={type}
				name={name}
				size={size}
				disabled={disabled}
				required={required}
				sx={sx ? { ...sx } : {}}
				value={internalState}
				onKeyDown={(ev) => {
					onKeydown?.(ev);

					if (ev.key.includes('Arrow')) return;

					if (maxLength && internalState.length >= maxLength && ev.key !== 'Backspace') {
						ev.preventDefault();
						return;
					}
				}}
				onChange={(ev) => {
					onChange && onChange(ev.target.value);
					setInternalState(ev.target.value);
					setIsValid(validateInput(ev.target.value));
					setValueHasChnaged(valueHasChnaged + 1);
				}}
				error={error !== undefined}
				InputProps={{
					startAdornment: startAdornment,
					endAdornment: endAdornment
				}}
				InputLabelProps={{
					shrink: true
				}}
				label={label}
			/>
			{hint !== undefined ? (
				<FormHelperText
					error={!!error}
					id={hintId}>
					{hint}
				</FormHelperText>
			) : null}
			{error !== undefined || !isValid ? (
				<FormHelperText
					id={errorId}
					error={true}>
					{error || (!isValid && (messageFormatError ?? 'No cumple con el formato'))}
				</FormHelperText>
			) : null}
		</FormControl>
	);
};
