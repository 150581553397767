import { ReactNode, useEffect, useState } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { JurisdictionContext } from './JurisdictionContext';
import { toast } from 'react-toastify';
import { useAppContext } from 'hooks/useAppContext';
import { ILinks, IMeta } from 'interfaces/commons/IResponse';
import { IPaginationRequest } from 'interfaces/commons/IParameters';
import { IJurisdiction, IJurisdictionFilters } from 'interfaces/jurisdictions/IJurisdictions';
import {
	tryCreateOrUpdateJurisdiction,
	tryDeleteJurisdiction,
	tryGetAllJurisdictionsBySearchPaginated,
	tryGetJurisdictionByID
} from 'services/jurisdictionsService';
import { captureMessageError } from 'services/httpService';

const JURISDICTIONS_FILTERS = 'JURISDICTIONS_FILTERS';

export const JurisdictionState = ({ children }: { children: ReactNode | ReactJSXElement }) => {
	const appContext = useAppContext();

	useEffect(() => {
		// Get Search parameters
		const persistedValue = localStorage.getItem(JURISDICTIONS_FILTERS);
		let searchFilters = { term: undefined } as IJurisdictionFilters;
		if (persistedValue !== null) {
			searchFilters = JSON.parse(persistedValue);
		}
		setFilters(searchFilters);
	}, []);

	// START Get All Jurisdictions
	const [entities, setEntities] = useState<IJurisdiction[]>([] as IJurisdiction[]);
	const [meta, setMeta] = useState<IMeta>({} as IMeta);
	const [links, setLinks] = useState<ILinks>({} as ILinks);

	const onHandleGetAll = async ({ term }: IJurisdictionFilters, { page, limit }: IPaginationRequest) => {
		setEntities([]);
		setMeta({} as IMeta);
		setLinks({} as ILinks);
		appContext?.onHandleShowSpinner();
		const response = await tryGetAllJurisdictionsBySearchPaginated({ term }, { page, limit });
		appContext?.onHandleHideSpinner();

		let defaultMessageError = captureMessageError(response, 'Ocurrio un error al recuperar las jurisdicciones!');
		if (defaultMessageError.hasError) {
			toast.error(defaultMessageError.message);
			return false;
		}

		setEntities(response.items);
		setMeta(response.meta);
		setLinks(response.links);
		toast.success('Jurisdicciones recuperadas!');
		return true;
	};
	// END Get All Jurisdictions

	// START Filter Jurisdiction
	const storagedFilters = localStorage.getItem(JURISDICTIONS_FILTERS);
	const [filters, setFilters] = useState<IJurisdictionFilters>(
		storagedFilters ? JSON.parse(storagedFilters) : ({ term: undefined } as IJurisdictionFilters)
	);

	const onHandleSearByFilters = async ({ term = '' }: IJurisdictionFilters) => {
		if (term && term !== '') {
			setFilters({ term });
			localStorage.setItem(JURISDICTIONS_FILTERS, JSON.stringify({ term }));
			return onHandleGetAll({ term }, { page: 1, limit: meta?.itemsPerPage });
		}
		return false;
	};

	const onHandleClearFilters = async () => {
		setFilters({ term: undefined } as IJurisdictionFilters);
		localStorage.removeItem(JURISDICTIONS_FILTERS);
		return onHandleGetAll({ term: undefined }, { page: 1, limit: meta?.itemsPerPage });
	};
	// END Filter Jurisdiction

	// START Handle Change Page
	const onHandleChangePage = async ({ page = 1, limit = meta?.itemsPerPage }: IPaginationRequest) => {
		return await onHandleGetAll({ ...filters }, { page, limit });
	};
	// END Handle Change Page

	// START Create or Update Jurisdiction
	const onHandleCreateOrUpdate = async (entity: IJurisdiction) => {
		if (!entity) {
			toast.error('Debe indicar una nueva jurisdicción para poder agregarla!');
			return false;
		}

		appContext?.onHandleShowSpinner();
		const response = await tryCreateOrUpdateJurisdiction(entity);
		appContext?.onHandleHideSpinner();

		let defaultMessageError = captureMessageError(response, 'Ocurrio un error al guardar los datos de la jurisdicción!');
		if (defaultMessageError.hasError) {
			toast.error(defaultMessageError.message);
			return false;
		}

		toast.success('Jurisdicción guardada exitosamente.');
		return true;
	};
	// END Create or Update Jurisdiction

	// START Delete Jurisdiction
	const onHandleDelete = async (id: number | null) => {
		if (id == null) {
			toast.error('Debe indicar una jurisdicción para poder eliminarla!');
			return false;
		}

		appContext?.onHandleShowSpinner();
		const response = await tryDeleteJurisdiction(id);
		appContext?.onHandleHideSpinner();

		let defaultMessageError = captureMessageError(response, 'Ocurrio un error al eliminar la jurisdicción!');
		if (defaultMessageError.hasError) {
			toast.error(defaultMessageError.message);
			return false;
		}

		toast.success('Jurisdicción eliminada exitosamente.');
		return true;
	};
	// END Delete Jurisdiction

	// START Get Data of Jurisdiction
	const onHandleGetData = async (id: number | null, showMessage: boolean = true) => {
		if (id == null) {
			toast.error('Debe indicar una jurisdicción para poder recuperar sus datos!');
			return false;
		}

		appContext?.onHandleShowSpinner();
		const response = await tryGetJurisdictionByID(id);
		appContext?.onHandleHideSpinner();

		let defaultMessageError = captureMessageError(response, 'Ocurrio un error al recuperar datos de la jurisdicción!');
		if (defaultMessageError.hasError) {
			toast.error(defaultMessageError.message);
			return false;
		}

		if (showMessage) {
			toast.success('Jurisdicción recuperada exitosamente.');
		}
		return true;
	};
	// END Get Data of Jurisdiction

	return (
		<JurisdictionContext.Provider
			value={{
				entities,
				meta,
				links,
				onHandleGetAll,
				onHandleSearByFilters,
				onHandleClearFilters,
				filters,
				onHandleChangePage,
				onHandleCreateOrUpdate,
				onHandleDelete,
				onHandleGetData
			}}>
			{children}
		</JurisdictionContext.Provider>
	);
};
