import { Box } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { SearchBarByText } from 'components/globals/searchBar/SearchBarByText';
import { FormDialog } from 'components/globals/dialog/FormDialog';
import { CustomDecideDialog } from 'components/globals/dialog/CustomDeleteDialog';

// Jurisdicciones
import { useJurisdictionContext } from 'hooks/useJurisdictionContext';
import { IJurisdiction } from 'interfaces/jurisdictions/IJurisdictions';
import { JurisdictionTable } from 'components/jurisdiction/JurisdictionTable';
import { OverviewJurisdictionForm } from 'components/jurisdiction/Overview';
import { useAppContext } from 'hooks/useAppContext';
import { IAdministrativeType } from 'interfaces/commons/IParameters';

export const JURISDICTIONS_LABELS = {
	label: 'Jurisdicción',
	add: 'Agregar Jurisdicción',
	update: 'Editar Jurisdicción',
	delete: 'Eliminar Jurisdicción',
	list: 'Jurisdicciones',
	manage: 'Gestión de Jurisdicciones',
	searchLabel: 'Ingrese código o nombre de la Jurisdicción'
};

const JurisdictionsOverview = memo(() => {
	const jurisdictionContext = useJurisdictionContext();
	const appContext = useAppContext();

	const [jurisdictions, setJurisdictions] = useState<IJurisdiction[] | []>([]);
	const [administrativeTypes, setAdministrativesType] = useState<IAdministrativeType[] | []>([]);

	useEffect(() => {
		appContext?.onHandleTryGetAllAdministrativeTypes();
		jurisdictionContext?.onHandleGetAll(jurisdictionContext?.filters, {
			page: jurisdictionContext?.meta?.currentPage,
			limit: jurisdictionContext?.meta?.itemsPerPage
		});
	}, []);

	useEffect(() => {
		setJurisdictions(jurisdictionContext?.entities ? jurisdictionContext?.entities : []);
	}, [jurisdictionContext?.entities]);

	useEffect(() => {
		setAdministrativesType(appContext?.administrativeTypes);
	}, [appContext?.administrativeTypes]);

	// START Handle Add entity Modal
	const [showManageEntityModal, toggleShowManageEntityModal] = useState<boolean>(false);

	// Jurisdicciones
	const [selectedJurisdiction, setSelectedJurisdiction] = useState<IJurisdiction | undefined>(undefined);
	const handleToggleJurisdictionModal = (show: boolean = false, entity: IJurisdiction | undefined = undefined) => {
		setSelectedJurisdiction(entity);
		toggleShowManageEntityModal(show);
	};

	const handleSubmitJurisdictionModal = async (entity: IJurisdiction | undefined) => {
		if (!entity) {
			return;
		}
		let result = await jurisdictionContext?.onHandleCreateOrUpdate(entity);

		if (result) {
			handleToggleJurisdictionModal();
			jurisdictionContext?.onHandleGetAll(jurisdictionContext?.filters, {
				page: jurisdictionContext?.meta?.currentPage,
				limit: jurisdictionContext?.meta?.itemsPerPage
			});
		}
	};
	// END Handle Add entity Modal

	// START Handle Delete entity Modal
	const [showDeleteEntityModal, toggleShowDeleteEntityModal] = useState<boolean>(false);

	// Jurisdicción
	const handleShowDeleteJurisdiction = (entity: IJurisdiction | undefined) => {
		if (!entity) {
			return;
		}
		setSelectedJurisdiction(entity);
		toggleShowDeleteEntityModal(true);
	};

	const handleHideDeleteJurisdiction = () => {
		setSelectedJurisdiction(undefined);
		toggleShowDeleteEntityModal(false);
	};

	const handleSubmitDeleteJurisdiction = async (entity: IJurisdiction | undefined) => {
		if (!entity) {
			return;
		}
		let result = await jurisdictionContext?.onHandleDelete(entity.id ? entity.id : null);

		if (result) {
			handleHideDeleteJurisdiction();
			jurisdictionContext?.onHandleGetAll(jurisdictionContext?.filters, {
				page: jurisdictionContext?.meta?.currentPage,
				limit: jurisdictionContext?.meta?.itemsPerPage
			});
		}
	};
	// END Handle Delete entity Modal

	return (
		<>
			{showManageEntityModal && (
				<FormDialog
					show={showManageEntityModal}
					title={selectedJurisdiction ? JURISDICTIONS_LABELS.update : JURISDICTIONS_LABELS.add}
					content={
						<OverviewJurisdictionForm
							entity={selectedJurisdiction}
							handleCancel={() => {
								handleToggleJurisdictionModal();
							}}
							handleSubmit={(entity: IJurisdiction) => {
								handleSubmitJurisdictionModal(entity);
							}}
							administrativeTypes={administrativeTypes ? administrativeTypes : []}
						/>
					}
					actions={<></>}
					handleClose={(value) => {
						handleToggleJurisdictionModal();
					}}
				/>
			)}

			{showDeleteEntityModal && (
				<CustomDecideDialog
					title={JURISDICTIONS_LABELS.delete}
					message={'¿Desea eliminar la Jurisdicción?'}
					handleSubmit={() => {
						handleSubmitDeleteJurisdiction(selectedJurisdiction);
					}}
					handleDecline={() => {
						handleHideDeleteJurisdiction();
					}}
				/>
			)}

			<Box sx={{ width: '100%', typography: 'body1' }}>
				<SearchBarByText
					label={JURISDICTIONS_LABELS.searchLabel}
					defaultValue={jurisdictionContext?.filters?.term}
					onHandleSearch={(ev: any) => {
						jurisdictionContext?.onHandleSearByFilters({ term: ev });
					}}
					submitLabel={JURISDICTIONS_LABELS.add}
					onHandleSubmit={(ev: any) => {
						handleToggleJurisdictionModal(true);
					}}
					onHandleClear={(ev: any) => {
						jurisdictionContext?.onHandleClearFilters();
					}}
				/>

				<Box sx={{ overflowX: 'scroll', marginTop: '5px' }}>
					<JurisdictionTable
						elements={jurisdictions}
						handleUpdate={(entity: any) => {
							handleToggleJurisdictionModal(true, entity);
						}}
						handleDelete={(entity: any) => {
							handleShowDeleteJurisdiction(entity);
						}}
					/>
				</Box>
			</Box>
		</>
	);
});

JurisdictionsOverview.displayName = 'Jurisdicciones';

export { JurisdictionsOverview };
