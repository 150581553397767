import { IAdquisicionValues } from './interfaces';

const types = ['png', 'jpeg', 'jpg', 'pdf'];

export const fileValidate = (file: File) => {
	if (file && !types.some((item) => item === file.type.split('/')[1])) {
		return 'El formato del archivo no es válido';
	}
	if (file && file?.size / 1024 / 1024 > 100) {
		return 'El archivo no puede superar los 100MB';
	}

	return null;
};

export const formValidateAdquisicion = (adquisicion: IAdquisicionValues): boolean => {
	const { cuit, comprobante, ncomprobante, file, errorFile, user, userError, puntoVenta } = adquisicion;
	if (cuit !== undefined) {
		if (cuit.trim().length < 11) {
			return false;
		}
	}
	if (comprobante === 0 || comprobante === undefined) {
		return false;
	}
	if (puntoVenta?.trim().length === 0 || puntoVenta === undefined || puntoVenta?.trim().length < 4) {
		return false;
	}
	if (ncomprobante?.trim().length === 0 || ncomprobante === undefined || ncomprobante?.trim().length < 8) {
		return false;
	}
	if (file?.name?.trim().length === 0 || file?.name === undefined) {
		return false;
	}
	if (errorFile !== null || errorFile === undefined) {
		return false;
	}
	if (userError) {
		if (!user?.persona?.nombre || !user?.persona?.domicilioUnico) {
			return false;
		}
	} else {
		if (!user?.persona?.domicilio) {
			return false;
		}
	}

	return true;
};

export const validateFechas = (
	fecha: string,
	isSameMonth: boolean,
	isCorrelativeError: boolean,
	fechaInstLegal: string,
	observacion: string,
	file: File
): boolean => {
	if (fecha === undefined || fecha.trim().length === 0) {
		return false;
	}
	if (isSameMonth && isCorrelativeError) {
		if (observacion.trim().length === 0 || observacion === undefined) {
			return false;
		}
		if (file?.name?.trim().length === 0 || file?.name === undefined) {
			return false;
		}
	}
	if (!isSameMonth) {
		if (observacion.trim().length === 0 || observacion === undefined) {
			return false;
		}
		if (file?.name?.trim().length === 0 || file?.name === undefined) {
			return false;
		}
		if (fechaInstLegal.trim().length === 0 || fechaInstLegal === undefined) {
			return false;
		}
	}
	return true;
};
