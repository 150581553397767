import { ColumnDef } from '@tanstack/react-table';
import { IconButton, Switch, Tooltip } from '@mui/material';

export const defaultColumns: ColumnDef<any, any>[] = [
	{
		accessorKey: 'code',
		enableSorting: true,
		header: () => <span>Código</span>,
		cell: ({ getValue }) => <p style={{ textAlign: 'center' }}>{getValue() as string}</p>
	},
	{
		accessorKey: 'name',
		enableSorting: true,
		header: () => <span>Unidad Organizacional</span>,
		cell: ({ getValue }) => <p style={{ textAlign: 'left', paddingLeft: '10px' }}>{getValue() as string}</p>
	},
	{
		accessorKey: 'status',
		enableSorting: false,
		enableGlobalFilter: false,
		enableColumnFilter: false,
		header: ({ table }) => (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>Desactivar/Activar</span>
				{/* <IconButton
					id="toggleAll"
					size="small"
					sx={{
						transition: 'all 200ms ease',
						'&:hover': {
							transform: 'scale(1.3)',
							transition: 'all 200ms ease'
						}
					}}>
					<Tooltip
						placement="right-start"
						arrow
						title={'Activas/Desactivar todos'}>
						<Switch
							disabled={table.options.data.length === 0}
							name="toggleAll"
						/>
					</Tooltip>
				</IconButton> */}
			</div>
		),
		cell: ({ row }) => (
			<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', width: '100%', justifyContent: 'space-around' }}>
				<IconButton
					onClick={() => {
						row.original.action = 'toggleStatus';
					}}
					size="small"
					sx={{
						transition: 'all 200ms ease',
						'&:hover': {
							transform: 'scale(1.3)',
							transition: 'all 200ms ease'
						}
					}}>
					<Tooltip
						placement="right-start"
						arrow
						title={row?.original?.sheets_presentation ? 'Desactivar' : 'Activar'}>
						<Switch
							checked={row?.original?.sheets_presentation}
							name="sheets_presentation"
						/>
					</Tooltip>
				</IconButton>
			</div>
		)
	}
];
