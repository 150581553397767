import { ColumnDef } from '@tanstack/react-table';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { MoreHoriz as MenuIcon, FileOpen, Download, History, ArrowDropDown } from '@mui/icons-material/';
import { MovementStatusType, MOVEMENT_TYPES } from 'enums';
import { parsedFormatDate } from 'helpers/Formaters/Formaters';
import { useState } from 'react';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export const defaultColumns: ColumnDef<any, any>[] = [
	{
		accessorKey: 'minute_number',
		accessorFn: (row) => {
			if (row.property_registration !== null) {
				return row.property_registration?.minute_number || '-';
			} else {
				return '';
			}
		},
		header: () => (
			<Box>
				Nro de Acta <ArrowDropDown />
			</Box>
		),
		cell: ({ row: { original } }) => {
			if (original.property_registration !== null && original.date_movement) {
				const [year] = original.date_movement.split('-');
				const minuteNumber = original.property_registration.minute_number;

				return <div>{minuteNumber ? <>{`${minuteNumber}/${year}`}</> : '-'}</div>;
			} else {
				return <div>{'-'}</div>;
			}
		}
	},
	{
		accessorKey: 'date_movement',
		accessorFn: (row) => parsedFormatDate(row?.date_movement),
		header: () => (
			<div style={{ minWidth: '130px' }}>
				Fecha de {localStorage.getItem('MOVEMENT_TYPE')} <ArrowDropDown />
			</div>
		),
		cell: ({ getValue }) => (
			<div style={{ textAlign: 'center', paddingLeft: '10px' }}>{parsedFormatDate(getValue())}</div>
		)
	},
	{
		accessorKey: 'high_type_name',
		accessorFn: (row) => row.high_type_name || 'No generado',
		enableColumnFilter: false,
		enableSorting: true,
		header: () => {
			return <span>Tipo de {localStorage.getItem('MOVEMENT_TYPE')}</span>;
		},
		cell: ({ row: { original } }) => {
			if (original.type === 'Baja') {
				const { property_deregistration } = original;
				return <div>{property_deregistration?.property_deregistration_item[0]?.deregistration_type?.name}</div>;
			}

			const value = original.high_type_name;

			const colors = {
				Transferencia: '#1a3762',
				Adquisición: '#11a3dd',
				Donación: '#e72260'
			};

			return (
				<Box sx={{ minWidth: '90px', display: 'flex', justifyContent: 'center' }}>
					<Typography
						sx={{ wordBreak: 'break-all' }}
						padding="4px 6px"
						borderRadius="6px"
						bgcolor={colors[value as keyof typeof colors]}
						fontSize={12}
						color="white"
						height="auto"
						fontWeight={800}>
						{original.high_type_name?.toUpperCase()}
					</Typography>
				</Box>
			);
		}
	},
	{
		accessorKey: 'totalStock',
		enableSorting: false,
		enableGlobalFilter: false,
		enableColumnFilter: false,
		accessorFn: (row) => `${row?.count_properties}` || 'No generado',
		header: () => <Box sx={{ minWidth: '140px' }}>Cantidad de Bienes</Box>,
		cell: ({ row: { original } }) => <div>{original?.count_properties || 0}</div>
	},
	{
		accessorKey: 'total_price',
		enableSorting: false,
		enableGlobalFilter: false,
		enableColumnFilter: false,
		accessorFn: (row) => `${Number(row?.amount).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}`,
		header: () => <span>Importe Total</span>,
		cell: ({ row: { original } }) => {
			if (original.type === 'Baja') {
				const { property_deregistration } = original;
				return (
					<div style={{ minWidth: '100px', textAlign: 'right' }}>
						{Number(
							property_deregistration.property_deregistration_item
								.map((el: any) => {
									return Number((el.property || el.property_timeless)?.unit_price || 0);
								})
								.reduce((a: number, b: number) => a + b, 0)
						).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}
					</div>
				);
			}

			if (original.amount) {
				return (
					<div style={{ minWidth: '100px', textAlign: 'right' }}>
						{Number(original?.amount).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}
					</div>
				);
			} else {
				return '';
			}
		}
	},
	{
		accessorKey: 'acta_firmada',
		enableSorting: false,
		enableGlobalFilter: false,
		enableColumnFilter: false,
		accessorFn: (row) => `${Number(row?.amount).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}`,
		header: () => <Box sx={{ minWidth: '100px' }}>Acta Firmada</Box>,
		cell: ({ row: { original } }) => {
			const files = original.property_movements_files;
			const isChecked = files.some((file: any) => file.file_type_id === 10);
			return (
				<div>
					{isChecked ? <CheckCircleOutlineOutlinedIcon color="success" /> : <CancelOutlinedIcon color="error" />}
				</div>
			);
		}
	},
	{
		accessorKey: 'inventoryNumber',
		enableColumnFilter: false,
		enableSorting: true,
		accessorFn: (row) => {
			if (row.type !== 'Baja') {
				return `${row?.inventory_number_range?.start || 0}-${row?.inventory_number_range?.end || 0}` || 'No generado';
			}
		},
		header: () => <span>Nro de Inventario</span>,
		cell: ({ row: { original } }) => {
			if (original.type === 'Baja') {
				const { property_deregistration } = original;
				const items = property_deregistration.property_deregistration_item;

				if (items && items.length > 0) {
					const timelessItems = [];
					const regularItems = [];

					for (let i = 0; i < items.length; i++) {
						const item = items[i];

						if (item.property_timeless) {
							timelessItems.push(item);
						} else {
							regularItems.push(item);
						}
					}

					const timelessList =
						timelessItems.length > 0
							? timelessItems
									.map((item) => {
										const inventoryNumber = item.property_timeless?.inventory_number;
										//const accessoryNumber = inventoryNumber;
										return inventoryNumber;
									})
									.join(', ')
							: null;

					const regularList = regularItems
						.map((item) => {
							const inventoryNumber = item.property?.item_inventory_number;
							//const accessoryNumber = item.property?.item_inventory_number;
							return inventoryNumber;
						})
						.join(', ');

					if (timelessList) {
						return (
							<div>
								{timelessList}
								{regularList ? ', ' : ''}
								{regularList}
							</div>
						);
					} else {
						return <div>{regularList}</div>;
					}
				}
			}

			const { start, end } = original?.inventory_number_range || {};
			const ranges = [start, end].filter((v) => !!v);

			if (original.count_properties === 1 && start) {
				return <div>{start}</div>;
			}

			return <div>{ranges.join('-') || '-'}</div>;
		}
	},
	{
		accessorKey: 'status',
		enableColumnFilter: false,
		enableSorting: true,
		header: () => <span>Estado</span>,
		cell: ({ row: { original } }) => {
			const value = original.movement_status_id;

			const colors: { [key in MovementStatusType]: string } = {
				[MovementStatusType.PENDIENTE]: '#f8891b',
				[MovementStatusType.APROBADO]: '#B1AFFF',
				[MovementStatusType.ENVIADO]: '#FFD56F',
				[MovementStatusType.OBSERVADO]: '#FF8787',
				[MovementStatusType.CONSOLIDADO]: '#66DE93',
				[MovementStatusType.RECTIFICADO]: '#86C8B6'
			};

			return (
				<Box sx={{ minWidth: '90px', display: 'flex', justifyContent: 'center' }}>
					<Typography
						sx={{ wordBreak: 'break-all' }}
						padding="4px 6px"
						borderRadius="6px"
						bgcolor={colors[value as keyof typeof colors]}
						fontSize={12}
						color="black"
						height="auto"
						fontWeight={800}>
						{MOVEMENT_TYPES[original.movement_status_id as keyof typeof MOVEMENT_TYPES]?.toUpperCase()}
					</Typography>
				</Box>
			);
		}
	},
	{
		accessorKey: 'Acciones',
		enableSorting: false,
		enableGlobalFilter: false,
		enableColumnFilter: false,
		header: () => <span>Acciones</span>,
		cell: ({ row: { original } }) => {
			const [anchorEl, setAnchorEl] = useState(null);
			const open = Boolean(anchorEl);

			const handleClick = (event: any) => {
				setAnchorEl(event.currentTarget);
			};

			const handleClose = () => {
				setAnchorEl(null);
			};

			const user = JSON.parse(localStorage.getItem('user') || 'null') || { role: { id: undefined } };

			return (
				<div>
					<Button
						id="menu-btn"
						onClick={handleClick}>
						<MenuIcon />
					</Button>
					<Menu
						id="menu-btn"
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}>
						<MenuItem
							disabled={user.role.id !== 4}
							color="primary"
							onClick={() => {
								handleClose();
								original.button =
									original.movement_status.id === MovementStatusType.PENDIENTE ? 'approve' : 'download-reception';
							}}>
							{original.movement_status.id === MovementStatusType.APROBADO ? (
								<Download
									color="primary"
									sx={{ marginRight: 1 }}
								/>
							) : (
								<CheckBoxOutlinedIcon
									color="primary"
									sx={{ marginRight: 1 }}
								/>
							)}
							{original.movement_status.id === MovementStatusType.PENDIENTE ? 'Aprobar' : 'Descargar Acta'}
						</MenuItem>

						<MenuItem
							color="primary"
							disabled={
								original.movement_status_id === MovementStatusType.ENVIADO ||
								original.movement_status_id === MovementStatusType.CONSOLIDADO
							}
							onClick={() => {
								handleClose();
								original.button = 'edit';
							}}>
							<BorderColorOutlinedIcon
								color="primary"
								sx={{ marginRight: 1 }}
							/>
							Editar
						</MenuItem>

						<MenuItem
							color="primary"
							disabled={original.movement_status_id !== MovementStatusType.PENDIENTE}
							onClick={() => {
								handleClose();
								original.button = 'delete';
							}}>
							<DeleteIcon
								color="primary"
								sx={{ marginRight: 1 }}
							/>
							Eliminar
						</MenuItem>

						<MenuItem
							color="primary"
							onClick={() => {
								handleClose();
								original.button = 'show';
							}}>
							<SearchIcon
								color="primary"
								sx={{ marginRight: 1 }}
							/>
							Ver Detalles
						</MenuItem>

						<MenuItem
							color="primary"
							disabled={original.movement_status_id === MovementStatusType.PENDIENTE}
							onClick={() => {
								handleClose();
								original.button = 'upload';
							}}>
							<FileUploadIcon
								color="primary"
								sx={{ marginRight: 1 }}
							/>
							{`Cargar Acta Firmada`}
						</MenuItem>

						<MenuItem
							color="primary"
							onClick={() => {
								handleClose();
								original.button = 'show-files';
							}}>
							<FileOpen
								color="primary"
								sx={{ marginRight: 1 }}
							/>
							Mostrar Archivos Adjuntos
						</MenuItem>

						<MenuItem
							color="primary"
							onClick={() => {
								handleClose();
								original.button = 'show-observations';
							}}>
							<History
								color="primary"
								sx={{ marginRight: 1 }}
							/>
							Histórico de Movimientos
						</MenuItem>
					</Menu>
				</div>
			);
		}
	}
];
