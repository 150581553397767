import { useState, useEffect, ChangeEvent, useRef, useMemo } from 'react';
import {
	Box,
	Button,
	Grid,
	Typography,
	FormControl,
	FormHelperText,
	OutlinedInput,
	InputAdornment,
	Tooltip
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import apiInstance from 'interceptors/axiosInterceptor';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Attachment, IFileAttachementManager } from 'interfaces/file/IFile';
import { Spinner } from '../spinner/Spinner';
import { toast } from 'react-toastify';

function getFileSizeInMb(size: number) {
	return size / 1024 / 1024;
}

function getFileSizeInKb(size: number) {
	return size / 1024;
}

function getFileSize(size: number) {
	const result = getFileSizeInKb(size);
	const suffix = result > 1024 ? 'mb' : 'kb';
	return `${(result > 1024 ? getFileSizeInMb(result * 1024) : result).toFixed(2)} ${suffix}`;
}

function isValidFileType(file: File, validTypes: string[]) {
	const [, type] = file.type.split('/');
	return validTypes.includes(type);
}

function getAttachmentFile(file: File) {
	return { name: file.name, size: file.size, file };
}

export const FileAttachementManager = ({
	label = 'Archivos Adjuntos',
	hideInputToUpload = false,
	limitCount = 10,
	limitSize = 100,
	validFormats = ['pdf', 'jpg', 'jpeg', 'png'],
	routeToUpload,
	routeToDelete,
	disableUploadAction,
	allowsMultiple,
	size,
	onChange,
	onUploadSuccess,
	onUploadError,
	onDeleteSuccess,
	onDeleteError,
	fieldName,
	attachments,
	value,
	disabled,
	readonly,
	error,
	filesLimit
}: IFileAttachementManager) => {
	const [attachmentsFiles, setAttachmentsFiles] = useState<Attachment[]>([]);
	const inputRef = useRef<HTMLInputElement>({} as HTMLInputElement);
	const [files, setFiles] = useState<File[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [fileErrorMsg, setFileErrorMsg] = useState('');

	const allFilesSize = useMemo(() => {
		return getFileSizeInMb(attachmentsFiles.reduce((acc, curr) => acc + curr.size, 0));
	}, [attachmentsFiles.length]);

	const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
		setFileErrorMsg('');

		const { target } = evt;
		const [file] = Array.from((target.files as unknown as File[]) || ([] as File[]));
		inputRef.current.value = '';

		if (!file) return;

		if (!isValidFileType(file, validFormats)) {
			return setFileErrorMsg('El formato de archivo no esta permitido.');
		}

		if (allFilesSize + getFileSizeInMb(file.size) > limitSize) {
			return setFileErrorMsg(`El tamaño máximo del conjunto no puede exceder los ${limitSize}mb.`);
		}

		if (attachmentsFiles.length + 1 > limitCount) {
			return setFileErrorMsg(`La cantidad maxima del conjunto no puede exceder los ${limitCount} adjuntos.`);
		}

		setFiles((prev) => [file, ...prev]);
		setAttachmentsFiles((prev) => [getAttachmentFile(file), ...prev]);
	};

	const handleRemoveFile = async (file: Attachment, index: number) => {
		setFileErrorMsg('');

		if (file.id === undefined) {
			setFiles((files) => files.filter((_, i) => i !== index));
			setAttachmentsFiles((files) => files.filter((_, i) => i !== index));
			return;
		}

		if (await deleteFile(file.id as number)) {
			setAttachmentsFiles((files) => files.filter((_, i) => i !== index));
			setFiles((files) => files.filter((_, i) => i !== index));
			onChange?.(files);
		}
	};

	const renderMessage = (message?: string, hasError = false) => {
		return (
			<FormHelperText
				sx={{ pl: 1 }}
				error={hasError}>
				{message ? message : ''}
			</FormHelperText>
		);
	};

	const uploadFile = async (file: File, index: number) => {
		if (!routeToUpload || !file || isLoading) return;

		try {
			setIsLoading(true);
			const formData = new FormData();
			formData.append(fieldName, new File([file], file.name.toLocaleLowerCase()));

			await apiInstance.post(routeToUpload, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			setFiles((curr) => curr.filter((_, i) => i !== index));

			toast('El archivo se ha cargado correctamente.', { autoClose: 1000, type: 'success' });

			onUploadSuccess && onUploadSuccess();
		} catch {
			toast('No se ha podido cargar el archivo.', { autoClose: 1000, type: 'error' });
			onUploadError && onUploadError();
		} finally {
			setIsLoading(false);
		}
	};

	const deleteFile = async (id: number) => {
		if (isLoading || !routeToDelete) return;

		try {
			setIsLoading(true);
			await apiInstance.delete(`${routeToDelete}/${id}`);
			toast('El archivo se ha borrado correctamente.', { autoClose: 1000, type: 'success' });
			onDeleteSuccess && onDeleteSuccess();
			return true;
		} catch {
			toast('No se ha podido eliminar el archivo.', { autoClose: 1000, type: 'error' });
			onDeleteError && onDeleteError();
			return false;
		} finally {
			setIsLoading(false);
		}
	};

	const isUploadBtnEnabled = (file: Attachment) => {
		return !disableUploadAction && file.file && file.id === undefined;
	};

	const disableInputClick =
		(!allowsMultiple && files?.length > 0) || disabled || readonly || (filesLimit === files.length && allowsMultiple);

	const handleClickFileInput = () => {
		if (disableInputClick) return;

		inputRef.current.click();
	};

	useEffect(() => {
		const nonUploadedFiles = files.map((el) => getAttachmentFile(el));
		if (attachments) setAttachmentsFiles([...nonUploadedFiles, ...attachments]);
	}, [attachments]);

	useEffect(() => {
		onChange && onChange(files);
	}, [files.length]);

	useEffect(() => {
		value && setFiles(value);

		return () => {
			setFiles([]);
			setAttachmentsFiles([]);
			inputRef.current && (inputRef.current.value = '');
		};
	}, []);

	useEffect(() => setFileErrorMsg(error || ''), [error]);

	return (
		<>
			<Grid
				style={{ display: hideInputToUpload ? 'none' : 'block' }}
				container
				spacing={2}>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}>
					{label ? (
						<Typography
							fontWeight={500}
							fontSize="0.875rem"
							variant="body1"
							mb={1}>
							{label}
						</Typography>
					) : null}
					<FormControl
						fullWidth
						sx={{ mb: 1, display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
						<input
							ref={inputRef}
							multiple={false}
							type="file"
							accept={validFormats.map((v) => `.${v}`).join(',')}
							hidden
							onInput={handleChange}
						/>
						<OutlinedInput
							readOnly
							fullWidth
							error={!!fileErrorMsg}
							size={size ?? 'medium'}
							disabled={disableInputClick}
							placeholder="Cargar archivo/s"
							autoComplete="off"
							onClick={handleClickFileInput}
							endAdornment={
								<InputAdornment position="end">
									<Button
										disableElevation
										disableRipple
										disabled={disableInputClick}
										sx={{
											backgroundColor: 'rgba(0, 0, 0, 0.05)',
											color: '#000',
											'&:hover': {
												backgroundColor: 'rgba(0, 0, 0, 0.05)'
											},
											minWidth: 10,
											width: 30,
											margin: '0 !important',
											padding: '2px'
										}}
										variant="contained"
										component="label">
										{isLoading ? <Spinner /> : <AttachFileIcon fontSize="small" />}
									</Button>
								</InputAdornment>
							}></OutlinedInput>
					</FormControl>

					{fileErrorMsg ? <FormHelperText error>{fileErrorMsg}</FormHelperText> : null}

					<Box mb={2}>
						{validFormats ? renderMessage(`- Extensiones permitidas: ${validFormats.join(', ')}`) : null}
						{limitSize ? renderMessage(`- Tamaño máximo del conjunto: ${limitSize.toString()}MB`) : null}
						{limitCount ? renderMessage(`- Cantidad de Adjuntos permitidos: ${limitCount.toString()}`) : null}
					</Box>
				</Grid>
			</Grid>

			<Grid
				container
				spacing={1}>
				{attachmentsFiles.map((file, index) => (
					<Grid
						key={index}
						item
						width="100%"
						sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'space-between' }}>
						<Typography
							variant="body2"
							sx={{ maxWidth: 200, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
							{file?.name?.includes('.pdf') ? (
								<PictureAsPdfIcon
									sx={{ ml: 1, mr: 0.2 }}
									color="primary"
								/>
							) : (
								<ImageIcon
									sx={{ ml: 1, mr: 0.2 }}
									color="primary"
								/>
							)}
							{` - ${file?.name}`}
						</Typography>

						<Typography
							variant="body2"
							fontWeight={500}
							sx={{ marginLeft: 'auto', flexShrink: 0 }}>
							{getFileSize(file.size)}
						</Typography>

						<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
							{!readonly && !disabled ? (
								<>
									<Tooltip
										placement="right-start"
										arrow
										color="blue"
										title="Eliminar Adjunto">
										<DeleteIcon
											sx={{
												cursor: 'pointer',
												backgroundColor: '#575757',
												color: '#fff',
												padding: '3px',
												borderRadius: '5px'
											}}
											color="error"
											onClick={() => handleRemoveFile(file, index)}
										/>
									</Tooltip>

									{isUploadBtnEnabled(file) && (
										<Tooltip
											placement="right-start"
											arrow
											color="blue"
											title="Subir Adjunto">
											<FileUploadIcon
												sx={{
													cursor: 'pointer',
													backgroundColor: '#575757',
													color: '#fff',
													padding: '3px',
													borderRadius: '5px'
												}}
												color="error"
												onClick={() => uploadFile(file.file as File, index)}
											/>
										</Tooltip>
									)}
								</>
							) : null}

							{/* {readonly ? (
								<Tooltip title="Ver documento">
									<OpenInBrowser
										sx={{
											cursor: 'pointer',
											backgroundColor: '#575757',
											color: '#fff',
											padding: '3px',
											borderRadius: '5px'
										}}
										onClick={() => handleOpenFile(file)}
									/>
								</Tooltip>
							) : null} */}
						</Box>
					</Grid>
				))}
				{attachmentsFiles.length ? (
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						style={{ textAlign: 'right' }}>
						<Typography
							fontSize={14}
							variant="subtitle1">
							{`Peso total de todos los archivos: ${getFileSizeInMb(
								attachmentsFiles.reduce((curr, file) => {
									return curr + Number(file.size);
								}, 0)
							).toFixed(2)} mb`}
						</Typography>
					</Grid>
				) : null}
			</Grid>
		</>
	);
};
