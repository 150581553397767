import { GenericFieldDetail } from './FieldDetail';
import { parsedFormatDate } from 'helpers/Formaters/Formaters';
import { IFieldPropertie } from 'interfaces/commons/IParameters';

export const FieldDate = ({ label = 'Fecha del Alta:', value = '' }: IFieldPropertie) => {
	return (
		<GenericFieldDetail
			label={label}
			value={value !== undefined && value !== null ? parsedFormatDate(value) : '-'}
		/>
	);
};
