import { useEffect, useState } from 'react';
import { ColorlibConnector, ColorlibStepIcon, steps } from './utils';
import { Stack, Stepper, Step, StepLabel } from '@mui/material';
import { useDeregistrationContext } from 'hooks/useDeregistrationContext';

export interface IPaso {
	data: any;
	valid: boolean;
}

export const StepperComponent = () => {
	const deregistrationContext = useDeregistrationContext();
	const [activeStep, setActiveStep] = useState(0);

	useEffect(() => {
		setActiveStep(deregistrationContext?.activeStep);
	}, [deregistrationContext?.activeStep]);

	return (
		<Stack
			className={'stepper'}
			spacing={4}>
			<Stepper
				alternativeLabel
				activeStep={activeStep}
				connector={<ColorlibConnector />}>
				{steps.map((label, index) => (
					<Step
						key={index}
						className={index === activeStep ? `${'active'} ${'customStep'}` : `${'customStep'}`}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}>
						<StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
		</Stack>
	);
};
