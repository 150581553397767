import { useState } from 'react';
import { BienesTable } from '../table/BienesTable';
import { useBFContext } from 'hooks/useBFContext';

//Material UI
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useAuth } from 'hooks/useAuth';

export const BFTabs = () => {
	const bfContext = useBFContext();
	const [value, setValue] = useState(bfContext?.tabValue || '3');
	const auth = useAuth();

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
		bfContext?.setTabValue(newValue);
	};

	return (
		<Box sx={{ width: '100%', typography: 'body1' }}>
			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={handleChange}>
						<Tab
							label="Pendientes"
							value="3"
						/>
						<Tab
							label="Aprobados"
							value="6"
						/>
						<Tab
							label="Observados"
							value="4"
						/>
						{auth?.user?.role?.id !== 3 ? (
							<Tab
								label="Auditados"
								value="5"
							/>
						) : null}
					</TabList>
				</Box>
				<TabPanel
					value="3"
					sx={{ padding: 0, margin: 0, mt: 2, mb: 2 }}>
					<BienesTable />
				</TabPanel>
				<TabPanel
					value="6"
					sx={{ padding: 0, margin: 0, mt: 2, mb: 2 }}>
					<BienesTable />
				</TabPanel>
				<TabPanel
					value="4"
					sx={{ padding: 0, margin: 0, mt: 2, mb: 2 }}>
					<BienesTable />
				</TabPanel>
				<TabPanel
					value="5"
					sx={{ padding: 0, margin: 0, mt: 2, mb: 2 }}>
					<BienesTable />
				</TabPanel>
			</TabContext>
		</Box>
	);
};
