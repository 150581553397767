import { ApiPaths } from 'enums';
import apiInstance from 'interceptors/axiosInterceptor';
import { IFiltersMovementTable } from 'interfaces/movements';
import { trackPromise } from 'react-promise-tracker';
import { buildDefaultOptions, handleHttpError, httpGet } from 'services/httpService';

const URL_BASE = `${ApiPaths.PROPERTY_MOVEMENTS}`;

export const getMovements = async (filters: IFiltersMovementTable): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const promise = trackPromise(
		new Promise((resolve, reject) => {
			try {
				resolve(
					apiInstance
						.get(`property-movements`, {
							headers: { Authorization: `Bearer ${token.access_token}` },
							params: { ...filters }
						})
						.then((res) => {
							return res;
						})
						.catch((error) => {
							return error;
						})
				);
			} catch (error) {
				reject(error);
			}
		}),
		'getMovementsTracker'
	);
	return promise;
};

export const getMovement = async (id: number): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const promise = trackPromise(
		new Promise((resolve, reject) => {
			try {
				resolve(
					apiInstance
						.get(`property-movements/${id}`, {
							headers: { Authorization: `Bearer ${token.access_token}` }
						})
						.then((res) => {
							return res;
						})
						.catch((error) => {
							return error;
						})
				);
			} catch (error) {
				reject(error);
			}
		}),
		'getMovementTracker'
	);
	return promise;
};

// UNFINISHED
/* export const approveMovement = async (id: number): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const promise = trackPromise(
		new Promise((resolve, reject) => {
			try {
				resolve(
					apiInstance
						.put(
							`property-movements/${id}/approve`,
							{},
							{
								headers: { Authorization: `Bearer ${token.access_token}` }
							}
						)
						.then((res) => {
							return res;
						})
						.catch((error) => {
							return error;
						})
				);
			} catch (error) {
				reject(error);
			}
		}),
		'approveMovementTracker'
	);
	return promise;
}; */

const GET_URL_WITH_ID = (id: number) => {
	return URL_BASE + `/${id}`;
};

export const tryGetMovementByID = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		const response = await httpGet(GET_URL_WITH_ID(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const getPdfSigned = async (id: number): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const promise = trackPromise(
		new Promise((resolve, reject) => {
			try {
				resolve(
					apiInstance
						.get(`property-movements-files/${id}`, {
							headers: { Authorization: `Bearer ${token.access_token}` }
						})
						.then((res) => {
							return res;
						})
						.catch((error) => {
							return error;
						})
				);
			} catch (error) {
				reject(error);
			}
		}),
		'getPdfSignedTracker'
	);
	return promise;
};
