import Button, { ButtonProps } from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

/* Creating a new component called ColorButton that is a styled version of the Button component. */
export const ButtonAuth = styled(Button)<ButtonProps>(({ theme }) => ({
	color: theme.palette.getContrastText(grey[500]),
	backgroundColor: '#fff',
	'&:hover': {
		backgroundColor: theme.palette.primary.dark,
		color: '#fff'
	}
}));

export const ButtonNextPrev = styled(Button)<ButtonProps>(({ theme }) => ({
	color: theme.palette.getContrastText(grey[500]),
	minWidth: '100px',
	backgroundColor: '#e7e7e7',
	'&:hover': {
		backgroundColor: theme.palette.primary.dark,
		color: '#fff'
	}
}));

export const ButtonRegister = styled(Button)<ButtonProps>(({ theme }) => ({
	color: grey[200],
	minWidth: '100px',
	backgroundColor: theme.palette.primary.main,
	'&:hover': {
		backgroundColor: theme.palette.primary.dark
	}
}));

export const ButtonSearch = styled(Button)<ButtonProps>(({ theme }) => ({
	textAlign: 'center',
	color: grey[200],
	backgroundColor: theme.palette.success.main,
	'&:hover': {
		backgroundColor: theme.palette.success.dark
	}
}));

export const ButtonConfirm = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#fff',
	minWidth: '100px',
	backgroundColor: theme.palette.primary.main,
	'&:hover': {
		backgroundColor: theme.palette.primary.dark
	}
}));

export const ButtonDecline = styled(Button)<ButtonProps>(({ theme }) => ({
	color: '#fff',
	minWidth: '100px',
	backgroundColor: theme.palette.secondary.main,
	'&:hover': {
		backgroundColor: theme.palette.secondary.dark
	}
}));
