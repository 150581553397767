import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

// React Router
import { BrowserRouter } from 'react-router-dom';

// Material UI
import { ThemeProvider } from '@mui/material/styles';
import { LightTheme } from '../src/themes';
import { ToastProvider } from 'contexts/toastContext/ToastProvider';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
	<ThemeProvider theme={LightTheme}>
		<ToastProvider>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</ToastProvider>
	</ThemeProvider>
);
