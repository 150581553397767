import React, { Ref, forwardRef } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';

export const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: Ref<unknown>
) {
	return (
		<Slide
			direction="up"
			ref={ref}
			{...props}
		/>
	);
});

export enum DialogType {
	PRIMARY = '#1A3762',
	SECONDARY = '#E72260',
	ERROR = '#d32f2f',
	WARNING = '#f57c00',
	INFO = '#0288d1',
	SUCCESS = '#388e3c'
}

export interface ICustomButtonProps {
	name?: string;
	color?: 'primary' | 'warning' | 'info' | 'inherit' | 'secondary' | 'success' | 'error';
	variant?: 'contained' | 'outlined' | 'text' | undefined;
	onClick: () => void;
}

export interface IDialogProps {
	title?: string;
	message: string;
	labelSubmit?: string;
	labelDecline?: string;
	handleSubmit: () => void;
	handleDecline: () => void;
}
