// Enums
import { ApiPaths } from 'enums/apiPaths';
import { handleHttpError, buildDefaultOptions, httpGet } from './httpService';

const VOUCHER_TYPES = `${ApiPaths.VOUCHER_TYPES}`;

export const tryGetAllVoucherTypes = async () => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpGet(VOUCHER_TYPES, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

const ITEM_TYPE = `${ApiPaths.ITEM_TYPE}`;
export const tryGetAllItemTypes = async () => {
	try {
		//const OPTIONS = buildDefaultOptions();
		//let response = await httpGet(ITEM_TYPE, OPTIONS);
		//return response?.data;
		return [
			{ id: 1, name: 'Genérico' },
			{ id: 2, name: 'Vehículo' },
			{ id: 3, name: 'Inmueble' }
		];
	} catch (error) {
		return handleHttpError(error);
	}
};
