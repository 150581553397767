import apiInstance from 'interceptors/axiosInterceptor';
import { trackPromise } from 'react-promise-tracker';
import { CreateAssetRegistration, Item } from 'interfaces/crud/createHighData';
import { toast } from 'react-toastify';

import { IHighDataAssets } from 'interfaces/crud/highDataAssets';
import { IRecepAndInstMovProvider } from 'interfaces/movements';

// GET's

/**
 * @description Get last property registered
 */
export const getLastPropertyRegistered = async (): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const promise = trackPromise(
		new Promise((resolve, reject) => {
			apiInstance
				.get(`properties/last/registered`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		}),
		'lastPropertyRegistered'
	);

	return promise;
};

/**
 * @description Search nomenclature code.
 */
export const getNomenclatureCode = (code: string): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const promise = trackPromise(
		new Promise((resolve, reject) => {
			resolve(
				apiInstance
					.get(`account-nomenclature/check`, {
						headers: { Authorization: `Bearer ${token.access_token}` },
						params: { code: code }
					})
					.then((res) => {
						return res;
					})
					.catch((error) => {
						return error;
					})
			);
		}),
		'getNomenclatureCodeTracker'
	);

	return promise;
};

/**
 * @description Get nomenclature code Description.
 */
export const getNomenclatureCodeDescription = (code: string): Promise<any> => {
	const promise = new Promise((resolve, reject) => {
		resolve(
			getNomenclatureCode(code)
				.then((res) => {
					if (res.data[0]) {
						let codigo = '';
						if (res.data[0].sub_group !== null) {
							codigo = `${res.data[0].sub_group}`;
						}
						if (res.data[0].account !== null) {
							codigo = `${res.data[0].account}`;
						}
						if (res.data[0].sub_account !== null) {
							codigo = `${res.data[0].sub_account}`;
						}
						const selectedItem = {
							value: codigo,
							label: `${codigo} - ${res.data[0].group} - ${res.data[0].sub_group_description} - ${
								res.data[0].description !== null ? res.data[0].description : ''
							} - ${res.data[0].sub_description !== null ? res.data[0].sub_description : ''}`
						};
						return selectedItem.label;
					}
				})
				.catch((error) => {
					return error;
				})
		);
	});
	return promise;
};

/**
 * @description Search patrimonial dependencies.
 */
export const getPatrimonialDependencies = async (dependencyTerm: string): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const datos = trackPromise(
		apiInstance.get(`patrimonial-dependencies/search`, {
			headers: { Authorization: `Bearer ${token.access_token}` },
			params: { limit: 500, term: `${dependencyTerm}` }
		}),
		'patrimonialDependenciesTracker'
	)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});

	return datos;
};

/**
 * @description Get the properties types.
 */
export const getHighTypes = async (): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const datos = trackPromise(
		apiInstance.get(`types/high-types`, {
			headers: { Authorization: `Bearer ${token.access_token}` }
		}),
		'highTypesTracker'
	)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});

	return datos;
};

/**
 * @description Get the deregistration types.
 */
export const getDeregistrationTypes = async (): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const datos = trackPromise(
		apiInstance.get(`types/property-deregistration-types`, {
			headers: { Authorization: `Bearer ${token.access_token}` }
		}),
		'deregistrationTypesTracker'
	)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});

	return datos;
};

/**
 * @description Get the vouchers types.
 */
export const getVoucherTypes = async (): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const datos = trackPromise(
		apiInstance.get(`types/voucher-types`, {
			headers: { Authorization: `Bearer ${token.access_token}` }
		}),
		'voucherTypesTracker'
	)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});

	return datos;
};

/**
 * @description Get the types of items.
 */
export const getPropertyTypes = async (): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const datos = trackPromise(
		apiInstance.get(`types/property-types`, {
			headers: { Authorization: `Bearer ${token.access_token}` }
		}),
		'propertyTypesTracker'
	)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});

	return datos;
};

// POST

export const createHighData = async (data: CreateAssetRegistration): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const datos = trackPromise(
		apiInstance.post(`properties`, data, {
			headers: { Authorization: `Bearer ${token.access_token}` }
		}),
		'createHighDataTracker'
	)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});

	return datos;
};

export const postUploads = async (selectedFiles: any, id: number): Promise<any> => {
	const formData = new FormData();
	const attachments: File[] = [];

	if (selectedFiles.reception) {
		if (Array.isArray(selectedFiles.reception)) {
			selectedFiles.reception.forEach((file: any) => {
				formData.append('reception', file);
			});
		} else {
			formData.append('reception', selectedFiles.reception);
		}
	}

	if (selectedFiles.attachments) {
		if (Array.isArray(selectedFiles.attachments)) {
			selectedFiles.attachments.forEach((file: any) => {
				attachments.push(file);
			});
		} else {
			attachments.push(selectedFiles.attachments);
		}
	}

	if (selectedFiles.timeless_justification) {
		if (Array.isArray(selectedFiles.timeless_justification)) {
			selectedFiles.timeless_justification.forEach((file: any) => {
				formData.append('timeless_justification', file);
			});
		} else {
			formData.append('timeless_justification', selectedFiles.timeless_justification);
		}
	}

	const onUploadProgress = (progressEvent: any) => {
		const { loaded, total } = progressEvent;
		const percent = Math.floor((loaded * 100) / total);
	};

	try {
		const response = toast
			.promise(
				trackPromise(
					apiInstance.post(`/properties-files/upload/${id}`, formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						},
						onUploadProgress
					}),
					'postAttachmentsTracker'
				),
				{
					pending: 'Creando Alta...',
					success: 'Alta creada correctamente',
					error: 'Error al subir adjuntos del Alta'
				},
				{ position: 'bottom-right' }
			)
			.then((res) => {
				uploadAttachments(attachments, id);
				return res;
			})
			.catch((error) => {
				return error;
			});
		return response;
	} catch (error) {
		return error;
	}
};

const uploadAttachments = async (selectedFiles: any, id: number): Promise<any> => {
	const allPromises: Promise<string>[] = [];
	if (selectedFiles.length > 0) {
		selectedFiles.forEach((file: any) => {
			const formData = new FormData();
			formData.append('attachments', file);
			const promise: Promise<string> = apiInstance.post(`/properties-files/upload/${id}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			allPromises.push(promise);
		});

		const data = toast
			.promise(
				trackPromise(Promise.allSettled(allPromises), 'postAttachmentsTracker1'),
				{
					pending: 'Subiendo archivos...',
					success: 'Archivos subidos correctamente',
					error: 'Error al subir archivos'
				},
				{ position: 'bottom-right' }
			)
			.then((results) => {
				return results;
			})
			.catch((error) => {
				return error;
			});

		return data;
	} else {
		return;
	}
};

export const postItemAttachment = async (selectedFile: any, id: string): Promise<any> => {
	const formData = new FormData();
	formData.append('attachments', selectedFile);

	const onUploadProgress = (progressEvent: any) => {
		const { loaded, total } = progressEvent;
		const percent = Math.floor((loaded * 100) / total);
	};

	try {
		const response = await apiInstance
			.post(`/properties-files/upload/item/${id}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				onUploadProgress
			})
			.catch((error) => {
				return error;
			});
		return response;
	} catch (error) {
		return error;
	}
};

// PATCH

export const patchProperty = async (data: any, id: number): Promise<any> => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const promise = trackPromise(
		new Promise((resolve, reject) => {
			resolve(
				apiInstance
					.patch(`properties/${id}`, data, { headers: { Authorization: `Bearer ${token.access_token}` } })
					.then((res) => {
						return res;
					})
					.catch((error) => {
						return error;
					})
			);
		}),
		'patchPropertyTracker'
	);

	return promise;
};

// DELETE

export const deleteItemAttachment = async (uuid: string) => {
	try {
		const response = await apiInstance.delete(`/properties-files/upload/item/${uuid}`).catch((error) => {
			return error;
		});
		return response;
	} catch (error) {
		return error;
	}
};

export const deletePropertyItem = async (id: string) => {
	const token = JSON.parse(localStorage.getItem('token') || '{}');

	const promise = trackPromise(
		new Promise((resolve, reject) => {
			resolve(
				apiInstance
					.delete(`property-items/${id}`, {
						headers: { Authorization: `Bearer ${token.access_token}` }
					})
					.then((res) => {
						toast.success('Item eliminado correctamente');
						return res;
					})
					.catch((error) => {
						toast.error('Error al eliminar Item');
						return error;
					})
			);
		}),
		'deletePropertyItem'
	);

	return promise;
};
