// Enums
import { ApiPaths } from 'enums/apiPaths';
import { IPaginationRequest } from 'interfaces/commons/IParameters';
import { IJurisdiction, IJurisdictionFilters } from 'interfaces/jurisdictions/IJurisdictions';

import {
	handleHttpError,
	buildDefaultOptions,
	httpGet,
	buildURLQuery,
	httpDelete,
	httpPut,
	httpPost,
	httpPatch
} from './httpService';

const URL_BASE = `${ApiPaths.JURISDICTIONS}`;
const URL_SEARCH = `${URL_BASE}/search`;
const URL_WITH_ID = (id: number) => {
	return URL_BASE + `/${id}`;
};

export const tryGetAllJurisdictions = async () => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpGet(URL_BASE, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryGetAllJurisdictionsBySearch = async (
	filters?: IJurisdictionFilters,
	pagination?: IPaginationRequest
) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let parameters = {};
		if (filters) {
			parameters = { ...filters };
		}
		if (pagination) {
			parameters = { ...parameters, ...pagination };
		}
		const queryString = buildURLQuery(parameters);
		let uri;
		if (!filters || !filters?.term) {
			uri = `${URL_BASE}?${queryString}`;
		} else {
			uri = `${URL_SEARCH}?${queryString}`;
		}
		let response = await httpGet(uri, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryGetAllJurisdictionsBySearchPaginated = async (
	filters?: IJurisdictionFilters,
	pagination?: IPaginationRequest
) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let parameters = {};
		if (filters) {
			parameters = { ...filters };
		}
		if (pagination) {
			parameters = { ...parameters, ...pagination };
		}
		const queryString = buildURLQuery(parameters);
		const URI = `${URL_BASE}?${queryString}`;

		let response = await httpGet(URI, OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryGetJurisdictionByID = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpGet(URL_WITH_ID(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryCreateOrUpdateJurisdiction = async (entity: IJurisdiction) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response;
		if (!entity.id) {
			response = await httpPost(URL_BASE, entity, OPTIONS);
		} else {
			let id = entity.id;
			delete entity.id;
			response = await httpPatch(URL_WITH_ID(id), entity, OPTIONS);
		}
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};

export const tryDeleteJurisdiction = async (id: number) => {
	try {
		const OPTIONS = buildDefaultOptions();
		let response = await httpDelete(URL_WITH_ID(id), OPTIONS);
		return response?.data;
	} catch (error) {
		return handleHttpError(error);
	}
};
