import './index.css';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//Tanstack table
import {
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
	FilterFn,
	Row
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';
import { defaultColumns } from './BienesColumnDef';
import ClearIcon from '@mui/icons-material/Clear';
//Hooks
import { useBFContext } from 'hooks/useBFContext';

//Interfaces
import { IPlanilla } from 'interfaces/bienesFiscales/IPlanilla';

//Components
import { InputLabel, MenuItem, FormControl, Box, Typography, Select, Button } from '@mui/material';
import { NotificationsPaginator } from 'components/EncPatrimonial/table/NotificationsPaginator';

//Services
import { BounceLoader } from 'react-spinners';

//Utils
import { FormGenericSelect } from 'components/globals/inputs/FormGenericSelect';
import { ISelectOption } from 'interfaces/commons/IParameters';
import { tryGetAllPatrimonialDependences } from 'services/patrimonialDependencesService';
import { FormGenericInput } from 'components/globals/inputs/FormGenericInput';
import { downloadSheetFile } from 'services/presentationSheetService';

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
	// Rank the item
	const itemRank = rankItem(row.getValue(columnId), value);

	// Store the itemRank info
	addMeta({
		itemRank
	});

	// Return if the item should be filtered in/out
	return itemRank.passed;
};

const monthOptions = [
	{ id: 1, label: 'Enero' },
	{ id: 2, label: 'Febrero' },
	{ id: 3, label: 'Marzo' },
	{ id: 4, label: 'Abril' },
	{ id: 5, label: 'Mayo' },
	{ id: 6, label: 'Junio' },
	{ id: 7, label: 'Julio' },
	{ id: 8, label: 'Agosto' },
	{ id: 9, label: 'Septiembre' },
	{ id: 10, label: 'Octubre' },
	{ id: 11, label: 'Noviembre' },
	{ id: 12, label: 'Diciembre' }
];

const movementTypeOptions = [
	{ id: 1, label: 'Alta' },
	{ id: 2, label: 'Baja' }
];

export const BienesTable = () => {
	const {
		planillas,
		mesFilter,
		tipoMovFilter,
		yearFilter,
		setYearFilter,
		meta,
		setMeta,
		dependency,
		setDependency,
		setMesFilter,
		setTipoMovFilter,
		setLimit
	} = useBFContext();
	const navigate = useNavigate();
	const [data, setData] = useState<IPlanilla[]>([]);
	const [columns] = useState<typeof defaultColumns>(() => [...defaultColumns]);
	const [loading, setLoading] = useState(true);
	const [patrimonialDependence, setPatrimonialDependence] = useState<ISelectOption | undefined>(undefined);
	const [patrimonialDependencesOptions, setPatrimonialDependencesOptions] = useState([]);
	const [movementType, setMovementType] = useState('');
	const [year, setYear] = useState('');
	const [month, setMonth] = useState('');
	const [resultsLimit, setResultsLimit] = useState(10);

	useEffect(() => {
		setData(planillas);
		setTimeout(() => {
			setLoading(false);
		}, 500);
	}, [planillas, tipoMovFilter, mesFilter, yearFilter, dependency, yearFilter]);

	// Create the table and pass your options
	const table = useReactTable({
		data,
		columns,
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getCoreRowModel: getCoreRowModel(),
		filterFns: {
			fuzzy: fuzzyFilter
		}
	});

	// Manage your own state of the table
	const [state, setState] = useState(table.initialState);

	// Override the state managers for the table to your own
	table.setOptions((prev) => ({
		...prev,
		state,
		onStateChange: setState
	}));

	const handleRedirectToAuditSheets = (row: Row<any>) => {
		navigate(`/planilla-mensual-auditoria`, { state: { planilla: row.original } });
	};

	const handleResetFilters = () => {
		setMonth('');
		setMesFilter(null);
		setYear('');
		setYearFilter(null);
		setResultsLimit(10);
		setLimit(10);
		setPatrimonialDependence(undefined);
		setDependency(null);
		setMovementType('');
		setTipoMovFilter(0);
	};

	const handleActions = async (row: any) => {
		const actionName = row.original.action;

		if (actionName === 'view-detail') {
			handleRedirectToAuditSheets(row);
		}
		if (actionName === 'download-sheet') {
			if (row.original.id) {
				await downloadSheetFile({
					sheetId: row.original.id,
					movement_type: row.original.movement_type,
					month: monthOptions.find((m: any) => m.id === row.original.month)?.label
				});
			}
		}

		delete row.original.action;
	};

	useEffect(() => {
		tryGetAllPatrimonialDependences().then((response) => {
			setPatrimonialDependencesOptions(
				response.map((item: any) => ({ label: item.patrimonial_dependence, id: item.id }))
			);
		});
	}, []);

	return (
		<Box>
			<div className="pagination-encargado">
				<Box width="100%">
					<Typography
						sx={{ my: 2 }}
						fontWeight={600}
						variant="h5">
						Filtrar por:
					</Typography>

					<Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
						<Box sx={{ maxWidth: '460px', width: '100%' }}>
							<FormGenericSelect
								id="patrimonial-dependence-select"
								value={patrimonialDependence}
								size="small"
								label="Buscar Dependencia Patrimonial"
								options={patrimonialDependencesOptions}
								handleChange={(value) => {
									setPatrimonialDependence(value);
									setDependency({ value: value?.id, label: value?.label });
								}}
								required={true}
							/>
						</Box>

						<FormControl sx={{ width: '160px', flexShrink: 0 }}>
							<InputLabel htmlFor="movemenType">Tipo de Movimiento</InputLabel>
							<Select
								size="small"
								value={movementType}
								onChange={(val) => {
									setMovementType(val.target.value);
									setTipoMovFilter(movementTypeOptions.find((el) => el.id === Number(val.target.value))?.id || 0);
								}}
								label="Tipo de Movimiento">
								{movementTypeOptions.map((item) => {
									return (
										<MenuItem
											key={item.id}
											value={item.id}>
											{item.label}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>

						<FormControl sx={{ width: '240px' }}>
							<FormGenericInput
								label="Año"
								maxLength={4}
								onChange={(value: string) => {
									setYear(value);
									if (value?.length === 4) setYearFilter(value ? Number(value) : 0);
								}}
								value={year}
								type="number"
								size="small"
							/>
						</FormControl>

						<FormControl sx={{ width: '240px' }}>
							<InputLabel htmlFor="stateFilter">Mes</InputLabel>
							<Select
								size="small"
								value={month}
								onChange={(ev) => {
									setMonth(ev.target.value);
									setMesFilter(Number(ev.target.value));
								}}
								label="Mes">
								{monthOptions.map((item) => {
									return (
										<MenuItem
											key={item.id}
											value={item.id}>
											{item.label}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>

						<FormControl
							fullWidth
							sx={{ maxWidth: '200px' }}>
							{<InputLabel id="select-label">Cantidad de resultados</InputLabel>}
							<Select
								size="small"
								label="Cantidad de resultados"
								onChange={(ev) => {
									const val = Number(ev.target.value);
									setResultsLimit(val);
									setLimit(val);
								}}
								value={resultsLimit}>
								{[10, 20, 30, 40, 50].map((pageSize) => (
									<MenuItem
										key={pageSize}
										value={pageSize}>
										Mostrar {pageSize}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<Button
							sx={{ flexShrink: 0 }}
							size="small"
							onClick={handleResetFilters}
							variant="contained">
							Limpiar
							<ClearIcon />
						</Button>
					</Box>

					<Box
						sx={{
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
							mb: 1
						}}>
						<Typography variant="h6">{`Total de Movimientos: ${meta.totalItems}`}</Typography>
					</Box>
				</Box>
			</div>

			{loading ? (
				<div className="table-loading">
					<BounceLoader
						size={100}
						speedMultiplier={1.5}
					/>
				</div>
			) : (
				<table className="movements-table">
					<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<th
										key={header.id}
										colSpan={header.colSpan}>
										{header.isPlaceholder ? null : (
											<div
												{...{
													className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
													onClick: header.column.getToggleSortingHandler()
												}}>
												{flexRender(header.column.columnDef.header, header.getContext())}
												{{
													asc: ' 🔼',
													desc: ' 🔽'
												}[header.column.getIsSorted() as string] ?? null}
											</div>
										)}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody>
						{table.getRowModel().rows.map((row) => (
							<tr
								onClick={() => handleActions(row)}
								key={row.id}>
								{row.getVisibleCells().map((cell) => (
									<td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
								))}
							</tr>
						))}

						{data.length === 0 ? (
							<tr>
								<td
									colSpan={columns.length}
									style={{
										paddingTop: '10px',
										paddingBottom: '10px'
									}}>
									<div>{`No se encontraron resultados en la búsqueda realizada`}</div>
								</td>
							</tr>
						) : null}
					</tbody>
				</table>
			)}
			<NotificationsPaginator
				showInputPage={false}
				meta={meta}
				setMeta={setMeta}
			/>
		</Box>
	);
};
